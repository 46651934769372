// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 接单 PUT /sinotransbooking/v1/seller/performance/sea/${param0}/accept */
export async function OperationId_sinotransbooking_PerformanceSeaSellerController_accept(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaSellerControllerAcceptParams,
  body: API.PerformanceSeaAcceptOrderRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performance/sea/${param0}/accept`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaSellerController_accept',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 完成订单 PUT /sinotransbooking/v1/seller/performance/sea/${param0}/complete */
export async function OperationId_sinotransbooking_PerformanceSeaSellerController_complete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaSellerControllerCompleteParams,
  body: API.PerformanceSeaCompleteOrderRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performance/sea/${param0}/complete`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaSellerController_complete',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 确认订舱 POST /sinotransbooking/v1/seller/performance/sea/${param0}/confirm-booking */
export async function OperationId_sinotransbooking_PerformanceSeaSellerController_confirmBooking(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaSellerControllerConfirmBookingParams,
  body: API.PerformanceOrderSeaConfirmBookingRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performance/sea/${param0}/confirm-booking`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaSellerController_confirmBooking',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 开航、抵港 PUT /sinotransbooking/v1/seller/performance/sea/${param0}/domestic-actions */
export async function OperationId_sinotransbooking_PerformanceSeaSellerController_domesticActions(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaSellerControllerDomesticActionsParams,
  body: API.SellerPerformanceSeaDomesticActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/sinotransbooking/v1/seller/performance/sea/${param0}/domestic-actions`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSeaSellerController_domesticActions',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 关闭订单 PUT /sinotransbooking/v1/seller/performance/sea/${param0}/force-close */
export async function OperationId_sinotransbooking_PerformanceSeaSellerController_forceClose(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaSellerControllerForceCloseParams,
  body: API.CommonPerformanceSeaActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performance/sea/${param0}/force-close`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaSellerController_forceClose',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 拒单 PUT /sinotransbooking/v1/seller/performance/sea/${param0}/reject */
export async function OperationId_sinotransbooking_PerformanceSeaSellerController_reject(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaSellerControllerRejectParams,
  body: API.CommonPerformanceSeaActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performance/sea/${param0}/reject`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaSellerController_reject',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 补料 POST /sinotransbooking/v1/seller/performance/sea/${param0}/supplementary */
export async function OperationId_sinotransbooking_PerformanceSeaSellerController_supplementary(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaSellerControllerSupplementaryParams,
  body: API.PerformanceOrderSeaSupplementaryRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performance/sea/${param0}/supplementary`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaSellerController_supplementary',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
