import { Space } from 'antd';
import { enums } from '@easygo/utils';
import cx from 'classnames';
import styles from './index.module.less';
import BigNumber from 'bignumber.js';

export type PriceLabelSizeVo = 'small' | 'medium' | 'large' | 'huge';
export type PriceLabelRoundVo = 'ceil' | 'floor' | 'round';
interface TagProps {
  currency: keyof typeof enums.CoinTypeCode;
  price: string | number;
  size?: PriceLabelSizeVo;
  color?: string;
  letter?: boolean; // true：显示字母，false：显示币种符号
  bold?: boolean;
  decimal?: number; // 保留小数位数
  round?: PriceLabelRoundVo; // ceil: 向上取整，floor: 向下取整，round: 四舍五入
  endzero?: boolean; // 是否补全小数点末尾的0
}

export const PriceLabel: React.FC<TagProps> = (props) => {
  const {
    currency = enums.CoinType.RMB,
    price,
    size = 'small',
    color = '#333',
    letter = false,
    bold = false,
    decimal = 0,
    round = 'round',
    endzero = true,
  } = props;

  const s = letter ? enums.CURRENCY[currency]?.letter : enums.CURRENCY[currency]?.symbol;
  const fsize = {
    small: { fontSize: '14px' },
    medium: { fontSize: '16px' },
    large: { fontSize: '18px' },
    huge: { fontSize: '22px' },
  }[size];
  const style = {
    color,
    fontWeight: bold ? 'bold' : 'normal',
    ...fsize,
  };

  // 补充小数点后末尾0
  const supplyEnd = (price: number) => {
    const arr = `${price}`.split('.');
    let end = '';
    if (arr[1]) {
      for (let i = arr[1].length; i < decimal; i++) {
        arr[1] += '0';
      }
      end = arr[1];
    } else {
      for (let i = 0; i < decimal; i++) {
        end += '0';
      }
    }
    return decimal > 0 ? `${arr[0]}.${end}` : arr[0];
  };

  // 处理金额
  let Deci: number = 1;
  for (let i = 0; i < decimal; i++) {
    Deci = Deci * 10;
  }
  const getPrice = (price: number) => {
    let res = price;
    const amount = BigNumber(res).times(Deci).toNumber();
    switch (round) {
      case 'ceil':
        res = Math.ceil(amount) / Deci;
        break;
      case 'floor':
        res = Math.floor(amount) / Deci;
        break;
      case 'round':
        res = Math.round(amount) / Deci;
        break;
      default:
        break;
    }
    let r = '-';
    if (endzero) r = supplyEnd(res);
    return r;
  };

  return (
    <Space className={cx(styles['price-label'])} style={style} size={2}>
      <span>{s}</span>
      <span>{getPrice(Number(price))}</span>
    </Space>
  );
};
