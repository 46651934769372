import { Dropdown, Menu, Button, Space } from 'antd';
import type { ProOperationConfig } from '../../type';
import { DownOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { useComponentTrans } from '../../../config';
const getItems = (arr?: ProOperationConfig<any>[], record?: any): any => {
  if (arr) {
    return arr.flatMap((item) => {
      if (item.hide && item.hide(record)) {
        return [];
      }
      return [
        {
          label: item.label,
          icon: item.icon,
          key: item.key,
          children: item.children ? getItems(item.children, record) : undefined,
          disabled: item.disabled ? item.disabled(record) : false,
        },
      ];
    });
  }
  return undefined;
};

export default function ProOperation<DataType>({ record, operations }: { record: DataType; operations?: ProOperationConfig<DataType>[] }) {
  const trans = useComponentTrans();
  const btns = useMemo<ProOperationConfig<DataType>[]>(() => {
    const arr = (operations || []).flatMap((item) => {
      if (item.hide && item.hide(record)) {
        return [];
      }
      return [item];
    });
    if (arr.length > 2) {
      return [
        arr[0],
        {
          label: trans('more'),
          key: 'more',
          children: arr.slice(1),
        },
      ];
    }
    return arr;
  }, [operations, record, trans]);
  return operations ? (
    <Space>
      {btns.map((item) => {
        return item.children ? (
          <Dropdown key={item.key} trigger={['click']} overlay={<Menu items={getItems(item.children, record)} />} placement="bottomRight">
            <Button icon={item.icon ?? <DownOutlined />} disabled={item.disabled ? item.disabled(record) : false} {...item.btnProps}>
              {item.label}
            </Button>
          </Dropdown>
        ) : (
          <Button onClick={() => (item.onClick ? item.onClick(record) : null)} key={item.key} icon={item.icon} {...item.btnProps}>
            {item.label}
          </Button>
        );
      })}
    </Space>
  ) : null;
}
