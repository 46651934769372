// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 钱包详情 GET /salesgateway/member/account/${param0} */
export async function OperationId_salesgateway_MemberController_accountDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerAccountDetailParams,
  options?: { [key: string]: any },
) {
  const { accountId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MemberAccountDetailVo>(`/salesgateway/member/account/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_accountDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 分页查询地址 GET /salesgateway/member/addresses */
export async function OperationId_salesgateway_MemberController_pageAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerPageAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageAddressDTO>('/salesgateway/member/addresses', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_pageAddress',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 根据企业id获取绑定银行卡信息 GET /salesgateway/member/cards */
export async function OperationId_salesgateway_MemberController_getMemberBankCard(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerGetMemberBankCardParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MemberBankCardResponseVO>('/salesgateway/member/cards', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_getMemberBankCard',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 修改绑定银行卡 PUT /salesgateway/member/cards */
export async function OperationId_salesgateway_MemberController_editBankCard(
  body: API.BankCardUpdateVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BasicResponseVO>('/salesgateway/member/cards', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_editBankCard',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 增加绑定银行卡 POST /salesgateway/member/cards */
export async function OperationId_salesgateway_MemberController_bindBankCard(
  body: API.BankCardVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BasicResponseVO>('/salesgateway/member/cards', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_bindBankCard',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 解绑银行卡 DELETE /salesgateway/member/cards */
export async function OperationId_salesgateway_MemberController_unbindBankCard(
  body: API.UnbindBankCardVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/member/cards', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_unbindBankCard',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 根据企业id获取绑定银行卡信息(v2) GET /salesgateway/member/cards/v2 */
export async function OperationId_salesgateway_MemberController_getMemberCurrencyBankCard(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerGetMemberCurrencyBankCardParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CurrencyMemberBankCardResponseVO>('/salesgateway/member/cards/v2', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_getMemberCurrencyBankCard',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取账期付款详情 GET /salesgateway/member/credits/${param0} */
export async function OperationId_salesgateway_MemberController_getCredit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerGetCreditParams,
  options?: { [key: string]: any },
) {
  const { creditId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.CreditVO>(`/salesgateway/member/credits/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_getCredit',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取电子回单 GET /salesgateway/member/downloadFinanceFile/${param0} */
export async function OperationId_salesgateway_MemberController_downloadFinanceFile(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerDownloadFinanceFileParams,
  options?: { [key: string]: any },
) {
  const { serialNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DownloadFinanceResponseVo>(
    `/salesgateway/member/downloadFinanceFile/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_MemberController_downloadFinanceFile',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 资金（保证金）冻结账单 POST /salesgateway/member/fundDepositOrders */
export async function OperationId_salesgateway_MemberController_fundDepositOrderList(
  body: API.GetFundDepositOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetFundDepositOrderResponseVo>('/salesgateway/member/fundDepositOrders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_fundDepositOrderList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 资金冻结账单 POST /salesgateway/member/fundFrozenOrders */
export async function OperationId_salesgateway_MemberController_fundFrozenOrderList(
  body: API.GetFundFrozenOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetFundFrozentOrderResponseVo>('/salesgateway/member/fundFrozenOrders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_fundFrozenOrderList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 资金账单 POST /salesgateway/member/fundOrders */
export async function OperationId_salesgateway_MemberController_fundOrderList(
  body: API.GetFundOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetFundOrderResponseVo>('/salesgateway/member/fundOrders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_fundOrderList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 生成验证码 GET /salesgateway/member/generateCode */
export async function OperationId_salesgateway_MemberController_generateCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerGenerateCodeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/member/generateCode', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_generateCode',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取开通线上支付详情 POST /salesgateway/member/getCreateOpenOnlinePay */
export async function OperationId_salesgateway_MemberController_getOpenOnlinePayDetail(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.GetOpenOnlinePayDetailResponseVo>(
    '/salesgateway/member/getCreateOpenOnlinePay',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_MemberController_getOpenOnlinePayDetail',
      },
      ...(rest || {}),
    },
  );
}

/** 根据企业id获取钱包账户详情 GET /salesgateway/member/getMemberDetail */
export async function OperationId_salesgateway_MemberController_getMemberDetailById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerGetMemberDetailByIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MembersDetailResponseVO>('/salesgateway/member/getMemberDetail', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_getMemberDetailById',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 线下钱包充提记录 POST /salesgateway/member/offlineTransactionLogs */
export async function OperationId_salesgateway_MemberController_offlineTransactionLogs(
  body: API.OfflineTransactionLogsRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OfflineTransactionLogsResponseVo>(
    '/salesgateway/member/offlineTransactionLogs',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_MemberController_offlineTransactionLogs',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 线下钱包充提记录详情 GET /salesgateway/member/offlineTransactionLogs/${param0} */
export async function OperationId_salesgateway_MemberController_offlineTransactionLogDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayMemberControllerOfflineTransactionLogDetailParams,
  options?: { [key: string]: any },
) {
  const { recordId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OfflineTransactionLogDetailVo>(
    `/salesgateway/member/offlineTransactionLogs/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_MemberController_offlineTransactionLogDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 线下钱包提现 POST /salesgateway/member/offlineWithdraw */
export async function OperationId_salesgateway_MemberController_offlineWithdraw(
  body: API.OfflineWithdrawRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoolBaseResponseVO>('/salesgateway/member/offlineWithdraw', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_offlineWithdraw',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 线下钱包提现试算 POST /salesgateway/member/offlineWithdrawTrail */
export async function OperationId_salesgateway_MemberController_offlineWithdrawTrial(
  body: API.OfflineWithdrawTrailRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OfflineWithdrawTrailResponseVo>('/salesgateway/member/offlineWithdrawTrail', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_offlineWithdrawTrial',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 开通线上支付 POST /salesgateway/member/openOnlinePay */
export async function OperationId_salesgateway_MemberController_openOnlinePay(
  body: API.OpenOnlinePayRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/member/openOnlinePay', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_openOnlinePay',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 验证打款金额 POST /salesgateway/member/payCheck */
export async function OperationId_salesgateway_MemberController_payCheck(
  body: API.PayCheckRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PayCheckResponseVo>('/salesgateway/member/payCheck', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_payCheck',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 签署开通线上支付苏宁协议 GET /salesgateway/member/signSuNingAgreement */
export async function OperationId_salesgateway_MemberController_signSuNingAgreement(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/member/signSuNingAgreement', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_signSuNingAgreement',
    },
    ...(rest || {}),
  });
}

/** 交易记录 POST /salesgateway/member/transactionLogs */
export async function OperationId_salesgateway_MemberController_transactionLogs(
  body: API.TransactionLogsRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.TransactionLogsResponseVo>('/salesgateway/member/transactionLogs', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_transactionLogs',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 根据企业id修改授权人手机号 PUT /salesgateway/member/updateMobile */
export async function OperationId_salesgateway_MemberController_updateMobile(
  body: API.UpdateMemberVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/member/updateMobile', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_updateMobile',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 提现 POST /salesgateway/member/withdraw */
export async function OperationId_salesgateway_MemberController_withdraw(
  body: API.WithdrawRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoolBaseResponseVO>('/salesgateway/member/withdraw', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_withdraw',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 发送提现验证码 GET /salesgateway/member/withdraw/code */
export async function OperationId_salesgateway_MemberController_withdrawGenerateCode(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/member/withdraw/code', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MemberController_withdrawGenerateCode',
    },
    ...(rest || {}),
  });
}
