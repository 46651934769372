// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 卖家端-查看发票 POST /sinotransbooking/v2/seller/statement/${param0}/invoice */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_invoiceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2InvoiceDetailParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.InvoiceVO>(`/sinotransbooking/v2/seller/statement/${param0}/invoice`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_invoiceDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询账单支付方案 GET /sinotransbooking/v2/seller/statement/${param0}/plan */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_queryStatementPayPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2QueryStatementPayPlanParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementPayPlanVO[]>(`/sinotransbooking/v2/seller/statement/${param0}/plan`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_queryStatementPayPlan',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 更新账单支付方案 PUT /sinotransbooking/v2/seller/statement/${param0}/plan */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_updateStatementPayPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2UpdateStatementPayPlanParams,
  body: API.StatementPayPlanRequest[],
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/sinotransbooking/v2/seller/statement/${param0}/plan`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_SellerStatementControllerV2_updateStatementPayPlan',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家端-子账单列表 GET /sinotransbooking/v2/seller/statement/${param0}/sub */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_listSubStatements(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2ListSubStatementsParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SubStatementItemVO[]>(`/sinotransbooking/v2/seller/statement/${param0}/sub`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_listSubStatements',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 卖家端-确认收款 POST /sinotransbooking/v2/seller/statement/confirmCollection */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_confirmCollection(
  body: API.ConfirmCollectionRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v2/seller/statement/confirmCollection', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_confirmCollection',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家端-确认收款检验 POST /sinotransbooking/v2/seller/statement/confirmCollection/${param0}/check */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_confirmCollectionCheck(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2ConfirmCollectionCheckParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/sinotransbooking/v2/seller/statement/confirmCollection/${param0}/check`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_SellerStatementControllerV2_confirmCollectionCheck',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 下载卖家海运费用确认单 GET /sinotransbooking/v2/seller/statement/details/${param0}/pdf */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_downloadBillInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2DownloadBillInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(`/sinotransbooking/v2/seller/statement/details/${param0}/pdf`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_downloadBillInfo',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 卖家端-主账单列表 POST /sinotransbooking/v2/seller/statement/list */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_pageStatement(
  body: API.StatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStatementItemVO>('/sinotransbooking/v2/seller/statement/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_pageStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家端-多个订单制作海运对账单 POST /sinotransbooking/v2/seller/statement/makeStatementByMultipleOrder */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_makeStatementByMultipleOrder(
  body: API.MakeSalesStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MakeSalesStatementResponse>(
    '/sinotransbooking/v2/seller/statement/makeStatementByMultipleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerStatementControllerV2_makeStatementByMultipleOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家端-单个订单制作海运对账单 POST /sinotransbooking/v2/seller/statement/makeStatementBySingleOrder */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_makeStatementBySingleOrder(
  body: API.MakeSalesStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MakeSalesStatementResponse>(
    '/sinotransbooking/v2/seller/statement/makeStatementBySingleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerStatementControllerV2_makeStatementBySingleOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家端-更新订单汇率 POST /sinotransbooking/v2/seller/statement/order-rate */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_updateOrderRate(
  body: API.UpdateOrderRateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v2/seller/statement/order-rate', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_updateOrderRate',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家端-制作海运订单的订单列表 POST /sinotransbooking/v2/seller/statement/orders */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_statementPerformanceOrder(
  body: API.StatementPerformanceQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStatementPerformanceOrderDetailVO>(
    '/sinotransbooking/v2/seller/statement/orders',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerStatementControllerV2_statementPerformanceOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家端-根据订单号获取关联子账单详情 POST /sinotransbooking/v2/seller/statement/orderStatementDetail/${param0} */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_orderWithStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2OrderWithStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OrderWithStatementVO>(
    `/sinotransbooking/v2/seller/statement/orderStatementDetail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_orderWithStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家端-根据履约单查询履约费用信息 POST /sinotransbooking/v2/seller/statement/queryPerformanceFeeByPerformanceId/${param0} */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_queryPerformanceFeeByPerformanceId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2QueryPerformanceFeeByPerformanceIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceFeeVO>(
    `/sinotransbooking/v2/seller/statement/queryPerformanceFeeByPerformanceId/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerStatementControllerV2_queryPerformanceFeeByPerformanceId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家端-根据账单查询账单费用列表 POST /sinotransbooking/v2/seller/statement/queryStatementFeeList */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_queryStatementFeeList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2QueryStatementFeeListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StatementFeeItemVO[]>(
    '/sinotransbooking/v2/seller/statement/queryStatementFeeList',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerStatementControllerV2_queryStatementFeeList',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 卖家端-根据子账单查账单信息 POST /sinotransbooking/v2/seller/statement/querySubStatementInfoById/${param0} */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_querySubStatementInfoById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2QuerySubStatementInfoByIdParams,
  options?: { [key: string]: any },
) {
  const { subStatementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementInfoVO>(
    `/sinotransbooking/v2/seller/statement/querySubStatementInfoById/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerStatementControllerV2_querySubStatementInfoById',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家端-应收金额 POST /sinotransbooking/v2/seller/statement/statementCollection/${param0} */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_statementCollection(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerV2StatementCollectionParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementCollectionAmountVO>(
    `/sinotransbooking/v2/seller/statement/statementCollection/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_statementCollection',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家端-修改海运账单 POST /sinotransbooking/v2/seller/statement/update */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_updateStatement(
  body: API.UpdateSalesStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v2/seller/statement/update', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_updateStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家端-上传发票 POST /sinotransbooking/v2/seller/statement/upload-invoice */
export async function OperationId_sinotransbooking_SellerStatementControllerV2_uploadInvoice(
  body: API.UploadInvoiceRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v2/seller/statement/upload-invoice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementControllerV2_uploadInvoice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
