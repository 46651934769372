// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询附加费 GET /sinotransbooking/v1/seller/surcharges */
export async function OperationId_sinotransbooking_SellerSurchargeController_pageSurcharge(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerSurchargeControllerPageSurchargeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageSurchargeVO>('/sinotransbooking/v1/seller/surcharges', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerSurchargeController_pageSurcharge',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 修改附加费 PUT /sinotransbooking/v1/seller/surcharges */
export async function OperationId_sinotransbooking_SellerSurchargeController_updateSurcharge(
  body: API.SurchargeUpdateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SurchargeRepeatVO[]>('/sinotransbooking/v1/seller/surcharges', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerSurchargeController_updateSurcharge',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 创建附加费 POST /sinotransbooking/v1/seller/surcharges */
export async function OperationId_sinotransbooking_SellerSurchargeController_createSurcharge(
  body: API.SurchargeCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SurchargeRepeatVO[]>('/sinotransbooking/v1/seller/surcharges', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerSurchargeController_createSurcharge',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取附加费详情 GET /sinotransbooking/v1/seller/surcharges/${param0} */
export async function OperationId_sinotransbooking_SellerSurchargeController_getSurcharge(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerSurchargeControllerGetSurchargeParams,
  options?: { [key: string]: any },
) {
  const { surchargeId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SurchargeVO>(`/sinotransbooking/v1/seller/surcharges/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerSurchargeController_getSurcharge',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 模板名称唯一性校验 GET /sinotransbooking/v1/seller/surcharges/by/${param0} */
export async function OperationId_sinotransbooking_SellerSurchargeController_verifyTemplateName(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerSurchargeControllerVerifyTemplateNameParams,
  options?: { [key: string]: any },
) {
  const { name: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SurchargeTemplate[]>(`/sinotransbooking/v1/seller/surcharges/by/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerSurchargeController_verifyTemplateName',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 模板重复数据校验,修改和新增前置接口 GET /sinotransbooking/v1/seller/surcharges/verify */
export async function OperationId_sinotransbooking_SellerSurchargeController_verifyOverWrite(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerSurchargeControllerVerifyOverWriteParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SurchargeRepeatVO[]>('/sinotransbooking/v1/seller/surcharges/verify', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerSurchargeController_verifyOverWrite',
    },
    params: {
      ...params,
      template: undefined,
      ...params['template'],
    },
    ...(rest || {}),
  });
}
