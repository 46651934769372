/**
 * 提还计划
 */
/** 提还计划: 业务类型 */
export enum BizType {
  /** 出口放空 */
  EXPORT_PICKUP_EMPTY = 'PLAN_BIZ_TYPE_EXPORT_PICKUP_EMPTY',
  /** 进口回空 */
  IMPORT_DROPOFF_EMPTY = 'PLAN_BIZ_TYPE_IMPORT_DROPOFF_EMPTY',
  /** 租箱放空 */
  LEASING_PICKUP_EMPTY = 'PLAN_BIZ_TYPE_LEASING_PICKUP_EMPTY',
  /** 租箱回空 */
  LEASING_DROPOFF_EMPTY = 'PLAN_BIZ_TYPE_LEASING_DROPOFF_EMPTY',
  /** */
  TEMPORARY_DROPOFF_BOX = 'PLAN_BIZ_TYPE_TEMPORARY_DROPOFF_BOX',
  /** */
  TEMPORARY_PICKUP_BOX = 'PLAN_BIZ_TYPE_TEMPORARY_PICKUP_BOX',
  /** */
  PORT_DROPOFF_FULL = 'PLAN_BIZ_TYPE_PORT_DROPOFF_FULL',
  /** */
  PORT_PICKUP_FULL = 'PLAN_BIZ_TYPE_PORT_PICKUP_FULL',
  /** 买箱进场 */
  BUY_BOX_IN_YARD = 'PLAN_BIZ_TYPE_BUY_BOX_IN_YARD',
  /** 卖箱出场 */
  SELL_BOX_OUT_YARD = 'PLAN_BIZ_TYPE_SELL_BOX_OUT_YARD',
  /** 转站进场 */
  TRANSFER_IN_YARD = 'PLAN_BIZ_TYPE_TRANSFER_IN_YARD',
  /** 转站出场 */
  TRANSFER_OUT_YARD = 'PLAN_BIZ_TYPE_TRANSFER_OUT_YARD',
  /** 长期堆存 */
  STORAGE = 'PLAN_BIZ_TYPE_STORAGE',
  /** 预约小程序 */
  BOOKING_MINI_PROGRAM = 'PLAN_BIZ_TYPE_BOOKING_MINI_PROGRAM',
}
/** 提还计划: 业务类型 */
export const BizTypeOptions = {
  [BizType.EXPORT_PICKUP_EMPTY]: '出口放空',
  [BizType.IMPORT_DROPOFF_EMPTY]: '进口回空',
  [BizType.LEASING_PICKUP_EMPTY]: '租箱放空',
  [BizType.LEASING_DROPOFF_EMPTY]: '租箱回空',
  [BizType.TEMPORARY_DROPOFF_BOX]: '',
  [BizType.TEMPORARY_PICKUP_BOX]: '',
  [BizType.PORT_DROPOFF_FULL]: '',
  [BizType.PORT_PICKUP_FULL]: '',
  [BizType.BUY_BOX_IN_YARD]: '买箱进场',
  [BizType.SELL_BOX_OUT_YARD]: '卖箱出场',
  [BizType.TRANSFER_IN_YARD]: '转站进场',
  [BizType.TRANSFER_OUT_YARD]: '转站出场',
};
/** 提还计划：状态 */
export enum PickupDropoffScheduleStatus {
  /** 审核中 */
  WAIT_AUDIT = 'PLAN_STATUS_WAIT_AUDIT',
  /** 已取消 */
  CANCELlED = 'PLAN_STATUS_CANCELlED',
  /** 已作废 */
  ABOLISHED = 'PLAN_STATUS_ABOLISHED',
  /** 驳回 */
  AUDIT_REJECT = 'PLAN_STATUS_AUDIT_REJECT',
  /** 进行中 */
  AUDIT_PASS = 'PLAN_STATUS_AUDIT_PASS',
  /** 完成 */
  FINISHED = 'PLAN_STATUS_FINISHED',
}
/** 提还计划：状态 */
export const PickupDropoffScheduleStatusOptions = {
  [PickupDropoffScheduleStatus.WAIT_AUDIT]: '审核中',
  [PickupDropoffScheduleStatus.CANCELlED]: '已取消',
  [PickupDropoffScheduleStatus.ABOLISHED]: '已作废',
  [PickupDropoffScheduleStatus.AUDIT_REJECT]: '驳回',
  [PickupDropoffScheduleStatus.AUDIT_PASS]: '进行中',
  [PickupDropoffScheduleStatus.FINISHED]: '完成',
};
/** 提还计划：状态颜色 */
export const PickupDropoffScheduleStatusColors = {
  [PickupDropoffScheduleStatus.WAIT_AUDIT]: '#FF8413',
  [PickupDropoffScheduleStatus.CANCELlED]: '#FF4747',
  [PickupDropoffScheduleStatus.ABOLISHED]: '#FF4747',
  [PickupDropoffScheduleStatus.AUDIT_REJECT]: '#FF4747',
  [PickupDropoffScheduleStatus.AUDIT_PASS]: '#FF8413',
  [PickupDropoffScheduleStatus.FINISHED]: '#28A051',
};
/** 提还计划：推送状态 */
export enum YardPushStatus {
  /** 不推送 */
  NO_SEND = 'EMAIL_EVENT_NOTICE_STATUS_NO_SEND',
  /** 待推送 */
  WAIT_SEND = 'EMAIL_EVENT_NOTICE_STATUS_WAIT_SEND',
  /** 推送中 */
  SEND_ING = 'EMAIL_EVENT_NOTICE_STATUS_SEND_ING',
  /** 已推送 */
  ALREADY_SEND = 'EMAIL_EVENT_NOTICE_STATUS_ALREADY_SEND',
  /** 推送失败 */
  SEND_FAIL = 'EMAIL_EVENT_NOTICE_STATUS_SEND_FAIL',
}
/** 提还计划：推送状态 */
export const YardPushStatusOptions = {
  [YardPushStatus.NO_SEND]: '不推送',
  [YardPushStatus.WAIT_SEND]: '待推送',
  [YardPushStatus.SEND_ING]: '推送中',
  [YardPushStatus.ALREADY_SEND]: '已推送',
  [YardPushStatus.SEND_FAIL]: '推送失败',
};
/** 提还计划：推送状态颜色 */
export const YardPushStatusColors = {
  [YardPushStatus.NO_SEND]: '#FF8413',
  [YardPushStatus.WAIT_SEND]: '#FF8413',
  [YardPushStatus.SEND_ING]: '#FF8413',
  [YardPushStatus.ALREADY_SEND]: '#28A051',
  [YardPushStatus.SEND_FAIL]: '#FF4747',
};
/** 提还计划：接收人身份 */
export enum ReceiveIdentity {
  /** 箱东/船公司 */
  CONTAINER_OWNER = 'CUSTOMER_PARTNER_TYPE_CONTAINER_OWNER',
  /** 司机 */
  DRIVER = 'CUSTOMER_PARTNER_TYPE_DRIVER',
  /** 货代/用箱人 */
  CONTAINER_USER = 'CUSTOMER_PARTNER_TYPE_CONTAINER_USER',
  /** 车队 */
  FLEET = 'CUSTOMER_PARTNER_TYPE_FLEET',
}
/** 提还计划：接收人身份 */
export const ReceiveIdentityOptions = {
  [ReceiveIdentity.CONTAINER_OWNER]: '箱东/船公司',
  [ReceiveIdentity.DRIVER]: '司机',
  [ReceiveIdentity.CONTAINER_USER]: '货代/用箱人',
  [ReceiveIdentity.FLEET]: '车队',
};
/** 进出类型 */
export enum InOutType {
  /** 还箱进场 */
  IN = 'PLAN_IN_OUT_TYPE_IN_YARD',
  /** 提箱出场 */
  OUT = 'PLAN_IN_OUT_TYPE_OUT_YARD',
}
/** 进出类型 */
export const InOutTypeOptions = {
  [InOutType.IN]: '还箱进场',
  [InOutType.OUT]: '提箱出场',
};

/** 能否继续分派 */
export enum ContinueAssign {
  /** 还箱进场 */
  YES = 'CONTINUE_ASSIGN_YES',
  /** 提箱出场 */
  NO = 'CONTINUE_ASSIGN_NO',
}
/** 能否继续分派 */
export const ContinueAssignOptions = {
  [ContinueAssign.NO]: '不能转派',
  [ContinueAssign.YES]: '可转派',
};

/** 分派模式 */
export enum AssignMode {
  /** 按箱量 */
  NUMBER = 'ASSIGN_MODE_NUMBER',
  /** 按箱号 */
  CONTAINER_NO = 'ASSIGN_MODE_CONTAINER_NO',
}
/** 分派模式 */
export const AssignModeOptions = {
  [AssignMode.NUMBER]: '按箱量',
  [AssignMode.CONTAINER_NO]: '按箱号',
};

/** 箱子空重类型 */
export enum EFType {
  /** 空箱 */
  E = 'CONTAINER_EMPTY_FULL_TYPE_E',
  /** 重箱 */
  F = 'CONTAINER_EMPTY_FULL_TYPE_F',
}
/** 箱子空重类型 */
export const EFTypeOptions = {
  [EFType.E]: '空箱',
  [EFType.F]: '重箱',
};
/** 是否加重箱 */
export enum HeavyBox {
  /** 使用 */
  T = 'TRUE_FALSE_STATUS_T',
  /** 不使用 */
  F = 'TRUE_FALSE_STATUS_F',
}
/** 是否加重箱 */
export const HeavyBoxOptions = {
  [HeavyBox.T]: '使用',
  [HeavyBox.F]: '不使用',
};
/** 修洗结算方式 */
export enum YardBoxSettlementMode {
  /** 现结 */
  CASH = 'YARD_BOX_SETTLEMENT_MODE_CASH',
  /** 账期 */
  BILL = 'YARD_BOX_SETTLEMENT_MODE_BILL',
}
/** 修洗结算方式 */
export const YardBoxSettlementModeOptions = {
  [YardBoxSettlementMode.CASH]: '现结',
  [YardBoxSettlementMode.BILL]: '账期',
};

/** 验箱状态 */
export enum CheckBoxStatus {
  /** 未验箱 */
  NO = 'CHECK_BOX_STATUS_NO',
  /** 已验箱 */
  YES = 'CHECK_BOX_STATUS_YES',
}
/** 验箱状态 */
export const CheckBoxStatusOptions = {
  [CheckBoxStatus.NO]: '未验箱',
  [CheckBoxStatus.YES]: '已验箱',
};
/** 验箱状态 */
export const CheckBoxStatusIcons = {
  [CheckBoxStatus.NO]: 'easygo-icon-Warning',
  [CheckBoxStatus.YES]: 'easygo-icon-Success',
};

/** 费用承担/付款方 */
export enum PayerType {
  /** 营运人 */
  OPERATOR = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_OPERATOR',
  /** 用箱人 */
  SUITCASE_CARRIER = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_SUITCASE_CARRIER',
  /** 箱东 */
  CONTAINER_OWNER = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_CONTAINER_OWNER',
  /** 船代 */
  SHIPPING_AGENCY = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_SHIPPING_AGENCY',
}
/** 费用承担/付款方 */
export const PayerTypeOptions = {
  [PayerType.OPERATOR]: '营运人',
  [PayerType.SUITCASE_CARRIER]: '用箱人',
  [PayerType.CONTAINER_OWNER]: '箱东',
  [PayerType.SHIPPING_AGENCY]: '船代',
};

/** 验箱标准 */
export enum CheckBoxNorm {
  IICL = 'CHECK_BOX_NORM_IICL',
  NEW = 'CHECK_BOX_NORM_NEW',
  TRAIN = 'CHECK_BOX_NORM_TRAIN',
  CW = 'CHECK_BOX_NORM_CW',
}
/** 验箱标准 */
export const CheckBoxNormOptions = {
  [CheckBoxNorm.IICL]: 'IICL',
  [CheckBoxNorm.NEW]: '新箱标准',
  [CheckBoxNorm.TRAIN]: '内地班列标准',
  [CheckBoxNorm.CW]: 'CW',
};

/** 退款状态 */
export enum RefundStatus {
  /** 售后服务单已提交成功 */
  SUBMIT = 'AFTER_SALE_STATUS_SUBMIT',
  /** 退款中 */
  DOING = 'AFTER_SALE_STATUS_DOING',
  /** 售后完成 */
  SUCCESS = 'AFTER_SALE_STATUS_SUCCESS',
  /** 售后单已取消 */
  CANCELED = 'AFTER_SALE_STATUS_CANCELED',
  /** 售后单处理失败 */
  FAIL = 'AFTER_SALE_STATUS_FAIL',
}
/** 退款状态 */
export const RefundStatusOptions = {
  [RefundStatus.SUBMIT]: '售后服务单已提交成功',
  [RefundStatus.DOING]: '退款中',
  [RefundStatus.SUCCESS]: '售后完成',
  [RefundStatus.CANCELED]: '售后单已取消',
  [RefundStatus.FAIL]: '售后单处理失败',
};

/** 是否出账 */
export enum FeeDetailStatus {
  CANCEL = 'FEE_DETAIL_STATUS_CANCEL',
  CREATE = 'FEE_DETAIL_STATUS_CREATE',
  OUT = 'FEE_DETAIL_STATUS_OUT',
}
/** 是否出账 */
export const FeeDetailStatusOptions = {
  [FeeDetailStatus.CANCEL]: '作废',
  [FeeDetailStatus.CREATE]: '未出账',
  [FeeDetailStatus.OUT]: '已出账',
};

/** 支付状态 */
export enum BillStatus {
  CREATE = 'BILL_STATUS_CREATE',
  WAIT_CONFIRM = 'BILL_STATUS_WAIT_CONFIRM',
  DONE = 'BILL_STATUS_DONE',
}
/** 支付状态 */
export const BillStatusOptions = {
  [BillStatus.CREATE]: '待支付',
  [BillStatus.WAIT_CONFIRM]: '待确认',
  [BillStatus.DONE]: '已支付',
};
