// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 新增询报价单 POST /salesgateway/demand/api/create/${param0} */
export async function OperationId_salesgateway_DemandController_createDemand(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDemandControllerCreateDemandParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DemandCreateResponse>(`/salesgateway/demand/api/create/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DemandController_createDemand',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取买家询报价单 POST /salesgateway/demand/api/getBuyerDemands */
export async function OperationId_salesgateway_DemandController_getBuyerDemands(
  body: API.DemandListRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DemandWithMsgTimeVO[]>('/salesgateway/demand/api/getBuyerDemands', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DemandController_getBuyerDemands',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取卖家询报价单 POST /salesgateway/demand/api/getSellerDemands */
export async function OperationId_salesgateway_DemandController_getSellerDemands(
  body: API.DemandListRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DemandWithMsgTimeVO[]>('/salesgateway/demand/api/getSellerDemands', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DemandController_getSellerDemands',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家拒绝消息 POST /salesgateway/demand/api/rejectDemand */
export async function OperationId_salesgateway_DemandController_rejectDemand(
  body: API.DemandRejectRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoolBaseResponseVO>('/salesgateway/demand/api/rejectDemand', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DemandController_rejectDemand',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
