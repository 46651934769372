// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询地址 GET /sinotransbooking/v1/open/configs/addresses */
export async function OperationId_sinotransbooking_OpenCenterConfigController_pageAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerPageAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageAddressDTO>('/sinotransbooking/v1/open/configs/addresses', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_pageAddress',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取地址详情 GET /sinotransbooking/v1/open/configs/addresses/${param0} */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetAddressParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AddressVO>(`/sinotransbooking/v1/open/configs/addresses/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getAddress',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 模糊获取买卖家公司列表 GET /sinotransbooking/v1/open/configs/agent-enterprise */
export async function OperationId_sinotransbooking_OpenCenterConfigController_listBuyerEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerListBuyerEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcEnterpriseInfoDTO[]>('/sinotransbooking/v1/open/configs/agent-enterprise', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_listBuyerEnterprise',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 分页查询箱型 GET /sinotransbooking/v1/open/configs/box-types */
export async function OperationId_sinotransbooking_OpenCenterConfigController_pageBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerPageBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageBoxTypeDTO>('/sinotransbooking/v1/open/configs/box-types', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_pageBoxType',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取箱型详情 GET /sinotransbooking/v1/open/configs/box-types/${param0} */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BoxTypeDTO>(`/sinotransbooking/v1/open/configs/box-types/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getBoxType',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 下载经营范围 GET /sinotransbooking/v1/open/configs/business-scope */
export async function OperationId_sinotransbooking_OpenCenterConfigController_downloadBusinessScope(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<string>('/sinotransbooking/v1/open/configs/business-scope', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_downloadBusinessScope',
    },
    ...(rest || {}),
  });
}

/** 查询货币列表 GET /sinotransbooking/v1/open/configs/currency-configs/list */
export async function OperationId_sinotransbooking_OpenCenterConfigController_listCurrencyConfig(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.CurrencyConfigDTO[]>(
    '/sinotransbooking/v1/open/configs/currency-configs/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_listCurrencyConfig',
      },
      ...(rest || {}),
    },
  );
}

/** 获取企业所有员工 GET /sinotransbooking/v1/open/configs/get-enterprise-employee */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getEnterpriseEmployee(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetEnterpriseEmployeeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetEnterpriseEmployeeResponse>(
    '/sinotransbooking/v1/open/configs/get-enterprise-employee',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OpenCenterConfigController_getEnterpriseEmployee',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 获取航线组 GET /sinotransbooking/v1/open/configs/get-route-group-list */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getRouteGroupList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetRouteGroupListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageRouteGroupDTO>('/sinotransbooking/v1/open/configs/get-route-group-list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getRouteGroupList',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取船期 GET /sinotransbooking/v1/open/configs/get-ship-schedule */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getShipSchedule(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetShipScheduleParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipScheduleResponse>('/sinotransbooking/v1/open/configs/get-ship-schedule', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getShipSchedule',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}

/** 分页查询港口 GET /sinotransbooking/v1/open/configs/harbours */
export async function OperationId_sinotransbooking_OpenCenterConfigController_pageHarbour(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerPageHarbourParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageHarbourDTO>('/sinotransbooking/v1/open/configs/harbours', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_pageHarbour',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取港口详情 GET /sinotransbooking/v1/open/configs/harbours/${param0} */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getHarbour(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetHarbourParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.HarbourDTO>(`/sinotransbooking/v1/open/configs/harbours/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getHarbour',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询按条件-港口列表 POST /sinotransbooking/v1/open/configs/harbours/condition-harbours */
export async function OperationId_sinotransbooking_OpenCenterConfigController_listConditionHarbour(
  body: API.HarbourConditionQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.HarbourConditionVO>(
    '/sinotransbooking/v1/open/configs/harbours/condition-harbours',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_listConditionHarbour',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询按条件-白名单港口列表 POST /sinotransbooking/v1/open/configs/harbours/condition-white-harbours */
export async function OperationId_sinotransbooking_OpenCenterConfigController_listConditionWhiteHarbour(
  body: API.HarbourConditionQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.HarbourConditionVO>(
    '/sinotransbooking/v1/open/configs/harbours/condition-white-harbours',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OpenCenterConfigController_listConditionWhiteHarbour',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取履约配置(附加费项) GET /sinotransbooking/v1/open/configs/performances */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getPerformance(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceConfigDTO>('/sinotransbooking/v1/open/configs/performances', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getPerformance',
    },
    ...(rest || {}),
  });
}

/** 通过费用类目获取履约配置(附加费项) GET /sinotransbooking/v1/open/configs/performances/${param0} */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getPerformanceByCategory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetPerformanceByCategoryParams,
  options?: { [key: string]: any },
) {
  const { feeCategoryEnum: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceConfigDTO>(
    `/sinotransbooking/v1/open/configs/performances/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OpenCenterConfigController_getPerformanceByCategory',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取履全部费用项配置(附加费项) GET /sinotransbooking/v1/open/configs/performancesFeeAll */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getPerformanceAll(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceConfigDTO[]>(
    '/sinotransbooking/v1/open/configs/performancesFeeAll',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getPerformanceAll',
      },
      ...(rest || {}),
    },
  );
}

/** 查询当前利率 GET /sinotransbooking/v1/open/configs/rate */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getRate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetRateParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CurRateVo>('/sinotransbooking/v1/open/configs/rate', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getRate',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 分页查询海运公司 GET /sinotransbooking/v1/open/configs/shipping-companies */
export async function OperationId_sinotransbooking_OpenCenterConfigController_pageShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerPageShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageShippingCompanyDTO>(
    '/sinotransbooking/v1/open/configs/shipping-companies',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_pageShippingCompany',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 获取海运公司详情 GET /sinotransbooking/v1/open/configs/shipping-companies/${param0} */
export async function OperationId_sinotransbooking_OpenCenterConfigController_getShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerGetShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ShippingCompanyDTO>(
    `/sinotransbooking/v1/open/configs/shipping-companies/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_getShippingCompany',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 分页查询港口-白名单 GET /sinotransbooking/v1/open/configs/white-harbours */
export async function OperationId_sinotransbooking_OpenCenterConfigController_pageWhiteHarbour(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerPageWhiteHarbourParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageHarbourDTO>('/sinotransbooking/v1/open/configs/white-harbours', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_pageWhiteHarbour',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 是否在白名单中 GET /sinotransbooking/v1/open/configs/white-list-exists */
export async function OperationId_sinotransbooking_OpenCenterConfigController_existsWhiteList(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/sinotransbooking/v1/open/configs/white-list-exists', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenCenterConfigController_existsWhiteList',
    },
    ...(rest || {}),
  });
}

/** 分页查询白名单海运公司 GET /sinotransbooking/v1/open/configs/white-shipping-companies */
export async function OperationId_sinotransbooking_OpenCenterConfigController_pageWhiteShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenCenterConfigControllerPageWhiteShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageShippingCompanyDTO>(
    '/sinotransbooking/v1/open/configs/white-shipping-companies',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OpenCenterConfigController_pageWhiteShippingCompany',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}
