// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 买家 查询各个询价消息数量接口 GET /salesgateway/message/api/demandBuyerMessage */
export async function OperationId_salesgateway_ImMessageController_getBuyerDemandNewMessageNum(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.DemandMessageNumResVO[]>('/salesgateway/message/api/demandBuyerMessage', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ImMessageController_getBuyerDemandNewMessageNum',
    },
    ...(rest || {}),
  });
}

/** 卖家公司下 查询各个询价消息数量接口 GET /salesgateway/message/api/demandSellerMessage */
export async function OperationId_salesgateway_ImMessageController_getTotalDemandNewMessageNum(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.DemandMessageNumResVO[]>('/salesgateway/message/api/demandSellerMessage', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ImMessageController_getTotalDemandNewMessageNum',
    },
    ...(rest || {}),
  });
}

/** 根据询价单拉取消息 POST /salesgateway/message/api/getMessageByDemandId */
export async function OperationId_salesgateway_ImMessageController_getMessageByDemandId(
  body: API.ImMessageRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageImMessageResVO>('/salesgateway/message/api/getMessageByDemandId', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ImMessageController_getMessageByDemandId',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 发送消息 POST /salesgateway/message/api/sendMessage */
export async function OperationId_salesgateway_ImMessageController_sendMessage(
  body: API.ImMessageCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoolBaseResponseVO>('/salesgateway/message/api/sendMessage', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ImMessageController_sendMessage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家 查询所有未读消息数量接口 GET /salesgateway/message/api/totalBuyerMessage */
export async function OperationId_salesgateway_ImMessageController_getBuyerTotalNewMessageNum(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/message/api/totalBuyerMessage', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ImMessageController_getBuyerTotalNewMessageNum',
    },
    ...(rest || {}),
  });
}

/** 卖家公司下 查询所有未读消息数量接口 GET /salesgateway/message/api/totalSellerMessage */
export async function OperationId_salesgateway_ImMessageController_getTotalSellerNewMessageNum(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/message/api/totalSellerMessage', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ImMessageController_getTotalSellerNewMessageNum',
    },
    ...(rest || {}),
  });
}

/** 根据询价单修改消息为已读 PUT /salesgateway/message/api/updateMessageStatus/${param0} */
export async function OperationId_salesgateway_ImMessageController_updateMessageStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayImMessageControllerUpdateMessageStatusParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BoolBaseResponseVO>(
    `/salesgateway/message/api/updateMessageStatus/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ImMessageController_updateMessageStatus',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
