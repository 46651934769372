// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 卖家订单列表 GET /sinotransbooking/v1/seller/performances */
export async function OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerPagePerformanceOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderDetailVO>('/sinotransbooking/v1/seller/performances', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrder',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 修改订单 PUT /sinotransbooking/v1/seller/performances */
export async function OperationId_sinotransbooking_PerformanceSellerController_updatePerformancePrice(
  body: API.PerformanceEditPriceRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceBaseResponse>('/sinotransbooking/v1/seller/performances', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_PerformanceSellerController_updatePerformancePrice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 代订舱 POST /sinotransbooking/v1/seller/performances */
export async function OperationId_sinotransbooking_PerformanceSellerController_createPerformance(
  body: API.PerformanceAgentCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceBaseResponse>('/sinotransbooking/v1/seller/performances', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_createPerformance',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家订单详情 GET /sinotransbooking/v1/seller/performances/${param0} */
export async function OperationId_sinotransbooking_PerformanceSellerController_detail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailVO>(
    `/sinotransbooking/v1/seller/performances/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_detail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家上传附件 POST /sinotransbooking/v1/seller/performances/${param0}/attachment */
export async function OperationId_sinotransbooking_PerformanceSellerController_uploadAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerUploadAttachmentParams,
  body: API.UploadDocInfoRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performances/${param0}/attachment`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_uploadAttachment',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家删除附件 DELETE /sinotransbooking/v1/seller/performances/${param0}/attachment/${param1} */
export async function OperationId_sinotransbooking_PerformanceSellerController_deleteAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerDeleteAddressParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, attachmentId: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/sinotransbooking/v1/seller/performances/${param0}/attachment/${param1}`,
    {
      method: 'DELETE',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_deleteAddress',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家上传（提单）附件 POST /sinotransbooking/v1/seller/performances/${param0}/attachment/pickup */
export async function OperationId_sinotransbooking_PerformanceSellerController_uploadPickupAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerUploadPickupAttachmentParams,
  body: API.UploadPickupDocInfoRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UploadPickupDocInfoResponse>(
    `/sinotransbooking/v1/seller/performances/${param0}/attachment/pickup`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceSellerController_uploadPickupAttachment',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家附件查询 GET /sinotransbooking/v1/seller/performances/${param0}/attachments */
export async function OperationId_sinotransbooking_PerformanceSellerController_attachmentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerAttachmentListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderAttachmentVo[]>(
    `/sinotransbooking/v1/seller/performances/${param0}/attachments`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_attachmentList',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 获取履约单汇率 GET /sinotransbooking/v1/seller/performances/${param0}/rate */
export async function OperationId_sinotransbooking_PerformanceSellerController_getPerformanceRate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerGetPerformanceRateParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceRateDTO>(
    `/sinotransbooking/v1/seller/performances/${param0}/rate`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_getPerformanceRate',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 修改结算汇率 PUT /sinotransbooking/v1/seller/performances/${param0}/settlement-rate */
export async function OperationId_sinotransbooking_PerformanceSellerController_updateSettlementRate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerUpdateSettlementRateParams,
  body: API.UpdateSettlementRateRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performances/${param0}/settlement-rate`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_updateSettlementRate',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 上报异常 POST /sinotransbooking/v1/seller/performances/abnormal */
export async function OperationId_sinotransbooking_PerformanceSellerController_sendAbnormal(
  body: API.SendAbnormalRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/sinotransbooking/v1/seller/performances/abnormal', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_sendAbnormal',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 模糊获取代下单买家公司列表 GET /sinotransbooking/v1/seller/performances/agent-enterprise */
export async function OperationId_sinotransbooking_PerformanceSellerController_listBuyerEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerListBuyerEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcEnterpriseInfoDTO[]>(
    '/sinotransbooking/v1/seller/performances/agent-enterprise',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_listBuyerEnterprise',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 模糊获取代下单买家公司员工列表 GET /sinotransbooking/v1/seller/performances/agent-enterprise/emps */
export async function OperationId_sinotransbooking_PerformanceSellerController_listBuyerEnterpriseEmps(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerListBuyerEnterpriseEmpsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseEmployeeVO[]>(
    '/sinotransbooking/v1/seller/performances/agent-enterprise/emps',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceSellerController_listBuyerEnterpriseEmps',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 模糊获取买家公司列表_海运 GET /sinotransbooking/v1/seller/performances/buyer-enterprise-options */
export async function OperationId_sinotransbooking_PerformanceSellerController_listEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerListEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcEnterpriseInfoDTO[]>(
    '/sinotransbooking/v1/seller/performances/buyer-enterprise-options',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_listEnterprise',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 卖家接单人创建 POST /sinotransbooking/v1/seller/performances/create/seller-contactor */
export async function OperationId_sinotransbooking_PerformanceSellerController_createSellerContactor(
  body: API.CreateAndUpdateSellerContactRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/seller/performances/create/seller-contactor', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_createSellerContactor',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 精准搜索代下单公司列表 GET /sinotransbooking/v1/seller/performances/customer-enterprise */
export async function OperationId_sinotransbooking_PerformanceSellerController_listCustomerEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerListCustomerEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcEnterpriseInfoDTO[]>(
    '/sinotransbooking/v1/seller/performances/customer-enterprise',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceSellerController_listCustomerEnterprise',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 获取异常 GET /sinotransbooking/v1/seller/performances/exception */
export async function OperationId_sinotransbooking_PerformanceSellerController_getException(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceExceptionVO[]>(
    '/sinotransbooking/v1/seller/performances/exception',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_getException',
      },
      ...(rest || {}),
    },
  );
}

/** 新增目的港费用项 POST /sinotransbooking/v1/seller/performances/expense/destination-port */
export async function OperationId_sinotransbooking_PerformanceSellerController_createExpense(
  body: API.CreateExpenseConfigRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/seller/performances/expense/destination-port', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_createExpense',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 导出订单 POST /sinotransbooking/v1/seller/performances/export */
export async function OperationId_sinotransbooking_PerformanceSellerController_exportOrderList(
  body: API.PerformanceQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/seller/performances/export', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_exportOrderList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家接单人查询 GET /sinotransbooking/v1/seller/performances/get/seller-contactor */
export async function OperationId_sinotransbooking_PerformanceSellerController_getSellerContactor(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.GetSellerContactResponse>(
    '/sinotransbooking/v1/seller/performances/get/seller-contactor',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_getSellerContactor',
      },
      ...(rest || {}),
    },
  );
}

/** 卖家家订单地图 GET /sinotransbooking/v1/seller/performances/map-graphs */
export async function OperationId_sinotransbooking_PerformanceSellerController_listMapGraph(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.MapGraphVO[]>('/sinotransbooking/v1/seller/performances/map-graphs', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_listMapGraph',
    },
    ...(rest || {}),
  });
}

/** 消息查询 GET /sinotransbooking/v1/seller/performances/message/${param0} */
export async function OperationId_sinotransbooking_PerformanceSellerController_messageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerMessageListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MessageItemDTO[]>(
    `/sinotransbooking/v1/seller/performances/message/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceSellerController_messageList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家订单列表分类统计数据 GET /sinotransbooking/v1/seller/performances/sortCount */
export async function OperationId_sinotransbooking_PerformanceSellerController_getPerformanceOrderSortCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderSortCountVo>(
    '/sinotransbooking/v1/seller/performances/sortCount',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceSellerController_getPerformanceOrderSortCount',
      },
      ...(rest || {}),
    },
  );
}

/** 卖家接单人修改 PUT /sinotransbooking/v1/seller/performances/update/seller-contactor */
export async function OperationId_sinotransbooking_PerformanceSellerController_updateSellerContactor(
  body: API.CreateAndUpdateSellerContactRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/sinotransbooking/v1/seller/performances/update/seller-contactor', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSellerController_updateSellerContactor',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家工作台订单列表 GET /sinotransbooking/v1/seller/performances/workbench */
export async function OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrderWorkBench(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerPagePerformanceOrderWorkBenchParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderDetailWorkBenchVO>(
    '/sinotransbooking/v1/seller/performances/workbench',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrderWorkBench',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 卖家工作台按城市规整订单列表 POST /sinotransbooking/v1/seller/performances/workbench/sortByCity */
export async function OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrderWorkBenchSortByCity(
  body: API.PerformanceQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailWorkBenchSortByCityVO[]>(
    '/sinotransbooking/v1/seller/performances/workbench/sortByCity',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrderWorkBenchSortByCity',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家工作台按时间规整订单列表 GET /sinotransbooking/v1/seller/performances/workbench/sortByTime */
export async function OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrderWorkBenchSortByTime(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSellerControllerPagePerformanceOrderWorkBenchSortByTimeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailWorkBenchSortByTimeVO>(
    '/sinotransbooking/v1/seller/performances/workbench/sortByTime',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceSellerController_pagePerformanceOrderWorkBenchSortByTime',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}
