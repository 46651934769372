// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取城市列表 GET /salesgateway/dict/cities */
export async function OperationId_salesgateway_DictController_listCities(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerListCitiesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityVo[]>('/salesgateway/dict/cities', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_listCities',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取城市（带国家）列表 批量获取城市（带国家）列表 GET /salesgateway/dict/cities/union/countries */
export async function OperationId_salesgateway_DictController_listCityWithCountry(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerListCityWithCountryParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityWithCountryVo[]>('/salesgateway/dict/cities/union/countries', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_listCityWithCountry',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取箱型列表 POST /salesgateway/dict/container-models */
export async function OperationId_salesgateway_DictController_listContainerModels(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerListContainerModelsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerModelDictVo[]>('/salesgateway/dict/container-models', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_listContainerModels',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}

/** 获取大陆板块列表 GET /salesgateway/dict/continents */
export async function OperationId_salesgateway_DictController_listContinents(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerListContinentsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContinentVo[]>('/salesgateway/dict/continents', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_listContinents',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取国家列表 GET /salesgateway/dict/countries */
export async function OperationId_salesgateway_DictController_listCountries(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerListCountriesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CountryVo[]>('/salesgateway/dict/countries', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_listCountries',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 根据addressId获取地址信息 GET /salesgateway/dict/getAddInfoById */
export async function OperationId_salesgateway_DictController_getAddInfoById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerGetAddInfoByIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<string>('/salesgateway/dict/getAddInfoById', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_getAddInfoById',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取大陆/国家/省/城市 GET /salesgateway/dict/getAddress */
export async function OperationId_salesgateway_DictController_getAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerGetAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PageAddressResponseVO>('/salesgateway/dict/getAddress', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_getAddress',
    },
    params: {
      ...params,
      pageAddressRequestVO: undefined,
      ...params['pageAddressRequestVO'],
    },
    ...(rest || {}),
  });
}

/** 获取费用项 GET /salesgateway/dict/getExcharge */
export async function OperationId_salesgateway_DictController_getExcharge(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDictControllerGetExchargeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PageExpenseResponseVO>('/salesgateway/dict/getExcharge', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DictController_getExcharge',
    },
    params: {
      ...params,
      pageExpenseRequestVO: undefined,
      ...params['pageExpenseRequestVO'],
    },
    ...(rest || {}),
  });
}
