// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询报关商品 GET /salesgateway/v1/declaration/seller/items */
export async function OperationId_salesgateway_DeclarationSellerProductController_pageItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationSellerProductControllerPageItemParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageDeclarationItemVO>('/salesgateway/v1/declaration/seller/items', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationSellerProductController_pageItem',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 报关商品修改 PUT /salesgateway/v1/declaration/seller/items */
export async function OperationId_salesgateway_DeclarationSellerProductController_updateItem(
  body: API.DeclarationItemUpdateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/declaration/seller/items', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationSellerProductController_updateItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 报关商品创建 POST /salesgateway/v1/declaration/seller/items */
export async function OperationId_salesgateway_DeclarationSellerProductController_createItem(
  body: API.DeclarationItemCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/v1/declaration/seller/items', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationSellerProductController_createItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取商品详情 GET /salesgateway/v1/declaration/seller/items/${param0} */
export async function OperationId_salesgateway_DeclarationSellerProductController_getItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationSellerProductControllerGetItemParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DeclarationItemVO>(`/salesgateway/v1/declaration/seller/items/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationSellerProductController_getItem',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 上架/下架 PUT /salesgateway/v1/declaration/seller/items/active-status/${param0}/${param1} */
export async function OperationId_salesgateway_DeclarationSellerProductController_onOrOffSaleItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationSellerProductControllerOnOrOffSaleItemParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, activeStatus: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/v1/declaration/seller/items/active-status/${param0}/${param1}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationSellerProductController_onOrOffSaleItem',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 审核商品 PUT /salesgateway/v1/declaration/seller/items/audit-status */
export async function OperationId_salesgateway_DeclarationSellerProductController_auditItem(
  body: API.AuditRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/declaration/seller/items/audit-status', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationSellerProductController_auditItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看商品日志 GET /salesgateway/v1/declaration/seller/items/item-logs */
export async function OperationId_salesgateway_DeclarationSellerProductController_pageItemLog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationSellerProductControllerPageItemLogParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageItemOperationLogDTO>(
    '/salesgateway/v1/declaration/seller/items/item-logs',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationSellerProductController_pageItemLog',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 查看商品版本 GET /salesgateway/v1/declaration/seller/items/item-versions */
export async function OperationId_salesgateway_DeclarationSellerProductController_pageItemVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationSellerProductControllerPageItemVersionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageDeclarationItemVO>(
    '/salesgateway/v1/declaration/seller/items/item-versions',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationSellerProductController_pageItemVersion',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 启用/禁用 PUT /salesgateway/v1/declaration/seller/items/status/${param0}/${param1} */
export async function OperationId_salesgateway_DeclarationSellerProductController_enableItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationSellerProductControllerEnableItemParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, status: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/declaration/seller/items/status/${param0}/${param1}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationSellerProductController_enableItem',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}
