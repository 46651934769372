import { i18n } from '@easygo/i18n';

/** 集装箱类型 */
export enum CntrType {
  /** 干散箱 */
  DRY = 'CNTR_TYPE_DRY',
  /** 开顶箱 */
  OPEN_TOP = 'CNTR_TYPE_OPEN_TOP',
  /** 冷藏箱 */
  REFRIGERATOR = 'CNTR_TYPE_REFRIGERATOR',
  /** 双开门箱 */
  DOUBLE_DOOR = 'CNTR_TYPE_DOUBLE_DOOR',
}

/** 堆场库容状态 */
export enum YardCapacityType {
  /** 充足 */
  SUFFICIENT = 'YARD_CAPACITY_TYPE_SUFFICIENT',
  /** 紧张 */
  TIGHT = 'YARD_CAPACITY_TYPE_TIGHT',
  /** 爆满 */
  FULL = 'YARD_CAPACITY_TYPE_FULL',
}

/** 空重箱类型 */
export enum CntrEmptyType {
  /** 空箱 */
  EMPTY = 'CNTR_EMPTY_TYPE_EMPTY',
  /** 重箱 */
  WEIGHT = 'CNTR_EMPTY_TYPE_WEIGHT',
}

/** 箱尺寸类型 */
export enum CntrSizeType {
  /** 20尺寸 */
  SIZE_20 = 'CNTR_SIZE_TYPE_20',
  /** 40尺寸 */
  SIZE_40 = 'CNTR_SIZE_TYPE_40',
  /** 45尺寸 */
  SIZE_45 = 'CNTR_SIZE_TYPE_45',
}

/** 合作意向 */
export enum RequireType {
  /** 发布堆场需求 */
  RELEASE = 'REQUIRE_TYPE_RELEASE',
  /** 堆场申请入驻 */
  SETTLE_IN = 'REQUIRE_TYPE_SETTLE_IN',
  /** 其他合作 */
  OTHER = 'REQUIRE_TYPE_OTHER',
  /** 预约堆场 */
  SUBSCRIBE = 'REQUIRE_TYPE_SUBSCRIBE_YARD',
}

export enum YardTransportType {
  HIGHWAY = 'TRANSPORT_TYPE_HIGHWAY',
  RAILWAY = 'TRANSPORT_TYPE_RAILWAY',
  SHIP = 'TRANSPORT_TYPE_SHIP',
}

export enum YardConditionType {
  TRUE = 'GROUND_CONDITION_TYPE_TRUE',
  FALSE = 'GROUND_CONDITION_TYPE_FALSE',
}

export enum YardSideOffsetType {
  YES = 'SIDE_OFFSET_TYPE_YES',
  NO = 'SIDE_OFFSET_TYPE_NO',
}

export enum YardEquipmentType {
  REACH_STACKER = 'EQUIPMENT_TYPE_REACH_STACKER',
  CRANE = 'EQUIPMENT_TYPE_CRANE',
  STACKER = 'EQUIPMENT_TYPE_STACKER',
  TRAILER = 'EQUIPMENT_TYPE_TRAILER',
  FORKLIFT = 'EQUIPMENT_TYPE_FORKLIFT',
  OTHER = 'EQUIPMENT_TYPE_OTHER',
}

export enum SortRule {
  // 默认排序
  DEFAULT = '1',
  // 堆位充足
  ENOUGH = '2',
  // 价格优先
  CHEAP = '3',
  // 最新发布
  RECENTLY = '4',
}

/**货币简称 */
export enum YardCurrencyAbbr {
  /** 人民币 */
  CURRENCY_TYPE_RMB = 'CURRENCY_TYPE_RMB',
  /** 美元 */
  CURRENCY_TYPE_DOLLAR = 'CURRENCY_TYPE_DOLLAR',
}

export const YardCurrencyIcon = {
  [YardCurrencyAbbr.CURRENCY_TYPE_RMB]: '¥',
  [YardCurrencyAbbr.CURRENCY_TYPE_DOLLAR]: '$',
};

/** 库存状态 */
export enum YardInventory {
  /** 无库存 */
  INVENTORY_LT_ZERO = 'INVENTORY_LT_ZERO',
  /** 有库存 */
  INVENTORY_GT_ZERO = 'INVENTORY_GT_ZERO',
}

/** 港区服务 */
export enum PortAreaServices {
  /** 堆存 */
  PORT_AREA_SERVICE_STORAGE = 'PORT_AREA_SERVICE_STORAGE',
  /** 修洗 */
  PORT_AREA_SERVICE_REPAIR_AND_WASHING = 'PORT_AREA_SERVICE_REPAIR_AND_WASHING',
}

export const PortAreaServicesType = {
  [PortAreaServices.PORT_AREA_SERVICE_STORAGE]: i18n?.t('堆存服务'),
  [PortAreaServices.PORT_AREA_SERVICE_REPAIR_AND_WASHING]: i18n?.t('修洗服务'),
};

/** 堆场服务 */
export enum YardServices {
  /** 堆存 */
  YARD_SERVICE_TYPE_STORAGE = 'YARD_SERVICE_TYPE_STORAGE',
  /** 修洗 */
  YARD_SERVICE_TYPE_REPAIR_AND_WASHING = 'YARD_SERVICE_TYPE_REPAIR_AND_WASHING',
}

export const YardServicesType = {
  [YardServices.YARD_SERVICE_TYPE_STORAGE]: i18n?.t('堆存服务'),
  [YardServices.YARD_SERVICE_TYPE_REPAIR_AND_WASHING]: i18n?.t('修洗服务'),
};

/** 堆场标记 */
export enum YardStar {
  /** 未标记 */
  TERRIBLE,
  /** 1星 */
  BAD,
  /** 2星 */
  NORMAL,
  /** 3星 */
  GOOD,
}

/** 港区类型 */
export enum PortAreaType {
  /** 海港港区 */
  HARBOUR = 'PORT_AREA_TYPE_HARBOUR',
  /** 陆港港区 */
  LAND_PORT = 'PORT_AREA_TYPE_LAND_PORT',
}
/** 港区类型 */
export const PortAreaTypeOptions = {
  [PortAreaType.HARBOUR]: i18n?.t('海港港区'),
  [PortAreaType.LAND_PORT]: i18n?.t('陆港港区'),
};

/** 港区状态 */
export enum PortAreaStatus {
  /** 启用 */
  ENABLE = 'PORT_AREA_STATUS_ENABLE',
  /** 禁用 */
  DISABLE = 'PORT_AREA_STATUS_DISABLE',
}
/** 港区状态 */
export const PortAreaStatusOptions = {
  [PortAreaStatus.ENABLE]: i18n?.t('启用'),
  [PortAreaStatus.DISABLE]: i18n?.t('禁用'),
};

/** 退款状态 */
export enum RefundStatusEnum {
  /** 未退款 */
  INIT = 'AFTERSALE_REFUND_STATUS_INIT',
  /** 退款中 */
  PROCESSING = 'AFTERSALE_REFUND_STATUS_PROCESSING',
  /** 退款完成 */
  FINISH = 'AFTERSALE_REFUND_STATUS_FINISH',
  /** 退款失败 */
  FAIL = 'AFTERSALE_REFUND_STATUS_FAIL',
}
/** 退款状态 */
export const RefundStatusOption = {
  [RefundStatusEnum.INIT]: '未退款',
  [RefundStatusEnum.PROCESSING]: '退款中',
  [RefundStatusEnum.FINISH]: '退款完成',
  [RefundStatusEnum.FAIL]: '退款失败',
};

/** 付款方式 */
export enum SettlementType {
  /** 账期 */
  PERIOD = 'SETTLEMENT_TYPE_PERIOD',
  /** 现结 */
  ONCE = 'SETTLEMENT_TYPE_ONCE',
}
/** 付款方式 */
export const SettlementTypeTxt = {
  [SettlementType.PERIOD]: '账期',
  [SettlementType.ONCE]: '现结',
};
