// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 集装箱租赁业务订单列表 集装箱租赁业务的待支付(未支付、部分支付)订单查询 GET /salesgateway/workbench-seller/api/container-order */
export async function OperationId_salesgateway_SellerWorkbenchController_containerOrder(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.SellerContainerResponseVo>(
    '/salesgateway/workbench-seller/api/container-order',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_SellerWorkbenchController_containerOrder',
      },
      ...(rest || {}),
    },
  );
}

/** 卖家 租箱 GET /salesgateway/workbench-seller/api/container-summary */
export async function OperationId_salesgateway_SellerWorkbenchController_getBuyerContainerSummary(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.SellerContainerSummerMapVO>(
    '/salesgateway/workbench-seller/api/container-summary',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_SellerWorkbenchController_getBuyerContainerSummary',
      },
      ...(rest || {}),
    },
  );
}

/** 获取卖家询报价单 POST /salesgateway/workbench-seller/api/getDemandInfos */
export async function OperationId_salesgateway_SellerWorkbenchController_getSellerDemands(
  body: API.DemandListRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DemandWithMsgTimeVO[]>('/salesgateway/workbench-seller/api/getDemandInfos', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_SellerWorkbenchController_getSellerDemands',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 今日交易信息 GET /salesgateway/workbench-seller/api/getTradingInfo */
export async function OperationId_salesgateway_SellerWorkbenchController_getTradingInfo(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<Record<string, any>>('/salesgateway/workbench-seller/api/getTradingInfo', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_SellerWorkbenchController_getTradingInfo',
    },
    ...(rest || {}),
  });
}

/** 卖家工作台获取海运任务信息 GET /salesgateway/workbench-seller/api/shipping-task */
export async function OperationId_salesgateway_SellerWorkbenchController_getShippingTask(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.ShippingSellerResponseVo>('/salesgateway/workbench-seller/api/shipping-task', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_SellerWorkbenchController_getShippingTask',
    },
    ...(rest || {}),
  });
}
