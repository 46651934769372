// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 堆场企业绑定关系 POST /salesgateway/yard/enterprise-relation/bind */
export async function OperationId_salesgateway_YardEnterpriseRelationController_bindYardEnterpriseRelation(
  body: API.YardEnterpriseBindRelationRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/yard/enterprise-relation/bind', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_YardEnterpriseRelationController_bindYardEnterpriseRelation',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 分页查询堆场 POST /salesgateway/yard/enterprise-relation/page-yard */
export async function OperationId_salesgateway_YardEnterpriseRelationController_pageYard(
  body: API.PageYardRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PageYardResponse>('/salesgateway/yard/enterprise-relation/page-yard', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardEnterpriseRelationController_pageYard',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 分页查询堆场企业绑定关系 POST /salesgateway/yard/enterprise-relation/page/query */
export async function OperationId_salesgateway_YardEnterpriseRelationController_pageYardEnterpriseRelation(
  body: API.YardEnterpriseRelationQueryRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.YardEnterpriseRelationQueryResponse>(
    '/salesgateway/yard/enterprise-relation/page/query',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_YardEnterpriseRelationController_pageYardEnterpriseRelation',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 堆场企业解除绑定关系 POST /salesgateway/yard/enterprise-relation/unbind */
export async function OperationId_salesgateway_YardEnterpriseRelationController_unbindYardEnterpriseRelation(
  body: API.YardEnterpriseBindRelationRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/yard/enterprise-relation/unbind', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_YardEnterpriseRelationController_unbindYardEnterpriseRelation',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
