import type { UploadFile } from 'antd';
import { Upload, message } from 'antd';
import { i18n } from '@easygo/i18n';
import FileSaver from 'file-saver';
import axios from 'axios';
import JSZip from 'jszip';

const extname = (url: string = '') => {
  const temp = url.split('/');
  const filename = temp[temp.length - 1];
  const filenameWithoutSuffix = filename.split(/#|\?/)[0];
  return (/\.[^./\\]*$/.exec(filenameWithoutSuffix) || [''])[0];
};
// 编辑状态upload组件回显 根据name 获取有效的附件资源地址
export const formatFilePreviewUrl = (url: string) => {
  const [before] = url.split('?X');
  const splitArr = before.split('/');
  const name = splitArr[splitArr.length - 1];
  return name;
};
/**
 *
 * @param fileUrl 文件Url
 * @returns
 */
export const isImageUrl = (fileUrl: string): boolean => {
  const extension = extname(fileUrl);
  if (/^data:image\//.test(fileUrl) || /(webp|svg|png|gif|jpg|jpeg|jfif|bmp|dpg|ico)$/i.test(extension)) {
    return true;
  }
  if (/^data:/.test(fileUrl)) {
    // other file types of base64
    return false;
  }
  if (extension) {
    // other file types which have extension
    return false;
  }
  return true;
};

/**
 * 导出文件
 * @param api {any} 文件导出接口
 * @param param  如果接口只有一个option可选参数，则需要在这里传入{ responseType: 'blob' }
 * @param fileName {string} 导出文件名
 * @returns
 */
export const exportFile = async (api: any, param: any, fileName: string) => {
  const content = await api(param, {
    responseType: 'blob',
  });
  const blob = new window.Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

  const url = URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  a.click();

  URL.revokeObjectURL(url);
};

/**
 * 下载文件
 * @param {any} data
 * @param {string} type 文件类型
 * @param {string} filename 文件名
 */
export function downloadFile(data: any, filename: string = i18n?.t('文件.xlsx'), type: string = 'text/plain;charset=utf-8') {
  let blob = new Blob([data], { type: type });
  let URL = window.URL || window.webkitURL;
  let src = URL.createObjectURL(blob);
  // 下载文件
  if ('download' in document.createElement('a')) {
    // 非IE下载
    if (src) {
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = src;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(src);
    }
  } else {
    (navigator as any).msSaveBlob(blob, filename);
  }
}

// 格式化上传的文件数据结构
export type TFileFormat = {
  name: string;
  uid: number;
  url: string;
  type: string;
};
export const getFileFormat = (url?: string): TFileFormat[] => {
  if (!url) return [];
  try {
    return [{ name: url, url: url, uid: Math.random(), type: 'image/png' }];
  } catch (error) {
    console.log(error);
    return [];
  }
};
//上传文件时beforeUpload钩子校验文件类型与大小
export const fileLimited = (file: UploadFile, option: { accept?: string; size?: number }) => {
  const fileTypes = option.accept?.replaceAll('.', '').split(',');
  if (fileTypes) {
    const isNotInTypes = fileTypes.every((type) => !file.type?.includes(type));
    if (isNotInTypes) {
      message.error(i18n?.t('仅支持文件格式：{{fileTypes}}格式附件', { fileTypes }));
    }
    if (isNotInTypes) return Upload.LIST_IGNORE;
  }
  if (option.size) {
    const isLtSize = (file.size ?? 0) / 1024 / 1024 <= option.size;
    if (!isLtSize) {
      message.error(i18n?.t('文件不允许超过{{size}}M', { size: option.size }));
    }
    if (!isLtSize) return Upload.LIST_IGNORE;
  }
};
// 格式化下载的文件数据结构
export type TFile = {
  name: string;
  uid: string;
  type: string;
} & File;
export const getFileObjFromStr = (file?: string): TFile[] => {
  if (!file) return [];
  try {
    return Array.isArray(JSON.parse(file)) ? JSON.parse(file) : [JSON.parse(file)];
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * @method 图片请求-下载
 * @param url
 * @returns
 */
const getFile = (url: string) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer',
      withCredentials: false,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error.toString());
      });
  });
};

/**
 * @method 批量下载转压缩包
 * @param fileList
 */
export const handleBatchDownload = async (fileList: string[], fileName?: string) => {
  const data = fileList;
  const zip = new JSZip();
  const cache: any = {};
  const promises: any = [];

  await data.forEach((item) => {
    const promise = getFile(item).then((data) => {
      let arr = item.split('/');
      let file_name = arr[arr.length - 1];
      try {
        file_name = decodeURIComponent(file_name);
      } catch (error) {}
      zip.file(file_name, data, { binary: true });
      cache[file_name] = data;
    });
    promises.push(promise);
  });
  Promise.all(promises).then(() => {
    zip
      .generateAsync({ type: 'blob' })
      .then((content) => {
        FileSaver.saveAs(content, fileName || '文件' + '.zip');
      })
      .catch((err) => {
        message.error('下载失败，请稍后再试！');
        throw err;
      });
  });
};
