// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 港区详情标准价 GET /salesgateway/port-area/${param0}/standard-price */
export async function OperationId_salesgateway_PortAreaController_standardPrice(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPortAreaControllerStandardPriceParams,
  options?: { [key: string]: any },
) {
  const { portAreaId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StandardPriceDetailVO[]>(`/salesgateway/port-area/${param0}/standard-price`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PortAreaController_standardPrice',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}
