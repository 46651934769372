// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 消息中心 修改消息状态 PUT /salesgateway/v2/message/confirmMessage */
export async function OperationId_salesgateway_MessageControllerV2_confirmMessage(
  body: API.MessageConfirmReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/v2/message/confirmMessage', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MessageControllerV2_confirmMessage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 消息中心 消息中心列表 POST /salesgateway/v2/message/queryMessage */
export async function OperationId_salesgateway_MessageControllerV2_queryMessage(
  body: API.MessageListReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MessageResponseVO>('/salesgateway/v2/message/queryMessage', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MessageControllerV2_queryMessage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 未处理消息统计 统计各类型未处理的消息数量 GET /salesgateway/v2/message/unprocessed/count */
export async function OperationId_salesgateway_MessageControllerV2_unprocessedCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.UnprocessedMessageCountResponseVo>(
    '/salesgateway/v2/message/unprocessed/count',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_MessageControllerV2_unprocessedCount',
      },
      ...(rest || {}),
    },
  );
}
