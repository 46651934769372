import { i18n } from '@easygo/i18n';
/** 公司名称展示方式 */
export enum COMPANY_NAME_SHOW_TYPE {
  /**真实全称 */
  REAL = '2',
  /**匿名 */
  ALIAS = '1',
}
export const OrderStatusLabel = {
  ACTION_TYPE_CREATE: i18n?.t('待接单'),
  ACTION_TYPE_CONFIRM: '待确认',
  ACTION_TYPE_ACCEPT: i18n?.t('已接单'),
  ACTION_TYPE_CONFIRM_BOOKING: i18n?.t('已放舱'),
  ACTION_TYPE_ALREADY_PACKING: i18n?.t('已装箱'),
  ACTION_TYPE_ENTER_STATION: i18n?.t('已进站'),
  ACTION_TYPE_ALREADY_DECLARATION: i18n?.t('已报关'),
  ACTION_TYPE_ALREADY_DEPARTURE: i18n?.t('已发车'),
  ACTION_TYPE_ALREADY_ARRIVAL: i18n?.t('已到站'),
  ACTION_TYPE_COMPLETE: i18n?.t('已完成'),
  ACTION_TYPE_CANCEL: i18n?.t('已取消'),
  ACTION_TYPE_REJECT: i18n?.t('已取消'),
  ACTION_TYPE_FORCE_CLOSE: i18n?.t('已取消'),
};

// 仅租箱类型
export const OrderStatusToContainerLabel = {
  ACTION_TYPE_CONFIRM: '待确认',
  ACTION_TYPE_CREATE: i18n?.t('待接单'),
  ACTION_TYPE_ACCEPT: i18n?.t('已接单'),
  ACTION_TYPE_COMPLETE: i18n?.t('已完成'),
  ACTION_TYPE_CANCEL: i18n?.t('已取消'),
  ACTION_TYPE_REJECT: i18n?.t('已取消'),
  ACTION_TYPE_FORCE_CLOSE: i18n?.t('已取消'),
};

export const TrainCargoStatusOnline = {
  ACTION_TYPE_ALREADY_PACKING: '正在前往起运城市运输中',
  ACTION_TYPE_ENTER_STATION: '已到达起运城市',
  ACTION_TYPE_ALREADY_DEPARTURE: '正在前往目的城市运输中',
  ACTION_TYPE_ALREADY_ARRIVAL: '已到达目的城市',
};
export const CompanyNameShowType = {
  [COMPANY_NAME_SHOW_TYPE.REAL]: 'REAL_COMPANY_NAME',
  [COMPANY_NAME_SHOW_TYPE.ALIAS]: 'ALIAS_COMPANY_NAME',
};

/** 进出口字段，进口还是出口 */
export enum IMPORT_OR_EXPORT {
  /** 进口 */
  IMPORT = '0',
  /** 出口 */
  EXPORT = '1',
}

export const ImportOrExport = {
  [IMPORT_OR_EXPORT.IMPORT]: 'TRADE_IMPORT',
  [IMPORT_OR_EXPORT.EXPORT]: 'TRADE_EXPORT',
};

export enum SaleMode {
  PRE_SALE = 'PRE_SALE',
  ON_SALE = 'ON_SALE',
}

export const saleModes = {
  [SaleMode.PRE_SALE]: i18n?.t('预售'),
  [SaleMode.ON_SALE]: i18n?.t('现舱'),
};

/**
 * 持箱人
 * 班列公司-1 卖家都是-2
 */
export enum RAILWAY_HOLDBOX_PEOPLE {
  /** 班列公司 */
  TRAINS_COMPANY = '1',
  /** 卖家 */
  SALER = '2',
}

export const RailwayHoldBoxPeople = {
  [RAILWAY_HOLDBOX_PEOPLE.TRAINS_COMPANY]: 'HOLDBOX_TYPE_TRAINS_COMPANY',
  [RAILWAY_HOLDBOX_PEOPLE.SALER]: 'HOLDBOX_TYPE_SALER',
};

/** 附件费计费方式 */
export enum COST_TYPE {
  /** 每票 */
  ORDER = '1',
  /** 每柜 */
  BOX = '2',
}

export const CostType = {
  [COST_TYPE.ORDER]: 'MEIPIAO',
  [COST_TYPE.BOX]: 'MEIGUI',
};

export const enum SHOW_PRICE {
  FIXED = '1',
  NEGOTIATE = '2',
  NO_SHOW = '3',
}

export const enum IS_COMPANY_ALIAS_SHOW {
  SHOW = 1,
  NO_SHOW = 2,
}

export enum FREEZE_STATUS {
  YES = '2',
  NO = '1',
}

export const FreezeStatus = {
  [FREEZE_STATUS.YES]: 'WHETHER_TYPE_YES',
  [FREEZE_STATUS.NO]: 'WHETHER_TYPE_NO',
};

export enum RAILWAY_PERFORMANCE_EXCEPTION_TYPE {
  UNKNOWN = 'RAILWAY_PERFORMANCE_EXCEPTION_TYPE_UNKNOWN',
  /**偏重偏载 */
  OVERWEIGHT = 'RAILWAY_PERFORMANCE_EXCEPTION_TYPE_OVERWEIGHT',
  /**集重 */
  SET_HEAVY = 'RAILWAY_PERFORMANCE_EXCEPTION_TYPE_SET_HEAVY',
  /**海关查验 */
  CUSTOMS_CHECK = 'RAILWAY_PERFORMANCE_EXCEPTION_TYPE_CUSTOMS_CHECK',
  /**箱况异常 */
  BOX_EXCEPTION = 'RAILWAY_PERFORMANCE_EXCEPTION_TYPE_BOX_EXCEPTION',
  /**资料异常 */
  MATERIALS = 'RAILWAY_PERFORMANCE_EXCEPTION_TYPE_MATERIALS',
  /**其他 */
  OTHER = 'RAILWAY_PERFORMANCE_EXCEPTION_TYPE_OTHER',
}

export const RailwayPerformanceExceptionType = {
  [RAILWAY_PERFORMANCE_EXCEPTION_TYPE.OVERWEIGHT]: i18n?.t('偏重偏载'),
  [RAILWAY_PERFORMANCE_EXCEPTION_TYPE.SET_HEAVY]: i18n?.t('集重'),
  [RAILWAY_PERFORMANCE_EXCEPTION_TYPE.CUSTOMS_CHECK]: i18n?.t('海关查验'),
  [RAILWAY_PERFORMANCE_EXCEPTION_TYPE.BOX_EXCEPTION]: i18n?.t('箱况异常'),
  [RAILWAY_PERFORMANCE_EXCEPTION_TYPE.MATERIALS]: i18n?.t('资料异常'),
  [RAILWAY_PERFORMANCE_EXCEPTION_TYPE.OTHER]: i18n?.t('其他'),
};

export enum BILL_FEE_TYPE {
  UNKNOWN = 'PERFORMANCE_BILL_TYPE_UNKNOWN',
  /** 运费 */
  FREIGHT = 'PERFORMANCE_BILL_TYPE_FREIGHT',
  /** 附加费 */
  EXTRA_FEE = 'PERFORMANCE_BILL_TYPE_EXTRA_FEE',
  /** 定金 */
  DEPOSIT = 'PERFORMANCE_BILL_TYPE_DEPOSIT',
}

export enum RailwayPickUpStatus {
  /** 未提箱 */
  NOT = '1',
  /** 提箱中--总共提箱数量>已经提箱数量 */
  ING = '2',
  /** 提箱完成--总共提箱数量==已经提箱数量 */
  FINISH = '3',
  /** 客户确认已经提箱 */
  CONFIRM_PICKUPS = '4',
  /** 取消提箱 */
  CANCEL_PICKUPS = '5',
}

export const RailwayPickUpStatusLabel = {
  [RailwayPickUpStatus.NOT]: 'SUTICASE',
  [RailwayPickUpStatus.ING]: 'SUTICASE',
  [RailwayPickUpStatus.FINISH]: 'SUTICASE',
  [RailwayPickUpStatus.CONFIRM_PICKUPS]: 'SUTICASE',
  [RailwayPickUpStatus.CANCEL_PICKUPS]: 'UN_SUTICASE',
};

export enum RailwayReturnBoxStatus {
  /** 申请预约还箱---客户还箱申请 */
  PREDROPOFFS = '1',
  /** 同意预约还箱、发放还箱单---还箱处理 */
  AGREEDROPOFFS = '2',
  /** 部分箱子确认已经还箱---上传还箱信息 */
  PARTCONFIRMDROPOFFS = '3',
  /** 全部箱子确认已经还箱---上传还箱信息 */
  ALLCONFIRMDROPOFFS = '4',
  /** 取消还箱单 */
  CANCEL_ROPOFFS = '5',
}

export const RailwayReturnBoxStatusLabel = {
  [RailwayReturnBoxStatus.PREDROPOFFS]: 'PREDROPOFFS',
  [RailwayReturnBoxStatus.AGREEDROPOFFS]: 'SUTICASE',
  [RailwayReturnBoxStatus.PARTCONFIRMDROPOFFS]: 'SUTICASE',
  [RailwayReturnBoxStatus.ALLCONFIRMDROPOFFS]: 'SUTICASE',
  [RailwayReturnBoxStatus.CANCEL_ROPOFFS]: 'UN_SUTICASE',
};

export const ServiceTypeLabel = {
  // ORDER_SERVICE_TRAIN_DOOR_TO_DOOR: i18n?.t('门到门'),
  ORDER_SERVICE_TRAIN_DOOR_TO_STATION: i18n?.t('门到站'),
  ORDER_SERVICE_TRAIN_STATION_TO_STATION: i18n?.t('站到站'),
  ORDER_SERVICE_TRAIN_ONLY_CONTAINER: '仅租箱',
};
export const PAY_STATUS = [
  { label: i18n?.t('已支付'), value: 'STATEMENT_PAY_STATUS_FINISHED' },
  { label: i18n?.t('部分支付'), value: 'STATEMENT_PAY_STATUS_PART' },
  { label: i18n?.t('待支付'), value: 'STATEMENT_PAY_STATUS_WAITING' },
];

export const TrainQuickSearch = {
  TOTAL_ORDER: 'TOTAL_ORDER',
  WAIT_CONFIRM: 'WAIT_CONFIRM',
  CONFIRMED: 'CONFIRMED',
  WAIT_ORDER: 'WAIT_ORDER',
  ORDERED: 'ORDERED',
  WAIT_CABIN: 'WAIT_CABIN',
  CABIN: 'CABIN',
  WAIT_BOX: 'WAIT_BOX',
  BOXED: 'BOXED',
  WAIT_STATION: 'WAIT_STATION',
  STATIONED: 'STATIONED',
  WAIT_CUSTOMS: 'WAIT_CUSTOMS',
  CUSTOMS: 'CUSTOMS',
  WAIT_DEPARTURE: 'WAIT_DEPARTURE',
  DEPARTURE: 'DEPARTURE',
  WAIT_ARRIVE: 'WAIT_ARRIVE',
  ARRIVE: 'ARRIVE',
  FINISH: 'FINISH',
  CANCEL: 'CANCEL',
};
