import type { ReactNode } from 'react';
import { Empty, Image } from 'antd';
import { useGlobalState } from './state';
import { useComponentTrans } from '../config';

export function AuthRoute({ resourceId, children }: { resourceId: string; children: ReactNode }) {
  const {
    state: { permiss, system },
  } = useGlobalState();
  const trans = useComponentTrans();
  return (
    <>
      {system === 1 || permiss?.includes(resourceId.replaceAll(/\/|\-/g, '_')) ? (
        children
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 56px)',
          }}
        >
          <Empty
            imageStyle={{ marginBottom: 24 }}
            description={trans('no_permiss')}
            image={<Image src="/images/status/noauth.svg" preview={false} alt="" />}
          />
        </div>
      )}
    </>
  );
}

export function NotFoundPage() {
  const trans = useComponentTrans();
  return (
    <Empty
      imageStyle={{ marginBottom: 24, marginTop: 200 }}
      description={trans('page_not_found')}
      image={<Image src="/images/status/404.svg" preview={false} alt="" />}
    />
  );
}
