// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 取消需求 POST /salesgateway/use-container/demand/api/${param0}/cancel */
export async function OperationId_salesgateway_UseContainerController_cancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayUseContainerControllerCancelParams,
  options?: { [key: string]: any },
) {
  const { backhaulDemandId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/use-container/demand/api/${param0}/cancel`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_UseContainerController_cancel',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询回货需求详情 GET /salesgateway/use-container/demand/api/${param0}/detail */
export async function OperationId_salesgateway_UseContainerController_findUseContainerDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayUseContainerControllerFindUseContainerDetailsParams,
  options?: { [key: string]: any },
) {
  const { backhaulDemandId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.FindUseContainerDetailsRes>(
    `/salesgateway/use-container/demand/api/${param0}/detail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_UseContainerController_findUseContainerDetails',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 新增回货需求 POST /salesgateway/use-container/demand/api/create */
export async function OperationId_salesgateway_UseContainerController_createUseContainer(
  body: API.CreateUseContainerReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CreateUseContainerRes>('/salesgateway/use-container/demand/api/create', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_UseContainerController_createUseContainer',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 编辑回货需求 PUT /salesgateway/use-container/demand/api/edit */
export async function OperationId_salesgateway_UseContainerController_editUseContainer(
  body: API.EditUseContainerReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EditUseContainerRes>('/salesgateway/use-container/demand/api/edit', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_UseContainerController_editUseContainer',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 历史用箱(回货)需求列表 POST /salesgateway/use-container/demand/api/list */
export async function OperationId_salesgateway_UseContainerController_useContainerListByPage(
  body: API.UseContainerListByPageReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UseContainerListByPageRes>('/salesgateway/use-container/demand/api/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_UseContainerController_useContainerListByPage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
