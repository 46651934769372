// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** (已废弃)获取对账单详情信息 GET /logisticssolution/v1/seller/statement/${param0} */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_getStatementDetailInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerGetStatementDetailInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderStatementDetailInfoVO>(
    `/logisticssolution/v1/seller/statement/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_getStatementDetailInfo',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 删除对账单 DELETE /logisticssolution/v1/seller/statement/${param0} */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_removeStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerRemoveStatementParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/logisticssolution/v1/seller/statement/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerPerformanceOrderStatementController_removeStatement',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 增加对账单退款记录 POST /logisticssolution/v1/seller/statement/addRefundRecord */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_addStatementRefundRecord(
  body: API.AddPerformanceOrderStatementRefundRecordRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AddPerformanceOrderStatementRefundRecordResponse>(
    '/logisticssolution/v1/seller/statement/addRefundRecord',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_addStatementRefundRecord',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 确认对账单 POST /logisticssolution/v1/seller/statement/confirm */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_confirmStatement(
  body: API.ConfirmPerformanceOrderStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/logisticssolution/v1/seller/statement/confirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerPerformanceOrderStatementController_confirmStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 分页查询对账单详情信息 POST /logisticssolution/v1/seller/statement/list */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_pageStatement(
  body: API.SalesSellerStatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderSellerStatementListItemVO>(
    '/logisticssolution/v1/seller/statement/list',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_pageStatement',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 修改对账单 POST /logisticssolution/v1/seller/statement/modify */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_modifyStatement(
  body: API.ModifyPerformanceOrderStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/logisticssolution/v1/seller/statement/modify', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerPerformanceOrderStatementController_modifyStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** (已废弃)修改对账单 POST /logisticssolution/v1/seller/statement/modify/deprecated */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_modifyStatementDeprecated(
  body: API.DeprecatedModifyPerformanceOrderStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/logisticssolution/v1/seller/statement/modify/deprecated', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerPerformanceOrderStatementController_modifyStatementDeprecated',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** (已废弃)新增对账单 POST /logisticssolution/v1/seller/statement/performance/${param0}/addStatement */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_addStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerAddStatementParams,
  body: API.AddPerformanceOrderStatementRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AddPerformanceOrderStatementResponse>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/addStatement`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_addStatement',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 制作对账单 POST /logisticssolution/v1/seller/statement/performance/${param0}/make */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_makeStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerMakeStatementParams,
  body: API.MakePerformanceOrderStatementRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MakePerformanceOrderStatementResponse>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/make`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_makeStatement',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** (已废弃)制作对账单 POST /logisticssolution/v1/seller/statement/performance/${param0}/make/deprecated */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_makeStatementDeprecated(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerMakeStatementDeprecatedParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DeprecatedMakePerformanceOrderStatementResponse>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/make/deprecated`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_makeStatementDeprecated',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 列表查询对账单信息 GET /logisticssolution/v1/seller/statement/performance/${param0}/statementList */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_listStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerListStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderSellerStatementListInfoVO>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/statementList`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_listStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取对账单制作详情信息 GET /logisticssolution/v1/seller/statement/performance/${param0}/statementMakeDetail */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_getStatementMakeDetailInfoByPerformanceId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerGetStatementMakeDetailInfoByPerformanceIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderStatementMakeDetailInfoVO>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/statementMakeDetail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_getStatementMakeDetailInfoByPerformanceId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** (已废弃)获取对账单制作详情信息 GET /logisticssolution/v1/seller/statement/performance/${param0}/statementMakeDetail/deprecated */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_getStatementMakeDetailInfoByPerformanceIdDeprecated(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerGetStatementMakeDetailInfoByPerformanceIdDeprecatedParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderStatementMakeDetailInfoVO>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/statementMakeDetail/deprecated`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_getStatementMakeDetailInfoByPerformanceIdDeprecated',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** (已废弃)去新增对账单 GET /logisticssolution/v1/seller/statement/performance/${param0}/toAddStatement */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_toAddStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerToAddStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ToAddPerformanceOrderStatementResponse>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/toAddStatement`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_toAddStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 去制作对账单 GET /logisticssolution/v1/seller/statement/performance/${param0}/toMake */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_toMakeStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerToMakeStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ToMakePerformanceOrderStatementResponse>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/toMake`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_toMakeStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 去修改对账单 GET /logisticssolution/v1/seller/statement/performance/${param0}/toModify */
export async function OperationId_logisticssolution_SellerPerformanceOrderStatementController_toModifyStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderStatementControllerToModifyStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ToModifyPerformanceOrderStatementResponse>(
    `/logisticssolution/v1/seller/statement/performance/${param0}/toModify`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderStatementController_toModifyStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
