// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询超期规则列表 GET /salesgateway/expiration-rule */
export async function OperationId_salesgateway_ExpirationRuleController_list(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayExpirationRuleControllerListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ExpirationRuleListResponseVo>('/salesgateway/expiration-rule', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ExpirationRuleController_list',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 创建超期规则 POST /salesgateway/expiration-rule */
export async function OperationId_salesgateway_ExpirationRuleController_create(
  body: API.ExpirationRuleVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/expiration-rule', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ExpirationRuleController_create',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 超期规则详情 GET /salesgateway/expiration-rule/${param0} */
export async function OperationId_salesgateway_ExpirationRuleController_detail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayExpirationRuleControllerDetailParams,
  options?: { [key: string]: any },
) {
  const { ruleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ExpirationRuleVo>(`/salesgateway/expiration-rule/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ExpirationRuleController_detail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 编辑超期规则 PUT /salesgateway/expiration-rule/${param0} */
export async function OperationId_salesgateway_ExpirationRuleController_update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayExpirationRuleControllerUpdateParams,
  body: API.ExpirationRuleVo,
  options?: { [key: string]: any },
) {
  const { ruleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/expiration-rule/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ExpirationRuleController_update',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 修改超期规则状态 PUT /salesgateway/expiration-rule/${param0}/change-status */
export async function OperationId_salesgateway_ExpirationRuleController_changeStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayExpirationRuleControllerChangeStatusParams,
  options?: { [key: string]: any },
) {
  const { ruleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/expiration-rule/${param0}/change-status`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ExpirationRuleController_changeStatus',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 超期规则历史版本列表 GET /salesgateway/expiration-rule/${param0}/history-list */
export async function OperationId_salesgateway_ExpirationRuleController_historyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayExpirationRuleControllerHistoryListParams,
  options?: { [key: string]: any },
) {
  const { ruleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ExpirationRuleSnapshotListResponseVo>(
    `/salesgateway/expiration-rule/${param0}/history-list`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ExpirationRuleController_historyList',
      },
      params: {
        ...queryParams,
        request: undefined,
        ...queryParams['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 根据全名称获取超期规则 GET /salesgateway/expiration-rule/search-full-name */
export async function OperationId_salesgateway_ExpirationRuleController_searchByName(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayExpirationRuleControllerSearchByNameParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ExpirationRuleVo>('/salesgateway/expiration-rule/search-full-name', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ExpirationRuleController_searchByName',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
