// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 买家获取申请发票详情 GET /invoice/v1/buyer/apply-invoice/detail/${param0} */
export async function OperationId_businessinvoice_BuyerApplyInvoiceController_getApplyInvoiceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinessinvoiceBuyerApplyInvoiceControllerGetApplyInvoiceDetailParams,
  options?: { [key: string]: any },
) {
  const { applyInvoiceDetailId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ApplyInvoiceDetailBO>(`/invoice/v1/buyer/apply-invoice/detail/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_BuyerApplyInvoiceController_getApplyInvoiceDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 根据订单获取相关发票信息 POST /invoice/v1/buyer/apply-invoice/get-by-order */
export async function OperationId_businessinvoice_BuyerApplyInvoiceController_getInvoiceInfoByOrderNos(
  body: API.GetInvoiceInfoRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BusinessOrderBO[]>('/invoice/v1/buyer/apply-invoice/get-by-order', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_businessinvoice_BuyerApplyInvoiceController_getInvoiceInfoByOrderNos',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家获取申请发票列表 POST /invoice/v1/buyer/apply-invoice/list */
export async function OperationId_businessinvoice_BuyerApplyInvoiceController_getApplyInvoiceList(
  body: API.ApplyInvoiceListRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageSurApplyInvoiceDTO>('/invoice/v1/buyer/apply-invoice/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_BuyerApplyInvoiceController_getApplyInvoiceList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 申请人民币发票 POST /invoice/v1/buyer/apply-invoice/rmb */
export async function OperationId_businessinvoice_BuyerApplyInvoiceController_applyInvoiceUsdRmb(
  body: API.ApplyInvoiceRmbRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/invoice/v1/buyer/apply-invoice/rmb', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_BuyerApplyInvoiceController_applyInvoiceUsdRmb',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 申请美元发票 POST /invoice/v1/buyer/apply-invoice/usd */
export async function OperationId_businessinvoice_BuyerApplyInvoiceController_applyInvoiceUsd(
  body: API.ApplyInvoiceUsdRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/invoice/v1/buyer/apply-invoice/usd', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_BuyerApplyInvoiceController_applyInvoiceUsd',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
