// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 履约动作 PUT /sinotransbooking/v1/seller/performances-declaration/${param0}/actions */
export async function OperationId_sinotransbooking_PerformanceDeclarationSellerController_performanceTrailerAction(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceDeclarationSellerControllerPerformanceTrailerActionParams,
  body: API.SellerPerformanceDeclarationActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/sinotransbooking/v1/seller/performances-declaration/${param0}/actions`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceDeclarationSellerController_performanceTrailerAction',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 报关拆分 PUT /sinotransbooking/v1/seller/performances-declaration/${param0}/items */
export async function OperationId_sinotransbooking_PerformanceDeclarationSellerController_declarationSplit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceDeclarationSellerControllerDeclarationSplitParams,
  body: API.PerformanceDeclarationSplitRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performances-declaration/${param0}/items`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_PerformanceDeclarationSellerController_declarationSplit',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
