export const CodeMap = {
  /**
   * 登录、入驻
   */
  '4001000000': 'INVALID_ARGUMENTS',
  '4002000000': 'INVALID_ARGUMENTS',
  '4042000000': 'INVALID_ARGUMENTS',
  '4031009000': 'PERMISSION_DENIED',
  '4092000000': 'CONFLICT',
  '5002000000': 'INVALID_ARGUMENTS',
  '5002000001': 'INTERNAL_UPSTREAM_ERROR',
  '5002005001': 'FAIL_GENERATE_CODE',
  '5002005002': 'INVALID_GENERATE_CODE',
  '4001000001': 'DUPLICATED_IDENTIFIER',
  '4001000002': 'INVALID_CIPHER',
  '4001000005': 'INVALID_TOKEN',
  '4031000001': 'INVALID_PASSWORD',
  '4031000002': 'LOGIN_FAIL',
  '4031000003': 'NO_PASSWORD',
  '4091000001': 'ROLE_PERMISSION_TYPE_CONFLICT',
  '4001000003': 'VALIDATE_CODE_VALID',
  '4091000002': 'SOCIAL_ALREADY_BIND',
  //联系人手机号与邮箱不能同时为空
  '4002000001': 'CONTACT_MOBILE_EMAIL_ALL_EMPTY',
  //营业执照未提交
  '4002000002': 'LICENCE_URL_EMPTY',
  //法人姓名未填写
  '4002000003': 'LEGAL_PERSON_NAME_EMPTY',
  //法人手机号未填写
  '4002000004': 'LEGAL_PERSON_MOBILE_EMPTY',
  //法人身份证号未填写
  '4002000005': 'LEGAL_PERSON_IDENTITY_EMPTY',
  //法人身份证照片未提交
  '4002000006': 'LEGAL_PERSON_IDENTITY_IMAGE_EMPTY',
  //企业证明文件未提交
  '4002000007': 'SUPPORT_DOCUMENT_EMPTY',
  //不支持的企业注册地
  '4002000008': 'UNSUPPORTED_REGISTRY_AREA',
  //企业统一信用代码和名称未填写
  '4002000009': 'CODE_NAME_ALL_EMPTY',
  //合同ID未提交（创建企业时，前端未传递合同ID，一般情况下不会出现这种情况）
  '4002000010': 'CONTRACT_FLOW_ID_EMPTY',
  //协议还未签署（创建企业时）
  '4032000001': 'NO_CONTRACT_FORBIDDEN',
  //当前组织下存在账号（删除组织时）
  '4032000002': 'ORGANIZATION_HAS_ACCOUNTS',
  //当前组织下存在子级组织（删除组织时）
  '4032000003': 'ORGANIZATION_HAS_CHILDREN',
  //OCR识别失败
  '5002000005': 'OCR_ERROR',
  //商品未找到（查询商品时）
  '4042000001': 'PRODUCT_NOT_FOUND',
  //企业信息未找到（查询企业时）
  '4042000002': 'ENTERPRISE_NOT_FOUND',
  //库存信息未找到（查询商品时）
  '4042000003': 'INVENTORY_NOT_FOUND',
  //未绑定银行卡（下单时）
  '4042000004': 'UNBOUND_BANK_CARD',
  //railway_fail_create_order_code
  '5002005003': 'RAILWAY_FAIL_CREATE_ORDER_CODE',
  //json解析失败!
  '5002005004': 'JSON_PARSE_ERROR_CODE',
  //amount or rate is null.
  '5003005001': 'AMOUNT_RATE_ISNULL_CODE',
};
