// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询境外用箱(回货)线路详情 GET /salesgateway/use-container/line/api/${param0}/detail */
export async function OperationId_salesgateway_UseContainerLineController_findForeignUseBoxLineDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayUseContainerLineControllerFindForeignUseBoxLineDetailsParams,
  options?: { [key: string]: any },
) {
  const { backhaulRouteId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.FindUseContainerLineDetailsRes>(
    `/salesgateway/use-container/line/api/${param0}/detail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_UseContainerLineController_findForeignUseBoxLineDetails',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 线路列表 POST /salesgateway/use-container/line/api/list */
export async function OperationId_salesgateway_UseContainerLineController_foreignUseBoxLineListByPage(
  body: API.UseContainerListLineByPageReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UseContainerLineListByPageRes>('/salesgateway/use-container/line/api/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_UseContainerLineController_foreignUseBoxLineListByPage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
