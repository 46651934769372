// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 关闭支付单 PUT /salesgateway/cashier/api/closePayment/${param0} */
export async function OperationId_salesgateway_CashierController_closePayment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierControllerClosePaymentParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/cashier/api/closePayment/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_closePayment',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 卖家-收款确认-确认已收款按钮 POST /salesgateway/cashier/api/confirmCollection */
export async function OperationId_salesgateway_CashierController_confirmCollection(
  body: API.UpdatePaymentOrderStatusReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/confirmCollection', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_confirmCollection',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 收银台详情 GET /salesgateway/cashier/api/getDetail */
export async function OperationId_salesgateway_CashierController_getCashierDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierControllerGetCashierDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetCashierDetailResponseVo>('/salesgateway/cashier/api/getDetail', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_getCashierDetail',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 买家端-付款确认填写 POST /salesgateway/cashier/api/payConfirm */
export async function OperationId_salesgateway_CashierController_payConfirm(
  body: API.PayReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/payConfirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_payConfirm',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家端-付款确认填写前信息获取 GET /salesgateway/cashier/api/payConfirmDetail/${param0} */
export async function OperationId_salesgateway_CashierController_payConfirmDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierControllerPayConfirmDetailParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PayConfirmDetail>(`/salesgateway/cashier/api/payConfirmDetail/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_payConfirmDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 支付验证码 GET /salesgateway/cashier/api/payGenerateCode */
export async function OperationId_salesgateway_CashierController_payGenerateCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierControllerPayGenerateCodeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/payGenerateCode', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_payGenerateCode',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 收银台-账期付-去还款 GET /salesgateway/cashier/api/payOnAccount/${param0} */
export async function OperationId_salesgateway_CashierController_payOnAccount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierControllerPayOnAccountParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(`/salesgateway/cashier/api/payOnAccount/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_payOnAccount',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 收银台-去还款 POST /salesgateway/cashier/api/toPay */
export async function OperationId_salesgateway_CashierController_toPay(
  body: API.PaymentOrderReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/cashier/api/toPay', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_toPay',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家-修改付款方式 PUT /salesgateway/cashier/api/updatePayModeType */
export async function OperationId_salesgateway_CashierController_updatePaymentOrder(
  body: API.UpdatePaymentOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/updatePayModeType', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_updatePaymentOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 支付验证码校验 POST /salesgateway/cashier/api/verifyCode */
export async function OperationId_salesgateway_CashierController_verifyCode(
  body: API.CreditPayReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/verifyCode', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierController_verifyCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
