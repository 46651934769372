// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 箱东-汇总统计 GET /salesgateway/container-owner/data/collect/count */
export async function OperationId_salesgateway_ContainerOwnerDataCountController_collectDataCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.CollectDataCountResponse>('/salesgateway/container-owner/data/collect/count', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerOwnerDataCountController_collectDataCount',
    },
    ...(rest || {}),
  });
}

/** 箱东-库存分布按港区维度数据统计 GET /salesgateway/container-owner/data/inventory-detail/count */
export async function OperationId_salesgateway_ContainerOwnerDataCountController_inventoryDetailDataCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.InventoryDetailDataCountResponse>(
    '/salesgateway/container-owner/data/inventory-detail/count',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerOwnerDataCountController_inventoryDetailDataCount',
      },
      ...(rest || {}),
    },
  );
}

/** 数据概览-消息通知任务列表 POST /salesgateway/container-owner/data/message-notify/page */
export async function OperationId_salesgateway_ContainerOwnerDataCountController_pageNotifyTask(
  body: API.PageMessageNotifyTaskRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PageMessageNotifyTaskResponse>(
    '/salesgateway/container-owner/data/message-notify/page',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerOwnerDataCountController_pageNotifyTask',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 箱东-库存分布按港区维度数据统计 GET /salesgateway/container-owner/data/port-area-inventory/count */
export async function OperationId_salesgateway_ContainerOwnerDataCountController_inventoryDataCountByPortArea(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.InventoryDataCountByCityResponse>(
    '/salesgateway/container-owner/data/port-area-inventory/count',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerOwnerDataCountController_inventoryDataCountByPortArea',
      },
      ...(rest || {}),
    },
  );
}
