// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 买家-查看业务账单(含总金额信息) POST /salesgateway/bill/api/buyer/businessBills */
export async function OperationId_salesgateway_BillController_getBuyerBusinessBills(
  body: API.GetBuyerBusinessBillReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetBuyerBusinessBillResponseVo>('/salesgateway/bill/api/buyer/businessBills', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getBuyerBusinessBills',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 费用ID批量查询-费用组成 POST /salesgateway/bill/api/fee/byIds */
export async function OperationId_salesgateway_BillController_getFeeByIds(
  body: API.GetFeeConstituteReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetFeeConstituteResponseVo>('/salesgateway/bill/api/fee/byIds', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getFeeByIds',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 订单ID-获取费用组成 GET /salesgateway/bill/api/fee/byOrderId */
export async function OperationId_salesgateway_BillController_getFeeConstituteByOrderId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBillControllerGetFeeConstituteByOrderIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetFeeConstituteResponseVo>('/salesgateway/bill/api/fee/byOrderId', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getFeeConstituteByOrderId',
    },
    params: {
      // page has a default value: 1
      page: '1',
      // size has a default value: 10
      size: '10',
      ...params,
    },
    ...(rest || {}),
  });
}

/** 支付单ID-获取费用组成 GET /salesgateway/bill/api/fee/byPaymentOrderId */
export async function OperationId_salesgateway_BillController_getFeeConstituteByPaymentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBillControllerGetFeeConstituteByPaymentIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetFeeConstituteResponseVo>('/salesgateway/bill/api/fee/byPaymentOrderId', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getFeeConstituteByPaymentId',
    },
    params: {
      // page has a default value: 1
      page: '1',
      // size has a default value: 10
      size: '10',
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看支付流水账单 POST /salesgateway/bill/api/get/payRecordBills */
export async function OperationId_salesgateway_BillController_getPayRecordBill(
  body: API.GetPayRecordReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetPayRecordResponseVo>('/salesgateway/bill/api/get/payRecordBills', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getPayRecordBill',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看支付流水 GET /salesgateway/bill/api/get/payRecords */
export async function OperationId_salesgateway_BillController_getPayRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBillControllerGetPayRecordParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetPayRecordResponseVo>('/salesgateway/bill/api/get/payRecords', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getPayRecord',
    },
    params: {
      // page has a default value: 1
      page: '1',
      // size has a default value: 10
      size: '10',
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看退款流水账单 POST /salesgateway/bill/api/get/refundBills */
export async function OperationId_salesgateway_BillController_getRefundBills(
  body: API.GetRefundReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetRefundResponseVo>('/salesgateway/bill/api/get/refundBills', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getRefundBills',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看结算明细 GET /salesgateway/bill/api/get/settlementDetail */
export async function OperationId_salesgateway_BillController_getSettlementDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBillControllerGetSettlementDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetSettlementOrderDetailResponseVo>(
    '/salesgateway/bill/api/get/settlementDetail',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BillController_getSettlementDetail',
      },
      params: {
        // page has a default value: 1
        page: '1',
        // size has a default value: 10
        size: '10',
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 资金结算账单 POST /salesgateway/bill/api/get/settlementList */
export async function OperationId_salesgateway_BillController_getSettlementList(
  body: API.GetSettlementOrderReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetSettlementOrderResponseVo>('/salesgateway/bill/api/get/settlementList', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BillController_getSettlementList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家-查看业务账单(含总金额信息) POST /salesgateway/bill/api/seller/businessBills */
export async function OperationId_salesgateway_BillController_getBusinessBills(
  body: API.GetSellerBusinessBillReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetSellerBusinessBillResponseVo>(
    '/salesgateway/bill/api/seller/businessBills',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BillController_getBusinessBills',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
