import type { ProTableColumnType, ProEnumType, ProArrayEnumItem } from './type';
import type { TableColumnType } from 'antd';

export function initColumns<T = any>(columns: ProTableColumnType<T>[]) {
  const cols: TableColumnType<T>[] = [];
  columns.forEach((item) => {
    if (item.search) {
      cols.push();
    }
  });
}

export const getColumnKey = (column: ProTableColumnType<any>) => {
  if (column.key) {
    return column.key;
  } else if (column.dataIndex) {
    if (Array.isArray(column.dataIndex)) {
      return column.dataIndex.join('_');
    }
    return column.dataIndex;
  }
  return '';
};

export function formatEnumToArray(enums?: ProEnumType | ProArrayEnumItem[]) {
  if (!enums) {
    return [];
  }
  if (Array.isArray(enums)) {
    return enums;
  }
  return Object.keys(enums).map((key) => {
    return {
      label: enums[key],
      value: key,
    };
  });
}

export function transform(values: Record<string, any>, columns: ProTableColumnType<any>[]) {
  for (let key in values) {
    const target = columns.find((item) => item.key === key || item.dataIndex === key);
    if (target && typeof target.search === 'object' && target.search.transform) {
      values[key] = target.search.transform(values[key]);
    }
  }
  return values;
}
