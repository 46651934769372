import { i18n } from '@easygo/i18n';
import type { AppProps } from 'next/app';
import type { NextComponentType, NextPageContext } from 'next';
import { appWithTranslation } from 'next-i18next';
import { BasicLayout } from '@/layouts/BasicLayout';
import { useGlobalProvider, AuthRoute, setCurrentTrackerPageName } from '@easygo/components';
import { OpenConfigContextProvider } from '@easygo/components/src/context/open-config-context';
import { apis } from '@/server';
import type { API } from '@/server';
import { requestConfig } from '@easygo-athena/request';
import { useRouter } from 'next/router';
import { message, Modal } from 'antd';
import { useMemoizedFn, useRequest } from 'ahooks';
import Cookies from 'js-cookie';
import { useMemo, useEffect, useRef, useReducer } from 'react';
import { isEmpty } from 'lodash';
import getConfig from 'next/config';
import { utils } from '@easygo/utils';
import NProgress from 'nprogress';
import { ErrorBound } from '../components/error-bound';
import { AliveScope } from 'react-activation';
import Script from 'next/script';
import { SiteContext } from '@easygo/components';

import bootstrap from '../bootstrap';
import { OperationId_salesgateway_OpenContainerLeasingPriceMarkController_enablePriceMark } from '@/server/api/salesgatewayOpenContainerLeasingPriceMarkController';
import { OperationId_sinotransbooking_OpenCenterConfigController_listCurrencyConfig } from '@/server/api/sinotransbookingOpenCenterConfigController';
import { TopMenusContext } from '@/context/top-menus';

bootstrap();

const { OperationId_salesgateway_EnterpriseController_authByEnterpriseId } = apis.default.salesgatewayEnterpriseController;
const { OperationId_salesgateway_AuthController_refreshToken } = apis.default.salesgatewayAuthController;

require('@/styles/index.less');
require('nprogress/nprogress.css');

const { publicRuntimeConfig } = getConfig();
interface NewAppProps extends AppProps {
  Component: NextComponentType<NextPageContext, any> & {
    getLayout?: (page: JSX.Element) => JSX.Element;
    resourceId?: string;
    trackerPageName?: string;
  };
  pageProps: any;
}

const cachePrefix = process.env.NEXT_PUBLIC_CACHE_PREFIX;
const useCacheKey = `${cachePrefix}user`;
const permissCacheKey = `${cachePrefix}permiss`;
const bussinessListCacheKey = `${cachePrefix}bussiness`;
const enterpriseTokenKey = process.env.NEXT_PUBLIC_ENTERPRISE_TOKEN_KEY;
const enterpriseIdKey = process.env.NEXT_PUBLIC_ENTERPRISE_ID_KEY;
const enterpriseTypeKey = process.env.NEXT_PUBLIC_ENTERPRISE_TYPE_KEY;
const bussinessKey = `${cachePrefix}buss`;
const sessionKey = process.env.NEXT_PUBLIC_SESSION_KEY;

requestConfig.setConfig({
  // baseUrl: process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_DEV_BASE_API : publicRuntimeConfig.API_PATH,
  baseUrl: publicRuntimeConfig.API_PATH,
  sessionKey: sessionKey,
  tokenKey: process.env.NEXT_PUBLIC_ENTERPRISE_TOKEN_KEY,
  refreshToken: {
    api: OperationId_salesgateway_AuthController_refreshToken,
  },
  onRefreshError: (error: any) => {
    location.reload();
  },
  onError: (code: string, msg?: string) => {
    message.error(<>{msg}</>);
  },
});

const getLocalCache = (key: string) => {
  let cacheObj = {};
  try {
    const cahceStr = sessionStorage.getItem(key);
    if (cahceStr) {
      cacheObj = JSON.parse(cahceStr);
    }
  } catch (e) {}
  return cacheObj;
};

const getSessionCache = () => {
  const sessionCacheStr = Cookies.get(sessionKey) ?? '';
  let sessionObj: any = {};
  try {
    sessionObj = JSON.parse(atob(sessionCacheStr));
  } catch (e: any) {}
  return sessionObj;
};

const getGroupArray = (str: string) => {
  try {
    const arr = JSON.parse(str);
    return Array.isArray(arr) ? arr : [];
  } catch (e) {
    return [];
  }
};

function MyApp({ Component, pageProps: { pageProps, ...restProps } }: NewAppProps) {
  const layout = Component.getLayout ?? ((page) => page);
  const resourceId = Component.resourceId;
  const router = useRouter();
  const currentLngFromCookie = Cookies.get(process.env.NEXT_PUBLIC_LANGUAGE);

  // 币种数据字典
  const { data: currencyItems = [] } = useRequest(OperationId_sinotransbooking_OpenCenterConfigController_listCurrencyConfig);

  if (!currentLngFromCookie || currentLngFromCookie !== router.locale) {
    // 主要是给接口 header 设置语言环境
    Cookies.set(process.env.NEXT_PUBLIC_LANGUAGE, router.locale || 'zh');
  }
  /** 火山引擎脚本app_id区分环境 */
  const volcengineId =
    publicRuntimeConfig.UIM_ENV === 'prod' ? process.env.NEXT_PUBLIC_VOLCENGINE_APPID : process.env.NEXT_PUBLIC_DEV_VOLCENGINE_APPID;
  const navigateRef = useRef<(path: string | -1) => void>();
  const { state, dispatch, GlobalProvider } = useGlobalProvider({
    currState: 'buier',
    locale: router.locale,
    pathname: router.pathname,
  });
  // 获取权限
  const fetchPermiss = useRequest(
    async () => {
      try {
        let tempData: string[] = [];
        //const cache = getLocalCache(permissCacheKey);
        const cache = null;
        if (cache && !isEmpty(cache)) {
          tempData = cache as string[];
        } else {
          const res = await apis.default.salesgatewayAccountController.OperationId_salesgateway_AccountController_getCurrentAccountPermissions({});
          tempData =
            res?.flatMap((item) => {
              return getGroupArray(item.operatorGroup);
            }) ?? [];
          sessionStorage.setItem(permissCacheKey, JSON.stringify(tempData));
        }
        return tempData;
      } catch (e) {
        return [];
      }
    },
    {
      manual: true,
      debounceWait: 300,
      onSuccess: (payload) => {
        dispatch({
          type: 'INIT_PERMISS',
          payload,
        });
      },
    }
  );

  // 刷新权限
  const refreshPermiss = async (force?: boolean) => {
    const cache: any = getLocalCache(bussinessKey);
    if (
      force ||
      (cache?.saler as API.GetEnterpriseCertificationGroupResponseVO)?.groupList?.some((saler) =>
        saler.types.some((t) => t.status === 'CERTIFICATION_STATUS_TYPE_PROGRESSING')
      )
    ) {
      await fetchPermiss.runAsync();
    }
  };

  const toggleLoading = (loading: boolean) => {
    dispatch({ type: 'TOGGLE_LOADING', payload: loading });
  };

  // 获取用户信息
  const fetchUserInfo = useMemoizedFn(async (force?: boolean) => {
    try {
      const sess = getSessionCache();
      const cache = getLocalCache(useCacheKey);
      let tempData;
      if (cache && !isEmpty(cache) && !force) {
        tempData = cache;
      } else {
        const res = await apis.default.salesgatewayAccountController.OperationId_salesgateway_AccountController_getAccountProfile();
        tempData = res ?? {};
        sessionStorage.setItem(useCacheKey, JSON.stringify(tempData));
      }
      const session = {
        ...state.session,
        ...sess,
        ...tempData,
      };
      dispatch({
        type: 'INIT_SESSION',
        payload: session,
      });
      return session;
    } catch (e) {
      return {};
    }
  });

  // 获取绑定企业列表
  const fetchBussinessList = useMemoizedFn(async (force?: boolean) => {
    try {
      // const cache = getLocalCache(bussinessListCacheKey);
      const cache = null;
      const sess = getSessionCache();
      let tempData;
      if (cache && !isEmpty(cache) && !force) {
        tempData = cache;
      } else {
        const res = await apis.default.salesgatewayEnterpriseController.OperationId_salesgateway_EnterpriseController_getEnterprisesByAccountId({
          accountId: sess.audience!,
        });
        tempData = (res?.enterprises ?? []).filter((enterprise) => enterprise.status === 'ENTERPRISE_STATUS_ENABLE');
        sessionStorage.setItem(bussinessListCacheKey, JSON.stringify(tempData));
      }
      dispatch({
        type: 'INIT_BUSSINESS',
        payload: tempData,
      });
      return (isEmpty(tempData) ? [] : tempData) as API.GetEnterpriseByAccountIDResponseVO['enterprises'];
    } catch (e) {
      return [];
    }
  });

  const toggleBussinssType = useMemoizedFn(() => {
    const payload = state.currState === 'saler' ? 'buier' : 'saler';
    utils.storage.setEnterpriseType(getSessionCache()?.audience + enterpriseTypeKey, payload);
    dispatch({ type: 'TOGGLE_TYPE', payload });
  });
  // 获取企业详情
  const fetchBussinessInfo = useMemoizedFn(async (force?: boolean) => {
    try {
      const { enterpriseId, audience } = getSessionCache();
      let enterpriseToken = Cookies.get(enterpriseTokenKey);
      if (enterpriseId && !enterpriseToken) {
        const res = await OperationId_salesgateway_EnterpriseController_authByEnterpriseId({ enterpriseId });
        Cookies.set(enterpriseTokenKey, res?.accessToken!);
        utils.storage.setEnterpriseId(audience + enterpriseIdKey, enterpriseId);
      }
      enterpriseToken = Cookies.get(enterpriseTokenKey); // 可能是重新请求回来的token
      const localEnterpriseType = utils.storage.getEnterpriseType(audience + enterpriseTypeKey);
      // 登录的时候，默认设置上一次选中的角色
      localEnterpriseType && dispatch({ type: 'TOGGLE_TYPE', payload: localEnterpriseType });

      // const cache = getLocalCache(bussinessKey);
      const cache = null;
      let tempData;
      if (enterpriseToken) {
        // 有企业token才需要执行下面的操作
        if (cache && !isEmpty(cache) && !force) {
          tempData = cache;
        } else {
          //const res = await sale.bussiness.getBussinessInfo(enterpriseId!);
          const res1 = await apis.default.salesgatewayEnterpriseController.OperationId_salesgateway_EnterpriseController_getCurrentEnterprise();
          const res2 =
            await apis.default.salesgatewayEnterpriseV1Controller.OperationId_salesgateway_EnterpriseV1Controller_getEnterpriseCertificationGroups({
              request: { reqId: `${new Date().getTime()}` },
            });
          tempData = {
            ...res1,
            saler: res2,
            /** 是否已认证的企业，表示可以选择买家或者卖家 */
            isSaler: (res2?.groupList || []).some(
              (item) =>
                item.certificationGroupType === 'CERTIFICATION_GROUP_TYPE_SELLER' &&
                item.types.every((t) => t.status === 'CERTIFICATION_STATUS_TYPE_COMPLETED')
            ),
          };
          sessionStorage.setItem(bussinessKey, JSON.stringify(tempData));
          if (!tempData.isSaler) {
            utils.storage.setEnterpriseType(audience + enterpriseTypeKey, 'buier');
            dispatch({ type: 'TOGGLE_TYPE', payload: 'buier' });
          }
        }
        dispatch({ type: 'INIT_BUSS', payload: tempData });
        // await refreshPermiss(true);
        toggleLoading(false);
      }
      return tempData as any;
    } catch (e) {
      return {};
    }
  });

  const toggleBussiness = useMemoizedFn(async (enterpriseId: string, cb?: () => void) => {
    toggleLoading(true);
    const sess = getSessionCache();
    Cookies.set(
      sessionKey,
      btoa(
        JSON.stringify({
          ...sess,
          enterpriseId,
        })
      )
    );

    Cookies.remove(enterpriseTokenKey);
    utils.storage.setEnterpriseType(sess?.audience + enterpriseTypeKey, 'buier');
    await fetchBussinessInfo(true);
    cb?.();
  });

  const asyncActions = useMemo(() => {
    return {
      refreshPermiss,
      toggleLoading,
      toggleBussiness,
      toggleBussinssType,
      fetchBussinessInfo,
      fetchUserInfo,
      fetchBussinessList,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  navigateRef.current = (pathName: string | -1) => {
    if (pathName === -1) {
      router.back();
    } else {
      const realPathName = pathName.replace('/manager', '');
      if (state.currState === 'saler' && (pathName.includes('buier') || pathName.includes('/consulting'))) {
        Modal.confirm({
          content: i18n?.t('是否要切换成买家?'),
          onOk: async () => {
            toggleBussinssType();
            setTimeout(() => {
              router.push(`/manager/${realPathName}`, undefined, { locale: router.locale });
            }, 0);
          },
        });
        return;
      }
      router.push(`/manager/${realPathName}`, undefined, { locale: router.locale });
    }
  };

  useRequest(async () => {
    if (location.pathname.includes('/login')) toggleLoading(false);
    if (Cookies.get(sessionKey)) {
      await fetchUserInfo();
      const bussinesses = await fetchBussinessList();
      if (bussinesses.length) {
        fetchBussinessInfo();
      } else {
        // await refreshPermiss(true);
        toggleLoading(false);
      }
    } else {
      toggleLoading(false);
    }
  });
  /**
   * 保存埋点页面名称
   */
  if (!router.pathname.replace(/^\/en/gim, '').startsWith('/manager')) {
    setCurrentTrackerPageName('sale', Component.trackerPageName);
  }
  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    const handleStart = async (url: string) => {
      const urlStr = url.replace(/^\/en/gim, '');
      const cPath = location.pathname.replace(/^\/en/gim, '');
      if ((cPath.startsWith('/manager') && !urlStr.startsWith('/manager')) || (!cPath.startsWith('/manager') && urlStr.startsWith('/manager'))) {
        location.href = url;
        return;
      }
      // await refreshPermiss();
      NProgress.start();
    };

    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);
    router.beforePopState(({ url, as, options }) => {
      const cPath = location.pathname.replace(/^\/en/gim, '');
      if (as.includes('/login') || cPath.includes('/login')) {
        window.location.href = as;
        return false;
      }
      return true;
    });
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const renderChildren = layout(<Component {...pageProps} {...restProps} />);

  /**
   * @method 火山引擎脚本加载
   */
  const loadVolcengine = () => {
    ((win: any, export_obj) => {
      win['LogAnalyticsObject'] = export_obj;
      if (!win[export_obj]) {
        function _collect() {
          _collect.q.push(arguments);
        }
        _collect.q = _collect.q || [];
        win[export_obj] = _collect;
      }
      win[export_obj].l = +new Date();
    })(window, 'collectEvent');
    window.collectEvent?.('init', {
      app_id: +volcengineId,
      channel_domain: 'https://gator.volces.com',
      disable_sdk_monitor: true, // 用于禁止SDK启动后自身监控事件 onload 的上报
    });
    window.collectEvent?.('start');
  };

  useEffect(() => {
    // 重写window.open防止切换语言环境后打开新标签页 当前语言不生效
    if (window) {
      window.open = (function (_open) {
        return function (...args) {
          const currentLocal = window.location.pathname.split('/').includes('en') ? 'en' : '';
          const target = `${args[0]}`;
          const targetSplitRes = target.split('/');
          const hasLocal = targetSplitRes.includes('en') || targetSplitRes.includes('zh') || /^http/.test(args[0] as string);
          const localUrl = `${currentLocal}${target.startsWith('/') ? target : `/${target}`}`;
          args[0] = hasLocal ? args[0] : `${localUrl.startsWith('/') ? localUrl : `/${localUrl}`}`;
          _open.apply(this, args);
        };
      })(window.open);
      /** 火山引擎脚本接入 */
      loadVolcengine();
    }
  }, [router.pathname]);

  /** 是否显示全球租箱看板 menu */
  const { data: showBoxOverview, loading: loadingShowBoxOverview } = useRequest(
    OperationId_salesgateway_OpenContainerLeasingPriceMarkController_enablePriceMark
  );

  /** 顶部主菜单 */
  const topMenus = useMemo(() => {
    return loadingShowBoxOverview
      ? []
      : [
          { key: process.env.DEPOT_WEBSITE ? '/freightService' : '/', label: i18n?.t('国际货运服务') },
          { key: process.env.DEPOT_WEBSITE ? '/' : '/depot', label: i18n?.t('全球精选堆场') },
          { key: '/box-overview', label: i18n?.t('全球租箱行情') },
          { key: '/product', label: i18n?.t('易畅优势') },
          // { key: '/finance', label: i18n?.t('金融') },
          { key: '/news', label: i18n?.t('新闻动态') },
          { key: '/aboutUs', label: i18n?.t('关于我们') },
        ].filter((item) => item.key !== '/box-overview' || showBoxOverview);
  }, [loadingShowBoxOverview, showBoxOverview]);

  return (
    <SiteContext.Provider value="sale">
      <Script src="https://lf3-data.volccdn.com/obj/data-static/log-sdk/collect/5.0/collect-rangers-v5.1.4.js" async />
      <ErrorBound>
        {/* <Provider value={{ state, dispatch, asyncActions, navigate: navigateRef }}> */}
        <GlobalProvider {...{ asyncActions, navigate: navigateRef, refresh: () => {} }}>
          <OpenConfigContextProvider value={{ currencyItems }}>
            <TopMenusContext.Provider value={topMenus}>
              <BasicLayout>
                <AliveScope>{resourceId ? <AuthRoute resourceId={resourceId}>{renderChildren}</AuthRoute> : renderChildren}</AliveScope>
              </BasicLayout>
            </TopMenusContext.Provider>
          </OpenConfigContextProvider>
        </GlobalProvider>
        {/* </Provider> */}
      </ErrorBound>
    </SiteContext.Provider>
  );
}

export default appWithTranslation(MyApp);
