import React from 'react';
import type { ReactNode } from 'react';
import { Modal } from 'antd';
import type { ModalProps } from 'antd';
import styles from './index.module.less';

interface EasyModalProps {
  /** 是否打开 */
  visible: boolean;
  /** 关闭事件 */
  handleCancel: () => void;
  /** 确定事件 */
  onOk?: (params: any) => void;
  /** 标题 */
  title?: string | React.ReactNode;
  /** 属性配置 */
  property?: any;
  children?: ReactNode;
  /** 底部按钮 */
  footer?: ReactNode | null;
  // 是否展示header border
  headerBorder?: boolean;
}

const EasyModal: React.FC<EasyModalProps & ModalProps> = (props) => {
  const { children, title = '', visible = false, property = {}, footer, handleCancel, onOk, headerBorder, ...restPropps } = props;

  return (
    <Modal
      title={title}
      open={visible}
      destroyOnClose
      footer={footer}
      onOk={onOk}
      onCancel={handleCancel}
      className={`${styles.easyModal} ${headerBorder ? '' : styles.headerBorder}`}
      {...property}
      {...restPropps}
    >
      {children}
    </Modal>
  );
};

export default EasyModal;
