import Script from 'next/script';
import dyamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { ConfigProvider, Spin } from 'antd';
import { WaterMark } from '@ant-design/pro-components';
import enUs from 'antd/lib/locale/en_US';
import zhCn from 'antd/lib/locale/zh_CN';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import 'dayjs/locale/zh';
import 'dayjs/locale/zh-cn';

import { i18n } from '@easygo/i18n';
import { ComponentConfigProvider, useGlobalState, componentI18nInstance } from '@easygo/components';

import { LayoutFooterOffice } from '@/components/layout-footer-office';

const Anchor = dyamic(() => import('@easygo/shared/src/components/anchor').then((res) => res.Anchor), { ssr: false });
const CustomEmpty = dyamic(() => import('../components/empty').then((res) => res.CustomEmpty), { ssr: false });

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

dayjs.updateLocale('zh', {
  relativeTime: {
    future: i18n?.t('%s后'),
    past: i18n?.t('%s前'),
    s: i18n?.t('刚刚'),
    m: i18n?.t('1 分钟'),
    mm: i18n?.t('%d 分钟'),
    h: i18n?.t('1 小时'),
    hh: i18n?.t('%d 小时'),
    d: i18n?.t('1 天'),
    dd: i18n?.t('%d 天'),
    M: i18n?.t('1 个月'),
    MM: i18n?.t('%d 个月'),
    y: i18n?.t('1 年'),
    yy: i18n?.t('%d 年'),
  },
});

const langMap: Record<string, typeof zhCn> = {
  zh: zhCn,
  en: enUs,
};

export function BasicLayout(props: { children: JSX.Element }) {
  const { locale, pathname } = useRouter();
  const {
    state: { session, bussiness, loading },
  } = useGlobalState();
  componentI18nInstance.locale = locale ?? 'zh';

  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  useEffect(() => {
    if (pathname === '/' || pathname.startsWith('/depot')) {
      document.body.classList.add('ignore_qidian');
    } else {
      document.body.classList.remove('ignore_qidian');
    }
  }, [pathname]);

  const hides = useMemo(
    () => ({
      footer: ['/box-overview', '/depot/port-area/map'].includes(pathname),
      anchor: pathname.startsWith('/depot'),
    }),
    [pathname]
  );

  return (
    <>
      <Script
        src="https://wp.qiye.qq.com/qidian/3009106267/d31f5ec98db6126933bf1cae8e83b009"
        id="qd3009106267d31f5ec98db6126933bf1cae8e83b009"
      ></Script>
      <ComponentConfigProvider value={{ locale }}>
        <ConfigProvider
          locale={langMap[locale!]}
          renderEmpty={(componentName) => {
            if (['Select', 'Cascader'].includes(componentName!)) {
              return <div style={{ color: '#6B7084', fontSize: 12, fontWeight: 400, textAlign: 'center' }}>{i18n?.t('暂无数据')}</div>;
            }
            return <CustomEmpty />;
          }}
        >
          <Spin spinning={loading}>
            <div className="page-container">
              {!loading ? (
                <>
                  <WaterMark
                    content={[bussiness?.name || '', session?.name || '']}
                    gapY={130}
                    fontColor="rgba(0,0,0,.08)"
                    rotate={-10}
                    height={60}
                    fontSize={14}
                    zIndex={9999}
                  >
                    {props.children}
                    {!hides.footer && <LayoutFooterOffice />}
                  </WaterMark>
                </>
              ) : null}
              {!hides.anchor && <Anchor />}
            </div>
          </Spin>
        </ConfigProvider>
      </ComponentConfigProvider>
    </>
  );
}
