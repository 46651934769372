import type { SelectProps } from 'antd';
import { Spin } from 'antd';
import { useRequest } from 'ahooks';
import { debounce } from 'lodash';
import type { RemoteSelectProps } from './interface';
import { useEffect } from 'react';
import { Select } from '../select';

const RemoteSelect: React.FC<RemoteSelectProps> = (props) => {
  const { value, inputSearch = false, labelInValue = false, onChange, onBlur, remoteProps, ...rest } = props;
  const { valueKey = 'id', labelKey = 'name', api, wait = 300, handleResult, labelRender, defaultSearch = true, optionDisableFn } = remoteProps;
  const apiSync = async (params: Record<string, any> | String) => {
    const result = await api(params);

    const resultArr = (handleResult && handleResult(result)) || result;
    const finalOptions = resultArr?.map((option: any) => ({
      ...option,
      disabled: optionDisableFn ? optionDisableFn(option) : false,
      label: labelRender ? labelRender(option) : option[labelKey],
      value: option[valueKey],
    }));
    return finalOptions;
  };
  const { loading, data, run } = useRequest(apiSync, { manual: true });
  useEffect(() => {
    defaultSearch && run('');
  }, []);

  const notFoundContent = loading ? <Spin size="small" spinning={loading} /> : undefined;
  const requestOption = inputSearch ? { onSearch: debounce(run, wait) } : {};

  const formatSelectValue = () => {
    if (value && labelInValue) {
      if (Array.isArray(value)) {
        return value.map((item) => ({ value: item[valueKey], label: item[labelKey] }));
      } else {
        return { value: value[valueKey], label: value[labelKey] };
      }
    } else {
      return value;
    }
  };
  const handleSelectChange = (newValue: any, option: any) => {
    let customizeKeyValue = newValue;
    if (newValue && labelInValue) {
      if (Array.isArray(newValue)) {
        customizeKeyValue = customizeKeyValue.map((item: any) => ({
          [labelKey]: item.label,
          [valueKey]: item.value,
        }));
      } else {
        customizeKeyValue = {
          [labelKey]: customizeKeyValue.label,
          [valueKey]: customizeKeyValue.value,
        };
      }
    }
    onChange && onChange(customizeKeyValue, option);
  };

  const selectProps = {
    allowClear: true,
    showSearch: true,
    value: formatSelectValue(),
    optionFilterProp: 'label',
    notFoundContent,
    labelInValue,
    options: data,
    onChange: handleSelectChange,
    onDropdownVisibleChange: (open) => open && defaultSearch && run(''),
    ...requestOption,
    ...rest,
  } as SelectProps;
  return <Select {...selectProps} />;
};

export default RemoteSelect;
