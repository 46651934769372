import { createContext, useContext } from 'react';

export interface CurrencyItem {
  id: string;

  /**
   * 货币名
   */
  currencyName: string;

  /**
   * 货币代码
   */
  currencyCode: string;

  /**
   * 货币符号
   */
  currencySymbol: string;

  /**
   * 货币类型
   */
  currencyType: string;

  /**
   * 排序
   */
  sort: number;
}

/** 项目基础配置，如：箱型、货币类型 */
export interface OpenConfigContextType {
  currencyItems: CurrencyItem[];
}

export const OpenConfigContext = createContext<OpenConfigContextType>({
  currencyItems: [],
});

export const OpenConfigContextProvider = OpenConfigContext.Provider;

export const useOpenConfigContext = () => useContext(OpenConfigContext);
