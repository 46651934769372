import styles from './index.module.less';
import { Space } from 'antd';
import { useCurrencyCodeAndSymbol } from '../hooks/useMultipleCurrencyAmount';
import type { StatementAmount } from './interface';

const AmountsArrayDesc = ({ value }: { value: StatementAmount[] }) => {
  const { currencySymbol } = useCurrencyCodeAndSymbol();
  const amounts: string[] = value?.map((item) => `${currencySymbol[item.currency]}${item.amount?.toFixed(2)}`) || [];

  if (amounts.length === 0) {
    return <span>0</span>;
  }

  return (
    <Space direction="vertical" size={0} className={styles.descAmount}>
      {amounts.map((amount, index) => (
        <span key={index}>{amount}</span>
      ))}
    </Space>
  );
};

export default AmountsArrayDesc;
