import styles from './index.module.less';
import React, { useRef, useState } from 'react';
import { Form, Select, InputNumber, Popconfirm, Table, Typography, Button, Space, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { enums, utils } from '@easygo/utils';
import type { FormInstance } from 'antd';
import { useRequest } from 'ahooks';
import { useDict } from '../hooks';
import { useSimleMutipleCurrencyAmountFormator } from '../hooks/useMultipleCurrencyAmount';
import BigNumber from 'bignumber.js';

// 费用项
interface TuoCheItem {
  key: string; // 自定义key标识
  feeType?: string;
  currency?: string;
  price?: string | number;
}

// 符号
const currencySymbol = enums.CurrencyTypeMapSymbol;

// 以拖车费做基础组件实现
export type EditChargeProps<Item> = {
  initList?: Partial<Item>[];
  onChange?(chargeList: Item[]): any;
  // 返回column
  resolveColumns?(option: {
    isEditing: (record: Item) => boolean;
    chargeSelcectList: any[];
    /** 当前列表 */
    chargeList: any[];
    columns: ColumnsType<Item>;
    save: any;
    cancel: any;
    deleteHandler: any;
    edit: any;
    editingKey: string;
  }): ColumnsType<Item>;
  // 点击编辑的时候设置值到输入框内的handler
  editHandler?(form: FormInstance<any>, record: Item): any;
  // 点击确认的时候设置值到状态内的handler
  resolveNewItemHandler?(old: Item, editPayload: Item): Item;
  // 添加新费用项目处理新item的handler
  resolveAddItemHandler?(newAdd: Item, chargeSelcectList: { label: string; value: string }[], chargeList: Item[]): Item;
  // 计算合计的handler
  resolveAllAmountHandler?(chargeList: Item[]): string;
  // 费用下拉
  chargeReq?: (...args: any) => Promise<any>;
  // 费用下拉
  chargeTypeList?: { label: string; value: any }[];
  // ref
  custRef?: any;
  // 其他list 外部状态
  otherList?: Partial<Item>[];
  // 禁新增按钮
  addBtnDisabled?: boolean;
  // 新增按钮
  isNeedAddBtn?: boolean;
  /** 详情接口返回的原始费用数据，包含了是否已出账，已出账的费用项不能操作 */
  dbChargeList?: any[];
};
function EditCharge<Item extends TuoCheItem = TuoCheItem>({
  initList = [],
  onChange,
  resolveColumns,
  editHandler,
  resolveNewItemHandler,
  resolveAddItemHandler = (e) => e,
  resolveAllAmountHandler,
  chargeReq,
  chargeTypeList,
  custRef,
  otherList,
  addBtnDisabled = false,
  isNeedAddBtn = true,
  dbChargeList,
}: EditChargeProps<Item>) {
  const [form] = Form.useForm();
  const [chargeList, setChargeList] = useState<Item[]>(() => initList?.map((e) => ({ ...e, key: uuid() })) as Item[]);
  const [editingKey, setEditingKey] = useState('');
  custRef && (custRef.current = form);
  const valiHandler = form.validateFields;
  form.validateFields = function (...args) {
    if (valiEditHandlerRef.current!()) {
      return Promise.reject('存在未保存的费用项！');
    }
    return valiHandler.call(this, ...args);
  };
  const isEditing = (record: Item) => record.key === editingKey;
  const allList = [...(otherList || []), ...(chargeList || [])];
  const valiEditHandlerRef = useRef<() => boolean>();
  valiEditHandlerRef.current = function () {
    return !saveClickFlagRef.current && allList.some((item) => isEditing(item as Item));
  };

  const edit = (record: Item) => {
    if (editHandler) {
      editHandler(form, record);
    } else {
      form.setFieldsValue({ feeType: undefined, currency: undefined, price: undefined, ...record });
    }
    setEditingKey(record.key);
  };

  const deleteHandler = (record: Partial<Item> & { key: React.Key }) => {
    const newList = chargeList.filter((e) => e.key !== record.key);
    setChargeList(newList);
    onChange?.(newList);
  };

  const cancel = (record: Item) => {
    if (newAddItemIng.current === record.key) {
      deleteHandler(record);
    }
    setEditingKey('');
    newAddItemIng.current = '';
  };

  // 币种
  const [currencyOptions = []] = useDict('currency');

  // 是确认按钮的点击校验标识
  const saveClickFlagRef = useRef(false);
  const save = async (key: React.Key) => {
    try {
      saveClickFlagRef.current = true;
      const row = (await form.validateFields()) as Item;

      const newList = [...chargeList];
      const index = newList.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newList[index];
        const newItem = resolveNewItemHandler?.(item, row) || {
          ...item,
          ...row,
        };
        newList.splice(index, 1, newItem);
        setChargeList(newList);
        setEditingKey('');
        onChange?.(newList);
      }
      newAddItemIng.current = '';
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    } finally {
      saveClickFlagRef.current = false;
    }
  };

  // 费用select下拉列表
  const { data: chargeSelectResult } = useRequest(async () => chargeReq?.());
  const chargeSelcectList = chargeReq
    ? chargeSelectResult?.expenseList?.map((e: any) => ({ label: e.name!, value: e.id! })) || []
    : chargeTypeList || [];

  const columnDefault: ColumnsType<Item> = [
    {
      title: '费用名称',
      dataIndex: 'feeType',
      width: '30%',
      render(_, record, i) {
        const editIng = isEditing(record);
        const editElem = (
          <Form.Item name="feeType" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请选择费用项目' }]}>
            <Select
              options={chargeSelcectList.map?.((item: any) => {
                return {
                  ...item,
                  disabled: !(record.feeType === item.value) && chargeList.some((usedItem) => usedItem.feeType === item.value),
                };
              })}
              placeholder="请选择费用项目"
            />
          </Form.Item>
        );
        return editIng ? editElem : findLabel(_, chargeSelcectList);
      },
    },
    {
      title: '币种',
      dataIndex: 'currency',
      width: '29.5%',
      render(_, record, i) {
        const editIng = isEditing(record);
        const editElem = (
          <Form.Item name="currency" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请选择币种' }]}>
            <Select options={currencyOptions} placeholder="请选择币种" />
          </Form.Item>
        );
        return editIng ? editElem : findLabel(_, currencyOptions);
      },
    },
    {
      title: '费用',
      dataIndex: 'price',
      align: 'right',
      width: '29.5%',
      render(_, record, i) {
        const editIng = isEditing(record);
        const editElem = (
          <Form.Item name="price" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请填写金额' }]}>
            <InputNumber style={{ width: '100%' }} min={0.01} precision={2} max={999999999} placeholder="请填写金额" />
          </Form.Item>
        );
        return editIng ? editElem : _ >= 0 ? utils.resolveCurrency({ price: _ }, 'none') : '';
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: '11%',
      fixed: 'right',
      render: (_: any, record: Item) => {
        if (checkOutBill(dbChargeList, record)) {
          return (
            <Tooltip title="已出账，不可修改" color="#1A1E2E">
              -
            </Tooltip>
          );
        }
        const editIng = isEditing(record);
        const fee = chargeSelcectList.find((item: any) => item.value === (record.tradeFeeSubTypeId || record.feeType));
        return editIng ? (
          <Space split="|" className={styles.operation}>
            <Typography.Link onClick={() => save(record.key)}>确认</Typography.Link>
            <Typography.Link onClick={() => cancel(record)}>取消</Typography.Link>
            {/* <Popconfirm title="确认取消吗？" onConfirm={() => cancel(record)}>
              <a className="cancel">取消</a>
            </Popconfirm> */}
          </Space>
        ) : (
          <Space split="|" className={styles.operation}>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              编辑
            </Typography.Link>
            <Popconfirm title={`确认删除“${fee?.label}”吗？`} onConfirm={() => deleteHandler(record)}>
              <Typography.Link disabled={editingKey !== ''}>删除</Typography.Link>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const columns_: ColumnsType<Item> =
    resolveColumns?.({
      isEditing,
      chargeSelcectList,
      chargeList,
      columns: columnDefault,
      save,
      edit,
      deleteHandler,
      cancel,
      editingKey,
    }) || columnDefault;

  // 记录新增项
  const newAddItemIng = useRef<string>();
  const addHandler = () => {
    const key = uuid();
    newAddItemIng.current = key;
    const newCharge: Item = resolveAddItemHandler({ key } as Item, chargeSelcectList, chargeList);
    setChargeList(chargeList.concat(newCharge));
    edit(newCharge);
  };

  // 合计
  function resolveAllMount() {
    const allCharge = allList.reduce((all, charge) => {
      if (+charge.price! >= 0 && charge.currency) {
        const currency = charge.currency as keyof typeof currencySymbol;
        all[currency] = +(all[currency] || 0) + +(charge.price as number);
      }
      return all;
    }, {} as Record<keyof typeof currencySymbol, number>);
    const allStr = Object.entries(allCharge)
      .map(([currency, price]) => utils.resolveCurrency({ price: price, currency: currency! as any }))
      .join(' + ');
    return `合计金额：${allStr || '-'}`;
  }
  const allMountItems = allList?.map((item) => ({ currency: item.currency!, amount: item.price! }));
  const formator = useSimleMutipleCurrencyAmountFormator();
  const allMount = resolveAllAmountHandler?.(allList as any) || `合计金额：${formator(allMountItems as any) || '-'}`;

  return (
    <Form form={form} component={false}>
      <Table
        className={styles.table}
        dataSource={allList as any}
        columns={columns_}
        pagination={false}
        locale={{ emptyText: '请添加费用' }}
        bordered={false}
        scroll={{ x: 800 }}
        rowKey="key"
      />
      {allList.length > 0 && <div className={styles.all}>{allMount}</div>}
      {isNeedAddBtn && (
        <div className={styles.add}>
          <Button onClick={addHandler} disabled={!!editingKey || addBtnDisabled} className="addbtn" type="text" icon={<PlusOutlined />}>
            添加
          </Button>
        </div>
      )}
    </Form>
  );
}
export default EditCharge;

// 报关费
type PortItem = {
  key: string; // 自定义key标识
  feeType?: string;
  currency?: string;
  count?: number; // 数量
  price?: number; // 单价
};
type EditCptProps<Ele> = EditChargeProps<Ele>;
export const EditPortCharge: React.FC<EditCptProps<PortItem>> = (props) => {
  // 币种
  const [currencyOptions = []] = useDict('currency');

  const resolveColumns: EditChargeProps<PortItem>['resolveColumns'] = ({ isEditing, chargeSelcectList, columns, chargeList }) => {
    // @ts-ignore
    const actionItem = columns.find((col) => col.dataIndex === 'operation')!;
    actionItem.width = '11%';
    return [
      {
        title: '费用名称',
        dataIndex: 'feeType',
        width: '18.8%',
        render(_, record, i) {
          const editIng = isEditing(record);
          const editElem = (
            <Form.Item name="feeType" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请选择费用项目' }]}>
              <Select
                options={chargeSelcectList.map((item) => ({
                  ...item,
                  disabled: !(record.feeType === item.value) && chargeList.some((usedItem) => usedItem.feeType === item.value),
                }))}
                placeholder="请选择费用项目"
              />
            </Form.Item>
          );
          return editIng ? editElem : findLabel(_, chargeSelcectList);
        },
      },
      {
        title: '币种',
        dataIndex: 'currency',
        width: '14.5%',
        render(_, record, i) {
          const editIng = isEditing(record);
          const editElem = (
            <Form.Item name="currency" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请选择币种' }]}>
              <Select options={currencyOptions} placeholder="请选择币种" />
            </Form.Item>
          );
          return editIng ? editElem : findLabel(_, currencyOptions);
        },
      },
      {
        title: '数量',
        dataIndex: 'count',
        width: '12.5%',
        render(_, record, i) {
          const editIng = isEditing(record);
          const editElem = (
            <Form.Item name="count" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请填写数量' }]}>
              <InputNumber style={{ width: '100%' }} precision={0} min={1} max={999999999} placeholder="请填写数量" />
            </Form.Item>
          );
          return editIng ? editElem : _;
        },
      },
      {
        title: '单价',
        dataIndex: 'price',
        align: 'right',
        width: '20.8%',
        render(_, record, i) {
          const editIng = isEditing(record);
          const editElem = (
            <Form.Item name="price" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请填写金额' }]}>
              <InputNumber precision={2} min={0.01} max={999999999} placeholder="请填写金额" style={{ width: '100%' }} />
            </Form.Item>
          );
          return editIng ? editElem : _ >= 0 ? utils.resolveCurrency({ price: _ }, 'none') : '';
        },
      },
      {
        title: '总额',
        dataIndex: 'amount',
        align: 'right',
        width: '21.8%',
        render(_, record, i) {
          if (record.count! >= 0 && +record.price! >= 0) {
            return utils.resolveCurrency({ price: record.count! * +record.price! }, 'none');
          }
          return '-';
        },
      },
      actionItem,
    ];
  };

  const editHandler: EditChargeProps<PortItem>['editHandler'] = (form, record) => {
    form.setFieldsValue({ feeType: undefined, currency: undefined, count: undefined, price: undefined, ...record });
  };

  const resolveAddItemHandler: EditChargeProps<PortItem>['resolveAddItemHandler'] = (newItem, chargeSelcectList, chargeList) => {
    return {
      ...newItem,
      count: 1,
      currency: 'CURRENCY_TYPE_RMB',
      feeType: chargeList.length === 0 ? chargeSelcectList?.find((e) => e.label === '报关费')?.value : undefined,
    };
  };

  const resolveAllAmountHandler: EditChargeProps<PortItem>['resolveAllAmountHandler'] = (chargeList) => {
    // const allCharge = chargeList.reduce((all, charge) => {
    //   if (charge.count! >= 0 && charge.currency && +charge.price! >= 0) {
    //     const currency = charge.currency as keyof typeof currencySymbol;
    //     all[currency] = +(all[currency] || 0) + +(charge.count as number) * +(charge.price as number);
    //   }
    //   return all;
    // }, {} as Record<keyof typeof currencySymbol, number>);
    // const allStr = Object.entries(allCharge)
    //   .map(([currency, price]) => utils.resolveCurrency({ price: price, currency: currency! as any }))
    //   .join(' + ');
    // return `合计金额：${allStr || '-'}`;

    const allMountItems = chargeList?.map((item) => ({
      currency: item.currency!,
      amount: BigNumber(item.price! || 0)
        .times(item.count! || 0)
        .toNumber(),
    }));
    return `合计金额：${formator(allMountItems as any) || '-'}`;
  };
  const formator = useSimleMutipleCurrencyAmountFormator();

  return (
    <EditCharge<PortItem>
      {...props}
      resolveColumns={resolveColumns}
      editHandler={editHandler}
      resolveAllAmountHandler={resolveAllAmountHandler}
      resolveAddItemHandler={resolveAddItemHandler}
    />
  );
};

// 服务费
type ServiceItem = {
  editAble?: boolean;
  key: string; // 自定义key标识
  feeType?: string;
  charge?: {
    currency?: string;
    price?: string | number;
  }[];
};
export const EditServiceCharge: React.FC<EditCptProps<ServiceItem> & { onViewChargeDetailAction?: (record: ServiceItem) => void }> = (props) => {
  const viewDetail = (record: ServiceItem) => props.onViewChargeDetailAction?.(record);
  // 币种
  const [currencyOptions = []] = useDict('currency');

  const resolveColumns: EditChargeProps<ServiceItem>['resolveColumns'] = ({
    isEditing,
    chargeSelcectList,
    chargeList,
    save,
    edit,
    deleteHandler,
    cancel,
    editingKey,
  }) => {
    return [
      {
        title: '提供服务',
        dataIndex: 'feeType', // 包含一级和二级
        width: '32%',
        render(_, record, i) {
          const editIng = isEditing(record);
          const editElem = (
            <Form.Item name="feeType" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请选择费用项目' }]}>
              <Select
                options={chargeSelcectList.map((item) => ({
                  ...item,
                  disabled: !(record.feeType === item.value) && chargeList.some((usedItem) => usedItem.feeType === item.value),
                }))}
                placeholder="请选择费用项目"
              />
            </Form.Item>
          );
          return editIng ? editElem : <span style={{}}>{findLabel(_, [...chargeSelcectList, ...feeTypesLv1])}</span>;
        },
      },
      {
        title: ' ',
        dataIndex: 'currency',
        width: '25%',
        render(_, record, i) {
          const editIng = isEditing(record);
          const editElem = (
            <Form.Item name="currency" style={{ marginBottom: 0 }} rules={[{ required: true, message: '请选择币种' }]}>
              <Select options={currencyOptions} placeholder="请选择币种" />
            </Form.Item>
          );
          return editIng ? editElem : '';
        },
      },
      {
        title: '费用',
        align: 'right',
        width: '25%',
        render(_, record, i) {
          const charge = resolveAmountToStr(record.charge) || 0;
          const chargeEle = <>{charge}</>;
          const editIng = isEditing(record);
          const editElem = (
            <Form.Item name="price" style={{ marginBottom: 0, textAlign: 'left' }} rules={[{ required: true, message: '请填写金额' }]}>
              <InputNumber style={{ width: '100%' }} precision={2} min={0.01} max={999999999} placeholder="请填写金额" />
            </Form.Item>
          );
          return editIng ? editElem : chargeEle;
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: '18%',
        render: (_: any, record) => {
          const viewItem = <Typography.Link onClick={() => viewDetail(record)}>明细</Typography.Link>;
          const editIng = isEditing(record);
          const fee = chargeSelcectList.find((item) => item.value === record.feeType);
          return editIng ? (
            <Space split="|" className={styles.operation}>
              <Typography.Link onClick={() => save(record.key)}>确认</Typography.Link>
              <Typography.Link onClick={() => cancel(record)}>取消</Typography.Link>
              {/* <Popconfirm title="确认取消吗？" onConfirm={() => cancel(record)}>
                <a className="cancel">取消</a>
              </Popconfirm> */}
            </Space>
          ) : record.editAble ? (
            <Space split="|" className={styles.operation}>
              {viewItem}
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                编辑
              </Typography.Link>
              <Popconfirm title={`确认删除“${fee?.label}”吗？`} onConfirm={() => deleteHandler(record)}>
                <Typography.Link disabled={editingKey !== ''}>删除</Typography.Link>
              </Popconfirm>
            </Space>
          ) : (
            <Space split="|" className={styles.operation}>
              {viewItem}
            </Space>
          );
        },
      },
    ];
  };

  const editHandler: EditChargeProps<ServiceItem>['editHandler'] = (form, record) => {
    form.setFieldsValue({ feeType: record.feeType, currency: record.charge?.[0].currency, price: record.charge?.[0].price });
  };

  const resolveNewItemHandler: EditChargeProps<ServiceItem>['resolveNewItemHandler'] = (old, payload) => {
    return {
      ...old,
      feeType: payload.feeType,
      // @ts-ignore
      charge: [{ currency: payload.currency, price: payload.price }],
    };
  };

  const resolveAddItemHandler: EditChargeProps<ServiceItem>['resolveAddItemHandler'] = (add) => {
    return {
      ...add,
      editAble: true,
    };
  };

  const resolveAllAmountHandler: EditChargeProps<ServiceItem>['resolveAllAmountHandler'] = (chargeList) => {
    const allMountItems = chargeList
      .reduce((all, curr) => all.concat(curr?.charge || []), [] as any[])
      .map((item) => ({
        currency: item.currency,
        amount: item.price,
      }));
    return `合计金额：${formator(allMountItems as any) || '-'}`;
  };
  const formator = useSimleMutipleCurrencyAmountFormator();

  return (
    <EditCharge<ServiceItem>
      {...props}
      resolveNewItemHandler={resolveNewItemHandler}
      resolveColumns={resolveColumns}
      editHandler={editHandler}
      resolveAddItemHandler={resolveAddItemHandler}
      resolveAllAmountHandler={resolveAllAmountHandler}
    />
  );
};

export enum FeeTypeLv1 {
  TRADE_FEE_TYPE_DINGCANG = 'TRADE_FEE_TYPE_DINGCANG',
  TRADE_FEE_TYPE_BAOGUAN = 'TRADE_FEE_TYPE_BAOGUAN',
  TRADE_FEE_TYPE_TUOCHE = 'TRADE_FEE_TYPE_TUOCHE',
  TRADE_FEE_TYPE_QINGGUAN = 'TRADE_FEE_TYPE_QINGGUAN',
  TRADE_FEE_TYPE_SONGCANG = 'TRADE_FEE_TYPE_SONGCANG',
}
const feeTypesLv1 = [
  { label: '订舱费', value: FeeTypeLv1['TRADE_FEE_TYPE_DINGCANG'] },
  { label: '报关费', value: FeeTypeLv1['TRADE_FEE_TYPE_BAOGUAN'] },
  { label: '拖车费', value: FeeTypeLv1['TRADE_FEE_TYPE_TUOCHE'] },
  { label: '清关费', value: FeeTypeLv1['TRADE_FEE_TYPE_QINGGUAN'] },
  { label: '送仓费', value: FeeTypeLv1['TRADE_FEE_TYPE_SONGCANG'] },
];
EditCharge.FeeTypeLv1 = FeeTypeLv1;

export function resolveAmountToStr(chargeList: ServiceItem['charge']) {
  const allCharge =
    chargeList?.reduce((all, charge) => {
      if (+charge.price! >= 0 && charge.currency) {
        const currency = charge.currency as keyof typeof currencySymbol;
        all[currency] = +(all[currency] || 0) + +(charge.price as number);
      }
      return all;
    }, {} as Record<keyof typeof currencySymbol, number>) || {};
  const allStr = Object.entries(allCharge)
    .map(([currency, price]) => utils.resolveCurrency({ price: price as number, currency: currency! as any }))
    .join(' + ');
  return allStr;
}
function uuid() {
  const temp_url = URL.createObjectURL(new Blob());
  const uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  return uuid.slice(uuid.lastIndexOf('/') + 1);
}
function findLabel(value: any, list: { label: string; value: any }[]) {
  return list.find((e) => e.value === value)?.label;
}

/** 详情接口返回的原始费用数据，包含了是否已出账，已出账的费用项不能操作 */
function checkOutBill(dbChargeList?: any[], record?: any) {
  return !!dbChargeList?.find((item: any) => item.tradeFeeSubTypeId === record?.feeType)?.billingFlag;
}
