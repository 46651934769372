// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询租箱商品未支付金额 GET /salesgateway/leasing/payment/leasing/remain/price */
export async function OperationId_salesgateway_LeasingPaymentController_qryOrderRemainPrice(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayLeasingPaymentControllerQryOrderRemainPriceParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.LeasingOrdRemainPriceVo>(
    '/salesgateway/leasing/payment/leasing/remain/price',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_LeasingPaymentController_qryOrderRemainPrice',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 订单支付单列表查询 GET /salesgateway/leasing/payment/list */
export async function OperationId_salesgateway_LeasingPaymentController_qryOrdPayment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayLeasingPaymentControllerQryOrdPaymentParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PaymentVo[]>('/salesgateway/leasing/payment/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_LeasingPaymentController_qryOrdPayment',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 收银台-支付 POST /salesgateway/leasing/payment/toPay */
export async function OperationId_salesgateway_LeasingPaymentController_toPay(
  body: API.ContainerLeasingPaymentOrderReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ResultResponseToPayRespVO>('/salesgateway/leasing/payment/toPay', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_LeasingPaymentController_toPay',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
