// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取私有下载地址 OpenOssController.getPrivateDownloadSignedUrl GET /logisticssolution/v1/open/oss/private/download/${param0}/sign */
export async function OperationId_logisticssolution_OpenOssController_getPrivateDownloadSignedUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenOssControllerGetPrivateDownloadSignedUrlParams,
  options?: { [key: string]: any },
) {
  const { name: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>(
    `/logisticssolution/v1/open/oss/private/download/${param0}/sign`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenOssController_getPrivateDownloadSignedUrl',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取私有Sts凭证 OpenOssController.getPrivateStsToken GET /logisticssolution/v1/open/oss/private/sts */
export async function OperationId_logisticssolution_OpenOssController_getPrivateStsToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenOssControllerGetPrivateStsTokenParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StsResponseVo>('/logisticssolution/v1/open/oss/private/sts', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenOssController_getPrivateStsToken',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取私有上传地址 OpenOssController.getPrivateUploadSignedUrl GET /logisticssolution/v1/open/oss/private/upload/sign */
export async function OperationId_logisticssolution_OpenOssController_getPrivateUploadSignedUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenOssControllerGetPrivateUploadSignedUrlParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>('/logisticssolution/v1/open/oss/private/upload/sign', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenOssController_getPrivateUploadSignedUrl',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取公有Sts凭证 OpenOssController.getPublicStsToken GET /logisticssolution/v1/open/oss/public/sts */
export async function OperationId_logisticssolution_OpenOssController_getPublicStsToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenOssControllerGetPublicStsTokenParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StsResponseVo>('/logisticssolution/v1/open/oss/public/sts', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenOssController_getPublicStsToken',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取公有上传地址 OpenOssController.getPublicUploadUrl GET /logisticssolution/v1/open/oss/public/upload/sign */
export async function OperationId_logisticssolution_OpenOssController_getPublicUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenOssControllerGetPublicUploadUrlParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>('/logisticssolution/v1/open/oss/public/upload/sign', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenOssController_getPublicUploadUrl',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
