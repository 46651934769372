// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 买家撤销变更申请记 POST /salesgateway/container-leasing/buyer/change-apply/buyer-cancel-change-apply */
export async function OperationId_salesgateway_ChangeApplyBuyerController_buyerCancelChangeApply(
  body: API.BuyerCancelChangeApplyVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>(
    '/salesgateway/container-leasing/buyer/change-apply/buyer-cancel-change-apply',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_buyerCancelChangeApply',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询变更城市列表 GET /salesgateway/container-leasing/buyer/change-apply/city/list */
export async function OperationId_salesgateway_ChangeApplyBuyerController_listChangeCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayChangeApplyBuyerControllerListChangeCityParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeCityVO[]>(
    '/salesgateway/container-leasing/buyer/change-apply/city/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_listChangeCity',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}

/** 查询线路统计数据 GET /salesgateway/container-leasing/buyer/change-apply/city/statistics/list */
export async function OperationId_salesgateway_ChangeApplyBuyerController_listCityStatistic(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayChangeApplyBuyerControllerListCityStatisticParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityStatisticsVO[]>(
    '/salesgateway/container-leasing/buyer/change-apply/city/statistics/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_listCityStatistic',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}

/** 买家创建提还箱变更 POST /salesgateway/container-leasing/buyer/change-apply/create-change-apply */
export async function OperationId_salesgateway_ChangeApplyBuyerController_createChangeApply(
  body: API.CreateChangeApplyVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/container-leasing/buyer/change-apply/create-change-apply', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_createChangeApply',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家变更申请详情 POST /salesgateway/container-leasing/buyer/change-apply/info */
export async function OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyInfo(
  body: API.QueryChangeApplyInfoVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyInfoVO>('/salesgateway/container-leasing/buyer/change-apply/info', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyInfo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家变更申请记录分页 POST /salesgateway/container-leasing/buyer/change-apply/page */
export async function OperationId_salesgateway_ChangeApplyBuyerController_buyerChangeApplyPage(
  body: API.QueryBuyerChangeApplyPageVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyPageVO>('/salesgateway/container-leasing/buyer/change-apply/page', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_buyerChangeApplyPage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家根据原线路和新选择线路获取差价/柜 POST /salesgateway/container-leasing/buyer/change-apply/query-change-apply-amount */
export async function OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyAmount(
  body: API.QueryChangeApplyAmountVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyAmountVO>(
    '/salesgateway/container-leasing/buyer/change-apply/query-change-apply-amount',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyAmount',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家提还变更申请页---获取线路信息、包括最大可申请数量(原订单信息) POST /salesgateway/container-leasing/buyer/change-apply/query-change-apply-old-Line */
export async function OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyLine(
  body: API.QueryChangeApplyLineVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyLineVO[]>(
    '/salesgateway/container-leasing/buyer/change-apply/query-change-apply-old-Line',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyLine',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家根据原线路和新选择线路获取服务费合计、差价合计、总费用美元、总费用美元人民币 POST /salesgateway/container-leasing/buyer/change-apply/query-change-apply-total-amount */
export async function OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyTotalAmount(
  body: API.QueryChangeApplyTotalAmountVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyTotalAmountVO>(
    '/salesgateway/container-leasing/buyer/change-apply/query-change-apply-total-amount',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ChangeApplyBuyerController_queryChangeApplyTotalAmount',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家获取服务费配置 POST /salesgateway/container-leasing/buyer/change-apply/query-service-amount-config */
export async function OperationId_salesgateway_ChangeApplyBuyerController_queryServiceAmountConfig(
  body: API.QueryServiceAmountConfigVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ServiceAmountConfigVO>(
    '/salesgateway/container-leasing/buyer/change-apply/query-service-amount-config',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplyBuyerController_queryServiceAmountConfig',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询提还城市变更待还箱号 GET /salesgateway/container-leasing/buyer/change-apply/wait-return/container/list */
export async function OperationId_salesgateway_ChangeApplyBuyerController_listWaitReturnContainerForLine(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayChangeApplyBuyerControllerListWaitReturnContainerForLineParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SimpleContainerVO[]>(
    '/salesgateway/container-leasing/buyer/change-apply/wait-return/container/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ChangeApplyBuyerController_listWaitReturnContainerForLine',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}
