// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 上传随车资料 POST /salesgateway/v1/railway/buyer/accessoryMaterial/${param0}/upload */
export async function OperationId_salesgateway_RailwayBuyerController_uploadAccessoryMaterial(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerUploadAccessoryMaterialParams,
  body: API.AccessoryMaterialsRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/buyer/accessoryMaterial/${param0}/upload`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_uploadAccessoryMaterial',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 取消订单 DELETE /salesgateway/v1/railway/buyer/cancelOrder */
export async function OperationId_salesgateway_RailwayBuyerController_cancelOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerCancelOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/buyer/cancelOrder', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_cancelOrder',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 下单 POST /salesgateway/v1/railway/buyer/createOrder */
export async function OperationId_salesgateway_RailwayBuyerController_createOrder(
  body: API.RailwayCreateOrderRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RailwayCreateOrderResponse>('/salesgateway/v1/railway/buyer/createOrder', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_createOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 创建支付单信息 PUT /salesgateway/v1/railway/buyer/createPaymentInfo */
export async function OperationId_salesgateway_RailwayBuyerController_createPaymentInfo(
  body: API.RailwayCreatePaymentInfoRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PaymentInfoVO>('/salesgateway/v1/railway/buyer/createPaymentInfo', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_createPaymentInfo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取客户列表 GET /salesgateway/v1/railway/buyer/listPerformanceCustom */
export async function OperationId_salesgateway_RailwayBuyerController_listPerformanceCustom(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceCustomerItemVo[]>(
    '/salesgateway/v1/railway/buyer/listPerformanceCustom',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayBuyerController_listPerformanceCustom',
      },
      ...(rest || {}),
    },
  );
}

/** 铁运订单列表 POST /salesgateway/v1/railway/buyer/orderList */
export async function OperationId_salesgateway_RailwayBuyerController_orderList(
  body: API.RailwayBuyerOrderListPageQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RailwayOrderListVO>('/salesgateway/v1/railway/buyer/orderList', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_orderList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 铁路订单列表banner GET /salesgateway/v1/railway/buyer/orderListBanner */
export async function OperationId_salesgateway_RailwayBuyerController_orderListBanner(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.RailwayOrderBannerVO[]>('/salesgateway/v1/railway/buyer/orderListBanner', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_orderListBanner',
    },
    ...(rest || {}),
  });
}

/** 申请提箱 POST /salesgateway/v1/railway/buyer/pickUp/${param0}/apply */
export async function OperationId_salesgateway_RailwayBuyerController_pickUpApply(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerPickUpApplyParams,
  body: API.RailwayPickupApplyRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/buyer/pickUp/${param0}/apply`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_pickUpApply',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 处理消息 PUT /salesgateway/v1/railway/buyer/processMessage */
export async function OperationId_salesgateway_RailwayBuyerController_processMessage(
  body: API.RailwayProcessMessageRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/buyer/processMessage', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_processMessage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看消息列表 GET /salesgateway/v1/railway/buyer/queryNotifyList */
export async function OperationId_salesgateway_RailwayBuyerController_queryNotifyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerQueryNotifyListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MessageItemVO[]>('/salesgateway/v1/railway/buyer/queryNotifyList', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_queryNotifyList',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 添加封箱资料 POST /salesgateway/v1/railway/buyer/sealingBox/${param0}/add */
export async function OperationId_salesgateway_RailwayBuyerController_addSealingBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerAddSealingBoxParams,
  body: API.SealingBoxItemRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/buyer/sealingBox/${param0}/add`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_addSealingBox',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 删除封箱资料 DELETE /salesgateway/v1/railway/buyer/sealingBox/${param0}/delete */
export async function OperationId_salesgateway_RailwayBuyerController_deleteSealingBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerDeleteSealingBoxParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/buyer/sealingBox/${param0}/delete`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_deleteSealingBox',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 修改订单 PUT /salesgateway/v1/railway/buyer/updateOrder */
export async function OperationId_salesgateway_RailwayBuyerController_updateOrder(
  body: API.RailwayUpdateOrderRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/buyer/updateOrder', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_updateOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 运单收讫(确认) PUT /salesgateway/v1/railway/buyer/waybill/${param0}/confirm */
export async function OperationId_salesgateway_RailwayBuyerController_waybillConfirm(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerWaybillConfirmParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/buyer/waybill/${param0}/confirm`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_waybillConfirm',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 运单样本确本 PUT /salesgateway/v1/railway/buyer/waybill/${param0}/materialsConfirm */
export async function OperationId_salesgateway_RailwayBuyerController_waybillMaterialsConfirm(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerControllerWaybillMaterialsConfirmParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/buyer/waybill/${param0}/materialsConfirm`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerController_waybillMaterialsConfirm',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}
