// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** banner查询 GET /salesgateway/home/banner */
export async function OperationId_salesgateway_HomeController_banner(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHomeControllerBannerParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BannerResponse>('/salesgateway/home/banner', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HomeController_banner',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 新闻首页列表 POST /salesgateway/home/news-index */
export async function OperationId_salesgateway_HomeController_news(
  body: API.NewsIndexRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ClassificationWithContentVo[]>('/salesgateway/home/news-index', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HomeController_news',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 新闻详情 GET /salesgateway/home/news/${param0} */
export async function OperationId_salesgateway_HomeController_newsDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHomeControllerNewsDetailParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.NewsContentVo>(`/salesgateway/home/news/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HomeController_newsDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 新闻所有分类 GET /salesgateway/home/news/all-class */
export async function OperationId_salesgateway_HomeController_listAllClass(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.ClassificationVo[]>('/salesgateway/home/news/all-class', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HomeController_listAllClass',
    },
    ...(rest || {}),
  });
}

/** 根据分类获取内容分页列表 GET /salesgateway/home/news/list */
export async function OperationId_salesgateway_HomeController_pageContent(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHomeControllerPageContentParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.NewsPageResponse>('/salesgateway/home/news/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HomeController_pageContent',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 根据分类获取推荐内容分页列表 GET /salesgateway/home/news/list-recommend */
export async function OperationId_salesgateway_HomeController_pageRecommendContent(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHomeControllerPageRecommendContentParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.NewsPageResponse>('/salesgateway/home/news/list-recommend', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HomeController_pageRecommendContent',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
