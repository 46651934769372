import type { SiteType } from '../context';

interface UserInfoProps {
  accountId: string;
  name: string;
  email: string;
  mobile: string;
}

interface BussinessProps {
  enterpriseId: string;
  isSaler: boolean;
  state: string;
  name: string;
}
interface AttributeProps {
  [key: string]: string | AttributeProps | undefined;
}
interface Referrence {
  currentPageName?: string;
  currentPageSiteType?: SiteType;
  prePageName?: string;
  prePageSite?: SiteType;
}

interface CommonAttributeProps {
  account_id: string;
  user_name: string;
  user_mobile: string;
  user_email: string;
  enterprise_id: string;
  enterprise_name: string;
  user_type: string;
  enterprise_authentication_status: string;
  source_page: Referrence;
}

const enterpriseStatus = {
  CERTIFICATION_STATUS_TYPE_UNAUTHORIZED: '未认证',
  CERTIFICATION_STATUS_TYPE_PROGRESSING: '认证中',
  CERTIFICATION_STATUS_TYPE_FAILED: '认证失败',
  CERTIFICATION_STATUS_TYPE_COMPLETED: '已认证',
};
const userType = {
  saler: '卖家',
  buier: '买家',
};
const REFERRER_PAGE = process.env.NEXT_PUBLIC_TRACKER_REFERRER;
export const easygoTracker = (
  eventName: string,
  attribute?: AttributeProps | ((commonAttrs: CommonAttributeProps) => AttributeProps),
  eventType?: 'beconEvent'
) => {
  try {
    const userInfo = sessionStorage.getItem('_easygo_user') ?? '';
    const bussinessInfo = sessionStorage.getItem('_easygo_buss') ?? '';
    const userInfoFormat = userInfo ? JSON.parse(userInfo as string) : '';
    const bussinessInfoFormat = bussinessInfo ? JSON.parse(bussinessInfo as string) : '';
    const { accountId, name: userName, email, mobile } = userInfoFormat as UserInfoProps;
    const { state, enterpriseId, name: enterpriseName } = bussinessInfoFormat as BussinessProps;
    const userTypeInfo = localStorage.getItem(`${accountId}E-TYPE`) ?? {};
    const sourcePage: Referrence = JSON.parse(sessionStorage.getItem(REFERRER_PAGE) ?? '{}');
    const commonAttr: CommonAttributeProps = {
      account_id: accountId,
      user_name: userName,
      user_mobile: mobile,
      user_email: email,
      enterprise_id: enterpriseId,
      enterprise_name: enterpriseName,
      user_type: userType[userTypeInfo as keyof typeof userTypeInfo],
      enterprise_authentication_status: enterpriseStatus[state as keyof typeof enterpriseStatus],
      source_page: sourcePage,
    };
    const realAttr = typeof attribute === 'function' ? attribute(commonAttr) : attribute;
    console.log(`埋点触发事件 ${eventName}, ${JSON.stringify({ ...commonAttr, ...realAttr })}`);
    eventType
      ? window.collectEvent?.(eventType, eventName, { ...commonAttr, ...realAttr })
      : window.collectEvent?.(eventName, { ...commonAttr, ...realAttr });
  } catch (error: any) {
    throw new Error('数据上报错误', error);
  }
};

export function setCurrentTrackerPageName(siteType: SiteType, name?: string) {
  if (typeof window === 'object') {
    const pageName = name ?? location.href;
    let sessionPage: Referrence = JSON.parse(sessionStorage.getItem(REFERRER_PAGE) ?? '{}');
    let localPage: Referrence = JSON.parse(localStorage.getItem(REFERRER_PAGE) ?? '{}');
    if (sessionPage.currentPageName !== pageName) {
      const obj = JSON.stringify({
        currentPageName: pageName,
        currentPageSiteType: siteType,
        prePageName: sessionPage.currentPageName ?? localPage.currentPageName,
        prePageSite: sessionPage.currentPageSiteType ?? localPage.currentPageSiteType,
      });
      localStorage.setItem(REFERRER_PAGE, obj);
      sessionStorage.setItem(REFERRER_PAGE, obj);
    }
  }
}
