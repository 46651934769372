import BigNumber from 'bignumber.js';
import { useCallback, useMemo } from 'react';
import { useOpenConfigContext } from '../context/open-config-context';

/**
 * 获取货币字典
 * @returns
 */
export function useCurrencyDic() {
  const { currencyItems } = useOpenConfigContext();

  return currencyItems;
}

/**
 * 获取 货币类型->货币代码、货币类型->货币符号 映射表
 * @returns
 */
export function useCurrencyCodeAndSymbol() {
  const currencyOptions = useCurrencyDic();

  const { currencyCode, currencySymbol, currencyLabel } = useMemo(() => {
    const currencyCode: Record<string, string> = {};
    const currencySymbol: Record<string, string> = {};
    const currencyLabel: Record<string, string> = {};
    currencyOptions.forEach((item) => {
      currencyCode[item.currencyType] = item.currencyCode;
      currencySymbol[item.currencyType] = item.currencySymbol;
      currencyLabel[item.currencyType] = item.currencyName;
    });

    return { currencyCode, currencySymbol, currencyLabel };
  }, [currencyOptions]);

  return { currencyCode, currencySymbol, currencyLabel };
}

export interface CurrencyAmount {
  currency: string;
  amount: number;
}

export interface SortableCurrencyAmount extends CurrencyAmount {
  sort?: number;
}

/**
 * 对多币种金额计算合计，同币种金额累加
 * @param currencyAmountItems 多币种金额项目数组
 * @param filterZeroItem 是否过滤合并结果中金额为0的项目，默认过滤
 * @returns 合并后的多币种金额项目数组，每个币种最多一条记录
 */
export function multipleCurrencyAmountSum<T extends CurrencyAmount>(currencyAmountItems: T[], filterZeroItem: boolean = true) {
  const groupedItems: T[] = [];

  currencyAmountItems?.forEach((item) => {
    const targetItem = groupedItems.find((groupedItem) => groupedItem.currency === item.currency);
    if (targetItem) {
      targetItem.amount = BigNumber(targetItem.amount).plus(item.amount).toNumber();
    } else {
      groupedItems.push({ ...item });
    }
  });

  return filterZeroItem ? groupedItems.filter((item) => item.amount !== 0) : groupedItems;
}

export function useMultipleCurrencyAmountSum<T extends SortableCurrencyAmount>(currencyAmountItems: T[]) {
  const sum = useMemo<T[]>(() => multipleCurrencyAmountSum(currencyAmountItems), [currencyAmountItems]);

  return sum;
}

export interface FormatMutipleCurrencyAmountOption<T extends SortableCurrencyAmount> {
  seperator?: string;
  seperatorbettweenCurrencyAndAmount?: string;
  precision?: number;
  roundingMode?: BigNumber.RoundingMode;
  sortFn?: (preAmountItem: T, nextAmountItem: T) => number;
  currencyToSymbol?: Record<string, string> | ((currencyAmountItem: T) => string);
  zeroStr?: string;
}

const DEFAULT_MUTIL_CURRENCY_AMOUNT_TO_STR_OPTION: FormatMutipleCurrencyAmountOption<SortableCurrencyAmount> = {
  seperator: ' + ',
  seperatorbettweenCurrencyAndAmount: '',
  precision: 2,
  roundingMode: BigNumber.ROUND_HALF_CEIL,
  sortFn: (pre, next) => (next.sort || 0) - (pre.sort || 0),
  zeroStr: '0',
};

/**
 * 多币种金额数组转换成字符串
 * @param currencyAmountItems 多币种金额项目数组
 * @param option.seperator 多个币种项目直接的连接字符串，默认为 ' + '
 * @param option.seperatorbettweenCurrencyAndAmount 币种和金额直接的连接符，默认为空
 * @param option.precision 币种项目金额转换成字符串保留的小数位数，默认为 2
 * @param option.roundingMode 金额转换指定位数字符串时舍入规则，详见BigNumber.RoundingMode，默认为BigNumber.ROUND_HALF_CEIL，四舍五入
 * @param option.sortFn 多个币种项目排序的方法
 * @param option.currencyToSymbol 币种和币种符号的映射表，未提供则币种符号为空
 * @param option.zeroStr 总金额为0的时候显示的字符串，默认为 '0'
 * @returns
 */
export function formatMutipleCurrencyAmount<T extends SortableCurrencyAmount>(
  currencyAmountItems: T[],
  option?: FormatMutipleCurrencyAmountOption<T>
) {
  const {
    seperator,
    seperatorbettweenCurrencyAndAmount,
    precision,
    roundingMode,
    sortFn,
    currencyToSymbol = {},
    zeroStr,
  } = { ...DEFAULT_MUTIL_CURRENCY_AMOUNT_TO_STR_OPTION, ...option };

  if (currencyAmountItems.some((item) => BigNumber(item.amount).isNaN())) {
    return '-';
  }

  const accItems = multipleCurrencyAmountSum(currencyAmountItems);

  if (accItems.length === 0) {
    return zeroStr!;
  }

  return accItems
    .sort(sortFn)
    .map(
      (fee) =>
        `${
          (typeof currencyToSymbol === 'function' ? currencyToSymbol(fee) : currencyToSymbol[fee.currency]) || ''
        }${seperatorbettweenCurrencyAndAmount}${BigNumber(fee.amount).toFixed(precision!, roundingMode)}`
    )
    .join(seperator);
}

export function useFormatMutipleCurrencyAmount<T extends SortableCurrencyAmount>(
  currencyAmountItems: T[],
  option?: FormatMutipleCurrencyAmountOption<T>
) {
  const res = useMemo(() => formatMutipleCurrencyAmount(currencyAmountItems, option), [currencyAmountItems, option]);

  return res;
}

/**
 * 警告：在table的render函数中使用时需要用useLatest包一层，参考：packages/shared/src/components/ocean/performance/performance/fee-info/fee-list/index.tsx：56
 * @param formatOption
 * @returns
 */
export function useSimleMutipleCurrencyAmountFormator<T extends CurrencyAmount>(formatOption?: FormatMutipleCurrencyAmountOption<T>) {
  const currencyOptions = useCurrencyDic();

  const format = useCallback(
    (currencyAmountItems: T[] = [], option: FormatMutipleCurrencyAmountOption<T> | undefined = formatOption) => {
      const sortableCurrencyAmount = currencyAmountItems.map((item) => {
        const targetCurrency = currencyOptions.find((currency) => currency.currencyType === item.currency);
        return { ...item, sort: targetCurrency?.sort, currencySymbol: targetCurrency?.currencySymbol };
      });

      return formatMutipleCurrencyAmount(sortableCurrencyAmount, {
        currencyToSymbol: (item) => item.currencySymbol || '',
        ...option,
      });
    },
    [currencyOptions, formatOption]
  );

  return format;
}

export function useSimleFormatMutipleCurrencyAmount<T extends CurrencyAmount>(
  currencyAmountItems: T[],
  option?: FormatMutipleCurrencyAmountOption<T>
) {
  const formator = useSimleMutipleCurrencyAmountFormator<T>();

  const res = useMemo(() => formator(currencyAmountItems, option), [formator, currencyAmountItems, option]);

  return res;
}
