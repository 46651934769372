import { getEnumsLabelValue } from '../utils';

/** 合同链状态 */
export enum ContractChainStatus {
  /** 未开始 */
  INIT = 'CONTRACT_CHAIN_STATUS_INIT',
  /** 合作中 */
  EFFECTIVE = 'CONTRACT_CHAIN_STATUS_EFFECTIVE',
  /** 终止 */
  TERMINATE = 'CONTRACT_CHAIN_STATUS_TERMINATE',
  /** 过期 */
  EXPIRED = 'CONTRACT_CHAIN_STATUS_EXPIRED',
}

export const ContractChainStatusLabel = {
  [ContractChainStatus.INIT]: '未开始',
  [ContractChainStatus.EFFECTIVE]: '合作中',
  [ContractChainStatus.TERMINATE]: '终止',
  [ContractChainStatus.EXPIRED]: '过期',
};

export const contractChainStatusOptions = getEnumsLabelValue(ContractChainStatusLabel);

// 空重
export enum StandardBusinessFeeBoxContent {
  EMPTY = 'STANDARD_BUSINESS_FEE_BOX_CONTENT_E',
  FULL = 'STANDARD_BUSINESS_FEE_BOX_CONTENT_F',
}
export const StandardBusinessFeeBoxContentLabel = {
  [StandardBusinessFeeBoxContent.EMPTY]: '空箱',
  [StandardBusinessFeeBoxContent.FULL]: '重箱',
};

export const standardBusinessFeeBoxContentOptions = getEnumsLabelValue(StandardBusinessFeeBoxContentLabel);

// 计费方式
export enum StandardBusinessFeeChargingType {
  ONCE = 'STANDARD_BUSINESS_FEE_CHARGING_TYPE_ONCE',
  DAYS = 'STANDARD_BUSINESS_FEE_CHARGING_TYPE_DAYS',
}

export const StandardBusinessFeeChargingTypeLabel = {
  [StandardBusinessFeeChargingType.ONCE]: '按次',
  [StandardBusinessFeeChargingType.DAYS]: '按天',
};

export const standardBusinessFeeChargingTypeOptions = getEnumsLabelValue(StandardBusinessFeeChargingTypeLabel);

/** 费用承担/付款方 */
export enum StandardBusinessFeePayerType {
  /** 营运人 */
  OPERATOR = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_OPERATOR',
  /** 用箱人 */
  SUITCASE_CARRIER = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_SUITCASE_CARRIER',
  /** 箱东 */
  CONTAINER_OWNER = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_CONTAINER_OWNER',
  /** 船代 */
  SHIPPING_AGENCY = 'STANDARD_BUSINESS_FEE_PAYER_TYPE_SHIPPING_AGENCY',
}

export const StandardBusinessFeePayerTypeLabel = {
  [StandardBusinessFeePayerType.OPERATOR]: '营运人',
  [StandardBusinessFeePayerType.SUITCASE_CARRIER]: '用箱人',
  [StandardBusinessFeePayerType.CONTAINER_OWNER]: '箱东',
  [StandardBusinessFeePayerType.SHIPPING_AGENCY]: '船代',
};

export const standardBusinessFeePayerTypeOptions = getEnumsLabelValue(StandardBusinessFeePayerTypeLabel);

export enum SettlementRuleMode {
  /** 按特殊价结算 */
  SPECIAL = 'SPECIAL',
  /** 按标准价结算 */
  STANDARD = 'STANDARD',
}

export const SettlementRuleModeLabel = {
  [SettlementRuleMode.SPECIAL]: '按特殊价结算',
  [SettlementRuleMode.STANDARD]: '按白菜价结算',
};

export const settlementRuleModeOptions = getEnumsLabelValue(SettlementRuleModeLabel);

export enum SettlementBusinessScopeType {
  /** 出口提空 */
  A = 'business_a',
  /** 进口还空 */
  C = 'business_c',
  /** 租箱还空 */
  D = 'business_d',
  /** 租箱提空 */
  E = 'business_e',
  /** 暂存提箱 */
  F = 'business_f',
  /** 暂存落箱 */
  G = 'business_g',
  /** 集港落重 */
  H = 'business_h',
  /** 集港提重 */
  I = 'business_i',
  /** 长期堆存 */
  J = 'business_j',
}

export enum SettlementFeeBillingMode {
  /** 按天计费 */
  DAY = 'DAY',
  /** 按次计费 */
  TIMES = 'TIMES',
}

export const SettlementBusinessScopeTypeLabel = {
  [SettlementBusinessScopeType.A]: '出口提空',
  [SettlementBusinessScopeType.C]: '进口还空',
  [SettlementBusinessScopeType.D]: '租箱还空',
  [SettlementBusinessScopeType.E]: '租箱提空',
  [SettlementBusinessScopeType.F]: '暂存提箱',
  [SettlementBusinessScopeType.G]: '暂存落箱',
  [SettlementBusinessScopeType.H]: '集港落重',
  [SettlementBusinessScopeType.I]: '集港提重',
  [SettlementBusinessScopeType.J]: '长期堆存',
};

export const settlementBusinessScopeTypeOptions = getEnumsLabelValue(SettlementBusinessScopeTypeLabel);

export const SettlementFeeBillingModeLabel = {
  [SettlementFeeBillingMode.DAY]: '按天计费',
  [SettlementFeeBillingMode.TIMES]: '按次计费',
};

export const settlementFeeBillingModeOptions = getEnumsLabelValue(SettlementFeeBillingModeLabel);
