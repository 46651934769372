// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 售后申请 POST /salesgateway/aftersale/shipping/api/afterSaleOrderReq */
export async function OperationId_salesgateway_ShippingSaleController_toAfterSaleOrderReq(
  body: API.AfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/shipping/api/afterSaleOrderReq',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_toAfterSaleOrderReq',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 平台申诉 POST /salesgateway/aftersale/shipping/api/applyPlatformAfterSale */
export async function OperationId_salesgateway_ShippingSaleController_applyPlatformAfterSaleByBuyer(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/shipping/api/applyPlatformAfterSale',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ShippingSaleController_applyPlatformAfterSaleByBuyer',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 同意退款 POST /salesgateway/aftersale/shipping/api/approveAfterSaleReq */
export async function OperationId_salesgateway_ShippingSaleController_approveAfterSaleReq(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/shipping/api/approveAfterSaleReq',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_approveAfterSaleReq',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家驳回申请 POST /salesgateway/aftersale/shipping/api/buyer/refuseAfterSaleReq */
export async function OperationId_salesgateway_ShippingSaleController_refuseAfterSaleReqByBuyer(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/shipping/api/buyer/refuseAfterSaleReq',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_refuseAfterSaleReqByBuyer',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 取消售后 POST /salesgateway/aftersale/shipping/api/cancelAfterSaleOrder */
export async function OperationId_salesgateway_ShippingSaleController_cancelAfterSaleOrder(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/shipping/api/cancelAfterSaleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_cancelAfterSaleOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取取消售后申请列表 POST /salesgateway/aftersale/shipping/api/cancelAfterSaleOrderApply */
export async function OperationId_salesgateway_ShippingSaleController_cancelAfterSaleOrderApply(
  body: API.AfterSaleReasonRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AfterSaleReasonResponseVo>(
    '/salesgateway/aftersale/shipping/api/cancelAfterSaleOrderApply',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_cancelAfterSaleOrderApply',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 提交售后申请 POST /salesgateway/aftersale/shipping/api/commitAfterSaleOrder */
export async function OperationId_salesgateway_ShippingSaleController_commitAfterSaleOrder(
  body: API.CommitAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CommitAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/shipping/api/commitAfterSaleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_commitAfterSaleOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询售后配置 GET /salesgateway/aftersale/shipping/api/config/${param0} */
export async function OperationId_salesgateway_ShippingSaleController_getConfig(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingSaleControllerGetConfigParams,
  options?: { [key: string]: any },
) {
  const { reqId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ConfigResponseVo>(`/salesgateway/aftersale/shipping/api/config/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSaleController_getConfig',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询海舱售后申请详情 POST /salesgateway/aftersale/shipping/api/detail */
export async function OperationId_salesgateway_ShippingSaleController_get(
  body: API.QueryAfterSaleOrderDetailReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryAfterSaleOrderDetailResponseVo>(
    '/salesgateway/aftersale/shipping/api/detail',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_get',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询海海舱售后列表 POST /salesgateway/aftersale/shipping/api/list */
export async function OperationId_salesgateway_ShippingSaleController_list(
  body: API.SearchAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchAfterSaleOrderResponseVo>('/salesgateway/aftersale/shipping/api/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSaleController_list',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家驳回申请 POST /salesgateway/aftersale/shipping/api/seller/refuseAfterSaleReq */
export async function OperationId_salesgateway_ShippingSaleController_refuseAfterSaleReqBySeller(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/shipping/api/seller/refuseAfterSaleReq',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingSaleController_refuseAfterSaleReqBySeller',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
