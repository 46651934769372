import { Select, Spin } from 'antd';
import { useRequest } from 'ahooks';
import type { SelectProps } from 'antd/es/select';
import { CaretDownOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useMemo } from 'react';

type IData = { value: number | string; label: string }[];

type IProps = {
  value?: any;
  onChange?: (value: any) => void;
  fetchList: (v: string) => Promise<any>;
  valueKey?: string;
  labelKey?: string;
  initData?: IData;
  initSearch?: boolean;
  customChange?: (value: any, current: any) => void;
};

const transformOptions = (data = [], valueKey: string, labelKey: string) => data.map((i) => ({ value: i[valueKey], label: i[labelKey] }));

const AsyncSelect: React.FC<IProps & SelectProps> = (props) => {
  const { onChange, fetchList, customChange, valueKey = 'id', initSearch = false, labelKey = 'name', initData = [], value, mode, ...rest } = props;

  const firstData = initData?.[0];

  const [data, setData] = useState<IData>(firstData?.value && firstData?.label ? initData : []);

  const { loading, run } = useRequest(fetchList, {
    debounceWait: 500,
    manual: true,
    onSuccess: (result) => {
      setData(transformOptions(result, valueKey, labelKey));
    },
  });

  const handleChange = (val: any) => {
    onChange?.(val);
    customChange?.(
      val,
      data.find((i) => i.value === val)
    );
  };

  const _value = useMemo(() => {
    if (!data.find((i) => i.value === value) && mode !== 'multiple') return undefined;

    if (mode === 'multiple' && value && value.every((i: any) => typeof i === 'object' && i !== null)) {
      return value.map((i: any) => i.value);
    }
    return value;
  }, [value, JSON.stringify(data)]);

  useEffect(() => {
    firstData?.value && firstData?.label && setData(initData);
  }, [JSON.stringify(initData)]);

  useEffect(() => {
    return () => {
      onChange?.(undefined);
    };
  }, []);

  useEffect(() => {
    initSearch && run('');
  }, [initSearch]);

  return (
    <Select
      mode={mode}
      value={_value}
      showSearch
      filterOption={false}
      onSearch={(val) => {
        val && run(val);
      }}
      suffixIcon={<CaretDownOutlined style={{ color: '#646566', pointerEvents: 'none' }} />}
      notFoundContent={loading ? <Spin size="small" /> : data?.length <= 0 ? '暂无数据' : null}
      onChange={handleChange}
      options={data}
      {...rest}
    />
  );
};

export default AsyncSelect;
