import { Spin, Empty } from 'antd';
import type { SelectProps, LabeledValue } from 'antd/lib/select';
import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { Select } from '../select';
import styles from './index.module.less';
import type { ReactNode } from 'react';
import { useDebounceFn } from 'ahooks';
import classNames from 'classnames/bind';
import { CaretDownOutlined } from '@ant-design/icons';
import { useComponentTrans } from '../config';

const cx = classNames.bind(styles);

export interface ValueItem {
  label: ReactNode;
  value: string | number;
  [key: string]: any;
}

export type RequestParamsType = {
  current: number;
  pageSize: number;
  keyword?: string;
};

export type ResponseType = {
  list: ValueItem[];
  total: number;
  success: boolean;
};

export type PropTypes = Omit<SelectProps<any>, 'mode' | 'value' | 'onChange'> & {
  mode?: 'multiple' | boolean;
  isFetchOnOpen?: boolean;
  initReq?: boolean;
  request: (params: RequestParamsType) => Promise<ResponseType>;
  pageSize?: number;
  notFinishedTip?: boolean;
};

export interface SinglrPropTypes extends PropTypes {
  value?: ValueItem;
  onChange?: (value: ValueItem, option: ValueItem) => void;
  optionNode?: (options: ValueItem) => ReactNode;
}

export interface MultiplePropTypes extends PropTypes {
  value?: ValueItem[];
  onChange?: (value: ValueItem[], option: ValueItem[]) => void;
  optionNode?: (options: ValueItem) => ReactNode;
}

export function ProSelect({ optionNode, ...props }: SinglrPropTypes | MultiplePropTypes) {
  const trans = useComponentTrans();
  const {
    placeholder = trans('remote_select_placeholder'),
    bordered = true,
    allowClear = true,
    mode,
    isFetchOnOpen,
    initReq,
    request,
    pageSize = 15,
    onChange,
    notFinishedTip,
    ...restProps
  } = props;
  const [options, setOptions] = useState<LabeledValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const paramRef = useRef<RequestParamsType & { total?: number }>({
    current: 1,
    pageSize,
  });
  const uniqueMap = useRef<Record<string | number, boolean>>({});
  const optCache = useRef<LabeledValue[]>([]);
  const domRef = useRef<HTMLDivElement>(null);
  const init = useCallback(() => {
    setOptions([]);
    optCache.current = [];
    paramRef.current = { current: 1, pageSize };
    setFinished(false);
    uniqueMap.current = {};
  }, [pageSize]);
  const { run, flush } = useDebounceFn(
    async (keyword?: string) => {
      if (keyword || isFetchOnOpen) {
        let opts: LabeledValue[] = [];
        try {
          setLoading(true);
          const { success, list, total } = await request({
            ...paramRef.current,
            keyword,
          });
          if (success) {
            paramRef.current = {
              ...paramRef.current,
              total,
              keyword,
            };
            opts = list.filter((it) => {
              const isExit = uniqueMap.current[it.value];
              uniqueMap.current[it.value] = true;
              return !isExit;
            });
          }
        } catch (e: any) {
          throw new Error(e.message);
        } finally {
          let arr = [...opts];
          if (keyword === paramRef.current.keyword) {
            arr = [...optCache.current, ...opts];
          }
          if (arr.length === paramRef.current.total || opts.length < pageSize) {
            setFinished(true);
          }
          setOptions(arr);
          optCache.current = arr;
          setLoading(false);
        }
      }
    },
    {
      wait: 500,
    }
  );

  useEffect(() => {
    if (initReq) {
      init();
      run();
      flush();
    }
  }, []);
  const inputProps = useMemo<SelectProps>(() => {
    const prop: SelectProps = {
      placeholder,
      bordered,
      allowClear,
      options: optionNode ? undefined : options,
      notFoundContent: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      filterOption: false,
      showSearch: true,
      labelInValue: true,
      onSearch: (keyword?: string) => {
        init();
        run(keyword);
      },
      onDropdownVisibleChange: (visible: boolean) => {
        if (visible && isFetchOnOpen) {
          init();
          run();
          flush();
        }
      },
      onChange: (value: ValueItem & ValueItem[], option: any) => {
        if (Array.isArray(value) && options.length) {
          onChange?.(
            value.map((item) => {
              const target = options.find((it) => it.value === item.value);
              return {
                ...target,
                ...item,
              };
            }) as any,
            option
          );
        } else {
          onChange?.(props?.labelInValue ? option : value, option);
        }
      },
      onPopupScroll: (e: any) => {
        if (e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) < 20 && !finished && !loading) {
          paramRef.current = {
            ...paramRef.current,
            current: paramRef.current.current + 1,
          };
          run(paramRef.current?.keyword);
          flush();
        }
      },
      getPopupContainer: () => domRef.current as HTMLElement,
      dropdownRender: (originNode: ReactNode) => {
        return (
          <div className={cx(['downWrap', { isEmpty: options.length === 0 }])}>
            {originNode}
            {options.length ? (
              notFinishedTip ? (
                loading ? (
                  <div className={styles.tip}>
                    <Spin />
                  </div>
                ) : finished ? null : (
                  <div className={styles.tip}>
                    <span className={styles.tipText}>{trans('remote_select_tip')}</span>
                  </div>
                )
              ) : (
                <div className={styles.tip}>
                  {loading ? (
                    <Spin />
                  ) : (
                    <span className={styles.tipText}>{finished ? trans('remote_select_no_more') : trans('remote_select_tip')}</span>
                  )}
                </div>
              )
            ) : null}
          </div>
        );
      },
      ...restProps,
    };
    if (mode) {
      prop.mode = 'multiple';
    }
    return prop;
  }, [allowClear, bordered, finished, flush, init, isFetchOnOpen, loading, mode, onChange, options, placeholder, restProps, run, trans, initReq]);
  const selectRender = useMemo(() => {
    return (
      <Select style={{ width: '100%' }} showArrow={false} {...inputProps}>
        {options.map((option) => optionNode?.(option))}
      </Select>
    );
  }, [inputProps, optionNode, options]);
  return (
    <div className={styles.selectWrap} ref={domRef}>
      {selectRender}
      <div className={styles.arrow}>
        <CaretDownOutlined />
      </div>
    </div>
  );
}
