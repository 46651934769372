// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** banner查询 GET /salesgateway/v2/open/home/banner */
export async function OperationId_salesgateway_OpenHomeController_banner(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenHomeControllerBannerParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BannerResponse>('/salesgateway/v2/open/home/banner', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenHomeController_banner',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 新闻首页列表 POST /salesgateway/v2/open/home/news-index */
export async function OperationId_salesgateway_OpenHomeController_news(
  body: API.NewsIndexRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ClassificationWithContentVo[]>('/salesgateway/v2/open/home/news-index', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenHomeController_news',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 新闻所有分类 GET /salesgateway/v2/open/home/news/all-class */
export async function OperationId_salesgateway_OpenHomeController_listAllClass(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.ClassificationVo[]>('/salesgateway/v2/open/home/news/all-class', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenHomeController_listAllClass',
    },
    ...(rest || {}),
  });
}

/** 根据分类获取内容分页列表 GET /salesgateway/v2/open/home/news/list */
export async function OperationId_salesgateway_OpenHomeController_pageContent(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenHomeControllerPageContentParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.NewsPageResponse>('/salesgateway/v2/open/home/news/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenHomeController_pageContent',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 通过验证码交换安全码 GET /salesgateway/v2/open/home/secure-code */
export async function OperationId_salesgateway_OpenHomeController_exchangeSecureCodeByVerifyCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenHomeControllerExchangeSecureCodeByVerifyCodeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ExchangeSecureCodeByVerifyCodeResponseVO>(
    '/salesgateway/v2/open/home/secure-code',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OpenHomeController_exchangeSecureCodeByVerifyCode',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 发送验证码，有效期120s POST /salesgateway/v2/open/home/verify-code */
export async function OperationId_salesgateway_OpenHomeController_sendVerifyCode(
  body: API.SendVerifyCodeVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SendVerifyCodeResponseVO>('/salesgateway/v2/open/home/verify-code', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenHomeController_sendVerifyCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
