// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 卖家撤销接口 PUT /invoice/v1/seller/apply-invoice/cancel */
export async function OperationId_businessinvoice_SellerApplyInvoiceController_cancelInvoiceDetail(
  body: API.CancelReasonRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/invoice/v1/seller/apply-invoice/cancel', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_SellerApplyInvoiceController_cancelInvoiceDetail',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家获取申请发票详情 GET /invoice/v1/seller/apply-invoice/detail/${param0} */
export async function OperationId_businessinvoice_SellerApplyInvoiceController_getApplyInvoiceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinessinvoiceSellerApplyInvoiceControllerGetApplyInvoiceDetailParams,
  options?: { [key: string]: any },
) {
  const { applyInvoiceDetailId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ApplyInvoiceDetailBO>(`/invoice/v1/seller/apply-invoice/detail/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_SellerApplyInvoiceController_getApplyInvoiceDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 卖家获取申请发票列表 POST /invoice/v1/seller/apply-invoice/list */
export async function OperationId_businessinvoice_SellerApplyInvoiceController_getApplyInvoiceList(
  body: API.ApplyInvoiceListRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageSurApplyInvoiceDTO>('/invoice/v1/seller/apply-invoice/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_SellerApplyInvoiceController_getApplyInvoiceList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
