import { getInitialMoney, resolveCurrency } from '@easygo/utils/src/utils';
import dayjs from 'dayjs';
import type { dateRangeValueProps, FieldProps } from './interface';
import { getLabelByEnumValue } from './utils';
import AmountsArrayDesc from '../amounts-array-desc';

const descTypes: Record<string, (props: FieldProps, record: any, index: number) => JSX.Element | string> = {
  select: (props: FieldProps) => {
    return (getLabelByEnumValue(props.value, props?.options || {}) || props.value) ?? '-';
  },
  time: (props: FieldProps) => {
    return props.value ? dayjs(props.value).format(`${props?.format ?? 'YYYY-MM-DD HH:mm:ss'}`) : '-';
  },
  day: (props: FieldProps) => {
    return props.value ? dayjs(props.value).format(`${props?.format ?? 'YYYY-MM-DD'}`) : '-';
  },
  amount: ({ value, priceKey = 'amount', currencyKey = 'coin' }: FieldProps) => {
    return value ? getInitialMoney({ price: value?.[priceKey], currency: value?.[currencyKey], rate: value?.rate }) : '-';
  },
  amountsArray: (props: FieldProps) => <AmountsArrayDesc {...props}></AmountsArrayDesc>,
  ceilAmount: ({ value, priceKey = 'amount', currencyKey = 'coin' }: FieldProps) => {
    return value ? getInitialMoney({ price: value?.[priceKey], currency: value?.[currencyKey], rate: value?.rate }, 'ceil') : '-';
  },
  rmb: ({ value }) => (value !== undefined ? resolveCurrency({ price: value, currency: 'SKU_COIN_TYPE_RMB', rate: 1 }) : '-'),
  dollar: ({ value }) => (value !== undefined ? resolveCurrency({ price: value, currency: 'SKU_COIN_TYPE_DOLLAR', rate: 1 }) : '-'),
  dateRange: ({ value, format = 'YYYY-MM-DD' }: { value: dateRangeValueProps; format: string }) => {
    return value ? `${dayjs(value.startTime).format(format)} ~ ${dayjs(value.endTime).format(format)}` : '-';
  },
  index: (_, row, index) => <span>{index + 1}</span>,
};

export default descTypes;
