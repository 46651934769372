// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 订单确认 POST /salesgateway/v1/declaration/performance/seller/confirm */
export async function OperationId_salesgateway_DeclarationPerformanceSellerController_orderConfirm(
  body: API.DeclarationOrderBaseReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/declaration/performance/seller/confirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationPerformanceSellerController_orderConfirm',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取订单关联买家信息 GET /salesgateway/v1/declaration/performance/seller/getBuyers */
export async function OperationId_salesgateway_DeclarationPerformanceSellerController_getBuyers(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseInfo[]>(
    '/salesgateway/v1/declaration/performance/seller/getBuyers',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationPerformanceSellerController_getBuyers',
      },
      ...(rest || {}),
    },
  );
}

/** 报关卖家订单列表 POST /salesgateway/v1/declaration/performance/seller/orderList */
export async function OperationId_salesgateway_DeclarationPerformanceSellerController_orderList(
  body: API.DeclarationPageOrderBaseRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CustomerOrderListVO>(
    '/salesgateway/v1/declaration/performance/seller/orderList',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationPerformanceSellerController_orderList',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 海关订单卖家列表banner GET /salesgateway/v1/declaration/performance/seller/orderListBanner */
export async function OperationId_salesgateway_DeclarationPerformanceSellerController_orderListBanner(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.CustomerOrderBannerVO[]>(
    '/salesgateway/v1/declaration/performance/seller/orderListBanner',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceSellerController_orderListBanner',
      },
      ...(rest || {}),
    },
  );
}

/** 卖家通关 PUT /salesgateway/v1/declaration/performance/seller/performance/customs-credentials/${param0} */
export async function OperationId_salesgateway_DeclarationPerformanceSellerController_customsCredentials(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationPerformanceSellerControllerCustomsCredentialsParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/v1/declaration/performance/seller/performance/customs-credentials/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceSellerController_customsCredentials',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 拒绝接单 POST /salesgateway/v1/declaration/performance/seller/reject */
export async function OperationId_salesgateway_DeclarationPerformanceSellerController_rejectedOrder(
  body: API.DeclarationOrderBaseReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/declaration/performance/seller/reject', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationPerformanceSellerController_rejectedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 修改订单 POST /salesgateway/v1/declaration/performance/seller/updateOrder */
export async function OperationId_salesgateway_DeclarationPerformanceSellerController_createOrder(
  body: API.DeclarationCreateOrderReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DeclarationCreateOrderVo>(
    '/salesgateway/v1/declaration/performance/seller/updateOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationPerformanceSellerController_createOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
