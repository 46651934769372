// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询堆场详情信息 GET /salesgateway/yard/${param0}/detail */
export async function OperationId_salesgateway_YardController_queryYardDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardControllerQueryYardDetailParams,
  options?: { [key: string]: any },
) {
  const { locationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.YardDetailVo>(`/salesgateway/yard/${param0}/detail`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardController_queryYardDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询当前用户关联堆场 GET /salesgateway/yard/bind/simple/list */
export async function OperationId_salesgateway_YardController_listSimpleBindYard(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardControllerListSimpleBindYardParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SimpleYardVO[]>('/salesgateway/yard/bind/simple/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardController_listSimpleBindYard',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 堆场列表查询 GET /salesgateway/yard/list */
export async function OperationId_salesgateway_YardController_queryYards(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardControllerQueryYardsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryYardResponseVo>('/salesgateway/yard/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardController_queryYards',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询当前用户关联堆场及箱量 GET /salesgateway/yard/simple/list-with-box-num */
export async function OperationId_salesgateway_YardController_listSimpleYardWithBoxNum(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardControllerListSimpleYardWithBoxNumParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SimpleYardWithBoxNumVO[]>('/salesgateway/yard/simple/list-with-box-num', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardController_listSimpleYardWithBoxNum',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}
