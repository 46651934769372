// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询堆场城市 GET /salesgateway/yard/website/cities */
export async function OperationId_salesgateway_YardWebsiteController_listYardCities(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardWebsiteControllerListYardCitiesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.YardCountryResponse[]>('/salesgateway/yard/website/cities', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_listYardCities',
    },
    params: {
      ...params,
      yardAddressQuery: undefined,
      ...params['yardAddressQuery'],
    },
    ...(rest || {}),
  });
}

/** 查询全量城市 GET /salesgateway/yard/website/cities/full */
export async function OperationId_salesgateway_YardWebsiteController_listFullCities(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardWebsiteControllerListFullCitiesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.FullCityResponse[]>('/salesgateway/yard/website/cities/full', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_listFullCities',
    },
    params: {
      ...params,
      fullCitiesQuery: undefined,
      ...params['fullCitiesQuery'],
    },
    ...(rest || {}),
  });
}

/** 添加客户线索 POST /salesgateway/yard/website/clue/add */
export async function OperationId_salesgateway_YardWebsiteController_addCustomerClue(
  body: API.AddCustomerClueRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/yard/website/clue/add', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_addCustomerClue',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 数据展示统计 GET /salesgateway/yard/website/data-show/count */
export async function OperationId_salesgateway_YardWebsiteController_dataShowCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.WebsiteDataShowCountResponse>('/salesgateway/yard/website/data-show/count', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_dataShowCount',
    },
    ...(rest || {}),
  });
}

/** 获取堆场详情 GET /salesgateway/yard/website/detail/${param0} */
export async function OperationId_salesgateway_YardWebsiteController_getYardDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardWebsiteControllerGetYardDetailParams,
  options?: { [key: string]: any },
) {
  const { yardId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.YardWebDetailResponse>(`/salesgateway/yard/website/detail/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_getYardDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取最新发布堆场数据 GET /salesgateway/yard/website/latest/data */
export async function OperationId_salesgateway_YardWebsiteController_latestYardData(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardWebsiteControllerLatestYardDataParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetYardDataResponse>('/salesgateway/yard/website/latest/data', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_latestYardData',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取附近堆场数据 GET /salesgateway/yard/website/near/data */
export async function OperationId_salesgateway_YardWebsiteController_nearYardData(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardWebsiteControllerNearYardDataParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetYardDataResponse>('/salesgateway/yard/website/near/data', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_nearYardData',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取优选堆场数据 GET /salesgateway/yard/website/optimize/data */
export async function OperationId_salesgateway_YardWebsiteController_optimizeYardData(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardWebsiteControllerOptimizeYardDataParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetYardDataResponse>('/salesgateway/yard/website/optimize/data', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_optimizeYardData',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取堆场列表(分页) POST /salesgateway/yard/website/page-list */
export async function OperationId_salesgateway_YardWebsiteController_pageYardList(
  body: API.PageYardListRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PageYardListResponse>('/salesgateway/yard/website/page-list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_pageYardList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询堆场费用 POST /salesgateway/yard/website/storageFee */
export async function OperationId_salesgateway_YardWebsiteController_queryStorageFee(
  body: API.YardStorageFeeRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.YardStorageFeeResponse>('/salesgateway/yard/website/storageFee', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardWebsiteController_queryStorageFee',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
