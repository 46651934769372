// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取箱东下拉列表 获取箱东下拉列表 POST /salesgateway/container-owner/yard-port-area/list-port-area-yard */
export async function OperationId_salesgateway_ContainerOwnerPortAreaYardController_listPortAreaYard(
  body: API.QuerySearchPortAreaYard,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchPortAreaYardRespVO>(
    '/salesgateway/container-owner/yard-port-area/list-port-area-yard',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerOwnerPortAreaYardController_listPortAreaYard',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 可用堆场列表 可用堆场列表 POST /salesgateway/container-owner/yard-port-area/page-available-yard */
export async function OperationId_salesgateway_ContainerOwnerPortAreaYardController_pageAvailableYard(
  body: API.QueryAvailableYardVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AvailableYardListVO>(
    '/salesgateway/container-owner/yard-port-area/page-available-yard',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerOwnerPortAreaYardController_pageAvailableYard',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 标星 标星 PATCH /salesgateway/container-owner/yard-port-area/set-star-number/${param0} */
export async function OperationId_salesgateway_ContainerOwnerPortAreaYardController_setStarNumber(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerPortAreaYardControllerSetStarNumberParams,
  options?: { [key: string]: any },
) {
  const { yardId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/container-owner/yard-port-area/set-star-number/${param0}`, {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerOwnerPortAreaYardController_setStarNumber',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}
