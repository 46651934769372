// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 增加用箱明细 POST /logisticssolution/v1/seller/container-orders/add-container-detail/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_addUsingBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerAddUsingBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/seller/container-orders/add-container-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_addUsingBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 批量操作用箱明细 POST /logisticssolution/v1/seller/container-orders/batch-handle-detail/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_batchHandleBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerBatchHandleBoxDetailParams,
  body: API.BatchHandleUsingBoxDetailRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BatchHandleUsingBoxDetailResponse>(
    `/logisticssolution/v1/seller/container-orders/batch-handle-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_batchHandleBoxDetail',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 箱子轨迹 GET /logisticssolution/v1/seller/container-orders/box-position-list/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_getBoxPositionList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerGetBoxPositionListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BoxPositionDTO[]>(
    `/logisticssolution/v1/seller/container-orders/box-position-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_getBoxPositionList',
      },
      params: {
        ...queryParams,
        request: undefined,
        ...queryParams['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 作废提/还箱单 PUT /logisticssolution/v1/seller/container-orders/cancel-pick-dropoff-order/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_cancelPickOrDropoffOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerCancelPickOrDropoffOrderParams,
  body: API.CanclePickOrDropoffRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/seller/container-orders/cancel-pick-dropoff-order/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_cancelPickOrDropoffOrder',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 删除用箱明细 POST /logisticssolution/v1/seller/container-orders/delete-container-detail/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_deleteUsingBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerDeleteUsingBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/seller/container-orders/delete-container-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_deleteUsingBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 修改用箱明细 POST /logisticssolution/v1/seller/container-orders/edit-container-detail/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_editUsingBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerEditUsingBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/seller/container-orders/edit-container-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_editUsingBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 提箱申请 POST /logisticssolution/v1/seller/container-orders/extract-box/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_extractBoxApply(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerExtractBoxApplyParams,
  body: API.ExtractBoxApplyRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/container-orders/extract-box/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerContainerOrderController_extractBoxApply',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 提箱记录详情 GET /logisticssolution/v1/seller/container-orders/extract-boxrecord-detail/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_extractBoxRecordDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerExtractBoxRecordDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickupDetailDTO[]>(
    `/logisticssolution/v1/seller/container-orders/extract-boxrecord-detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_extractBoxRecordDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 根据明细id获取已提的箱子 GET /logisticssolution/v1/seller/container-orders/get-after-box-list/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_getBoxListByPerformanceContainerDetailId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerGetBoxListByPerformanceContainerDetailIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(
    `/logisticssolution/v1/seller/container-orders/get-after-box-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_getBoxListByPerformanceContainerDetailId',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 根据履约单id获取所有已提还的箱子 GET /logisticssolution/v1/seller/container-orders/get-all-box-list/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_getAllBoxListByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerGetAllBoxListByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(
    `/logisticssolution/v1/seller/container-orders/get-all-box-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_getAllBoxListByPerformanceContainerId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 根据履约单id获取所有已经还的箱子 GET /logisticssolution/v1/seller/container-orders/get-already-box-list/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_getAlreadyBoxListByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerGetAlreadyBoxListByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(
    `/logisticssolution/v1/seller/container-orders/get-already-box-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_getAlreadyBoxListByPerformanceContainerId',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 根据用箱单获取用箱明细 GET /logisticssolution/v1/seller/container-orders/get-box-detail/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_getBoxDetailByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerGetBoxDetailByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetBoxDetailResponse[]>(
    `/logisticssolution/v1/seller/container-orders/get-box-detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_getBoxDetailByPerformanceContainerId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 根据履约单id获取所有已经提箱子 GET /logisticssolution/v1/seller/container-orders/get-box-list/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_getBoxListByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerGetBoxListByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(`/logisticssolution/v1/seller/container-orders/get-box-list/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerContainerOrderController_getBoxListByPerformanceContainerId',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 根据箱型获取堆场箱子库存 GET /logisticssolution/v1/seller/container-orders/location-stock-by-box-type/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_getLocationStockByBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerGetLocationStockByBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<number>(
    `/logisticssolution/v1/seller/container-orders/location-stock-by-box-type/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_getLocationStockByBoxType',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 预览提/还箱单 GET /logisticssolution/v1/seller/container-orders/preview-pickup-order/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_previewPickupOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerPreviewPickupOrderParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(
    `/logisticssolution/v1/seller/container-orders/preview-pickup-order/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_previewPickupOrder',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 查看用箱信息 GET /logisticssolution/v1/seller/container-orders/query-using-boxrecord/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_queryUsingBoxRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerQueryUsingBoxRecordParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UsingBoxRecordVo>(
    `/logisticssolution/v1/seller/container-orders/query-using-boxrecord/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_queryUsingBoxRecord',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 还箱申请 POST /logisticssolution/v1/seller/container-orders/repay-box/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_repayBoxApply(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerRepayBoxApplyParams,
  body: API.RepayBacktBoxApplyRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/container-orders/repay-box/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerContainerOrderController_repayBoxApply',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 还箱记录详情 GET /logisticssolution/v1/seller/container-orders/repay-boxrecord-detail/${param0} */
export async function OperationId_logisticssolution_SellerContainerOrderController_repayBoxRecordDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerContainerOrderControllerRepayBoxRecordDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DropoffDetailDTO[]>(
    `/logisticssolution/v1/seller/container-orders/repay-boxrecord-detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerContainerOrderController_repayBoxRecordDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}
