// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** (已废弃)获取对账单详情信息 GET /logisticssolution/v1/buyer/statement/${param0} */
export async function OperationId_logisticssolution_BuyerPerformanceOrderStatementController_getStatementDetailInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderStatementControllerGetStatementDetailInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderStatementDetailInfoVO>(
    `/logisticssolution/v1/buyer/statement/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerPerformanceOrderStatementController_getStatementDetailInfo',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 账单去支付 POST /logisticssolution/v1/buyer/statement/${param0}/toPay */
export async function OperationId_logisticssolution_BuyerPerformanceOrderStatementController_toPayStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderStatementControllerToPayStatementParams,
  body: API.ToPayPerformanceOrderStatementRequest,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ToPayPerformanceOrderStatementResponse>(
    `/logisticssolution/v1/buyer/statement/${param0}/toPay`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerPerformanceOrderStatementController_toPayStatement',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 分页查询对账单详情信息 POST /logisticssolution/v1/buyer/statement/list */
export async function OperationId_logisticssolution_BuyerPerformanceOrderStatementController_pageStatement(
  body: API.SalesBuyerStatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderBuyerStatementListItemVO>(
    '/logisticssolution/v1/buyer/statement/list',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerPerformanceOrderStatementController_pageStatement',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 列表查询对账单信息 GET /logisticssolution/v1/buyer/statement/performance/${param0}/statementList */
export async function OperationId_logisticssolution_BuyerPerformanceOrderStatementController_listStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderStatementControllerListStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderBuyerStatementListInfoVO>(
    `/logisticssolution/v1/buyer/statement/performance/${param0}/statementList`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerPerformanceOrderStatementController_listStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
