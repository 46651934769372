// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 港区堆场查询 GET /salesgateway/open/port/areas/${param0}/yard */
export async function OperationId_salesgateway_OpenPortAreaController_yard(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenPortAreaControllerYardParams,
  options?: { [key: string]: any },
) {
  const { portAreaId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.HomeYardVO[]>(`/salesgateway/open/port/areas/${param0}/yard`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_yard',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 查询港区城市 GET /salesgateway/open/port/areas/cities */
export async function OperationId_salesgateway_OpenPortAreaController_pagePortAreaCities(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenPortAreaControllerPagePortAreaCitiesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageAddressDTO>('/salesgateway/open/port/areas/cities', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_pagePortAreaCities',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 首页港区坐标查询 GET /salesgateway/open/port/areas/coordinates */
export async function OperationId_salesgateway_OpenPortAreaController_portAreaCoordinates(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PortAreaCoordinatesResponse>('/salesgateway/open/port/areas/coordinates', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_portAreaCoordinates',
    },
    ...(rest || {}),
  });
}

/** 查询港区国家 GET /salesgateway/open/port/areas/countries */
export async function OperationId_salesgateway_OpenPortAreaController_pagePortAreaCountries(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenPortAreaControllerPagePortAreaCountriesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageAddressDTO>('/salesgateway/open/port/areas/countries', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_pagePortAreaCountries',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 热门港区查询 GET /salesgateway/open/port/areas/hot */
export async function OperationId_salesgateway_OpenPortAreaController_hotPortArea(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.HotPortAreaResponse>('/salesgateway/open/port/areas/hot', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_hotPortArea',
    },
    ...(rest || {}),
  });
}

/** 查询港区详情 GET /salesgateway/open/port/areas/info */
export async function OperationId_salesgateway_OpenPortAreaController_getPortAreasInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenPortAreaControllerGetPortAreasInfoParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetPortAreaResponse>('/salesgateway/open/port/areas/info', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_getPortAreasInfo',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}

/** 分页查询港区列表 POST /salesgateway/open/port/areas/list */
export async function OperationId_salesgateway_OpenPortAreaController_pagePortAreasInfo(
  body: API.PagePortAreaRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PagePortAreaResponse>('/salesgateway/open/port/areas/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_pagePortAreasInfo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 官网港区下拉列表 POST /salesgateway/open/port/areas/sum/yard */
export async function OperationId_salesgateway_OpenPortAreaController_pagePortAreaWithYard(
  body: API.PagePortAreaWithYarQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PagePortAreaWithYardResponse>('/salesgateway/open/port/areas/sum/yard', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenPortAreaController_pagePortAreaWithYard',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
