import OSS from 'ali-oss';
import { request } from '@easygo/service';
import type {
  RequestPrivateFileAddress,
  RequestPrivateStsToken,
  RequestPublicFileAddress,
  RequestPublicStsToken,
  RequestPrivateFileUploadAddress,
} from './interface';

type UploadOptions = {
  onProgress?: (pervent: number) => void;
  onError?: (e: any) => void;
  onSuccess?: (url: string) => void;
  isPublic?: boolean;
};

type UploadConfigOptions = {
  /**
   * 获取私有文件真实地址接口
   */
  getPrivateFileAddress?: RequestPrivateFileAddress;
  /**
   * 获取公有文件真实地址接口
   */
  getPublicFileAddress?: RequestPublicFileAddress;
  /**
   * 获取私有文件上传地址
   */
  getPrivateUploadAddress?: RequestPrivateFileUploadAddress;
  /**
   * 获取公有STS上传临时Token
   */
  getPublicStsToken?: RequestPublicStsToken;
  /**
   * 获取私有STS上传临时Token
   */
  getPrivateStsToken?: RequestPrivateStsToken;
};
class UploadConfig {
  /**
   * 文件上传接口配置
   */
  public _config: UploadConfigOptions = {};
  /**
   * 文件上传接口配置方法
   */
  public config(config: Partial<UploadConfigOptions>) {
    this._config = {
      ...this._config,
      ...config,
    };
  }
}

export const uploadConfig = new UploadConfig();

class Upload {
  private client: OSS | undefined;
  private isPublic: boolean = false;
  private fileType: string = '';
  private suffix: string = '';
  private fileName: string = '';
  private dir: string = '';
  private async getClient(isPublic: boolean = false) {
    this.isPublic = isPublic;
    const data = await (this.isPublic
      ? uploadConfig._config.getPublicStsToken?.({ format: this.suffix })
      : uploadConfig._config.getPrivateStsToken?.({ format: this.suffix }));
    if (data) {
      this.fileName = data.name;
      this.dir = data.dir;
      const client = new OSS({
        secure: true,
        accessKeyId: data.accessKeyId,
        region: data.regionId,
        stsToken: data.securityToken,
        bucket: data.buket,
        accessKeySecret: data.accessKeySecret,
        // 刷新临时访问凭证。
        refreshSTSToken: async () => {
          // const {
          //   data: { data },
          // } = await common.refreshStsToken();
          // if (data) {
          //   return {
          //     secure: false,
          //     accessKeyId: data.accesskey,
          //     accessKeySecret: data.accessSecret,
          //     bucket: data.bucketName,
          //     stsToken: data?.stsToken,
          //     region: data.region,
          //   };
          // }
          throw new Error('refresh sts token faild');
        },
      });
      this.client = client;
      this.isPublic = isPublic;
      return client;
    }
    return null;
  }
  async multiUpload(file: File, opts?: UploadOptions) {
    try {
      this.fileType = file.type;
      const arr = file.name.split('.');
      this.suffix = arr[arr.length - 1];
      const client = await this.getClient(opts?.isPublic);
      const res: any = await client?.multipartUpload(`${this.dir}/${this.fileName}`, file, {
        partSize: 102400 * 5,
        progress: async (p, _checkpoint) => {
          opts?.onProgress?.(p * 100);
        },
      });
      let url;
      if (this.isPublic) {
        url = res.res.requestUrls[0].replace(/\?.+/, '').replace('http:', 'https:');
      } else {
        url = this.fileName;
      }
      opts?.onSuccess?.(url);
    } catch (e) {
      opts?.onError?.(e);
      throw e;
    }
  }
  async upload(file: File, opts?: UploadOptions) {
    try {
      const fn = opts?.isPublic ? uploadConfig._config.getPublicFileAddress : uploadConfig._config.getPrivateUploadAddress;
      const data = (await fn?.({ format: file.type })) ?? {};
      await request.put(data?.url!, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress(progressEvent) {
          opts?.onProgress?.(progressEvent);
        },
      });
      opts?.onSuccess?.(data?.name!);
    } catch (e) {
      opts?.onError?.(e);
    }
  }
}

/**
 * 获取私有文件地址
 * @param id 私有文件名
 * @param watermarkStyleName oss 水印样式名称
 * @returns
 */
export async function getFileAddress(id: string, watermarkStyleName?: string) {
  try {
    const url = await uploadConfig._config.getPrivateFileAddress?.({ name: id!, watermarkStyleName })!;
    return typeof url === 'string' ? url : url?.url;
  } catch (e) {
    return '';
  }
}

export default Upload;
