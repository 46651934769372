// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取报关服务费 GET /salesgateway/v1/declaration/common/declaration-fees */
export async function OperationId_salesgateway_DeclarationCommonController_getDeclareFees(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.DeclareFeeVO>('/salesgateway/v1/declaration/common/declaration-fees', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationCommonController_getDeclareFees',
    },
    ...(rest || {}),
  });
}

/** 获取报关模式 GET /salesgateway/v1/declaration/common/declaration-mode-types */
export async function OperationId_salesgateway_DeclarationCommonController_getDeclareModeTypes(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.DeclareModeTypeDTO[]>(
    '/salesgateway/v1/declaration/common/declaration-mode-types',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationCommonController_getDeclareModeTypes',
      },
      ...(rest || {}),
    },
  );
}

/** 获取报关类型 GET /salesgateway/v1/declaration/common/declaration-types */
export async function OperationId_salesgateway_DeclarationCommonController_getDeclareTypes(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.DeclareTypeDTO[]>('/salesgateway/v1/declaration/common/declaration-types', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationCommonController_getDeclareTypes',
    },
    ...(rest || {}),
  });
}

/** 获取运输类型 GET /salesgateway/v1/declaration/common/transport-types */
export async function OperationId_salesgateway_DeclarationCommonController_getTransportTypes(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.TransportTypeDTO[]>('/salesgateway/v1/declaration/common/transport-types', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationCommonController_getTransportTypes',
    },
    ...(rest || {}),
  });
}
