/**
 * 电话号码分段加空格
 * @param tel 电话号码
 * @returns 符合要求的电话会返回如下格式：135 7353 2312
 */
export function parserTelNumber(tel: string) {
  const result = /^(\d{3})(\d{4})(\d{4})$/.exec(tel);
  if (result) {
    return `${result[1]} ${result[2]} ${result[3]}`;
  }
  return tel;
}
