// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 租箱商品查询接口 此接口由于可提供给未登录的用户使用，所以不做权限控制，但因为涉及到暴露在公网，所以需要做请求频率限制和缓存 POST /salesgateway/container-leasing/products */
export async function OperationId_salesgateway_ContainerLeasingProductController_listContainerLeasingProducts(
  body: API.QueryContainerLeasingVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryContainerLeasingProductsResponseVo>(
    '/salesgateway/container-leasing/products',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingProductController_listContainerLeasingProducts',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询活动商品 POST /salesgateway/container-leasing/products/activity */
export async function OperationId_salesgateway_ContainerLeasingProductController_queryActivityProducts(
  body: API.QueryActivityProductVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryContainerLeasingProductsResponseVo>(
    '/salesgateway/container-leasing/products/activity',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingProductController_queryActivityProducts',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询推荐商品列表 POST /salesgateway/container-leasing/products/recommended */
export async function OperationId_salesgateway_ContainerLeasingProductController_queryRecommendedProducts(
  body: API.QueryRecommendedProductVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryContainerLeasingProductsResponseVo>(
    '/salesgateway/container-leasing/products/recommended',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingProductController_queryRecommendedProducts',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
