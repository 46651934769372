// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询铁路商品 GET /salesgateway/v1/railway/buyer/items */
export async function OperationId_salesgateway_RailwayBuyerProductController_pageItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerProductControllerPageItemParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageItemVO>('/salesgateway/v1/railway/buyer/items', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerProductController_pageItem',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取商品详情 GET /salesgateway/v1/railway/buyer/items/${param0} */
export async function OperationId_salesgateway_RailwayBuyerProductController_getItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerProductControllerGetItemParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ItemVO>(`/salesgateway/v1/railway/buyer/items/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerProductController_getItem',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 模糊查询获取城市车站选项 GET /salesgateway/v1/railway/buyer/items/city-station-options/name-fuzzy */
export async function OperationId_salesgateway_RailwayBuyerProductController_nameFuzzyCityStationOptions(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayBuyerProductControllerNameFuzzyCityStationOptionsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityStationSelectOptionDTO[]>(
    '/salesgateway/v1/railway/buyer/items/city-station-options/name-fuzzy',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_RailwayBuyerProductController_nameFuzzyCityStationOptions',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 获取搜索历史 GET /salesgateway/v1/railway/buyer/items/search-histories */
export async function OperationId_salesgateway_RailwayBuyerProductController_listSearchHistory(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.SearchHistoryDTO[]>('/salesgateway/v1/railway/buyer/items/search-histories', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayBuyerProductController_listSearchHistory',
    },
    ...(rest || {}),
  });
}
