// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 回箱线路列表 POST /salesgateway/open/container-back/line/api/list */
export async function OperationId_salesgateway_OpenContainerBackRouteOpenController_pageContainerBackLine(
  body: API.ContainerBackRoutePageReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerBackRoutePageResVO>(
    '/salesgateway/open/container-back/line/api/list',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_OpenContainerBackRouteOpenController_pageContainerBackLine',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
