/**
 * 国际化过滤
 * @param param
 * @returns
 * eg: ignoreI18n('关于我们')
 */
export const ignoreI18n = (param: any) => {
  if (typeof param === 'function') {
    return param();
  }
  return param;
};

/**
 * 对象包含不同语言字段，按语言取相应字段值
 * @return 根据locale自动取相应key的value
 * eg: data: {nameZh: '', nameEn: ''}
 * const resolveLocale = resolveLocaleKeyValue(locale)(
 *   (locale) => `name${locale.slice(0, 1).toUpperCase()}${locale.slice(1)}`
 * );
 * resolveLocale(data);
 */
export const resolveLocaleKeyValue =
  (locale: string = 'zh') =>
  (matchKey: (locale: string) => string, defaultKey?: string) =>
  <Data extends Record<string, any>>(data?: Data) => {
    if (!data) return '';
    const key = matchKey(locale);
    return data[key] || (defaultKey && data[defaultKey]);
  };

export const isChinese = (str: string) => {
  const reg = /[u00-uff]/;
  return !reg.test(str);
};
