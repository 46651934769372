// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 已报关 PUT /logisticssolution/v1/seller/declaration-orders/${param0}/release */
export async function OperationId_logisticssolution_SellerDeclarationOrderController_release(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerDeclarationOrderControllerReleaseParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/declaration-orders/${param0}/release`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerDeclarationOrderController_release',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
