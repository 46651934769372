// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 还箱单查询列表 POST /salesgateway/biz/container-leasing/fulfillment/drop */
export async function OperationId_salesgateway_BizFulfillmentController_dropList(
  body: API.DropListRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DropListResponseVo>('/salesgateway/biz/container-leasing/fulfillment/drop', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizFulfillmentController_dropList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 还箱单详情 GET /salesgateway/biz/container-leasing/fulfillment/drop/${param0} */
export async function OperationId_salesgateway_BizFulfillmentController_dropDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizFulfillmentControllerDropDetailParams,
  options?: { [key: string]: any },
) {
  const { applicationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DropDetailVo>(
    `/salesgateway/biz/container-leasing/fulfillment/drop/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizFulfillmentController_dropDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 作废提箱单 PUT /salesgateway/biz/container-leasing/fulfillment/drop/cancel/${param0} */
export async function OperationId_salesgateway_BizFulfillmentController_dropCancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizFulfillmentControllerDropCancelParams,
  options?: { [key: string]: any },
) {
  const { dropId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/biz/container-leasing/fulfillment/drop/cancel/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizFulfillmentController_dropCancel',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 确认还箱 POST /salesgateway/biz/container-leasing/fulfillment/drop/confirm */
export async function OperationId_salesgateway_BizFulfillmentController_dropConfirm(
  body: API.DropConfirmVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/biz/container-leasing/fulfillment/drop/confirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizFulfillmentController_dropConfirm',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 发放还箱单 POST /salesgateway/biz/container-leasing/fulfillment/drop/send */
export async function OperationId_salesgateway_BizFulfillmentController_dropSend(
  body: API.DropSendVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/biz/container-leasing/fulfillment/drop/send', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizFulfillmentController_dropSend',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 提箱单查询列表 POST /salesgateway/biz/container-leasing/fulfillment/pickup */
export async function OperationId_salesgateway_BizFulfillmentController_pickupList(
  body: API.PickupListRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PickupListResponseVo>(
    '/salesgateway/biz/container-leasing/fulfillment/pickup',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizFulfillmentController_pickupList',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 提箱单详情 GET /salesgateway/biz/container-leasing/fulfillment/pickup/${param0} */
export async function OperationId_salesgateway_BizFulfillmentController_pickupDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizFulfillmentControllerPickupDetailParams,
  options?: { [key: string]: any },
) {
  const { applicationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickupApplicationDetailVo>(
    `/salesgateway/biz/container-leasing/fulfillment/pickup/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizFulfillmentController_pickupDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 作废提箱单 PUT /salesgateway/biz/container-leasing/fulfillment/pickup/cancel/${param0} */
export async function OperationId_salesgateway_BizFulfillmentController_pickupCancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizFulfillmentControllerPickupCancelParams,
  options?: { [key: string]: any },
) {
  const { pickupId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/biz/container-leasing/fulfillment/pickup/cancel/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizFulfillmentController_pickupCancel',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 确认提箱 POST /salesgateway/biz/container-leasing/fulfillment/pickup/confirm */
export async function OperationId_salesgateway_BizFulfillmentController_pickupConfirm(
  body: API.PickupConfirmVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/biz/container-leasing/fulfillment/pickup/confirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizFulfillmentController_pickupConfirm',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 发放提箱单 POST /salesgateway/biz/container-leasing/fulfillment/pickup/send */
export async function OperationId_salesgateway_BizFulfillmentController_pickupSend(
  body: API.PickupSendVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/biz/container-leasing/fulfillment/pickup/send', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizFulfillmentController_pickupSend',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
