import { Card } from 'antd';
import type { ReactNode } from 'react';
import styles from './index.module.less';

const EasyCard = ({ children, ...rest }: { children: ReactNode; [key: string]: any }) => {
  return (
    <div className={styles.cardWrapper}>
      <Card {...rest}>{children}</Card>
    </div>
  );
};

export default EasyCard;
