// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询地址 GET /salesgateway/v2/open/config-center/addresses */
export async function OperationId_salesgateway_OpenConfigCenterController_pageAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenConfigCenterControllerPageAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageAddressDTO>('/salesgateway/v2/open/config-center/addresses', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenConfigCenterController_pageAddress',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 分页查询箱型 GET /salesgateway/v2/open/config-center/box-types */
export async function OperationId_salesgateway_OpenConfigCenterController_pageBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenConfigCenterControllerPageBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageBoxTypeDTO>('/salesgateway/v2/open/config-center/box-types', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenConfigCenterController_pageBoxType',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 分页查询铁路公司 GET /salesgateway/v2/open/config-center/railway-companies */
export async function OperationId_salesgateway_OpenConfigCenterController_pageRailwayCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenConfigCenterControllerPageRailwayCompanyParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageRailwayCompanyDTO>(
    '/salesgateway/v2/open/config-center/railway-companies',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OpenConfigCenterController_pageRailwayCompany',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 分页查询海运公司 GET /salesgateway/v2/open/config-center/shipping-companies */
export async function OperationId_salesgateway_OpenConfigCenterController_pageShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenConfigCenterControllerPageShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageShippingCompanyDTO>(
    '/salesgateway/v2/open/config-center/shipping-companies',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OpenConfigCenterController_pageShippingCompany',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}
