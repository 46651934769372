// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 附件查询 GET /salesgateway/v1/declaration/performance/common/attachment/list/${param0} */
export async function OperationId_salesgateway_DeclarationPerformanceCommonController_listAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationPerformanceCommonControllerListAttachmentParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.CustomsDeclareAttachmentVO[]>(
    `/salesgateway/v1/declaration/performance/common/attachment/list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceCommonController_listAttachment',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 附件删除 DELETE /salesgateway/v1/declaration/performance/common/attachment/remove/${param0} */
export async function OperationId_salesgateway_DeclarationPerformanceCommonController_removeAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationPerformanceCommonControllerRemoveAttachmentParams,
  options?: { [key: string]: any },
) {
  const { attachmentItemId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/v1/declaration/performance/common/attachment/remove/${param0}`,
    {
      method: 'DELETE',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceCommonController_removeAttachment',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 上传附件 POST /salesgateway/v1/declaration/performance/common/attachment/upload/${param0} */
export async function OperationId_salesgateway_DeclarationPerformanceCommonController_uploadAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationPerformanceCommonControllerUploadAttachmentParams,
  body: API.CustomsDeclareUploadAttachmentReq,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/v1/declaration/performance/common/attachment/upload/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceCommonController_uploadAttachment',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取履约详情 GET /salesgateway/v1/declaration/performance/common/detail/${param0} */
export async function OperationId_salesgateway_DeclarationPerformanceCommonController_performanceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationPerformanceCommonControllerPerformanceDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.CustomsDeclarePerformanceDetailVO>(
    `/salesgateway/v1/declaration/performance/common/detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceCommonController_performanceDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
