import { createContext, useContext, useReducer, useMemo } from 'react';
import type * as common from '@easygo/service/src/type';
import type { sale } from '@easygo/service';
import type { MutableRefObject, PropsWithChildren, FC } from 'react';

export type GlobalAction = {
  type:
    | 'INIT_SESSION'
    | 'INIT_BUSSINESS'
    | 'INIT_BUSS'
    | 'TOGGLE_TYPE'
    | 'LOGIN_OUT'
    | 'TOGGLE_LOADING'
    | 'INIT_PERMISS'
    | 'INIT_ACCOUNT_INSTANCE_TYPE'
    | 'SET_LOCALE'
    | 'INIT_STATE'
    | 'SET_AS_PATH';
  payload?: any;
};

export type GlobalContextType = {
  state: common.GlobalContextState;
  dispatch: (action: GlobalAction) => void;
  asyncActions: AsyncActions;
  navigate?: MutableRefObject<((path: string | -1, ...args: any) => void) | undefined>;
  refresh?: () => void;
};

export type AsyncActions = {
  toggleLoading: (loading: boolean) => void;
  toggleBussiness: (enterpriseId: string, cb?: () => void) => Promise<any>;
  toggleBussinssType: () => void;
  fetchBussinessList: (force: boolean) => Promise<sale.API.GetEnterpriseByAccountIDResponseVO['enterprises']>;
  fetchBussinessInfo: (force: boolean) => Promise<common.SessionBussinerss>;
  fetchUserInfo: (force: boolean) => Promise<common.PageSession>;
  refreshPermiss: (force?: boolean) => Promise<void>;
};

const defaultGlobalContextState: common.GlobalContextState = {
  session: {},
  permiss: [],
  system: undefined,
  bussiness: {},
  currState: 'buier',
  loading: true,
};

const GlobalContext = createContext<GlobalContextType>({
  state: defaultGlobalContextState,
  dispatch: () => {},
  asyncActions: {
    toggleLoading: () => {},
    toggleBussiness: () => Promise.resolve({}),
    toggleBussinssType: () => {},
    fetchBussinessList: () => Promise.resolve([]),
    fetchBussinessInfo: () => Promise.resolve({}),
    fetchUserInfo: () => Promise.resolve({}),
    refreshPermiss: () => Promise.resolve(),
  },
});

const globalReducer = (state: common.GlobalContextState = defaultGlobalContextState, action: GlobalAction) => {
  switch (action.type) {
    case 'INIT_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'INIT_SESSION':
      return {
        ...state,
        session: action.payload,
      };
    case 'INIT_BUSSINESS':
      return {
        ...state,
        bussinessList: action.payload,
      };
    case 'INIT_BUSS':
      return {
        ...state,
        bussiness: action.payload,
      };
    case 'TOGGLE_TYPE':
      return {
        ...state,
        currState: action.payload,
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'LOGIN_OUT':
      return {
        ...state,
        session: {},
        bussiness: {},
        bussinessList: [],
      };
    case 'INIT_PERMISS':
      return {
        ...state,
        permiss: action.payload,
      };
    case 'INIT_ACCOUNT_INSTANCE_TYPE':
      return {
        ...state,
        system: action.payload,
      };
    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.payload,
      };
    case 'SET_AS_PATH':
      return {
        ...state,
        asPath: action.payload,
      };
    default:
      return state;
  }
};

export const GlobalProvider = GlobalContext.Provider;

export const useGlobalProvider = (defaultState: Partial<common.GlobalContextState>) => {
  const [reducerState, dispatch] = useReducer(globalReducer, {
    ...defaultGlobalContextState,
    currState: 'buier',
    ...defaultState,
  });
  const GlobalProvider = useMemo<FC<PropsWithChildren<Omit<GlobalContextType, 'state' | 'dispatch'>>>>(() => {
    return ({ children, ...props }: PropsWithChildren<Omit<GlobalContextType, 'state' | 'dispatch'>>) => (
      <GlobalContext.Provider value={{ ...props, state: reducerState, dispatch }}>{children}</GlobalContext.Provider>
    );
  }, [reducerState]);
  return {
    GlobalProvider,
    dispatch,
    state: reducerState,
  };
};

export const useGlobalState = () => {
  const state = useContext(GlobalContext);
  return state;
};
