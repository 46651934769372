// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 已到站 PUT /logisticssolution/v1/seller/train-orders/${param0}/arrival */
export async function OperationId_logisticssolution_SellerTrainOrderController_arrival(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerTrainOrderControllerArrivalParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/train-orders/${param0}/arrival`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerTrainOrderController_arrival',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 已发车 PUT /logisticssolution/v1/seller/train-orders/${param0}/departure */
export async function OperationId_logisticssolution_SellerTrainOrderController_departure(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerTrainOrderControllerDepartureParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/train-orders/${param0}/departure`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerTrainOrderController_departure',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 放舱 PUT /logisticssolution/v1/seller/train-orders/${param0}/so */
export async function OperationId_logisticssolution_SellerTrainOrderController_confirmSo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerTrainOrderControllerConfirmSoParams,
  body: API.CommonPerformanceTrainActionSoRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/train-orders/${param0}/so`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerTrainOrderController_confirmSo',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
