import { i18n } from '@easygo/i18n';
import { createEnums } from '../utils/select-enums';

/** 支付单状态 */
export const PaymentOrderStatusMap = {
  1: 'PAYMENT_ORDER_STATUS_CREATED',
  2: 'PAYMENT_ORDER_STATUS_CANCEL',
  3: 'PAYMENT_ORDER_STATUS_CONFIRM_WAIT',
  4: 'PAYMENT_ORDER_STATUS_FINISH',
  5: 'PAYMENT_ORDER_STATUS_CLOSE',
};

export enum RailwayCabinTypeEnum {
  'SOC' = '1',
  'COC' = '2',
}

// 舱位类型
export const RailwayCabinType = {
  [RailwayCabinTypeEnum.SOC]: 'SOC',
  [RailwayCabinTypeEnum.COC]: 'COC',
};

/** 支付单状态 */
export const PaymentOrderStatus = createEnums([
  'PAYMENT_ORDER_STATUS_CREATE',
  'PAYMENT_ORDER_STATUS_CANCEL',
  'PAYMENT_ORDER_STATUS_WAITING',
  'PAYMENT_ORDER_STATUS_FINISH',
  'PAYMENT_ORDER_STATUS_CLOSE',
]);

/** 支付单状态 */
export const OfflineOrderStatus = createEnums(['PAYMENT_ORDER_STATUS_WAITING', 'PAYMENT_ORDER_STATUS_FINISH']);

/** 币种 */
export const CurrencyMap = createEnums(['SKU_COIN_TYPE_RMB', 'SKU_COIN_TYPE_DOLLAR']);

export enum CurrencyTypeMap {
  /** 人民币 */
  RMB = 'SKU_COIN_TYPE_RMB',
  /** 美元 */
  DOLLAR = 'SKU_COIN_TYPE_DOLLAR',
  /** 欧元 */
  EURO = 'SKU_COIN_TYPE_EURO',
  /** 人民币 */
  MARINERMB = 'CURRENCY_TYPE_RMB',
  /** 美元 */
  MARINEDOLLAR = 'CURRENCY_TYPE_DOLLAR',
  /** 欧元 */
  MAIRNEEURO = 'CURRENCY_TYPE_EURO',
}

/** 币种符号 */
export const CurrencySymbolMap = {
  [CurrencyTypeMap.RMB]: '¥', // 人民币
  [CurrencyTypeMap.DOLLAR]: '$', // 美元
  [CurrencyTypeMap.EURO]: '€', // 欧元
  [CurrencyTypeMap.MARINERMB]: '¥', // 人民币
  [CurrencyTypeMap.MARINEDOLLAR]: '$', // 美元
  [CurrencyTypeMap.MAIRNEEURO]: '€', // 欧元
};

/** 币种字母 */
export const CurrencyLetterMap = {
  [CurrencyTypeMap.RMB]: 'CNY', // 人民币
  [CurrencyTypeMap.DOLLAR]: 'USD', // 美元
  [CurrencyTypeMap.EURO]: 'EUR', // 欧元
};
/** 币种排序权重 */
export const CurrencySortMap = {
  [CurrencyTypeMap.RMB]: 1, // 人民币
  [CurrencyTypeMap.DOLLAR]: 2, // 美元
  [CurrencyTypeMap.EURO]: 3, // 欧元
};

export const CurrencySortTypeMap = {
  CURRENCY_TYPE_RMB: 1,
  CURRENCY_TYPE_DOLLAR: 2,
  CURRENCY_TYPE_EURO: 3,
  CURRENCY_TYPE_YEN: 4,
};

/** 钱币 */
export const CURRENCY = {
  SKU_COIN_TYPE_RMB: {
    key: 'SKU_COIN_TYPE_RMB',
    letter: 'CNY',
    text: i18n?.t('人民币'),
    symbol: '¥',
  },
  SKU_COIN_TYPE_DOLLAR: {
    key: 'SKU_COIN_TYPE_DOLLAR',
    letter: 'USD',
    text: i18n?.t('美元'),
    symbol: '$',
  },
  SKU_COIN_TYPE_EURO: {
    key: 'SKU_COIN_TYPE_EURO',
    letter: 'EUR',
    text: i18n?.t('欧元'),
    symbol: '€',
  },
};

export const CurrencyTypeMapSymbol = {
  CURRENCY_TYPE_RMB: '¥',
  CURRENCY_TYPE_DOLLAR: '$',
  CURRENCY_TYPE_EURO: '€',
  CURRENCY_TYPE_YEN: 'Ұ',
};
export enum ShowPriceValue {
  FIXED = '1',
  VARIABLE = '2',
  HIDE = '3',
}

/** 是否显示价格 */
export const ShowPrice = {
  // 一口价
  [ShowPriceValue.FIXED]: 'YI_KOU_JIA',
  //可议价格
  [ShowPriceValue.VARIABLE]: 'KE_YI_JIA',
  //不显示价格
  [ShowPriceValue.HIDE]: 'NO_SHOW_PRICE',
};

/** 商品的code和自定义property映射 */
export const ProductCodeMap = {
  /** 箱型(租箱) */
  rentBoxType: {
    propertyId: '7335807144742912',
    propertyCode: 'de7a3c',
    propertyName: i18n?.t('箱型(租箱)'),
  },
  /** 提箱城市 */
  pickupCity: {
    propertyId: '7340317535686656',
    propertyCode: 'cf0142',
    propertyName: i18n?.t('提箱城市'),
  },
  /** 还箱城市 */
  returnCity: {
    propertyId: '7342259926257664',
    propertyCode: 'e39526',
    propertyName: i18n?.t('还箱城市'),
  },
  /** 免箱期 */
  freePeriod: {
    propertyId: '7342750173286400',
    propertyCode: 'dd4645',
    propertyName: i18n?.t('免箱期'),
  },
  /** 超期规则 */
  overdueRule: {
    propertyId: '',
    propertyCode: 'cba42c',
    propertyName: i18n?.t('超期规则'),
  },
  /** 报关口岸 */
  declarationPort: {
    propertyId: '7345701080059904',
    propertyCode: '88a3ee',
    propertyName: i18n?.t('报关口岸'),
  },
  /** 货物类型 */
  goodsType: {
    propertyId: '7346557942812672',
    propertyCode: '8e6aa2',
    propertyName: i18n?.t('货物类型'),
  },
  /** 运输方式 */
  transMode: {
    propertyId: '7346931550441472',
    propertyCode: '249df0',
    propertyName: i18n?.t('运输方式'),
  },
  /** 类型 */
  type: {
    propertyId: '7348673423921152',
    propertyCode: '79b055',
    propertyName: i18n?.t('类型'),
  },
  /** 备注*/
  remark: {
    propertyId: '7351313515991040',
    propertyCode: '759583',
    propertyName: i18n?.t('备注'),
  },
  /** 企业信息是否匿名 */
  companyAnonymous: {
    propertyId: '7352051134681088',
    propertyCode: 'd92620',
    propertyName: i18n?.t('企业信息是否匿名'),
  },
  /** 保舱服务 */
  protectService: {
    propertyId: '7352331628761088',
    propertyCode: 'ec7785',
    propertyName: i18n?.t('保舱服务'),
  },
  /** 舱位类别 */
  spaceType: {
    propertyId: '7352546129661952',
    propertyCode: 'a66ce8',
    propertyName: i18n?.t('舱位类别'),
  },
  /** 持箱人 */
  holdBoxPeople: {
    propertyId: '7352777592328192',
    propertyCode: '6819d5',
    propertyName: i18n?.t('持箱人'),
  },
  /** 起始港口 */
  startPort: {
    propertyId: '7354936589340672',
    propertyCode: '412a5e',
    propertyName: i18n?.t('起始港口'),
  },
  /** 起始港口城市 */
  startPortCity: {
    propertyId: '',
    propertyCode: '321baa',
    propertyName: i18n?.t('起始港口城市'),
  },
  /** 目的港口 */
  purposePort: {
    propertyId: '7355067577454592',
    propertyCode: '54f839',
    propertyName: i18n?.t('目的港口'),
  },
  /** 目的港口城市 */
  purposePortCity: {
    propertyId: '',
    propertyCode: '37e580',
    propertyName: i18n?.t('目的港口城市'),
  },
  /** 航次 */
  voyage: {
    propertyId: '7355420997898240',
    propertyCode: '40c98f',
    propertyName: i18n?.t('航次'),
  },
  /** 截单时间 */
  closeOrderTime: {
    propertyId: '7355604339314688',
    propertyCode: '9a1e6f',
    propertyName: i18n?.t('截单时间'),
  },
  /** 截关时间 */
  closeTime: {
    propertyId: '7355915409870848',
    propertyCode: 'fdfc01',
    propertyName: i18n?.t('截关时间'),
  },
  /** 航程天数 */
  voyageDays: {
    propertyId: '7368203126755328',
    propertyCode: 'd5fb8b',
    propertyName: i18n?.t('航程天数'),
  },
  /** 说明 */
  instructions: {
    propertyId: '7369634500104192',
    propertyCode: 'd10d4a',
    propertyName: i18n?.t('说明'),
  },
  /** 是否展示价格 */
  showPrice: {
    propertyId: '7379760539815936',
    propertyCode: '3bd7b5',
    propertyName: i18n?.t('是否展示价格'),
  },
  /** 箱型(海运) */
  marineBoxType: {
    propertyId: '7380535785607168',
    propertyCode: 'fb84bf',
    propertyName: i18n?.t('箱型(海运)'),
  },
  /** 班列/计划号 */
  trains: {
    propertyId: '7588499133878272',
    propertyCode: '5b225b',
    propertyName: i18n?.t('班列/计划号'),
  },
  /** 计划出发时间 */
  plannedDepartureTime: {
    propertyId: '7588676393553920',
    propertyCode: 'd4f210',
    propertyName: i18n?.t('计划出发时间'),
  },
  /** 接单时间 */
  orderReceivingTime: {
    propertyId: '7588837459021824',
    propertyCode: 'eca530',
    propertyName: i18n?.t('接单时间'),
  },
  /** 运费说明 */
  freightDesc: {
    propertyId: '7589898294972416',
    propertyCode: 'c1c351',
    propertyName: i18n?.t('运费说明'),
  },
  /** 箱型(班列) */
  railwayBoxType: {
    propertyId: '7590696974340096',
    propertyCode: 'c60cba',
    propertyName: i18n?.t('箱型(班列)'),
  },
  shipCompany: {
    propertyId: '',
    propertyCode: '48db9a',
    propertyName: i18n?.t('船公司'),
  },
  shipName: {
    propertyId: '',
    propertyCode: 'd342e7',
    propertyName: i18n?.t('船名'),
  },
  voyageLine: {
    propertyId: '',
    propertyCode: '00126e',
    propertyName: i18n?.t('航线'),
  },
  planToPortTime: {
    propertyId: '',
    propertyCode: '288d69',
    propertyName: i18n?.t('计划到港时间'),
  },
  planLeavePortTime: {
    propertyId: '',
    propertyCode: '8afba6',
    propertyName: i18n?.t('计划离港时间'),
  },
  indateStart: {
    propertyId: '',
    propertyCode: '4b9c24',
    propertyName: i18n?.t('有效期开始时间'),
  },
  indateEnd: {
    propertyId: '',
    propertyCode: 'd34961',
    propertyName: i18n?.t('有效期结束时间'),
  },
  declareType: {
    propertyId: '',
    propertyCode: '264f4d',
    propertyName: i18n?.t('报关类型'),
  },
  declareServiceCity: {
    propertyId: '',
    propertyCode: 'eb5b1e',
    propertyName: i18n?.t('服务城市'),
  },
  declareServiceContent: {
    propertyId: '',
    propertyCode: '3e5af8',
    propertyName: i18n?.t('服务内容'),
  },
  declareServiceMode: {
    propertyId: '',
    propertyCode: 'e378b1',
    propertyName: i18n?.t('报关模式'),
  },
  boxClause: {
    propertyId: '',
    propertyCode: 'b9b7b8',
    propertyName: i18n?.t('用箱条款'),
  },
  boxState: {
    propertyId: '',
    propertyCode: '4b33f7',
    propertyName: i18n?.t('箱况'),
  },
  performanceMode: {
    propertyId: '',
    propertyCode: 'd5c5d9',
    propertyName: i18n?.t('履约模式'),
  },
};

// 商品分类
export const productCategory = {
  '9108763151749120': 'CATEGORY_RENTBOX',
  // '9108763940278272': 'CATEGORY_BAOGUAN',
  // '9108764598784000': 'CATEGORY_TUOCHE',
  '9108765257289728': 'CATEGORY_HAIYUNCANG',
  '9108765907406848': 'CATEGORY_TIELUCANG',
};

export const BankCardType = createEnums(['BANK_CARD_TYPE_PUBLIC']);

export const BankCardTypeOptions = [{ label: '对公账户', value: 'BANK_CARD_TYPE_PUBLIC' }];

// 堆场状态
export const yardStatus = {
  '1': 'ENTERPRISE_STATUS_ENABLE',
  '2': 'ENTERPRISE_STATUS_DISABLE',
};
// 堆场状态
export enum YardStatus {
  YARD_STATUS_DISABLE = 'YARD_STATUS_DISABLE',
  YARD_STATUS_ENABLE = 'YARD_STATUS_ENABLE',
}
// 堆场绑定关系状态
export enum YardRelationStatus {
  UNKNOWN = 'YARD_ENTERPRISE_RELATION_STATUS_UNKNOWN',
  BIND = 'YARD_ENTERPRISE_RELATION_STATUS_BIND',
  UNBIND = 'YARD_ENTERPRISE_RELATION_STATUS_UNBIND',
}
// 超期规则状态
export const overdueRuleStatus = {
  EXPIRATION_RULE_STATUS_ON: 'EXPIRATION_RULE_STATUS_ON',
  EXPIRATION_RULE_STATUS_OFF: 'EXPIRATION_RULE_STATUS_OFF',
};

// 附加费费用状态
export const additionalFeeStatus = {
  EXTRA_CHARGE_STATUS_WAIT: 'EXTRA_CHARGE_STATUS_WAIT',
  EXTRA_CHARGE_STATUS_FINISH: 'EXTRA_CHARGE_STATUS_FINISH',
  EXTRA_CHARGE_STATUS_CLOSE: 'EXTRA_CHARGE_STATUS_CLOSE',
};
// 附加费费用状态
export const additionalFeeStatusForTable = {
  ...additionalFeeStatus,
  EXTRA_CHARGE_STATUS_UNKNOWN: 'EXTRA_CHARGE_STATUS_UNKNOWN',
  UNRECOGNIZED: 'UNRECOGNIZED',
};

/** 退款状态 */
// export const AFTER_SALE_STATUS = {
//   AFTER_SALE_ORDER_SUBMIT_SUCCESS: {
//     key: 'AFTER_SALE_ORDER_SUBMIT_SUCCESS',
//     text: i18n?.t('待卖家审核'),
//   },
//   AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS: {
//     key: 'AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS',
//     text: i18n?.t('待买家确认'),
//   },
//   AFTER_SALE_ORDER_APPROVE_PASS: {
//     key: 'AFTER_SALE_ORDER_APPROVE_PASS',
//     text: i18n?.t('退款中'),
//   },
//   AFTER_SALE_ORDER_UNAPPROVE: {
//     key: 'AFTER_SALE_ORDER_UNAPPROVE',
//     text: i18n?.t('审核驳回'),
//   },
//   // AFTER_SALE_ORDER_REFUND_SUCCESS: {
//   //   key: 'AFTER_SALE_ORDER_REFUND_SUCCESS',
//   //   text: '已退款待确认',
//   // },
//   AFTER_SALE_ORDER_SUCCESS: {
//     key: 'AFTER_SALE_ORDER_SUCCESS',
//     text: i18n?.t('退款完成'),
//   },
//   AFTER_SALE_ORDER_FAIL: {
//     key: 'AFTER_SALE_ORDER_FAIL',
//     text: i18n?.t('退款失败'),
//   },
//   AFTER_SALE_ORDER_CANCELED: {
//     key: 'AFTER_SALE_ORDER_CANCELED',
//     text: i18n?.t('已取消'),
//   },
// };

/** 退款类型 */
// export const AFTER_SALE_TYPE = {
//   AFTER_SALE_TYPE_REFUND_PRODUCT_AND_AMOUNT: {
//     key: 'AFTER_SALE_TYPE_REFUND_PRODUCT_AND_AMOUNT',
//     text: i18n?.t('退商品&退款'),
//   },
//   // 仅针对：退款-已还箱
//   AFTER_SALE_TYPE_REFUND_FINISH_DROP_OFF: {
//     key: 'AFTER_SALE_TYPE_REFUND_FINISH_DROP_OFF',
//     text: i18n?.t('仅退款'),
//   },
// };

// export const REASON_TYPE = {
//   REASON_TYPE_AFTER_SALE_REQ: {
//     key: 'REASON_TYPE_AFTER_SALE_REQ',
//     text: i18n?.t('申请售后'),
//   },
//   REASON_TYPE_CANCEL_AFTER_SALE: {
//     key: 'REASON_TYPE_CANCEL_AFTER_SALE',
//     text: i18n?.t('取消售后'),
//   },
// };
// 订单支付方式
export const ORDER_PAY_MODE_TYPE = {
  /** 余额付款 */
  PAY_MODE_TYPE_BALANCE: i18n?.t('余额付款'),
  /** 平台授信付款 */
  PAY_MODE_TYPE_PLATFORM_CREDIT: i18n?.t('平台授信付款'),
  /** 商家授信付款 */
  PAY_MODE_TYPE_SHOP_CREDIT: i18n?.t('商家授信付款'),
  /** 转账付款  */
  PAY_MODE_TYPE_TRANSFER: i18n?.t('转账付款'),
};
export enum ShippingDateType {
  // 自动创建，创建商品时根据有效期自动生成
  SYSTEM = 'SHIPPING_DATE_TYPE_SYSTEM',
  // 用户填写，无论是按日期填写还是按周一到周日规则生成
  CUSTOM = 'SHIPPING_DATE_TYPE_CUSTOM',
}
