export const RoleStatus = {
  // 激活
  ROLE_STATUS_ACTIVATED: 'ROLE_STATUS_ACTIVATED',
  // 禁用
  ROLE_STATUS_DEACTIVATED: 'ROLE_STATUS_DEACTIVATED',
};

export const RoleType = {
  // 买家
  PERMISSION_TYPE_BUYER: 'PERMISSION_TYPE_BUYER',
  // 卖家
  PERMISSION_TYPE_SELLER: 'PERMISSION_TYPE_SELLER',
  // 系统
  PERMISSION_TYPE_SYSTEM: 'PERMISSION_TYPE_SYSTEM',
};
export const enum ItemTag {
  recommend = 1,
}

// 企业注册区域
export const RegistryArea = {
  REGISTRY_AREA_TERRITORY: 'REGISTRY_AREA_TERRITORY', //中国内地
  REGISTRY_AREA_HKMT: 'REGISTRY_AREA_HKMT', // 中国港澳台地区
  REGISTRY_AREA_ABROAD: 'REGISTRY_AREA_ABROAD', // 境外
};

export enum SHELVE_STATUS {
  ON = '1',
  OFF = '2',
}

// 上下架状态 1：上架 2：下架
export const ShelveStatus = {
  [SHELVE_STATUS.ON]: 'SHELVESTATUS_ON',
  [SHELVE_STATUS.OFF]: 'SHELVESTATUS_OFF',
};

export const ActiveStatus = {
  ...ShelveStatus,
  ...{
    '0': 'SHELVESTATUS_OFF',
  },
};

export enum AUDIT_STATUS {
  BACKLOG = '1',
  PASS = '2',
  NOTPASS = '3',
}

// 审核状态 0.未审核 2.审核通过 3.审核未通过
export const AuditStatus = {
  [AUDIT_STATUS.BACKLOG]: 'AUDIT_BACKLOG',
  [AUDIT_STATUS.PASS]: 'AUDIT_PASS',
  [AUDIT_STATUS.NOTPASS]: 'AUDIT_NOTPASS',
};

// 用户角色
export const CustomType = {
  CUSTOM_TYPE_UNKNOWN: 'CUSTOM_TYPE_UNKNOWN', // 未知
  CUSTOM_TYPE_BUYER: 'CUSTOM_TYPE_BUYER', // 买家
  CUSTOM_TYPE_SELLER: 'CUSTOM_TYPE_SELLER', // 卖家
  UNRECOGNIZED: 'UNRECOGNIZED', // 未注册
};

// 查询类型
export enum BannerPositionType {
  INDEX = 'POSITION_PC_INDEX',
  CONTAINER = 'POSITION_PC_CONTAINER',
  SHIPPING = 'POSITION_PC_SHIPPING',
  RAILWAY = 'POSITION_PC_RAILWAY',
  TRAILER = 'POSITION_PC_TRAILER',
  CLEARANCE = 'POSITION_PC_CLEARANCE',
  BUYER = 'POSITION_PC_BUYER',
  SELLER = 'POSITION_PC_SELLER',
  ABOUT = 'POSITION_PC_ABOUT',
  NEWS = 'POSITION_PC_NEWS',
  CONTAINER_BACK = 'POSITION_PC_CONTAINER_BACK',
  YARD_CHOICE_NESS = 'POSITION_PC_YARD_CHOICE_NESS', // PC官网-堆场精选页banner
  OVERSEAS_BOXES = 'POSITION_PC_OVERSEAS_BOXES', // PC官网-境外用箱子
  OVERSEAS_FIRST_STEPS = 'POSITION_PC_OVERSEAS_FIRST_STEPS', // PC官网-跨境电商头程
}
export const enum FROZEN_STATUS {
  normal = 1,
  forzen,
}

export const enum RECOOMEND_STATUS {
  recommend = 1,
  unRecommend,
}

export const enum NavigateType {
  /** 租箱 */
  RENTBOX = 'RENTBOX',
  /** 海运 */
  OCEAN = 'OCEAN',
  /** 班列 */
  RAILWAY = 'RAILWAY',
  /** 报关 */
  DECLARE = 'DECLARE',
  /** 拖车 */
  TRAILER = 'TRAILER',
}
