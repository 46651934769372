/** 发票状态 */
export enum InvoiceStatus {
  /** 正常 */
  NORMAL = 'NORMAL',
  /** 已红冲 */
  RED_INVOICE = 'RED_INVOICE',
}
/** 发票状态 */
export const InvoiceStatusOptions = {
  [InvoiceStatus.NORMAL]: '正常',
  [InvoiceStatus.RED_INVOICE]: '已红冲',
};
/** 发票状态 */
export const InvoiceStatusBadge = {
  [InvoiceStatus.NORMAL]: {
    label: '正常',
    color: '#2C334D',
  },
  [InvoiceStatus.RED_INVOICE]: {
    label: '已红冲',
    color: '#FF8413',
  },
};

/** 发票类型 */
export enum InvoiceType {
  /** 红票 */
  INVOICE_TYPE_RED = 'INVOICE_TYPE_RED',
  /** 蓝票 */
  INVOICE_TYPE_BLUE = 'INVOICE_TYPE_BLUE',
}
/** 发票类型 */
export const InvoiceTypeOptions = {
  /** 红票 */
  [InvoiceType.INVOICE_TYPE_RED]: '红票',
  /** 蓝票 */
  [InvoiceType.INVOICE_TYPE_BLUE]: '蓝票',
};
/** 开票类型 */
export enum IssuingType {
  /** 红票 */
  RED = 'APPLY_TYPE_RED',
  /** 蓝票 */
  BLUE = 'APPLY_TYPE_BLUE',
}
/** 开票类型 */
export const IssuingTypeOptions = {
  /** 红票 */
  [IssuingType.RED]: '红冲',
  /** 蓝票 */
  [IssuingType.BLUE]: '开票',
};

/** 资金渠道/支付渠道 */
// 在线支付、线下余额抵扣、对公转账
export enum PayType {
  /** 线下余额抵扣 */
  OFFLINE_PURSE = 'PAY_TYPE_OFFLINE_PURSE',
  /** 对公转账 */
  TRANSFER = 'PAY_TYPE_TRANSFER',
  /** 在线支付 */
  ONLINE = 'PAY_TYPE_ONLINE',
}
/** 开票类型 */
export const PayTypeOptions = {
  [PayType.ONLINE]: '在线支付',
  [PayType.OFFLINE_PURSE]: '线下余额抵扣',
  [PayType.TRANSFER]: '对公转账',
};

/** 开票状态 */
// 待处理、已开票、已红冲、已撤销
export enum IssuingStatus {
  /** 待处理 */
  HANDLING = 'STATUS_HANDLING',
  /** 已处理 */
  INVOICED = 'STATUS_INVOICED',
  /** 已撤销 */
  CANCEL = 'STATUS_CANCEL',
}
/** 开票状态 */
export const IssuingStatusOptions = {
  [IssuingStatus.HANDLING]: '待处理',
  [IssuingStatus.INVOICED]: '已处理',
  [IssuingStatus.CANCEL]: '已撤销',
};

/** 开票状态 */
export const IssuingStatusBadge = {
  [IssuingStatus.HANDLING]: {
    label: '待处理',
    color: '#FF8413',
  },
  [IssuingStatus.INVOICED]: {
    label: '已处理',
    color: '#2C334D',
  },
  [IssuingStatus.CANCEL]: {
    label: '已撤销',
    color: '#FF8413',
  },
};

/** 开票处理 */
// 撤销、开票
export enum IssuingOperation {
  CANCEL = 'CANCEL',
  ISSUING = 'ISSUING',
}
/** 开票处理 */
export const IssuingOperationOptions = {
  [IssuingOperation.CANCEL]: '撤销',
  [IssuingOperation.ISSUING]: '开票',
};

/** 本单退款是否需红冲 */
// 无需红冲/需红冲
export enum RushRedOperation {
  NEED = 'NEED',
  NO_NEED = 'NO_NEED',
}
/** 本单退款是否需红冲 */
export const RushRedOperationOptions = {
  [RushRedOperation.NO_NEED]: '无需红冲',
  [RushRedOperation.NEED]: '需红冲',
};

export enum BusinessType {
  /** 订单 */
  ORDER = 'BUSINESS_TYPE_ORDER',
  /** 账单 */
  STATEMENT = 'BUSINESS_TYPE_STATEMENT',
}

/** 申请发票类型 */
export enum InvoicedType {
  VAT_SPECIAL_INVOICE = 'VAT_SPECIAL_INVOICE',
  TRANSPORTATION_VAT_SPECIAL_INVOICE = 'TRANSPORTATION_VAT_SPECIAL_INVOICE',
  CARS_SALE_UNIFIED_INVOICE = 'CARS_SALE_UNIFIED_INVOICE',
  VAT_COMMON_INVOICE = 'VAT_COMMON_INVOICE',
  VAT_SPECIAL_INVOICE_ELECTRONIC = ' VAT_SPECIAL_INVOICE_ELECTRONIC',
  VAT_COMMON_INVOICE_ELECTRONIC = 'VAT_COMMON_INVOICE_ELECTRONIC',
  VAT_COMMON_INVOICE_ROLL = 'VAT_COMMON_INVOICE_ROLL',
  VAT_SPECIAL_INVOICE_PASSENGER_TRANSPORT = 'VAT_SPECIAL_INVOICE_PASSENGER_TRANSPORT',
  USED_CARS_SALE_UNIFIED_INVOICE = 'USED_CARS_SALE_UNIFIED_INVOICE',
  SZ_BLOCKCHAIN_INVOICE = 'SZ_BLOCKCHAIN_INVOICE',
  PROFORMA_INVOICE = 'PROFORMA_INVOICE',
  COMMON_MACHINE_PRINTED_INVOICE = 'COMMON_MACHINE_PRINTED_INVOICE',
}
/** 申请发票类型 */
export const InvoicedTypeOptions = {
  [InvoicedType.VAT_SPECIAL_INVOICE]: '增值税专用发票',
  [InvoicedType.TRANSPORTATION_VAT_SPECIAL_INVOICE]: '货运运输业增值税专用发票',
  [InvoicedType.CARS_SALE_UNIFIED_INVOICE]: '机动车销售统一发票',
  [InvoicedType.VAT_COMMON_INVOICE]: '增值税普通发票',
  [InvoicedType.VAT_SPECIAL_INVOICE_ELECTRONIC]: '增值税电子专用发票',
  [InvoicedType.VAT_COMMON_INVOICE_ELECTRONIC]: '增值税电子普通发票',
  [InvoicedType.VAT_COMMON_INVOICE_ROLL]: '增值税普通发票（卷票）',
  [InvoicedType.VAT_SPECIAL_INVOICE_PASSENGER_TRANSPORT]: '增值税电子（通行费）发票',
  [InvoicedType.USED_CARS_SALE_UNIFIED_INVOICE]: '二手车销售统一发票',
  [InvoicedType.SZ_BLOCKCHAIN_INVOICE]: '深圳区块链发票',
  [InvoicedType.PROFORMA_INVOICE]: '形式发票',
  [InvoicedType.COMMON_MACHINE_PRINTED_INVOICE]: '通用机打电子发票',
};
