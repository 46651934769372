// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 确认提单 PUT /salesgateway/v1/shipping/buyer/confirmBL/${param0} */
export async function OperationId_salesgateway_ShippingBuyerController_confirmBL(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingBuyerControllerConfirmBLParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/shipping/buyer/confirmBL/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_confirmBL',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 确认提单样本 PUT /salesgateway/v1/shipping/buyer/confirmBLTemp/${param0} */
export async function OperationId_salesgateway_ShippingBuyerController_confirmBLTemp(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingBuyerControllerConfirmBLTempParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/shipping/buyer/confirmBLTemp/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_confirmBLTemp',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 下单接口 POST /salesgateway/v1/shipping/buyer/createOrder */
export async function OperationId_salesgateway_ShippingBuyerController_createOrder(
  body: API.CreateOrderReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CreateOrderRes>('/salesgateway/v1/shipping/buyer/createOrder', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_createOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家修改订单 POST /salesgateway/v1/shipping/buyer/editOrder */
export async function OperationId_salesgateway_ShippingBuyerController_editOrder(
  body: API.EditOrderReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<string>('/salesgateway/v1/shipping/buyer/editOrder', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_editOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 当前登陆人获取订单发货人姓名list POST /salesgateway/v1/shipping/buyer/getCargoSenders */
export async function OperationId_salesgateway_ShippingBuyerController_getCargoSenders(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingBuyerControllerGetCargoSendersParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BaseInfoShipVo[]>('/salesgateway/v1/shipping/buyer/getCargoSenders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_getCargoSenders',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取当前定金 ShippingBuyerController.getDeposit GET /salesgateway/v1/shipping/buyer/getDeposit */
export async function OperationId_salesgateway_ShippingBuyerController_getDeposit(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.GetDepositeRes>('/salesgateway/v1/shipping/buyer/getDeposit', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_getDeposit',
    },
    ...(rest || {}),
  });
}

/** 获取是否有账单 ShippingBuyerController.getHasBill GET /salesgateway/v1/shipping/buyer/getHasBill/${param0} */
export async function OperationId_salesgateway_ShippingBuyerController_getHasBill(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingBuyerControllerGetHasBillParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/shipping/buyer/getHasBill/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_getHasBill',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取支付金额 POST /salesgateway/v1/shipping/buyer/getPay */
export async function OperationId_salesgateway_ShippingBuyerController_getPay(
  body: string,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/v1/shipping/buyer/getPay', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_getPay',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取订单关联卖家信息 POST /salesgateway/v1/shipping/buyer/getSellers */
export async function OperationId_salesgateway_ShippingBuyerController_getBuyers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingBuyerControllerGetBuyersParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BaseInfoShipVo[]>('/salesgateway/v1/shipping/buyer/getSellers', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_getBuyers',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 取消订单 DELETE /salesgateway/v1/shipping/buyer/orders/cancel/${param0} */
export async function OperationId_salesgateway_ShippingBuyerController_cancelOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingBuyerControllerCancelOrderParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/shipping/buyer/orders/cancel/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_cancelOrder',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 提交补料 POST /salesgateway/v1/shipping/buyer/submitSI */
export async function OperationId_salesgateway_ShippingBuyerController_submitSI(
  body: API.PerformanceSIReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/buyer/submitSI', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingBuyerController_submitSI',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
