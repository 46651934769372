import { forwardRef } from 'react';

import generatePicker from 'antd/es/date-picker/generatePicker';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import dayjsGenerateConfig from 'rc-picker/es/generate/dayjs';
import 'antd/es/date-picker/style';
import 'antd/es/calendar/style';
import type {
  PickerDateProps,
  RangePickerProps as RangePickerPropsType,
  PickerTimeProps,
  RangePickerTimeProps,
} from 'antd/es/date-picker/generatePicker';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import type { Dayjs } from 'dayjs';

dayjs.extend(weekday);
dayjs.extend(localeData);

export interface DatePickerProps extends Omit<PickerDateProps<Dayjs>, 'picker'> {}

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}

export interface RangePickerProps extends Omit<RangePickerPropsType<Dayjs>, 'picker'> {}

export interface TimeRangePickerProps extends Omit<RangePickerTimeProps<Dayjs>, 'picker'> {}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

TimePicker.displayName = 'TimePicker';

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

export { TimePicker, DatePicker, Calendar };
