// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 更改账户信息 AccountController.updateAccount PATCH /salesgateway/accounts */
export async function OperationId_salesgateway_AccountController_updateAccount(
  body: API.UpdateAccountVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/accounts', {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_updateAccount',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取企业权限组列表 GET /salesgateway/accounts/enterprise/permissions */
export async function OperationId_salesgateway_AccountController_getEnterprisePermissions(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PermissionGroupResponseVO[]>('/salesgateway/accounts/enterprise/permissions', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_getEnterprisePermissions',
    },
    ...(rest || {}),
  });
}

/** 获取当前登录用户信息 AccountController.getCurrentAccount GET /salesgateway/accounts/self */
export async function OperationId_salesgateway_AccountController_getCurrentAccountProfile(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.AccountResponseVO>('/salesgateway/accounts/self', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_getCurrentAccountProfile',
    },
    ...(rest || {}),
  });
}

/** 使用安全码（验证码流程）更新手机号或邮箱 AccountController.resetMobileOrEmailBySecureCode 此操作适用于没有绑定手机或邮箱的场景 PATCH /salesgateway/accounts/self/contacts */
export async function OperationId_salesgateway_AccountController_resetMobileOrEmailBySecureCode(
  body: API.ResetMobileOrEmailBySecureCodeVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/accounts/self/contacts', {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_resetMobileOrEmailBySecureCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 使用安全码再次发送验证码 AccountController.sendVerifyCodeBySecureCode 此操作适用于已绑定手机或邮箱，需要重新绑定的场景 POST /salesgateway/accounts/self/contacts/verify-code */
export async function OperationId_salesgateway_AccountController_sendVerifyCodeBySecureCode(
  body: API.SendVerifyCodeBySecureCodeVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SendVerifyCodeResponseVO>('/salesgateway/accounts/self/contacts/verify-code', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_sendVerifyCodeBySecureCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 使用原密码重置密码 AccountController.resetPasswordByPassword PUT /salesgateway/accounts/self/password */
export async function OperationId_salesgateway_AccountController_resetPasswordByPassword(
  body: API.CipherRequestVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/accounts/self/password', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_resetPasswordByPassword',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 使用安全码（验证码流程）重置密码 AccountController.resetPasswordBySecureCode PATCH /salesgateway/accounts/self/password */
export async function OperationId_salesgateway_AccountController_resetPasswordBySecureCode(
  body: API.CipherRequestVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/accounts/self/password', {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_resetPasswordBySecureCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取登录用户权限组列表 AccountController.getPermissions 此操作适用于已登录的用户需要获取所有权限以渲染后台界面操作菜单 GET /salesgateway/accounts/self/permissions */
export async function OperationId_salesgateway_AccountController_getCurrentAccountPermissions(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PermissionGroupResponseVO>('/salesgateway/accounts/self/permissions', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_getCurrentAccountPermissions',
    },
    ...(rest || {}),
  });
}

/** 获取账户详情 AccountController.getAccountProfile GET /salesgateway/accounts/self/profiles */
export async function OperationId_salesgateway_AccountController_getAccountProfile(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.GetAccountProfileResponseVO>('/salesgateway/accounts/self/profiles', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_getAccountProfile',
    },
    ...(rest || {}),
  });
}

/** 重置密码 AccountController.resetPasswordByAccountId PATCH /salesgateway/accounts/self/resetPassword */
export async function OperationId_salesgateway_AccountController_resetPasswordByAccountId(
  body: API.CipherRequestVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/accounts/self/resetPassword', {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountController_resetPasswordByAccountId',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
