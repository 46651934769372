// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取当前企业信息（包括真实性认证信息） GET /salesgateway/enterprises */
export async function OperationId_salesgateway_EnterpriseController_getCurrentEnterprise(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseResponseVO>('/salesgateway/enterprises', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseController_getCurrentEnterprise',
    },
    ...(rest || {}),
  });
}

/** 创建企业 POST /salesgateway/enterprises */
export async function OperationId_salesgateway_EnterpriseController_createEnterprise(
  body: API.CreateEnterpriseVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BasicResponseVO>('/salesgateway/enterprises', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseController_createEnterprise',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 根据企业ID登录（获取企业登录凭证） GET /salesgateway/enterprises/${param0}/authorize */
export async function OperationId_salesgateway_EnterpriseController_authByEnterpriseId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseControllerAuthByEnterpriseIdParams,
  options?: { [key: string]: any },
) {
  const { enterpriseId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AuthResponseVO>(`/salesgateway/enterprises/${param0}/authorize`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseController_authByEnterpriseId',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 根据账户ID获取已绑定的企业列表 GET /salesgateway/enterprises/account */
export async function OperationId_salesgateway_EnterpriseController_getEnterprisesByAccountId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseControllerGetEnterprisesByAccountIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetEnterpriseByAccountIDResponseVO>('/salesgateway/enterprises/account', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseController_getEnterprisesByAccountId',
    },
    params: {
      // accountId has a default value: 1
      accountId: '1',
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取企业管理员账号信息 GET /salesgateway/enterprises/accounts/admin */
export async function OperationId_salesgateway_EnterpriseController_getEnterpriseAdmin(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.AccountResponseVO>('/salesgateway/enterprises/accounts/admin', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseController_getEnterpriseAdmin',
    },
    ...(rest || {}),
  });
}

/** 创建企业真实性认证 POST /salesgateway/enterprises/certification/authenticity */
export async function OperationId_salesgateway_EnterpriseController_createEnterpriseAuthenticityApplication(
  body: API.CreateEnterpriseAuthenticityApplicationVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/enterprises/certification/authenticity', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseController_createEnterpriseAuthenticityApplication',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取企业卖家认证信息 GET /salesgateway/enterprises/certification/selling */
export async function OperationId_salesgateway_EnterpriseController_getEnterpriseSellingApplications(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseSellingApplicationResponseVO[]>(
    '/salesgateway/enterprises/certification/selling',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseController_getEnterpriseSellingApplications',
      },
      ...(rest || {}),
    },
  );
}

/** 创建或更新企业卖家认证 创建或更新企业卖家认证，多次创建不同服务包认证可多次调用 POST /salesgateway/enterprises/certification/selling */
export async function OperationId_salesgateway_EnterpriseController_createEnterpriseSellingApplication(
  body: API.CreateEnterpriseSellingApplicationVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/enterprises/certification/selling', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseController_createEnterpriseSellingApplication',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询企业信用代码或名称是否存在 GET /salesgateway/enterprises/duplicated */
export async function OperationId_salesgateway_EnterpriseController_checkEnterpriseCodeOrNameExist(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseControllerCheckEnterpriseCodeOrNameExistParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CheckEnterpriseCodeOrNameResponseVO>('/salesgateway/enterprises/duplicated', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseController_checkEnterpriseCodeOrNameExist',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 根据企业ID获取基础信息 GET /salesgateway/enterprises/profiles/basic */
export async function OperationId_salesgateway_EnterpriseController_getEnterpriseBasicProfileByEnterpriseId(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseBasicVO>('/salesgateway/enterprises/profiles/basic', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseController_getEnterpriseBasicProfileByEnterpriseId',
    },
    ...(rest || {}),
  });
}

/** 修改企业 POST /salesgateway/enterprises/renew */
export async function OperationId_salesgateway_EnterpriseController_modifyEnterprise(
  body: API.ModifyEnterpriseVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BasicResponseVO>('/salesgateway/enterprises/renew', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseController_modifyEnterprise',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
