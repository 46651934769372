// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as salesgatewayZoneActivityController from './salesgatewayZoneActivityController';
import * as salesgatewayBizOrderController from './salesgatewayBizOrderController';
import * as salesgatewayShippingCommonController from './salesgatewayShippingCommonController';
import * as salesgatewayOpenAuthController from './salesgatewayOpenAuthController';
import * as sinotransbookingBuyerProductController from './sinotransbookingBuyerProductController';
import * as salesgatewayYardBillController from './salesgatewayYardBillController';
import * as salesgatewayDeclarationPerformanceSellerController from './salesgatewayDeclarationPerformanceSellerController';
import * as logisticssolutionSellerContainerOrderController from './logisticssolutionSellerContainerOrderController';
import * as sinotransbookingOpenProductController from './sinotransbookingOpenProductController';
import * as salesgatewayPaymentOnAccountManagerController from './salesgatewayPaymentOnAccountManagerController';
import * as salesgatewayYardController from './salesgatewayYardController';
import * as logisticssolutionSellerPerformanceOrderController from './logisticssolutionSellerPerformanceOrderController';
import * as sinotransbookingOpenCenterConfigController from './sinotransbookingOpenCenterConfigController';
import * as salesgatewayShippingSaleController from './salesgatewayShippingSaleController';
import * as salesgatewayEnterpriseController from './salesgatewayEnterpriseController';
import * as salesgatewayCashierController from './salesgatewayCashierController';
import * as businessinvoiceSellerInvoiceController from './businessinvoiceSellerInvoiceController';
import * as salesgatewayContainerOwnerBoxController from './salesgatewayContainerOwnerBoxController';
import * as sinotransbookingSellerSurchargeController from './sinotransbookingSellerSurchargeController';
import * as sinotransbookingBuyerSurchargeController from './sinotransbookingBuyerSurchargeController';
import * as salesgatewayRailwayBuyerController from './salesgatewayRailwayBuyerController';
import * as salesgatewayMemberController from './salesgatewayMemberController';
import * as salesgatewayShippingBuyerController from './salesgatewayShippingBuyerController';
import * as sinotransbookingPerformanceSeaSellerController from './sinotransbookingPerformanceSeaSellerController';
import * as salesgatewayConfigCenterController from './salesgatewayConfigCenterController';
import * as salesgatewayDeclarationBillController from './salesgatewayDeclarationBillController';
import * as salesgatewayChangeApplyBuyerController from './salesgatewayChangeApplyBuyerController';
import * as salesgatewayPlannedOrderController from './salesgatewayPlannedOrderController';
import * as salesgatewayBizFulfillmentController from './salesgatewayBizFulfillmentController';
import * as salesgatewayBizAdditionalFeesController from './salesgatewayBizAdditionalFeesController';
import * as salesgatewayYardEnterpriseRelationController from './salesgatewayYardEnterpriseRelationController';
import * as salesgatewayContainerLeasingBuyerController from './salesgatewayContainerLeasingBuyerController';
import * as salesgatewayRailwaySellerController from './salesgatewayRailwaySellerController';
import * as sinotransbookingSellerStatementController from './sinotransbookingSellerStatementController';
import * as salesgatewayMessageControllerV2 from './salesgatewayMessageControllerV2';
import * as sinotransbookingOperationProductController from './sinotransbookingOperationProductController';
import * as salesgatewayDictController from './salesgatewayDictController';
import * as sinotransbookingPerformanceDeliverySellerController from './sinotransbookingPerformanceDeliverySellerController';
import * as logisticssolutionOpenCommonController from './logisticssolutionOpenCommonController';
import * as logisticssolutionOpenCenterConfigController from './logisticssolutionOpenCenterConfigController';
import * as sinotransbookingPerformanceBuyerController from './sinotransbookingPerformanceBuyerController';
import * as businesscommodityOriginCoverCityTemplateController from './businesscommodityOriginCoverCityTemplateController';
import * as sinotransbookingSellerLineController from './sinotransbookingSellerLineController';
import * as salesgatewayContainerProdSupplierController from './salesgatewayContainerProdSupplierController';
import * as sinotransbookingPerformanceSellerController from './sinotransbookingPerformanceSellerController';
import * as salesgatewayExpirationRuleController from './salesgatewayExpirationRuleController';
import * as salesgatewayRailwayCommonController from './salesgatewayRailwayCommonController';
import * as sinotransbookingSellerProductController from './sinotransbookingSellerProductController';
import * as salesgatewayContainerBackDemandController from './salesgatewayContainerBackDemandController';
import * as salesgatewayAttachmentController from './salesgatewayAttachmentController';
import * as salesgatewayPaymentOnAccountController from './salesgatewayPaymentOnAccountController';
import * as salesgatewayContainerLeasingPriceMarkController from './salesgatewayContainerLeasingPriceMarkController';
import * as salesgatewayImMessageController from './salesgatewayImMessageController';
import * as salesgatewayContainerOwnerBoxLogController from './salesgatewayContainerOwnerBoxLogController';
import * as salesgatewayCashierV2Controller from './salesgatewayCashierV2Controller';
import * as salesgatewayShippingProductController from './salesgatewayShippingProductController';
import * as businessinvoiceBuyerInvoiceController from './businessinvoiceBuyerInvoiceController';
import * as sinotransbookingOpenOssController from './sinotransbookingOpenOssController';
import * as sinotransbookingOperationPerformanceController from './sinotransbookingOperationPerformanceController';
import * as businessinvoiceBuyerApplyInvoiceController from './businessinvoiceBuyerApplyInvoiceController';
import * as salesgatewayOfflinePaymentController from './salesgatewayOfflinePaymentController';
import * as sinotransbookingOperationPurchaseStatementController from './sinotransbookingOperationPurchaseStatementController';
import * as salesgatewayHelperCenterController from './salesgatewayHelperCenterController';
import * as sinotransbookingOperationSalesStatementController from './sinotransbookingOperationSalesStatementController';
import * as salesgatewayOpenFileUploadController from './salesgatewayOpenFileUploadController';
import * as salesgatewayDeclarationSellerProductController from './salesgatewayDeclarationSellerProductController';
import * as salesgatewayOrganizationController from './salesgatewayOrganizationController';
import * as logisticssolutionSellerPerformanceOrderStatementController from './logisticssolutionSellerPerformanceOrderStatementController';
import * as salesgatewayPermissionController from './salesgatewayPermissionController';
import * as salesgatewayContainerOwnerPortAreaYardController from './salesgatewayContainerOwnerPortAreaYardController';
import * as salesgatewayStatementController from './salesgatewayStatementController';
import * as salesgatewayShippingSellerController from './salesgatewayShippingSellerController';
import * as salesgatewayAfterSaleController from './salesgatewayAfterSaleController';
import * as logisticssolutionBuyerPerformanceOrderController from './logisticssolutionBuyerPerformanceOrderController';
import * as logisticssolutionBuyerContainerOrderController from './logisticssolutionBuyerContainerOrderController';
import * as salesgatewayAuthController from './salesgatewayAuthController';
import * as salesgatewayChangeApplySellerController from './salesgatewayChangeApplySellerController';
import * as salesgatewaySocialController from './salesgatewaySocialController';
import * as salesgatewayUseContainerLineOpenController from './salesgatewayUseContainerLineOpenController';
import * as salesgatewayLeasingPaymentController from './salesgatewayLeasingPaymentController';
import * as sinotransbookingSellerStatementControllerV2 from './sinotransbookingSellerStatementControllerV2';
import * as logisticssolutionBuyerPerformanceOrderStatementController from './logisticssolutionBuyerPerformanceOrderStatementController';
import * as salesgatewayAccountController from './salesgatewayAccountController';
import * as businessinvoiceSellerApplyInvoiceController from './businessinvoiceSellerApplyInvoiceController';
import * as sinotransbookingBuyerStatementControllerV2 from './sinotransbookingBuyerStatementControllerV2';
import * as sinotransbookingOperationCommonController from './sinotransbookingOperationCommonController';
import * as logisticssolutionSellerDemandOrderController from './logisticssolutionSellerDemandOrderController';
import * as salesgatewayYardWebsiteController from './salesgatewayYardWebsiteController';
import * as salesgatewayOpenPortAreaController from './salesgatewayOpenPortAreaController';
import * as salesgatewayOpenConfigCenterController from './salesgatewayOpenConfigCenterController';
import * as salesgatewayContainerOwnerStockController from './salesgatewayContainerOwnerStockController';
import * as salesgatewayBizDeclarationBillController from './salesgatewayBizDeclarationBillController';
import * as logisticssolutionOpenOssController from './logisticssolutionOpenOssController';
import * as salesgatewayDeclarationCommonController from './salesgatewayDeclarationCommonController';
import * as salesgatewayContainerLogController from './salesgatewayContainerLogController';
import * as salesgatewayEnterpriseV1Controller from './salesgatewayEnterpriseV1Controller';
import * as salesgatewayMessageController from './salesgatewayMessageController';
import * as salesgatewayDemandController from './salesgatewayDemandController';
import * as salesgatewayContractController from './salesgatewayContractController';
import * as salesgatewayUseContainerLineController from './salesgatewayUseContainerLineController';
import * as sinotransbookingPerformanceTrailerSellerController from './sinotransbookingPerformanceTrailerSellerController';
import * as salesgatewayBuyerWorkbenchController from './salesgatewayBuyerWorkbenchController';
import * as salesgatewayWorkBenchMessageController from './salesgatewayWorkBenchMessageController';
import * as sinotransbookingOperationSurchargeController from './sinotransbookingOperationSurchargeController';
import * as salesgatewayWorkbenchTrackingController from './salesgatewayWorkbenchTrackingController';
import * as salesgatewayDeclarationPerformanceCommonController from './salesgatewayDeclarationPerformanceCommonController';
import * as sinotransbookingSellerSettlementTypeController from './sinotransbookingSellerSettlementTypeController';
import * as salesgatewayBillController from './salesgatewayBillController';
import * as salesgatewayAccountEnterpriseController from './salesgatewayAccountEnterpriseController';
import * as salesgatewayContainerBackRouteOpenController from './salesgatewayContainerBackRouteOpenController';
import * as logisticssolutionBuyerDemandOrderController from './logisticssolutionBuyerDemandOrderController';
import * as sinotransbookingOperationBusinessScopeController from './sinotransbookingOperationBusinessScopeController';
import * as salesgatewayBuyerSurchargeController from './salesgatewayBuyerSurchargeController';
import * as salesgatewayCategoryController from './salesgatewayCategoryController';
import * as businesscommodityRailwayBuyerProductController from './businesscommodityRailwayBuyerProductController';
import * as logisticssolutionSellerTrailerOrderController from './logisticssolutionSellerTrailerOrderController';
import * as salesgatewayOpenContainerLeasingPriceMarkController from './salesgatewayOpenContainerLeasingPriceMarkController';
import * as salesgatewayUseContainerController from './salesgatewayUseContainerController';
import * as salesgatewayOpenHomeController from './salesgatewayOpenHomeController';
import * as logisticssolutionSellerTrainOrderController from './logisticssolutionSellerTrainOrderController';
import * as salesgatewayContainerLeasingProductController from './salesgatewayContainerLeasingProductController';
import * as salesgatewayContainerConfigController from './salesgatewayContainerConfigController';
import * as salesgatewayDeclarationPerformanceBuyerController from './salesgatewayDeclarationPerformanceBuyerController';
import * as sinotransbookingPerformanceClearanceSellerController from './sinotransbookingPerformanceClearanceSellerController';
import * as sinotransbookingPerformanceSeaBuyerController from './sinotransbookingPerformanceSeaBuyerController';
import * as salesgatewayHomeController from './salesgatewayHomeController';
import * as salesgatewayDeclarationBuyerProductController from './salesgatewayDeclarationBuyerProductController';
import * as salesgatewayRailwayBuyerProductController from './salesgatewayRailwayBuyerProductController';
import * as salesgatewaySellerWorkbenchController from './salesgatewaySellerWorkbenchController';
import * as sinotransbookingBuyerStatementController from './sinotransbookingBuyerStatementController';
import * as salesgatewayMarketingController from './salesgatewayMarketingController';
import * as salesgatewayAgreementController from './salesgatewayAgreementController';
import * as salesgatewayEnterpriseInvoiceTitleController from './salesgatewayEnterpriseInvoiceTitleController';
import * as salesgatewayContainerOwnerDataCountController from './salesgatewayContainerOwnerDataCountController';
import * as salesgatewayOpenRailwayBuyerProductController from './salesgatewayOpenRailwayBuyerProductController';
import * as salesgatewayRailwaySellerProductController from './salesgatewayRailwaySellerProductController';
import * as salesgatewayOpenContainerLeasingProductController from './salesgatewayOpenContainerLeasingProductController';
import * as salesgatewayOpenAttachmentController from './salesgatewayOpenAttachmentController';
import * as salesgatewayContainerLeasingController from './salesgatewayContainerLeasingController';
import * as exportcenterExportController from './exportcenterExportController';
import * as sinotransbookingPerformanceDeclarationSellerController from './sinotransbookingPerformanceDeclarationSellerController';
import * as salesgatewayOverdueEmailController from './salesgatewayOverdueEmailController';
import * as salesgatewayContainerBackRouteController from './salesgatewayContainerBackRouteController';
import * as businesscommodityOpenRailwayBuyerProductController from './businesscommodityOpenRailwayBuyerProductController';
import * as salesgatewayThirdPartyController from './salesgatewayThirdPartyController';
import * as sinotransbookingBusinessConfigurationController from './sinotransbookingBusinessConfigurationController';
import * as salesgatewayOpenContainerBackRouteOpenController from './salesgatewayOpenContainerBackRouteOpenController';
import * as salesgatewayPortAreaController from './salesgatewayPortAreaController';
import * as salesgatewayEnterpriseWxEmployeeController from './salesgatewayEnterpriseWxEmployeeController';
import * as logisticssolutionSellerDeclarationOrderController from './logisticssolutionSellerDeclarationOrderController';
import * as salesgatewayOpenZoneActivityController from './salesgatewayOpenZoneActivityController';
export default {
  salesgatewayZoneActivityController,
  salesgatewayBizOrderController,
  salesgatewayShippingCommonController,
  salesgatewayOpenAuthController,
  sinotransbookingBuyerProductController,
  salesgatewayYardBillController,
  salesgatewayDeclarationPerformanceSellerController,
  logisticssolutionSellerContainerOrderController,
  sinotransbookingOpenProductController,
  salesgatewayPaymentOnAccountManagerController,
  salesgatewayYardController,
  logisticssolutionSellerPerformanceOrderController,
  sinotransbookingOpenCenterConfigController,
  salesgatewayShippingSaleController,
  salesgatewayEnterpriseController,
  salesgatewayCashierController,
  businessinvoiceSellerInvoiceController,
  salesgatewayContainerOwnerBoxController,
  sinotransbookingSellerSurchargeController,
  sinotransbookingBuyerSurchargeController,
  salesgatewayRailwayBuyerController,
  salesgatewayMemberController,
  salesgatewayShippingBuyerController,
  sinotransbookingPerformanceSeaSellerController,
  salesgatewayConfigCenterController,
  salesgatewayDeclarationBillController,
  salesgatewayChangeApplyBuyerController,
  salesgatewayPlannedOrderController,
  salesgatewayBizFulfillmentController,
  salesgatewayBizAdditionalFeesController,
  salesgatewayYardEnterpriseRelationController,
  salesgatewayContainerLeasingBuyerController,
  salesgatewayRailwaySellerController,
  sinotransbookingSellerStatementController,
  salesgatewayMessageControllerV2,
  sinotransbookingOperationProductController,
  salesgatewayDictController,
  sinotransbookingPerformanceDeliverySellerController,
  logisticssolutionOpenCommonController,
  logisticssolutionOpenCenterConfigController,
  sinotransbookingPerformanceBuyerController,
  businesscommodityOriginCoverCityTemplateController,
  sinotransbookingSellerLineController,
  salesgatewayContainerProdSupplierController,
  sinotransbookingPerformanceSellerController,
  salesgatewayExpirationRuleController,
  salesgatewayRailwayCommonController,
  sinotransbookingSellerProductController,
  salesgatewayContainerBackDemandController,
  salesgatewayAttachmentController,
  salesgatewayPaymentOnAccountController,
  salesgatewayContainerLeasingPriceMarkController,
  salesgatewayImMessageController,
  salesgatewayContainerOwnerBoxLogController,
  salesgatewayCashierV2Controller,
  salesgatewayShippingProductController,
  businessinvoiceBuyerInvoiceController,
  sinotransbookingOpenOssController,
  sinotransbookingOperationPerformanceController,
  businessinvoiceBuyerApplyInvoiceController,
  salesgatewayOfflinePaymentController,
  sinotransbookingOperationPurchaseStatementController,
  salesgatewayHelperCenterController,
  sinotransbookingOperationSalesStatementController,
  salesgatewayOpenFileUploadController,
  salesgatewayDeclarationSellerProductController,
  salesgatewayOrganizationController,
  logisticssolutionSellerPerformanceOrderStatementController,
  salesgatewayPermissionController,
  salesgatewayContainerOwnerPortAreaYardController,
  salesgatewayStatementController,
  salesgatewayShippingSellerController,
  salesgatewayAfterSaleController,
  logisticssolutionBuyerPerformanceOrderController,
  logisticssolutionBuyerContainerOrderController,
  salesgatewayAuthController,
  salesgatewayChangeApplySellerController,
  salesgatewaySocialController,
  salesgatewayUseContainerLineOpenController,
  salesgatewayLeasingPaymentController,
  sinotransbookingSellerStatementControllerV2,
  logisticssolutionBuyerPerformanceOrderStatementController,
  salesgatewayAccountController,
  businessinvoiceSellerApplyInvoiceController,
  sinotransbookingBuyerStatementControllerV2,
  sinotransbookingOperationCommonController,
  logisticssolutionSellerDemandOrderController,
  salesgatewayYardWebsiteController,
  salesgatewayOpenPortAreaController,
  salesgatewayOpenConfigCenterController,
  salesgatewayContainerOwnerStockController,
  salesgatewayBizDeclarationBillController,
  logisticssolutionOpenOssController,
  salesgatewayDeclarationCommonController,
  salesgatewayContainerLogController,
  salesgatewayEnterpriseV1Controller,
  salesgatewayMessageController,
  salesgatewayDemandController,
  salesgatewayContractController,
  salesgatewayUseContainerLineController,
  sinotransbookingPerformanceTrailerSellerController,
  salesgatewayBuyerWorkbenchController,
  salesgatewayWorkBenchMessageController,
  sinotransbookingOperationSurchargeController,
  salesgatewayWorkbenchTrackingController,
  salesgatewayDeclarationPerformanceCommonController,
  sinotransbookingSellerSettlementTypeController,
  salesgatewayBillController,
  salesgatewayAccountEnterpriseController,
  salesgatewayContainerBackRouteOpenController,
  logisticssolutionBuyerDemandOrderController,
  sinotransbookingOperationBusinessScopeController,
  salesgatewayBuyerSurchargeController,
  salesgatewayCategoryController,
  businesscommodityRailwayBuyerProductController,
  logisticssolutionSellerTrailerOrderController,
  salesgatewayOpenContainerLeasingPriceMarkController,
  salesgatewayUseContainerController,
  salesgatewayOpenHomeController,
  logisticssolutionSellerTrainOrderController,
  salesgatewayContainerLeasingProductController,
  salesgatewayContainerConfigController,
  salesgatewayDeclarationPerformanceBuyerController,
  sinotransbookingPerformanceClearanceSellerController,
  sinotransbookingPerformanceSeaBuyerController,
  salesgatewayHomeController,
  salesgatewayDeclarationBuyerProductController,
  salesgatewayRailwayBuyerProductController,
  salesgatewaySellerWorkbenchController,
  sinotransbookingBuyerStatementController,
  salesgatewayMarketingController,
  salesgatewayAgreementController,
  salesgatewayEnterpriseInvoiceTitleController,
  salesgatewayContainerOwnerDataCountController,
  salesgatewayOpenRailwayBuyerProductController,
  salesgatewayRailwaySellerProductController,
  salesgatewayOpenContainerLeasingProductController,
  salesgatewayOpenAttachmentController,
  salesgatewayContainerLeasingController,
  exportcenterExportController,
  sinotransbookingPerformanceDeclarationSellerController,
  salesgatewayOverdueEmailController,
  salesgatewayContainerBackRouteController,
  businesscommodityOpenRailwayBuyerProductController,
  salesgatewayThirdPartyController,
  sinotransbookingBusinessConfigurationController,
  salesgatewayOpenContainerBackRouteOpenController,
  salesgatewayPortAreaController,
  salesgatewayEnterpriseWxEmployeeController,
  logisticssolutionSellerDeclarationOrderController,
  salesgatewayOpenZoneActivityController,
};
