import { useRequest } from 'ahooks';
import { Cascader } from 'antd';
import type { DefaultOptionType } from 'antd/lib/cascader';
import { useEffect, useState } from 'react';
import type { EasyCascaderProps } from './interface';

const EasyCascader = (props: EasyCascaderProps) => {
  const {
    selectProps: { api, optionParams, ...restSelectProps },
    ...restprops
  } = props;
  const [options, setOptions] = useState([]);
  const [targetOption, setTargetOption] = useState<undefined | Record<string, any>>();
  const { loading: optionLoading } = useRequest(api, {
    defaultParams: [optionParams],
    onSuccess: (result) => {
      setOptions((result as any).records.map((item: any) => ({ value: item.id, label: item.nameZh, isLeaf: false })));
    },
  });
  const { run: getChildren } = useRequest(api, {
    manual: true,
    onSuccess: (result) => {
      const children = (result as any).records?.map((item: any) => ({ value: item.id, label: item.nameZh }));
      setTargetOption({ ...targetOption, children });
      const targetIndex = (options as DefaultOptionType[]).findIndex((item) => item.value === targetOption?.value);
      (options[targetIndex] as any).children = children;

      setOptions([...options]);
    },
  });

  const loadData = (selectedOptions: DefaultOptionType[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    setTargetOption(targetOption);
    getChildren({ size: -1, level: 4, parentId: targetOption.value });
  };
  const cascaderProps = {
    ...restprops,
    ...restSelectProps,
    options,
  };
  //回显数据
  useEffect(() => {
    if (props?.value && options) {
      const [parentId] = props.value;
      const targetOption = options.find((item: DefaultOptionType) => item.value === parentId);
      setTargetOption(targetOption);

      getChildren({ size: -1, level: 4, parentId });
    }
  }, [props?.value, optionLoading]);

  return <Cascader loadData={loadData} loading={optionLoading} {...cascaderProps}></Cascader>;
};
export default EasyCascader;
