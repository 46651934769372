import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { InputNumber } from 'antd';
import cls from 'classnames';
import BigNumber from 'bignumber.js';
import type { InputNumberProps } from 'antd';
import { IconFont } from '../icon-font';
import styles from './index.module.less';

enum AMOUNT_TYPE {
  ADD = 'ADD',
  FLOW = 'FLOW',
}
const ProInputNumber: FC<InputNumberProps> = (props) => {
  const { disabled = false, value, min = 0, max, onChange, step = 1 } = props;

  const isMin = useMemo(() => (Number.isFinite(min) ? (value ?? 0) < BigNumber(min).plus(step).toString() : false), [min, value, step]);
  const isMax = useMemo(() => (Number.isFinite(max) ? (value ?? 0) > BigNumber(max!).minus(step).toString() : false), [max, value, step]);

  const handleChange = (type: AMOUNT_TYPE) => {
    let dealCount = (value ?? 0) as number;
    const isDisable = type === AMOUNT_TYPE.ADD ? isMax : isMin;
    if (disabled || isDisable) return;
    if (type === AMOUNT_TYPE.ADD) {
      dealCount = +BigNumber(dealCount).plus(step).toString();
    } else {
      dealCount = +BigNumber(dealCount).minus(step).toString();
    }
    onChange?.(dealCount);
  };

  return (
    <InputNumber
      {...props}
      controls={false}
      addonBefore={
        <div className={cls(styles.action_icon, { [styles.is_min]: disabled || isMin })} onClick={() => handleChange(AMOUNT_TYPE.FLOW)}>
          <IconFont type="easygo-icon-wushuju-weizhankai" />
        </div>
      }
      addonAfter={
        <div className={cls(styles.action_icon, { [styles.is_max]: disabled || isMax })} onClick={() => handleChange(AMOUNT_TYPE.ADD)}>
          <IconFont type="easygo-icon-youshuju-weizhankai" />
        </div>
      }
      className={styles.pro_input_number}
    />
  );
};
export default ProInputNumber;
