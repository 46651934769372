// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 同步前端权限数据 PUT /salesgateway/permission/refreshPermission */
export async function OperationId_salesgateway_PermissionController_refreshPermission(
  body: API.RefreshPermissionReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ResultResponseBoolean>('/salesgateway/permission/refreshPermission', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PermissionController_refreshPermission',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
