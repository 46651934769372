// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 接受订单 PUT /salesgateway/v1/railway/seller/accept/${param0} */
export async function OperationId_salesgateway_RailwaySellerController_orderAccept(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerControllerOrderAcceptParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/seller/accept/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_orderAccept',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 到达目的车站 PUT /salesgateway/v1/railway/seller/arrive */
export async function OperationId_salesgateway_RailwaySellerController_arrive(
  body: API.RailwayArriveRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/arrive', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_arrive',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 创建账单 POST /salesgateway/v1/railway/seller/bill/${param0}/createOrUpdate */
export async function OperationId_salesgateway_RailwaySellerController_createOrUpdateBill(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerControllerCreateOrUpdateBillParams,
  body: API.AddOrUpdatePerformanceBillRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/seller/bill/${param0}/createOrUpdate`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_createOrUpdateBill',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 改价接口 PUT /salesgateway/v1/railway/seller/changePrice */
export async function OperationId_salesgateway_RailwaySellerController_changePrice(
  body: API.RailwayChangePriceRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/changePrice', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_changePrice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 确认舱位 PUT /salesgateway/v1/railway/seller/confirmPo */
export async function OperationId_salesgateway_RailwaySellerController_confirmPo(
  body: API.ConfirmPoInfoRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/confirmPo', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_confirmPo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 删除PoItem DELETE /salesgateway/v1/railway/seller/deletePoItem */
export async function OperationId_salesgateway_RailwaySellerController_deletePoItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerControllerDeletePoItemParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/deletePoItem', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_deletePoItem',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 发车 PUT /salesgateway/v1/railway/seller/depart */
export async function OperationId_salesgateway_RailwaySellerController_depart(
  body: API.RailwayDepartRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/depart', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_depart',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 修改舱位 PUT /salesgateway/v1/railway/seller/editPo */
export async function OperationId_salesgateway_RailwaySellerController_editPo(
  body: API.EditPoInfoRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/editPo', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_editPo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 重箱进站 PUT /salesgateway/v1/railway/seller/heavyEntry */
export async function OperationId_salesgateway_RailwaySellerController_heavyEntry(
  body: API.RailwayHeavyEntryRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/heavyEntry', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_heavyEntry',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 放行 PUT /salesgateway/v1/railway/seller/letGo */
export async function OperationId_salesgateway_RailwaySellerController_letGo(
  body: API.LetGoRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/letGo', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_letGo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取客户列表 GET /salesgateway/v1/railway/seller/listPerformanceCustom */
export async function OperationId_salesgateway_RailwaySellerController_listPerformanceCustom(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceCustomerItemVo[]>(
    '/salesgateway/v1/railway/seller/listPerformanceCustom',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwaySellerController_listPerformanceCustom',
      },
      ...(rest || {}),
    },
  );
}

/** 铁运订单列表 POST /salesgateway/v1/railway/seller/orderList */
export async function OperationId_salesgateway_RailwaySellerController_orderList(
  body: API.RailwaySellerOrderListPageQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RailwayOrderListVO>('/salesgateway/v1/railway/seller/orderList', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_orderList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 铁路订单列表banner GET /salesgateway/v1/railway/seller/orderListBanner */
export async function OperationId_salesgateway_RailwaySellerController_orderListBanner(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.RailwayOrderBannerVO[]>('/salesgateway/v1/railway/seller/orderListBanner', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_orderListBanner',
    },
    ...(rest || {}),
  });
}

/** 申请提箱 POST /salesgateway/v1/railway/seller/pickUp/${param0}/apply */
export async function OperationId_salesgateway_RailwaySellerController_pickUpApply(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerControllerPickUpApplyParams,
  body: API.RailwayPickupApplyRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/seller/pickUp/${param0}/apply`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_pickUpApply',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 作废提箱单 DELETE /salesgateway/v1/railway/seller/pickUp/${param0}/invalid */
export async function OperationId_salesgateway_RailwaySellerController_invalidPickUpBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerControllerInvalidPickUpBoxParams,
  body: API.InvalidPickUpBoxRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/seller/pickUp/${param0}/invalid`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_invalidPickUpBox',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 查看消息列表 GET /salesgateway/v1/railway/seller/queryNotifyList */
export async function OperationId_salesgateway_RailwaySellerController_queryNotifyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerControllerQueryNotifyListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MessageItemVO[]>('/salesgateway/v1/railway/seller/queryNotifyList', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_queryNotifyList',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 拒绝接单 PUT /salesgateway/v1/railway/seller/reject */
export async function OperationId_salesgateway_RailwaySellerController_rejectedOrder(
  body: API.RailwayRejectedOrderRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/reject', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_rejectedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 上报异常 PUT /salesgateway/v1/railway/seller/reportException */
export async function OperationId_salesgateway_RailwaySellerController_reportException(
  body: API.ReportExceptionRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/reportException', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_reportException',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 作废还箱单 DELETE /salesgateway/v1/railway/seller/returnBox/${param0}/invalid */
export async function OperationId_salesgateway_RailwaySellerController_invalidReturnBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerControllerInvalidReturnBoxParams,
  body: API.RailwayInvalidReturnBoxRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/seller/returnBox/${param0}/invalid`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_invalidReturnBox',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 更新poItem PUT /salesgateway/v1/railway/seller/updatePoItem */
export async function OperationId_salesgateway_RailwaySellerController_updatePoItem(
  body: API.UpdatePoItemListRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/updatePoItem', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_updatePoItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 上传提货指令 PUT /salesgateway/v1/railway/seller/uploadInstruction */
export async function OperationId_salesgateway_RailwaySellerController_uploadInstruction(
  body: API.UploadInstructionRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/uploadInstruction', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerController_uploadInstruction',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
