// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取平台协议 GET /salesgateway/agreements */
export async function OperationId_salesgateway_AgreementController_getAgreements(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAgreementControllerGetAgreementsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AgreementDTO[]>('/salesgateway/agreements', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AgreementController_getAgreements',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
