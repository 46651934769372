// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 根据订单号查询箱号列表 GET /salesgateway/container-leasing/containers/${param0}/box-nos */
export async function OperationId_salesgateway_ContainerLeasingController_getContainerNOs(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingControllerGetContainerNOsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetContainerNOsVO>(
    `/salesgateway/container-leasing/containers/${param0}/box-nos`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLeasingController_getContainerNOs',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查看箱子轨迹接口 GET /salesgateway/container-leasing/containers/${param0}/positions */
export async function OperationId_salesgateway_ContainerLeasingController_listContainerPosition(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingControllerListContainerPositionParams,
  options?: { [key: string]: any },
) {
  const { containerNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ContainerPositionVO[]>(
    `/salesgateway/container-leasing/containers/${param0}/positions`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLeasingController_listContainerPosition',
      },
      params: {
        ...queryParams,
        vo: undefined,
        ...queryParams['vo'],
      },
      ...(rest || {}),
    },
  );
}

/** 查询订单超时未支付天数 GET /salesgateway/container-leasing/order/timeout-unpaid-days */
export async function OperationId_salesgateway_ContainerLeasingController_getOrderTimeOutUnpaidDays(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/container-leasing/order/timeout-unpaid-days', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingController_getOrderTimeOutUnpaidDays',
    },
    ...(rest || {}),
  });
}
