import { getEnumsLabelValue } from '../utils';

export enum EIRStatus {
  /** 未预约 */
  NO_BOOKING = 'EIR_STATUS_NO_BOOKING',
  /** 已预约 */
  BOOKED = 'EIR_STATUS_BOOKED',
  /** 已进场 */
  ALREADY_IN_YARD = 'EIR_STATUS_ALREADY_IN_YARD',
  /** 已出场 */
  ALREADY_OUT_YARD = 'EIR_STATUS_ALREADY_OUT_YARD',
  /** 完成 */
  FINISHED = 'EIR_STATUS_FINISHED',
  /** 已作废 */
  CANCELED = 'EIR_STATUS_CANCELED',
}

export const EIRStatusLabel = {
  [EIRStatus.NO_BOOKING]: '未预约',
  [EIRStatus.BOOKED]: '已预约',
  [EIRStatus.ALREADY_IN_YARD]: '已进场',
  [EIRStatus.ALREADY_OUT_YARD]: '已出场',
  [EIRStatus.FINISHED]: '完成',
  [EIRStatus.CANCELED]: '已作废',
};
/** EIR作业进度、状态color */
export const EIRStatusColors = {
  [EIRStatus.NO_BOOKING]: '#FF8413',
  [EIRStatus.BOOKED]: '#2C334D',
  [EIRStatus.ALREADY_IN_YARD]: '#2C334D',
  [EIRStatus.ALREADY_OUT_YARD]: '#2C334D',
  [EIRStatus.FINISHED]: '#2C334D',
  [EIRStatus.CANCELED]: '#FF4747',
};

export const eirStatusOptions = getEnumsLabelValue(EIRStatusLabel);

/** 修洗结算方式 */
export enum YardBoxSettlementMode {
  /** 现结 */
  CASH = 'YARD_BOX_SETTLEMENT_MODE_CASH',
  /** 账期 */
  BILL = 'YARD_BOX_SETTLEMENT_MODE_BILL',
}

/** 修洗结算方式 */
export const YardBoxSettlementModeLabel = {
  [YardBoxSettlementMode.CASH]: '现结',
  [YardBoxSettlementMode.BILL]: '账期',
};

export const yardBoxSettlementModeOptions = getEnumsLabelValue(YardBoxSettlementModeLabel);

export enum CheckBoxNorm {
  /** IICL */
  IICL = 'CHECK_BOX_NORM_IICL',
  /** 新箱标准 */
  NEW = 'CHECK_BOX_NORM_NEW',
  /** 内地班列标准 */
  TRAIN = 'CHECK_BOX_NORM_TRAIN',
  /** CW */
  CW = 'CHECK_BOX_NORM_CW',
}

export const CheckBoxNormLabel = {
  [CheckBoxNorm.IICL]: 'IICL',
  [CheckBoxNorm.NEW]: '新箱标准',
  [CheckBoxNorm.TRAIN]: '内地班列标准',
  [CheckBoxNorm.CW]: 'CW',
};

/** 验箱状态 */
export enum CheckBoxStatus {
  /** 未验箱 */
  NO = 'CHECK_BOX_STATUS_NO',
  /** 已验箱 */
  YES = 'CHECK_BOX_STATUS_YES',
}

/** 验箱状态 */
export const CheckBoxStatusLabel = {
  [CheckBoxStatus.NO]: '未验箱',
  [CheckBoxStatus.YES]: '已验箱',
};
