import { enums } from '@easygo/utils';
import styles from './index.module.less';
import React from 'react';

export interface Ticket {
  label: string;
  desc: string;
  /** 满减金额 */
  literal?: {
    currency: keyof typeof enums.CurrencySymbolMap;
    price: number;
  };
  /** 打折百分比0-10 */
  discount?: number;
  t: any;
  [key: string]: any;
}
export function Ticket({ label, desc, literal, discount, className, t, ...res }: Ticket & React.HTMLAttributes<HTMLDivElement>) {
  // const { t } = useTranslation('common');
  return (
    <div className={`${styles.ticket} ${className}`} {...res}>
      <div className={styles.l}>
        <p className={styles.title}>{label}</p>
        <p className={styles.type}>{desc}</p>
      </div>
      <div className={styles.r}>
        {literal && (
          <>
            <i className={styles.symb}>{enums.CurrencySymbolMap[literal.currency]}</i> {(+literal.price).toFixed(0)}
          </>
        )}
        {!literal && discount && (
          <>
            {discount}
            <i className={styles.symb}>{t('rate')}</i>
          </>
        )}
      </div>
    </div>
  );
}

export function Tickets({ tickets = [], t, className = '', ...resProps }: { tickets: Ticket[]; t: any } & React.HTMLAttributes<HTMLDivElement>) {
  return tickets.length > 0 ? (
    <div className={`${styles.tickets} ${className}`} {...resProps}>
      <img className={styles.icon} src="/images/ticket.png" alt="ticke" />
      {tickets.map((ticket, i) => (
        <Ticket {...ticket} key={i} t={t} className={styles.mb12} />
      ))}
    </div>
  ) : null;
}
