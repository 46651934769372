/**
 * 箱子相关的枚举
 */

/** 验箱标准 */
export enum RepairCode {
  /** IICL */
  IICL = 'REPAIR_CODE_IICL',
  /** 新箱标准 */
  NEW = 'REPAIR_CODE_NEW',
  /** 内地班列标准 */
  INLAND_BLOCK_TRAIN = 'REPAIR_CODE_INLAND_BLOCK_TRAIN',
  /** CW */
  CW = 'REPAIR_CODE_CW',
}
/** 验箱标准 */
export const RepairCodeOptions = {
  [RepairCode.IICL]: 'IICL',
  [RepairCode.NEW]: '新箱标准',
  [RepairCode.INLAND_BLOCK_TRAIN]: '内地班列标准',
  [RepairCode.CW]: 'CW',
};

/** 还箱模式 */
export enum DropoffMode {
  /** 次日估价 */
  NEXT_DAY = 'DROPOFF_MODE_NEXT_DAY',
  /** 到场现结 */
  SCENE = 'DROPOFF_MODE_SCENE',
}
/** 还箱模式 */
export const DropoffModeOptions = {
  [DropoffMode.NEXT_DAY]: '次日估价',
  [DropoffMode.SCENE]: '到场现结',
};

// 空重
export const enum EmptyFull {
  CONTAINER_EMPTY_FULL_ENUM_EMPTY = 'CONTAINER_EMPTY_FULL_ENUM_EMPTY',
  CONTAINER_EMPTY_FULL_ENUM_FULL = 'CONTAINER_EMPTY_FULL_ENUM_FULL',
}
export const EmptyFullLabel = {
  [EmptyFull.CONTAINER_EMPTY_FULL_ENUM_EMPTY]: '空箱',
  [EmptyFull.CONTAINER_EMPTY_FULL_ENUM_FULL]: '重箱',
};

// 箱使用状态（好箱 - AV/坏箱 - DM/报废箱 - SC）
export const enum UsageStatus {
  CONTAINER_USAGE_STATUS_ENUM_GOOD = 'CONTAINER_USAGE_STATUS_ENUM_GOOD',
  CONTAINER_USAGE_STATUS_ENUM_BAD = 'CONTAINER_USAGE_STATUS_ENUM_BAD',
  CONTAINER_USAGE_STATUS_ENUM_SCRAP = 'CONTAINER_USAGE_STATUS_ENUM_SCRAP',
  CONTAINER_USAGE_STATUS_ENUM_UNVERIFIED = 'CONTAINER_USAGE_STATUS_ENUM_UNVERIFIED',
}
export const UsageStatusLabel = {
  [UsageStatus.CONTAINER_USAGE_STATUS_ENUM_GOOD]: '好箱',
  [UsageStatus.CONTAINER_USAGE_STATUS_ENUM_BAD]: '箱损',
  [UsageStatus.CONTAINER_USAGE_STATUS_ENUM_SCRAP]: '报废',
  [UsageStatus.CONTAINER_USAGE_STATUS_ENUM_UNVERIFIED]: '未验',
};

// 箱子状态
export const enum LocateStatus {
  CONTAINER_LOCATE_STATUS_ENUM_IN = 'CONTAINER_LOCATE_STATUS_ENUM_IN',
  CONTAINER_LOCATE_STATUS_ENUM_OUT = 'CONTAINER_LOCATE_STATUS_ENUM_OUT',
}
export const LocateStatusLabel = {
  [LocateStatus.CONTAINER_LOCATE_STATUS_ENUM_IN]: '在场',
  [LocateStatus.CONTAINER_LOCATE_STATUS_ENUM_OUT]: '离场',
};

// 订箱状态
export enum ReserveContainerStatusEnum {
  ENUM_RESERVE = 'RESERVE_CONTAINER_STATUS_ENUM_RESERVE',
  ENUM_NO_RESERVE = 'RESERVE_CONTAINER_STATUS_ENUM_NO_RESERVE',
}
export const ReserveContainerStatusLabel = {
  [ReserveContainerStatusEnum.ENUM_RESERVE]: '订箱',
  [ReserveContainerStatusEnum.ENUM_NO_RESERVE]: '未订箱',
};
