import { Button, Input, message, Spin } from 'antd';
import { useState, useRef, useEffect } from 'react';
import styles from './index.module.less';
import { useMemoizedFn, useRequest } from 'ahooks';
import { useComponentTrans, componentI18nInstance } from '../config';

const renderText = ({ loading, seconds, loadingText }: Record<string, any>) => {
  if (loading) {
    return '';
  } else if (seconds) {
    return loadingText;
  } else {
    return componentI18nInstance.trans('fetch_validate_code');
  }
};
/**
 * @param api 发送验证码的接口
 * @param immediately 是否立马发送验证码
 */
export function ValidateButtonInput(props: any) {
  const { api, immediately = false, ...restProps } = props;
  const trans = useComponentTrans();
  const [seconds, setSeconds] = useState(0);
  const timer = useRef<{ time?: any; second: number }>({
    time: null,
    second: 0,
  });
  const loop = useMemoizedFn(() => {
    if (timer.current.time && timer.current.second === 0) {
      clearTimeout(timer.current.time);
      timer.current.time = null;
      return;
    }
    timer.current.time = setTimeout(() => {
      const nextSecond = timer.current.second - 1;
      timer.current.second = nextSecond;
      setSeconds(nextSecond);
      loop();
    }, 1000);
  });
  const { loading, run } = useRequest(api, {
    manual: immediately ? false : true,
    onSuccess: (res: any) => {
      message.success(trans('send_validate_code_finished'));
      let time = 60;
      if (res && res.data.data?.nextTime) {
        time = res.data.data?.nextTime;
      }
      timer.current.second = time;
      setSeconds(time);
      loop();
    },
  });
  const handleClick = () => {
    if (!loading && timer.current.second === 0) {
      run();
    }
  };

  useEffect(() => {
    return () => {
      if (timer.current.time) {
        clearTimeout(timer.current.time);
      }
    };
  }, []);
  return (
    <div className={styles.wrap}>
      <Input controls={false} className={styles.input} {...restProps} />
      <div className={styles.button}>
        <Spin size="small" spinning={loading}>
          <Button disabled={!!seconds} onClick={handleClick}>
            {renderText({ loading, seconds, loadingText: `${seconds}s` })}
          </Button>
        </Spin>
      </div>
    </div>
  );
}
