// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 订单账单信息列表 GET /salesgateway/declaration/bill/${param0} */
export async function OperationId_salesgateway_DeclarationBillController_list(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationBillControllerListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BillVo>(`/salesgateway/declaration/bill/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationBillController_list',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取附加费明细 GET /salesgateway/declaration/bill/${param0}/additional-fee */
export async function OperationId_salesgateway_DeclarationBillController_additionalFee(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationBillControllerAdditionalFeeParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BillVo>(`/salesgateway/declaration/bill/${param0}/additional-fee`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationBillController_additionalFee',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取商品费用明细 GET /salesgateway/declaration/bill/${param0}/commodity-fee */
export async function OperationId_salesgateway_DeclarationBillController_commodityFee(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationBillControllerCommodityFeeParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BillVo>(`/salesgateway/declaration/bill/${param0}/commodity-fee`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationBillController_commodityFee',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 支付明细列表 GET /salesgateway/declaration/bill/${param0}/pay-list */
export async function OperationId_salesgateway_DeclarationBillController_payList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationBillControllerPayListParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PayListVo>(`/salesgateway/declaration/bill/${param0}/pay-list`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationBillController_payList',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 支付账单 POST /salesgateway/declaration/bill/${param0}/to-pay */
export async function OperationId_salesgateway_DeclarationBillController_toPay(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationBillControllerToPayParams,
  body: string,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<number>(`/salesgateway/declaration/bill/${param0}/to-pay`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationBillController_toPay',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
