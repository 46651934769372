// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 线下收款确认统计 GET /salesgateway/offline-payment/api/collectionOrderSummary */
export async function OperationId_salesgateway_OfflinePaymentController_collectionOrderSummary(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.CollectionOrderSummaryRespVO>(
    '/salesgateway/offline-payment/api/collectionOrderSummary',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OfflinePaymentController_collectionOrderSummary',
      },
      ...(rest || {}),
    },
  );
}

/** 卖家端-线下转账流水查询 POST /salesgateway/offline-payment/api/paymentFlow */
export async function OperationId_salesgateway_OfflinePaymentController_offlinePaymentFlow(
  body: API.PageOfflinePaymentFlowReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PageOfflinePaymentFlowRespVO>(
    '/salesgateway/offline-payment/api/paymentFlow',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OfflinePaymentController_offlinePaymentFlow',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 线下转账流水查询详情 GET /salesgateway/offline-payment/api/paymentFlow/${param0} */
export async function OperationId_salesgateway_OfflinePaymentController_offlinePaymentFlowDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOfflinePaymentControllerOfflinePaymentFlowDetailParams,
  options?: { [key: string]: any },
) {
  const { flowId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OfflinePaymentFlowDetailRespVO>(
    `/salesgateway/offline-payment/api/paymentFlow/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OfflinePaymentController_offlinePaymentFlowDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 线下转账流水更新 PUT /salesgateway/offline-payment/api/paymentFlow/${param0} */
export async function OperationId_salesgateway_OfflinePaymentController_updateOfflinePaymentFlow(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOfflinePaymentControllerUpdateOfflinePaymentFlowParams,
  body: API.UpdateOfflinePaymentFlowReqVO,
  options?: { [key: string]: any },
) {
  const { flowId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/offline-payment/api/paymentFlow/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OfflinePaymentController_updateOfflinePaymentFlow',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 收款确认-支付单列表查询 POST /salesgateway/offline-payment/api/searchCollectionOrder */
export async function OperationId_salesgateway_OfflinePaymentController_searchCollectionOrder(
  body: API.SearchPaymentOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchPaymentOrderResPB>(
    '/salesgateway/offline-payment/api/searchCollectionOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OfflinePaymentController_searchCollectionOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 付款确认-支付单列表查询 POST /salesgateway/offline-payment/api/searchPaymentOrder */
export async function OperationId_salesgateway_OfflinePaymentController_searchPaymentOrder(
  body: API.SearchPaymentOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchPaymentOrderResPB>(
    '/salesgateway/offline-payment/api/searchPaymentOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OfflinePaymentController_searchPaymentOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 退款确认-查询退款单 POST /salesgateway/offline-payment/api/searchRefundInfo */
export async function OperationId_salesgateway_OfflinePaymentController_searchRefund(
  body: API.SearchRefundReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchRefundResPB>('/salesgateway/offline-payment/api/searchRefundInfo', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OfflinePaymentController_searchRefund',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 退款确认-卖家退款操作 POST /salesgateway/offline-payment/api/seller/refundConfirm */
export async function OperationId_salesgateway_OfflinePaymentController_refundConfirm(
  body: API.RefundConfirmVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/offline-payment/api/seller/refundConfirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OfflinePaymentController_refundConfirm',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
