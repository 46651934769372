// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取部门组织架构 GET /salesgateway/organizations */
export async function OperationId_salesgateway_OrganizationController_getOrganizations(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.OrganizationVo>('/salesgateway/organizations', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_getOrganizations',
    },
    ...(rest || {}),
  });
}

/** 添加子部门 POST /salesgateway/organizations */
export async function OperationId_salesgateway_OrganizationController_createChildOrganization(
  body: API.CreateChildOrganizationVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/organizations', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_createChildOrganization',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 更新部门 PUT /salesgateway/organizations/${param0} */
export async function OperationId_salesgateway_OrganizationController_updateOrganization(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerUpdateOrganizationParams,
  body: API.UpdateOrganizationVo,
  options?: { [key: string]: any },
) {
  const { organizationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/organizations/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_updateOrganization',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 删除部门 DELETE /salesgateway/organizations/${param0} */
export async function OperationId_salesgateway_OrganizationController_deleteOrganization(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerDeleteOrganizationParams,
  options?: { [key: string]: any },
) {
  const { organizationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/organizations/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_deleteOrganization',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取企业组织下绑定的员工（账号）列表 GET /salesgateway/organizations/accounts */
export async function OperationId_salesgateway_OrganizationController_getAccounts(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerGetAccountsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetOrganizationAccountsResponseVO>('/salesgateway/organizations/accounts', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_getAccounts',
    },
    params: {
      // page has a default value: 1
      page: '1',
      // pageSize has a default value: 10
      pageSize: '10',
      ...params,
    },
    ...(rest || {}),
  });
}

/** 添加企业部门员工（账号） POST /salesgateway/organizations/accounts */
export async function OperationId_salesgateway_OrganizationController_addAccount(
  body: API.AddAccountVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/organizations/accounts', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_addAccount',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 删除企业员工（账号） DELETE /salesgateway/organizations/accounts */
export async function OperationId_salesgateway_OrganizationController_deleteAccount(
  body: API.DeleteAccountVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/organizations/accounts', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_deleteAccount',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 更新企业员工（账号）部门 PATCH /salesgateway/organizations/accounts */
export async function OperationId_salesgateway_OrganizationController_updateAccountsOrganization(
  body: API.UpdateAccountOrganizationVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/organizations/accounts', {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_updateAccountsOrganization',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询企业员工（账号）信息 GET /salesgateway/organizations/accounts/${param0} */
export async function OperationId_salesgateway_OrganizationController_getAccount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerGetAccountParams,
  options?: { [key: string]: any },
) {
  const { accountId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetAccountProfileResponseVO>(
    `/salesgateway/organizations/accounts/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OrganizationController_getAccount',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 更新企业员工（账号）信息 PATCH /salesgateway/organizations/accounts/${param0} */
export async function OperationId_salesgateway_OrganizationController_updateAccount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerUpdateAccountParams,
  body: API.UpdateAccountVo,
  options?: { [key: string]: any },
) {
  const { accountId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/organizations/accounts/${param0}`, {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_updateAccount',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 查询企业员工（账号）角色 GET /salesgateway/organizations/accounts/${param0}/roles */
export async function OperationId_salesgateway_OrganizationController_getAccountRoles(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerGetAccountRolesParams,
  options?: { [key: string]: any },
) {
  const { accountId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BasicRoleResponseVO[]>(
    `/salesgateway/organizations/accounts/${param0}/roles`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OrganizationController_getAccountRoles',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 更新企业员工（账号）角色 PATCH /salesgateway/organizations/accounts/${param0}/roles */
export async function OperationId_salesgateway_OrganizationController_updateAccountRoles(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerUpdateAccountRolesParams,
  body: API.UpdateAccountRolesVo,
  options?: { [key: string]: any },
) {
  const { accountId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/organizations/accounts/${param0}/roles`, {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_updateAccountRoles',
      'Content-Type': 'application/json',
    },
    params: {
      ...queryParams,
    },
    data: body,
    ...(rest || {}),
  });
}

/** 批量绑定企业员工（账号）角色 PATCH /salesgateway/organizations/accounts/roles */
export async function OperationId_salesgateway_OrganizationController_bindAccountsRole(
  body: API.BindAccountRoleVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/organizations/accounts/roles', {
    method: 'PATCH',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_bindAccountsRole',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取当前企业的角色列表 OrganizationController.getRoles GET /salesgateway/organizations/roles */
export async function OperationId_salesgateway_OrganizationController_getRoles(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerGetRolesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetRolesResponse>('/salesgateway/organizations/roles', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_getRoles',
    },
    params: {
      // page has a default value: 1
      page: '1',
      // pageSize has a default value: 10
      pageSize: '10',
      ...params,
    },
    ...(rest || {}),
  });
}

/** 创建角色 POST /salesgateway/organizations/roles */
export async function OperationId_salesgateway_OrganizationController_createRole(
  body: API.CreateRoleVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/organizations/roles', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_createRole',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取指定角色权限列表 OrganizationController.getRole GET /salesgateway/organizations/roles/${param0} */
export async function OperationId_salesgateway_OrganizationController_getRole(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerGetRoleParams,
  options?: { [key: string]: any },
) {
  const { roleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.RoleWithPermissionGroupResponseVo>(
    `/salesgateway/organizations/roles/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_OrganizationController_getRole',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 更新角色 PUT /salesgateway/organizations/roles/${param0} */
export async function OperationId_salesgateway_OrganizationController_updateRole(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerUpdateRoleParams,
  body: API.UpdateRoleVo,
  options?: { [key: string]: any },
) {
  const { roleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/organizations/roles/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_updateRole',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 变更角色状态 PUT /salesgateway/organizations/roles/${param0}/status */
export async function OperationId_salesgateway_OrganizationController_setRoleStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOrganizationControllerSetRoleStatusParams,
  options?: { [key: string]: any },
) {
  const { roleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/organizations/roles/${param0}/status`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_setRoleStatus',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 获取当前权限数据 GET /salesgateway/organizations/roles/all-permissions */
export async function OperationId_salesgateway_OrganizationController_getPermissionGroups(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PermissionGroupTreeVo>('/salesgateway/organizations/roles/all-permissions', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OrganizationController_getPermissionGroups',
    },
    ...(rest || {}),
  });
}
