// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 买家端-关闭支付单 POST /sinotransbooking/v2/buyer/statement/${param0}/close */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_closePayment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2ClosePaymentParams,
  options?: { [key: string]: any },
) {
  const { paymentDetailId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/sinotransbooking/v2/buyer/statement/${param0}/close`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_closePayment',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 买家端-查看发票 POST /sinotransbooking/v2/buyer/statement/${param0}/invoice */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_invoiceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2InvoiceDetailParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.InvoiceVO>(`/sinotransbooking/v2/buyer/statement/${param0}/invoice`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_invoiceDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询账单支付方案 GET /sinotransbooking/v2/buyer/statement/${param0}/plan */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_queryStatementPayPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2QueryStatementPayPlanParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementPayPlanVO[]>(`/sinotransbooking/v2/buyer/statement/${param0}/plan`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_queryStatementPayPlan',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 买家端-子账单列表 GET /sinotransbooking/v2/buyer/statement/${param0}/sub */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_listSubStatements(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2ListSubStatementsParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SubStatementItemVO[]>(`/sinotransbooking/v2/buyer/statement/${param0}/sub`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_listSubStatements',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 买家端-去支付 POST /sinotransbooking/v2/buyer/statement/${param0}/toPay */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_toPay(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2ToPayParams,
  body: API.ToPayStatementRequest,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ToPayStatementResponse>(
    `/sinotransbooking/v2/buyer/statement/${param0}/toPay`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_toPay',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家端-确认账单 POST /sinotransbooking/v2/buyer/statement/confirm/${param0} */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_confirmStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2ConfirmStatementParams,
  body: API.ConfirmSalesStatementRequest,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/sinotransbooking/v2/buyer/statement/confirm/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_confirmStatement',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 下载卖家海运费用确认单 GET /sinotransbooking/v2/buyer/statement/details/${param0}/pdf */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_downloadBillInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2DownloadBillInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(`/sinotransbooking/v2/buyer/statement/details/${param0}/pdf`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_downloadBillInfo',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 买家端-主账单列表 POST /sinotransbooking/v2/buyer/statement/list */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_pageStatement(
  body: API.StatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStatementItemVO>('/sinotransbooking/v2/buyer/statement/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_pageStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家端-根据订单号获取关联子账单详情 POST /sinotransbooking/v2/buyer/statement/orderStatementDetail/${param0} */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_orderWithStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2OrderWithStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OrderWithStatementVO>(
    `/sinotransbooking/v2/buyer/statement/orderStatementDetail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_orderWithStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 买家端-根据账单查询账单费用列表 POST /sinotransbooking/v2/buyer/statement/queryStatementFeeList */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_queryStatementFeeListByOrderId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2QueryStatementFeeListByOrderIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StatementFeeItemVO[]>(
    '/sinotransbooking/v2/buyer/statement/queryStatementFeeList',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_BuyerStatementControllerV2_queryStatementFeeListByOrderId',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 买家端-主账单详情 POST /sinotransbooking/v2/buyer/statement/statementDetail/${param0} */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_statementDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2StatementDetailParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BuyerStatementDetailVO>(
    `/sinotransbooking/v2/buyer/statement/statementDetail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_BuyerStatementControllerV2_statementDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 买家端-应付金额 POST /sinotransbooking/v2/buyer/statement/statementPayment/${param0} */
export async function OperationId_sinotransbooking_BuyerStatementControllerV2_statementPaymentAmount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerV2StatementPaymentAmountParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementPaymentAmountVO>(
    `/sinotransbooking/v2/buyer/statement/statementPayment/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_BuyerStatementControllerV2_statementPaymentAmount',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
