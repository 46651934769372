import { sharedApiConfig } from '@easygo/config/commonApiConfig';
import { apis } from '@/server';

const bootstrap = () => {
  // 设置shared接口
  sharedApiConfig.setApiConfig({
    pageAddress: apis.default.salesgatewayConfigCenterController.OperationId_salesgateway_ConfigCenterController_pageAddress,
    sinoPageAddress: apis.default.sinotransbookingOpenCenterConfigController.OperationId_sinotransbooking_OpenCenterConfigController_pageAddress,
    sinoPageHarbour: apis.default.sinotransbookingOpenCenterConfigController.OperationId_sinotransbooking_OpenCenterConfigController_pageHarbour,
    pageHarbourNotIncludedCity:
      apis.default.salesgatewayConfigCenterController.OperationId_salesgateway_ConfigCenterController_pageHarbourNotIncludedCity,
    pageHarbour: apis.default.salesgatewayConfigCenterController.OperationId_salesgateway_ConfigCenterController_pageHarbour,
    nameFuzzyCityPortOptions:
      apis.default.salesgatewayShippingProductController.OperationId_salesgateway_ShippingProductController_nameFuzzyCityPortOptions,
    pageYardEnterpriseRelation:
      apis.default.salesgatewayYardEnterpriseRelationController.OperationId_salesgateway_YardEnterpriseRelationController_pageYardEnterpriseRelation,
    queryProvenanceCities:
      apis.default.salesgatewayContainerProdSupplierController.OperationId_salesgateway_ContainerProdSupplierController_queryProvenanceCities,
    pageBoxType: apis.default.salesgatewayConfigCenterController.OperationId_salesgateway_ConfigCenterController_pageBoxType,
    pageShippingCompany: apis.default.salesgatewayConfigCenterController.OperationId_salesgateway_ConfigCenterController_pageShippingCompany,
  });
};

export default bootstrap;
