// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 开户文件上传-上传 POST /salesgateway/open-file-upload/uploadOpenAcctFile */
export async function OperationId_salesgateway_OpenFileUploadController_uploadOpenAcctFile(
  body: API.UploadFileRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OpenAcctBankResponseVo>('/salesgateway/open-file-upload/uploadOpenAcctFile', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenFileUploadController_uploadOpenAcctFile',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
