// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取对账单详情信息 GET /sinotransbooking/v1/buyer/statement/${param0} */
export async function OperationId_sinotransbooking_BuyerStatementController_getStatementDetailInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerStatementControllerGetStatementDetailInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementDetailInfoVO>(`/sinotransbooking/v1/buyer/statement/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementController_getStatementDetailInfo',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 分页查询对账单详情信息 POST /sinotransbooking/v1/buyer/statement/list */
export async function OperationId_sinotransbooking_BuyerStatementController_pageStatement(
  body: API.SalesBuyerStatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStatementVO>('/sinotransbooking/v1/buyer/statement/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerStatementController_pageStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
