import { useRequest } from 'ahooks';
import { useRef } from 'react';

type ReturenValue = { label: string; value: string; [key: string]: any };

export type DictType = 'boxType' | 'shipCompany' | 'shipFees' | 'trainFees' | 'performanceTypeRentBox' | 'currency';

type DictConfigOptions = Record<DictType, (locale?: string) => Promise<ReturenValue[]>>;

const cache: Record<string, any> = { zh: {}, en: {} };

export const getWeight = (str: string) => {
  const sArr = str.split('');
  return sArr.reduce((a, b) => {
    return a + b.charCodeAt(0);
  }, 0);
};
class DictConfig {
  public static getWeight(str: string) {
    const sArr = str.split('');
    return sArr.reduce((a, b) => {
      return a + b.charCodeAt(0);
    }, 0);
  }
  public _config: DictConfigOptions = {
    boxType: async () => [],
    shipCompany: async (locale?: string) => [],
    shipFees: async () => [],
    trainFees: async () => [],
    /** 履约类型：海运：1，班列：2，租箱：3 */
    performanceTypeRentBox: async () => [],
  };
  public config(opts: Partial<DictConfigOptions>) {
    Object.assign(this._config, opts);
  }
}
export const dictConfig = new DictConfig();

export function useDict(type: DictType | DictType[], locale: string = 'zh') {
  const data = useRequest(async () => {
    const arr = Array.isArray(type) ? type : [type];
    const fetch = arr.map(async (item) => {
      if (cache[locale]?.[item] && cache[locale]?.[item]?.length) {
        return Promise.resolve(cache[locale][item]);
      }
      const res = await dictConfig._config[item](locale);
      cache[locale][item] = res;
      return res;
    });
    const result = await Promise.allSettled(fetch);
    return result.map((item) => {
      if (item.status === 'fulfilled') {
        return item.value;
      }
      return [];
    });
  });
  const fb = useRef([]);
  return (data.data ?? fb.current) as ReturenValue[][];
}
