// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 关闭支付单(除对公转账之外) PUT /salesgateway/cashier/api/v2/closePayment/${param0} */
export async function OperationId_salesgateway_CashierV2Controller_closePayment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierV2ControllerClosePaymentParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/cashier/api/v2/closePayment/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_closePayment',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 对公转账关闭打款(只适用于对公转账) PUT /salesgateway/cashier/api/v2/closeTransferPayment/${param0} */
export async function OperationId_salesgateway_CashierV2Controller_closeTransferPayment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierV2ControllerCloseTransferPaymentParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/cashier/api/v2/closeTransferPayment/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_closeTransferPayment',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 卖家-收款确认-确认已收款按钮 POST /salesgateway/cashier/api/v2/confirmCollection */
export async function OperationId_salesgateway_CashierV2Controller_confirmCollection(
  body: API.UpdatePaymentOrderStatusReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/v2/confirmCollection', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_confirmCollection',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取流水币种 POST /salesgateway/cashier/api/v2/flowCurrency */
export async function OperationId_salesgateway_CashierV2Controller_getFlowCurrency(
  body: API.FlowCurrencyReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<
    | 'CURRENCY_TYPE_UNKNOWN'
    | 'CURRENCY_TYPE_RMB'
    | 'CURRENCY_TYPE_DOLLAR'
    | 'CURRENCY_TYPE_YEN'
    | 'CURRENCY_TYPE_EURO'
    | 'CURRENCY_TYPE_GBP'
    | 'CURRENCY_TYPE_RUB'
  >('/salesgateway/cashier/api/v2/flowCurrency', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_getFlowCurrency',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 收银台详情 GET /salesgateway/cashier/api/v2/getDetail */
export async function OperationId_salesgateway_CashierV2Controller_getCashierDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierV2ControllerGetCashierDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetCombineCashierDetailResponseVo>('/salesgateway/cashier/api/v2/getDetail', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_getCashierDetail',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 买家端-付款确认填写 POST /salesgateway/cashier/api/v2/payConfirm */
export async function OperationId_salesgateway_CashierV2Controller_payConfirm(
  body: API.TransferPayReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/v2/payConfirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_payConfirm',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家端-付款确认填写前信息获取 GET /salesgateway/cashier/api/v2/payConfirmDetail/${param0} */
export async function OperationId_salesgateway_CashierV2Controller_payConfirmDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierV2ControllerPayConfirmDetailParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.CombinePayConfirmDetail>(
    `/salesgateway/cashier/api/v2/payConfirmDetail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_CashierV2Controller_payConfirmDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 买家端-付款确认填写前信息获取(订单列表上传凭证使用) GET /salesgateway/cashier/api/v2/payConfirmDetailByDetailId/${param0} */
export async function OperationId_salesgateway_CashierV2Controller_payConfirmDetailByDetailId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierV2ControllerPayConfirmDetailByDetailIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.CombinePayConfirmDetail>(
    `/salesgateway/cashier/api/v2/payConfirmDetailByDetailId/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_CashierV2Controller_payConfirmDetailByDetailId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 支付验证码 GET /salesgateway/cashier/api/v2/payGenerateCode */
export async function OperationId_salesgateway_CashierV2Controller_payGenerateCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayCashierV2ControllerPayGenerateCodeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/v2/payGenerateCode', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_payGenerateCode',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 构建支付规则 POST /salesgateway/cashier/api/v2/rules */
export async function OperationId_salesgateway_CashierV2Controller_buildRules(
  body: API.BuildSendRulesReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BuildSendRulesResponseVO>('/salesgateway/cashier/api/v2/rules', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_buildRules',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 支付验证码校验 POST /salesgateway/cashier/api/v2/verifyCode */
export async function OperationId_salesgateway_CashierV2Controller_verifyCode(
  body: API.CombinePayReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/cashier/api/v2/verifyCode', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CashierV2Controller_verifyCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
