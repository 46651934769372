// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询商品分类 GET /salesgateway/category/api/getCategory */
export async function OperationId_salesgateway_CategoryController_getCategory(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.BaseIdWithStringValueVO[]>('/salesgateway/category/api/getCategory', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_CategoryController_getCategory',
    },
    ...(rest || {}),
  });
}
