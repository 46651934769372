// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取企业营业执照OCR结果 AttachmentController.ocrBusinessLicense GET /salesgateway/attachments/ocr/business */
export async function OperationId_salesgateway_AttachmentController_ocrBusinessLicense(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAttachmentControllerOcrBusinessLicenseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OcrBusinessLicenseResponse>('/salesgateway/attachments/ocr/business', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AttachmentController_ocrBusinessLicense',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取身份证照OCR结果 AttachmentController.ocrIdCard GET /salesgateway/attachments/ocr/id-card */
export async function OperationId_salesgateway_AttachmentController_ocrIdCard(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAttachmentControllerOcrIdCardParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OcrIdCardResponse>('/salesgateway/attachments/ocr/id-card', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AttachmentController_ocrIdCard',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取私有下载地址 AttachmentController.getPrivateDownloadSignedUrl GET /salesgateway/attachments/private/download/${param0}/sign */
export async function OperationId_salesgateway_AttachmentController_getPrivateDownloadSignedUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAttachmentControllerGetPrivateDownloadSignedUrlParams,
  options?: { [key: string]: any },
) {
  const { name: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>(`/salesgateway/attachments/private/download/${param0}/sign`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AttachmentController_getPrivateDownloadSignedUrl',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 获取私有Sts凭证 AttachmentController.getPrivateStsToken GET /salesgateway/attachments/private/sts */
export async function OperationId_salesgateway_AttachmentController_getPrivateStsToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAttachmentControllerGetPrivateStsTokenParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StsResponseVo>('/salesgateway/attachments/private/sts', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AttachmentController_getPrivateStsToken',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取私有上传地址 AttachmentController.getPrivateUploadSignedUrl GET /salesgateway/attachments/private/upload/sign */
export async function OperationId_salesgateway_AttachmentController_getPrivateUploadSignedUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAttachmentControllerGetPrivateUploadSignedUrlParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>('/salesgateway/attachments/private/upload/sign', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AttachmentController_getPrivateUploadSignedUrl',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取公有Sts凭证 AttachmentController.getPublicStsToken GET /salesgateway/attachments/public/sts */
export async function OperationId_salesgateway_AttachmentController_getPublicStsToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAttachmentControllerGetPublicStsTokenParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StsResponseVo>('/salesgateway/attachments/public/sts', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AttachmentController_getPublicStsToken',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取公有上传地址 AttachmentController.getPublicUploadUrl GET /salesgateway/attachments/public/upload/sign */
export async function OperationId_salesgateway_AttachmentController_getPublicUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAttachmentControllerGetPublicUploadUrlParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>('/salesgateway/attachments/public/upload/sign', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AttachmentController_getPublicUploadUrl',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
