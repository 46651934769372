// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询线路详情 GET /sinotransbooking/v1/seller/line/get-line */
export async function OperationId_sinotransbooking_SellerLineController_getLine(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerLineControllerGetLineParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetLineResponse>('/sinotransbooking/v1/seller/line/get-line', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerLineController_getLine',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询线路列表 GET /sinotransbooking/v1/seller/line/get-line-list */
export async function OperationId_sinotransbooking_SellerLineController_getLineList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerLineControllerGetLineListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.LineListResponse>('/sinotransbooking/v1/seller/line/get-line-list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerLineController_getLineList',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 初始化线路 POST /sinotransbooking/v1/seller/line/init-line */
export async function OperationId_sinotransbooking_SellerLineController_initLine(
  body: API.InitLineRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/seller/line/init-line', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerLineController_initLine',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 更新线路 PUT /sinotransbooking/v1/seller/line/update-line */
export async function OperationId_sinotransbooking_SellerLineController_updateLine(
  body: API.UpdateLineRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/seller/line/update-line', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerLineController_updateLine',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
