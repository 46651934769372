import { DatePicker } from '../date-picker';
import type { DatePickerProps } from '../date-picker';
import Dayjs from 'dayjs';
import type { Dayjs as DayjsType } from 'dayjs';
import { useMemoizedFn } from 'ahooks';
import { useMemo } from 'react';
import { isArray } from 'lodash';

const getFormatValue = (value, format) => value;

const useDatePropsControl = (props, isRange?) => {
  const { value, onChange, ...rest } = props;

  const handeChange = useMemoizedFn((value) => {
    let newValue = value;

    if (rest.format) {
      if (isRange) {
        newValue = value?.map((i) => getFormatValue(i, rest.format));
      } else {
        newValue = getFormatValue(value, rest.format);
      }
    }

    onChange?.(newValue);
  });

  const memoValue = useMemo(() => {
    if (isRange && isArray(value)) {
      return value.map((v) => Dayjs(v));
    } else if (typeof value === 'string') {
      return Dayjs(value);
    }
    return value;
  }, [value]);

  return { ...rest, value: memoValue, onChange: handeChange };
};

export interface ProDatePickerProps extends Omit<DatePickerProps, 'onChange' | 'value'> {
  onChange?: (value?: string) => void;
  value?: string | DayjsType;
}

function ProDatePicker(props: ProDatePickerProps) {
  const controlProps = useDatePropsControl(props);
  return <DatePicker {...controlProps} />;
}

function ProRangePicker(props: ProDatePickerProps) {
  const controlProps = useDatePropsControl(props, true);
  return <DatePicker.RangePicker {...controlProps} />;
}

ProDatePicker.ProRangePicker = ProRangePicker;

export { ProDatePicker };
