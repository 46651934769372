// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 买家-租箱 租箱 GET /salesgateway/workbench-buyer/container-summary */
export async function OperationId_salesgateway_BuyerWorkbenchController_getBuyerContainerSummary(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.BuyerContainerSummerResponseVO>(
    '/salesgateway/workbench-buyer/container-summary',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BuyerWorkbenchController_getBuyerContainerSummary',
      },
      ...(rest || {}),
    },
  );
}

/** 买家优惠券汇总 GET /salesgateway/workbench-buyer/coupon-count */
export async function OperationId_salesgateway_BuyerWorkbenchController_couponCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.BuyerCouponCountResponseVo>('/salesgateway/workbench-buyer/coupon-count', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BuyerWorkbenchController_couponCount',
    },
    ...(rest || {}),
  });
}

/** 买家优惠券中心-查询接口 POST /salesgateway/workbench-buyer/get-coupon */
export async function OperationId_salesgateway_BuyerWorkbenchController_getCoupon(
  body: API.PageCouponsReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PageCouponsResVO>('/salesgateway/workbench-buyer/get-coupon', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BuyerWorkbenchController_getCoupon',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家工作台获取海运任务信息 GET /salesgateway/workbench-buyer/shipping-task */
export async function OperationId_salesgateway_BuyerWorkbenchController_getShippingTask(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.ShippingBuyerResponseVo>('/salesgateway/workbench-buyer/shipping-task', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BuyerWorkbenchController_getShippingTask',
    },
    ...(rest || {}),
  });
}
