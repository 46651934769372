import { i18n } from '@easygo/i18n';
import { PureComponent } from 'react';
import type { ReactNode } from 'react';
import { Empty, Image, Button } from 'antd';
import { withRouter } from 'next/router';

export class ErrorBound extends PureComponent<{ children: ReactNode }> {
  state: { hasError: boolean } = { hasError: false };
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // 1、错误信息（error）
    // 2、错误堆栈（errorInfo)
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 308px)',
          }}
        >
          <Empty
            imageStyle={{ marginBottom: 24 }}
            image={<Image src="/images/status/order-faild.svg" alt="" preview={false} />}
            description={i18n?.t('客户端发生未知错误')}
          >
            <Button
              type="primary"
              onClick={() => {
                history.back();
                setTimeout(() => {
                  location.reload();
                }, 0);
              }}
            >
              {i18n?.t('返回')}
            </Button>
          </Empty>
        </div>
      );
    }
    return this.props.children;
  }
}
