// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询租箱订单列表 POST /salesgateway/seller/container-leasing/orders */
export async function OperationId_salesgateway_BizOrderController_list(
  body: API.ListContainerLeasingOrdersVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerLeasingOrdersVo>('/salesgateway/seller/container-leasing/orders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizOrderController_list',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询订单详情 GET /salesgateway/seller/container-leasing/orders/${param0} */
export async function OperationId_salesgateway_BizOrderController_detail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerDetailParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OrderWithPerformanceVo>(
    `/salesgateway/seller/container-leasing/orders/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizOrderController_detail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 订单取消 DELETE /salesgateway/seller/container-leasing/orders/${param0} */
export async function OperationId_salesgateway_BizOrderController_cancelOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerCancelOrderParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/seller/container-leasing/orders/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizOrderController_cancelOrder',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询箱子记录列表 GET /salesgateway/seller/container-leasing/orders/${param0}/containers */
export async function OperationId_salesgateway_BizOrderController_listContainers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerListContainersParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ListContainerResponse>(
    `/salesgateway/seller/container-leasing/orders/${param0}/containers`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizOrderController_listContainers',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询订单还箱记录列表 GET /salesgateway/seller/container-leasing/orders/${param0}/drop-off */
export async function OperationId_salesgateway_BizOrderController_listDropOffApplications(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerListDropOffApplicationsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ListDropOffApplicationResponse>(
    `/salesgateway/seller/container-leasing/orders/${param0}/drop-off`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizOrderController_listDropOffApplications',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询订单提箱记录列表 GET /salesgateway/seller/container-leasing/orders/${param0}/pickup */
export async function OperationId_salesgateway_BizOrderController_listPickupApplications(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerListPickupApplicationsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ListPickupApplicationResponse>(
    `/salesgateway/seller/container-leasing/orders/${param0}/pickup`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizOrderController_listPickupApplications',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 申请提箱 POST /salesgateway/seller/container-leasing/orders/${param0}/pickup-applications */
export async function OperationId_salesgateway_BizOrderController_addPickupApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerAddPickupApplicationParams,
  body: API.AddPickupApplicationVo,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/seller/container-leasing/orders/${param0}/pickup-applications`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizOrderController_addPickupApplication',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取结算金额列表 GET /salesgateway/seller/container-leasing/orders/${param0}/settlement */
export async function OperationId_salesgateway_BizOrderController_listSettlement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerListSettlementParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SettlementVo[]>(
    `/salesgateway/seller/container-leasing/orders/${param0}/settlement`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizOrderController_listSettlement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询卖家端待还箱号 GET /salesgateway/seller/container-leasing/orders/dropoff/wait-return/container/list */
export async function OperationId_salesgateway_BizOrderController_listWaitReturnContainerForDropOff(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerListWaitReturnContainerForDropOffParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SimpleContainerVO[]>(
    '/salesgateway/seller/container-leasing/orders/dropoff/wait-return/container/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_BizOrderController_listWaitReturnContainerForDropOff',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}

/** 消息处理 PUT /salesgateway/seller/container-leasing/orders/message/${param0} */
export async function OperationId_salesgateway_BizOrderController_readMessage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerReadMessageParams,
  options?: { [key: string]: any },
) {
  const { messageId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/seller/container-leasing/orders/message/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizOrderController_readMessage',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取订单查询过滤器 GET /salesgateway/seller/container-leasing/orders/order-filters */
export async function OperationId_salesgateway_BizOrderController_listOrderFilters(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.OrderFilterVo[]>(
    '/salesgateway/seller/container-leasing/orders/order-filters',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizOrderController_listOrderFilters',
      },
      ...(rest || {}),
    },
  );
}

/** 查询订单可申请提箱数量v2 GET /salesgateway/seller/container-leasing/orders/orders/${param0}/pickup-available/v2 */
export async function OperationId_salesgateway_BizOrderController_queryAvailableAmountForPickupCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerQueryAvailableAmountForPickupCityParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<number>(
    `/salesgateway/seller/container-leasing/orders/orders/${param0}/pickup-available/v2`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_BizOrderController_queryAvailableAmountForPickupCity',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 订单修改 PUT /salesgateway/seller/container-leasing/orders/single-price/${param0} */
export async function OperationId_salesgateway_BizOrderController_modifyOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizOrderControllerModifyOrderParams,
  body: API.ModifyOrderSinglePriceVo,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/seller/container-leasing/orders/single-price/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizOrderController_modifyOrder',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 订单列表统计信息 GET /salesgateway/seller/container-leasing/orders/summary */
export async function OperationId_salesgateway_BizOrderController_listSummary(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.OrderSummaryVo>('/salesgateway/seller/container-leasing/orders/summary', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizOrderController_listSummary',
    },
    ...(rest || {}),
  });
}
