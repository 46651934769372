// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 订单确认 POST /salesgateway/v1/shipping/seller/confirm */
export async function OperationId_salesgateway_ShippingSellerController_orderConfirm(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingSellerControllerOrderConfirmParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/seller/confirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_orderConfirm',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 确认舱位 POST /salesgateway/v1/shipping/seller/confirmSO */
export async function OperationId_salesgateway_ShippingSellerController_confirmSO(
  body: API.ConfirmPerformanceSOReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/seller/confirmSO', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_confirmSO',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 改价接口 POST /salesgateway/v1/shipping/seller/editOrderPrice */
export async function OperationId_salesgateway_ShippingSellerController_editOrderPrice(
  body: API.ModifyPriceReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/seller/editOrderPrice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_editOrderPrice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取订单关联买家信息 POST /salesgateway/v1/shipping/seller/getBuyers */
export async function OperationId_salesgateway_ShippingSellerController_getBuyers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingSellerControllerGetBuyersParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BaseInfoShipVo[]>('/salesgateway/v1/shipping/seller/getBuyers', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_getBuyers',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 制作账单 POST /salesgateway/v1/shipping/seller/makeBill */
export async function OperationId_salesgateway_ShippingSellerController_makeBill(
  body: API.PerformanceBillReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/seller/makeBill', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_makeBill',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 拒绝接单 DELETE /salesgateway/v1/shipping/seller/reject */
export async function OperationId_salesgateway_ShippingSellerController_rejectedOrder(
  body: API.RejectedOrderReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/seller/reject', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_rejectedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 上传提单 POST /salesgateway/v1/shipping/seller/uploadBL */
export async function OperationId_salesgateway_ShippingSellerController_uploadBL(
  body: API.PerformanceBLInfoReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/seller/uploadBL', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_uploadBL',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 上传提单样本 POST /salesgateway/v1/shipping/seller/uploadBLTemp */
export async function OperationId_salesgateway_ShippingSellerController_uploadBLTemp(
  body: API.PerformanceBLInfoReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/seller/uploadBLTemp', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingSellerController_uploadBLTemp',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
