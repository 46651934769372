// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 应付账单列表-主账单列表 POST /sinotransbooking/v1/operation/purchase/statement */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_pageStatement(
  body: API.StatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStatementItemVO>('/sinotransbooking/v1/operation/purchase/statement', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationPurchaseStatementController_pageStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 应付账单列表-查看发票 POST /sinotransbooking/v1/operation/purchase/statement/${param0}/invoice */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_invoiceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerInvoiceDetailParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.InvoiceVO>(
    `/sinotransbooking/v1/operation/purchase/statement/${param0}/invoice`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_invoiceDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询账单支付方案 GET /sinotransbooking/v1/operation/purchase/statement/${param0}/plan */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_queryStatementPayPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerQueryStatementPayPlanParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementPayPlanVO[]>(
    `/sinotransbooking/v1/operation/purchase/statement/${param0}/plan`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_queryStatementPayPlan',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 更新账单支付方案 PUT /sinotransbooking/v1/operation/purchase/statement/${param0}/plan */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_updateStatementPayPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerUpdateStatementPayPlanParams,
  body: API.StatementPayPlanRequest[],
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/sinotransbooking/v1/operation/purchase/statement/${param0}/plan`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationPurchaseStatementController_updateStatementPayPlan',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 应付账单列表-子账单列表 GET /sinotransbooking/v1/operation/purchase/statement/${param0}/sub */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_subStatementList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerSubStatementListParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SubStatementItemVO[]>(
    `/sinotransbooking/v1/operation/purchase/statement/${param0}/sub`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_subStatementList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 应付账单列表-确认付款 POST /sinotransbooking/v1/operation/purchase/statement/confirmPayment */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_ConfirmPayment(
  body: API.ConfirmPaymentRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/operation/purchase/statement/confirmPayment', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationPurchaseStatementController_ConfirmPayment',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 下载采购海运费用确认单 GET /sinotransbooking/v1/operation/purchase/statement/details/${param0}/pdf */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_downloadBillInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerDownloadBillInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(
    `/sinotransbooking/v1/operation/purchase/statement/details/${param0}/pdf`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_downloadBillInfo',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 根据企业id获取银行卡信息 GET /sinotransbooking/v1/operation/purchase/statement/queryBankCardInfo/${param0} */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_queryBankCardInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerQueryBankCardInfoParams,
  options?: { [key: string]: any },
) {
  const { enterpriseId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MembersDetailResponseVO>(
    `/sinotransbooking/v1/operation/purchase/statement/queryBankCardInfo/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_queryBankCardInfo',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 运营平台-根据履约单查询履约费用信息 POST /sinotransbooking/v1/operation/purchase/statement/queryPerformanceFeeByPerformanceId/${param0} */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_queryPerformanceFeeByPerformanceId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerQueryPerformanceFeeByPerformanceIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceFeeVO>(
    `/sinotransbooking/v1/operation/purchase/statement/queryPerformanceFeeByPerformanceId/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_queryPerformanceFeeByPerformanceId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 应付账单-根据账单查询账单费用列表 POST /sinotransbooking/v1/operation/purchase/statement/queryStatementFeeList */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_queryStatementFeeListByOrderId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerQueryStatementFeeListByOrderIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StatementFeeItemVO[]>(
    '/sinotransbooking/v1/operation/purchase/statement/queryStatementFeeList',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_queryStatementFeeListByOrderId',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 应付账单列表-应付金额 POST /sinotransbooking/v1/operation/purchase/statement/statementPayment/${param0} */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_statementPaymentAmount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPurchaseStatementControllerStatementPaymentAmountParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementPaymentAmountVO>(
    `/sinotransbooking/v1/operation/purchase/statement/statementPayment/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPurchaseStatementController_statementPaymentAmount',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 应付账单列表-上传修改发票 POST /sinotransbooking/v1/operation/purchase/statement/upload-invoice */
export async function OperationId_sinotransbooking_OperationPurchaseStatementController_uploadInvoice(
  body: API.UploadInvoiceRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/operation/purchase/statement/upload-invoice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationPurchaseStatementController_uploadInvoice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
