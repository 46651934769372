// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 消息中心 修改消息状态 PUT /salesgateway/message/confirmMessage */
export async function OperationId_salesgateway_MessageController_confirmMessage(
  body: API.MessageConfirmReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/message/confirmMessage', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MessageController_confirmMessage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 消息中心 消息中心列表 POST /salesgateway/message/queryMessage */
export async function OperationId_salesgateway_MessageController_queryMessage(
  body: API.MessageListReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.MessageResponseVO>('/salesgateway/message/queryMessage', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MessageController_queryMessage',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 未处理消息统计 统计各类型未处理的消息数量 GET /salesgateway/message/unprocessed/count */
export async function OperationId_salesgateway_MessageController_unprocessedCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.UnprocessedMessageCountResponseVo>('/salesgateway/message/unprocessed/count', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_MessageController_unprocessedCount',
    },
    ...(rest || {}),
  });
}
