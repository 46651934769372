// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 详情 GET /invoice/v1/buyer/invoice/getDetails/${param0} */
export async function OperationId_businessinvoice_BuyerInvoiceController_getDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinessinvoiceBuyerInvoiceControllerGetDetailsParams,
  options?: { [key: string]: any },
) {
  const { invoiceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetDetailsResponse>(`/invoice/v1/buyer/invoice/getDetails/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_BuyerInvoiceController_getDetails',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 列表 GET /invoice/v1/buyer/invoice/page-invoice */
export async function OperationId_businessinvoice_BuyerInvoiceController_pageInvoice(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinessinvoiceBuyerInvoiceControllerPageInvoiceParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BuyerPageInvoiceResponse>('/invoice/v1/buyer/invoice/page-invoice', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_BuyerInvoiceController_pageInvoice',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}
