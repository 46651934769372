import { CurrencyLetterMap, CurrencySymbolMap } from '../enums/product';
import { CurrencyMap } from '../enums';
import { CoinType, CoinTypeCode, CoinTypeSymbol } from '../enums/currency';
import currency from 'currency.js';
import BigNumber from 'bignumber.js';

/** 价格 */
type Price = {
  /** 价格 */
  price: number;
  /** 币种 */
  currency?: 'SKU_COIN_TYPE_UNKNOWN' | 'SKU_COIN_TYPE_RMB' | 'SKU_COIN_TYPE_DOLLAR' | 'SKU_COIN_TYPE_YEN' | 'SKU_COIN_TYPE_EURO' | 'UNRECOGNIZED';
  /** 汇率 */
  rate?: number;
};

/**
 * 格式化价格
 * eg: {currency: 'xx', price: 5} → $ 5
 * eg: {currency: 'xx', price: 5} → USD 5
 * @param price 价格
 * @param type 显示符号：symbol - 符号 ｜ letter - 币种 ｜ none - 不显示
 * @param options { ceil: boolean - 是否向上取整 }
 * @returns
 */
export const resolveCurrency = (price?: Price, type: 'symbol' | 'letter' | 'none' = 'symbol', options?: { ceil?: boolean; precision?: number }) => {
  const map = {
    symbol: CurrencySymbolMap,
    letter: CurrencyLetterMap,
    none: {} as any,
  }[type];
  let value = price?.price;
  if (options?.ceil && value) {
    value = Math.ceil(+currency(value, { precision: 4 }).multiply(100).toString()) / 100;
  }
  return price
    ? currency(value!, {
        precision: options?.precision ?? 2,
        symbol: `${map[price.currency!] ? `${map[price.currency!]}` : ''}`,
      }).format()
    : '';
};

/**
 * 金额为外币时，显示折合人民币数额
 * @param amount { price: number; currency?: enums; rate?: number }
 * @returns
 */

export const getInitialMoney = (amount: Price, type?: 'floor' | 'ceil') => {
  if (amount) {
    const { currency, rate } = amount;
    if (currency !== CurrencyMap.SKU_COIN_TYPE_RMB && rate) {
      return `${resolveCurrency(amount)}(${resolveCurrency({
        price:
          type && type === 'ceil'
            ? BigNumber(amount.price).times(rate).decimalPlaces(2, BigNumber.ROUND_CEIL).toNumber()
            : BigNumber(amount.price).times(rate).decimalPlaces(2, BigNumber.ROUND_FLOOR).toNumber(),
        currency: 'SKU_COIN_TYPE_RMB',
        rate: 1,
      })})`;
    } else {
      return resolveCurrency(amount);
    }
  } else {
    return '-';
  }
};

/**
 * @method 数值向上取多为小数
 * @param value 数值
 * @param digit 小数位
 * @returns
 */
export const ceil = (value: number, digit: number = 2) => {
  return BigNumber(value).toFixed(digit, BigNumber.ROUND_CEIL);
};

export const thousandSymbol = (num: string | number) => num.toString().replace(/\B(?=(\d{3})+$)/g, ',');

/**
 * @method 金额数组转字符串
 * @param chargeList 金额数组
 * @returns str
 */
export function resolveAmountsToStr(chargeList: Price[]) {
  const allCharge =
    chargeList?.reduce((all, charge) => {
      if (+charge.price! >= 0 && charge.currency) {
        const currency = charge.currency;
        all[currency] = +(all[currency] || 0) + +(charge.price as number);
      }
      return all;
    }, {} as Record<NonNullable<Price['currency']>, number>) || {};
  const allStr = Object.entries(allCharge)
    .map(([currency, price]) => resolveCurrency({ price: price as number, currency: currency! as any }))
    .join(' + ');
  return allStr;
}
//数字添加千分位
export function moneyRegex(number: number | string, fix?: number) {
  if (fix) {
    //当传入保留小数位数时，先讲number保留相应相应小数，再添加千分位
    number = number.toFixed(fix);
  }
  return !(number + '').includes('.')
    ? (number + '').replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
        return match + ',';
      })
    : (number + '').replace(/\d{1,3}(?=(\d{3})+(\.))/g, (match) => {
        return match + ',';
      });
}

export const formatPrice = (value: number | string | undefined, decimalPlaces = 2, roundingMode?: BigNumber.RoundingMode) => {
  const bigValue = BigNumber(value!);
  return bigValue.isNaN() ? undefined : bigValue.toFormat(decimalPlaces, roundingMode);
};

export const formatPriceWithPrefix = (price: number | string | undefined, prefix: string = '') => {
  const str = formatPrice(price);
  return str ? `${prefix}${str}` : undefined;
};

const CoinTypeStrMaps = {
  code: CoinTypeCode,
  symbol: CoinTypeSymbol,
};

type CoinTypePrice = {
  /** 价格 */
  amount: BigNumber.Value;
  /** 币种 */
  coin?: CoinType;
  /** 汇率 */
  rate?: BigNumber.Value;
};

const coinTypePriceFormat = (
  price?: CoinTypePrice,
  options?: {
    type?: keyof typeof CoinTypeStrMaps;
    decimalPlaces?: number;
    roundingMode?: BigNumber.RoundingMode;
  }
) => {
  const bigValue = BigNumber(price?.amount!);

  if (bigValue.isNaN()) {
    return undefined;
  }

  const fixStr = CoinTypeStrMaps[options?.type!]?.[price?.coin!] ?? '';
  const priceStr = bigValue.toFormat(options?.decimalPlaces ?? 2, options?.roundingMode);

  return `${fixStr}${priceStr}`;
};

export const coinTypePriceRender = (
  price?: CoinTypePrice,
  options?: {
    type?: keyof typeof CoinTypeStrMaps;
    roundingMode?: BigNumber.RoundingMode;
  }
) => {
  const fixOptions = { ...options, type: options?.type ?? 'symbol' };
  const priceFormatStr = coinTypePriceFormat(price, fixOptions);

  if (priceFormatStr) {
    const bigRate = BigNumber(price?.rate!);
    if (price?.coin !== CoinType.RMB && !bigRate.isNaN() && !bigRate.isZero()) {
      const ratePriceFormatStr = coinTypePriceFormat({ amount: bigRate.times(price?.amount!), coin: CoinType.RMB }, fixOptions);
      return `${priceFormatStr}(${ratePriceFormatStr})`;
    }
    return priceFormatStr;
  }

  return '-';
};
