import dayjs, { type Dayjs } from 'dayjs';
import type { RailwayCommodity } from '../../../service/src/models/railway-fd';

/**
 * 年月日 时分秒
 */
export const DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
/**
 * 年月日 时分
 */
export const SHORT_DATE_TIME = 'YYYY-MM-DD HH:mm';
/**
 * 年月日
 */
export const DATE = 'YYYY-MM-DD';

/**
 * 简单的格式化日期字符串到本地格式
 * @param dateString 日期字符串或者时间戳
 * @param notTime 是否显示时分
 * @returns
 */
/** 简单的格式化日期字符串到本地格式 */
export const toLocalDate = (dateString?: string | number, notTime?: boolean) => {
  if (!dateString) return;
  const oDate = dayjs(dateString);
  return !notTime ? oDate.format('YYYY-MM-DD HH:mm') : oDate.format('YYYY-MM-DD');
};

/**
 * 格式化时间字符串
 * @param dateTimeStr time 字符串 参考 dayjs 创建入参
 * @param format 格式化配置，默认 YYYY-MM-DD HH:mm
 * @returns
 */
export function formatIsoDatetimeStr(dateTimeStr: string, format: string = 'YYYY-MM-DD HH:mm') {
  return dayjs(dateTimeStr).format(format);
}

/**
 * 返回一个时间格式化函数，入参：dayjs 创建入参，获得一个基于 formatCreater 格式过滤的时间格式
 * @param format 格式化配置，默认 YYYY-MM-DD HH:mm:ss
 * @returns 返回格式化函数
 */
export function formatCreater(format = 'YYYY-MM-DD HH:mm:ss') {
  return (dateTimeStr?: string | null, defaultStr?: string | null) => {
    if (!dateTimeStr) {
      return defaultStr || '';
    }
    return dayjs(dateTimeStr).format(format);
  };
}

/**
 * 转换时间为iso时间
 * @param date dayjs时间
 * @returns iso时间
 */
export const transformDateToISO = (date: string | Dayjs) => {
  if (!date) return;
  return (date instanceof Object ? date : dayjs(date)).toISOString();
};

/**
 * 获得一个时间格式化函数，格式：YYYY-MM-DD HH:mm:ss
 * eg：
 *    formatDateTime('2023-02-11 15:34:22')
 * returns
 *    2023-02-11 15:34:22
 */
export const formatDateTime = formatCreater();

export const formatDate = formatCreater('YYYY-MM-DD');

/**
 * 获得一个时间格式化函数，格式：MM-DD
 * eg：
 *    formatDateTime('2023-02-11 15:34:22')
 * returns
 *    02-11
 */
export const formatShortDate = formatCreater('MM-DD');

/**
 * 获得一个时间格式化函数，格式：YYYY-MM-DD HH:mm
 * eg：
 *    formatShortDateTime('2023-02-11 15:34:22')
 * returns
 *    2023-02-11 15:34
 */
export const formatShortDateTime = formatCreater('YYYY-MM-DD HH:mm');

/**
 * 该方法为别处移植方法，拆文件暂时放这里，后期再来废弃
 * @param commodity
 * @returns
 */
export function getStartTimeStr(commodity: RailwayCommodity) {
  const startTime = commodity.spu.departureStartTime?.propertyValue;
  const endTime = commodity.spu.departureEndTime?.propertyValue;
  if (!startTime && !endTime) {
    return '-';
  }

  if (startTime && endTime && dayjs(startTime).isSame(endTime, 'd')) {
    return dayjs(startTime).format('MM-DD');
  }

  return `${startTime ? dayjs(startTime).format('MM/DD') : ''}-${endTime ? dayjs(endTime).format('MM/DD') : ''}`;
}

/**
 * @method 可选择时间大于当前时间
 * @param current
 * @returns
 */
export function beforeNow(current: any, timeFormat: string = 'YYYY-MM-DD HH:mm:ss') {
  const currFmt = current.format(timeFormat);
  const localNow = dayjs(Date.now()).format(timeFormat);
  return currFmt < localNow;
}

/**
 * @method 时间范围格式化往后台提交 开始日期补00:00:00 结束日期补23:59:59
 * @param start, end
 * @returns [formatStart, formatEnd]
 */
export function resolveRangeDate(start?: dayjs.Dayjs, end?: dayjs.Dayjs) {
  const format = 'YYYY-MM-DD';
  const startPlanForwardTime = start ? dayjs(`${start.format(format)} 00:00:00`).toISOString() : start;
  const endPlanForwardTime = end ? dayjs(dayjs(`${end.format(format)} 23:59:59`).valueOf() + 999).toISOString() : end;
  return [startPlanForwardTime, endPlanForwardTime];
}

export function dateToWeek(date: string) {
  return date ? ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][dayjs(date).day()] : date;
}
