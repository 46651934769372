// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询铁路商品 GET /salesgateway/v1/railway/seller/items */
export async function OperationId_salesgateway_RailwaySellerProductController_pageItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerProductControllerPageItemParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageItemVO>('/salesgateway/v1/railway/seller/items', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_pageItem',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 铁路商品修改 PUT /salesgateway/v1/railway/seller/items */
export async function OperationId_salesgateway_RailwaySellerProductController_updateItem(
  body: API.ItemUpdateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/items', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_updateItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 铁路商品创建 POST /salesgateway/v1/railway/seller/items */
export async function OperationId_salesgateway_RailwaySellerProductController_createItem(
  body: API.ItemCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/v1/railway/seller/items', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_createItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取商品详情 GET /salesgateway/v1/railway/seller/items/${param0} */
export async function OperationId_salesgateway_RailwaySellerProductController_getItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerProductControllerGetItemParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ItemVO>(`/salesgateway/v1/railway/seller/items/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_getItem',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 上架/下架 PUT /salesgateway/v1/railway/seller/items/active-status/${param0}/${param1} */
export async function OperationId_salesgateway_RailwaySellerProductController_onOrOffSaleItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerProductControllerOnOrOffSaleItemParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, activeStatus: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/v1/railway/seller/items/active-status/${param0}/${param1}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwaySellerProductController_onOrOffSaleItem',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 审核商品 PUT /salesgateway/v1/railway/seller/items/audit-status */
export async function OperationId_salesgateway_RailwaySellerProductController_auditItem(
  body: API.AuditRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/items/audit-status', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_auditItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 更新库存 PUT /salesgateway/v1/railway/seller/items/inventories */
export async function OperationId_salesgateway_RailwaySellerProductController_updateInventory(
  body: API.SkuInventoryUpdateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/seller/items/inventories', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_updateInventory',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看商品日志 GET /salesgateway/v1/railway/seller/items/item-logs */
export async function OperationId_salesgateway_RailwaySellerProductController_pageItemLog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerProductControllerPageItemLogParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageItemOperationLogDTO>('/salesgateway/v1/railway/seller/items/item-logs', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_pageItemLog',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 查看商品版本 GET /salesgateway/v1/railway/seller/items/item-versions */
export async function OperationId_salesgateway_RailwaySellerProductController_pageItemVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerProductControllerPageItemVersionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageItemVO>('/salesgateway/v1/railway/seller/items/item-versions', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_pageItemVersion',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 查看商品版本详情 GET /salesgateway/v1/railway/seller/items/item-versions/${param0} */
export async function OperationId_salesgateway_RailwaySellerProductController_getItemVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerProductControllerGetItemVersionParams,
  options?: { [key: string]: any },
) {
  const { versionId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ItemVO>(`/salesgateway/v1/railway/seller/items/item-versions/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_getItemVersion',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 启用/禁用 PUT /salesgateway/v1/railway/seller/items/status/${param0}/${param1} */
export async function OperationId_salesgateway_RailwaySellerProductController_enableItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwaySellerProductControllerEnableItemParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, status: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/seller/items/status/${param0}/${param1}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwaySellerProductController_enableItem',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}
