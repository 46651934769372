// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 租箱-订单修改记录 GET /salesgateway/container-leasing/log/order-modify/${param0} */
export async function OperationId_salesgateway_ContainerLogController_getOrderModifyLogs(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLogControllerGetOrderModifyLogsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetOrderModifyLogsResponse>(
    `/salesgateway/container-leasing/log/order-modify/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLogController_getOrderModifyLogs',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
