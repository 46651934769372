// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 附加费用详情 GET /salesgateway/buyer/surcharge/${param0} */
export async function OperationId_salesgateway_BuyerSurchargeController_getDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBuyerSurchargeControllerGetDetailParams,
  options?: { [key: string]: any },
) {
  const { additionalFeeId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AdditionalFeeVo>(`/salesgateway/buyer/surcharge/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BuyerSurchargeController_getDetail',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询附加费列表 POST /salesgateway/buyer/surcharge/get-list */
export async function OperationId_salesgateway_BuyerSurchargeController_getList(
  body: API.AdditionalFeeListRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AdditionalFeeListResponseVo>('/salesgateway/buyer/surcharge/get-list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BuyerSurchargeController_getList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
