// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取企业发票抬头列表 GET /salesgateway/enterprise/invoice-title */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_listEnterpriseInvoiceTitle(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseInvoiceTitleControllerListEnterpriseInvoiceTitleParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseInvoiceTitleVO[]>('/salesgateway/enterprise/invoice-title', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseInvoiceTitleController_listEnterpriseInvoiceTitle',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 编辑企业发票抬头 PUT /salesgateway/enterprise/invoice-title */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_updateEnterpriseInvoiceTitle(
  body: API.UpdateEnterpriseInvoiceTitleRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/enterprise/invoice-title', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseInvoiceTitleController_updateEnterpriseInvoiceTitle',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 新增企业发票抬头 POST /salesgateway/enterprise/invoice-title */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_createEnterpriseInvoiceTitle(
  body: API.CreateEnterpriseInvoiceTitleRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/enterprise/invoice-title', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseInvoiceTitleController_createEnterpriseInvoiceTitle',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 删除发票抬头 DELETE /salesgateway/enterprise/invoice-title/${param0} */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_deleteInvoiceTitle(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseInvoiceTitleControllerDeleteInvoiceTitleParams,
  options?: { [key: string]: any },
) {
  const { invoiceTitleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/enterprise/invoice-title/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseInvoiceTitleController_deleteInvoiceTitle',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 取消默认发票抬头 PUT /salesgateway/enterprise/invoice-title/default/${param0}/cancel */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_cancelDefaultInvoiceTitle(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseInvoiceTitleControllerCancelDefaultInvoiceTitleParams,
  options?: { [key: string]: any },
) {
  const { invoiceTitleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/enterprise/invoice-title/default/${param0}/cancel`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseInvoiceTitleController_cancelDefaultInvoiceTitle',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 设置默认发票抬头 PUT /salesgateway/enterprise/invoice-title/default/${param0}/set */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_setDefaultInvoiceTitle(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseInvoiceTitleControllerSetDefaultInvoiceTitleParams,
  options?: { [key: string]: any },
) {
  const { invoiceTitleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/enterprise/invoice-title/default/${param0}/set`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseInvoiceTitleController_setDefaultInvoiceTitle',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取发票抬头详情 GET /salesgateway/enterprise/invoice-title/detail/${param0} */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_getInvoiceTitleDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseInvoiceTitleControllerGetInvoiceTitleDetailParams,
  options?: { [key: string]: any },
) {
  const { invoiceTitleId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseInvoiceTitleVO>(
    `/salesgateway/enterprise/invoice-title/detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseInvoiceTitleController_getInvoiceTitleDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取企业信息-用于发票抬头填充信息 GET /salesgateway/enterprise/invoice-title/enterprise-info/${param0} */
export async function OperationId_salesgateway_EnterpriseInvoiceTitleController_getInvoiceTitleEnterpriseInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseInvoiceTitleControllerGetInvoiceTitleEnterpriseInfoParams,
  options?: { [key: string]: any },
) {
  const { enterpriseId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.InvoiceTitleEnterpriseResponse>(
    `/salesgateway/enterprise/invoice-title/enterprise-info/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseInvoiceTitleController_getInvoiceTitleEnterpriseInfo',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
