// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 应收账单列表-主账单列表 POST /sinotransbooking/v1/operation/sales/statement */
export async function OperationId_sinotransbooking_OperationSalesStatementController_pageStatement(
  body: API.StatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStatementItemVO>('/sinotransbooking/v1/operation/sales/statement', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationSalesStatementController_pageStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 应收账单列表-查看发票 POST /sinotransbooking/v1/operation/sales/statement/${param0}/invoice */
export async function OperationId_sinotransbooking_OperationSalesStatementController_invoiceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerInvoiceDetailParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.InvoiceVO>(
    `/sinotransbooking/v1/operation/sales/statement/${param0}/invoice`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationSalesStatementController_invoiceDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询账单支付方案 GET /sinotransbooking/v1/operation/sales/statement/${param0}/plan */
export async function OperationId_sinotransbooking_OperationSalesStatementController_queryStatementPayPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerQueryStatementPayPlanParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementPayPlanVO[]>(
    `/sinotransbooking/v1/operation/sales/statement/${param0}/plan`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationSalesStatementController_queryStatementPayPlan',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 更新账单支付方案 PUT /sinotransbooking/v1/operation/sales/statement/${param0}/plan */
export async function OperationId_sinotransbooking_OperationSalesStatementController_updateStatementPayPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerUpdateStatementPayPlanParams,
  body: API.StatementPayPlanRequest[],
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/sinotransbooking/v1/operation/sales/statement/${param0}/plan`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationSalesStatementController_updateStatementPayPlan',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 应收账单列表-子账单列表 GET /sinotransbooking/v1/operation/sales/statement/${param0}/sub */
export async function OperationId_sinotransbooking_OperationSalesStatementController_subStatementList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerSubStatementListParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SubStatementItemVO[]>(
    `/sinotransbooking/v1/operation/sales/statement/${param0}/sub`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationSalesStatementController_subStatementList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 应收账单列表-确认收款 POST /sinotransbooking/v1/operation/sales/statement/confirmCollection */
export async function OperationId_sinotransbooking_OperationSalesStatementController_ConfirmCollection(
  body: API.ConfirmCollectionRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/operation/sales/statement/confirmCollection', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationSalesStatementController_ConfirmCollection',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 应收账单列表-确认收款检验 POST /sinotransbooking/v1/operation/sales/statement/confirmCollection/${param0}/check */
export async function OperationId_sinotransbooking_OperationSalesStatementController_confirmCollectionCheck(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerConfirmCollectionCheckParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(
    `/sinotransbooking/v1/operation/sales/statement/confirmCollection/${param0}/check`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationSalesStatementController_confirmCollectionCheck',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 下载销售海运费用确认单 GET /sinotransbooking/v1/operation/sales/statement/details/${param0}/pdf */
export async function OperationId_sinotransbooking_OperationSalesStatementController_downloadBillInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerDownloadBillInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(`/sinotransbooking/v1/operation/sales/statement/details/${param0}/pdf`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationSalesStatementController_downloadBillInfo',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 应收账单列表-根据订单号获取关联子账单详情 POST /sinotransbooking/v1/operation/sales/statement/orderStatementDetail/${param0} */
export async function OperationId_sinotransbooking_OperationSalesStatementController_orderWithStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerOrderWithStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OrderWithStatementVO>(
    `/sinotransbooking/v1/operation/sales/statement/orderStatementDetail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationSalesStatementController_orderWithStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 运营平台-根据子账单查账单信息 POST /sinotransbooking/v1/operation/sales/statement/querySubStatementInfoById/${param0} */
export async function OperationId_sinotransbooking_OperationSalesStatementController_querySubStatementInfoById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerQuerySubStatementInfoByIdParams,
  options?: { [key: string]: any },
) {
  const { subStatementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementInfoVO>(
    `/sinotransbooking/v1/operation/sales/statement/querySubStatementInfoById/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationSalesStatementController_querySubStatementInfoById',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 应收账单列表-应收金额 POST /sinotransbooking/v1/operation/sales/statement/statementCollection/${param0} */
export async function OperationId_sinotransbooking_OperationSalesStatementController_statementCollection(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSalesStatementControllerStatementCollectionParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementCollectionAmountVO>(
    `/sinotransbooking/v1/operation/sales/statement/statementCollection/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationSalesStatementController_statementCollection',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 应收账单列表-上传修改发票 POST /sinotransbooking/v1/operation/sales/statement/upload-invoice */
export async function OperationId_sinotransbooking_OperationSalesStatementController_uploadInvoice(
  body: API.UploadInvoiceRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/operation/sales/statement/upload-invoice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationSalesStatementController_uploadInvoice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
