// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 取消 PUT /sinotransbooking/v1/buyer/performance/sea/${param0}/cancel */
export async function OperationId_sinotransbooking_PerformanceSeaBuyerController_cancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaBuyerControllerCancelParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/buyer/performance/sea/${param0}/cancel`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaBuyerController_cancel',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 确认订单 PUT /sinotransbooking/v1/buyer/performance/sea/${param0}/confirm */
export async function OperationId_sinotransbooking_PerformanceSeaBuyerController_confirmOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceSeaBuyerControllerConfirmOrderParams,
  body: API.PerformanceConfirmRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/buyer/performance/sea/${param0}/confirm`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceSeaBuyerController_confirmOrder',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
