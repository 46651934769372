// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 模糊获取卖家公司列表_海运 GET /sinotransbooking/v1/operation/common/enterprise-options */
export async function OperationId_sinotransbooking_OperationCommonController_listEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationCommonControllerListEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcEnterpriseInfoDTO[]>(
    '/sinotransbooking/v1/operation/common/enterprise-options',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationCommonController_listEnterprise',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查询线路详情 GET /sinotransbooking/v1/operation/common/get-line */
export async function OperationId_sinotransbooking_OperationCommonController_getLine(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationCommonControllerGetLineParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetLineResponse>('/sinotransbooking/v1/operation/common/get-line', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationCommonController_getLine',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询线路列表 GET /sinotransbooking/v1/operation/common/get-line-list */
export async function OperationId_sinotransbooking_OperationCommonController_getLineList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationCommonControllerGetLineListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.LineListResponse>('/sinotransbooking/v1/operation/common/get-line-list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationCommonController_getLineList',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
