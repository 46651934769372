// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询核心线路 查询核心线路 GET /salesgateway/container-leasing/price-mark/core-line/list */
export async function OperationId_salesgateway_ContainerLeasingPriceMarkController_listCoreLine(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<string[]>('/salesgateway/container-leasing/price-mark/core-line/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingPriceMarkController_listCoreLine',
    },
    ...(rest || {}),
  });
}

/** 查询价格指数详情 查询价格指数详情 GET /salesgateway/container-leasing/price-mark/detail */
export async function OperationId_salesgateway_ContainerLeasingPriceMarkController_listPriceMarkDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingPriceMarkControllerListPriceMarkDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PriceMarkDetailVO[]>('/salesgateway/container-leasing/price-mark/detail', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerLeasingPriceMarkController_listPriceMarkDetail',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 是否开启全球价格指数 是否开启全球价格指数 GET /salesgateway/container-leasing/price-mark/enable */
export async function OperationId_salesgateway_ContainerLeasingPriceMarkController_enablePriceMark(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/container-leasing/price-mark/enable', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingPriceMarkController_enablePriceMark',
    },
    ...(rest || {}),
  });
}

/** 查询价格指数历史数据 查询价格指数历史数据 GET /salesgateway/container-leasing/price-mark/history/list */
export async function OperationId_salesgateway_ContainerLeasingPriceMarkController_listHistoryPriceMark(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingPriceMarkControllerListHistoryPriceMarkParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.HistoryPriceMarkVO[]>(
    '/salesgateway/container-leasing/price-mark/history/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingPriceMarkController_listHistoryPriceMark',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}

/** 商品不存在时查询价格指数历史数据 商品不存在时查询价格指数历史数据 GET /salesgateway/container-leasing/price-mark/not-exist-product/history/list */
export async function OperationId_salesgateway_ContainerLeasingPriceMarkController_listHistoryPriceMarkIfNotExitProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingPriceMarkControllerListHistoryPriceMarkIfNotExitProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.HistoryPriceMarkVO[]>(
    '/salesgateway/container-leasing/price-mark/not-exist-product/history/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingPriceMarkController_listHistoryPriceMarkIfNotExitProduct',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}

/** 租箱价格指数列表 租箱价格指数列表 GET /salesgateway/container-leasing/price-mark/page */
export async function OperationId_salesgateway_ContainerLeasingPriceMarkController_pagePriceMark(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingPriceMarkControllerPagePriceMarkParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PriceMarkPageVO[]>('/salesgateway/container-leasing/price-mark/page', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingPriceMarkController_pagePriceMark',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}
