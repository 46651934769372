// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取随机公钥 GET /salesgateway/v2/open/auth/random-key */
export async function OperationId_salesgateway_OpenAuthController_randomRsaKey(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.RandomRsaKeyResponseVO>('/salesgateway/v2/open/auth/random-key', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenAuthController_randomRsaKey',
    },
    ...(rest || {}),
  });
}
