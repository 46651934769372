import { i18n } from '@easygo/i18n';

/** 售后类型 */
export enum AfterSaleTypeEnum {
  PRODUCT_AND_AMOUNT = 'AFTER_SALE_TYPE_REFUND_PRODUCT_AND_AMOUNT',
  FINISH_DROP_OFF = 'AFTER_SALE_TYPE_REFUND_FINISH_DROP_OFF',
  NO_PICKUP = 'AFTER_SALE_TYPE_REFUND_NO_PICKUP',
  FINISH_PICKUP = 'AFTER_SALE_TYPE_REFUND_FINISH_PICKUP',
}
export const afterSaleType = {
  [AfterSaleTypeEnum.PRODUCT_AND_AMOUNT]: i18n?.t('退商品&退款'),
  [AfterSaleTypeEnum.FINISH_DROP_OFF]: i18n?.t('仅退款-已还箱'), // 仅针对：退款-已还箱
  [AfterSaleTypeEnum.NO_PICKUP]: '仅退款-未提箱',
  [AfterSaleTypeEnum.FINISH_PICKUP]: '仅退款-已提箱',
};

/** 售后原因 */
export const afterSaleReasonType = {
  REASON_TYPE_AFTER_SALE_REQ: i18n?.t('申请售后'),
  REASON_TYPE_CANCEL_AFTER_SALE: i18n?.t('取消售后'),
};

/** 售后状态 枚举定义 */
export enum AfterSaleStatusEnum {
  SUBMIT_SUCCESS = 'AFTER_SALE_ORDER_SUBMIT_SUCCESS',
  MODIFIED_PRICE_SUCCESS = 'AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS',
  APPROVE_PASS = 'AFTER_SALE_ORDER_APPROVE_PASS',
  UNAPPROVE = 'AFTER_SALE_ORDER_UNAPPROVE',
  SUCCESS = 'AFTER_SALE_ORDER_SUCCESS',
  FAIL = 'AFTER_SALE_ORDER_FAIL',
  CANCELED = 'AFTER_SALE_ORDER_CANCELED',
}

/** 售后状态对应状态文案 */
export const afterSaleStatus = {
  [AfterSaleStatusEnum.SUBMIT_SUCCESS]: i18n?.t('待卖家审核'),
  [AfterSaleStatusEnum.MODIFIED_PRICE_SUCCESS]: i18n?.t('待买家确认'),
  [AfterSaleStatusEnum.APPROVE_PASS]: i18n?.t('退款中'),
  [AfterSaleStatusEnum.UNAPPROVE]: i18n?.t('审核驳回'),
  [AfterSaleStatusEnum.SUCCESS]: i18n?.t('退款完成'),
  [AfterSaleStatusEnum.FAIL]: i18n?.t('退款失败'),
  [AfterSaleStatusEnum.CANCELED]: i18n?.t('已取消'),
};
/** 售后状态对应状态颜色 */
export const afterSaleStatusColor = {
  [AfterSaleStatusEnum.SUBMIT_SUCCESS]: 'gray',
  [AfterSaleStatusEnum.MODIFIED_PRICE_SUCCESS]: 'gray',
  [AfterSaleStatusEnum.APPROVE_PASS]: 'black',
  [AfterSaleStatusEnum.UNAPPROVE]: 'red',
  // [AfterSaleStatusEnum.REFUND_SUCCESS]: 'black',
  [AfterSaleStatusEnum.SUCCESS]: 'black',
  [AfterSaleStatusEnum.FAIL]: 'gray',
  [AfterSaleStatusEnum.CANCELED]: 'gray',
};
/** 售后显示对应文案和圆点颜色，pro-components ProTable 使用 */
const afterSaleStatusDisplay: { [key: string]: { text: string; color: string } } = {};
for (let key in afterSaleStatus) {
  afterSaleStatusDisplay[key] = {
    text: afterSaleStatus[key as keyof typeof afterSaleStatus],
    color: afterSaleStatusColor[key as keyof typeof afterSaleStatusColor],
  };
}
export { afterSaleStatusDisplay };
