// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 设置卖家给买家配置的结算方式 POST /sinotransbooking/v1/seller/settlement-type/create-or-update-by-performance-id */
export async function OperationId_sinotransbooking_SellerSettlementTypeController_createOrUpdateSettlementTypeConfig(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerSettlementTypeControllerCreateOrUpdateSettlementTypeConfigParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SettlementTypeVO>(
    '/sinotransbooking/v1/seller/settlement-type/create-or-update-by-performance-id',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerSettlementTypeController_createOrUpdateSettlementTypeConfig',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 获取卖家给买家配置的结算方式（通过买家企业 id） GET /sinotransbooking/v1/seller/settlement-type/get-by-buyer-enterprise-id */
export async function OperationId_sinotransbooking_SellerSettlementTypeController_getSettlementTypeConfigByBuyerEnterpriseId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerSettlementTypeControllerGetSettlementTypeConfigByBuyerEnterpriseIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SettlementTypeVO>(
    '/sinotransbooking/v1/seller/settlement-type/get-by-buyer-enterprise-id',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerSettlementTypeController_getSettlementTypeConfigByBuyerEnterpriseId',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 获取卖家给买家配置的结算方式（通过履约单 id） GET /sinotransbooking/v1/seller/settlement-type/get-by-performance-id */
export async function OperationId_sinotransbooking_SellerSettlementTypeController_getSettlementTypeConfigByPerformanceId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerSettlementTypeControllerGetSettlementTypeConfigByPerformanceIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SettlementTypeVO>(
    '/sinotransbooking/v1/seller/settlement-type/get-by-performance-id',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerSettlementTypeController_getSettlementTypeConfigByPerformanceId',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}
