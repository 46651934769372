import React, { useEffect, useMemo, useState } from 'react';
import type { FC, CSSProperties } from 'react';
import { Input, Space } from 'antd';
import cls from 'classnames';
import styles from './index.module.less';

enum AMOUNT_TYPE {
  ADD = 'ADD',
  FLOW = 'FLOW',
}
interface InputNmberProps {
  /** value值 */
  value?: string;
  /** 默认值 */
  defaultValue?: string;
  /** 最小值 */
  min?: number;
  /** 最大值 不超过16个字符 */
  max?: number;
  /** 变更操作 */
  onChange?: (e: any) => void;
  /** 位数保留 */
  fixed?: number;
  /** 步数 */
  step?: number;
  /** 是否不可编辑 */
  disabled?: boolean;
  /** 是否为偶数 */
  double?: boolean;
  /** 自定义样式 */
  style?: CSSProperties;
  className?: string;
}
const reg = /^[-]?[\.\d]+$/;
const InputNmber: FC<InputNmberProps> = (props) => {
  const { value = '', defaultValue = '', onChange, fixed = 0, min = 0, max, step = 1, disabled = false, double = false, style = {} } = props ?? {};
  const [count, setCount] = useState<number | string>(0);
  /** 是否最大值 */
  const isMax = useMemo(() => (max ? count > max - step : false), [step, count, max]);
  /** 是否最小值 */
  const isMin = useMemo(() => (min ? count < min + step : count < step), [step, count, min]);

  useEffect(() => {
    setCount(value || defaultValue);
  }, [defaultValue, value]);

  /**
   * @method 用户自主输入
   * @param e
   */
  const handleChange = (e: any) => {
    const value = e.target.value;
    setCount(value);
  };

  /**
   * @method 处理保留小数
   */
  const dealAmountFixed = (amount: number) => {
    let formatValue: number | string = amount;
    /** 是否为number类型 */
    const isNumber = reg.test(amount.toString());
    if (isNumber) {
      /** 是否为负值 */
      if (+amount <= 0) {
        formatValue = 0;
      }
      /** 是否为偶数 */
      if (double) {
        formatValue = amount % 2 === 0 ? amount : '';
      }
    } else {
      formatValue = '';
    }
    const fiexdAmount = isNumber && !isNaN(parseInt(formatValue as string)) ? parseInt(formatValue as string).toFixed(fixed) : '';
    onChange?.(fiexdAmount);
    return fiexdAmount;
  };

  /**
   * @method 失去焦点事件
   */
  const onBlur = (e: any) => {
    const value = e.target.value;
    let dealAmount = dealAmountFixed(count as number) as any;
    if (min && dealAmount < min) {
      dealAmount = '';
    }

    if (max && dealAmount > max) {
      dealAmount = '';
    }
    setCount(value ? dealAmount : '');
    onChange?.(dealAmountFixed(dealAmount));
  };

  /**
   * @method 自增/降低
   */
  const handleAutoincrement = (type: AMOUNT_TYPE) => {
    let amount = 0;
    const isDisabled = type === AMOUNT_TYPE.ADD ? isMax : isMin;
    if (isDisabled || max === 0 || disabled) return;
    if (type === AMOUNT_TYPE.ADD) {
      amount = count ? +count + step : min || step;
    } else {
      amount = count ? +count - step : 0;
    }
    const negative = dealAmountFixed(amount);
    setCount(negative);
  };

  return (
    <Space direction="vertical" className={styles.container}>
      <div className={styles.inputNumberContainer} style={style}>
        <span
          className={cls(styles.activity, styles.reduce, {
            [styles.disabled]: isMin || max === 0 || disabled,
          })}
          onClick={() => handleAutoincrement(AMOUNT_TYPE.FLOW)}
        >
          -
        </span>
        <Input
          value={count}
          onChange={handleChange}
          className={cls(styles.input, { [styles.disabled]: disabled })}
          onBlur={onBlur}
          maxLength={16}
          disabled={disabled}
        />
        <span
          className={cls(styles.activity, styles.plus, {
            [styles.disabled]: isMax || max === 0 || disabled,
          })}
          onClick={() => handleAutoincrement(AMOUNT_TYPE.ADD)}
        >
          +
        </span>
      </div>
    </Space>
  );
};

export default InputNmber;
