/**
 * UNICODE中的货币符号
 * https://www.unicode.org/versions/Unicode15.0.0/ch22.pdf
 * 第4页 22.1
 */
export const CurrencyUnicodeSymbol = {
  /** £ 英镑 */
  POUND: String.fromCharCode(0xa3),
  /** $ 美元符号 */
  DOLLAR: String.fromCharCode(0x24),
  /** ¥ 日元符号。在Unicode标准中，U+00A5日元符号是日元和人民币的货币符号，与字形表示的细节无关。 */
  YEN: String.fromCharCode(0xa5),
  /** € 欧元符号。欧洲经济和货币联盟成员国的单一货币 */
  EURO: String.fromCharCode(0x20ac),
  /** ₹ 印度卢比符号。于2010年引入的印度卢比货币符号，作为印度卢比（INR）的官方货币符号。 */
  INDIAN_RUPEE: String.fromCharCode(0x20b9),
  /** ₺ 土耳其里拉符号。2012年引入新符号之前，该货币通常缩写为字母“TL” */
  TURKISH_LIRA: String.fromCharCode(0x20ba),
  /** ₽ 卢布符号。于2013年被用作俄罗斯联邦货币的官方符号 */
  RUBLE: String.fromCharCode(0x20bd),
  /** ₾ 拉里符号。于2014年被用作格鲁吉亚货币的官方符号。 */
  LARI: String.fromCharCode(0x20be),
  /** ₿ 比特币符号 */
  BITCOIN: String.fromCharCode(0x20bf),
  /** ⃀ 索姆符号。于2017年2月8日被用作吉尔吉斯斯坦共和国的官方货币符号。 */
  SOM: String.fromCharCode(0x20c0),
};

/**
 * 货币全配置
 * @property code 基于ISO 4217标准的货币code  https://github.com/datasets/currency-codes/blob/master/data/codes-all.csv
 * @property symbol 基于Unicode标准的货币符号
 */
export const CurrencyConfigMap = {
  /** 人民币 */
  CNY: {
    code: 'CNY',
    symbol: CurrencyUnicodeSymbol.YEN,
    label: '人民币',
  },
  /** 美元 */
  USD: {
    code: 'USD',
    symbol: CurrencyUnicodeSymbol.DOLLAR,
    label: '美元',
  },
  /** 日元 */
  JPY: {
    code: 'JPY',
    // 这里增加 JP前缀 与人民币作区分
    symbol: `JP${CurrencyUnicodeSymbol.YEN}`,
    label: '日元',
  },
  /** 欧元 */
  EUR: {
    code: 'EUR',
    symbol: CurrencyUnicodeSymbol.EURO,
    label: '欧元',
  },
  /** 英镑 */
  GBP: {
    code: 'GBP',
    symbol: CurrencyUnicodeSymbol.POUND,
    label: '英镑',
  },
  /** 卢布 */
  RUB: {
    code: 'RUB',
    symbol: CurrencyUnicodeSymbol.RUBLE,
    label: '卢布',
  },
};

/**
 * 后端货币枚举
 * 次要版本，多用于海运铁运等商品中
 * 后面的开发迭代尽量别用
 */
export enum CoinType {
  /** 人民币 */
  RMB = 'SKU_COIN_TYPE_RMB',
  /** 美元 */
  DOLLAR = 'SKU_COIN_TYPE_DOLLAR',
  /** 日元 */
  YEN = 'SKU_COIN_TYPE_YEN',
  /** 欧元 */
  EURO = 'SKU_COIN_TYPE_EURO',
  /** 英镑 */
  GBP = 'SKU_COIN_TYPE_GBP',
  /** 卢布 */
  SUR = 'SKU_COIN_TYPE_SUR',
}

/**
 * 后端货币枚举次要版本对应的 ISO code
 */
export const CoinTypeCode = {
  [CoinType.RMB]: CurrencyConfigMap.CNY.code,
  [CoinType.DOLLAR]: CurrencyConfigMap.USD.code,
  [CoinType.YEN]: CurrencyConfigMap.JPY.code,
  [CoinType.EURO]: CurrencyConfigMap.EUR.code,
  [CoinType.GBP]: CurrencyConfigMap.GBP.code,
  [CoinType.SUR]: CurrencyConfigMap.RUB.code,
};

/**
 * 后端货币枚举次要版本对应的 Unicode Symbol
 */
export const CoinTypeSymbol = {
  [CoinType.RMB]: CurrencyConfigMap.CNY.symbol,
  [CoinType.DOLLAR]: CurrencyConfigMap.USD.symbol,
  [CoinType.YEN]: CurrencyConfigMap.JPY.symbol,
  [CoinType.EURO]: CurrencyConfigMap.EUR.symbol,
  [CoinType.GBP]: CurrencyConfigMap.GBP.symbol,
  [CoinType.SUR]: CurrencyConfigMap.RUB.symbol,
};

/**
 * 后端货币枚举主要版本对应的 Label
 */
export const CoinTypeTypeLabel = {
  [CoinType.RMB]: CurrencyConfigMap.CNY.label,
  [CoinType.DOLLAR]: CurrencyConfigMap.USD.label,
  [CoinType.YEN]: CurrencyConfigMap.JPY.label,
  [CoinType.EURO]: CurrencyConfigMap.EUR.label,
  [CoinType.GBP]: CurrencyConfigMap.GBP.label,
  [CoinType.SUR]: CurrencyConfigMap.RUB.label,
};

/**
 * 后端货币枚举
 * 主要版本
 * 后面的开发迭代尽量要求后端保持跟这个枚举值统一
 */
export enum CurrencyType {
  /** 人民币 */
  RMB = 'CURRENCY_TYPE_RMB',
  /** 美元 */
  DOLLAR = 'CURRENCY_TYPE_DOLLAR',
  /** 日元 */
  YEN = 'CURRENCY_TYPE_YEN',
  /** 欧元 */
  EURO = 'CURRENCY_TYPE_EURO',
  /** 英镑 */
  GBP = 'CURRENCY_TYPE_GBP',
  /** 卢布 */
  RUB = 'CURRENCY_TYPE_RUB',
}

/**
 * 后端货币枚举主要版本对应的 ISO code
 */
export const CurrencyTypeCode = {
  [CurrencyType.RMB]: CurrencyConfigMap.CNY.code,
  [CurrencyType.DOLLAR]: CurrencyConfigMap.USD.code,
  [CurrencyType.YEN]: CurrencyConfigMap.JPY.code,
  [CurrencyType.EURO]: CurrencyConfigMap.EUR.code,
  [CurrencyType.GBP]: CurrencyConfigMap.GBP.code,
  [CurrencyType.RUB]: CurrencyConfigMap.RUB.code,
};

/**
 * 后端货币枚举主要版本对应的 Unicode Symbol
 */
export const CurrencyTypeSymbol = {
  [CurrencyType.RMB]: CurrencyConfigMap.CNY.symbol,
  [CurrencyType.DOLLAR]: CurrencyConfigMap.USD.symbol,
  [CurrencyType.YEN]: CurrencyConfigMap.JPY.symbol,
  [CurrencyType.EURO]: CurrencyConfigMap.EUR.symbol,
  [CurrencyType.GBP]: CurrencyConfigMap.GBP.symbol,
  [CurrencyType.RUB]: CurrencyConfigMap.RUB.symbol,
};

/**
 * 后端货币枚举主要版本对应的 Unicode Symbol
 */
export const CurrencyTypeForLabel = {
  [CurrencyType.RMB]: CurrencyConfigMap.CNY.label,
  [CurrencyType.DOLLAR]: CurrencyConfigMap.USD.label,
  [CurrencyType.YEN]: CurrencyConfigMap.JPY.label,
  [CurrencyType.EURO]: CurrencyConfigMap.EUR.label,
  [CurrencyType.GBP]: CurrencyConfigMap.GBP.label,
  [CurrencyType.RUB]: CurrencyConfigMap.RUB.label,
};

/**
 * 后端货币枚举
 * 次要版本，多用于海运铁运等商品中
 * 后面的开发迭代尽量别用
 */
export enum CurrencyTrans {
  /** 人民币 */
  SKU_COIN_TYPE_RMB = 'CURRENCY_TYPE_RMB',
  /** 美元 */
  SKU_COIN_TYPE_DOLLAR = 'CURRENCY_TYPE_DOLLAR',
  /** 日元 */
  SKU_COIN_TYPE_YEN = 'CURRENCY_TYPE_YEN',
  /** 欧元 */
  SKU_COIN_TYPE_EURO = 'CURRENCY_TYPE_EURO',
  /** 英镑 */
  SKU_COIN_TYPE_GBP = 'CURRENCY_TYPE_GBP',
  /** 卢布 */
  SKU_COIN_TYPE_SUR = 'CURRENCY_TYPE_RUB',
}
/**
 * pro/components valueEnum 类型数据
 */
export const CurrencyValueEnum = {
  [CurrencyType.DOLLAR]: {
    key: CurrencyType.DOLLAR,
    text: '美元',
    symbol: CurrencyUnicodeSymbol.DOLLAR,
  },
  [CurrencyType.RMB]: {
    key: CurrencyType.RMB,
    text: '人民币',
    symbol: CurrencyUnicodeSymbol.YEN,
  },
  [CurrencyType.EURO]: {
    key: CurrencyType.EURO,
    text: '欧元',
    symbol: CurrencyUnicodeSymbol.EURO,
  },
  [CurrencyType.RUB]: {
    key: CurrencyType.RUB,
    text: '卢布',
    symbol: CurrencyUnicodeSymbol.POUND,
  },
  [CurrencyType.GBP]: {
    key: CurrencyType.GBP,
    text: '英镑',
    symbol: CurrencyUnicodeSymbol.POUND,
  },
};
