import type { DataNode } from 'antd/lib/tree';
import type { PermissionPlatform } from '../constants';
import { PERMISSION_JSON_URL_MAP } from '../constants';
import type React from 'react';

export interface PermissionItem {
  access: string;
  name: string;
  operationIds?: string[];
  btnAccess?: PermissionItem[];
  routes?: PermissionItem[];
  isBtnAccess?: boolean;
  ignoreAccess?: boolean;
  pid: string;
}

export interface PermissionDataNode extends DataNode {
  originItem: PermissionItem;
  children?: PermissionDataNode[];
  key: string;
  checked?: boolean;
  pid: string;
}

export type IconRender = (permissionItem: PermissionItem) => React.ReactNode;

export function convertToTreeData(permissions: PermissionItem[], iconRender?: IconRender, trans?: (key: string) => string): PermissionDataNode[] {
  return permissions.map((item) => ({
    title: trans ? trans(item.name) : item.name,
    key: item.access,
    children: convertToTreeData(
      [...(item.routes || []), ...(item.btnAccess || []).map((btnItem) => ({ ...btnItem, isBtnAccess: true }))],
      iconRender,
      trans
    ),
    icon: iconRender ? iconRender(item) : null,
    pid: item.pid,
    originItem: item,
  }));
}

/**
 * @method 请求权限树
 * @param platform 指定的应用平台
 * @returns json格式的权限配置
 */
export function fetchPermissionTree(platform: PermissionPlatform, version: string) {
  const permissionUrlOption = PERMISSION_JSON_URL_MAP(version)[platform];
  // 优先从base获取权限文件，如果不存在则从fallback获取权限文件
  return fetch(permissionUrlOption.base)
    .then((res) => {
      if (res.status === 404) {
        return fetch(permissionUrlOption.fallback);
      }

      return res;
    })
    .then((response) => response.json())
    .then((permissionItems) => permissionItems[0].routes || permissionItems)
    .catch(() => [] as PermissionItem[]);
}

/**
 * @method 获取堆场聚合权限树
 * @param version
 */
export async function getPolymerizationDepotPermission(version: string) {
  const polymerizationPermissionTree: Array<PermissionItem[]> = [];
  const polymerizationPlatform: PermissionPlatform[] = ['dms', 'depot_app'];
  for (const item of polymerizationPlatform) {
    const permissionTree = await fetchPermissionTree(item, version);
    polymerizationPermissionTree.push(permissionTree);
  }
  // 外层数据构造
  const formatPolymerizationPermissionTree = polymerizationPermissionTree
    ?.filter((item) => item.length)
    .map((item, index) => ({
      access: !index ? 'depot_polymerization' : 'depot_polymerization_app',
      routes: item,
      ignoreAccess: true,
      name: !index ? '数字堆场' : '场务APP',
    }));
  return formatPolymerizationPermissionTree;
}

/**
 * 获取指定平台的权限配置
 * @param platform 指定的应用平台（trade:交易 admin:运营 box:箱管）
 * @returns json格式的权限配置
 */
export function getPermissionJson(platform: PermissionPlatform, version: string) {
  if (platform === 'depot') {
    return getPolymerizationDepotPermission(version);
  }
  return fetchPermissionTree(platform, version);
}

/**
 * 获取指定平台的权限配置并转换成树组件所需的格式
 * @param platform 指定的应用平台（trade:交易 admin:运营 box:箱管）
 * @returns 权限树节点数组
 */
export function getPermissionTreeData(platform: PermissionPlatform, version: string, iconRender?: IconRender, trans?: (key: string) => string) {
  return getPermissionJson(platform, version).then((json) => convertToTreeData(json, iconRender, trans));
}

/**
 * 筛选指定角色类型的权限树节点
 * @param nodes 全量权限树节点
 * @param roleType 指定的角色类型
 * @returns
 */
export const filterPermissionDataNodeByRoleType = (nodes: PermissionDataNode[], roleType: 'buier' | 'saler'): PermissionDataNode[] => {
  const excludePath = roleType === 'buier' ? 'saler' : 'buier';

  return filterPermissionDataNode(nodes, ({ originItem }) => !originItem.access.includes(excludePath));
};

/**
 * 筛选指定角色类型的权限树节点（此函数变量nodes数组以及node的children）
 * @param nodes 全量权限树节点
 * @param filterFn 过滤函数
 * @returns
 */
export const filterPermissionDataNode = (nodes: PermissionDataNode[], filterFn: (node: PermissionDataNode) => boolean): PermissionDataNode[] => {
  return nodes.filter(filterFn).map((item) => ({
    ...item,
    children: filterPermissionDataNode((item.children || []) as PermissionDataNode[], filterFn),
  }));
};

export const findNodes = (nodes: PermissionDataNode[], filterFn: (node: PermissionDataNode) => boolean) => {
  let targetNodes: PermissionDataNode[] = [];

  const innerfindNodes = (nodes: PermissionDataNode[], filterFn: (node: PermissionDataNode) => boolean) => {
    nodes.forEach((node) => {
      if (filterFn(node)) {
        targetNodes.push(node);
      }

      if (node.children && node.children.length > 0) {
        innerfindNodes(node.children as PermissionDataNode[], filterFn);
      }
    });
    return targetNodes;
  };

  return innerfindNodes(nodes, filterFn);
};

/** 遍历树，并根据给定筛选条件执行一些操作 */
export const traverseNodeWithFilter = (
  nodes: PermissionDataNode[],
  cb: (node: PermissionDataNode) => PermissionDataNode,
  filterFn: (node: PermissionDataNode) => boolean
): PermissionDataNode[] =>
  nodes.map((node) =>
    filterFn(node)
      ? { ...cb(node), children: node.children?.length ? traverseNodeWithFilter(node.children, cb, filterFn) : undefined }
      : node.children?.length
      ? { ...node, children: traverseNodeWithFilter(node.children, cb, filterFn) }
      : node
  );

/** 向上遍历树节点的所有父节点及祖先节点，并执行一些操作 */
export const reverseNodeWithFilter = (
  node: PermissionDataNode,
  nodes: PermissionDataNode[],
  cb: (node: PermissionDataNode) => PermissionDataNode
): PermissionDataNode[] => {
  let pid = node.pid;
  function findParent(nodes: PermissionDataNode[]) {
    for (let i = 0; i < nodes.length; i++) {
      let nd = nodes[i];
      if (nd.key === pid) {
        pid = nd.pid;
        nodes[i] = cb(nd);
        break;
      } else if (nd.children?.length) {
        findParent(nd.children);
      }
    }
    return nodes;
  }
  while (pid && pid !== '_') {
    findParent(nodes);
  }
  return nodes;
};

/** 根据key向下遍历自身及所有子元素，并执行一些操作 */
export const traverseAndUpdateNode = (
  key: string | number,
  nodes: PermissionDataNode[],
  cb: (node: PermissionDataNode) => PermissionDataNode
): PermissionDataNode[] => {
  const updatedNodes = nodes.map((node) => {
    if (node.key === key) {
      return traverseNodeWithFilter(
        [node],
        (node) => cb(node),
        () => true
      )?.[0];
    } else if (node.children?.length) {
      const updatedChildren = traverseAndUpdateNode(key, node.children, cb);
      return { ...node, children: updatedChildren };
    } else {
      return node;
    }
  });
  return updatedNodes;
};
