// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 异常情况列表 获取当前用户的异常情况列表 GET /salesgateway/work-bench/message/abnormal */
export async function OperationId_salesgateway_WorkBenchMessageController_abnormalMessage(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.AbnormalMessageResponseVo[]>('/salesgateway/work-bench/message/abnormal', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_WorkBenchMessageController_abnormalMessage',
    },
    ...(rest || {}),
  });
}

/** 待办任务列表 获取当前用户的待办任务列表 GET /salesgateway/work-bench/message/getUndoTask */
export async function OperationId_salesgateway_WorkBenchMessageController_getUndoTask(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.UndoTaskMessageResponseVO>('/salesgateway/work-bench/message/getUndoTask', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_WorkBenchMessageController_getUndoTask',
    },
    ...(rest || {}),
  });
}
