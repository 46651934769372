import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Space } from 'antd';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { utils, enums } from '@easygo/utils';

export function LayoutFooterOffice() {
  const router = useRouter();
  const { t } = useTranslation('website');
  return (
    <footer className={styles.footer}>
      <div className={styles.wrap}>
        <div className={styles.enterprise}>
          <div>
            <Image alt="" width={186} height={32} src="/images/logo.png" />
          </div>
          {/* <p>{t('introduce')}</p> */}
          <div className={styles.contact}>
            <Image alt="" width={20} height={20} src="/images/phone.png" />
            <span>400-666-5517</span>
          </div>
          <p>{t('address')}</p>
        </div>
        <div className={styles.linkListArea}>
          <ul>
            <label>{t('about_us')}</label>
            <li
              onClick={() => {
                router.push('/news');
              }}
            >
              {t('news')}
            </li>
            <li
              onClick={() => {
                router.push('/aboutUs');
              }}
            >
              {t('contact_us')}
            </li>
            <li
              onClick={() => {
                window.open(enums.PRIVACY_DOC);
              }}
            >
              {t('privacy_agreement')}
            </li>
          </ul>
          <ul>
            <label>{t('investment_cooperation')}</label>
            <li
              onClick={() => {
                router.push('/aboutUs');
              }}
            >
              {t('service_provider_recruitment')}
            </li>
            <li
              onClick={() => {
                router.push('/aboutUs');
              }}
            >
              {t('business_cooperation')}
            </li>
          </ul>
          <ul>
            <label>{t('follow_us')}</label>
            <Space size={20}>
              <Space className={styles['code']} direction="vertical" size={0}>
                <Image src="/images/WeChat_official_account.jpg" width={70} height={70} alt="" />
                {t('official_account')}
              </Space>
              <Space className={styles['code']} direction="vertical" size={0}>
                <Image src="/images/tiktok.png" width={70} height={70} alt="" />
                {t('TikTok')}
              </Space>
            </Space>
          </ul>
        </div>
      </div>
      <div className={styles.copyright}>
        Copyright © 2021 - 2024 www.easygo.cn All Rights Reserved{' '}
        <span
          onClick={(): void => {
            window.open('https://beian.miit.gov.cn', 'blank', 'noopener=yes,noreferrer=yes');
          }}
          style={{ cursor: 'pointer' }}
        >
          {utils.ignoreI18n('蜀ICP备2021030441号-4')}
        </span>
        {` `}
        <span
          onClick={(): void => {
            window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005340', 'blank', 'noopener=yes,noreferrer=yes');
          }}
          style={{ cursor: 'pointer' }}
        >
          <Image width={16} height={16} src="https://yichang-fronted-public.oss-cn-hangzhou.aliyuncs.com/imgs/record_01.png" />
          {utils.ignoreI18n('川公网安备 51019002005340号')}
        </span>
      </div>
    </footer>
  );
}
