// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 附加费用详情 GET /salesgateway/seller/container-leasing/additional-fees/${param0} */
export async function OperationId_salesgateway_BizAdditionalFeesController_detail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizAdditionalFeesControllerDetailParams,
  options?: { [key: string]: any },
) {
  const { additionalFeeId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AdditionalFeeVo>(
    `/salesgateway/seller/container-leasing/additional-fees/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizAdditionalFeesController_detail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 新增附加费用 POST /salesgateway/seller/container-leasing/additional-fees/add */
export async function OperationId_salesgateway_BizAdditionalFeesController_add(
  body: API.AddAdditionalFeeRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/seller/container-leasing/additional-fees/add', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizAdditionalFeesController_add',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询附加费用列表 POST /salesgateway/seller/container-leasing/additional-fees/get-list */
export async function OperationId_salesgateway_BizAdditionalFeesController_list(
  body: API.AdditionalFeeListRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AdditionalFeeListResponseVo>(
    '/salesgateway/seller/container-leasing/additional-fees/get-list',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizAdditionalFeesController_list',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 修改附加费 PUT /salesgateway/seller/container-leasing/additional-fees/modify */
export async function OperationId_salesgateway_BizAdditionalFeesController_update(
  body: API.AdditionalFeeUpdateRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/seller/container-leasing/additional-fees/modify', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizAdditionalFeesController_update',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 附加费修改备注 PUT /salesgateway/seller/container-leasing/additional-fees/remark/${param0} */
export async function OperationId_salesgateway_BizAdditionalFeesController_remark(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizAdditionalFeesControllerRemarkParams,
  options?: { [key: string]: any },
) {
  const { additionalFeeId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/seller/container-leasing/additional-fees/remark/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizAdditionalFeesController_remark',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 关闭附加费 PUT /salesgateway/seller/container-leasing/additional-fees/state-cancel/${param0} */
export async function OperationId_salesgateway_BizAdditionalFeesController_cancelFee(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizAdditionalFeesControllerCancelFeeParams,
  options?: { [key: string]: any },
) {
  const { additionalFeeId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/seller/container-leasing/additional-fees/state-cancel/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizAdditionalFeesController_cancelFee',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}
