// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 已装箱 PUT /logisticssolution/v1/seller/trailer-orders/${param0}/packing */
export async function OperationId_logisticssolution_SellerTrailerOrderController_packing(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerTrailerOrderControllerPackingParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/trailer-orders/${param0}/packing`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerTrailerOrderController_packing',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 已进站 PUT /logisticssolution/v1/seller/trailer-orders/${param0}/station */
export async function OperationId_logisticssolution_SellerTrailerOrderController_station(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerTrailerOrderControllerStationParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/trailer-orders/${param0}/station`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerTrailerOrderController_station',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
