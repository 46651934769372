// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 企业履约模式选择 GET /salesgateway/container-leasing/config/enterprise-performance-mode/choose */
export async function OperationId_salesgateway_ContainerConfigController_chooseEnterprisePerformanceMode(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.ChooseEnterprisePerformanceModeResponse>(
    '/salesgateway/container-leasing/config/enterprise-performance-mode/choose',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerConfigController_chooseEnterprisePerformanceMode',
      },
      ...(rest || {}),
    },
  );
}

/** 获取租箱-提箱有效期 GET /salesgateway/container-leasing/config/pickup-expire-time/${param0} */
export async function OperationId_salesgateway_ContainerConfigController_getPickupExpireTime(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerConfigControllerGetPickupExpireTimeParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetLeasingPickupExpireTimeResponse>(
    `/salesgateway/container-leasing/config/pickup-expire-time/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerConfigController_getPickupExpireTime',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
