import { Form, Popconfirm, Table } from 'antd';
import styles from './index.module.less';
import { PlusOutlined } from '@ant-design/icons';
import { createGuid } from '@easygo/utils/src/utils';
import type { Rule } from 'antd/lib/form';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  record: any;
  index: number;
  children: React.ReactNode;
  rules: Rule[];
  render: () => React.ReactNode;
}
interface EditableTableProps {
  customIdKey: string;
  listFieldName?: string;
  isReadOnly: Boolean;
  onAdd?: (preList: any[]) => void;
  columns: any[];
  defaultList: any[];
}
export interface EditableTableRef {
  getList: any;
  setList: any;
  validateForm: () => boolean;
}

const EditableCell: React.FC<EditableCellProps> = ({ dataIndex, title, record, index, children, render, rules, ...restProps }) => {
  return (
    <td {...restProps}>
      <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
        {render && render()}
      </Form.Item>
    </td>
  );
};
const InnerEditableTable: React.FC<EditableTableProps> = ({ columns, isReadOnly, listFieldName = 'list', customIdKey, defaultList, onAdd }, ref) => {
  const [form] = Form.useForm();
  const [list, setList] = useState<any>(defaultList || []);
  useImperativeHandle(ref, () => ({
    getList: () => form.getFieldValue(listFieldName),
    setList,
    validateForm,
  }));
  const addSurchargeItem = () => {
    const value = form.getFieldValue(listFieldName);
    if (onAdd) {
      onAdd(value);
    } else {
      setList([
        ...value,
        {
          uid: createGuid(),
        },
      ]);
    }
  };
  const delSurchargeItem = (uid?: string, customId?: string) => {
    const value = form.getFieldValue(listFieldName);
    if (uid) {
      setList(value.filter((item: any) => item.uid !== uid));
      return;
    }
    customId && setList(value.filter((item: any) => item[customIdKey] !== customId));
  };
  const validateForm = async () => {
    const valid = await form.validateFields().then(
      () => true,
      () => false
    );
    return valid;
  };
  useEffect(() => {
    form.setFieldsValue({ [listFieldName]: list });
  }, [form, list, listFieldName]);

  return (
    <div className={styles['surcharge-tpl-wrap']}>
      <Form layout="horizontal" form={form} colon={false} labelAlign="left" id="surchargeTpFormlWrap">
        <div className={styles['surchage-list']}>
          <Table
            rowKey={customIdKey}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={
              [
                ...columns,
                {
                  title: '操作',
                  align: 'center',
                  minWidth: 120,
                  width: 120,
                  editable: false,
                  onCell: (record: any) => ({
                    record,
                    render: () => (
                      <Popconfirm title="确认删除吗？" trigger="click" onConfirm={() => delSurchargeItem(record.uid, record[customIdKey])}>
                        <span style={{ cursor: 'pointer', color: '#F00', lineHeight: '22px' }}>删除</span>
                      </Popconfirm>
                    ),
                  }),
                },
              ] as Exclude<Parameters<typeof Table>[0]['columns'], undefined>
            }
            dataSource={list}
            bordered
            pagination={false}
            locale={{ emptyText: null }}
          />
          {!isReadOnly && (
            <div className={styles.ops}>
              <span className={styles['btn-add']} onClick={addSurchargeItem}>
                <PlusOutlined style={{ fontSize: 15 }} /> 添加
              </span>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export const EditableTable = forwardRef<EditableTableRef, EditableTableProps>(InnerEditableTable);
