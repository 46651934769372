import { Form, Button, Space, Tabs } from 'antd';
import { useMemo, useLayoutEffect, useRef, useState, useEffect } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useControllableValue, useMemoizedFn, useDebounceFn, useSize } from 'ahooks';
import classNames from 'classnames/bind';
import cls from 'classnames';
import styles from './index.module.less';
import { useComponentTrans } from '../config';
import type { FilterConfig, ProSearchFormProps } from './interface';
import formTypes from '../field-types/formTypes';

const FILTER_ITEM_WIDTH = 240;
const FILTER_ITEM_MARGIN_RIGHT = 52;
const PADDING_RIGHT_WHEN_COLLAGE = 257;
const SPACE_WHEN_BORDER = 50;

const cx = classNames.bind(styles);
// 文件夹不加pro前缀以便查找时可以根据首字母直接找到文件夹，如要加Pro可在导出组件时重命名
export function ProFilter(props: { options: FilterConfig[]; value?: string; onChange?: (value: string) => void }) {
  const { options } = props;
  const [tab, setTab] = useControllableValue(props, {
    defaultValue: options[0]?.value,
  });
  return (
    <Tabs activeKey={tab} onTabClick={(key) => setTab(key)}>
      {props.options.map((opt) => (
        <Tabs.TabPane key={opt.value} tab={opt.label}></Tabs.TabPane>
      ))}
    </Tabs>
  );
}

export function ProSearchForm<ValuesType extends Record<string, any> = Record<string, any>>(props: ProSearchFormProps<ValuesType>) {
  const { reactive = false, options, border = true, loading, filters, filterKey, onSearch, ...restProps } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [showCollapseBtn, setShowCollapseBtn] = useState(true);
  const [customForm] = Form.useForm();
  const trans = useComponentTrans();
  const [form] = useControllableValue(props, {
    valuePropName: 'form',
    defaultValue: customForm,
  });
  const [collapse, setCollapse] = useControllableValue(props, {
    defaultValue: true,
    defaultValuePropName: 'defaultExpand',
    valuePropName: 'expand',
  });
  const onFinish = useMemoizedFn((values: ValuesType) => {
    onSearch?.(values);
  });
  const hasFilter = useMemo(() => {
    return !!filters;
  }, [filters]);

  const isVertical = useMemo(() => restProps.layout === 'vertical', [restProps]);

  const [countPerLine, setCountPerLine] = useState(0);
  const wrapRef = useRef<HTMLDivElement>(null);
  const size = useSize(wrapRef);

  useEffect(() => {
    if (!size?.width) {
      return;
    }

    const realWrapWidth = size.width - (border ? SPACE_WHEN_BORDER : 0) - (collapse ? PADDING_RIGHT_WHEN_COLLAGE : 0);
    let expectCount = Math.floor(realWrapWidth / (FILTER_ITEM_WIDTH + FILTER_ITEM_MARGIN_RIGHT));
    if (expectCount * (FILTER_ITEM_WIDTH + FILTER_ITEM_MARGIN_RIGHT) + FILTER_ITEM_WIDTH <= realWrapWidth) {
      expectCount += 1;
    }

    setCountPerLine(expectCount);
  }, [size?.width, border, collapse]);

  const realItemWidth = useMemo(() => {
    if (!size?.width) {
      return FILTER_ITEM_MARGIN_RIGHT;
    }

    if (countPerLine <= 1) {
      return FILTER_ITEM_MARGIN_RIGHT;
    }

    const realWrapWidth = size.width - (border ? SPACE_WHEN_BORDER : 0) - (collapse ? PADDING_RIGHT_WHEN_COLLAGE : 0);
    // console.log('realItemWidth', realWrapWidth, (realWrapWidth - FILTER_ITEM_MARGIN_RIGHT * (countPerLine - 1)) / countPerLine - 1);
    return (realWrapWidth - FILTER_ITEM_MARGIN_RIGHT * (countPerLine - 1)) / countPerLine - 1;
  }, [size?.width, countPerLine, border, collapse]);

  const initCollapseBtn = useDebounceFn(
    () => {
      if (ref.current) {
        const wrapStyle = window.getComputedStyle(ref.current!);
        const h = parseInt(wrapStyle.height);
        if (h > (isVertical ? 88 : 56)) {
          setShowCollapseBtn(true);
        } else {
          setShowCollapseBtn(false);
        }
      }
    },
    {
      wait: 100,
    }
  );
  const handleResize = useMemoizedFn(() => {
    initCollapseBtn.run();
  });
  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  initCollapseBtn.run();

  return (
    <Form form={form} onFinish={onFinish} {...restProps}>
      <div ref={wrapRef} className={cx('wrap', { border, collapse, filters: hasFilter, vertical: isVertical })}>
        {hasFilter ? (
          <div className={styles.filter}>
            <Form.Item name={filterKey ?? 'filter'}>
              <ProFilter options={filters!} />
            </Form.Item>
          </div>
        ) : null}
        <div className={styles.itemWrap} ref={ref}>
          {(options ?? []).map(({ key, placeholder, type, col, label, renderFormItem, formItemProps, fieldProps, remoteProps }, index) => {
            const fieldProp = {
              ...fieldProps,
              placeholder: placeholder || label,
            };
            if (remoteProps) {
              fieldProp.remoteProps = remoteProps;
            }
            if (type && ['select', 'dateRange', 'dateTimeRange', 'date', 'dateTime'].includes(type)) {
              fieldProp.getPopupContainer = () => wrapRef.current?.parentElement;
            }
            return (
              <div
                key={key}
                className={cx('item', `col${col ?? 1}`)}
                style={reactive ? { width: realItemWidth, marginRight: (index + 1) % countPerLine == 0 ? 0 : FILTER_ITEM_MARGIN_RIGHT } : {}}
              >
                <Form.Item label={isVertical ? label : undefined} name={key} {...formItemProps}>
                  {renderFormItem ? renderFormItem(formItemProps) : formTypes[type ?? 'text'](fieldProp)}
                </Form.Item>
              </div>
            );
          })}
        </div>
        <div className={cls(styles.btns, { [styles.wrpeBtn]: !collapse })}>
          <Space size={8}>
            {showCollapseBtn ? (
              <span className={styles.collapseBtn} onClick={() => setCollapse(!collapse)}>
                {collapse ? trans('search_form_expand') : trans('search_form_collapse')}
                {collapse ? <DownOutlined /> : <UpOutlined />}
              </span>
            ) : null}
            <Button onClick={() => props.onReset?.()}>{trans('search_form_reset')}</Button>
            <Button loading={loading} type="primary" htmlType="submit">
              {trans('search_form_search')}
            </Button>
          </Space>
        </div>
      </div>
    </Form>
  );
}
