// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 详情 GET /salesgateway/v1/helper-center/api/question/${param0} */
export async function OperationId_salesgateway_HelperCenterController_getDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHelperCenterControllerGetDetailsParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetQuestionDetailsResp>(
    `/salesgateway/v1/helper-center/api/question/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_HelperCenterController_getDetails',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 帮助目录 GET /salesgateway/v1/helper-center/api/question/catalogs */
export async function OperationId_salesgateway_HelperCenterController_queryQuestionCatalog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHelperCenterControllerQueryQuestionCatalogParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QuestionCatalogVO>('/salesgateway/v1/helper-center/api/question/catalogs', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HelperCenterController_queryQuestionCatalog',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取第一个文档 GET /salesgateway/v1/helper-center/api/question/first-document */
export async function OperationId_salesgateway_HelperCenterController_getFirstDocument(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHelperCenterControllerGetFirstDocumentParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetQuestionDetailsResp>(
    '/salesgateway/v1/helper-center/api/question/first-document',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_HelperCenterController_getFirstDocument',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 列表 POST /salesgateway/v1/helper-center/api/question/list */
export async function OperationId_salesgateway_HelperCenterController_getList(
  body: API.GetQuestionListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetQuestionListResp>('/salesgateway/v1/helper-center/api/question/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HelperCenterController_getList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 搜索 POST /salesgateway/v1/helper-center/api/question/search */
export async function OperationId_salesgateway_HelperCenterController_search(
  body: API.SearchQuestionListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchQuestionListResp>('/salesgateway/v1/helper-center/api/question/search', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_HelperCenterController_search',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 通过DB模糊搜索 POST /salesgateway/v1/helper-center/api/question/searchByDB */
export async function OperationId_salesgateway_HelperCenterController_searchByDB(
  body: API.SearchQuestionListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetQuestionSearchByDBResp>(
    '/salesgateway/v1/helper-center/api/question/searchByDB',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_HelperCenterController_searchByDB',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取置顶帮助目录文档 GET /salesgateway/v1/helper-center/api/question/top-catalogs */
export async function OperationId_salesgateway_HelperCenterController_queryTopQuestionCatalog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayHelperCenterControllerQueryTopQuestionCatalogParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QuestionCatalogVO>(
    '/salesgateway/v1/helper-center/api/question/top-catalogs',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_HelperCenterController_queryTopQuestionCatalog',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}
