// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 回货线路列表 POST /salesgateway/container-back/line/open/api/list */
export async function OperationId_salesgateway_UseContainerLineOpenController_foreignUseBoxLineListByPage(
  body: API.UseContainerListLineOpenByPageReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UseContainerLineListOpenByPageRes>(
    '/salesgateway/container-back/line/open/api/list',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_UseContainerLineOpenController_foreignUseBoxLineListByPage',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
