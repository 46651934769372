// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 箱源简单列表 GET /salesgateway/container-owner/box/box/simple/list */
export async function OperationId_salesgateway_ContainerOwnerBoxController_listSimpleBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerBoxControllerListSimpleBoxParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SimpleBoxVO[]>('/salesgateway/container-owner/box/box/simple/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerOwnerBoxController_listSimpleBox',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 箱源列表导出 GET /salesgateway/container-owner/box/export */
export async function OperationId_salesgateway_ContainerOwnerBoxController_exportContainerOwnerBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerBoxControllerExportContainerOwnerBoxParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/container-owner/box/export', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerOwnerBoxController_exportContainerOwnerBox',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 箱源详情 GET /salesgateway/container-owner/box/get */
export async function OperationId_salesgateway_ContainerOwnerBoxController_getContainerOwnerBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerBoxControllerGetContainerOwnerBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerOwnerBoxVO>('/salesgateway/container-owner/box/get', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerOwnerBoxController_getContainerOwnerBoxDetail',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 箱源列表 GET /salesgateway/container-owner/box/page */
export async function OperationId_salesgateway_ContainerOwnerBoxController_pageContainerOwnerBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerBoxControllerPageContainerOwnerBoxParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerOwnerBoxPageVO>('/salesgateway/container-owner/box/page', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerOwnerBoxController_pageContainerOwnerBox',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 历史记录 GET /salesgateway/container-owner/box/pickupDropOff/page */
export async function OperationId_salesgateway_ContainerOwnerBoxController_pagePickupDropOffByBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerBoxControllerPagePickupDropOffByBoxParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PickupDropOffByBoxPageVO>(
    '/salesgateway/container-owner/box/pickupDropOff/page',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerOwnerBoxController_pagePickupDropOffByBox',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}
