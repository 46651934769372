// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取对账单详情信息 GET /sinotransbooking/v1/seller/statement/${param0} */
export async function OperationId_sinotransbooking_SellerStatementController_getStatementDetailInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerGetStatementDetailInfoParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementDetailInfoVO>(`/sinotransbooking/v1/seller/statement/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementController_getStatementDetailInfo',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 制作海运对账单 POST /sinotransbooking/v1/seller/statement/${param0}/make */
export async function OperationId_sinotransbooking_SellerStatementController_makeStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerMakeStatementParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MakeStatementResponse>(
    `/sinotransbooking/v1/seller/statement/${param0}/make`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerStatementController_makeStatement',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 确认海运对账单 POST /sinotransbooking/v1/seller/statement/confirm */
export async function OperationId_sinotransbooking_SellerStatementController_confirmStatement(
  body: API.ConfirmStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/seller/statement/confirm', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementController_confirmStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 分页查询对账单详情信息 POST /sinotransbooking/v1/seller/statement/list */
export async function OperationId_sinotransbooking_SellerStatementController_pageStatement(
  body: API.SalesSellerStatementQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStatementVO>('/sinotransbooking/v1/seller/statement/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementController_pageStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 修改海运对账单 POST /sinotransbooking/v1/seller/statement/modify */
export async function OperationId_sinotransbooking_SellerStatementController_modifyStatement(
  body: API.ModifyStatementRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/seller/statement/modify', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerStatementController_modifyStatement',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取对账单制作详情信息 GET /sinotransbooking/v1/seller/statement/performance/${param0}/statementMakeDetail */
export async function OperationId_sinotransbooking_SellerStatementController_getStatementMakeDetailInfoByPerformanceId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerStatementControllerGetStatementMakeDetailInfoByPerformanceIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.StatementMakeDetailInfoVO>(
    `/sinotransbooking/v1/seller/statement/performance/${param0}/statementMakeDetail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerStatementController_getStatementMakeDetailInfoByPerformanceId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
