import { i18n } from '@easygo/i18n';
import { createEnums } from '../utils/select-enums';

// 账号类型
export const AccountType = createEnums(['ACCOUNT_TYPE_BASE', 'ACCOUNT_TYPE_CREDIT', 'ACCOUNT_TYPE_SHOP_CREDIT', 'ACCOUNT_TYPE_OFFLINE_BALANCE']);

// 资金类型
export const FundType = createEnums(['FUND_TYPE_USABLE', 'FUND_TYPE_FROZEN', 'FUND_TYPE_USABLE_LINE', 'FUND_TYPE_USED_LINE', 'FUND_TYPE_LINE']);

// 账期付卖家买家
export const MerchantType = createEnums(['MERCHANT_TYPE_BUYER', 'MERCHANT_TYPE_SELLER']);

/** 收银台调起方式 */
export const CashierType = createEnums(['ORDER', 'DATEPAY']);
//账期结算模式
export const MerchantCreditType = createEnums(['MERCHANT_CREDIT_TYPE_MONTHLY', 'MERCHANT_CREDIT_TYPE_HALF_MONTHLY', 'MERCHANT_CREDIT_TYPE_WEEKLY']);
//账期付结算周期
export const BillCycleType = createEnums(['BILL_CYCLE_MONTHLY', 'BILL_CYCLE_HALF_MONTHLY', 'BILL_CYCLE_WEEKLY']);
//账期付账单生成日
export const BillDayType = createEnums(['BILL_DAY_MONTHLY', 'BILL_DAY_HALF_MONTHLY', 'BILL_DAY_WEEKLY']);
//挂销账状态
export const MarkStatus = createEnums(['MARK_UN_RECEIVE', 'MARK_RECEIVE', 'MARK_NONE']);
//关联单据类型
export const RelatedDealType = createEnums(['RECEIPTS_TYPE_ORDER', 'RECEIPTS_TYPE_AFTER_SALES', 'RECEIPTS_TYPE_CREDIT_ORDER']);

// 账单还款状态
export const PayStatus = createEnums([
  'CREDIT_TYPE_STATUS_WAITING',
  // 'CREDIT_TYPE_STATUS_PART',
  'CREDIT_TYPE_STATUS_FINISH',
  'CREDIT_TYPE_STATUS_OVERTIME',
  'CREDIT_TYPE_STATUS_CONFIRM',
]);

export const MainBusinessType = createEnums([
  'BUSINESS_TYPE_LEASING',
  'BUSINESS_TYPE_TRAINS_BOOKING_SPACE',
  'BUSINESS_TYPE_SEA_BOOKING_SPACE',
  // 'BUSINESS_TYPE_CUSTOMS',
  // 'BUSINESS_TYPE_TRAILER',
]);

export const FundLogChangeDirection = createEnums(['FUND_LOG_CHANGE_DIRECTION_IN', 'FUND_LOG_CHANGE_DIRECTION_OUT']);
// 支付单账单相关业务类型
export const BusinessType = createEnums([
  'BUSINESS_TYPE_LEASING',
  'BUSINESS_TYPE_TRAINS_BOOKING_SPACE',
  'BUSINESS_TYPE_SEA_BOOKING_SPACE',
  'BUSINESS_TYPE_TRAILER',
  'BUSINESS_TYPE_CUSTOMS',
  'BUSINESS_TYPE_SECOND_HAND',
  'BUSINESS_TYPE_CASH_PLEDGE',
  'BUSINESS_TYPE_SERVICE_FEE',
  'BUSINESS_TYPE_CREDIT_REPAY',
  'BUSINESS_TYPE_SUPPLY_CHAIN_FINANCE',
  'BUSINESS_TYPE_UNKNOWN',
]);

//账期付状态
export const AccountStatus = createEnums(['ACCOUNT_STATUS_ON', 'ACCOUNT_STATUS_OFF']);
/** 订单状态 */
export const OrderStatusEnums = createEnums([
  'CREDIT_RECORD_STATUS_UNKNOWN',
  'CREDIT_RECORD_STATUS_UN_REPAY',
  'CREDIT_RECORD_STATUS_REPAY',
  'CREDIT_RECORD_STATUS_OVER_DUE',
  'CREDIT_RECORD_STATUS_REPAY_CONFIRM',
]);

// 付款方式
export const PayModeType = createEnums([
  'PAY_MODE_TYPE_UNKNOWN',
  'PAY_MODE_TYPE_BALANCE',
  'PAY_MODE_TYPE_PLATFORM_CREDIT',
  'PAY_MODE_TYPE_SHOP_CREDIT',
  'PAY_MODE_TYPE_TRANSFER',
  'PAY_MODE_TYPE_OFFLINE_BALANCE',
]);

// 付款方式
export enum PayModeInt {
  PAY_MODE_TYPE_UNKNOWN = 0, // 支付方式
  PAY_MODE_TYPE_BALANCE = 1, // 余额付款
  PAY_MODE_TYPE_PLATFORM_CREDIT = 2, // 平台授信付款
  PAY_MODE_TYPE_SHOP_CREDIT = 3, // 商家授信付款
  PAY_MODE_TYPE_TRANSFER = 4, // 转账付款
  PAY_MODE_TYPE_OFFLINE_BALANCE = 5, // 线下钱包余额付款
}

// 费用项类型
export const FeeTypeBill = createEnums([
  'FEE_TYPE_UNKNOWN',
  'FEE_TYPE_COMMODITY',
  'FEE_TYPE_EXTRA_CHARGE',
  'FEE_TYPE_SHIP_PORT',
  'FEE_TYPE_SHIP_STATION',
  'FEE_TYPE_OTHER',
]);

//settlementList  资金结算账单中的 settlementStatus
export const SettlementStatus = createEnums(['SETTLEMENT_STATUS_SUC', 'SETTLEMENT_STATUS_WAITING', 'SETTLEMENT_STATUS_FAILED']);

// 支付流水账单状态
export const PayRecordStatus = [
  { value: 'PAYMENT_DETAIL_STATUS_WAITING', label: i18n?.t('支付待确认') },
  { value: 'PAYMENT_DETAIL_STATUS_FINISH', label: i18n?.t('已完成') },
  { value: 'PAYMENT_DETAIL_STATUS_PROCESSING', label: i18n?.t('处理中') },
  { value: 'PAYMENT_DETAIL_STATUS_FAIL', label: i18n?.t('失败') },
  { value: 'PAYMENT_DETAIL_STATUS_CLOSE', label: i18n?.t('关闭') },
];

// 是否已经开通线上支付
export enum OnlinePayOpenStatus {
  //  未开通
  NOT_OPEN = 'ONLINE_PAY_OPEN_STATUS_NOT_OPEN',
  //  开通中，待填写打款金额
  UNDER_OPEN = 'ONLINE_PAY_OPEN_STATUS_UNDER_OPEN',
  //  已开通
  OPENED = 'ONLINE_PAY_OPEN_STATUS_OPENED',
}

export enum Write_Off_Account {
  MARK_UN_RECEIVE = 'MARK_UN_RECEIVE',
  MARK_RECEIVE = 'MARK_RECEIVE',
  MARK_NONE = 'MARK_NONE',
}
export const Write_Off_Account_Label = {
  [Write_Off_Account.MARK_UN_RECEIVE]: '挂账',
  [Write_Off_Account.MARK_RECEIVE]: '销账',
  [Write_Off_Account.MARK_NONE]: '无挂销账',
};

export enum Amount_Type {
  IS_CHINA_ENUM_YES = 'IS_CHINA_ENUM_YES',
  IS_CHINA_ENUM_NO = 'IS_CHINA_ENUM_NO',
}
export const Amount_Type_Label = {
  [Amount_Type.IS_CHINA_ENUM_YES]: '境内',
  [Amount_Type.IS_CHINA_ENUM_NO]: '境外',
};

export const LogType = {
  OFFLINE_RECORD_LOG_TYPE_CREATE: '新增',
  OFFLINE_RECORD_LOG_TYPE_UPDATE_LIST: '修改-列表',
  OFFLINE_RECORD_LOG_TYPE_UPDATE_CONFIRM: '修改-确认收款',
  OFFLINE_RECORD_LOG_TYPE_UPDATE_CROSS: '修改-销账',
};

// 交易退款状态
export enum MainBusiness {
  PROCESSING = 'TRADE_REFUND_STATUS_PROCESSING',
  COMPLETED = 'TRADE_REFUND_STATUS_COMPLETED',
  FAIL = 'TRADE_REFUND_STATUS_FAIL',
}
export const MainBusinessLabel = {
  [MainBusiness.PROCESSING]: '退款中',
  [MainBusiness.COMPLETED]: '退款完成',
  [MainBusiness.FAIL]: '退款失败',
};

// 退款方式（渠道）
export enum TradeRefundModeType {
  BALANCE = 'REFUND_MODE_TYPE_BALANCE',
  PLATFORM_CREDIT = 'REFUND_MODE_TYPE_PLATFORM_CREDIT',
  SHOP_CREDIT = 'REFUND_MODE_TYPE_SHOP_CREDIT',
  OFFLINE_TRANSFER = 'REFUND_MODE_TYPE_OFFLINE_TRANSFER',
  OFFLINE_BALANCE = 'REFUND_MODE_TYPE_OFFLINE_BALANCE',
  ONLINE_TRANSFER = 'REFUND_MODE_TYPE_ONLINE_TRANSFER',
}
export const TradeRefundModeTypeLabel = {
  [TradeRefundModeType.BALANCE]: '线上钱包',
  [TradeRefundModeType.PLATFORM_CREDIT]: '平台授信',
  [TradeRefundModeType.SHOP_CREDIT]: '商家授信',
  [TradeRefundModeType.OFFLINE_TRANSFER]: '线下转账',
  [TradeRefundModeType.OFFLINE_BALANCE]: '线下钱包',
  [TradeRefundModeType.ONLINE_TRANSFER]: '线上钱包',
};
