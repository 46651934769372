import { getEnumsLabelValue } from '../utils';

export enum BillRoleType {
  /** 箱东 */
  CONTAINER_OWNER = 'ROLE_TYPE_CONTAINER_OWNER',
  /** 运营一 */
  OPERATOR_ONE = 'ROLE_TYPE_OPERATOR_ONE',
  /** 运营二 */
  OPERATOR_TWO = 'ROLE_TYPE_OPERATOR_TWO',
  /** 堆场 */
  YARD = 'ROLE_TYPE_YARD',
}

export enum BillSettlementType {
  /** 现结 */
  ONCE = 'SETTLEMENT_TYPE_ONCE',
  /** 账期 */
  PERIOD = 'SETTLEMENT_TYPE_PERIOD',
}

export const BillSettlementTypeLabel = {
  [BillSettlementType.ONCE]: '现结',
  [BillSettlementType.PERIOD]: '账期',
};

export const billSettlementTypeOptions = getEnumsLabelValue(BillSettlementTypeLabel);

export enum BillSettlementStatus {
  /** 结算中 */
  DOING = 'BILL_SETTLEMENT_STATUS_DOING',
  /** 已经结算 */
  DONE = 'BILL_SETTLEMENT_STATUS_DONE',
  /** 结算失败 */
  FAILED = 'BILL_SETTLEMENT_STATUS_FAILED',
}

export const BillSettlementStatusLabel = {
  [BillSettlementStatus.DOING]: '结算中',
  [BillSettlementStatus.DONE]: '已经结算',
  [BillSettlementStatus.FAILED]: '结算失败',
};

export const ImmediateBillSettlementStatusLabel = {
  [BillSettlementStatus.DOING]: '未结算',
  [BillSettlementStatus.DONE]: '已结算',
};

export const immediateBillSettlementStatusOptions = getEnumsLabelValue(ImmediateBillSettlementStatusLabel);

export enum BillSettlementMode {
  /** 日结 */
  DAY = 'SETTLEMENT_MODE_DAY',
  /** 周结 */
  WEEK = 'SETTLEMENT_MODE_WEEK',
  /** 半月结 */
  HALF_MONTH = 'SETTLEMENT_MODE_HALF_MONTH',
  /** 月结 */
  MONTH = 'SETTLEMENT_MODE_MONTH',
  /** 三月结 */
  QUARTER = 'SETTLEMENT_MODE_QUARTER',
  /** 半年结 */
  HALF_YEAR = 'SETTLEMENT_MODE_HALF_YEAR',
  /** 年结 */
  YEAR = 'SETTLEMENT_MODE_YEAR',
}

export const BillSettlementModeLabel = {
  [BillSettlementMode.DAY]: '日结',
  [BillSettlementMode.WEEK]: '周结',
  [BillSettlementMode.HALF_MONTH]: '半月结',
  [BillSettlementMode.MONTH]: '月结',
  [BillSettlementMode.QUARTER]: '季度结',
  [BillSettlementMode.HALF_YEAR]: '半年结',
  [BillSettlementMode.YEAR]: '年结',
};

export const billSettlementModeOptions = getEnumsLabelValue(BillSettlementModeLabel);

export enum BillStatus {
  /** 待支付，待收款*/
  CREATE = 'BILL_STATUS_CREATE',
  /** 支付待确认，收款待确认 */
  WAIT_CONFIRM = 'BILL_STATUS_WAIT_CONFIRM',
  /** 已支付 */
  DONE = 'BILL_STATUS_DONE',
}

export const BillDisburseStatusLabel = {
  [BillStatus.CREATE]: '待支付',
  [BillStatus.WAIT_CONFIRM]: '支付待确认',
  [BillStatus.DONE]: '已支付',
};

/** 支付状态 */
export const billDisburseStatusOptions = getEnumsLabelValue(BillDisburseStatusLabel);

export const BillReceiveStatusLabel = {
  [BillStatus.CREATE]: '待收款',
  [BillStatus.WAIT_CONFIRM]: '收款待确认',
  [BillStatus.DONE]: '已收款',
};

/** 收款状态 */
export const billReceiveStatusOptions = getEnumsLabelValue(BillReceiveStatusLabel);

export enum BillConfirmStatus {
  /** 待出账 */
  WAIT_CREATE = 'BILL_CONFIRM_STATUS_WAIT_CREATE',
  /** 待审核 */
  CREATE = 'BILL_CONFIRM_STATUS_CREATE',
  /** 待确认 */
  CONFIRM = 'BIlL_CONFIRM_STATUS_CONFIRM',
  /** 已驳回 */
  REJECT = 'BILL_CONFIRM_STATUS_REJECT',
  /** 已确认 */
  CONFIRMED = 'BIlL_CONFIRM_STATUS_CONFIRMED',
}

export const BillConfirmStatusLabel = {
  [BillConfirmStatus.WAIT_CREATE]: '待出账',
  [BillConfirmStatus.CREATE]: '待审核',
  [BillConfirmStatus.CONFIRM]: '待确认',
  [BillConfirmStatus.REJECT]: '已驳回',
  [BillConfirmStatus.CONFIRMED]: '已确认',
};

export const billConfirmStatusOptions = getEnumsLabelValue(BillConfirmStatusLabel);
