// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 到港-运营平台 POST /salesgateway/v1/shipping/common/arrive */
export async function OperationId_salesgateway_ShippingCommonController_arrive(
  body: number[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/arrive', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_arrive',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 作废提还箱单 POST /salesgateway/v1/shipping/common/canclerRef */
export async function OperationId_salesgateway_ShippingCommonController_canclerRef(
  body: API.CancleRefVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/canclerRef', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_canclerRef',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询履约单单是否属于当前登录用户 GET /salesgateway/v1/shipping/common/check-performance */
export async function OperationId_salesgateway_ShippingCommonController_checkPerformanceInfoByNoAndCompanyId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerCheckPerformanceInfoByNoAndCompanyIdParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/check-performance', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ShippingCommonController_checkPerformanceInfoByNoAndCompanyId',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 检查是否签署过合同 POST /salesgateway/v1/shipping/common/checkContract */
export async function OperationId_salesgateway_ShippingCommonController_checkSignedShipContract(
  body: API.ContractVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/checkContract', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_checkSignedShipContract',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询获取城市或者港口选项 GET /salesgateway/v1/shipping/common/city-port-options */
export async function OperationId_salesgateway_ShippingCommonController_listCityPortOption(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerListCityPortOptionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityOrPortOptionDTO[]>('/salesgateway/v1/shipping/common/city-port-options', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_listCityPortOption',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 签署合同 POST /salesgateway/v1/shipping/common/createContract */
export async function OperationId_salesgateway_ShippingCommonController_createContract(
  body: API.ContractVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<string>('/salesgateway/v1/shipping/common/createContract', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_createContract',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 起运-运营平台 POST /salesgateway/v1/shipping/common/departure */
export async function OperationId_salesgateway_ShippingCommonController_departure(
  body: number[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/departure', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_departure',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 提还箱下载 GET /salesgateway/v1/shipping/common/downloadBoxRecord */
export async function OperationId_salesgateway_ShippingCommonController_downloadBoxRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerDownloadBoxRecordParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<string>('/salesgateway/v1/shipping/common/downloadBoxRecord', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_downloadBoxRecord',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 批量导出海运商品 POST /salesgateway/v1/shipping/common/downLoadShippingProduct */
export async function OperationId_salesgateway_ShippingCommonController_downLoadShippingProduct(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/v1/shipping/common/downLoadShippingProduct', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_downLoadShippingProduct',
    },
    ...(rest || {}),
  });
}

/** 申请提箱 POST /salesgateway/v1/shipping/common/extractBoxApply */
export async function OperationId_salesgateway_ShippingCommonController_extractBoxApply(
  body: API.ExtractBoxApplyReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/extractBoxApply', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_extractBoxApply',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询所有箱子 GET /salesgateway/v1/shipping/common/queryAllBoxByPerformanceNo */
export async function OperationId_salesgateway_ShippingCommonController_queryAllBoxByPerformanceNo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryAllBoxByPerformanceNoParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<string[]>('/salesgateway/v1/shipping/common/queryAllBoxByPerformanceNo', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryAllBoxByPerformanceNo',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看所有附件 GET /salesgateway/v1/shipping/common/queryAllDocInfo */
export async function OperationId_salesgateway_ShippingCommonController_queryAllDocInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryAllDocInfoParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DocInfoRes[]>('/salesgateway/v1/shipping/common/queryAllDocInfo', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryAllDocInfo',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看箱货信息 POST /salesgateway/v1/shipping/common/queryBoxLocus */
export async function OperationId_salesgateway_ShippingCommonController_queryBoxLocus(
  body: API.BoxLocusReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoxLocusEventRes[]>('/salesgateway/v1/shipping/common/queryBoxLocus', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryBoxLocus',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看还箱详情 GET /salesgateway/v1/shipping/common/queryDropoffBoxRecordDetail */
export async function OperationId_salesgateway_ShippingCommonController_queryDropoffBoxRecordDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryDropoffBoxRecordDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DropoffDetailVO[]>(
    '/salesgateway/v1/shipping/common/queryDropoffBoxRecordDetail',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ShippingCommonController_queryDropoffBoxRecordDetail',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 根据订单号查看可还箱箱子列表 GET /salesgateway/v1/shipping/common/queryDropoffListDetail */
export async function OperationId_salesgateway_ShippingCommonController_queryDropoffListDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryDropoffListDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PickupDetailVO[]>('/salesgateway/v1/shipping/common/queryDropoffListDetail', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryDropoffListDetail',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看提箱记录 GET /salesgateway/v1/shipping/common/queryExtractBoxRecord */
export async function OperationId_salesgateway_ShippingCommonController_queryExtractBoxRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryExtractBoxRecordParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ExtractBoxDetailRes[]>(
    '/salesgateway/v1/shipping/common/queryExtractBoxRecord',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_queryExtractBoxRecord',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查看提箱详情 GET /salesgateway/v1/shipping/common/queryExtractBoxRecordDetail */
export async function OperationId_salesgateway_ShippingCommonController_queryExtractBoxRecordDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryExtractBoxRecordDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PickupDetailVO[]>(
    '/salesgateway/v1/shipping/common/queryExtractBoxRecordDetail',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ShippingCommonController_queryExtractBoxRecordDetail',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 通过履约单号查询履约单信息 GET /salesgateway/v1/shipping/common/queryOrderByPerformanceNo */
export async function OperationId_salesgateway_ShippingCommonController_queryOrderByPerformanceNo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryOrderByPerformanceNoParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceInfoRes>(
    '/salesgateway/v1/shipping/common/queryOrderByPerformanceNo',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_queryOrderByPerformanceNo',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查询履约事件 GET /salesgateway/v1/shipping/common/queryPerformanceAction */
export async function OperationId_salesgateway_ShippingCommonController_queryPerformanceAction(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryPerformanceActionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceActionRes[]>(
    '/salesgateway/v1/shipping/common/queryPerformanceAction',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_queryPerformanceAction',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查询履约 GET /salesgateway/v1/shipping/common/queryPerformanceBill */
export async function OperationId_salesgateway_ShippingCommonController_queryPerformanceBill(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryPerformanceBillParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceBillRes>('/salesgateway/v1/shipping/common/queryPerformanceBill', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryPerformanceBill',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看提单样本/提单 GET /salesgateway/v1/shipping/common/queryPerformanceBL */
export async function OperationId_salesgateway_ShippingCommonController_queryPerformanceBL(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryPerformanceBLParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceBLInfoReq>('/salesgateway/v1/shipping/common/queryPerformanceBL', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryPerformanceBL',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询履约费用项 GET /salesgateway/v1/shipping/common/queryPerformanceFeeDetail */
export async function OperationId_salesgateway_ShippingCommonController_queryPerformanceFeeDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryPerformanceFeeDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceFeeDetailRes>(
    '/salesgateway/v1/shipping/common/queryPerformanceFeeDetail',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_queryPerformanceFeeDetail',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查看补料 GET /salesgateway/v1/shipping/common/queryPerformanceSI */
export async function OperationId_salesgateway_ShippingCommonController_queryPerformanceSI(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryPerformanceSIParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceSIRes>('/salesgateway/v1/shipping/common/queryPerformanceSI', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryPerformanceSI',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看SO GET /salesgateway/v1/shipping/common/queryPerformanceSO */
export async function OperationId_salesgateway_ShippingCommonController_queryPerformanceSO(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryPerformanceSOParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceSORes>('/salesgateway/v1/shipping/common/queryPerformanceSO', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryPerformanceSO',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看还箱记录 GET /salesgateway/v1/shipping/common/queryRepayBoxRecord */
export async function OperationId_salesgateway_ShippingCommonController_queryRepayBoxRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerQueryRepayBoxRecordParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RepayBoxDetailRes[]>('/salesgateway/v1/shipping/common/queryRepayBoxRecord', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_queryRepayBoxRecord',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 申请还箱 POST /salesgateway/v1/shipping/common/repayBoxApply */
export async function OperationId_salesgateway_ShippingCommonController_repayBoxApply(
  body: API.RepayBoxApplyReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/repayBoxApply', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_repayBoxApply',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询海运订单列表_买家 POST /salesgateway/v1/shipping/common/shippingBuyerOrderList */
export async function OperationId_salesgateway_ShippingCommonController_shippingBuyerOrderList(
  body: API.ShippingOrderListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipOrderListOuterRes>(
    '/salesgateway/v1/shipping/common/shippingBuyerOrderList',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_shippingBuyerOrderList',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询海运订单列表banner POST /salesgateway/v1/shipping/common/shippingOrderBanner */
export async function OperationId_salesgateway_ShippingCommonController_shippingOrderBanner(
  body: API.ShipOrderBannerReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipOrderBannerResOut>(
    '/salesgateway/v1/shipping/common/shippingOrderBanner',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_shippingOrderBanner',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询海运订单列表banner_买家 POST /salesgateway/v1/shipping/common/shippingOrderBuyerBanner */
export async function OperationId_salesgateway_ShippingCommonController_shippingOrderBuyerBanner(
  body: API.ShipOrderBannerReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipOrderBannerResOut>(
    '/salesgateway/v1/shipping/common/shippingOrderBuyerBanner',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_shippingOrderBuyerBanner',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询海运订单列表 POST /salesgateway/v1/shipping/common/shippingOrderList */
export async function OperationId_salesgateway_ShippingCommonController_shippingOrderList(
  body: API.ShippingOrderListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipOrderListOuterRes>('/salesgateway/v1/shipping/common/shippingOrderList', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_shippingOrderList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询海运订单列表banner_卖家 POST /salesgateway/v1/shipping/common/shippingOrderSellerBanner */
export async function OperationId_salesgateway_ShippingCommonController_shippingOrderSellerBanner(
  body: API.ShipOrderBannerReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipOrderBannerResOut>(
    '/salesgateway/v1/shipping/common/shippingOrderSellerBanner',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_shippingOrderSellerBanner',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询海运订单列表_卖家 POST /salesgateway/v1/shipping/common/shippingSellerOrderList */
export async function OperationId_salesgateway_ShippingCommonController_shippingSellerOrderList(
  body: API.ShippingOrderListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipOrderListOuterRes>(
    '/salesgateway/v1/shipping/common/shippingSellerOrderList',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingCommonController_shippingSellerOrderList',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 上传附件 POST /salesgateway/v1/shipping/common/uploadDocInfo */
export async function OperationId_salesgateway_ShippingCommonController_uploadDocInfo(
  body: API.UploadDocInfoReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/common/uploadDocInfo', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_uploadDocInfo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 批量上传海运商品 POST /salesgateway/v1/shipping/common/uploadShippingProduct */
export async function OperationId_salesgateway_ShippingCommonController_uploadShippingProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingCommonControllerUploadShippingProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<string>('/salesgateway/v1/shipping/common/uploadShippingProduct', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingCommonController_uploadShippingProduct',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
