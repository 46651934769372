// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询附加费 GET /sinotransbooking/v1/operation/surcharges */
export async function OperationId_sinotransbooking_OperationSurchargeController_pageSurcharge(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSurchargeControllerPageSurchargeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageSurchargeVO>('/sinotransbooking/v1/operation/surcharges', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationSurchargeController_pageSurcharge',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取附加费详情 GET /sinotransbooking/v1/operation/surcharges/${param0} */
export async function OperationId_sinotransbooking_OperationSurchargeController_getSurcharge(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationSurchargeControllerGetSurchargeParams,
  options?: { [key: string]: any },
) {
  const { surchargeId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SurchargeVO>(`/sinotransbooking/v1/operation/surcharges/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationSurchargeController_getSurcharge',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}
