// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 创建需求单 POST /logisticssolution/v1/buyer/demand-orders */
export async function OperationId_logisticssolution_BuyerDemandOrderController_createDemandOrder(
  body: API.DemandOrderCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/logisticssolution/v1/buyer/demand-orders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_BuyerDemandOrderController_createDemandOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
