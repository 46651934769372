// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询堆场城市（不分组） GET /salesgateway/plannedOrder/cities */
export async function OperationId_salesgateway_PlannedOrderController_listYardCitiesWithoutGrouping(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerListYardCitiesWithoutGroupingParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.YardCityResponse[]>('/salesgateway/plannedOrder/cities', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_listYardCitiesWithoutGrouping',
    },
    params: {
      ...params,
      yardAddressQuery: undefined,
      ...params['yardAddressQuery'],
    },
    ...(rest || {}),
  });
}

/** 查询堆场城市及箱量 GET /salesgateway/plannedOrder/cityWithContainerNum */
export async function OperationId_salesgateway_PlannedOrderController_listYardCityWithContainerNum(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerListYardCityWithContainerNumParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.YardCityWithContainerNumResponse[]>(
    '/salesgateway/plannedOrder/cityWithContainerNum',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PlannedOrderController_listYardCityWithContainerNum',
      },
      params: {
        ...params,
        yardCityWithContainerNumQuery: undefined,
        ...params['yardCityWithContainerNumQuery'],
      },
      ...(rest || {}),
    },
  );
}

/** 查询堆场国家及箱量 GET /salesgateway/plannedOrder/countryWithContainerNum */
export async function OperationId_salesgateway_PlannedOrderController_listYardCountryWithContainerNum(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerListYardCountryWithContainerNumParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.YardCountryWithContainerNumResponse[]>(
    '/salesgateway/plannedOrder/countryWithContainerNum',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_PlannedOrderController_listYardCountryWithContainerNum',
      },
      params: {
        ...params,
        yardCountryWithContainerNumQuery: undefined,
        ...params['yardCountryWithContainerNumQuery'],
      },
      ...(rest || {}),
    },
  );
}

/** 提箱计划单关闭 POST /salesgateway/plannedOrder/pickup/${param0}/close */
export async function OperationId_salesgateway_PlannedOrderController_closePickUpPlannedOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerClosePickUpPlannedOrderParams,
  options?: { [key: string]: any },
) {
  const { pickUpPlannedOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/plannedOrder/pickup/${param0}/close`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_closePickUpPlannedOrder',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 提箱单下箱号明细 GET /salesgateway/plannedOrder/pickup/${param0}/containers */
export async function OperationId_salesgateway_PlannedOrderController_getPickUpOrderContainers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetPickUpOrderContainersParams,
  options?: { [key: string]: any },
) {
  const { pickUpOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ContainersResVO>(`/salesgateway/plannedOrder/pickup/${param0}/containers`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_getPickUpOrderContainers',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 提箱计划单下提箱单明细 GET /salesgateway/plannedOrder/pickup/${param0}/details */
export async function OperationId_salesgateway_PlannedOrderController_getPickUpPlannedOrderDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetPickUpPlannedOrderDetailsParams,
  options?: { [key: string]: any },
) {
  const { pickUpPlannedOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickUpOrderListResVO>(`/salesgateway/plannedOrder/pickup/${param0}/details`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_getPickUpPlannedOrderDetails',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 提箱计划单详情 GET /salesgateway/plannedOrder/pickup/${param0}/info */
export async function OperationId_salesgateway_PlannedOrderController_getPickUpPlannedOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetPickUpPlannedOrderParams,
  options?: { [key: string]: any },
) {
  const { pickUpPlannedOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickUpPlannedOrderVO>(`/salesgateway/plannedOrder/pickup/${param0}/info`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_getPickUpPlannedOrder',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取提箱最大可申请数量 POST /salesgateway/plannedOrder/pickup/availableNum */
export async function OperationId_salesgateway_PlannedOrderController_getPickUpApplyAvailableNum(
  body: API.GetMaxPickUpNumReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetPickUpApplyAvailableNum>('/salesgateway/plannedOrder/pickup/availableNum', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_getPickUpApplyAvailableNum',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 提箱计划单创建 POST /salesgateway/plannedOrder/pickup/create */
export async function OperationId_salesgateway_PlannedOrderController_createPickUpPlannedOrder(
  body: API.CreatePickUpPlannedOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/plannedOrder/pickup/create', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_createPickUpPlannedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 提箱计划单明细凭证 POST /salesgateway/plannedOrder/pickup/details/${param0}/preview */
export async function OperationId_salesgateway_PlannedOrderController_getPickUpPlannedOrderPreviewCertificate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetPickUpPlannedOrderPreviewCertificateParams,
  options?: { [key: string]: any },
) {
  const { detailsId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PreviewCertificateResVO>(
    `/salesgateway/plannedOrder/pickup/details/${param0}/preview`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_PlannedOrderController_getPickUpPlannedOrderPreviewCertificate',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 提箱计划单列表 POST /salesgateway/plannedOrder/pickup/list */
export async function OperationId_salesgateway_PlannedOrderController_searchPickUpPlannedOrder(
  body: API.QueryPickUpPlannedOrderListReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PickUpPlannedOrderListResVO>('/salesgateway/plannedOrder/pickup/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_searchPickUpPlannedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 提箱计划单编辑 POST /salesgateway/plannedOrder/pickup/update */
export async function OperationId_salesgateway_PlannedOrderController_updatePickUpPlannedOrder(
  body: API.UpdatePickUpPlannedOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/plannedOrder/pickup/update', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_updatePickUpPlannedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取还箱最大可申请数量 GET /salesgateway/plannedOrder/return/${param0}/availableNum */
export async function OperationId_salesgateway_PlannedOrderController_getReturnApplyAvailableNum(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetReturnApplyAvailableNumParams,
  options?: { [key: string]: any },
) {
  const { returnPlannedOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetReturnApplyAvailableNum>(
    `/salesgateway/plannedOrder/return/${param0}/availableNum`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PlannedOrderController_getReturnApplyAvailableNum',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 还箱计划单关闭 POST /salesgateway/plannedOrder/return/${param0}/close */
export async function OperationId_salesgateway_PlannedOrderController_closeReturnPlannedOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerCloseReturnPlannedOrderParams,
  options?: { [key: string]: any },
) {
  const { returnPlannedOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/plannedOrder/return/${param0}/close`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_closeReturnPlannedOrder',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 还箱单下箱号明细 GET /salesgateway/plannedOrder/return/${param0}/containers */
export async function OperationId_salesgateway_PlannedOrderController_getReturnOrderContainers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetReturnOrderContainersParams,
  options?: { [key: string]: any },
) {
  const { returnOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ContainersResVO>(`/salesgateway/plannedOrder/return/${param0}/containers`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_getReturnOrderContainers',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 还箱计划单下还箱单明细 GET /salesgateway/plannedOrder/return/${param0}/details */
export async function OperationId_salesgateway_PlannedOrderController_getReturnPlannedOrderDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetReturnPlannedOrderDetailsParams,
  options?: { [key: string]: any },
) {
  const { returnPlannedOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ReturnRelationListResVO>(
    `/salesgateway/plannedOrder/return/${param0}/details`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PlannedOrderController_getReturnPlannedOrderDetails',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 还箱计划单 GET /salesgateway/plannedOrder/return/${param0}/info */
export async function OperationId_salesgateway_PlannedOrderController_getReturnPlannedOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetReturnPlannedOrderParams,
  options?: { [key: string]: any },
) {
  const { returnPlannedOrderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ReturnPlannedOrderVO>(`/salesgateway/plannedOrder/return/${param0}/info`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_getReturnPlannedOrder',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 还箱计划单创建 POST /salesgateway/plannedOrder/return/create */
export async function OperationId_salesgateway_PlannedOrderController_createReturnPlannedOrder(
  body: API.CreateReturnPlannedOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ReturnPlannedOrderVO>('/salesgateway/plannedOrder/return/create', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_createReturnPlannedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 还箱计划单明细凭证 POST /salesgateway/plannedOrder/return/details/${param0}/preview */
export async function OperationId_salesgateway_PlannedOrderController_getReturnPlannedOrderPreviewCertificate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPlannedOrderControllerGetReturnPlannedOrderPreviewCertificateParams,
  options?: { [key: string]: any },
) {
  const { detailsId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PreviewCertificateResVO>(
    `/salesgateway/plannedOrder/return/details/${param0}/preview`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_PlannedOrderController_getReturnPlannedOrderPreviewCertificate',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 还箱计划单列表 POST /salesgateway/plannedOrder/return/list */
export async function OperationId_salesgateway_PlannedOrderController_searchReturnPlannedOrder(
  body: API.QueryReturnPlannedOrderListReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ReturnPlannedOrderListResVO>('/salesgateway/plannedOrder/return/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_searchReturnPlannedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 还箱计划单编辑 POST /salesgateway/plannedOrder/return/update */
export async function OperationId_salesgateway_PlannedOrderController_updateReturnPlannedOrder(
  body: API.UpdateReturnPlannedOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/plannedOrder/return/update', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_updateReturnPlannedOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 还箱计划关联信息创建 POST /salesgateway/plannedOrder/returnRelation/create */
export async function OperationId_salesgateway_PlannedOrderController_createReturnOrder(
  body: API.CreateReturnPlannedRelationReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/plannedOrder/returnRelation/create', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PlannedOrderController_createReturnOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
