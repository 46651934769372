// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询售后申请详情 POST /salesgateway/aftersale/api/afterSaleOrderDetail */
export async function OperationId_salesgateway_AfterSaleController_getAfterSaleOrder(
  body: API.QueryAfterSaleOrderDetailReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryAfterSaleOrderDetailResponseVo>(
    '/salesgateway/aftersale/api/afterSaleOrderDetail',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_getAfterSaleOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 售后申请 POST /salesgateway/aftersale/api/afterSaleOrderReq */
export async function OperationId_salesgateway_AfterSaleController_toAfterSaleOrderReq(
  body: API.AfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AfterSaleOrderResponseVo>('/salesgateway/aftersale/api/afterSaleOrderReq', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AfterSaleController_toAfterSaleOrderReq',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 取消售后 POST /salesgateway/aftersale/api/buyer/cancelAfterSaleOrder */
export async function OperationId_salesgateway_AfterSaleController_cancelAfterSaleOrder(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/buyer/cancelAfterSaleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_cancelAfterSaleOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取取消售后申请列表 POST /salesgateway/aftersale/api/buyer/cancelAfterSaleOrderApply */
export async function OperationId_salesgateway_AfterSaleController_cancelAfterSaleOrderApply(
  body: API.AfterSaleReasonRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AfterSaleReasonResponseVo>(
    '/salesgateway/aftersale/api/buyer/cancelAfterSaleOrderApply',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_cancelAfterSaleOrderApply',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 确认金额并退款 POST /salesgateway/aftersale/api/buyer/confirmAfterSaleReqAmount */
export async function OperationId_salesgateway_AfterSaleController_confirmAfterSaleReqAmount(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/buyer/confirmAfterSaleReqAmount',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_confirmAfterSaleReqAmount',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 退款到账确认 POST /salesgateway/aftersale/api/buyer/confirmRefundSuccess */
export async function OperationId_salesgateway_AfterSaleController_confirmRefundSuccess(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/buyer/confirmRefundSuccess',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_confirmRefundSuccess',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取售后手续费 POST /salesgateway/aftersale/api/buyer/getCharges */
export async function OperationId_salesgateway_AfterSaleController_getAfterSaleCharges(
  body: API.AfterSaleChargesRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AfterSaleChargesResponse>('/salesgateway/aftersale/api/buyer/getCharges', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AfterSaleController_getAfterSaleCharges',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取售后线路列表 POST /salesgateway/aftersale/api/buyer/getPerformanceInfo */
export async function OperationId_salesgateway_AfterSaleController_getPerformanceInfo4AfterSale(
  body: API.PerformancelInfoRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetPerformanceInfo4AfterSaleResponse>(
    '/salesgateway/aftersale/api/buyer/getPerformanceInfo',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_getPerformanceInfo4AfterSale',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询售后单列表 POST /salesgateway/aftersale/api/buyer/searchAfterSaleOrder */
export async function OperationId_salesgateway_AfterSaleController_searchAfterSaleOrder4Buyer(
  body: API.SearchAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/buyer/searchAfterSaleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_searchAfterSaleOrder4Buyer',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 提交售后申请 POST /salesgateway/aftersale/api/commitAfterSaleOrder */
export async function OperationId_salesgateway_AfterSaleController_commitAfterSaleOrder(
  body: API.CommitAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CommitAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/commitAfterSaleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_commitAfterSaleOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家审核售后单查询 POST /salesgateway/aftersale/api/seller/afterSaleApplyInfo */
export async function OperationId_salesgateway_AfterSaleController_afterSaleApplyInfo(
  body: API.QueryAfterSaleOrderDetailReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AfterSaleApplyInfoResponseVo>(
    '/salesgateway/aftersale/api/seller/afterSaleApplyInfo',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_afterSaleApplyInfo',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家审核 POST /salesgateway/aftersale/api/seller/approveAfterSaleReq */
export async function OperationId_salesgateway_AfterSaleController_approveAfterSaleReq(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/seller/approveAfterSaleReq',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_approveAfterSaleReq',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 审核驳回 POST /salesgateway/aftersale/api/seller/refuseAfterSaleReq */
export async function OperationId_salesgateway_AfterSaleController_refuseAfterSaleReq(
  body: API.UpdateAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UpdateAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/seller/refuseAfterSaleReq',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_refuseAfterSaleReq',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询售后单列表 POST /salesgateway/aftersale/api/seller/searchAfterSaleOrder */
export async function OperationId_salesgateway_AfterSaleController_searchAfterSaleOrder4Seller(
  body: API.SearchAfterSaleOrderRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchAfterSaleOrderResponseVo>(
    '/salesgateway/aftersale/api/seller/searchAfterSaleOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AfterSaleController_searchAfterSaleOrder4Seller',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
