import { Table, Form, Tooltip } from 'antd';
import type { TablePaginationConfig, TableColumnType } from 'antd';
import { useEffect, useMemo, useRef, useContext, useState, useImperativeHandle } from 'react';
import { useMemoizedFn, useRequest, useControllableValue } from 'ahooks';
import type { ProTableProps, ProTableContextState, ProTableAction } from './type';
import { TableContext } from './context';
import { ProSearchForm } from '../search-form';
import { ProPagination } from '../pro-pagination';
import ProOperation from './components/operation';
import styles from './index.module.less';
import { isEqual } from 'lodash';
import { transform } from './utils';
import type { ProSearchFormProps } from '../search-form/interface';
import descTypes from '../field-types/descTypes';
import { useComponentTrans } from '../config';

function TableComponent<DataType extends object, ParamsType extends Record<string, any>>(props: Partial<ProTableProps<DataType, ParamsType>>) {
  const { request, pagination = {}, title, height, toolbar, rowSelection, expandIconColumnKey, formProps, ...restProps } = props;
  const { state, form, setState, server, selectRowsRef, actionRef } = useContext(TableContext);
  const trans = useComponentTrans();
  const onPageChange = useMemoizedFn((curr: number, size: number) => {
    let page = curr;
    if (Number(state.pageParams?.size) !== Number(size)) {
      page = 1;
    }
    setState({ pageParams: { page, size } });
    setTimeout(() => {
      actionRef?.current.reload();
    }, 0);
  });
  const onShowSizeChange = useMemoizedFn((curr, size: number) => {
    setState({ pageParams: { page: 1, size } });
    setTimeout(() => {
      actionRef?.current.reload();
    }, 0);
  });
  const tablePagination = useMemo<TablePaginationConfig>(() => {
    return {
      onChange: onPageChange,
      onShowSizeChange,
      current: state.pageParams?.page || server.data.pagination,
      page: state.pageParams?.page,
      pageSize: state.pageParams?.size,
      total: server.data?.total || 0,
      ...pagination,
      ...server.data?.pagination,
    };
  }, [onPageChange, onShowSizeChange, pagination, server.data, state.pageParams]);

  const tableColumns = useMemo<TableColumnType<DataType>[]>(() => {
    const arr: TableColumnType<DataType>[] = [];
    state.columns?.forEach((column) => {
      const { hideInTable, ellipsis, fieldProps, shouldCellUpdate, render, operations, renderText, type, ...rest } = column;
      //仅在search表单中显示
      if (hideInTable) {
        return;
      }
      let targetEllipsis: any = { showTitle: false };
      if (typeof ellipsis === 'boolean') {
        if (!ellipsis) {
          targetEllipsis = false;
        }
      } else if (typeof ellipsis === 'object') {
        Object.assign(targetEllipsis, ellipsis);
      }
      const target = {
        ...rest,
        ellipsis: targetEllipsis,
        shouldCellUpdate: (record: DataType, prevRecord: DataType) => {
          if (shouldCellUpdate) {
            return shouldCellUpdate.call(null, record, prevRecord);
          }
          return !isEqual(record, prevRecord) || fieldProps?.options !== undefined || !!render;
        },
        render: (...args: any) => {
          if (operations) {
            return render ? render.apply(null, args) : <ProOperation<DataType> record={args[1]} operations={operations} />;
          }
          const [value, record, index] = args;
          let target = null;
          if (render) {
            target = render.apply(null, args);
          } else if (renderText) {
            target = renderText.apply(null, args);
          } else if (type && descTypes[type]) {
            const itemProps = {
              value,
              ...fieldProps,
            };
            target = descTypes[type](itemProps, record, index);
          } else {
            // 默认render函数返回自身或‘-’
            target = value ? value : '-';
          }

          if (['string', 'number'].includes(typeof target)) {
            return !!targetEllipsis ? (
              <Tooltip placement="topLeft" title={target}>
                {target}
              </Tooltip>
            ) : (
              target
            );
          }
          return target;
        },
      } as any;
      arr.push(target);
    });
    if (expandIconColumnKey) {
      const insertIndex = arr.findIndex((item) => item.dataIndex === expandIconColumnKey);
      arr.splice(insertIndex, 0, Table.EXPAND_COLUMN);
    }
    return arr;
  }, [expandIconColumnKey, state.columns]);

  const tableRowSelection = useMemo(() => {
    return rowSelection
      ? {
          ...(rowSelection || {}),
          selectedRowKeys: state.selectRowKeys,
          columnWidth: 36,
          fixed: 'left',
          onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[], info: any) => {
            selectRowsRef!.current = selectedRows;
            setState({
              selectRowKeys: selectedRowKeys,
            });
            if (rowSelection && rowSelection?.onChange) {
              rowSelection?.onChange(selectedRowKeys, selectedRows, info);
            }
          },
        }
      : (false as any);
  }, [rowSelection, state.selectRowKeys, selectRowsRef, setState]);
  const memoTableProps = useMemo(() => {
    return {
      rowKey: 'id',
      pagination: false as false,
      dataSource: server.data?.data,
      loading: server.loading,
      rowSelection: tableRowSelection,
      ...restProps,
      columns: tableColumns,
    };
  }, [restProps, server.data?.data, server.loading, tableColumns, tableRowSelection]);

  const memoTableRender = useMemo(() => {
    return <Table {...memoTableProps} />;
  }, [memoTableProps]);
  const searchFormProps = useMemo<ProSearchFormProps<ParamsType>>(() => {
    return {
      form,
      onSearch: () => actionRef?.current.resetPageAndLoad(),
      onReset: () => actionRef?.current.resetAndLoad(),
      loading: server.loading,
      options: (state.columns ?? []).flatMap((item) => {
        if (item.search) {
          return [
            {
              ...item,
              type: item.type,
              key: item.key || item.dataIndex,
              label: item.title,
            } as any,
          ];
        }
        return [];
      }),
      ...formProps,
    };
  }, [actionRef, form, formProps, server.loading, state.columns]);

  const toolbarRender = useMemo(() => {
    if (toolbar && !server.data?.toolbarData) {
      return <div className={styles['pro-table-toolbar']}>{toolbar(state.selectRowKeys ?? [], selectRowsRef)}</div>;
    }
    if (toolbar && server.data?.toolbarData) {
      return <div className={styles['pro-table-toolbar']}>{toolbar(server.data?.toolbarData)}</div>;
    }
    return null;
  }, [selectRowsRef, state.selectRowKeys, toolbar, server.data]);

  //传入搜索表单配置或columns中有配置表单fields时显示搜索表单
  const showSearch = useMemo(() => {
    return (state.columns ?? []).some((item) => item.search) || formProps;
  }, [state.columns, formProps]);
  return (
    <div className={styles['pro-table']} style={{ height }}>
      <div className={styles['pro-table-title']}>{title}</div>
      {showSearch ? <ProSearchForm<ParamsType> {...searchFormProps} /> : null}
      {toolbarRender}
      <div className={styles['pro-table-wrap']}>{memoTableRender}</div>
      {pagination && <ProPagination {...tablePagination} />}
    </div>
  );
}

function ProTable<DataType extends Record<string, any>, ParamsType extends Record<string, any>>(props: ProTableProps<DataType, ParamsType>) {
  const { action, columns, manual, request, formProps, onReset, ...restProps } = props;
  const propsRef = useRef<ProTableProps<DataType, ParamsType>>(props);
  const selectRowsRef = useRef<DataType[]>([]);
  const curAction = useRef<ProTableAction>();
  const [currform] = Form.useForm<ParamsType>();
  const [form] = useControllableValue(formProps, {
    defaultValue: currform,
    valuePropName: 'form',
  });
  const actionRef = action ?? curAction;
  const [state, setState] = useState(() => {
    const obj: Record<string, any> = {};
    if (formProps?.initialValues) {
      Object.assign(obj, formProps.initialValues);
    }
    columns?.forEach((item) => {
      if (item.formItemProps?.initialValue) {
        if (Array.isArray(item.dataIndex)) {
          item.dataIndex.reduce((pre, next, i) => {
            if (i === (item.dataIndex as any).length - 1) {
              pre[next] = item.formItemProps?.initialValue;
              return pre;
            }
            let temp = {};
            pre[next] = temp;
            return temp;
          }, obj);
        } else if (typeof item.dataIndex === 'string') {
          obj[item.dataIndex] = item.formItemProps.initialValue;
        }
      }
    });
    return {
      defaultParams: {
        ...obj,
        page: 1,
        size: props.pagination ? props.pagination?.pageSize ?? 10 : 10,
      },
      columns: columns,
      selectRowKeys: props.rowSelection?.selectedRowKeys,
      pageParams: {
        page: 1,
        size: props.pagination ? props.pagination?.pageSize ?? 10 : 10,
      },
    } as ProTableContextState<DataType, ParamsType>;
  });
  const asyncRef = useRef<ProTableContextState<DataType, ParamsType>>(state);
  const setStateFn = useMemoizedFn((newState) => {
    const target = {
      ...state,
      ...newState,
    };
    asyncRef.current = target;
    setState(target);
  });
  const asyncColumns = useMemoizedFn(() => {
    const columns = propsRef.current.columns?.map((item) => {
      const oldColum = state.columns?.find((it) => isEqual(item.dataIndex, it.dataIndex));
      return {
        ...oldColum,
        search: item.search,
        formItemProps: item.formItemProps,
        fieldProps: item.fieldProps,
      };
    });
    setStateFn({
      columns,
    });
  });

  const server = useRequest(
    () => {
      if (!props.rowSelection?.preserveSelectedRowKeys) {
        selectRowsRef!.current = [];
        setStateFn({
          selectRowKeys: [],
        });
      }

      if (props.dataSource) {
        return Promise.resolve({
          data: props.dataSource,
          total: props.pagination ? props.pagination.total : props.dataSource.length,
        });
      } else if (props.request) {
        return props.request(
          transform(
            {
              ...asyncRef!.current.defaultParams,
              ...asyncRef!.current.pageParams,
              ...form!.getFieldsValue(),
            },
            columns
          )
        );
      } else {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    },
    {
      manual: manual ?? false,
    }
  );
  useImperativeHandle(actionRef, () => ({
    form,
    reload: () => server.refresh(),
    resetAndLoad: () => {
      form!.resetFields();
      setStateFn({
        selectRowKeys: [],
        pageParams: { page: 1, size: state.defaultParams!.size },
      });
      selectRowsRef!.current = [];
      onReset && onReset();
      setTimeout(() => {
        server.run();
      }, 0);
    },
    resetPageAndLoad: () => {
      setStateFn({
        selectRowKeys: [],
        pageParams: { page: 1, size: state.defaultParams!.size },
      });
      selectRowsRef!.current = [];
      onReset && onReset();
      setTimeout(() => {
        server.run();
      }, 0);
    },
  }));
  useEffect(() => {
    asyncColumns();
  }, [columns, asyncColumns]);

  useEffect(() => {
    setStateFn({
      selectRowKeys: props.rowSelection?.selectedRowKeys,
    });
  }, [setStateFn, props.rowSelection?.selectedRowKeys]);
  useEffect(() => {
    propsRef.current = props;
  }, [props]);
  return (
    <TableContext.Provider
      value={{
        props: propsRef,
        state,
        setState: setStateFn,
        asyncRef,
        form,
        server,
        selectRowsRef,
        actionRef: actionRef as any,
      }}
    >
      <TableComponent {...restProps} formProps={formProps} />
    </TableContext.Provider>
  );
}

export default ProTable;
