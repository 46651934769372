import { Select as AntdSelect } from 'antd';
import type { SelectProps } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
export function Select(props: SelectProps) {
  return (
    <AntdSelect
      allowClear={true}
      style={{ width: '100%' }}
      suffixIcon={<CaretDownOutlined style={{ color: '#646566', pointerEvents: 'none' }} />}
      {...props}
    ></AntdSelect>
  );
}
