// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 企业邀请员工 POST /salesgateway/v1/account/enterprise/invitation */
export async function OperationId_salesgateway_AccountEnterpriseController_invitedAccount(
  body: API.InvitedAccountRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/v1/account/enterprise/invitation', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountEnterpriseController_invitedAccount',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 检查邀请用户是否已在企业中存在 GET /salesgateway/v1/account/enterprise/invitation/check/${param0} */
export async function OperationId_salesgateway_AccountEnterpriseController_checkIsExist(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAccountEnterpriseControllerCheckIsExistParams,
  options?: { [key: string]: any },
) {
  const { identity: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.InvitationAccountCheckResponse>(
    `/salesgateway/v1/account/enterprise/invitation/check/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_AccountEnterpriseController_checkIsExist',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 用户通过邀请码加入企业 POST /salesgateway/v1/account/enterprise/invitation/receive */
export async function OperationId_salesgateway_AccountEnterpriseController_receiveInvitation(
  body: API.ReceiveInvitationRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/v1/account/enterprise/invitation/receive', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AccountEnterpriseController_receiveInvitation',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
