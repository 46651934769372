import dayjs from 'dayjs';
import { ProductCodeMap } from '../enums/product';

/**
 * 商品code到自定义property的映射使用上的便捷性，不需要通过code去获取对应字段信息
 */
type CodeType = {
  propertyId: string;
  propertyCode: string;
  propertyName: string;
};
/** 商品属性描述对象 */
type ProductFieldProperty = {
  propertyId: number;
  code: string;
  nameZh: string;
  nameEn: string;
  enumId: number;
  value: string;
  type: any;
  useType: any;
  alias: string;
  displayName: boolean;
};

export const mapCodeToProperty: <MapType extends Record<string, CodeType> = {}>(
  codeMap: MapType
) => <ProductField extends Record<string, any> = ProductFieldProperty>(
  properties: ProductField[],
  codeName?: string | ((field: ProductField, codeObj: CodeType) => boolean)
) => {
  [key in keyof MapType]?: ProductField;
} =
  (codeMap) =>
  (properties, codeName = 'code') => {
    let b = codeName as Function;
    if (typeof codeName === 'string') {
      b = (property: any, codeObj: any) => property[codeName as string] === codeObj.propertyCode;
    }
    return Object.entries(codeMap).reduce(
      (all, [property, codeObj]) => ({
        ...all,
        [property]: properties.find((p) => b(p, codeObj)),
      }),
      {} as any
    );
  };

/**
 * 商品code到自定义property的映射使用上的便捷性，不需要通过code去获取对应字段信息
 * eg: 租箱类型code为de7a3c，自定义属性名为rentBoxType
 * // 第二个参数表示后台返回的字段描述对象的code名字，默认code
 * transCodeToProperty([{code: 'de7a3c', value: 'test'}], 'code')
 * => {rentBoxType: {code: 'de7a3c', value: 'test'}} rentBox为自定义属性名称
 */
export const transCodeToProperty = mapCodeToProperty(ProductCodeMap);

type ProductKeyType = keyof typeof ProductCodeMap;

/** 商品属性反转, {key: value}转properties */
export const transFieldToProperty = (target: Record<string, any>, isSearch = false, addEmpty = false) => {
  const arr = [];
  const keys = Object.keys(ProductCodeMap);
  for (let key in target) {
    if (keys.includes(key)) {
      const propertyValue = target[key];
      if (propertyValue || addEmpty) {
        let obj = ProductCodeMap[key as ProductKeyType];
        let value = propertyValue?.value ?? propertyValue;
        // 特殊处理港口搜索
        if (isSearch) {
          const type = propertyValue.type ?? propertyValue?.value?.type;
          if (key === 'startPort') {
            if (type === 'CITY' || type === 1) {
              obj = ProductCodeMap['startPortCity'];
            }
            value = value?.id ?? value;
          } else if (key === 'purposePort') {
            if (type === 'CITY' || type === 1) {
              obj = ProductCodeMap['purposePortCity'];
            }
            value = value?.id ?? value;
          }
        }
        if (isSearch && Array.isArray(value)) {
          let [start, end] = value;
          if (value.every((it) => it instanceof dayjs)) {
            start = dayjs(start).startOf('date').toISOString();
            end = dayjs(end).endOf('date').toISOString();
          }
          arr.push(
            {
              propertyCode: obj.propertyCode,
              propertyValue: [start],
              propertyEnumId: obj.propertyId,
              expr: 'ge',
            },
            {
              propertyCode: obj.propertyCode,
              propertyValue: [end],
              propertyEnumId: obj.propertyId,
              expr: 'le',
            }
          );
        } else if (key === 'voyage') {
          arr.push({
            propertyCode: obj.propertyCode,
            propertyValue: isSearch ? [value] : value,
            propertyEnumId: obj.propertyId,
            expr: isSearch ? 'like' : undefined,
          });
        } else {
          arr.push({
            propertyCode: obj.propertyCode,
            propertyValue: isSearch ? [value] : value,
            propertyEnumId: obj.propertyId,
            expr: isSearch ? 'eq' : undefined,
          });
        }
      }
    }
  }
  return arr;
};

type ProductProperty = { propertyCode: string; propertyValue: string; [key: string]: any };

/** 商品属性转{key,value} */
export function transPropertyToKeyValue(properties: ProductProperty[]) {
  const obj: Record<string, any> = {};
  properties.forEach((prop) => {
    for (let key in ProductCodeMap) {
      const target = ProductCodeMap[key as ProductKeyType];
      if (target.propertyCode === prop.propertyCode) {
        if (prop.propertyValueVo) {
          let subLabel: string = '',
            label: string = prop.propertyValueVo;
          if (/:/.test(prop.propertyValueVo)) {
            const arr = label.split(':');
            label = arr[1];
            subLabel = arr[0];
          }
          obj[key] = {
            label,
            subLabel,
            value: prop.propertyValue,
          };
        } else {
          obj[key] = prop.propertyValue || undefined;
        }
      }
    }
  });
  return obj as Record<keyof typeof ProductCodeMap, any>;
}

/** 过滤掉商品属性key，查询时用 */
export function filterParamsPropertyKey(target: Record<string, any>) {
  const keys = Object.keys(ProductCodeMap);
  const obj: Record<string, any> = {};
  for (let key in target) {
    if (!keys.includes(key)) {
      obj[key] = target[key];
    }
  }
  return obj;
}

/**
 * 把搜索properties值转换为提交接口需要的数组格式
 * @params record Record<string, any>
 * @params exp Record<string, exp>匹配方式默认PROPERTY_EXPR_EQ
 * @return [{code: xx, value: [xx], expr: xx}]
 */
export function transPropertiesToArr(
  record: Record<string, any>,
  exp: Record<string, string> = {} as any,
  keyNames = { code: 'code', value: 'value', expr: 'expr' }
) {
  const keys = Object.keys(ProductCodeMap);
  return Object.entries(record)
    .filter(([key, val]) => {
      if (!keys.includes(key)) return false;
      if (Array.isArray(val)) {
        return val.length > 0;
      } else {
        return ![null, undefined, ''].includes(val);
      }
    })
    .map(([key_, val]) => {
      type key = keyof typeof ProductCodeMap;
      const key = key_ as key;
      const target = ProductCodeMap[key] || {};
      return {
        propertyId: target.propertyId,
        [keyNames.code]: target.propertyCode,
        [keyNames.value]: Array.isArray(val) ? val : val ? [val] : [],
        [keyNames.expr]: exp[key] || 'PROPERTY_EXPR_EQ',
      };
    });
}
