import styles from './index.module.less';
import { useRequest } from 'ahooks';
import React, { useEffect, useRef, useState } from 'react';
import type { SelectProps } from 'antd';
import { List, Select } from 'antd';
import classNames from 'classnames';
import type { BaseOptionType, DefaultOptionType } from 'antd/lib/select';

export enum AreaType {
  city = 'CITY',
  station = 'STATION',
  port = 'PORT',
}

export interface AreaOptionItem {
  id: string;
  nameZh: string;
  nameEn: string;
  areaType: AreaType;
}

export interface BaseSearchSelectProps<T, P extends BaseOptionType | DefaultOptionType = DefaultOptionType>
  extends Omit<SelectProps<T, P>, 'onSearch'> {
  value?: T;
  onChange?: (value: T | undefined) => void;
  onSearch: (keyword: string) => Promise<T[]>;
  dropdownItemRender: (selectedItem: T, onSelected: (item: T) => void) => React.ReactNode;
  selectItemRender: (selectedItem: T) => React.ReactNode;
}

export default function BaseSearchSelect<T extends AreaOptionItem>(props: BaseSearchSelectProps<T>) {
  const { value, defaultValue, dropdownItemRender, onSearch, onChange, style, selectItemRender, ...restProps } = props;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T>();
  const keywordRef = useRef<string>();
  const [keyword, setKeyword] = useState<string>();
  const portServer = useRequest(onSearch, {
    manual: true,
    debounceWait: 500,
  });

  useEffect(() => {
    const mergedValue = value || defaultValue || undefined;
    setSelectedItem(mergedValue);
  }, [value, defaultValue]);

  function onSelected(item: T | undefined) {
    setSelectedItem(item);
    onChange && onChange(item);
    setOpen(false);
  }

  const dropdownRender = () => (
    <List
      style={{ maxHeight: 300, overflow: 'auto', padding: '8px' }}
      loading={portServer.loading}
      dataSource={keyword ? portServer.data || [] : []}
      renderItem={(item) => dropdownItemRender(item, onSelected)}
      locale={{
        emptyText: <div className={styles.empty}>没有找到哦</div>,
      }}
    />
  );

  return (
    <div className={classNames(styles['base-search-select'])} style={style}>
      <Select
        className={classNames(styles.selector, { [styles.hide]: !open })}
        open={open}
        dropdownMatchSelectWidth={363}
        showSearch
        allowClear
        onClear={() => onSelected(undefined)}
        style={{
          width: '100%',
        }}
        onSearch={(keyword) => {
          keywordRef.current = keyword;
          setKeyword(keyword);
          if (!keyword) {
            return;
          }

          portServer.runAsync(keyword);
        }}
        placement="bottomLeft"
        value={selectedItem}
        {...restProps}
        placeholder=""
        showArrow={false}
        onDropdownVisibleChange={(value) => {
          setKeyword('');
          setOpen(value);
        }}
        dropdownRender={dropdownRender}
      />
      <div className={classNames(styles['display-label'], open ? (!!keyword ? styles.hide : styles.placehoder) : '')} onClick={() => setOpen(true)}>
        {selectedItem ? selectItemRender(selectedItem) : <div className={styles.placeholder}>{restProps.placeholder}</div>}
      </div>
    </div>
  );
}
