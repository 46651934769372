import BigNumber from 'bignumber.js';
import { forwardRef } from 'react';
import { useControllableValue, useMemoizedFn } from 'ahooks';
import { Button, InputNumber as AntdInputNumber } from 'antd';

import type { InputNumberProps } from 'antd';

import styles from './index.module.less';

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps<any>>((props, ref) => {
  const [value, onChange] = useControllableValue(props);

  const handlePlus = useMemoizedFn(() => {
    const bigValue = BigNumber(value);
    const stepValue = BigNumber(props.step!);
    const minBigValue = BigNumber(props.min);
    const maxBigValue = BigNumber(props.max);

    if (!bigValue.isNaN()) {
      const newBigValue = bigValue.plus(stepValue.isNaN() ? 1 : stepValue);
      onChange(newBigValue.gt(maxBigValue) ? maxBigValue.valueOf() : newBigValue.valueOf());
    } else {
      onChange(minBigValue.isNaN() ? 0 : minBigValue.valueOf());
    }
  });

  const handleMinus = useMemoizedFn(() => {
    const bigValue = BigNumber(value);
    const stepValue = BigNumber(props.step!);
    const minBigValue = BigNumber(props.min);

    if (!bigValue.isNaN()) {
      const newBigValue = bigValue.minus(stepValue.isNaN() ? 1 : stepValue);
      onChange(newBigValue.lt(minBigValue) ? minBigValue.valueOf() : newBigValue.valueOf());
    } else {
      onChange(minBigValue.isNaN() ? 0 : minBigValue.valueOf());
    }
  });

  return (
    <AntdInputNumber
      ref={ref}
      {...props}
      value={value}
      onChange={onChange}
      addonBefore={
        <Button disabled={props.disabled} onClick={handleMinus}>
          -
        </Button>
      }
      addonAfter={
        <Button disabled={props.disabled} onClick={handlePlus}>
          +
        </Button>
      }
      className={styles.input}
      controls={false}
    />
  );
});

export default InputNumber;
