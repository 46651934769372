// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询报关商品 GET /salesgateway/v1/declaration/buyer/items */
export async function OperationId_salesgateway_DeclarationBuyerProductController_pageItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationBuyerProductControllerPageItemParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageDeclarationItemVO>('/salesgateway/v1/declaration/buyer/items', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationBuyerProductController_pageItem',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取商品详情 GET /salesgateway/v1/declaration/buyer/items/${param0} */
export async function OperationId_salesgateway_DeclarationBuyerProductController_getItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationBuyerProductControllerGetItemParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DeclarationItemVO>(`/salesgateway/v1/declaration/buyer/items/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationBuyerProductController_getItem',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取城市历史记录 GET /salesgateway/v1/declaration/buyer/items/searchHistoryRecorde */
export async function OperationId_salesgateway_DeclarationBuyerProductController_searchHistoryRecorde(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.SearchHistoryRecordeResponseVO>(
    '/salesgateway/v1/declaration/buyer/items/searchHistoryRecorde',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationBuyerProductController_searchHistoryRecorde',
      },
      ...(rest || {}),
    },
  );
}
