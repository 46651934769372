// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 检查线下钱包支付开通 GET /salesgateway/payment-on-account/manager/api/check-offline-balance */
export async function OperationId_salesgateway_PaymentOnAccountManagerController_checkOfflineBalance(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/payment-on-account/manager/api/check-offline-balance', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PaymentOnAccountManagerController_checkOfflineBalance',
    },
    ...(rest || {}),
  });
}

/** 账期付-配置规则获取用户接收验证码信息 GET /salesgateway/payment-on-account/manager/api/getPayAccountVerificationCodeRule */
export async function OperationId_salesgateway_PaymentOnAccountManagerController_getPayAccountVerificationCodeRule(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.GetPayAccountVerificationCodeRuleResponse>(
    '/salesgateway/payment-on-account/manager/api/getPayAccountVerificationCodeRule',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_PaymentOnAccountManagerController_getPayAccountVerificationCodeRule',
      },
      ...(rest || {}),
    },
  );
}

/** 支付-账期付-配置规则获取用户接收验证码信息 POST /salesgateway/payment-on-account/manager/api/getPayOnAccountPhoneOrEmail */
export async function OperationId_salesgateway_PaymentOnAccountManagerController_getPayOnAccountPhoneOrEmail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPaymentOnAccountManagerControllerGetPayOnAccountPhoneOrEmailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetPayOnAccountPhoneOrEmailResponse>(
    '/salesgateway/payment-on-account/manager/api/getPayOnAccountPhoneOrEmail',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_PaymentOnAccountManagerController_getPayOnAccountPhoneOrEmail',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 更新付款是验证码接收规则 POST /salesgateway/payment-on-account/manager/api/updatePayAccountVerificationCodeRule */
export async function OperationId_salesgateway_PaymentOnAccountManagerController_updatePayAccountVerificationCodeRule(
  body: API.UpdatePayAccountVerificationCodeRuleReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>(
    '/salesgateway/payment-on-account/manager/api/updatePayAccountVerificationCodeRule',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_PaymentOnAccountManagerController_updatePayAccountVerificationCodeRule',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
