// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 创建企业 POST /salesgateway/v1/enterprises */
export async function OperationId_salesgateway_EnterpriseV1Controller_createEnterprise(
  body: API.EnterpriseCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseCreateResponse>('/salesgateway/v1/enterprises', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_createEnterprise',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 通过企业邀请码关注企业 POST /salesgateway/v1/enterprises/add/supplier-stock-user-relation */
export async function OperationId_salesgateway_EnterpriseV1Controller_createEnterpriseSupplierStockUserRelation(
  body: API.SupplierStockUserRelationCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SupplierStockUserRelationCreateResponse>(
    '/salesgateway/v1/enterprises/add/supplier-stock-user-relation',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseV1Controller_createEnterpriseSupplierStockUserRelation',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取当前企业资质认证信息 GET /salesgateway/v1/enterprises/certifications */
export async function OperationId_salesgateway_EnterpriseV1Controller_getEnterpriseCertifications(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerGetEnterpriseCertificationsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetEnterpriseCertificationResponseVO>(
    '/salesgateway/v1/enterprises/certifications',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_EnterpriseV1Controller_getEnterpriseCertifications',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 提交企业资质认证 POST /salesgateway/v1/enterprises/certifications */
export async function OperationId_salesgateway_EnterpriseV1Controller_createCertifications(
  body: API.CreateCertificationRequestVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CreateCertificationResponseVO>('/salesgateway/v1/enterprises/certifications', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_createCertifications',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取资质认证类型 GET /salesgateway/v1/enterprises/certifications/types */
export async function OperationId_salesgateway_EnterpriseV1Controller_getCertificationTypes(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerGetCertificationTypesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetCertificationResponseVO>(
    '/salesgateway/v1/enterprises/certifications/types',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_EnterpriseV1Controller_getCertificationTypes',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 根据邀请码查询是否可用 GET /salesgateway/v1/enterprises/check-inviteCode */
export async function OperationId_salesgateway_EnterpriseV1Controller_checkInviteCdoe(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerCheckInviteCdoeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/enterprises/check-inviteCode', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_checkInviteCdoe',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 通过企业邀请码检测是否已关注企业 POST /salesgateway/v1/enterprises/check/supplier-stock-user-relation */
export async function OperationId_salesgateway_EnterpriseV1Controller_checkEnterpriseSupplierStockUserRelation(
  body: API.SupplierStockUserRelationCheckRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SupplierStockUserRelationCheckResponse>(
    '/salesgateway/v1/enterprises/check/supplier-stock-user-relation',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseV1Controller_checkEnterpriseSupplierStockUserRelation',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 设置卖家给买家配置的结算方式 POST /salesgateway/v1/enterprises/create-or-update-settlement-type */
export async function OperationId_salesgateway_EnterpriseV1Controller_createOrUpdateSettlementTypeConfig(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerCreateOrUpdateSettlementTypeConfigParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CreateOrUpdateSettlementTypeResponse>(
    '/salesgateway/v1/enterprises/create-or-update-settlement-type',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseV1Controller_createOrUpdateSettlementTypeConfig',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 根据企业id获取企业信息（包括真实性认证信息） GET /salesgateway/v1/enterprises/detail/${param0} */
export async function OperationId_salesgateway_EnterpriseV1Controller_getEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerGetEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { enterpriseId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseResponseVO>(`/salesgateway/v1/enterprises/detail/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_getEnterprise',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取卖家给买家配置的结算方式（通过买家企业 id） GET /salesgateway/v1/enterprises/get-settlement-type */
export async function OperationId_salesgateway_EnterpriseV1Controller_getSettlementTypeConfig(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerGetSettlementTypeConfigParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetSettlementTypeResponse>(
    '/salesgateway/v1/enterprises/get-settlement-type',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_EnterpriseV1Controller_getSettlementTypeConfig',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 获取当前企业所有认证业务组列表及认证状态 GET /salesgateway/v1/enterprises/groups */
export async function OperationId_salesgateway_EnterpriseV1Controller_getEnterpriseCertificationGroups(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerGetEnterpriseCertificationGroupsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetEnterpriseCertificationGroupResponseVO>(
    '/salesgateway/v1/enterprises/groups',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseV1Controller_getEnterpriseCertificationGroups',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 获取所有企业列表 GET /salesgateway/v1/enterprises/list */
export async function OperationId_salesgateway_EnterpriseV1Controller_listEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerListEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ListPageVoEnterpriseListVo>('/salesgateway/v1/enterprises/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_listEnterprise',
    },
    params: {
      // page has a default value: 1
      page: '1',
      // pageSize has a default value: 10
      pageSize: '10',
      ...params,
    },
    ...(rest || {}),
  });
}

/** 客户管理-客户关系（已关注的企业）列表 GET /salesgateway/v1/enterprises/list/supplier-stock-user-relation */
export async function OperationId_salesgateway_EnterpriseV1Controller_listEnterpriseSupplierStockUserRelation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerListEnterpriseSupplierStockUserRelationParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SupplierStockUserRelationListResponse>(
    '/salesgateway/v1/enterprises/list/supplier-stock-user-relation',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseV1Controller_listEnterpriseSupplierStockUserRelation',
      },
      params: {
        // page has a default value: 1
        page: '1',
        // pageSize has a default value: 10
        pageSize: '10',
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 企业资料-已关注的企业列表 GET /salesgateway/v1/enterprises/list/supplier-stock-user-relation/simple */
export async function OperationId_salesgateway_EnterpriseV1Controller_listEnterpriseSupplierStockUserRelationSimple(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerListEnterpriseSupplierStockUserRelationSimpleParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SupplierStockUserRelationListSimpleResponse>(
    '/salesgateway/v1/enterprises/list/supplier-stock-user-relation/simple',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_EnterpriseV1Controller_listEnterpriseSupplierStockUserRelationSimple',
      },
      params: {
        // page has a default value: 1
        page: '1',
        // pageSize has a default value: 10
        pageSize: '10',
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查询企业基础信息 GET /salesgateway/v1/enterprises/simple */
export async function OperationId_salesgateway_EnterpriseV1Controller_enterpriseSimple(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseSimpleResponse>('/salesgateway/v1/enterprises/simple', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_enterpriseSimple',
    },
    ...(rest || {}),
  });
}

/** 更新企业 POST /salesgateway/v1/enterprises/staff */
export async function OperationId_salesgateway_EnterpriseV1Controller_updateEnterprise(
  body: API.EnterpriseUpdateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/v1/enterprises/staff', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_updateEnterprise',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 企业唯一性检查 POST /salesgateway/v1/enterprises/unique/check */
export async function OperationId_salesgateway_EnterpriseV1Controller_enterpriseUniqueCheck(
  body: API.EnterpriseUniqueCheckRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseUniqueCheckResponse>('/salesgateway/v1/enterprises/unique/check', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_enterpriseUniqueCheck',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 企业名称修改记录分页查询 GET /salesgateway/v1/enterprises/version */
export async function OperationId_salesgateway_EnterpriseV1Controller_pageEnterpriseEditVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseV1ControllerPageEnterpriseEditVersionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseEditVersionResponse>('/salesgateway/v1/enterprises/version', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_EnterpriseV1Controller_pageEnterpriseEditVersion',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
