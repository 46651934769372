// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询地址 GET /logisticssolution/v1/open/configs/addresses */
export async function OperationId_logisticssolution_OpenCenterConfigController_pageAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerPageAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageAddressDTO>('/logisticssolution/v1/open/configs/addresses', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_pageAddress',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取地址详情 GET /logisticssolution/v1/open/configs/addresses/${param0} */
export async function OperationId_logisticssolution_OpenCenterConfigController_getAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetAddressParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AddressVO>(`/logisticssolution/v1/open/configs/addresses/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getAddress',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取箱东 GET /logisticssolution/v1/open/configs/box-owner */
export async function OperationId_logisticssolution_OpenCenterConfigController_getBoxOwner(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.BoxOwnerDTO[]>('/logisticssolution/v1/open/configs/box-owner', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getBoxOwner',
    },
    ...(rest || {}),
  });
}

/** 分页查询箱型 GET /logisticssolution/v1/open/configs/box-types */
export async function OperationId_logisticssolution_OpenCenterConfigController_pageBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerPageBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageBoxTypeDTO>('/logisticssolution/v1/open/configs/box-types', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_pageBoxType',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取箱型详情 GET /logisticssolution/v1/open/configs/box-types/${param0} */
export async function OperationId_logisticssolution_OpenCenterConfigController_getBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BoxTypeDTO>(`/logisticssolution/v1/open/configs/box-types/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getBoxType',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 创建地址 POST /logisticssolution/v1/open/configs/create/address */
export async function OperationId_logisticssolution_OpenCenterConfigController_createAddress(
  body: API.CreateAddressRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/logisticssolution/v1/open/configs/create/address', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_createAddress',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 创建联系人 POST /logisticssolution/v1/open/configs/create/contact */
export async function OperationId_logisticssolution_OpenCenterConfigController_createContact(
  body: API.CreateAndEditEnterpriseStaffRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/logisticssolution/v1/open/configs/create/contact', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_createContact',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 删除地址 DELETE /logisticssolution/v1/open/configs/delete/address */
export async function OperationId_logisticssolution_OpenCenterConfigController_deleteAddress(
  body: API.DeleteAddressRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/logisticssolution/v1/open/configs/delete/address', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_deleteAddress',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 删除联系人 DELETE /logisticssolution/v1/open/configs/delete/contact */
export async function OperationId_logisticssolution_OpenCenterConfigController_deleteContact(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerDeleteContactParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/logisticssolution/v1/open/configs/delete/contact', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_deleteContact',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取企业所有员工 GET /logisticssolution/v1/open/configs/get-enterprise-employee */
export async function OperationId_logisticssolution_OpenCenterConfigController_getEnterpriseEmployee(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetEnterpriseEmployeeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetEnterpriseEmployeeResponse>(
    '/logisticssolution/v1/open/configs/get-enterprise-employee',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_OpenCenterConfigController_getEnterpriseEmployee',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 获取航线组 GET /logisticssolution/v1/open/configs/get-route-group-list */
export async function OperationId_logisticssolution_OpenCenterConfigController_getRouteGroupList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetRouteGroupListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageRouteGroupDTO>(
    '/logisticssolution/v1/open/configs/get-route-group-list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getRouteGroupList',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 分页查询港口 GET /logisticssolution/v1/open/configs/harbours */
export async function OperationId_logisticssolution_OpenCenterConfigController_pageHarbour(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerPageHarbourParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageHarbourDTO>('/logisticssolution/v1/open/configs/harbours', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_pageHarbour',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取港口详情 GET /logisticssolution/v1/open/configs/harbours/${param0} */
export async function OperationId_logisticssolution_OpenCenterConfigController_getHarbour(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetHarbourParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.HarbourDTO>(`/logisticssolution/v1/open/configs/harbours/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getHarbour',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询按条件-港口列表 POST /logisticssolution/v1/open/configs/harbours/condition-harbours */
export async function OperationId_logisticssolution_OpenCenterConfigController_listConditionHarbour(
  body: API.HarbourConditionQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.HarbourConditionVO>(
    '/logisticssolution/v1/open/configs/harbours/condition-harbours',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_OpenCenterConfigController_listConditionHarbour',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取履约配置(附加费项) GET /logisticssolution/v1/open/configs/performances */
export async function OperationId_logisticssolution_OpenCenterConfigController_getPerformance(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceConfigDTO>('/logisticssolution/v1/open/configs/performances', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getPerformance',
    },
    ...(rest || {}),
  });
}

/** 获取履全部费用项配置(附加费项) GET /logisticssolution/v1/open/configs/performancesFeeAll */
export async function OperationId_logisticssolution_OpenCenterConfigController_getPerformanceAll(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceConfigDTO[]>(
    '/logisticssolution/v1/open/configs/performancesFeeAll',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getPerformanceAll',
      },
      ...(rest || {}),
    },
  );
}

/** 分页查询口岸城市 GET /logisticssolution/v1/open/configs/port-cities */
export async function OperationId_logisticssolution_OpenCenterConfigController_pagePortCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerPagePortCityParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePortCityDTO>('/logisticssolution/v1/open/configs/port-cities', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_pagePortCity',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取口岸城市 GET /logisticssolution/v1/open/configs/port-cities/${param0} */
export async function OperationId_logisticssolution_OpenCenterConfigController_getPortCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetPortCityParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PortCityDTO>(`/logisticssolution/v1/open/configs/port-cities/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getPortCity',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 分页查询铁路站 GET /logisticssolution/v1/open/configs/railway-stations */
export async function OperationId_logisticssolution_OpenCenterConfigController_pageRailwayStation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerPageRailwayStationParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageRailwayStationDTO>(
    '/logisticssolution/v1/open/configs/railway-stations',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCenterConfigController_pageRailwayStation',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 获取铁路站详情 GET /logisticssolution/v1/open/configs/railway-stations/${param0} */
export async function OperationId_logisticssolution_OpenCenterConfigController_getRailwayStation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetRailwayStationParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.RailwayStationDTO>(
    `/logisticssolution/v1/open/configs/railway-stations/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getRailwayStation',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询当前利率 GET /logisticssolution/v1/open/configs/rate */
export async function OperationId_logisticssolution_OpenCenterConfigController_getRate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetRateParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CurRateVo>('/logisticssolution/v1/open/configs/rate', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getRate',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询地址 GET /logisticssolution/v1/open/configs/search/address */
export async function OperationId_logisticssolution_OpenCenterConfigController_searchAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerSearchAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageEnterpriseAddressDTO>(
    '/logisticssolution/v1/open/configs/search/address',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCenterConfigController_searchAddress',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 查询联系人 GET /logisticssolution/v1/open/configs/search/contact */
export async function OperationId_logisticssolution_OpenCenterConfigController_searchContact(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerSearchContactParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStaffDTO>('/logisticssolution/v1/open/configs/search/contact', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_searchContact',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}

/** 获取销售人员 GET /logisticssolution/v1/open/configs/sellers */
export async function OperationId_logisticssolution_OpenCenterConfigController_listSeller(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerListSellerParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcInviteUserVO[]>('/logisticssolution/v1/open/configs/sellers', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_listSeller',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}

/** 分页查询海运公司 GET /logisticssolution/v1/open/configs/shipping-companies */
export async function OperationId_logisticssolution_OpenCenterConfigController_pageShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerPageShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageShippingCompanyDTO>(
    '/logisticssolution/v1/open/configs/shipping-companies',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCenterConfigController_pageShippingCompany',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 获取海运公司详情 GET /logisticssolution/v1/open/configs/shipping-companies/${param0} */
export async function OperationId_logisticssolution_OpenCenterConfigController_getShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCenterConfigControllerGetShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ShippingCompanyDTO>(
    `/logisticssolution/v1/open/configs/shipping-companies/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCenterConfigController_getShippingCompany',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 修改地址 PUT /logisticssolution/v1/open/configs/update/address */
export async function OperationId_logisticssolution_OpenCenterConfigController_updateAddress(
  body: API.EditAddressRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/logisticssolution/v1/open/configs/update/address', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_updateAddress',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 修改联系人 PUT /logisticssolution/v1/open/configs/update/contact */
export async function OperationId_logisticssolution_OpenCenterConfigController_updateContact(
  body: API.CreateAndEditEnterpriseStaffRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/logisticssolution/v1/open/configs/update/contact', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCenterConfigController_updateContact',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
