import { i18n } from '@easygo/i18n';
import classNames from 'classnames/bind';
import { isEqual } from 'lodash';
import { useMemoizedFn } from 'ahooks';
import { Space, Spin, Tabs } from 'antd';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useComponentTrans } from '@easygo/components';
import type { SelectProps } from 'antd';
import { Select } from '@easygo/components/src/select';
import styles from './index.module.less';

const cx = classNames.bind(styles);

const defaultParams = { page: '1', size: '-1' };

export enum LOAD_TYPE {
  ADDRESS = 'address',
  STATION = 'station',
}

interface Field {
  type: LOAD_TYPE;
  config?: Record<string, any>;
}

const defaultFields = [
  { type: LOAD_TYPE.ADDRESS, config: { level: 1 } },
  { type: LOAD_TYPE.ADDRESS, config: { level: 2 } },
  { type: LOAD_TYPE.ADDRESS, config: { level: 3 } },
  { type: LOAD_TYPE.ADDRESS, config: { level: 4 } },
  { type: LOAD_TYPE.STATION },
];

export type Load = {
  [key in LOAD_TYPE]: <T extends any>(params?: Record<string, any>, parent?: any) => Promise<any>;
};

type StepLoad = {
  type: 'loading' | 'loaded';
  data?: any;
};

export interface ChainSelectProps extends Omit<SelectProps<string[]>, 'options' | 'onChange'> {
  loads: Load;
  fields: Field[];
  onChange?: (value: string[]) => void;
}

const ChainSelect: React.FC<ChainSelectProps> = (props) => {
  const { value, onChange, fields = defaultFields, loads, allowClear = true, ...restProps } = props;
  const trans = useComponentTrans();
  const memoFormValue = useRef<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [checkValue, setCheckValue] = useState<any[]>([]);
  const [stepLoads, setStepLoads] = useState<StepLoad[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<number>(0);

  const load = useMemoizedFn((index, parent) => {
    const currentStep = fields[index];
    const func = loads[currentStep?.type];
    if (func) {
      return func(currentStep.config, parent);
    } else {
      throw new Error('no type config');
    }
  });

  const loadStep = useMemoizedFn(async (index, parent?) => {
    setStepLoads((prev) => [...prev.slice(0, index), { type: 'loading' }]);
    const res = await load(index, parent);
    setStepLoads((prev) => [...prev.slice(0, index), { type: 'loaded', data: res?.records }]);
  });

  const loadBatch = useMemoizedFn(async () => {
    const promiseList: Promise<any>[] = [];
    const newStepLoads: StepLoad[] = [];
    value?.forEach((_, index) => {
      promiseList.push(load(index, { id: value![index - 1] }));
      newStepLoads.push({ type: 'loading' });
    });

    setStepLoads(newStepLoads);

    const responses = await Promise.all(promiseList);

    const loadedStepLoads: StepLoad[] = [];
    const newCheckValue: any[] = [];

    responses.forEach((response, index) => {
      loadedStepLoads.push({ type: 'loaded', data: response?.records });
      newCheckValue.push(response?.records?.find((record) => record?.id === value![index]));
    });

    setStepLoads(loadedStepLoads);
    setCheckValue(newCheckValue);
    setActiveTabKey(value && value.length ? value.length - 1 : 0);
  });

  const handleTabClick = useMemoizedFn((value) => {
    setActiveTabKey(value);
  });

  const handleItemClick = useMemoizedFn((value: sale.API.PageAddressVO) => {
    const newCheckValue = [...checkValue.slice(0, activeTabKey), value];
    setCheckValue(newCheckValue);
    if (newCheckValue.length < fields.length) {
      setActiveTabKey(activeTabKey + 1);
      loadStep(newCheckValue.length, value);
    } else {
      const newValue = newCheckValue?.map((item) => item.id);
      memoFormValue.current = newValue;
      onChange?.(newValue);
      setOpen(false);
    }
  });

  const dropdownRender = useCallback(() => {
    const tabs = checkValue.slice().map((v, index) => ({
      label: v?.nameZh,
      key: index,
    }));
    if (tabs.length < fields.length) {
      tabs.push({
        label: i18n?.t('请选择'),
        key: tabs.length,
      });
    }
    return (
      <div className={styles.dropdownWrap}>
        <Tabs activeKey={activeTabKey} destroyInactiveTabPane onTabClick={handleTabClick} tabBarGutter={0} type="card" items={tabs}></Tabs>
        <Spin spinning={stepLoads[activeTabKey]?.type === 'loading'}>
          <div className={styles.dropdownContent}>
            <Space size={24} wrap>
              {stepLoads[activeTabKey]?.data?.map((s) => (
                <div onClick={() => handleItemClick(s)} key={s.id} className={cx('option', { selected: checkValue[activeTabKey]?.id === s.id })}>
                  {s.nameZh}
                </div>
              ))}
            </Space>
          </div>
        </Spin>
      </div>
    );
  }, [checkValue, stepLoads, activeTabKey]);

  const handleClear = useMemoizedFn(() => {
    const newValue: any[] = [];
    setActiveTabKey(0);
    setCheckValue(newValue);
    onChange?.(newValue);
  });

  const handleDropdownVisibleChange = useMemoizedFn(async (open: boolean) => {
    if (!open) {
      if (checkValue && checkValue.length < fields.length) {
        handleClear();
      }
    }
    setOpen(open);
  });

  useEffect(() => {
    if (!isEqual(memoFormValue.current, value)) {
      memoFormValue.current = value;
      loadBatch();
    }
  }, [value]);

  useEffect(() => {
    if (open && activeTabKey === 0 && !stepLoads[0]) {
      loadStep(0);
    }
  }, [open, stepLoads, activeTabKey]);

  const memoSelectValue = useMemo(() => (checkValue && checkValue.length ? checkValue.map((c) => c.nameZh).join('/') : undefined), [checkValue]);

  return (
    <Select
      value={memoSelectValue}
      labelInValue
      open={open}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      dropdownMatchSelectWidth={530}
      dropdownRender={dropdownRender}
      placeholder={trans('select_default_placeholder')}
      allowClear={allowClear}
      onClear={handleClear}
      {...restProps}
    />
  );
};

export default ChainSelect;
