// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 商品过滤条件 POST /commodity/v1/railway/buyer/items/open/getProductFilter */
export async function OperationId_businesscommodity_OpenRailwayBuyerProductController_getProductFilter(
  body: API.GetProductFilterReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ProductFilterResp>('/commodity/v1/railway/buyer/items/open/getProductFilter', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_businesscommodity_OpenRailwayBuyerProductController_getProductFilter',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询铁路商品列表 POST /commodity/v1/railway/buyer/items/open/list */
export async function OperationId_businesscommodity_OpenRailwayBuyerProductController_itemList(
  body: API.ItemListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ItemListResp>('/commodity/v1/railway/buyer/items/open/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businesscommodity_OpenRailwayBuyerProductController_itemList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
