import { Pagination, Select } from 'antd';
import type { PaginationProps } from 'antd';
import { useMemo } from 'react';
import { useComponentTrans } from '../config';
import styles from './index.module.less';

export function ProPagination(props: PaginationProps) {
  const { current = 1, pageSize = 10, onShowSizeChange, pageSizeOptions, onChange, ...restProps } = props;
  const sizeOptions = useMemo(() => {
    return pageSizeOptions ?? [10, 15, 20, 50, 100];
  }, [pageSizeOptions]);
  const trans = useComponentTrans();
  const memoProps = useMemo<PaginationProps>(() => {
    return {
      size: 'small',
      position: ['bottomRight'],
      showQuickJumper: true,
      showSizeChanger: false,
      showTotal: (total: number, range: number[]) => {
        return (
          <div className={styles.total}>
            <span>
              {trans('pagination_total')}
              <span>
                {total}
                {trans('pagination_unit')}
              </span>
            </span>
            ，{trans('pagination_per')}
            <Select
              value={pageSize}
              onChange={(value) => {
                onShowSizeChange?.(1, value);
              }}
              placement="topRight"
              popupClassName={styles.pageSelect}
              getPopupContainer={(element) => element.parentNode}
              dropdownMatchSelectWidth={100}
              size="small"
              options={sizeOptions.map((value) => ({
                label: value,
                value,
              }))}
            />
            <span>{trans('pagination_unit')}</span>
          </div>
        );
      },
      onChange,
      onShowSizeChange,
      current,
      pageSize,
      ...restProps,
    };
  }, [current, onChange, onShowSizeChange, pageSize, restProps, sizeOptions, trans]);
  return <>{Number(restProps.total) === 0 ? null : <Pagination className={styles.page} {...memoProps} />}</>;
}
