// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 运营后台订单列表 GET /sinotransbooking/v1/operation/performances */
export async function OperationId_sinotransbooking_OperationPerformanceController_pagePerformanceOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPerformanceControllerPagePerformanceOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderDetailVO>('/sinotransbooking/v1/operation/performances', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_OperationPerformanceController_pagePerformanceOrder',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 运营后台订单详情 GET /sinotransbooking/v1/operation/performances/${param0} */
export async function OperationId_sinotransbooking_OperationPerformanceController_detail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPerformanceControllerDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailVO>(
    `/sinotransbooking/v1/operation/performances/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationPerformanceController_detail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 附件查询 GET /sinotransbooking/v1/operation/performances/${param0}/attachments */
export async function OperationId_sinotransbooking_OperationPerformanceController_attachmentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationPerformanceControllerAttachmentListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderAttachmentVo[]>(
    `/sinotransbooking/v1/operation/performances/${param0}/attachments`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationPerformanceController_attachmentList',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 运营后台订单列表分类统计数据 GET /sinotransbooking/v1/operation/performances/sortCount */
export async function OperationId_sinotransbooking_OperationPerformanceController_getPerformanceOrderSortCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderSortCountVo>(
    '/sinotransbooking/v1/operation/performances/sortCount',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationPerformanceController_getPerformanceOrderSortCount',
      },
      ...(rest || {}),
    },
  );
}
