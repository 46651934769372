// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 取消需求 POST /salesgateway/container-back/demand/api/${param0}/cancelDemand */
export async function OperationId_salesgateway_ContainerBackDemandController_cancelDemand(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerBackDemandControllerCancelDemandParams,
  options?: { [key: string]: any },
) {
  const { backhaulDemandId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/container-back/demand/api/${param0}/cancelDemand`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerBackDemandController_cancelDemand',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询回箱需求详情 GET /salesgateway/container-back/demand/api/${param0}/detail */
export async function OperationId_salesgateway_ContainerBackDemandController_getContainerBackDemandInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerBackDemandControllerGetContainerBackDemandInfoParams,
  options?: { [key: string]: any },
) {
  const { backhaulDemandId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ContainerBackDemandInfoResVO>(
    `/salesgateway/container-back/demand/api/${param0}/detail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerBackDemandController_getContainerBackDemandInfo',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 新增回箱需求 POST /salesgateway/container-back/demand/api/create */
export async function OperationId_salesgateway_ContainerBackDemandController_createContainerBackDemand(
  body: API.ContainerBackDemandCreateReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerBackDemandIdResVO>('/salesgateway/container-back/demand/api/create', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerBackDemandController_createContainerBackDemand',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 编辑回箱需求 PUT /salesgateway/container-back/demand/api/edit */
export async function OperationId_salesgateway_ContainerBackDemandController_editContainerBackDemand(
  body: API.ContainerBackDemandEditReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/container-back/demand/api/edit', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerBackDemandController_editContainerBackDemand',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 回箱需求列表 POST /salesgateway/container-back/demand/api/list */
export async function OperationId_salesgateway_ContainerBackDemandController_pageContainerBackDemand(
  body: API.ContainerBackDemandPageReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerBackDemandPageResVO>('/salesgateway/container-back/demand/api/list', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerBackDemandController_pageContainerBackDemand',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
