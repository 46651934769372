// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询专区活动详情 GET /salesgateway/zone/activity/detail */
export async function OperationId_salesgateway_ZoneActivityController_getActivityDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayZoneActivityControllerGetActivityDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ZoneActivityDetailResponse>('/salesgateway/zone/activity/detail', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ZoneActivityController_getActivityDetail',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询专区活动航线组商品信息 GET /salesgateway/zone/activity/tag-group/detail */
export async function OperationId_salesgateway_ZoneActivityController_getActivityTagGroupDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayZoneActivityControllerGetActivityTagGroupDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ZoneaActivityTagGroupDetailResponse>(
    '/salesgateway/zone/activity/tag-group/detail',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ZoneActivityController_getActivityTagGroupDetail',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}
