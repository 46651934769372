// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询订单 GET /logisticssolution/v1/seller/performances */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_pagePerformanceOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerPagePerformanceOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderBriefVO>('/logisticssolution/v1/seller/performances', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerPerformanceOrderController_pagePerformanceOrder',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 接单 PUT /logisticssolution/v1/seller/performances/${param0}/accept */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_accept(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerAcceptParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/performances/${param0}/accept`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerPerformanceOrderController_accept',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 卖家附件查询 GET /logisticssolution/v1/seller/performances/${param0}/attachments */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_attachmentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerAttachmentListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderAttachmentVo[]>(
    `/logisticssolution/v1/seller/performances/${param0}/attachments`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderController_attachmentList',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 卖家上传附件 POST /logisticssolution/v1/seller/performances/${param0}/attachments */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_uploadAttachments(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerUploadAttachmentsParams,
  body: API.UploadDocInfoRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/performances/${param0}/attachments`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerPerformanceOrderController_uploadAttachments',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 完成订单 PUT /logisticssolution/v1/seller/performances/${param0}/complete */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_complete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerCompleteParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/performances/${param0}/complete`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerPerformanceOrderController_complete',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 关闭订单 PUT /logisticssolution/v1/seller/performances/${param0}/force-close */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_forceClose(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerForceCloseParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/performances/${param0}/force-close`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerPerformanceOrderController_forceClose',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 拒单 PUT /logisticssolution/v1/seller/performances/${param0}/reject */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_reject(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerRejectParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/performances/${param0}/reject`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerPerformanceOrderController_reject',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 获取订单聚合统计 GET /logisticssolution/v1/seller/performances/aggregations */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_aggregatePerformanceOrder(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.AggregatePerformanceOrderDTO>(
    '/logisticssolution/v1/seller/performances/aggregations',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderController_aggregatePerformanceOrder',
      },
      ...(rest || {}),
    },
  );
}

/** 消息查询 GET /logisticssolution/v1/seller/performances/message/${param0} */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_messageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerMessageListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MessageItemDTO[]>(
    `/logisticssolution/v1/seller/performances/message/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_SellerPerformanceOrderController_messageList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 是否有箱货轨迹 GET /logisticssolution/v1/seller/performances/trace/${param0} */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_isHaveTrace(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerIsHaveTraceParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/performances/trace/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerPerformanceOrderController_isHaveTrace',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 班列代客下单 POST /logisticssolution/v1/seller/performances/train */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_createTrainPerformance(
  body: API.PerformanceOrderTrainAgentCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderCreateResponse>(
    '/logisticssolution/v1/seller/performances/train',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderController_createTrainPerformance',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 班列订单详情-查询 GET /logisticssolution/v1/seller/performances/train/${param0} */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_getTrainPerformanceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerGetTrainPerformanceDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailResponse>(
    `/logisticssolution/v1/seller/performances/train/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderController_getTrainPerformanceDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 班列订单更新 PUT /logisticssolution/v1/seller/performances/train/${param0} */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_updateTrainPerformance(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerUpdateTrainPerformanceParams,
  body: API.PerformanceOrderTrainUpdateRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/seller/performances/train/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_SellerPerformanceOrderController_updateTrainPerformance',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 班列订单详情-用于更新 GET /logisticssolution/v1/seller/performances/train/${param0}/update-detail */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_updateTrainPerformanceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerUpdateTrainPerformanceDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UpdatePerformanceOrderTrainDetailResponse>(
    `/logisticssolution/v1/seller/performances/train/${param0}/update-detail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderController_updateTrainPerformanceDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 班列订单详情-查询费用信息 GET /logisticssolution/v1/seller/performances/train/fee/${param0} */
export async function OperationId_logisticssolution_SellerPerformanceOrderController_getTrainPerformanceFeeDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerPerformanceOrderControllerGetTrainPerformanceFeeDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderFeeDetailResponse>(
    `/logisticssolution/v1/seller/performances/train/fee/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_SellerPerformanceOrderController_getTrainPerformanceFeeDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
