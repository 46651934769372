import type { ReactNode } from 'react';
import styles from './index.module.less';

export type ProConfirmContentProps = {
  title: ReactNode;
  description: ReactNode;
};
export function ProConfirmContent(props: ProConfirmContentProps) {
  const { title, description } = props;
  return (
    <div className={styles.box}>
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{description}</div>
    </div>
  );
}
