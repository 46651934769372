// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 铁路业务追踪数据 铁路业务追踪订单数据统计 GET /salesgateway/workbench-tracking/railway */
export async function OperationId_salesgateway_WorkbenchTrackingController_railwayTracking(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayWorkbenchTrackingControllerRailwayTrackingParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.TrackingResponseVo>('/salesgateway/workbench-tracking/railway', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_WorkbenchTrackingController_railwayTracking',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 海运业务追踪数据 海运业务追踪订单数据统计 GET /salesgateway/workbench-tracking/ship */
export async function OperationId_salesgateway_WorkbenchTrackingController_shipTracking(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayWorkbenchTrackingControllerShipTrackingParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.TrackingResponseVo>('/salesgateway/workbench-tracking/ship', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_WorkbenchTrackingController_shipTracking',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
