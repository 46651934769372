export enum Platform_Type {
  TRADE = 'TRADE', // 交易中心
  WEB = 'WEB', // 官网
  ADMIN = 'ADMIN', // 运营平台
}

export const Platform_Type_Label = {
  [Platform_Type.TRADE]: '交易中心',
  [Platform_Type.WEB]: '官网',
  [Platform_Type.ADMIN]: '运营平台',
};
