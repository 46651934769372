/**
 * 将对象中为数组的属性转换成符合后端get规范的格式,只处理一层不递归
 * @param obj
 * @returns
 */
export function convert(obj: any) {
  let json: any = {};
  for (const key in obj) {
    const value = obj[key];
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === 'object') {
          for (const itemKey in item) {
            json[`${key}[${index}].${itemKey}`] = item[itemKey];
          }
        } else {
          json[`${key}[${index}]`] = item;
        }
      });
    } else {
      json[key] = value;
    }
  }

  return json;
}
