// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 订单账单信息列表 GET /salesgateway/seller/declaration/bill/${param0} */
export async function OperationId_salesgateway_BizDeclarationBillController_list(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizDeclarationBillControllerListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BillVo>(`/salesgateway/seller/declaration/bill/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizDeclarationBillController_list',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 提交账单费用项目 POST /salesgateway/seller/declaration/bill/${param0} */
export async function OperationId_salesgateway_BizDeclarationBillController_add(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizDeclarationBillControllerAddParams,
  body: API.SubmitBillRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/seller/declaration/bill/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizDeclarationBillController_add',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 获取附加费明细 GET /salesgateway/seller/declaration/bill/${param0}/additional-fee */
export async function OperationId_salesgateway_BizDeclarationBillController_additionalFee(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizDeclarationBillControllerAdditionalFeeParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BillVo>(`/salesgateway/seller/declaration/bill/${param0}/additional-fee`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizDeclarationBillController_additionalFee',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询是否有未完结支付单 GET /salesgateway/seller/declaration/bill/${param0}/check-pay */
export async function OperationId_salesgateway_BizDeclarationBillController_checkPay(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizDeclarationBillControllerCheckPayParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/seller/declaration/bill/${param0}/check-pay`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizDeclarationBillController_checkPay',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取商品费用明细 GET /salesgateway/seller/declaration/bill/${param0}/commodity-fee */
export async function OperationId_salesgateway_BizDeclarationBillController_commodityFee(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizDeclarationBillControllerCommodityFeeParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BillVo>(`/salesgateway/seller/declaration/bill/${param0}/commodity-fee`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizDeclarationBillController_commodityFee',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 支付明细列表 GET /salesgateway/seller/declaration/bill/${param0}/pay-list */
export async function OperationId_salesgateway_BizDeclarationBillController_payList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizDeclarationBillControllerPayListParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PayListVo>(`/salesgateway/seller/declaration/bill/${param0}/pay-list`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_BizDeclarationBillController_payList',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 结算记录 GET /salesgateway/seller/declaration/bill/${param0}/settlement-list */
export async function OperationId_salesgateway_BizDeclarationBillController_settlementList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayBizDeclarationBillControllerSettlementListParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SettlementItemVo[]>(
    `/salesgateway/seller/declaration/bill/${param0}/settlement-list`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_BizDeclarationBillController_settlementList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
