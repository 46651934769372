// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 作废还箱单 DELETE /salesgateway/buyer/container-leasing/dropoff-applications/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_cancelDropOffApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerCancelDropOffApplicationParams,
  options?: { [key: string]: any },
) {
  const { applicationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/buyer/container-leasing/dropoff-applications/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerLeasingBuyerController_cancelDropOffApplication',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询买家端待还箱号 GET /salesgateway/buyer/container-leasing/dropoff/wait-return/container/list */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listWaitReturnContainerForDropOff(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerListWaitReturnContainerForDropOffParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SimpleContainerVO[]>(
    '/salesgateway/buyer/container-leasing/dropoff/wait-return/container/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_listWaitReturnContainerForDropOff',
      },
      params: {
        ...params,
        vo: undefined,
        ...params['vo'],
      },
      ...(rest || {}),
    },
  );
}

/** 订单金额信息 POST /salesgateway/buyer/container-leasing/order/money */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryOrderMoney(
  body: API.QueryOrderVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OrderMoneyVo>('/salesgateway/buyer/container-leasing/order/money', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_queryOrderMoney',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 提交订单接口 POST /salesgateway/buyer/container-leasing/orders */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_createOrder(
  body: API.CreateOrderVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/buyer/container-leasing/orders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_createOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询订单详情 GET /salesgateway/buyer/container-leasing/orders/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryOrderParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OrderWithPerformanceVo>(
    `/salesgateway/buyer/container-leasing/orders/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_queryOrder',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 取消订单 DELETE /salesgateway/buyer/container-leasing/orders/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_cancelOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerCancelOrderParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/buyer/container-leasing/orders/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_cancelOrder',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询订单还箱记录列表 GET /salesgateway/buyer/container-leasing/orders/${param0}/containers */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listContainers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerListContainersParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ListContainerResponse>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/containers`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_listContainers',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询订单还箱记录列表 GET /salesgateway/buyer/container-leasing/orders/${param0}/drop-off */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listDropOffApplications(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerListDropOffApplicationsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ListDropOffApplicationResponse>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/drop-off`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_listDropOffApplications',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询订单可选择的还箱堆场列表 GET /salesgateway/buyer/container-leasing/orders/${param0}/dropOff/yards */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryDropOffAvailableYards(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryDropOffAvailableYardsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BaseYardVo[]>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/dropOff/yards`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryDropOffAvailableYards',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询订单提箱记录列表 GET /salesgateway/buyer/container-leasing/orders/${param0}/pickup */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listPickupApplications(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerListPickupApplicationsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ListPickupApplicationResponse>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/pickup`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_listPickupApplications',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 申请提箱 POST /salesgateway/buyer/container-leasing/orders/${param0}/pickup-applications */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_addPickupApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerAddPickupApplicationParams,
  body: API.AddPickupApplicationVo,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/pickup-applications`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_addPickupApplication',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询订单可申请提箱数量 GET /salesgateway/buyer/container-leasing/orders/${param0}/pickup-available */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryAvailablePickupAmount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryAvailablePickupAmountParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<number>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/pickup-available`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryAvailablePickupAmount',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 查询订单可申请提箱数量v2 GET /salesgateway/buyer/container-leasing/orders/${param0}/pickup-available/v2 */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryAvailableAmountForPickupCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryAvailableAmountForPickupCityParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<number>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/pickup-available/v2`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryAvailableAmountForPickupCity',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 查询订单可选择的提箱堆场列表 GET /salesgateway/buyer/container-leasing/orders/${param0}/pickup/yards */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryPickupAvailableYards(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryPickupAvailableYardsParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BaseYardVo[]>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/pickup/yards`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryPickupAvailableYards',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 申请退款 POST /salesgateway/buyer/container-leasing/orders/${param0}/refunds */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_refundOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerRefundOrderParams,
  body: API.RefundOrderVo,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/buyer/container-leasing/orders/${param0}/refunds`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_refundOrder',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 申请还箱 POST /salesgateway/buyer/container-leasing/orders/${param0}/return-applications */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_addReturnApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerAddReturnApplicationParams,
  body: API.AddDropOffApplicationVo,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/return-applications`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_addReturnApplication',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询订单可申请还箱数量 GET /salesgateway/buyer/container-leasing/orders/${param0}/return-available */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryAvailableReturnAmount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryAvailableReturnAmountParams,
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<number>(
    `/salesgateway/buyer/container-leasing/orders/${param0}/return-available`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryAvailableReturnAmount',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询租箱订单列表 POST /salesgateway/buyer/container-leasing/orders/lists */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listOrders(
  body: API.ListContainerLeasingOrdersVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerLeasingOrdersVo>(
    '/salesgateway/buyer/container-leasing/orders/lists',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_listOrders',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 快速提箱列表 GET /salesgateway/buyer/container-leasing/orders/quick-return-box/list */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listQuickReturnBox(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerListQuickReturnBoxParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QuickReturnBoxResponse>(
    '/salesgateway/buyer/container-leasing/orders/quick-return-box/list',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_listQuickReturnBox',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查看提箱申请单 GET /salesgateway/buyer/container-leasing/pickup-applications/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryPickupApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryPickupApplicationParams,
  options?: { [key: string]: any },
) {
  const { applicationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickupApplicationDetailVo>(
    `/salesgateway/buyer/container-leasing/pickup-applications/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryPickupApplication',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 确认已提箱 PUT /salesgateway/buyer/container-leasing/pickup-applications/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_confirmPickupApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerConfirmPickupApplicationParams,
  body: API.ConfirmPickupApplicationVo,
  options?: { [key: string]: any },
) {
  const { applicationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/buyer/container-leasing/pickup-applications/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerLeasingBuyerController_confirmPickupApplication',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 作废提箱单 DELETE /salesgateway/buyer/container-leasing/pickup-applications/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_cancelPickupApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerCancelPickupApplicationParams,
  options?: { [key: string]: any },
) {
  const { applicationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/buyer/container-leasing/pickup-applications/${param0}`, {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerLeasingBuyerController_cancelPickupApplication',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 租箱商品详情查询接口 GET /salesgateway/buyer/container-leasing/products/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryContainerLeasingProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryContainerLeasingProductParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ContainerLeasingProductVo>(
    `/salesgateway/buyer/container-leasing/products/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryContainerLeasingProduct',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取商品订单试算结果接口 POST /salesgateway/buyer/container-leasing/products/${param0}/trial */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryProductOrderTrial(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryProductOrderTrialParams,
  body: API.QueryProductOrderTrialVo,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductOrderTrialVo>(
    `/salesgateway/buyer/container-leasing/products/${param0}/trial`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryProductOrderTrial',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查看还箱申请单 GET /salesgateway/buyer/container-leasing/return-applications/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryReturnApplication(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryReturnApplicationParams,
  options?: { [key: string]: any },
) {
  const { applicationId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ReturnApplicationDetailVo>(
    `/salesgateway/buyer/container-leasing/return-applications/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerLeasingBuyerController_queryReturnApplication',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 订单统计信息 GET /salesgateway/buyer/container-leasing/summary/orders */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listOrderSummary(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerListOrderSummaryParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OrderSummaryVo>('/salesgateway/buyer/container-leasing/summary/orders', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_listOrderSummary',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取订单查询过滤器 GET /salesgateway/buyer/container-leasing/summary/orders/filters */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_listOrderFilters(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.OrderFilterVo[]>(
    '/salesgateway/buyer/container-leasing/summary/orders/filters',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_listOrderFilters',
      },
      ...(rest || {}),
    },
  );
}

/** 查询堆场 GET /salesgateway/buyer/container-leasing/yards/${param0} */
export async function OperationId_salesgateway_ContainerLeasingBuyerController_queryYard(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerLeasingBuyerControllerQueryYardParams,
  options?: { [key: string]: any },
) {
  const { yardId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.YardVo>(`/salesgateway/buyer/container-leasing/yards/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerLeasingBuyerController_queryYard',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}
