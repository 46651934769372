import { loadScript } from './common';

async function loadCss(href: string, $: any) {
  const rid = btoa(href).replace(/\=/gim, '');
  return new Promise((resolve, reject) => {
    if (!document.querySelector(`style[rid=${rid}]`)) {
      const link = document.createElement('style');
      link.setAttribute('rid', rid);
      $.ajax({
        url: href,
        success: (res: string) => {
          link.innerHTML = res;
          document.body.appendChild(link);
          resolve(true);
        },
        error: () => {
          reject();
        },
      });
    }
  });
}
/**
 * 加载船讯网 Api
 * @param $ jquery $
 * @returns
 */
const getShipScriptStr = ($: any): Promise<string> => {
  return new Promise((resolve) => {
    $.ajax({
      url: 'https://api.shipxy.com/h5s/api/3.5/?k=5eb083de56314d169e628a64ed8d5dc8',
      success: (res: string) => {
        resolve(res);
      },
    });
  });
};

/**
 * 加载船讯网sdk
 */
export const loadShipSite = async () => {
  try {
    await loadScript('https://api.shipxy.com/h5s/api/3.5/plugins/jquery/jquery.min.js');
    const str = await getShipScriptStr((window as any).$);
    const elanemapBasePath = str.match(/elanemap_basePath=".+";/gim)?.[0]?.replace(/elanemap_basePath="|";/gim, '');
    const resourceArr = str.match(/elanemap_basePath\+'[_\-\/a-zA-Z0-9\.]+"/gim);
    if (resourceArr?.length) {
      for (let i = 0; i < resourceArr.length; i++) {
        const item = resourceArr[i];
        const sourcePath = `${elanemapBasePath}${item.replace(/elanemap_basePath\+'|"/gim, '')}`;
        if (/\.css/.test(sourcePath)) {
          loadCss(sourcePath, (window as any).$);
        } else {
          await loadScript(sourcePath);
        }
      }
    }
  } catch (e) {
    throw e;
  }
};
