// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询商品详情 GET /sinotransbooking/v1/operation/products/${param0} */
export async function OperationId_sinotransbooking_OperationProductController_getProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationProductControllerGetProductParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductVO>(`/sinotransbooking/v1/operation/products/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationProductController_getProduct',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 推荐商品 PUT /sinotransbooking/v1/operation/products/${param0}/recommend-status/${param1} */
export async function OperationId_sinotransbooking_OperationProductController_recommendItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationProductControllerRecommendItemParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, recommendStatus: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/sinotransbooking/v1/operation/products/${param0}/recommend-status/${param1}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationProductController_recommendItem',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查看商品日志 GET /sinotransbooking/v1/operation/products/item-logs */
export async function OperationId_sinotransbooking_OperationProductController_pageItemLog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationProductControllerPageItemLogParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductOperationLogDTO>(
    '/sinotransbooking/v1/operation/products/item-logs',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationProductController_pageItemLog',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 查看商品版本 GET /sinotransbooking/v1/operation/products/item-versions */
export async function OperationId_sinotransbooking_OperationProductController_pageItemVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationProductControllerPageItemVersionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVersionVO>(
    '/sinotransbooking/v1/operation/products/item-versions',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationProductController_pageItemVersion',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 查看商品版本详情 GET /sinotransbooking/v1/operation/products/item-versions/${param0} */
export async function OperationId_sinotransbooking_OperationProductController_getItemVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationProductControllerGetItemVersionParams,
  options?: { [key: string]: any },
) {
  const { versionId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductVersionVO>(
    `/sinotransbooking/v1/operation/products/item-versions/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationProductController_getItemVersion',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 商品列表 POST /sinotransbooking/v1/operation/products/page */
export async function OperationId_sinotransbooking_OperationProductController_searchPageProduct(
  body: API.ProductQueryV2,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/operation/products/page', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationProductController_searchPageProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 预览商品 POST /sinotransbooking/v1/operation/products/preview */
export async function OperationId_sinotransbooking_OperationProductController_previewPageProduct(
  body: API.ProductQueryV2,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/operation/products/preview', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationProductController_previewPageProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品 POST /sinotransbooking/v1/operation/products/search */
export async function OperationId_sinotransbooking_OperationProductController_pageProduct(
  body: API.ProductQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/operation/products/search', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationProductController_pageProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询船期价格表 GET /sinotransbooking/v1/operation/products/shipping-date-prices */
export async function OperationId_sinotransbooking_OperationProductController_listShippingDatePrice(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationProductControllerListShippingDatePriceParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShippingDatePriceDTO[]>(
    '/sinotransbooking/v1/operation/products/shipping-date-prices',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_OperationProductController_listShippingDatePrice',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 启用/禁用 PUT /sinotransbooking/v1/operation/products/status/${param0}/${param1} */
export async function OperationId_sinotransbooking_OperationProductController_enableItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationProductControllerEnableItemParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, status: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/operation/products/status/${param0}/${param1}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationProductController_enableItem',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}
