import dayjs from 'dayjs';

export function DateFormat(props: { date?: string; format: string; relative?: boolean }) {
  const { date, format, relative } = props;
  const str = () => {
    if (relative) {
      const seconds = dayjs().diff(dayjs(date), 'seconds');
      return (dayjs(dayjs(date)) as any).fromNow(seconds < 60);
    }
    return date ? dayjs(date).format(format) : '-';
  };
  return <>{str()}</>;
}
