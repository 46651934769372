// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 买家订单列表 GET /sinotransbooking/v1/buyer/performances */
export async function OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerPagePerformanceOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderDetailVO>('/sinotransbooking/v1/buyer/performances', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrder',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 订舱 POST /sinotransbooking/v1/buyer/performances */
export async function OperationId_sinotransbooking_PerformanceBuyerController_createPerformance(
  body: API.PerformanceCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceBaseResponse>('/sinotransbooking/v1/buyer/performances', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_createPerformance',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家订单详情 GET /sinotransbooking/v1/buyer/performances/${param0} */
export async function OperationId_sinotransbooking_PerformanceBuyerController_detail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailVO>(
    `/sinotransbooking/v1/buyer/performances/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_detail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 买家上传附件 POST /sinotransbooking/v1/buyer/performances/${param0}/attachment */
export async function OperationId_sinotransbooking_PerformanceBuyerController_uploadAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerUploadAttachmentParams,
  body: API.UploadDocInfoRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/buyer/performances/${param0}/attachment`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_uploadAttachment',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 买家删除附件 DELETE /sinotransbooking/v1/buyer/performances/${param0}/attachment/${param1} */
export async function OperationId_sinotransbooking_PerformanceBuyerController_deleteAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerDeleteAddressParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, attachmentId: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/sinotransbooking/v1/buyer/performances/${param0}/attachment/${param1}`,
    {
      method: 'DELETE',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_deleteAddress',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 买家上传（提单）附件 POST /sinotransbooking/v1/buyer/performances/${param0}/attachment/pickup */
export async function OperationId_sinotransbooking_PerformanceBuyerController_uploadPickupAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerUploadPickupAttachmentParams,
  body: API.UploadPickupDocInfoRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UploadPickupDocInfoResponse>(
    `/sinotransbooking/v1/buyer/performances/${param0}/attachment/pickup`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceBuyerController_uploadPickupAttachment',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家附件查询 GET /sinotransbooking/v1/buyer/performances/${param0}/attachments */
export async function OperationId_sinotransbooking_PerformanceBuyerController_attachmentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerAttachmentListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderAttachmentVo[]>(
    `/sinotransbooking/v1/buyer/performances/${param0}/attachments`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_attachmentList',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 创建地址 POST /sinotransbooking/v1/buyer/performances/create/address */
export async function OperationId_sinotransbooking_PerformanceBuyerController_createAddress(
  body: API.CreateAddressRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/buyer/performances/create/address', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_createAddress',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 创建联系人 POST /sinotransbooking/v1/buyer/performances/create/contact */
export async function OperationId_sinotransbooking_PerformanceBuyerController_createContact(
  body: API.CreateAndEditEnterpriseStaffRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/buyer/performances/create/contact', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_createContact',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 删除地址 DELETE /sinotransbooking/v1/buyer/performances/delete/address */
export async function OperationId_sinotransbooking_PerformanceBuyerController_deleteAddress_2(
  body: API.DeleteAddressRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/sinotransbooking/v1/buyer/performances/delete/address', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_deleteAddress',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 删除联系人 DELETE /sinotransbooking/v1/buyer/performances/delete/contact */
export async function OperationId_sinotransbooking_PerformanceBuyerController_deleteContact(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerDeleteContactParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/sinotransbooking/v1/buyer/performances/delete/contact', {
    method: 'DELETE',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_deleteContact',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取异常 GET /sinotransbooking/v1/buyer/performances/exception */
export async function OperationId_sinotransbooking_PerformanceBuyerController_getException(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceExceptionVO[]>(
    '/sinotransbooking/v1/buyer/performances/exception',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_getException',
      },
      ...(rest || {}),
    },
  );
}

/** 新增目的港费用项 POST /sinotransbooking/v1/buyer/performances/expense/destination-port */
export async function OperationId_sinotransbooking_PerformanceBuyerController_createExpense(
  body: API.CreateExpenseConfigRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/buyer/performances/expense/destination-port', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_createExpense',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家订单地图 GET /sinotransbooking/v1/buyer/performances/map-graphs */
export async function OperationId_sinotransbooking_PerformanceBuyerController_listMapGraph(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.MapGraphVO[]>('/sinotransbooking/v1/buyer/performances/map-graphs', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_listMapGraph',
    },
    ...(rest || {}),
  });
}

/** 消息查询 GET /sinotransbooking/v1/buyer/performances/message/${param0} */
export async function OperationId_sinotransbooking_PerformanceBuyerController_messageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerMessageListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MessageItemDTO[]>(
    `/sinotransbooking/v1/buyer/performances/message/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_messageList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询地址 GET /sinotransbooking/v1/buyer/performances/search/address */
export async function OperationId_sinotransbooking_PerformanceBuyerController_searchAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerSearchAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageEnterpriseAddressDTO>(
    '/sinotransbooking/v1/buyer/performances/search/address',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_searchAddress',
      },
      params: {
        ...params,
        request: undefined,
        ...params['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 查询联系人 GET /sinotransbooking/v1/buyer/performances/search/contact */
export async function OperationId_sinotransbooking_PerformanceBuyerController_searchContact(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerSearchContactParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageStaffDTO>('/sinotransbooking/v1/buyer/performances/search/contact', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_searchContact',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}

/** 买家订单列表分类统计数据 GET /sinotransbooking/v1/buyer/performances/sortCount */
export async function OperationId_sinotransbooking_PerformanceBuyerController_getPerformanceOrderSortCount(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderSortCountVo>(
    '/sinotransbooking/v1/buyer/performances/sortCount',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceBuyerController_getPerformanceOrderSortCount',
      },
      ...(rest || {}),
    },
  );
}

/** 修改地址 PUT /sinotransbooking/v1/buyer/performances/update/address */
export async function OperationId_sinotransbooking_PerformanceBuyerController_updateAddress(
  body: API.EditAddressRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/buyer/performances/update/address', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_updateAddress',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 修改联系人 PUT /sinotransbooking/v1/buyer/performances/update/contact */
export async function OperationId_sinotransbooking_PerformanceBuyerController_updateContact(
  body: API.CreateAndEditEnterpriseStaffRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/buyer/performances/update/contact', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_PerformanceBuyerController_updateContact',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家工作台订单列表 GET /sinotransbooking/v1/buyer/performances/workbench */
export async function OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrderWorkBench(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerPagePerformanceOrderWorkBenchParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderDetailWorkBenchVO>(
    '/sinotransbooking/v1/buyer/performances/workbench',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrderWorkBench',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 买家工作台按城市规整订单列表 POST /sinotransbooking/v1/buyer/performances/workbench/sortByCity */
export async function OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrderWorkBenchSortByCity(
  body: API.PerformanceQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailWorkBenchSortByCityVO[]>(
    '/sinotransbooking/v1/buyer/performances/workbench/sortByCity',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrderWorkBenchSortByCity',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家工作台按时间规整订单列表 GET /sinotransbooking/v1/buyer/performances/workbench/sortByTime */
export async function OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrderWorkBenchSortByTime(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceBuyerControllerPagePerformanceOrderWorkBenchSortByTimeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailWorkBenchSortByTimeVO>(
    '/sinotransbooking/v1/buyer/performances/workbench/sortByTime',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_PerformanceBuyerController_pagePerformanceOrderWorkBenchSortByTime',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}
