import axios from 'axios';
import Cookies from 'js-cookie';
import { resolveCurrency } from './currency';
import BigNumber from 'bignumber.js';
import { CurrencyType } from '../enums/currency';
import html2canvas from 'html2canvas';

export const storage = {
  setEnterpriseId(key: string, enterpriseId?: number | string) {
    if (enterpriseId) {
      localStorage.setItem(key, `${enterpriseId}`);
      Cookies.set(key, `${enterpriseId}`);
    } else {
      localStorage.removeItem(key);
      Cookies.remove(key);
    }
  },
  getEnterpriseId(key: string) {
    return Cookies.get(key) || localStorage.getItem(key);
  },
  setEnterpriseType(key: string, type: string = 'buier') {
    localStorage.setItem(key, type);
    Cookies.set(key, type);
  },
  getEnterpriseType(key: string) {
    return Cookies.get(key) || localStorage.getItem(key);
  },
};

/** 查找父元素 */
export function findParentNode(className: string, childNode?: HTMLElement): HTMLElement | undefined {
  const parent = childNode?.parentElement;
  let node: HTMLElement | undefined = undefined;
  if (parent && parent.classList.contains(className)) {
    node = parent;
  } else if (parent) {
    node = findParentNode(className, parent);
  }
  return node;
}

/**
 * 生成唯一id
 * @returns
 */
export function createGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * 下载
 * @param filePath 文件 url
 * @param fileName 文件名
 * @param type 加载方式：'open' 新窗口 ｜ 'a' 本窗口
 */
export function download(filePath: string, fileName: string, type: 'open' | 'a' = 'a') {
  if (type === 'a') {
    const link = document.createElement('a');
    link.download = fileName;
    link.rel = 'noopener';
    link.href = filePath;
    if (link.origin !== location.origin) {
      axios
        .get(filePath, { responseType: 'blob' })
        .then((data) => {
          link.href = URL.createObjectURL(data.data);
          setTimeout(() => {
            link.dispatchEvent(new MouseEvent('click'));
          });
          setTimeout(() => {
            URL.revokeObjectURL(link.href);
          }, 10000);
        })
        .catch((e) => {
          console.error(e);
          link.target = '_blank';
          link.href = filePath;
          link.dispatchEvent(new MouseEvent('click'));
        });
    } else {
      link.dispatchEvent(new MouseEvent('click'));
    }
  } else {
    window.open(filePath);
  }
}

export const ruleRequired = (message: string) => [{ required: true, message }];

export function loadScript(src: string): Promise<boolean> {
  const rid = btoa(src).replace(/\=/gim, '');
  return new Promise((resolve, reject) => {
    let el: any = document.querySelector(`script[rid=${rid}]`);
    if (el) {
      resolve(true);
      return;
    }
    el = document.createElement('script');
    el.setAttribute('src', src);
    el.setAttribute('rid', rid);
    document.body.appendChild(el);
    el.onload = () => {
      resolve(true);
    };
    el.onerror = () => {
      reject();
    };
  });
}

interface UserInfoProps {
  accountId: string;
  name: string;
  email: string;
  mobile: string;
}
/**
 * @method 获取当前用户身份 卖家/买家
 */
export function userIdentity() {
  const userInfo = sessionStorage.getItem('_easygo_user') ?? '';
  const userInfoFormat = userInfo ? JSON.parse(userInfo as string) : '';
  const { accountId } = userInfoFormat as UserInfoProps;
  const userTypeInfo = localStorage.getItem(`${accountId}E-TYPE`) ?? {};
  return userTypeInfo;
}

// 金额集合转str eg: [{price: 20, currency: 'rmb'}, {price: 20, currency: '$'}] => ¥20.00 + $20.00
export function resolveAmountsToStr(
  chargeList?: { price: any; currency: string }[],
  options?: { type?: Parameters<typeof resolveCurrency>[1] } & Parameters<typeof resolveCurrency>[2]
) {
  const allCharge =
    sortCurrency(chargeList)?.reduce((all, charge) => {
      if (/\d/.test(`${charge.price!}`) && +charge.price! >= 0 && charge.currency) {
        const currency = charge.currency;
        all[currency] = (+all[currency] || 0) + +(charge.price as number);
      }
      return all;
    }, {} as any) || {};
  const allStr = Object.entries(allCharge)
    .filter(([currency, amount]) => amount)
    .map(([currency, amount]) => resolveCurrency({ price: amount as number, currency: currency! as any }, options?.type || 'symbol', options))
    .join(' + ');
  return allStr;
}
// 币种排序
const CurrencySortRule = [CurrencyType.DOLLAR, CurrencyType.RMB];
function sortCurrency(prices?: { price: any; currency: any }[]) {
  return prices?.sort((a, b) => CurrencySortRule.findIndex((val) => val === a.currency) - CurrencySortRule.findIndex((val) => val === b.currency));
}

export function labelObjToList(obj: Record<string, any>) {
  return Object.entries(obj).map(([value, label]) => ({ value, label }));
}

export function findLabel(val: any, list: { value: any; [key: string]: any }[]) {
  return list.find((e) => e.value === val)?.label;
}

export function uuid() {
  const temp_url = URL.createObjectURL(new Blob());
  const uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  return uuid.slice(uuid.lastIndexOf('/') + 1);
}

interface TextOverProps {
  maxWidth?: number;
  fontWidth: number;
  textContainer: HTMLElement;
  line?: number;
}
interface TextOverReturn {
  isOver: boolean;
  maxCharCount: number;
  originalText: string;
}
export function isTextOver({ textContainer, maxWidth, fontWidth, line = 1 }: TextOverProps): TextOverReturn {
  const originalText = textContainer?.textContent || '';

  const containerWidth = maxWidth || textContainer?.clientWidth;

  // 计算能容纳的最大字符数
  const maxCharCount = Math.floor(containerWidth / fontWidth) * line;
  // 判断是否超出
  if (originalText.length > maxCharCount) {
    return { isOver: true, maxCharCount, originalText };
  } else {
    return { isOver: false, maxCharCount, originalText };
  }
}
export const resolveQuery = (search: string) => {
  return search
    .slice(1)
    .split('&')
    .reduce((all, curr) => {
      if (curr) {
        const [key, value] = curr.split('=');
        return { ...all, [key]: value };
      } else {
        return all;
      }
    }, {} as Record<string, string>);
};

export const togoWebsiteFromTradeCenter = (): string => {
  const currentLocale = Cookies.get(process.env.NEXT_PUBLIC_LANGUAGE!) || 'zh';

  const websitePath = {
    dev: `//easygo-web-${process.env.VERSION}.dev-easygo-multi.easy-go.top/`,
    // 多版本 预留代码
    test: `//easygo-web-${process.env.VERSION}.test.easy-go.top/`,
    pre: 'https://trade.pre.easy-go.top/',
    prod: 'https://trade.easy-go.net/',
  }[process.env.APP_ENV!];

  // 当前是否在交易中心（有两种情况可以）
  const isTradeHost = location?.hostname?.includes('-trade-');

  return `${isTradeHost ? websitePath : ''}/${currentLocale}/`;
};

type FeeItem = { currency: any; price: any; count: any };
export function getAmountComputeStr(fees: FeeItem[], formator: (...args: any[]) => string) {
  if (fees && fees.length) {
    // const amountStr = resolveAmountsToStr(
    //   fees.map(({ currency, price, count }) => ({ price: BigNumber(price).times(count).toNumber(), currency })),
    //   { precision: 0 }
    // );
    const amountStr = formator(
      fees.map(({ currency, price, count }) => ({ amount: BigNumber(price).times(count).toNumber(), currency })),
      { precision: 2 }
    );
    return fees.map(({ price, count }) => `${(Number(price) || 0).toFixed(2)}*${count}`).join(' + ') + ` = ${amountStr}`;
  } else {
    return '-';
  }
}

interface CcopyElementToClipboardProps {
  element: HTMLElement;
  format?: 'png' | 'jpg' | 'jpeg';
  onSuccess?: (blob: Blob | null) => void;
  onError?: () => void;
  onFinally?: () => void;
}
/**
 * @method 截屏传入元素、生成图片并复制
 * @param element 被截屏的元素
 * @param format 生成的图片格式
 */
export function copyElementToClipboard({ element, format = 'png', onSuccess, onError, onFinally }: CcopyElementToClipboardProps) {
  if (typeof html2canvas !== 'undefined' && 'clipboard' in navigator) {
    // 确保html2canvas和Clipboard API都支持
    html2canvas(element, { useCORS: true /* 其他配置项 */ }).then((canvas) => {
      canvas.toBlob((blob) => {
        if (blob) {
          // 将canvas转换为Blob对象
          const url = URL.createObjectURL(blob);
          // 使用Clipboard API写入数据
          navigator.clipboard
            .write([new ClipboardItem({ [blob.type]: blob })])
            .then(() => {
              console.log('Image copied to clipboard');
              onSuccess?.(blob);
            })
            .catch(() => {
              // 如果Clipboard API写入失败，尝试回退到模拟复制事件
              fallbackCopy(canvas.toDataURL());
              onError?.();
            })
            .finally(() => {
              URL.revokeObjectURL(url);
              onFinally?.();
            });
        }
      }, `image/${format}`); // 或者选择其他格式如'image/jpeg'
    });
  } else {
    // 如果不支持html2canvas或Clipboard API，则使用回退方案
    fallbackCopy();
    onError?.();
    onFinally?.();
  }
}

/**
 * @method 文本复制
 * @description 兼容性回退方案
 * @param text 复制的文案
 */
function fallbackCopy(text = '') {
  // 创建一个隐藏的textarea用于模拟复制操作
  const textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.opacity = '0';
  textarea.value = text;

  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand('copy');
    console.log('Copied to clipboard using fallback method');
  } catch (err) {
    console.error('Unable to copy via fallback method', err);
  } finally {
    document.body.removeChild(textarea);
  }
}
