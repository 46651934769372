import React, { useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';

import type { ReactNode, FC } from 'react';
import styles from './index.module.less';
import { isTextOver } from '@easygo/utils/src/utils';
interface PopoverProps {
  title?: string | ReactNode;
  content?: string;
  fontWidth?: number;
  maxWidth: number;
  trigger?: 'hover' | 'focus' | 'click';
  children?: any;
}
const PopoverComponent: FC<PopoverProps> = (props) => {
  const { maxWidth = 0, fontWidth = 12 } = props;
  const ref = useRef() as any;
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const { title = '', content = '', trigger = 'hover', children } = props;
  const customStyle = maxWidth ? { maxWidth } : {};
  useEffect(() => {
    const { isOver } = isTextOver({ textContainer: ref?.current, maxWidth, fontWidth });
    setPopoverVisible(isOver);
  }, [ref, maxWidth, children]);

  return (
    <div ref={ref} className={styles.popoverContainer}>
      {popoverVisible ? (
        <Popover content={content} title={title} trigger={trigger}>
          <div className={styles.popover} style={customStyle}>
            {children}
          </div>
        </Popover>
      ) : (
        children
      )}
    </div>
  );
};
export default PopoverComponent;
