// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询专区活动详情 GET /salesgateway/v2/open/zone/activity/detail */
export async function OperationId_salesgateway_OpenZoneActivityController_getActivityDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenZoneActivityControllerGetActivityDetailParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ZoneActivityDetailResponse>('/salesgateway/v2/open/zone/activity/detail', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OpenZoneActivityController_getActivityDetail',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
