import { Upload } from 'antd';
import type { UploadProps } from 'antd';
import { useMemo } from 'react';
import { useMemoizedFn } from 'ahooks';
import type { UploadFile } from 'antd';
import UploadUtils, { getFileAddress } from './utils';
import { utils } from '@easygo/utils';
import { flushSync } from 'react-dom';
import type { UploadComponentProps } from './interface';

export function UploadComponent<T = any>(props: UploadComponentProps<T>) {
  const { isPublic = false, children, beforeUpload, maxCount = 99, showButtonRelatedCount = false, fileList = [], ...restProps } = props;
  const onDownload = useMemoizedFn(async (file: UploadFile) => {
    const res = await getFileAddress(file.name, restProps.watermarkStyleName);
    utils.download(res as string, file.name);
  });
  const uploadProps = useMemo<UploadProps<T>>(() => {
    return {
      beforeUpload,
      maxCount,
      fileList,
      onDownload,
      customRequest: (options) => {
        return new UploadUtils().multiUpload(options.file as File, {
          isPublic: isPublic ?? false,
          onError: (e) => options?.onError?.(e),
          onProgress: (percent) => options?.onProgress?.({ percent }),
          onSuccess: (res) => {
            flushSync(() => {
              options?.onSuccess?.(res, options.file as any);
            });
          },
        });
      },
      ...restProps,
    };
  }, [isPublic, onDownload, restProps, beforeUpload]);
  return <Upload {...uploadProps}>{fileList?.length >= maxCount && showButtonRelatedCount ? null : children}</Upload>;
}
