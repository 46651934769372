// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 租箱-买家-超期费账单导出 GET /salesgateway/statement/api/containerDetention/buyer/${param0}/export */
export async function OperationId_salesgateway_StatementController_exportContainerDetentionBuyerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerDetentionBuyerStatementParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerDetention/buyer/${param0}/export`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerDetentionBuyerStatement',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 租箱-买家-超期费账单明细导出 GET /salesgateway/statement/api/containerDetention/buyer/export */
export async function OperationId_salesgateway_StatementController_exportContainerDetentionDetailBuyerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerDetentionDetailBuyerStatementParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/statement/api/containerDetention/buyer/export', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerDetentionDetailBuyerStatement',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 租箱-卖家-超期费账单导出 GET /salesgateway/statement/api/containerDetention/seller/${param0}/export */
export async function OperationId_salesgateway_StatementController_exportContainerDetentionSellerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerDetentionSellerStatementParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerDetention/seller/${param0}/export`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerDetentionSellerStatement',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 租箱-卖家-超期费账单明细导出 GET /salesgateway/statement/api/containerDetention/seller/export */
export async function OperationId_salesgateway_StatementController_exportContainerDetentionDetailSellerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerDetentionDetailSellerStatementParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/statement/api/containerDetention/seller/export', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerDetentionDetailSellerStatement',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 租箱-买家-租箱费账单导出 GET /salesgateway/statement/api/containerLease/buyer/${param0}/export */
export async function OperationId_salesgateway_StatementController_exportContainerLeaseBuyerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerLeaseBuyerStatementParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerLease/buyer/${param0}/export`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerLeaseBuyerStatement',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 租箱-买家-确认账单 PUT /salesgateway/statement/api/containerLease/buyer/confirm/${param0} */
export async function OperationId_salesgateway_StatementController_confirmContainerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerConfirmContainerStatementParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerLease/buyer/confirm/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_StatementController_confirmContainerStatement',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 租箱-买家-查看超期费对账单明细 POST /salesgateway/statement/api/containerLease/buyer/detentionDetails */
export async function OperationId_salesgateway_StatementController_getContainerBuyerDetentionDetails(
  body: API.GetDetentionDetailReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetDetentionDetailResponseVo>(
    '/salesgateway/statement/api/containerLease/buyer/detentionDetails',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_StatementController_getContainerBuyerDetentionDetails',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱-买家-查看对超期费账单列表 POST /salesgateway/statement/api/containerLease/buyer/detentions */
export async function OperationId_salesgateway_StatementController_getContainerBuyerDetentions(
  body: API.GetStatementReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetStatementResponseVo>(
    '/salesgateway/statement/api/containerLease/buyer/detentions',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_StatementController_getContainerBuyerDetentions',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱-买家-租箱费账单明细导出 GET /salesgateway/statement/api/containerLease/buyer/export */
export async function OperationId_salesgateway_StatementController_exportContainerLeaseDetailBuyerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerLeaseDetailBuyerStatementParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/statement/api/containerLease/buyer/export', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerLeaseDetailBuyerStatement',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 租箱-买家-异议账单 POST /salesgateway/statement/api/containerLease/buyer/objection/${param0} */
export async function OperationId_salesgateway_StatementController_objectionContainerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerObjectionContainerStatementParams,
  body: API.ObjectionVo,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerLease/buyer/objection/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_StatementController_objectionContainerStatement',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 租箱-卖家-异议账单处理 POST /salesgateway/statement/api/containerLease/buyer/objectionDone/${param0} */
export async function OperationId_salesgateway_StatementController_objectionDoneContainerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerObjectionDoneContainerStatementParams,
  body: API.ObjectionDoneVo,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerLease/buyer/objectionDone/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_StatementController_objectionDoneContainerStatement',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 租箱-买家-查看对账单明细 POST /salesgateway/statement/api/containerLease/buyer/statementDetails */
export async function OperationId_salesgateway_StatementController_getContainerBuyerStatementDetails(
  body: API.GetStatementDetailReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetStatementDetailResponseVo>(
    '/salesgateway/statement/api/containerLease/buyer/statementDetails',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_StatementController_getContainerBuyerStatementDetails',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱-买家-查看对账单列表 POST /salesgateway/statement/api/containerLease/buyer/statements */
export async function OperationId_salesgateway_StatementController_getContainerBuyerStatements(
  body: API.GetStatementReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetStatementResponseVo>(
    '/salesgateway/statement/api/containerLease/buyer/statements',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_StatementController_getContainerBuyerStatements',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱-买家-邮箱列表 GET /salesgateway/statement/api/containerLease/email/list */
export async function OperationId_salesgateway_StatementController_getEmails(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.GetEmailVo>('/salesgateway/statement/api/containerLease/email/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_StatementController_getEmails',
    },
    ...(rest || {}),
  });
}

/** 租箱-买家-保存邮箱 POST /salesgateway/statement/api/containerLease/email/save */
export async function OperationId_salesgateway_StatementController_saveEmails(
  body: API.GetEmailVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/statement/api/containerLease/email/save', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_StatementController_saveEmails',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 租箱-卖家-租箱费账单导出 GET /salesgateway/statement/api/containerLease/seller/${param0}/export */
export async function OperationId_salesgateway_StatementController_exportContainerLeaseSellerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerLeaseSellerStatementParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerLease/seller/${param0}/export`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerLeaseSellerStatement',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 租箱-卖家-查看超期费对账单明细 POST /salesgateway/statement/api/containerLease/seller/detentionDetails */
export async function OperationId_salesgateway_StatementController_getContainerSellerDetentionDetails(
  body: API.GetDetentionDetailReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetDetentionDetailResponseVo>(
    '/salesgateway/statement/api/containerLease/seller/detentionDetails',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_StatementController_getContainerSellerDetentionDetails',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱-卖家-查看对超期费账单列表 POST /salesgateway/statement/api/containerLease/seller/detentions */
export async function OperationId_salesgateway_StatementController_getContainerSellerDetentions(
  body: API.GetStatementReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetStatementResponseVo>(
    '/salesgateway/statement/api/containerLease/seller/detentions',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_StatementController_getContainerSellerDetentions',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱-卖家-租箱费账单明细导出 GET /salesgateway/statement/api/containerLease/seller/export */
export async function OperationId_salesgateway_StatementController_exportContainerLeaseDetailSellerStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerExportContainerLeaseDetailSellerStatementParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/statement/api/containerLease/seller/export', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_StatementController_exportContainerLeaseDetailSellerStatement',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 租箱-卖家-重新生成账单 POST /salesgateway/statement/api/containerLease/seller/remake/${param0} */
export async function OperationId_salesgateway_StatementController_reMakeStatement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayStatementControllerReMakeStatementParams,
  options?: { [key: string]: any },
) {
  const { statementId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/statement/api/containerLease/seller/remake/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_StatementController_reMakeStatement',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 租箱-卖家-查看对账单明细 POST /salesgateway/statement/api/containerLease/seller/statementDetails */
export async function OperationId_salesgateway_StatementController_getContainerSellerStatementDetails(
  body: API.GetStatementDetailReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetStatementDetailResponseVo>(
    '/salesgateway/statement/api/containerLease/seller/statementDetails',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_StatementController_getContainerSellerStatementDetails',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱-卖家-查看对账单列表 POST /salesgateway/statement/api/containerLease/seller/statements */
export async function OperationId_salesgateway_StatementController_getContainerSellerStatements(
  body: API.GetStatementReqVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetStatementResponseVo>(
    '/salesgateway/statement/api/containerLease/seller/statements',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_StatementController_getContainerSellerStatements',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
