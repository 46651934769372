import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { i18n } from 'next-i18next';

interface Nums {
  d: string;
  h: string;
  m: string;
  s: string;
}
function count(endTime: string | number): Nums {
  const dis = (new Date(endTime).getTime() - Date.now()) / 1000;
  const d = Math.floor(dis / 60 / 60 / 24);
  const h = Math.floor((dis / 60 / 60) % 24);
  const m = Math.floor((dis / 60) % 60);
  const s = Math.floor(dis % 60);
  if (dis <= 0) {
    return {
      d: '00',
      h: '00',
      m: '00',
      s: '00',
    };
  }
  return {
    d: d < 10 ? `0${d}` : String(d),
    h: h < 10 ? `0${h}` : String(h),
    m: `0${m}`.slice(-2),
    s: `0${s}`.slice(-2),
  };
}

export function CountDownx({ endTime, className = '', ...res }: { endTime: string } & React.HTMLAttributes<HTMLDivElement>) {
  const [nums, setNums] = useState(() => count(endTime));
  useEffect(() => {
    const timer = setTimeout(() => {
      setNums(count(endTime));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className={`${styles.wrap} ${className}`} {...res}>
      <p className={styles.num}>{nums.h}</p>
      <p className={styles.num}>{nums.m}</p>
      <p className={styles.num}>{nums.s}</p>
    </div>
  );
}

export function CountDown({ endTime, className = '', ...res }: { endTime: string | number } & React.HTMLAttributes<HTMLDivElement>) {
  const [nums, setNums] = useState(() => count(endTime));
  useEffect(() => {
    const timer = setTimeout(() => {
      setNums(count(endTime));
    }, 1000);
    return () => clearTimeout(timer);
  });
  return (
    <div className={`${styles.wrap} ${className}`} {...res}>
      {i18n?.t('before_close_rest', { ns: 'common' })}
      <p className={`${styles.num} ${styles.day}`}>{nums.d}</p>
      {i18n?.t('day', { ns: 'common' })}
      <p className={`${styles.num} ${styles.day}`}>{nums.h}</p>
      {i18n?.t('hour', { ns: 'common' })}
      <p className={`${styles.num} ${styles.day}`}>{nums.m}</p>
      {i18n?.t('minute', { ns: 'common' })}
      <p className={`${styles.num} ${styles.day}`}>{nums.s}</p>
      {i18n?.t('second', { ns: 'common' })}
    </div>
  );
}
