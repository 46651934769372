/** 订单操作 */
export enum ACTION_TYPE {
  /** 接单 */
  CONFIRM_ORDER = 'CONFIRM_ORDER',
  /** 修改订单 */
  EDIT_ORDER = 'EDIT_ORDER',
  /** 下单 */
  PLACE_ORDER = 'PLACE_ORDER',
}

/** 海运订单状态 */
export const ORDER_STATUS = {
  /** 全部 */
  ALL: '0',
  /** 待接单 */
  WAIT_ACCEPT: '1',
  /** 已接单待放仓 */
  ACCEPT: '2',
  /** 已放仓待起运 / 确认舱位 */
  CONFIRM_SO: '3',
  /** 已起运 */
  DEPARTURES: '4',
  /** 已完成 */
  FINISHED: '8',
  /** 已撤销 */
  CANCEL: '7',
  /** 已到达 */
  ARRIVE: '6',
  /** 确认提单 */
  RELEASE_BI: '5',
};

/** 订单状态 */
export const SEARCH_BUTTON_ENUM = {
  [ORDER_STATUS.ALL]: 'ALL',
  [ORDER_STATUS.WAIT_ACCEPT]: 'WAIT_ACCEPT',
  [ORDER_STATUS.ACCEPT]: 'ACCEPT',
  [ORDER_STATUS.CONFIRM_SO]: 'CONFIRM_SO',
  [ORDER_STATUS.DEPARTURES]: 'DEPARTURES_PORT',
  [ORDER_STATUS.RELEASE_BI]: 'RELEASED_BI',
  [ORDER_STATUS.ARRIVE]: 'ARRIVE_PORT',
  [ORDER_STATUS.CANCEL]: 'CANCEL',
  [ORDER_STATUS.FINISHED]: 'FINISHED',
};

/** 箱型枚举 */
export const BOX_TYPE = {
  '1': '20GP',
  '2': '40GP',
  '3': '40HQ',
  '4': '45GP',
};

/** 保舱服务 */
export const ENSURECABIN_TYPE = {
  '0': 'KEEP_CABIN_YES',
  '1': 'KEEP_CABIN_NO',
};

/** Free类型 */
export const BOX_FREE_MODE = {
  /** 票 */
  CABINET: 1,
  /** 柜 */
  TICKET: 2,
};

/** 付款状态 */
export const PAY_STATUS_OCEAN = {
  1: 'UNPAID', // 未付款
  2: 'PARTIAL_AYMENT', // 部分付款
  3: 'FULL_PAYMENT', // 全部付款
};

/** 海运履约节点状态 */
export enum PERFORMANCE_STATUS {
  /** 提交订单 */
  INIT = 1,
  /** 卖家接单 */
  ACCEPT = 2,
  /** 确认舱位 */
  CONFIRM_SO = 3,
  /** 提空箱 */
  LIFT = 4,
  /** 进港 */
  ARRIVAL = 5,
  /** 海关放行 */
  CUSTOMS_RELEASE = 6,
  /** 码头放行 */
  DOCK_RELEASE = 7,
  /** 提交补料 */
  SUBMIT_SI = 8,
  /** 上传提单样本 */
  UPLOAD_TEMP_BI = 9,
  /** 核对提单样本 */
  CONFIRM_BI = 10,
  /** 上船 */
  ON_BOARD = 11,
  /** 离港 */
  DEPARTURES = 12,
  /** 制作账单 */
  CRAEATE_BILL = 13,
  // /** 确认账单 */
  // CONFIRM_BILL = 14,
  /** 支付 */
  PAYMENT = 15,
  /** 上传提单 */
  UPLOAD_BI = 16,
  /** 确认提单 */
  RELEASE_BI = 17,
  /** 到港 */
  ARRIVE = 18,
  /** 还空 */
  RCVE = 19,
  /** 卖家拒单 */
  REJECT_ORDER = 20,
  /** 买家撤单 */
  CANCEL_ORDER = 21,
}

export const PERFORMANCE_STATUS_TEXT = {
  [PERFORMANCE_STATUS.INIT]: 'SUBMMIT_ORER',
  [PERFORMANCE_STATUS.ACCEPT]: 'SELLER_RECEIVE_ORDER',
  [PERFORMANCE_STATUS.CONFIRM_SO]: 'CONFIRM_SPACE',
  [PERFORMANCE_STATUS.LIFT]: 'PICKUP_EMPTY_BOX',
  [PERFORMANCE_STATUS.ARRIVAL]: 'ENTER_PORT',
  [PERFORMANCE_STATUS.CUSTOMS_RELEASE]: 'CUSTOMS_CLEARANCE',
  [PERFORMANCE_STATUS.DOCK_RELEASE]: 'DOCK_RELASE',
  [PERFORMANCE_STATUS.SUBMIT_SI]: 'SUBMIT_SI',
  [PERFORMANCE_STATUS.UPLOAD_TEMP_BI]: 'UPLOAD_TEMP_BI',
  [PERFORMANCE_STATUS.CONFIRM_BI]: 'CONFIRM_BI',
  [PERFORMANCE_STATUS.ON_BOARD]: 'ON_BOARD',
  [PERFORMANCE_STATUS.DEPARTURES]: 'DEPARTURES',
  [PERFORMANCE_STATUS.CRAEATE_BILL]: 'CRAEATE_BILL',
  [PERFORMANCE_STATUS.PAYMENT]: 'PAYMENT',
  [PERFORMANCE_STATUS.UPLOAD_BI]: 'UPLOAD_BI',
  [PERFORMANCE_STATUS.RELEASE_BI]: 'RELEASE_BI',
  [PERFORMANCE_STATUS.ARRIVE]: 'ARRIVE',
  [PERFORMANCE_STATUS.RCVE]: 'RCVE',
  [PERFORMANCE_STATUS.REJECT_ORDER]: 'REJECT_ORDER',
  [PERFORMANCE_STATUS.CANCEL_ORDER]: 'CANCEL_ORDER',
};

/** 提单类型 */
export const BILL_TYPE = {
  1: 'MBL',
  2: 'HBL',
};

/** 运输方式 */
export const SERVICE_TYPE = {
  1: 'CY-CY',
  2: 'CY-SD',
};

/** 付款方式 */
export const PAY_TYPE = {
  1: 'ADVANCE_PAY',
};

/** 付款方式 */
export const PAYMENT = {
  1: 'BALANCE_PAYMENT',
  2: 'ORDER_STATUS_UNKNOWN',
  3: 'CUSTOM_CREDIT_PAYMENT',
  4: 'TRANSFER_PAYMENT',
};

/** 放单方式 */
export const RELEASE_TYPE = {
  1: 'ORIGINAL_BILL',
  2: 'ELETRIC_BILL',
  3: 'OFFSIT_BILL',
};

/** 提单类型 */
export const BL_TYPE = {
  /** 样本提单 */
  SAMPLE: '1',
  /** 正本提单 */
  ORIG: '2',
};

export enum DOCTYPE_ENUM {
  /** 托书 */
  LETTER_ENTRUSTMENT = 1,
  /** SO */
  SO = 2,
  /** 补料 */
  RELENISHMENT = 3,
  /** 海关资料 */
  CUSTOM_INFORMATION = 4,
  /** 码头资料 */
  TERMINAL_INFORMATION = 5,
  /** 提单样本 */
  SAMPLIE_LADING_BILL = 6,
  /** 正本提单 */
  ORIGINAL_LADING_BILL = 7,
  /** 销售合同 */
  SALE_CONTRACT = 8,
  /** 发票 */
  INVOICE = 9,
  /** 装箱单 */
  PACKE_BILL = 10,
  /** 装箱方案 */
  PACKE_PLANE = 11,
  /** 装箱照片 */
  PACKE_PRICTUR = 12,
  /** VGM */
  VGM = 13,
  /** ISF */
  ISF = 14,
}

/** 附件类型 */
export const DOCINFO_TYPE = {
  1: 'LETTER',
  2: 'SO',
  3: 'FEED',
  4: 'CUSTOMS_INFO',
  5: 'TERMAINAL_INFO',
  6: 'SAMPLE_BILL_INFO',
  7: 'ORIGINAL_BILL',
  8: 'SALE_CONTRACT',
  9: 'INVOICE',
  10: 'PACKING_LIST',
  11: 'PACKING_PLANE',
  12: 'PACKING_PRICTUR',
  13: 'VGM',
  14: 'ISF',
};

/** 附加费类型 */
export const FEE_TYPE = {
  1: 'BOOKIING_FEE',
  2: 'FILES_FEE',
  3: 'LEAD_FEE',
  4: 'HOISTINGS_FEE',
  5: 'STOCK_FEE',
  6: 'MESSAGE_FEE',
  7: 'PACKING_FEE',
};

/** 签约类型 */
export enum PERFORMANCE_TYPE {
  BUIER = 13, // 买家
}

/** 异常类型 */
export const ABNORMAL_TYPE = {
  /** 船舶起运延误 */
  1: 'DELAY_SHIP_DEPARTURE',
  /** 船舶到港延误 */
  2: 'SHIP_ARRIVAL_DEALY',
  /** SO与订单信息不符 */
  3: 'INCONSISTENT_SO_INFORMATION',
  /** 截关时间前柜子未进港 */
  4: 'CLOASING_UN_ENTER_PORT',
  /** 集装箱未上船 */
  5: 'NOT_ON_BOARD',
  /** 船舶离港时间更改 */
  6: 'CHANGE_DEPARTURE_TIME',
};

/** 持箱人 */
export const HANDLE_BOXER = {
  /** 船公司 */
  UN_SELF_SUPPORT: 1,
  /** 自营 */
  SELF_SUPPORT: 2,
};

/** 卖家自营 */
export const SELLER_SELF = {
  /** 自营 */
  SELF_SUPPORT: 1,
  /** 非自营 */
  UN_SELF_SUPPORT: 2,
};

/** 提箱状态 */
export const SUTICASE_STATUS = {
  /** 已发放 */
  1: 'SUTICASE',
  /** 已作废 */
  2: 'UN_SUTICASE',
};

export enum SUTICASE_STATUS_ENUM {
  /** 已发放 */
  SUTICASE = 1,
  /** 已作废 */
  UN_SUTICASE = 2,
}

/** 还箱状态 */
export const RETURN_BOX_STATUS = {
  /** 待发放还箱单 */
  1: 'RETURN_STATUS_UNPROVIDED',
  /** 已发放还箱单 */
  2: 'RETURN_STATUS_PROVIDED',
  /** 已作废 */
  3: 'RETURN_STATUS_CANCELED',
};

export enum RETURN_BOX_STATUS_ENUMS {
  /** 待发放还箱单 */
  RETURN_STATUS_UNPROVIDED = 1,
  /** 已发放还箱单 */
  RETURN_STATUS_PROVIDED = 2,
  /** 已作废 */
  RETURN_STATUS_CANCELED = 3,
}

/** 提还箱类型 */
export enum RETURN_PICKUP_BOK_TYPE {
  /** 提箱 */
  PICKUP_BOX_BOX = 1,
  /** 还箱 */
  RETURN_BOX = 2,
}

/** 提单类型 */
export enum BillType {
  /** 海单 */
  BILL_MBL = 'BILL_MBL',
  /** 代理单 */
  BILL_HBL = 'BILL_HBL',
}

/** 提单类型 */
export const BillTypeTxt = {
  /** 海单 */
  [BillType.BILL_MBL]: 'MBL',
  /** 代理单 */
  [BillType.BILL_HBL]: 'HBL',
};

/** 库存状态 */
export enum InventoryStatus {
  /** 充足 */
  INVENTORY_STATUS_ADEQUATE = 'INVENTORY_STATUS_ADEQUATE',
  /** 紧张 */
  INVENTORY_STATUS_TIGHT = 'INVENTORY_STATUS_TIGHT',
  /** 售罄 */
  INVENTORY_STATUS_OUT = 'INVENTORY_STATUS_OUT',
}
/** 库存状态 */
export const InventoryStatusTxt = {
  /** 充足 */
  [InventoryStatus.INVENTORY_STATUS_ADEQUATE]: '库存充足',
  /** 紧张 */
  [InventoryStatus.INVENTORY_STATUS_TIGHT]: '库存紧张',
  /** 售罄 */
  [InventoryStatus.INVENTORY_STATUS_OUT]: '已售罄',
};

/** 库存状态-商品列表 */
export const InventoryStatusTxtForGoods = {
  /** 充足 */
  [InventoryStatus.INVENTORY_STATUS_ADEQUATE]: '充足',
  /** 紧张 */
  [InventoryStatus.INVENTORY_STATUS_TIGHT]: '紧张',
  /** 售罄 */
  [InventoryStatus.INVENTORY_STATUS_OUT]: '售罄',
};

/** 结算方式 */
export enum ORDER_SETTLEMENT_TYPE {
  /** 付款买单 */
  ORDER_SETTLEMENT_TYPE_PAY = 'ORDER_SETTLEMENT_TYPE_PAY',
  /** 账期 */
  ORDER_SETTLEMENT_TYPE_PERIOD = 'ORDER_SETTLEMENT_TYPE_PERIOD',
}
/** 结算方式 */
export const OrderSettlementTypeTxt = {
  /** 海单 */
  [ORDER_SETTLEMENT_TYPE.ORDER_SETTLEMENT_TYPE_PAY]: '付款买单',
  /** 代理单 */
  [ORDER_SETTLEMENT_TYPE.ORDER_SETTLEMENT_TYPE_PERIOD]: '账期',
};

/** 周几 */
export enum WEEK {
  /** 周一 */
  WEEK_MONDAY = 'WEEK_MONDAY',
  /** 周二 */
  WEEK_TUESDAY = 'WEEK_TUESDAY',
  /** 周三 */
  WEEK_WEDNESDAY = 'WEEK_WEDNESDAY',
  /** 周四 */
  WEEK_THURSDAY = 'WEEK_THURSDAY',
  /** 周五 */
  WEEK_FRIDAY = 'WEEK_FRIDAY',
  /** 周六 */
  WEEK_SATURDAY = 'WEEK_SATURDAY',
  /** 周日 */
  WEEK_SUNDAY = 'WEEK_SUNDAY',
}
/** 周几 */
export const WeekTxt = {
  /** 周一 */
  [WEEK.WEEK_MONDAY]: '周一',
  /** 周二 */
  [WEEK.WEEK_TUESDAY]: '周二',
  /** 周三 */
  [WEEK.WEEK_WEDNESDAY]: '周三',
  /** 周四 */
  [WEEK.WEEK_THURSDAY]: '周四',
  /** 周五 */
  [WEEK.WEEK_FRIDAY]: '周五',
  /** 周六 */
  [WEEK.WEEK_SATURDAY]: '周六',
  /** 周日 */
  [WEEK.WEEK_SUNDAY]: '周日',
};

/** 一级附加费类型 */
export enum TradeFeeType {
  TRADE_FEE_TYPE_COMMODITY = 'TRADE_FEE_TYPE_COMMODITY',
  TRADE_FEE_TYPE_EXTRA_CHARGE = 'TRADE_FEE_TYPE_EXTRA_CHARGE',
  TRADE_FEE_TYPE_SHIP_PORT = 'TRADE_FEE_TYPE_SHIP_PORT',
  TRADE_FEE_TYPE_PORT_TO_PORT = 'TRADE_FEE_TYPE_PORT_TO_PORT',
  TRADE_FEE_TYPE_SHIP_STATION = 'TRADE_FEE_TYPE_SHIP_STATION',
  TRADE_FEE_TYPE_DEPOSIT_BUYER = 'TRADE_FEE_TYPE_DEPOSIT_BUYER',
  TRADE_FEE_TYPE_DEPOSIT_SELLER = 'TRADE_FEE_TYPE_DEPOSIT_SELLER',
  TRADE_FEE_TYPE_COMMISSION = 'TRADE_FEE_TYPE_COMMISSION',
  TRADE_FEE_TYPE_OTHER = 'TRADE_FEE_TYPE_OTHER',
  TRADE_FEE_TYPE_TRAILER = 'TRADE_FEE_TYPE_TRAILER',
  TRADE_FEE_TYPE_CUSTOMS = 'TRADE_FEE_TYPE_CUSTOMS',
  TRADE_FEE_TYPE_DELIVERY = 'TRADE_FEE_TYPE_DELIVERY',
  TRADE_FEE_TYPE_CLEARANCE = 'TRADE_FEE_TYPE_CLEARANCE',
  TRADE_FEE_TYPE_DESTINATION_PORT = 'TRADE_FEE_TYPE_DESTINATION_PORT',
}
/** 一级附加费类型 */
export const TradeFeeTypeTxt = {
  [TradeFeeType.TRADE_FEE_TYPE_COMMODITY]: '商品',
  [TradeFeeType.TRADE_FEE_TYPE_EXTRA_CHARGE]: '附加费(商品附加费)',
  [TradeFeeType.TRADE_FEE_TYPE_SHIP_PORT]: '起运港',
  [TradeFeeType.TRADE_FEE_TYPE_PORT_TO_PORT]: '港到港',
  [TradeFeeType.TRADE_FEE_TYPE_SHIP_STATION]: '起运站',
  [TradeFeeType.TRADE_FEE_TYPE_DEPOSIT_BUYER]: '买家定金',
  [TradeFeeType.TRADE_FEE_TYPE_DEPOSIT_SELLER]: '卖家保证金',
  [TradeFeeType.TRADE_FEE_TYPE_COMMISSION]: '佣金',
  [TradeFeeType.TRADE_FEE_TYPE_OTHER]: '其他费用',
  [TradeFeeType.TRADE_FEE_TYPE_TRAILER]: '拖车费',
  [TradeFeeType.TRADE_FEE_TYPE_CUSTOMS]: '报关费',
  [TradeFeeType.TRADE_FEE_TYPE_DELIVERY]: '送仓费',
  [TradeFeeType.TRADE_FEE_TYPE_CLEARANCE]: '清关费',
  [TradeFeeType.TRADE_FEE_TYPE_DESTINATION_PORT]: '目的港费用',
};

/** 开票状态 */
export enum InvoiceStatus {
  /** 未开票 */
  INVOICE_STATUS_WAITING = 'INVOICE_STATUS_WAITING',
  /** 已开票 */
  INVOICE_STATUS_COMPLETE = 'INVOICE_STATUS_COMPLETE',
}
/** 开票状态 */
export const InvoiceStatusTxt = {
  /** 未开票 */
  [InvoiceStatus.INVOICE_STATUS_WAITING]: '未开票',
  /** 已开票 */
  [InvoiceStatus.INVOICE_STATUS_COMPLETE]: '已开票',
};

/** 对账单状态 */
export enum StatementStatus {
  /** 待确认 */
  WAITING = 'CONFIRM_STATUS_WAITING',
  /** 已确认 */
  FINISHED = 'CONFIRM_STATUS_CONFIRM',
  /** 已拒绝 */
  REJECT = 'CONFIRM_STATUS_REJECT',
}
/** 对账单状态 */
export const StatementStatusTxt = {
  /** 待确认 */
  [StatementStatus.WAITING]: '待确认',
  /** 已确认 */
  [StatementStatus.FINISHED]: '已确认',
  /** 已拒绝 */
  [StatementStatus.REJECT]: '已拒绝',
};

/** 支付状态 */
export enum StatementPayStatus {
  /** 待支付 */
  WAITING = 'STATEMENT_PAY_STATUS_WAITING',
  /** 已支付 */
  SUCCESS = 'STATEMENT_PAY_STATUS_SUCCESS',
  /** 部分支付 */
  PARTIAL = 'STATEMENT_PAY_STATUS_PARTIAL',
}
/** 支付状态 */
export const StatementPayStatusTxt = {
  /** 待支付 */
  [StatementPayStatus.WAITING]: '待支付',
  /** 已支付 */
  [StatementPayStatus.SUCCESS]: '已支付',
  /** 部分支付 */
  [StatementPayStatus.PARTIAL]: '部分支付',
};
