import { Input, message, Spin } from 'antd';
import { useMemo, useState, useRef, useEffect } from 'react';
import type { InputProps } from 'antd';
import type { request } from '@easygo/service';
import type { AxiosResponse } from 'axios';
import styles from './index.module.less';
import { useMemoizedFn, useRequest } from 'ahooks';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export type ValidateInputState = {
  loading: boolean;
  seconds: number;
};

export type ValidateInputLabels = {
  normal?: string;
  loading?: string;
};

export type ValidateInputProps = InputProps & {
  label: string;
  renderText?: (state: ValidateInputState, label?: ValidateInputLabels) => JSX.Element | string | undefined;
  request: () => Promise<AxiosResponse<request.IResponse<{ nextTime: number }>> | null>;
  message?: string;
};

const defultRenderText = (state: ValidateInputState, label: ValidateInputLabels) => {
  if (state.loading) {
    return '';
  } else if (state.seconds) {
    return label.loading;
  } else {
    return label.normal;
  }
};

export function ValidateInput(props: ValidateInputProps) {
  const { renderText, label, request, message: messageStr, ...restProps } = props;
  const [seconds, setSeconds] = useState(0);
  const timer = useRef<{ time?: any; second: number }>({
    time: null,
    second: 0,
  });
  const loop = useMemoizedFn(() => {
    if (timer.current.time && timer.current.second === 0) {
      clearTimeout(timer.current.time);
      timer.current.time = null;
      return;
    }
    timer.current.time = setTimeout(() => {
      const nextSecond = timer.current.second - 1;
      timer.current.second = nextSecond;
      setSeconds(nextSecond);
      loop();
    }, 1000);
  });
  const server = useRequest(request, {
    manual: true,
    onSuccess: (res) => {
      messageStr && message.info(messageStr);
      let time = 60;
      if (res && res?.nextTime) {
        time = res?.nextTime;
      }
      timer.current.second = time;
      setSeconds(time);
      loop();
    },
  });
  const buttonText = useMemo<JSX.Element | string | undefined>(() => {
    let fn = renderText ? renderText : defultRenderText;
    return fn(
      { loading: server.loading, seconds },
      {
        normal: label,
        loading: `${seconds}s`,
      }
    );
  }, [renderText, server.loading, seconds, label]);
  const suffixButton = useMemo(() => {
    return (
      <div
        className={cx('button', { disabled: !!seconds })}
        onClick={() => {
          if (!server.loading && timer.current.second === 0) {
            server.run();
          }
        }}
      >
        <Spin size="small" spinning={server.loading}>
          {buttonText}
        </Spin>
      </div>
    );
  }, [buttonText, seconds, server]);
  useEffect(() => {
    return () => {
      if (timer.current.time) {
        clearTimeout(timer.current.time);
      }
    };
  }, []);
  return (
    <div className={styles.wrap}>
      <Input className={styles.input} suffix={suffixButton} {...restProps} />
    </div>
  );
}
