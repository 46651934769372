export enum TrackerEvents {
  /** 进入登录页面 */
  ENTER_LOGIN = 'enter_login',
  /** 点击登录按钮 */
  CLICK_LOGIN = 'click_login',

  /** 进入创建企业 */
  ENTER_ENTERPRISE_CREATE = 'enter_enterprise_create',
  /** 点击认证企业 */
  CLICK_ENTERPRISE_CREATE = 'click_enterprise_create',
  /** 点击立即认证 */
  CLICK_ENTERPRISE_AUTH = 'click_enterprise_auth',
  /** 进入企业认证 */
  ENTER_ENTERPRISE_AUTH = 'enter_enterprise_auth',
  /** 进入交易中心回箱 */
  ENTER_TRADE_BACKBOX = 'enter_trade_backbox',
  /** 进入回箱需求 */
  ENTER_BACKBOX_DEMAND = 'enter_backbox_demand',
  /** 进入回箱需求提交页面 */
  ENTER_BACKBOX_DEMAND_CREATE = 'enter_backbox_demand_create',
  /** 回箱需求提交 */
  CLICK_BACKBOX_DEMAND_CREATE = 'click_backbox_demand_create',
  /** 进入交易中心用箱 */
  ENTER_TRADE_USEBOX = 'enter_trade_usebox',
  /** 用箱需求 */
  ENTER_USEBOX_DEMAND = 'enter_usebox_demand',
  /** 进入发布用箱需求提交页面 */
  ENTER_USEBOX_DEMAND_CREATE = 'enter_usebox_demand_create',
  /** 用箱需求提交 */
  CLICK_USEBOX_DEMAND_CREATE = 'click_usebox_demand_create',
  /** 交易中心回箱查询 */
  CLICK_TRADE_BACKBOX_SEARCH = 'click_trade_backbox_search',
  /** 交易中心回箱-我要调箱 */
  CLICK_TRADE_BACKBOX_CREATE = 'click_trade_backbox_create',
  /** 交易中心用箱查询 */
  CLICK_TRADE_USEBOX_SEARCH = 'click_trade_usebox_search',
  /** 交易中心用箱-我要用箱 */
  CLICK_TRADE_USEBOX_CREATE = 'click_trade_usebox_create',
  /** 进入官网用箱 */
  ENTER_SITE_USEBOX = 'enter_site_usebox',
  /** 官网用箱查询 */
  CLICK_SITE_USEBOX_SEARCH = 'click_site_usebox_search',
  /** 官网用箱我要用箱 */
  CLICK_SITE_USEBOX_CREATE = 'click_site_usebox_create',
  /** 进入官网回箱 */
  ENTER_SITE_BACKBOX = 'enter_site_backbox',
  /** 官网回箱查询 */
  CLICK_SITE_BACKBOX_SEARCH = 'click_site_backbox_search',
  /** 官网回箱我要调箱 */
  CLICK_SITE_BACKBOX_CREATE = 'click_site_backbox_create',
  /** 进入海运订下单页 */
  ENTER_OCEAN_PLACE_PAGE = 'enter_ocean_place_order_page',
  /** 海运商品下单 */
  CLICK_OCEAN_PLACE_ORDER = 'click_ocean_place_order',
  /** 进入海运tab */
  ENTER_OCEAN_TAB = 'enter_ocean_tab',
  /** 进入海运tab 【官网来源】 */
  ENTER_OCEAN_TAB_FROM_SITE = 'enter_ocean_tab_from_site',
  /** 海运tab 搜素框 查询按钮 */
  CLICK_OCEAN_TAB_SEARCH = 'click_ocean_tab_search',
  /** 海运tab 搜素框 查询按钮 【官网来源】 */
  CLICK_OCEAN_TAB_SEARCH_FROM_SITE = 'click_ocean_tab_search_from_site',
  /** 海运tab 热门推荐 立即抢购 */
  CLICK_OCEAN_TAB_RECOMMEND_BOOKING = 'click_ocean_tab_recommend_booking',
  /** 海运tab 热门推荐 立即抢购【官网来源】 */
  CLICK_OCEAN_TAB_RECOMMEND_BOOKING_FROM_SITE = 'click_ocean_tab_recommend_booking_from_site',
  /** 海运tab 按钮 订舱 */
  CLICK_OCEAN_TAB_BOOKING = 'click_ocean_tab_booking',
  /** 海运tab 按钮 订舱【官网来源】 */
  CLICK_OCEAN_TAB_BOOKING_FROM_SITE = 'click_ocean_tab_booking_from_site',
  /** 海运tab 按钮 询价 */
  CLICK_OCEAN_TAB_ASK = 'click_ocean_tab_ask',
  /** 海运tab 按钮 询价【官网来源】 */
  CLICK_OCEAN_TAB_ASK_FROM_SITE = 'click_ocean_tab_ask_from_site',
  /** 进入交易中心班列页面 */
  ENTER_TRADE_RAILWAY = 'enter_trade_railway',
  /** 交易中心班列tab点击查询 */
  CLICK_TRADE_RAILWAY_SEARCH = 'click_trade_railway_search',
  /** 交易中心班列tab-热门推荐：点击订舱 */
  CLICK_TRADE_RAILWAY_HOT_CREATE_ORDER = 'click_trade_railway_hot_create_order',
  /** 交易中心班列tab-商品列表：点击订舱 */
  CLICK_TRADE_RAILWAY_LIST_CREATE_ORDER = 'click_trade_railway_list_create_order',
  /** 交易中心班列tab-商品列表：点击询价 */
  CLICK_TRADE_RAILWAY_LIST_CONSULT = 'click_trade_railway_list_consult',
  /** 进入官网班列页面 */
  ENTER_SITE_RAILWAY = 'enter_site_railway',
  /** 官网班列tab点击查询 */
  CLICK_SITE_RAILWAY_SEARCH = 'click_site_railway_search',
  /** 官网班列tab-热门推荐：点击订舱 */
  CLICK_SITE_RAILWAY_HOT_CREATE_ORDER = 'click_site_railway_hot_create_order',
  /** 官网班列tab-商品列表：点击订舱 */
  CLICK_SITE_RAILWAY_LIST_CREATE_ORDER = 'click_site_railway_list_create_order',
  /** 交易中心班列tab-商品列表：点击询价 */
  CLICK_SITE_RAILWAY_LIST_CONSULT = 'click_site_railway_list_consult',
  /** 进入官网金融页面 */
  ENTER_SITE_FINANCE = 'enter_site_finance',

  /** 进入班列下单页 */
  ENTER_RAILWAY_CREATE_ORDER = 'enter_railway_create_order',
  /** 班列下单页提交订单 */
  CLICK_RAILWAY_CREATE_ORDER = 'click_railway_create_order',
  /** 进入官网首页 */
  ENTER_SITE_HOME = 'enter_site_home',
  /** 进入官网租箱页 */
  ENTER_SITE_RENTBOX = 'enter_site_rentbox',
  /** 进入交易中心租箱页 */
  ENTER_TRADE_RENTBOX = 'enter_trade_rentbox',
  /** 进入租箱下单页 */
  ENTER_RENTBOX_ORDER = 'enter_rentbox_order',
  /** 租箱下单页提交订单 */
  CLICK_RENTBOX_ORDER_SUBMIT = 'click_rentbox_order_submit',
  /** 官网租箱查询 */
  CLICK_SITE_RENTBOX_SEARCH = 'click_site_rentbox_search',
  /** 交易中心租箱查询 */
  CLICK_TRADE_RENTBOX_SEARCH = 'click_trade_rentbox_search',
  /** 官网租箱页-限时秒杀：点击立即抢购 */
  CLICK_SITE_RENTBOX_SECKILL = 'click_site_rentbox_seckill',
  /** 官网租箱页-热门推荐：点击立即抢购 */
  CLICK_SITE_RENTBOX_HOT = 'click_site_rentbox_hot',
  /** 官网租箱页-商品列表：点击下单 */
  CLICK_SITE_RENTBOX_ORDER_BTN = 'click_site_rentbox_order_btn',
  /** 交易中心租箱tab-限时秒杀：点击立即抢购 */
  CLICK_TRADE_RENTBOX_SECKILL = 'click_trade_rentbox_seckill',
  /** 交易中心租箱tab-热门推荐：点击立即抢购 */
  CLICK_TRADE_RENTBOX_HOT = 'click_trade_rentbox_hot',
  /** 交易中心租箱tab-商品列表：点击下单 */
  CLICK_TRADE_RENTBOX_ORDER_BTN = 'click_trade_rentbox_order_btn',
  /** 进入官网全球租箱看板 */
  ENTER_SITE_BOX_OVERVIEW = 'enter_box_overview',
  /** 官网全球租箱看板查询 */
  CLICK_SITE_BOX_OVERVIEW_SEARCH = 'click_site_box_overview_search',
  /** 交易中心海运订单详情：点击接单拒单 */
  CLICK_TRADE_OCEAN_RECEIVING_BTN = 'click_trade_ocean_receiving_btn',

  /** 交易中心海运订单详情：卖家确认舱位 */
  CLICK_TRADE_OCEAN_SELLER_CONFIRM_STOWAGE = 'click_trade_ocean_seller_confirm_stowage',
  /** 交易中心海运订单详情：确认上船 */
  CLICK_TRADE_OCEAN_CONFIRM_BOARDING = 'click_trade_ocean_confirm_boarding',

  /** 交易中心海运订单详情：提交制作账单 */
  SUBMIT_TRADE_OCEAN_MAKE_BILL = 'submit_trade_ocean_make_bill',
  /** 交易中心海运订单详情：修改账单，顶部入口 */
  CLICK_TRADE_OCEAN_MODIFY_BILL_BTN = 'click_trade_ocean_modify_bill_btn',
  /** 交易中心海运订单详情：下面费用tab列表中的修改账单 */
  CLICK_TRADE_OCEAN_FEE_TAB_MODIFY_BILL_BTN = 'click_trade_ocean_fee_tab_modify_bill_btn',
  /** 交易中心海运订单：订单列表点击制作账单那妞 */
  CLICK_TRADE_OCEAN_ORDERLIST_MAKE_BILL_BTN = 'click_trade_ocean_orderlist_make_bill_btn',
  /** 交易中心海运：合并制作账单页面，确认提交账单弹窗确认 */
  CLICK_TRADE_OCEAN_MAKE_BILL_PAGE_SUBMIT = 'click_trade_ocean_make_bill_page_submit',
  /** 交易中心海运：订单列表导出订单 */
  CLICK_TRADE_OCEAN_ORDERLIST_EXPORT = 'click_trade_ocean_orderlist_export',
  /** 交易中心海运：订单列表搜索 */
  CLICK_TRADE_OCEAN_ORDERLIST_SEARCH = 'click_trade_ocean_orderlist_search',
  /** 交易中心海运：进入订单列表 */
  CLICK_TRADE_OCEAN_ORDERLIST_PAGE_INTO = 'click_trade_ocean_orderlist_page_into',
  /** 交易中心海运：账单列表 确认收款弹窗确认 */
  CLICK_TRADE_OCEAN_BILLLIST_COMFIRM_PAYEE = 'click_trade_ocean_bill_list_comfirm_payee',
  /** 交易中心海运：账单列表 点击查看发票按钮 */
  CLICK_TRADE_OCEAN_BILLLIST_VIEW_TICKET = 'click_trade_ocean_bill_list_view_ticket',
  /** 交易中心海运：账单列表 上传发票提交成功 */
  CLICK_TRADE_OCEAN_BILLLIST_UPLOADED_TICKET = 'click_trade_ocean_bill_list_uploaded_ticket',

  /** 线下转账确认-进入线下转账确认列表页面 */
  ENTER_OFFLINE_PAY_PAGE = 'enter_offline_pay_page',
  /** 线下转账确认-进入线下转账确认列表页面-进入确认收款页面 */
  ENTER_OFFLINE_PAY_PAGE_RECEIVE = 'enter_offline_pay_page_receive',
  /** 线下转账确认-提交确认收款 */
  SUBMIT_CONFIRM_RECEIPT_OFFLINE_PAY_PAGE = 'submit_confirm_receipt_offline_pay_page',
  /** 线下转账确认-提交销账 */
  SUBMIT_CLEAR_BILL_OFFLINE_PAY_PAGE = 'submit_clear_bill_offline_pay_page',
  /** 客户线下转账流水-进入转账流水页面 */
  ENTER_OFFLINE_WATER_BILLS = 'enter_offline-water-bills',
  /** 客户线下转账流水-进入修改流水页面 */
  ENTER_MODIFY_OFFLINE_WATER_BILLS = 'enter_modify_offline-water-bills',
  /** 客户线下转账流水-提交修改 */
  SUBMIT_MODIFY_OFFLINE_WATER_BILLS = 'submit_modify_offline-water-bills',
  /** 收银台-提交支付 */
  SHOUYINTAI_SUBMIT_PAY = 'shouyintai_submit_pay',
  /** 上传支付凭证 */
  UPLOAD_PAY_TICKET = 'upload_pay_ticket',
  /** 关闭打款 */
  CLOSE_PAY = 'close_pay',
}

/** 海运属性 还没改的中文还没用到 */
export enum OceanTrackerProperties {
  ENSURE_CABINTYPE = '保舱服务',
  CABINTYPE = '舱位类型',
  /** 起运港中文 */
  START_PORTNAME = 'start_port',
  /** 目的港中文 */
  END_PORTNAME = 'end_port',
  /** xxx */
  START_CITYNAME = 'start_city',
  /** xxx */
  END_CITYNAME = 'end_city',
  BOX_TYPE = '箱型',
  /** 船公司 */
  SHIPPIING_COMPANY_NAME = 'shipping_company_name',
  /** 船名 */
  SHIPPIING_NAME = 'shipping_name',
  PLANE_DEPARTURE_TIME = '计划离港时间',
  PLANE_ARRIVAL_TIME = '计划到港时间',
  SELLER_COMPANY_ID = '卖家企业ID',
  SELLER_COMPANY_NAME = '卖家企业名称',
  GOODS_SKU = '商品编码',
  PRICE_SHOW = '商品价格类型',
  GOODS_TIME = '货好日期',
  BILL_TYPE = '提单方式',
  TRANS_PORT_MODE = '运输条款',
  PAYMENT_MODE = '付款方式',
  RELEASE_TYPE = '放单方式',
  VOYAGE = '航次',
  VOYAGELINE = '航线',
  VOYAGEDAYS = '航程天数',
  CLOSEORDERTIME = '截单时间',
  /** 类型：接单、拒单 */
  RECEIVING_TYPE = 'receiving_type',
  /** 订单号 */
  ORDER_NO = 'order_no',
  /** 下单时间 */
  CREATE_ORDER_TIME = 'create_order_time',
  /** 修改前箱型数量 */
  BEFORE_MODIFY_QUANTITY = 'before_modify_quantity',
  /** 修改后箱型数量 */
  AFTER_MODIFY_QUANTITY = 'after_modify_quantity',
  /** 船公司代码 */
  SHIPPIING_COMPANY_CODE = 'shipping_company_code',
  /** 客户名称 */
  BUYER_NAME = 'buyer_name',
  /** 发票付款方企业 */
  Payer_Enterprise = 'payer_enterprise',
}

/** 班列属性 */
export enum RailwayTrackerProperties {
  START_CITY = '出发城市',
  END_CITY = '目的城市',
  BOX_TYPE = '箱型',
  START_STATION = '出发车站',
  END_STATION = '目的车站',
  GOODS_SKU = '商品编码',
  TRAINS_COMPANY = '班列公司',
  IMPORT_OR_EXPORT = '进出口',
  SOURCE_ADDRESS = '出发地',
  TARGET_ADDRESS = '目的地',
  EXIT_PORT = '出境口岸',
  ENTRY_PORT = '入境口岸',
  PLAN_DEPARTURE_TIME = '计划出发时间',
  CABIN_TYPE = '舱位类型',
  BILL_CUT_OFF_TIME = '截单时间',
  BILL_HEAVY_TIME = '截重时间',
  BILL_CLOSING_TIME = '截关时间',
  PRICE_SHOW = '商品价格类型',
  SELLER_COMPANY_ID = '卖家企业ID',
  SELLER_COMPANY_NAME = '卖家企业名称',
  GOODS_TIME = '货好日期',
}

/** 转账 */
export enum TransferAccountsTrackerProperties {
  /** 支付币种 */
  pay_currency = 'pay_currency',
  /** 是否更改币种（按子支付单记录） */
  is_change_currency = 'is_change_currency',
  /** 是否挂账 */
  is_on_credit = 'is_on_credit',
  /** 是否关联发票 */
  is_relation_ticket = 'is_relation_ticket',
  /** 收款时间 */
  receipt_time = 'receipt_time',
  /** 挂账时间 */
  on_credit_time = 'on_credit_time',
}

/** 公共属性 */
export enum commonTrackerProperties {
  ACCOUNT_ID = '用户ID',
  USER_NAME = '用户名',
  MOBILE = '用户电话',
  EMAIL = '用户邮箱',
  ENTERPRISE_ID = '企业ID',
  ENTERPRISE_NAME = '企业名称',
  ENTERPRISE_TYPE = '企业类型',
  EVENT_TIME = '上报时间',
  ENTERPRISE_STATUS = '企业认证状态',
  SOURCE_PAGE = '来源页面',
  /** 下单时间 */
  CREATE_ORDER_TIME = 'create_order_time',
  /** 上传凭证时间 */
  UPLOAD_TICKET_TIME = 'upload_ticket_time',
  /** 选择支付方式 */
  select_pay_method = 'select_pay_method',
  /** 所属业务 */
  belong_to_business = 'belong_to_business',
}

export const TrackKeepCabinType = {
  '0': '保舱',
  '1': '非报仓',
};

export const TrackImportOrExport = {
  '0': '进口',
  '1': '出口',
};

export const TrackShowPrice = {
  '1': '一口价',
  '2': '可议价格',
  '3': '不显示价格',
};
