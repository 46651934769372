// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 卖家变更申请详情 POST /salesgateway/container-leasing/seller/change-apply/info */
export async function OperationId_salesgateway_ChangeApplySellerController_queryChangeApplyInfo(
  body: API.QueryChangeApplyInfoVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyInfoVO>(
    '/salesgateway/container-leasing/seller/change-apply/info',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplySellerController_queryChangeApplyInfo',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家变更申请记录分页 POST /salesgateway/container-leasing/seller/change-apply/page */
export async function OperationId_salesgateway_ChangeApplySellerController_sellerChangeApplyPage(
  body: API.QuerySellerChangeApplyPageVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyPageVO>(
    '/salesgateway/container-leasing/seller/change-apply/page',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplySellerController_sellerChangeApplyPage',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家获取服务费配置 POST /salesgateway/container-leasing/seller/change-apply/query-service-amount-config */
export async function OperationId_salesgateway_ChangeApplySellerController_queryServiceAmountConfig(
  body: API.QueryServiceAmountConfigVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ServiceAmountConfigVO>(
    '/salesgateway/container-leasing/seller/change-apply/query-service-amount-config',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ChangeApplySellerController_queryServiceAmountConfig',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家同意变更申请记 POST /salesgateway/container-leasing/seller/change-apply/seller-agree-change-apply */
export async function OperationId_salesgateway_ChangeApplySellerController_sellerAgreeChangeApply(
  body: API.SellerAgreeChangeApplyVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>(
    '/salesgateway/container-leasing/seller/change-apply/seller-agree-change-apply',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplySellerController_sellerAgreeChangeApply',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家审核时变更数据后重新计算 POST /salesgateway/container-leasing/seller/change-apply/seller-query-change-apply-total-amount */
export async function OperationId_salesgateway_ChangeApplySellerController_sellerQueryChangeApplyTotalAmount(
  body: API.SellerQueryChangeApplyTotalAmountVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ChangeApplyTotalAmountVO>(
    '/salesgateway/container-leasing/seller/change-apply/seller-query-change-apply-total-amount',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ChangeApplySellerController_sellerQueryChangeApplyTotalAmount',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家驳回变更申请记 POST /salesgateway/container-leasing/seller/change-apply/seller-reject-change-apply */
export async function OperationId_salesgateway_ChangeApplySellerController_sellerRejectChangeApply(
  body: API.SellerRejectChangeApplyVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>(
    '/salesgateway/container-leasing/seller/change-apply/seller-reject-change-apply',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ChangeApplySellerController_sellerRejectChangeApply',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
