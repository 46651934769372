// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询商品 GET /sinotransbooking/v1/buyer/products */
export async function OperationId_sinotransbooking_BuyerProductController_pageProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerProductControllerPageProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/buyer/products', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerProductController_pageProduct',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 查询商品详情 GET /sinotransbooking/v1/buyer/products/${param0} */
export async function OperationId_sinotransbooking_BuyerProductController_getProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerProductControllerGetProductParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductVO>(`/sinotransbooking/v1/buyer/products/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerProductController_getProduct',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查看字典 GET /sinotransbooking/v1/buyer/products/dict/${param0} */
export async function OperationId_sinotransbooking_BuyerProductController_getDict(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerProductControllerGetDictParams,
  options?: { [key: string]: any },
) {
  const { type: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DictVO[]>(`/sinotransbooking/v1/buyer/products/dict/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerProductController_getDict',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 查询热门商品 GET /sinotransbooking/v1/buyer/products/recommend-products */
export async function OperationId_sinotransbooking_BuyerProductController_pageRecommendProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerProductControllerPageRecommendProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/buyer/products/recommend-products', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BuyerProductController_pageRecommendProduct',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 查询船期价格表 GET /sinotransbooking/v1/buyer/products/shipping-date-prices */
export async function OperationId_sinotransbooking_BuyerProductController_listShippingDatePrice(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBuyerProductControllerListShippingDatePriceParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShippingDatePriceDTO[]>(
    '/sinotransbooking/v1/buyer/products/shipping-date-prices',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_BuyerProductController_listShippingDatePrice',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}
