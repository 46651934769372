// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 确认账单 POST /salesgateway/yard/bill/container-owner/${param0}/confirm */
export async function OperationId_salesgateway_YardBillController_confirmContainerOwnerBill(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardBillControllerConfirmContainerOwnerBillParams,
  body: API.ConfirmContainerOwnerBillParamVo,
  options?: { [key: string]: any },
) {
  const { containerOwnerBillId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/yard/bill/container-owner/${param0}/confirm`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardBillController_confirmContainerOwnerBill',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 获取箱东账单周期信息 GET /salesgateway/yard/bill/container-owner/bill-interval */
export async function OperationId_salesgateway_YardBillController_getContainerOwnerBillInterval(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayYardBillControllerGetContainerOwnerBillIntervalParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryContainerOwnerBillIntervalVO>(
    '/salesgateway/yard/bill/container-owner/bill-interval',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_YardBillController_getContainerOwnerBillInterval',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 账单明细-对账分析页 POST /salesgateway/yard/bill/container-owner/bill/analysis */
export async function OperationId_salesgateway_YardBillController_billAnalysis(
  body: API.QueryIdVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerOwnerBillAnalysisListVO>(
    '/salesgateway/yard/bill/container-owner/bill/analysis',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_YardBillController_billAnalysis',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 账单明细-对账分析页-导出 POST /salesgateway/yard/bill/container-owner/bill/analysis-export */
export async function OperationId_salesgateway_YardBillController_exportBillAnalysis(
  body: API.QueryIdVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/yard/bill/container-owner/bill/analysis-export', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardBillController_exportBillAnalysis',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 账单明细-账单对账页 POST /salesgateway/yard/bill/container-owner/bill/analysis/detail */
export async function OperationId_salesgateway_YardBillController_billAnalysisDetail(
  body: API.QueryContainerOwnerBillAnalysisDetailVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContainerOwnerBillAnalysisDetailPageVO>(
    '/salesgateway/yard/bill/container-owner/bill/analysis/detail',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_YardBillController_billAnalysisDetail',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 账单明细-账单对账页-导出 POST /salesgateway/yard/bill/container-owner/bill/analysis/detail-export */
export async function OperationId_salesgateway_YardBillController_exportBillAnalysisDetail(
  body: API.QueryContainerOwnerBillAnalysisDetailVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/yard/bill/container-owner/bill/analysis/detail-export', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardBillController_exportBillAnalysisDetail',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 导出箱东账单明细列表 POST /salesgateway/yard/bill/container-owner/detail-export */
export async function OperationId_salesgateway_YardBillController_exportContainerOwnerBillDetailList(
  body: API.QueryContainerOwnerBillDetailVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/yard/bill/container-owner/detail-export', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardBillController_exportContainerOwnerBillDetailList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 分页获取箱东账单明细 POST /salesgateway/yard/bill/container-owner/detail/page */
export async function OperationId_salesgateway_YardBillController_pageContainerOwnerBillDetail(
  body: API.QueryContainerOwnerBillDetailVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryContainerOwnerBillDetailPageVO>(
    '/salesgateway/yard/bill/container-owner/detail/page',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_YardBillController_pageContainerOwnerBillDetail',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 导出箱东账单列表 POST /salesgateway/yard/bill/container-owner/export */
export async function OperationId_salesgateway_YardBillController_exportContainerOwnerBillList(
  body: API.QueryContainerOwnerBillVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/yard/bill/container-owner/export', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_YardBillController_exportContainerOwnerBillList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 分页获取箱东账单列表 POST /salesgateway/yard/bill/container-owner/page */
export async function OperationId_salesgateway_YardBillController_pageContainerOwnerBill(
  body: API.QueryContainerOwnerBillVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryContainerOwnerBillPageVO>(
    '/salesgateway/yard/bill/container-owner/page',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_YardBillController_pageContainerOwnerBill',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 堆存费分页查询 POST /salesgateway/yard/bill/container-owner/stockpiling/page */
export async function OperationId_salesgateway_YardBillController_pageStockpiling(
  body: API.QueryContainerOwnerBillStorageDetailVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QueryContainerOwnerStockpilingFeePageVO>(
    '/salesgateway/yard/bill/container-owner/stockpiling/page',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_YardBillController_pageStockpiling',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
