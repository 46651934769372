// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询订单 GET /logisticssolution/v1/buyer/performances */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_pagePerformanceOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerPagePerformanceOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePerformanceOrderBriefVO>('/logisticssolution/v1/buyer/performances', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_BuyerPerformanceOrderController_pagePerformanceOrder',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 买家附件查询 GET /logisticssolution/v1/buyer/performances/${param0}/attachments */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_attachmentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerAttachmentListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderAttachmentVo[]>(
    `/logisticssolution/v1/buyer/performances/${param0}/attachments`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_BuyerPerformanceOrderController_attachmentList',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 买家上传附件 POST /logisticssolution/v1/buyer/performances/${param0}/attachments */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_uploadAttachments(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerUploadAttachmentsParams,
  body: API.UploadDocInfoRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/buyer/performances/${param0}/attachments`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_BuyerPerformanceOrderController_uploadAttachments',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 取消订单 PUT /logisticssolution/v1/buyer/performances/${param0}/cancel */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_cancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerCancelParams,
  body: API.CommonPerformanceTrainActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/buyer/performances/${param0}/cancel`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_BuyerPerformanceOrderController_cancel',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 获取订单变更信息 GET /logisticssolution/v1/buyer/performances/${param0}/change-infos */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_getPerformanceOrderChange(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerGetPerformanceOrderChangeParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderChangeVO>(
    `/logisticssolution/v1/buyer/performances/${param0}/change-infos`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerPerformanceOrderController_getPerformanceOrderChange',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 确认订单 PUT /logisticssolution/v1/buyer/performances/${param0}/confirm */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_confirm(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerConfirmParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/buyer/performances/${param0}/confirm`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_BuyerPerformanceOrderController_confirm',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 获取订单聚合统计 GET /logisticssolution/v1/buyer/performances/aggregations */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_aggregatePerformanceOrder(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.AggregatePerformanceOrderDTO>(
    '/logisticssolution/v1/buyer/performances/aggregations',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerPerformanceOrderController_aggregatePerformanceOrder',
      },
      ...(rest || {}),
    },
  );
}

/** 消息查询 GET /logisticssolution/v1/buyer/performances/message/${param0} */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_messageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerMessageListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.MessageItemDTO[]>(
    `/logisticssolution/v1/buyer/performances/message/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_BuyerPerformanceOrderController_messageList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 是否有箱货轨迹 GET /logisticssolution/v1/buyer/performances/trace/${param0} */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_isHaveTrace(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerIsHaveTraceParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/logisticssolution/v1/buyer/performances/trace/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_BuyerPerformanceOrderController_isHaveTrace',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 班列订单详情-查询 GET /logisticssolution/v1/buyer/performances/train/${param0} */
export async function OperationId_logisticssolution_BuyerPerformanceOrderController_getTrainPerformanceDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerPerformanceOrderControllerGetTrainPerformanceDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceOrderDetailResponse>(
    `/logisticssolution/v1/buyer/performances/train/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerPerformanceOrderController_getTrainPerformanceDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
