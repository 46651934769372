// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取堆场图片下载地址 OpenAttachmentController.getPrivateDownloadYardSignedUrl GET /salesgateway/v2/attachments/private/download/${param0}/${param1}/sign */
export async function OperationId_salesgateway_OpenAttachmentController_getPrivateDownloadYardSignedUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayOpenAttachmentControllerGetPrivateDownloadYardSignedUrlParams,
  options?: { [key: string]: any },
) {
  const { yardId: param0, name: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>(
    `/salesgateway/v2/attachments/private/download/${param0}/${param1}/sign`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_OpenAttachmentController_getPrivateDownloadYardSignedUrl',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}
