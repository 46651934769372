import { Checkbox, Form, Input, InputNumber, Radio, Space, Switch } from 'antd';
import ButtonRangePicker from '../button-range-picker';
import { DatePicker } from '../date-picker';
import RemoteSelect from '../remote-select';
import classNames from 'classnames';
import type { FieldProps } from './interface';
import { Select } from '../select';
import { ValidateButtonInput } from '../validate-button-input';
import EasyCascader from '../cascader';
import { ProUpload } from '../pro-upload/ProUpload';
import { PlusOutlined } from '@ant-design/icons';
import ChainSelect from '../../../shared/src/components/chain-select';
import type { ChainSelectProps } from '../../../shared/src/components/chain-select';
import { componentI18nInstance } from '../config';
import { isArray } from 'lodash';
import styles from './index.module.less';
import WeekRadioDropdown from '../week-radio-dropdown';
const { Group: CheckboxGroup } = Checkbox;
const { Group: RadioGroup } = Radio;

const formTypes: Record<string, (props: FieldProps) => JSX.Element> = {
  select: (props: FieldProps) => <Select style={{ width: '100%' }} {...props} />,
  selectInput: ({ options, onChange, beforeKey, selectMessage, ...rest }: FieldProps) => (
    <InputNumber
      style={{ width: '100%' }}
      addonBefore={
        <Form.Item name={beforeKey} noStyle rules={[{ required: true, message: selectMessage || '' }]}>
          <Select allowClear={false} options={options} style={{ width: '68px' }} onChange={onChange}></Select>
        </Form.Item>
      }
      disabled
      {...rest}
    ></InputNumber>
  ),
  text: (props: FieldProps) => <Input style={{ width: '100%' }} {...props} />,
  number: (props: FieldProps) => <InputNumber style={{ width: '100%' }} {...props} />,
  date: (props: FieldProps) => <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} {...props} />,
  dateTime: (props: FieldProps) => <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} {...props} />,
  dateRange: (props: FieldProps) => {
    const finalProps = {
      format: 'YYYY-MM-DD',
      style: { width: '100%' },
      ...props,
      placeholder: isArray(props.placeholder)
        ? props.placeholder
        : [componentI18nInstance.trans('range_picker_start_placeholder'), componentI18nInstance.trans('range_picker_end_placeholder')],
    };
    return <DatePicker.RangePicker {...finalProps} />;
  },
  dateTimeRange: (props: FieldProps) => {
    const finalProps = {
      format: 'YYYY-MM-DD HH:mm',
      style: { width: '100%' },
      showTime: true,
      ...props,
      placeholder: isArray(props.placeholder)
        ? props.placeholder
        : [componentI18nInstance.trans('range_picker_start_placeholder'), componentI18nInstance.trans('range_picker_end_placeholder')],
    };
    return <DatePicker.RangePicker {...finalProps} />;
  },
  buttonRangePicker: (props: FieldProps) => <ButtonRangePicker {...props} />,
  remoteSelect: (props: FieldProps) => <RemoteSelect {...props} />,
  weekRadioDropdown: (props: FieldProps) => <WeekRadioDropdown {...props} />,
  validateButtonInput: (props: FieldProps) => <ValidateButtonInput {...props} />,
  cascader: (props: FieldProps) => <EasyCascader {...props} />,
  radioGroup: (props: FieldProps) => <RadioGroup {...props} />,
  checkboxGroup: (props: FieldProps) => <CheckboxGroup {...props} />,
  upload: ({ showDefaultText = true, ...rest }: FieldProps) => {
    const finalProps = { maxCount: 1, listType: 'picture-card', ...rest };
    return (
      <ProUpload {...finalProps}>
        <div className={classNames(styles['btn-upload'])}>
          <Space direction={finalProps.listType === 'picture-card' ? 'vertical' : 'horizontal'}>
            <PlusOutlined className={styles.icon} />
            <div className={styles.text}>上传</div>
          </Space>
        </div>
      </ProUpload>
    );
  },
  chainSelect: (props: ChainSelectProps) => <ChainSelect {...props} />,
  textarea: (props: FieldProps) => <Input.TextArea {...props} />,
  switch: (props: FieldProps) => <Switch {...props} />,
};

export default formTypes;
