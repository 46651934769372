// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查看随车资料 GET /salesgateway/v1/railway/common/accessoryMaterial/${param0}/detail */
export async function OperationId_salesgateway_RailwayCommonController_getAccessoryMaterials(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerGetAccessoryMaterialsParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AccessoryMaterialsVO>(
    `/salesgateway/v1/railway/common/accessoryMaterial/${param0}/detail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_getAccessoryMaterials',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查看账单 GET /salesgateway/v1/railway/common/bill/${param0}/list */
export async function OperationId_salesgateway_RailwayCommonController_queryBillList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerQueryBillListParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceBillItemVO[]>(
    `/salesgateway/v1/railway/common/bill/${param0}/list`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_queryBillList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询获取城市或者车站选项 GET /salesgateway/v1/railway/common/city-station-options */
export async function OperationId_salesgateway_RailwayCommonController_listCityStationOption(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerListCityStationOptionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityOrStationOptionDTO[]>(
    '/salesgateway/v1/railway/common/city-station-options',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_listCityStationOption',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 获取定金 GET /salesgateway/v1/railway/common/getConfigDeposit */
export async function OperationId_salesgateway_RailwayCommonController_getConfigDeposit(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.DepositVO>('/salesgateway/v1/railway/common/getConfigDeposit', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_getConfigDeposit',
    },
    ...(rest || {}),
  });
}

/** 获取支付单信息(成功支付的支付单) GET /salesgateway/v1/railway/common/payment-infos/${param0} */
export async function OperationId_salesgateway_RailwayCommonController_getPaymentDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerGetPaymentDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.RailwayPaymentDetailDTO[]>(
    `/salesgateway/v1/railway/common/payment-infos/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_getPaymentDetail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取支付单信息 GET /salesgateway/v1/railway/common/payment-infos/${param0}/status */
export async function OperationId_salesgateway_RailwayCommonController_getPaymentInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerGetPaymentInfoParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PaymentInfoDTO[]>(
    `/salesgateway/v1/railway/common/payment-infos/${param0}/status`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_getPaymentInfo',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 履约单数据权限校验 GET /salesgateway/v1/railway/common/performance/${param0}/data-auths */
export async function OperationId_salesgateway_RailwayCommonController_performanceAuthCheck(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerPerformanceAuthCheckParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/common/performance/${param0}/data-auths`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_performanceAuthCheck',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 查看提箱单箱号明细 GET /salesgateway/v1/railway/common/pickUp/${param0}/boxDetail */
export async function OperationId_salesgateway_RailwayCommonController_pickUpBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerPickUpBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickUpBoxNoDetailVO>(
    `/salesgateway/v1/railway/common/pickUp/${param0}/boxDetail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_pickUpBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 查看提箱记录 GET /salesgateway/v1/railway/common/pickUp/${param0}/list */
export async function OperationId_salesgateway_RailwayCommonController_pickUpList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerPickUpListParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickUpBoxRecordItemVO[]>(
    `/salesgateway/v1/railway/common/pickUp/${param0}/list`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_pickUpList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 提箱预览下载 GET /salesgateway/v1/railway/common/pickUp/${param0}/previewDownload */
export async function OperationId_salesgateway_RailwayCommonController_pickUpPreviewDownload(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerPickUpPreviewDownloadParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(`/salesgateway/v1/railway/common/pickUp/${param0}/previewDownload`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_pickUpPreviewDownload',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 附件查询 GET /salesgateway/v1/railway/common/queryAttachment */
export async function OperationId_salesgateway_RailwayCommonController_queryAttachment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerQueryAttachmentParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AttachmentItemVO[]>('/salesgateway/v1/railway/common/queryAttachment', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_queryAttachment',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询箱子轨迹信息 GET /salesgateway/v1/railway/common/queryBoxTrajectoryInfo */
export async function OperationId_salesgateway_RailwayCommonController_queryBoxTrajectoryInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerQueryBoxTrajectoryInfoParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoxTrajectoryVO>('/salesgateway/v1/railway/common/queryBoxTrajectoryInfo', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_queryBoxTrajectoryInfo',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 通过履约单号查询履约单信息 GET /salesgateway/v1/railway/common/queryPerformanceDetailByNo */
export async function OperationId_salesgateway_RailwayCommonController_queryPerformanceDetailByNo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerQueryPerformanceDetailByNoParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformanceDetailVO>(
    '/salesgateway/v1/railway/common/queryPerformanceDetailByNo',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_queryPerformanceDetailByNo',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查看PO GET /salesgateway/v1/railway/common/queryPerformancePo */
export async function OperationId_salesgateway_RailwayCommonController_queryPerformancePo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerQueryPerformancePoParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PerformancePoVO>('/salesgateway/v1/railway/common/queryPerformancePo', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_queryPerformancePo',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 申请还箱 POST /salesgateway/v1/railway/common/returnBox/${param0}/apply */
export async function OperationId_salesgateway_RailwayCommonController_returnBoxApply(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerReturnBoxApplyParams,
  body: API.RailwayReturnBoxApplyRequest,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/salesgateway/v1/railway/common/returnBox/${param0}/apply`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_returnBoxApply',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 查看还箱号明细 GET /salesgateway/v1/railway/common/returnBox/${param0}/detail */
export async function OperationId_salesgateway_RailwayCommonController_returnBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerReturnBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ReturnBoxDetailVO>(
    `/salesgateway/v1/railway/common/returnBox/${param0}/detail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_returnBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 查看还箱记录 GET /salesgateway/v1/railway/common/returnBox/${param0}/list */
export async function OperationId_salesgateway_RailwayCommonController_returnBoxList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerReturnBoxListParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ReturnBoxRecordItemVO[]>(
    `/salesgateway/v1/railway/common/returnBox/${param0}/list`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_returnBoxList',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 还箱预览下载 GET /salesgateway/v1/railway/common/returnBox/${param0}/previewDownload */
export async function OperationId_salesgateway_RailwayCommonController_returnBoxPreviewDownload(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerReturnBoxPreviewDownloadParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(`/salesgateway/v1/railway/common/returnBox/${param0}/previewDownload`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_returnBoxPreviewDownload',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 待还箱列表 GET /salesgateway/v1/railway/common/returnBox/${param0}/waitReturnList */
export async function OperationId_salesgateway_RailwayCommonController_waitReturnList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayRailwayCommonControllerWaitReturnListParams,
  options?: { [key: string]: any },
) {
  const { performanceNo: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.WaitReturnBoxItemVO[]>(
    `/salesgateway/v1/railway/common/returnBox/${param0}/waitReturnList`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_RailwayCommonController_waitReturnList',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 附件上传 POST /salesgateway/v1/railway/common/uploadAttachment */
export async function OperationId_salesgateway_RailwayCommonController_uploadAttachment(
  body: API.AttachmentRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/railway/common/uploadAttachment', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_RailwayCommonController_uploadAttachment',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
