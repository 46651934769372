// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 商品详情 GET /salesgateway/seller/container-leasing/product/${param0} */
export async function OperationId_salesgateway_ContainerProdSupplierController_queryProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerProdSupplierControllerQueryProductParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.SupplierContainerProdVo>(
    `/salesgateway/seller/container-leasing/product/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerProdSupplierController_queryProduct',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询城市和箱型对应的实际库存 GET /salesgateway/seller/container-leasing/product/actual/inventory */
export async function OperationId_salesgateway_ContainerProdSupplierController_qryActualInventory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerProdSupplierControllerQryActualInventoryParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/seller/container-leasing/product/actual/inventory', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerProdSupplierController_qryActualInventory',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取还箱城市列表 POST /salesgateway/seller/container-leasing/product/cities/destination */
export async function OperationId_salesgateway_ContainerProdSupplierController_queryDestinationCities(
  body: API.ListCitiesVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityWithCountryVo[]>(
    '/salesgateway/seller/container-leasing/product/cities/destination',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerProdSupplierController_queryDestinationCities',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 检查还箱城市是否有商品 POST /salesgateway/seller/container-leasing/product/cities/destination/check */
export async function OperationId_salesgateway_ContainerProdSupplierController_checkProductIsExist(
  body: API.CheckProductRequestVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>(
    '/salesgateway/seller/container-leasing/product/cities/destination/check',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerProdSupplierController_checkProductIsExist',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取提箱城市列表 POST /salesgateway/seller/container-leasing/product/cities/provenance */
export async function OperationId_salesgateway_ContainerProdSupplierController_queryProvenanceCities(
  body: API.ListCitiesVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CityWithCountryVo[]>(
    '/salesgateway/seller/container-leasing/product/cities/provenance',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerProdSupplierController_queryProvenanceCities',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询提箱城市指定箱型的库存 GET /salesgateway/seller/container-leasing/product/city/inventory */
export async function OperationId_salesgateway_ContainerProdSupplierController_queryProvenanceCityInventory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerProdSupplierControllerQueryProvenanceCityInventoryParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/seller/container-leasing/product/city/inventory', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerProdSupplierController_queryProvenanceCityInventory',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 租箱商品创建 POST /salesgateway/seller/container-leasing/product/create */
export async function OperationId_salesgateway_ContainerProdSupplierController_createProduct(
  body: API.CreateSupplierContainerProdVo[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number[]>('/salesgateway/seller/container-leasing/product/create', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerProdSupplierController_createProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 校验企业是否绑定银行卡 GET /salesgateway/seller/container-leasing/product/ent/card/check */
export async function OperationId_salesgateway_ContainerProdSupplierController_checkEnterpriseCard(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/seller/container-leasing/product/ent/card/check', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerProdSupplierController_checkEnterpriseCard',
    },
    ...(rest || {}),
  });
}

/** 租箱商品列表查询 查询服务商配置的租箱商品列表，包括审核状态、上下架状态 POST /salesgateway/seller/container-leasing/product/list */
export async function OperationId_salesgateway_ContainerProdSupplierController_queryProducts(
  body: API.QryContainerProdVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QrySupplierContainerProdsRespVo>(
    '/salesgateway/seller/container-leasing/product/list',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerProdSupplierController_queryProducts',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 租箱商品批量下架 PUT /salesgateway/seller/container-leasing/product/off-sale */
export async function OperationId_salesgateway_ContainerProdSupplierController_productOffSale(
  body: number[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/seller/container-leasing/product/off-sale', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerProdSupplierController_productOffSale',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 租箱商品批量上架 PUT /salesgateway/seller/container-leasing/product/on-sale */
export async function OperationId_salesgateway_ContainerProdSupplierController_productOnSale(
  body: number[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/seller/container-leasing/product/on-sale', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerProdSupplierController_productOnSale',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 租箱商品更新库存 PUT /salesgateway/seller/container-leasing/product/products/${param0}/inventory/update */
export async function OperationId_salesgateway_ContainerProdSupplierController_updateInventory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerProdSupplierControllerUpdateInventoryParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/seller/container-leasing/product/products/${param0}/inventory/update`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerProdSupplierController_updateInventory',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 租箱商品编辑 PUT /salesgateway/seller/container-leasing/product/products/${param0}/update */
export async function OperationId_salesgateway_ContainerProdSupplierController_updateProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerProdSupplierControllerUpdateProductParams,
  body: API.UpdateSupplierContainerProdVo,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/seller/container-leasing/product/products/${param0}/update`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerProdSupplierController_updateProduct',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 查询商品类目下的属性列表 GET /salesgateway/seller/container-leasing/product/properties */
export async function OperationId_salesgateway_ContainerProdSupplierController_queryProperties(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerProdSupplierControllerQueryPropertiesParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.PropertyVo[]>('/salesgateway/seller/container-leasing/product/properties', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerProdSupplierController_queryProperties',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 租箱商品历史版本查询 POST /salesgateway/seller/container-leasing/product/version */
export async function OperationId_salesgateway_ContainerProdSupplierController_queryProductVersion(
  body: API.QryContainerProdVersionVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QryContainerProdVersionRespVo>(
    '/salesgateway/seller/container-leasing/product/version',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ContainerProdSupplierController_queryProductVersion',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
