// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 批量下架商品 POST /salesgateway/v1/shipping/product/batchProductUnSale */
export async function OperationId_salesgateway_ShippingProductController_batchProductUnSale(
  body: API.BatchProductSaleReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/shipping/product/batchProductUnSale', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_batchProductUnSale',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品列表buyer POST /salesgateway/v1/shipping/product/buyerListProducts */
export async function OperationId_salesgateway_ShippingProductController_buyerListProducts(
  body: API.ShipProductListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ListProductResponse>('/salesgateway/v1/shipping/product/buyerListProducts', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_buyerListProducts',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 模糊查询获取城市港口选项 GET /salesgateway/v1/shipping/product/cityPortOptions/nameFuzzy */
export async function OperationId_salesgateway_ShippingProductController_nameFuzzyCityPortOptions(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingProductControllerNameFuzzyCityPortOptionsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipAddAndPortRes[]>(
    '/salesgateway/v1/shipping/product/cityPortOptions/nameFuzzy',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingProductController_nameFuzzyCityPortOptions',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 海运商品创建并提交审核 POST /salesgateway/v1/shipping/product/create */
export async function OperationId_salesgateway_ShippingProductController_createProduct(
  body: API.CreateProductShipProdReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/v1/shipping/product/create', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_createProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品详情 GET /salesgateway/v1/shipping/product/detailProduct */
export async function OperationId_salesgateway_ShippingProductController_detailProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingProductControllerDetailProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipProductRes>('/salesgateway/v1/shipping/product/detailProduct', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_detailProduct',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查询商品详情_下单前 GET /salesgateway/v1/shipping/product/detailProductBeforeOrder */
export async function OperationId_salesgateway_ShippingProductController_detailProductBeforeOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayShippingProductControllerDetailProductBeforeOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipProductResBeforeOrder>(
    '/salesgateway/v1/shipping/product/detailProductBeforeOrder',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingProductController_detailProductBeforeOrder',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 查询推热门商品列表 POST /salesgateway/v1/shipping/product/listHotProducts */
export async function OperationId_salesgateway_ShippingProductController_listHotProducts(
  body: API.ShipProductListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ListProductResponse>('/salesgateway/v1/shipping/product/listHotProducts', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_listHotProducts',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 批量撤销/提交审核 PUT /salesgateway/v1/shipping/product/offSaleItem */
export async function OperationId_salesgateway_ShippingProductController_auditItems(
  body: API.AuditItemsReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipComRes>('/salesgateway/v1/shipping/product/offSaleItem', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_auditItems',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品操作记录 POST /salesgateway/v1/shipping/product/operationLogList */
export async function OperationId_salesgateway_ShippingProductController_operationLogList(
  body: API.ShipProductVersionReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ListOpreationRes>('/salesgateway/v1/shipping/product/operationLogList', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_operationLogList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品版本 POST /salesgateway/v1/shipping/product/pageItemProductVersion */
export async function OperationId_salesgateway_ShippingProductController_pageItemProductVersion(
  body: API.ShipProductVersionReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ListProductResponse>(
    '/salesgateway/v1/shipping/product/pageItemProductVersion',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ShippingProductController_pageItemProductVersion',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 商品下架 PUT /salesgateway/v1/shipping/product/productOffSale */
export async function OperationId_salesgateway_ShippingProductController_productOffSale(
  body: number[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipComRes>('/salesgateway/v1/shipping/product/productOffSale', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_productOffSale',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 商品上架 PUT /salesgateway/v1/shipping/product/productOnSale */
export async function OperationId_salesgateway_ShippingProductController_productOnSale(
  body: number[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipComRes>('/salesgateway/v1/shipping/product/productOnSale', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_productOnSale',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品列表Seller POST /salesgateway/v1/shipping/product/sellerListProducts */
export async function OperationId_salesgateway_ShippingProductController_sellerListProducts(
  body: API.ShipProductListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ListProductResponse>('/salesgateway/v1/shipping/product/sellerListProducts', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_sellerListProducts',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 批量修改海运商品库存 POST /salesgateway/v1/shipping/product/updateInventoryList */
export async function OperationId_salesgateway_ShippingProductController_updateInventoryList(
  body: API.SkuReq[],
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipComRes>('/salesgateway/v1/shipping/product/updateInventoryList', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_updateInventoryList',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 海运商品更新item以及spu PUT /salesgateway/v1/shipping/product/updateItemAndSpu */
export async function OperationId_salesgateway_ShippingProductController_updateItemAndSpu(
  body: API.ShipUpdateItemSpuReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipComRes>('/salesgateway/v1/shipping/product/updateItemAndSpu', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_updateItemAndSpu',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 海运商品更新 POST /salesgateway/v1/shipping/product/updateProduct */
export async function OperationId_salesgateway_ShippingProductController_updateProduct(
  body: API.CreateProductShipProdReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/salesgateway/v1/shipping/product/updateProduct', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_updateProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 海运商品更新skuAndFee PUT /salesgateway/v1/shipping/product/updateSkuAndExFee */
export async function OperationId_salesgateway_ShippingProductController_updateItemProduct(
  body: API.ShipUpdateSkuAndExFeeReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ShipComRes>('/salesgateway/v1/shipping/product/updateSkuAndExFee', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ShippingProductController_updateItemProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
