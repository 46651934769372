import { i18n as transformI18n } from 'next-i18next';

function replaceStringParams(template: string, params: Record<string, any> = {}) {
  return template.replace(/{{(.*?)}}/g, (_, key) => params[key.trim()] || '');
}

const i18n = {
  t: (key: string, options?: any) =>
    (transformI18n?.t(key, {
      ns: 'translation',
      ...options,
    }) as string) || replaceStringParams(key, options),
};

export { i18n };
