import { i18n } from '@easygo/i18n';
/** 平台类型 */
export enum PLATFORM_TYPE {
  /** 服务商端 */
  BUIER = 'BUIER',
  /** 客户端 */
  SALER = 'SALER',
  /** 运营平台 */
  ADMIN = 'ADMIN',
}

/** 工作台类型 */
export enum WORK_BANCH_TYPE {
  TRAIN = 'TRAIN_WORK_BANCH',
  OCEAN = 'OCEAN_WORK_BANCH',
}

export const WORK_BANCH_LABLE = {
  [WORK_BANCH_TYPE.TRAIN]: i18n?.t('班列看板'),
  [WORK_BANCH_TYPE.OCEAN]: i18n?.t('海运看板'),
};

export const StatusStyleConfigMap = {
  /** 未开始/流程中的初始节点状态 */
  START: { color: '#00A69F', background: '#F5FFFF', borderColor: '#D7F2F0' },
  /** 正常结束、未知的节点（结束、完成状态） */
  END: { color: '#2C334D', background: '#F4F6F9', borderColor: '#E8E9EE' },
  /** 失败、中断节点、异常结束（失败状态） */
  ERROR: { color: '#FF4D4F', background: '#FFF8F8', borderColor: '#FFDFDF' },
  /** 正常流程中的节点（成功状态） */
  PROGRESS: { color: '#58AF2C', background: '#F7FFF3', borderColor: '#DBF5CF' },
  /** 警告、异常（警告状态） */
  WARNING: { color: '#FBA000', background: '#FFFCF8', borderColor: '#FFEBC7' },
};

/** 境内外 */
export enum FrontierType {
  /** 境内 */
  DOMESTIC = 'DOMESTIC',
  /** 境外 */
  OVERSEAS = 'OVERSEAS',
}

/** 买家关联卖家的关联类型 */
export enum RELATION_TYPE {
  /** 邀请码 */
  CODE = 'SUPPLIER_STOCK_USER_RELATION_TYPE_CODE',
  /** 客户下单 */
  ORDER = 'SUPPLIER_STOCK_USER_RELATION_TYPE_ORDER',
}
/** 买家关联卖家的关联类型 */
export const RelationTypeOptions = {
  [RELATION_TYPE.CODE]: '邀请码',
  [RELATION_TYPE.ORDER]: '客户下单',
};

/** 注册企业邀请码类型 */
export enum INVITATION_TYPE {
  /** 企业 */
  ENTERPRISE = 'INVITATION_TYPE_ENTERPRISE',
  /** 销售 */
  SELL = 'INVITATION_TYPE_SELL',
}

export enum OldBillSettlementType {
  MONTH = 'BILL_SETTLEMENT_TYPE_MONTH',
}

export const BUSINESS_TYPE_LABEL = {
  BUSINESS_TYPE_UNKNOWN: '未知类型',
  BUSINESS_TYPE_LEASING: '租箱',
  BUSINESS_TYPE_TRAINS_BOOKING_SPACE: '铁路订舱',
  BUSINESS_TYPE_SEA_BOOKING_SPACE: '铁路订舱',
  BUSINESS_TYPE_TRAILER: '拖车',
  BUSINESS_TYPE_CUSTOMS: '报关',
  BUSINESS_TYPE_SECOND_HAND: '二手箱买卖',
  BUSINESS_TYPE_CASH_PLEDGE: '押金缴纳',
  BUSINESS_TYPE_SERVICE_FEE: '服务费用',
  BUSINESS_TYPE_CREDIT_REPAY: '账期还款',
  BUSINESS_TYPE_SUPPLY_CHAIN_FINANCE: '供应链金融',
};

// 业务线枚举类型
export enum categoryIdEnum {
  RENTBOX = '9108763151749120',
  MARINE = '9108765257289728',
  RAILWAY = '9108765907406848',
  TRAILER = '9108764598784000',
  DECLARE = '9108763940278272',
}
export const categoryId = {
  [categoryIdEnum.RENTBOX]: '租箱',
  [categoryIdEnum.MARINE]: '海运舱',
  [categoryIdEnum.RAILWAY]: '班列',
  [categoryIdEnum.TRAILER]: '拖车',
  [categoryIdEnum.DECLARE]: '报关',
};

/** 签署状态枚举 */
export enum EsignCertificationStatus {
  /** 未授权 */
  CERTIFICATION_STATUS_TYPE_UNAUTHORIZED = 'CERTIFICATION_STATUS_TYPE_UNAUTHORIZED',
  /** 进行中 */
  CERTIFICATION_STATUS_TYPE_PROGRESSING = 'CERTIFICATION_STATUS_TYPE_PROGRESSING',
  /** 完成 */
  CERTIFICATION_STATUS_TYPE_COMPLETED = 'CERTIFICATION_STATUS_TYPE_COMPLETED',
  /** 失败 */
  CERTIFICATION_STATUS_TYPE_FAILED = 'CERTIFICATION_STATUS_TYPE_FAILED',
}
