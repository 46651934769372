// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 根据企业名称查询企业信息 POST /salesgateway/third/api/getByKeyword */
export async function OperationId_salesgateway_ThirdPartyController_getEnterPriseBusinessInfo(
  body: API.QccQueryRequestVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.QccEnterpriseInfoResponseVO>('/salesgateway/third/api/getByKeyword', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ThirdPartyController_getEnterPriseBusinessInfo',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
