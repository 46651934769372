import { i18n } from '@easygo/i18n';
export const RailwayKeyCodeMap: Record<string, string> = {
  trainsNo: '5b225b',
  trainsCompany: 'e3fa23',
  importOrExport: '9a5dc9',
  exitPort: '0b6d7c',
  entryPort: 'e352a3',
  spaceType: 'a66ce8',
  holdBoxPeople: '6819d5',
  remark: 'd10d4a',
  sourceCounty: '63ef8e',
  sourceCity: '533758',
  sourceStation: '6f5a37',
  targetCounty: 'd9d093',
  targetCity: '203f87',
  targetStation: 'b29469',
  departureStartTime: 'd4f210',
  departureEndTime: 'd4969e',
  jiedanTime: '9a1e6f',
  jiechongTime: 'eca530',
  jieguanTime: 'fdfc01',
  boxType: 'c60cba',
};

export const enum RAILWAY_PERFORMANCE_STATUS {
  /** 待接单 */
  ORDER_WAITING = 'RAILWAY_PERFORMANCE_STATUS_ORDER_WAITING',
  /** 待放舱位 */
  PO_WAITING = 'RAILWAY_PERFORMANCE_STATUS_PO_WAITING',
  /** 已放舱待发车 */
  CONFIRM_PO_WAITING_PULL_OUT = 'RAILWAY_PERFORMANCE_STATUS_CONFIRM_PO_WAITING_PULL_OUT',
  /** 已发车 */
  PULL_OUT = 'RAILWAY_PERFORMANCE_STATUS_PULL_OUT',
  /** 已到站 */
  ARRIVE = 'RAILWAY_PERFORMANCE_STATUS_ARRIVE',
  /** 上传提货指令 */
  UPLOAD_DELIVERY_INSTRUCTIONS = 'RAILWAY_PERFORMANCE_STATUS_UPLOAD_DELIVERY_INSTRUCTIONS',
  /** 已取消（取消/拒绝） */
  ORDER_CLOSE = 'RAILWAY_PERFORMANCE_STATUS_ORDER_CLOSE',
  /** 已完成 */
  ORDER_COMPLETED = 'RAILWAY_PERFORMANCE_STATUS_ORDER_COMPLETED',
}

export const RAILWAY_PERFORMANCE_STATUS_TO_CN = {
  [RAILWAY_PERFORMANCE_STATUS.ORDER_WAITING]: i18n?.t('待接单'),
  [RAILWAY_PERFORMANCE_STATUS.PO_WAITING]: i18n?.t('待放舱位'),
  [RAILWAY_PERFORMANCE_STATUS.CONFIRM_PO_WAITING_PULL_OUT]: i18n?.t('已放舱待发车'),
  [RAILWAY_PERFORMANCE_STATUS.PULL_OUT]: i18n?.t('已发车'),
  [RAILWAY_PERFORMANCE_STATUS.ARRIVE]: i18n?.t('已到站'),
  [RAILWAY_PERFORMANCE_STATUS.UPLOAD_DELIVERY_INSTRUCTIONS]: i18n?.t('上传提货指令'),
  [RAILWAY_PERFORMANCE_STATUS.ORDER_CLOSE]: i18n?.t('已取消'),
  [RAILWAY_PERFORMANCE_STATUS.ORDER_COMPLETED]: i18n?.t('已完成'),
};

export const RAILWAY_PERFORMANCE_STATUS_TO_STEP = {
  [RAILWAY_PERFORMANCE_STATUS.ORDER_WAITING]: 1,
  [RAILWAY_PERFORMANCE_STATUS.PO_WAITING]: 2,
  [RAILWAY_PERFORMANCE_STATUS.CONFIRM_PO_WAITING_PULL_OUT]: 3,
  [RAILWAY_PERFORMANCE_STATUS.PULL_OUT]: 4,
  [RAILWAY_PERFORMANCE_STATUS.ARRIVE]: 5,
  [RAILWAY_PERFORMANCE_STATUS.UPLOAD_DELIVERY_INSTRUCTIONS]: 6,
  [RAILWAY_PERFORMANCE_STATUS.ORDER_CLOSE]: 7,
  [RAILWAY_PERFORMANCE_STATUS.ORDER_COMPLETED]: 7,
};

export type TankType = 'TANK_TYPE_UNKNOWN' | 'TANK_TYPE_SOC' | 'TANK_TYPE_COC' | 'UNRECOGNIZED';

export type PAYMENT_STATUS =
  | 'PAYMENT_STATUS_UNKNOWN'
  | 'PAYMENT_STATUS_NON_PAYMENT'
  | 'PAYMENT_STATUS_PART_PAYMENT'
  | 'PAYMENT_STATUS_ALL_PAYMENT'
  | 'UNRECOGNIZED';

// 支付状态
export enum PaymentStatus {
  UNKNOWN = 'PAYMENT_STATUS_UNKNOWN',
  /** 未付款 */
  NON_PAYMENT = 'PAYMENT_STATUS_NON_PAYMENT',
  /** 部分付款 */
  PART_PAYMENT = 'PAYMENT_STATUS_PART_PAYMENT',
  /** 全付款 */
  ALL_PAYMENT = 'PAYMENT_STATUS_ALL_PAYMENT',
}

export enum RailwayPerformanceAction {
  /** 提交下单 */
  ORDER_SUBMIT = 'RAILWAY_PERFORMANCE_ACTION_ORDER_SUBMIT',
  /** 卖家接单 */
  ORDER_ACCEPT = 'RAILWAY_PERFORMANCE_ACTION_ORDER_ACCEPT',
  /** 卖家拒单 */
  ORDER_REJECT = 'RAILWAY_PERFORMANCE_ACTION_ORDER_REJECT',
  /** 买家取消单 */
  ORDER_CANCEL = 'RAILWAY_PERFORMANCE_ACTION_ORDER_CANCEL',
  /** 确认舱位 */
  CONFIRM_PO = 'RAILWAY_PERFORMANCE_ACTION_CONFIRM_PO',
  /** 提空 */
  PICK_UP = 'RAILWAY_PERFORMANCE_ACTION_PICK_UP',
  /** 上传随车资料 */
  UPLOAD_ACCESSORY_MATERIALS = 'RAILWAY_PERFORMANCE_ACTION_UPLOAD_ACCESSORY_MATERIALS',
  /** 重柜入站 */
  HEAVY_TANK = 'RAILWAY_PERFORMANCE_ACTION_HEAVY_TANK',
  /** 海关放行 */
  CUSTOMS_RELEASE = 'RAILWAY_PERFORMANCE_ACTION_CUSTOMS_RELEASE',
  /** 核对运单信息 */
  CHECK_WAYBILL = 'RAILWAY_PERFORMANCE_ACTION_CHECK_WAYBILL',
  /** 发车 */
  PULL_OUT = 'RAILWAY_PERFORMANCE_ACTION_PULL_OUT',
  /** 确认账单 */
  CONFIRM_BILL = 'RAILWAY_PERFORMANCE_ACTION_CONFIRM_BILL',
  /** 确认收讫 */
  CONFIRM_PAYMENT_RECEIVED = 'RAILWAY_PERFORMANCE_ACTION_CONFIRM_PAYMENT_RECEIVED',
  /** 到站 */
  ARRIVE = 'RAILWAY_PERFORMANCE_ACTION_ARRIVE',
  /** 提供提货指令 */
  OFFER_DELIVERY_INSTRUCTIONS = 'RAILWAY_PERFORMANCE_ACTION_OFFER_DELIVERY_INSTRUCTIONS',
  /** 还箱 */
  BOX_OF_RETURN = 'RAILWAY_PERFORMANCE_ACTION_BOX_OF_RETURN',
}

export const RAILWAY_PERFORMANCE_ACTION_TO_CN = {
  [RailwayPerformanceAction.ORDER_ACCEPT]: i18n?.t('卖家接单'),
  [RailwayPerformanceAction.ORDER_REJECT]: i18n?.t('卖家拒单'),
  [RailwayPerformanceAction.ORDER_CANCEL]: i18n?.t('买家取消订单'),
};

export const enum ATTACHMENT_TYPE {
  /** 未知 */
  UNKNOWN = 'ATTACHMENT_TYPE_UNKNOWN',
  /** 确认舱位号 */
  CONFIRM_PO = 'ATTACHMENT_TYPE_CONFIRM_PO',
  /** 报关 */
  CUSTOMS_DECLARE = 'ATTACHMENT_TYPE_CUSTOMS_DECLARE',
  /** 通关凭证 */
  CUSTOMS_CREDENTIALS = 'ATTACHMENT_TYPE_CUSTOMS_CREDENTIALS',
  /** 托书 */
  VOTING_TRUST = 'ATTACHMENT_TYPE_VOTING_TRUST',
  /** 运单样本 */
  WAYBILL_MATERIALS = 'ATTACHMENT_TYPE_WAYBILL_MATERIALS',
  /** 运单 */
  WAYBILL = 'ATTACHMENT_TYPE_WAYBILL',
  /** 箱单发票 */
  INVOICE = 'ATTACHMENT_TYPE_INVOICE',
  /** 运单倒入表 */
  WAYBILL_TABLE = 'ATTACHMENT_TYPE_WAYBILL_TABLE',
  /** 装箱照片 */
  PACKING_PHOTO = 'ATTACHMENT_TYPE_PACKING_PHOTO',
  /** 装箱方案 */
  PACKING_SOLUTION = 'ATTACHMENT_TYPE_PACKING_SOLUTION',
  /** 核实箱重 */
  CONFIRM_BOX_HEAVY = 'ATTACHMENT_TYPE_CONFIRM_BOX_HEAVY',
  /** 装箱清单 */
  PACKING_LIST = 'ATTACHMENT_TYPE_PACKING_LIST',
  /** 贸易合同 */
  TRADE_CONTRACT = 'ATTACHMENT_TYPE_TRADE_CONTRACT',
  /** 对账单 */
  STATEMENTS = 'ATTACHMENT_TYPE_STATEMENTS',
}

export const RailwayPerformanceStatusCode = {
  /** 0b00000001 已取消（取消/拒绝）*/
  [RAILWAY_PERFORMANCE_STATUS.ORDER_CLOSE]: 0b00000001,
  /** 0b00000010 待接单 */
  [RAILWAY_PERFORMANCE_STATUS.ORDER_WAITING]: 0b00000010,
  /** 0b00000100 待放舱位 */
  [RAILWAY_PERFORMANCE_STATUS.PO_WAITING]: 0b00000100,
  /** 0b00001000 已放舱待发车 */
  [RAILWAY_PERFORMANCE_STATUS.CONFIRM_PO_WAITING_PULL_OUT]: 0b00001000,
  /** 0b00010000 已发车 */
  [RAILWAY_PERFORMANCE_STATUS.PULL_OUT]: 0b00010000,
  /** 0b00100000 已到站 */
  [RAILWAY_PERFORMANCE_STATUS.ARRIVE]: 0b00100000,
  /** 0b01000000 上传提货指令 */
  [RAILWAY_PERFORMANCE_STATUS.UPLOAD_DELIVERY_INSTRUCTIONS]: 0b01000000,
  /** 0b10000000 已完成 */
  [RAILWAY_PERFORMANCE_STATUS.ORDER_COMPLETED]: 0b10000000,
};

export const attachmentTypeLabel = {
  [ATTACHMENT_TYPE.CONFIRM_PO]: i18n?.t('确认舱位号'),
  [ATTACHMENT_TYPE.CUSTOMS_DECLARE]: i18n?.t('报关'),
  [ATTACHMENT_TYPE.CUSTOMS_CREDENTIALS]: i18n?.t('通关凭证'),
  [ATTACHMENT_TYPE.VOTING_TRUST]: i18n?.t('托书'),
  [ATTACHMENT_TYPE.WAYBILL_MATERIALS]: i18n?.t('运单样本'),
  [ATTACHMENT_TYPE.WAYBILL]: i18n?.t('运单'),
  [ATTACHMENT_TYPE.INVOICE]: i18n?.t('箱单发票'),
  [ATTACHMENT_TYPE.WAYBILL_TABLE]: i18n?.t('运单倒入表'),
  [ATTACHMENT_TYPE.PACKING_PHOTO]: i18n?.t('装箱照片'),
  [ATTACHMENT_TYPE.PACKING_SOLUTION]: i18n?.t('装箱方案'),
};

/** 账单制作状态 */
export enum BillMadeStatus {
  /** 未制作账单 */
  NOT_MADE = 'BILL_MADE_NOT_MADE',
  /** 已制作账单 */
  HAS_MADE = 'BILL_MADE_HAS_MADE',
}

export enum INSTRUCTIONS_TYPE {
  /** ATB是一个电子文档，支持上传附件 */
  ATB = 'INSTRUCTIONS_TYPE_ATB',
  /** DSK是一串号码，无附件 */
  DSK = 'INSTRUCTIONS_TYPE_DSK',
}

export const instructionsTypeLabel = {
  [INSTRUCTIONS_TYPE.ATB]: 'ATB',
  [INSTRUCTIONS_TYPE.DSK]: 'DSK',
};

/** 空重箱类型 */
export enum CntrEmpty_Type {
  /** 空箱 */
  EMPTY = 'BOX_CATEGORY_EMPTY_BOX',
  /** 重箱 */
  WEIGHT = 'BOX_CATEGORY_WEIGHT_BOX',
}

/** 空重箱类型文案 */
export const CntrEmptyTypeLabel = {
  /** 空箱 */
  [CntrEmpty_Type.EMPTY]: i18n?.t('空箱'),
  /** 重箱 */
  [CntrEmpty_Type.WEIGHT]: i18n?.t('重箱'),
};

/** 提箱状态 */
export enum PICKUPS_DROPS_STATUS {
  /** 已发放 */
  OFFERED = 'OFFERED',
  /** 待发放 */
  NOT_OFFER = 'NOT_OFFER',
  /** 已作废 */
  CANCLE = 'CANCLE',
}

/** 提箱状态文案 */
export const PickupDropsStatusLabel = {
  [PICKUPS_DROPS_STATUS.OFFERED]: {
    label: i18n?.t('已发放'),
    color: '#44AF0F',
  },
  [PICKUPS_DROPS_STATUS.NOT_OFFER]: {
    label: i18n?.t('待发放'),
    color: '#00A69F',
  },
  [PICKUPS_DROPS_STATUS.CANCLE]: {
    label: '已作废',
    color: '#FF4747',
  },
};

//履约单状态
export enum PerformanceOrderStatus {
  STATUS_WAITING_ACCEPT = 'STATUS_WAITING_ACCEPT', //待接单
  STATUS_WAITING_CONFIRM = 'STATUS_WAITING_CONFIRM', //待确认
  STATUS_ALREADY_ACCEPT = 'STATUS_ALREADY_ACCEPT', //已接单
  STATUS_ORDER_CLOSE = 'STATUS_ORDER_CLOSE', //已关闭
  STATUS_ORDER_CANCEL = 'STATUS_ORDER_CANCEL', //已取消
  STATUS_ORDER_DONE = 'STATUS_ORDER_DONE', //已完成
}
/** 订单服务类型 */
export enum Order_Service_Type {
  /** 门到站 */
  ORDER_SERVICE_TRAIN_DOOR_TO_STATION = 'ORDER_SERVICE_TRAIN_DOOR_TO_STATION',
  /** 站到站 */
  ORDER_SERVICE_TRAIN_STATION_TO_STATION = 'ORDER_SERVICE_TRAIN_STATION_TO_STATION',
  /** 门到门 */
  ORDER_SERVICE_TRAIN_DOOR_TO_DOOR = 'ORDER_SERVICE_TRAIN_DOOR_TO_DOOR',
  /** 仅租箱 */
  ORDER_SERVICE_TRAIN_ONLY_CONTAINER = 'ORDER_SERVICE_TRAIN_ONLY_CONTAINER',
}

/** 订单服务类型文本 */
export const Order_Service_Type_Txt = {
  /** 门到站 */
  [Order_Service_Type.ORDER_SERVICE_TRAIN_DOOR_TO_STATION]: '门到站',
  /** 站到站 */
  [Order_Service_Type.ORDER_SERVICE_TRAIN_STATION_TO_STATION]: '站到站',
  /** 门到门 */ // 这期不做门到门
  // [Order_Service_Type.ORDER_SERVICE_TRAIN_DOOR_TO_DOOR]: '门到门',
  /** 仅租箱仅租箱 */
  [Order_Service_Type.ORDER_SERVICE_TRAIN_ONLY_CONTAINER]: '仅租箱',
};

/** 舱位类型 */
export enum Space_Type {
  /** COC */
  CABIN_COC = 'CABIN_COC',
  /** SOC */
  CABIN_SOC = 'CABIN_SOC',
}

/** 舱位类型 */
export const Space_Type_Txt = {
  /** COC */
  [Space_Type.CABIN_COC]: 'COC',
  /** SOC */
  [Space_Type.CABIN_SOC]: 'SOC',
};

/** 提货类型 */
export enum Pickup_Type {
  /** 工厂提货 */
  PICKUP_TYPE_FACTORY = 'PICKUP_TYPE_FACTORY',
  /** 堆场提货 */
  PICKUP_TYPE_YARD = 'PICKUP_TYPE_YARD',
}

/** 提货类型 */
export const Pickup_Type_Txt = {
  /** 工厂提货 */
  [Pickup_Type.PICKUP_TYPE_FACTORY]: '工厂提货',
  /** 堆场提货 */
  [Pickup_Type.PICKUP_TYPE_YARD]: '堆场提货',
};

/** 服务类型 */
export enum Service_Type {
  /** 履约单 */
  PERFORMANCE_DOCUMENT_TYPE_PERFORMANCE = 'PERFORMANCE_DOCUMENT_TYPE_PERFORMANCE',
  /** 班列 */
  PERFORMANCE_DOCUMENT_TYPE_TRAIN = 'PERFORMANCE_DOCUMENT_TYPE_TRAIN',
  /** 拖车-国内运输 */
  PERFORMANCE_DOCUMENT_TYPE_TRAILER = 'PERFORMANCE_DOCUMENT_TYPE_TRAILER',
  /** 报关 */
  PERFORMANCE_DOCUMENT_TYPE_DECLARATION = 'PERFORMANCE_DOCUMENT_TYPE_DECLARATION',
  /** 租箱 */
  PERFORMANCE_DOCUMENT_TYPE_CONTAINER = 'PERFORMANCE_DOCUMENT_TYPE_CONTAINER',
}

/** 服务类型 */
export const Service_Type_Txt = {
  /** 履约单 */
  // PERFORMANCE_DOCUMENT_TYPE_PERFORMANCE: '履约单',
  /** 班列 */
  PERFORMANCE_DOCUMENT_TYPE_TRAIN: '舱位服务',
  /** 拖车-国内运输 */
  PERFORMANCE_DOCUMENT_TYPE_TRAILER: '国内运输',
  /** 报关 */
  PERFORMANCE_DOCUMENT_TYPE_DECLARATION: '报关',
  /** 租箱 */
  PERFORMANCE_DOCUMENT_TYPE_CONTAINER: '集装箱租用',
  /** 堆场 */
  PERFORMANCE_DOCUMENT_TYPE_YARD: '堆场服务',
};

/** 需求单状态 */
export enum Demand_Status {
  /** 待处理 */
  DEMAND_STATUS_WAITING = 'DEMAND_STATUS_WAITING',
  /** 已关闭 */
  DEMAND_STATUS_CLOSE = 'DEMAND_STATUS_CLOSE',
  /** 报价完成 */
  DEMAND_STATUS_QUOTE = 'DEMAND_STATUS_QUOTE',
  /** 已成交 */
  DEMAND_STATUS_DEAL = 'DEMAND_STATUS_DEAL',
}

/** 需求单状态 */
export const Demand_Status_Txt = {
  /** 待处理 */
  [Demand_Status.DEMAND_STATUS_WAITING]: '待处理',
  /** 已关闭 */
  [Demand_Status.DEMAND_STATUS_CLOSE]: '已关闭',
  /** 报价完成 */
  [Demand_Status.DEMAND_STATUS_QUOTE]: '报价完成',
  /** 已成交 */
  [Demand_Status.DEMAND_STATUS_DEAL]: '已成交',
};

/** 销售类型 */
export enum Sale_Type {
  /** 预售 */
  SALE_TYPE_PRE_SALE = 'SALE_TYPE_PRE_SALE',
  /** 现舱 */
  SALE_TYPE_SPOT_SALE = 'SALE_TYPE_SPOT_SALE',
}

/** 销售类型 */
export const Sale_Type_Txt = {
  /** 预售 */
  [Sale_Type.SALE_TYPE_PRE_SALE]: '预售',
  /** 现舱 */
  [Sale_Type.SALE_TYPE_SPOT_SALE]: '现舱',
};

/** 服务类型 */
export enum Search_Service_Type {
  /** 报关服务 */
  DECLARATION_SERVICE = 'DECLARATION_SERVICE',
  /** 集装箱租赁 */
  CONTAINER_LEASING_SERVICE = 'CONTAINER_LEASING_SERVICE',
  /** 上门提货 */
  DELIVERY_SERVICE = 'DELIVERY_SERVICE',
  /** 订舱 */
  ORDER_SPACE = 'ORDER_SPACE',
}

/** 服务类型 */
export const Search_Service_Type_Text = {
  /** 订舱 */
  [Search_Service_Type.ORDER_SPACE]: '订舱',
  /** 报关服务 */
  [Search_Service_Type.DECLARATION_SERVICE]: '报关服务',
  /** 用箱 */
  [Search_Service_Type.CONTAINER_LEASING_SERVICE]: '用箱',
  /** 上门提货 */
  [Search_Service_Type.DELIVERY_SERVICE]: '上门提货',
};
