// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询企业经营范围信息 GET /sinotransbooking/v1/operation/business/scope/${param0} */
export async function OperationId_sinotransbooking_OperationBusinessScopeController_detail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationBusinessScopeControllerDetailParams,
  options?: { [key: string]: any },
) {
  const { enterpriseId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BusinessScopeInfoResponse>(
    `/sinotransbooking/v1/operation/business/scope/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationBusinessScopeController_detail',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 新增或编辑企业经营范围 PUT /sinotransbooking/v1/operation/business/scope/${param0} */
export async function OperationId_sinotransbooking_OperationBusinessScopeController_addOrEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationBusinessScopeControllerAddOrEditParams,
  body: API.AddOrEditBusinessScopeRequest,
  options?: { [key: string]: any },
) {
  const { enterpriseId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/operation/business/scope/${param0}`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OperationBusinessScopeController_addOrEdit',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 企业经营范围操作日志 GET /sinotransbooking/v1/operation/business/scope/${param0}/operate-logs */
export async function OperationId_sinotransbooking_OperationBusinessScopeController_operateLogs(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOperationBusinessScopeControllerOperateLogsParams,
  options?: { [key: string]: any },
) {
  const { enterpriseId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BusinessScopeOperateLogResponse[]>(
    `/sinotransbooking/v1/operation/business/scope/${param0}/operate-logs`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OperationBusinessScopeController_operateLogs',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
