// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取私有下载地址 OpenOssController.getPrivateDownloadSignedUrl GET /sinotransbooking/v1/open/oss/private/download/${param0}/sign */
export async function OperationId_sinotransbooking_OpenOssController_getPrivateDownloadSignedUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenOssControllerGetPrivateDownloadSignedUrlParams,
  options?: { [key: string]: any },
) {
  const { name: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>(
    `/sinotransbooking/v1/open/oss/private/download/${param0}/sign`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_OpenOssController_getPrivateDownloadSignedUrl',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取私有Sts凭证 OpenOssController.getPrivateStsToken GET /sinotransbooking/v1/open/oss/private/sts */
export async function OperationId_sinotransbooking_OpenOssController_getPrivateStsToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenOssControllerGetPrivateStsTokenParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StsResponseVo>('/sinotransbooking/v1/open/oss/private/sts', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenOssController_getPrivateStsToken',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取私有上传地址 OpenOssController.getPrivateUploadSignedUrl GET /sinotransbooking/v1/open/oss/private/upload/sign */
export async function OperationId_sinotransbooking_OpenOssController_getPrivateUploadSignedUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenOssControllerGetPrivateUploadSignedUrlParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>('/sinotransbooking/v1/open/oss/private/upload/sign', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenOssController_getPrivateUploadSignedUrl',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取公有Sts凭证 OpenOssController.getPublicStsToken GET /sinotransbooking/v1/open/oss/public/sts */
export async function OperationId_sinotransbooking_OpenOssController_getPublicStsToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenOssControllerGetPublicStsTokenParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.StsResponseVo>('/sinotransbooking/v1/open/oss/public/sts', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenOssController_getPublicStsToken',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 获取公有上传地址 OpenOssController.getPublicUploadUrl GET /sinotransbooking/v1/open/oss/public/upload/sign */
export async function OperationId_sinotransbooking_OpenOssController_getPublicUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenOssControllerGetPublicUploadUrlParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.UrlResponseVo>('/sinotransbooking/v1/open/oss/public/upload/sign', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenOssController_getPublicUploadUrl',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}
