// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询地址 GET /salesgateway/v1/config-center/addresses */
export async function OperationId_salesgateway_ConfigCenterController_pageAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPageAddressParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageAddressDTO>('/salesgateway/v1/config-center/addresses', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pageAddress',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取地址详情 GET /salesgateway/v1/config-center/addresses/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetAddressParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AddressVO>(`/salesgateway/v1/config-center/addresses/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_getAddress',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 分页查询箱型 GET /salesgateway/v1/config-center/box-types */
export async function OperationId_salesgateway_ConfigCenterController_pageBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPageBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageBoxTypeDTO>('/salesgateway/v1/config-center/box-types', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pageBoxType',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取箱型详情 GET /salesgateway/v1/config-center/box-types/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BoxTypeDTO>(`/salesgateway/v1/config-center/box-types/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_getBoxType',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 分页查询港口 GET /salesgateway/v1/config-center/harbours */
export async function OperationId_salesgateway_ConfigCenterController_pageHarbour(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPageHarbourParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageHarbourDTO>('/salesgateway/v1/config-center/harbours', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pageHarbour',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取港口详情 GET /salesgateway/v1/config-center/harbours/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getHarbour(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetHarbourParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.HarbourDTO>(`/salesgateway/v1/config-center/harbours/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_getHarbour',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 分页查询港口(排除未绑定城市的港口) GET /salesgateway/v1/config-center/harboursNotIncludedCity */
export async function OperationId_salesgateway_ConfigCenterController_pageHarbourNotIncludedCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPageHarbourNotIncludedCityParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageHarbourDTO>('/salesgateway/v1/config-center/harboursNotIncludedCity', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pageHarbourNotIncludedCity',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取履约配置 GET /salesgateway/v1/config-center/performances/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getPerformance(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetPerformanceParams,
  options?: { [key: string]: any },
) {
  const { categoryId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceConfigDTO>(
    `/salesgateway/v1/config-center/performances/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ConfigCenterController_getPerformance',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 根据业务线类型获取履约配置 GET /salesgateway/v1/config-center/performances/type/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getPerformanceType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetPerformanceTypeParams,
  options?: { [key: string]: any },
) {
  const { type: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PerformanceConfigDTO>(
    `/salesgateway/v1/config-center/performances/type/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ConfigCenterController_getPerformanceType',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 分页查询口岸城市 GET /salesgateway/v1/config-center/port-cities */
export async function OperationId_salesgateway_ConfigCenterController_pagePortCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPagePortCityParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePortCityDTO>('/salesgateway/v1/config-center/port-cities', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pagePortCity',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取口岸城市 GET /salesgateway/v1/config-center/port-cities/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getPortCity(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetPortCityParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PortCityDTO>(`/salesgateway/v1/config-center/port-cities/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_getPortCity',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 分页查询铁路公司 GET /salesgateway/v1/config-center/railway-companies */
export async function OperationId_salesgateway_ConfigCenterController_pageRailwayCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPageRailwayCompanyParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageRailwayCompanyDTO>('/salesgateway/v1/config-center/railway-companies', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pageRailwayCompany',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取铁路公司详情 GET /salesgateway/v1/config-center/railway-companies/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getRailwayCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetRailwayCompanyParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.RailwayCompanyDTO>(
    `/salesgateway/v1/config-center/railway-companies/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ConfigCenterController_getRailwayCompany',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 分页查询铁路站 GET /salesgateway/v1/config-center/railway-stations */
export async function OperationId_salesgateway_ConfigCenterController_pageRailwayStation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPageRailwayStationParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageRailwayStationDTO>('/salesgateway/v1/config-center/railway-stations', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pageRailwayStation',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取铁路站详情 GET /salesgateway/v1/config-center/railway-stations/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getRailwayStation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetRailwayStationParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.RailwayStationDTO>(
    `/salesgateway/v1/config-center/railway-stations/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ConfigCenterController_getRailwayStation',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 获取汇率 GET /salesgateway/v1/config-center/rate */
export async function OperationId_salesgateway_ConfigCenterController_getRate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetRateParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ResultResponseString>('/salesgateway/v1/config-center/rate', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_getRate',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 分页查询海运公司 GET /salesgateway/v1/config-center/shipping-companies */
export async function OperationId_salesgateway_ConfigCenterController_pageShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerPageShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageShippingCompanyDTO>('/salesgateway/v1/config-center/shipping-companies', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ConfigCenterController_pageShippingCompany',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取海运公司详情 GET /salesgateway/v1/config-center/shipping-companies/${param0} */
export async function OperationId_salesgateway_ConfigCenterController_getShippingCompany(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayConfigCenterControllerGetShippingCompanyParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ShippingCompanyDTO>(
    `/salesgateway/v1/config-center/shipping-companies/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_ConfigCenterController_getShippingCompany',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
