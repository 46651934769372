/**
 * 预约换单
 */
/** 预约状态 */
export enum BookingStatus {
  /** 未进场 */
  WAIT = 'BOOKING_STATUS_WAIT',
  /** 已进场 */
  ENTER = 'BOOKING_STATUS_ENTER',
  /** 已出场 */
  OUT = 'BOOKING_STATUS_OUT',
  /** 已完成 */
  FINISH = 'BOOKING_STATUS_FINISH',
  /** 已取消 */
  CANCEL = 'BOOKING_STATUS_CANCEL',
}
/** 预约状态 */
export const BookingStatusOptions = {
  [BookingStatus.WAIT]: '未进场',
  [BookingStatus.ENTER]: '已进场',
  [BookingStatus.OUT]: '已出场',
  [BookingStatus.FINISH]: '完成',
  [BookingStatus.CANCEL]: '取消',
};
/** 预约状态：状态颜色 */
export const BookingStatusColors = {
  [BookingStatus.WAIT]: '#FF8413',
  [BookingStatus.ENTER]: '#28A051',
  [BookingStatus.OUT]: '#28A051',
  [BookingStatus.FINISH]: '#28A051',
  [BookingStatus.CANCEL]: '#FF4747',
};
/** 预约状态：icon */
export const BookingStatusIcons = {
  [BookingStatus.WAIT]: 'easygo-icon-CheckUpdates',
  [BookingStatus.ENTER]: 'easygo-icon-banliejiedian-yidaozhan',
  [BookingStatus.OUT]: 'easygo-icon-banliejiedian-yijinzhan',
};
