import React from 'react';
import { Drawer } from 'antd';
import type { DrawerProps } from 'antd';
import styles from './index.module.less';

interface EasyDrawerProps extends DrawerProps {
  /** 属性配置 */
  property?: any;
}

const EasyDrawer: React.FC<EasyDrawerProps> = (props) => {
  const { width = 700, open = false, property = {}, placement = 'right', ...restProps } = props;

  return (
    <Drawer
      open={open}
      placement={placement}
      width={width}
      className={styles.easydDawer}
      bodyStyle={{ padding: '0 24px 24px' }}
      destroyOnClose
      {...property}
      {...restProps}
    />
  );
};

export default EasyDrawer;
