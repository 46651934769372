import { i18n } from '@easygo/i18n';
/** 海运履约状态 */
export enum PerformanceStatus {
  WAITING_ACCEPT = 'STATUS_WAITING_ACCEPT', // 待接单
  ALREADY_ACCEPT = 'STATUS_ALREADY_ACCEPT', // 已接单
  CONFIRM_STOWAGE = 'STATUS_CONFIRM_STOWAGE', // 确认已放舱 待退定金
  CONFIRM_BOARDING = 'STATUS_CONFIRM_BOARDING', // 确认已上船
  ORDER_CANCEL = 'STATUS_ORDER_CANCEL', // 已取消
  ORDER_COMPLETED = 'STATUS_ORDER_COMPLETED', // 已完成
  ORDER_CLOSE = 'STATUS_ORDER_CLOSE',
} // 已关闭
export const PerformanceStatusLabel = {
  [PerformanceStatus.WAITING_ACCEPT]: i18n?.t('待接单'),
  [PerformanceStatus.ALREADY_ACCEPT]: i18n?.t('已接单'),
  [PerformanceStatus.CONFIRM_STOWAGE]: i18n?.t('确认已放舱'),
  [PerformanceStatus.CONFIRM_BOARDING]: i18n?.t('确认已上船'),
  [PerformanceStatus.ORDER_CANCEL]: i18n?.t('已取消'),
  [PerformanceStatus.ORDER_COMPLETED]: i18n?.t('已完成'),
  [PerformanceStatus.ORDER_CLOSE]: i18n?.t('已关闭'),
};

/** 海运履约支付状态 */
export enum PerformancePayStatus {
  WAITING = 'PAY_STATUS_WAITING', // 待支付
  PROCESSING = 'PAY_STATUS_PROCESSING', // 处理中
  SUCCESS = 'PAY_STATUS_SUCCESS', // 支付成功
  FAIL = 'PAY_STATUS_FAIL', // 支付失败
  CLOSE = 'PAY_STATUS_CLOSE',
} // 支付关闭

/** 海运履约节点 */
export enum PerformanceNode {
  CREATE_ORDER = 'NODE_CREATE_ORDER', // 买家下单
  ACCEPT_ORDER = 'NODE_ACCEPT_ORDER', // 卖家接单
  REJECT_ORDER = 'NODE_REJECT_ORDER', // 卖家拒单
  CANCEL_ORDER = 'NODE_CANCEL_ORDER', // 买家取消订单
  COMPLETE_ORDER = 'NODE_COMPLETE_ORDER', // 完成订单
  CONFIRM_STOWAGE = 'NODE_CONFIRM_STOWAGE', // 确认已放舱
  CONFIRM_BOARDING = 'NODE_CONFIRM_BOARDING', // 确认上船
  CLOSE_ORDER = 'NODE_CLOSE_ORDER',
} // 关闭订单

/** 海运舱位类型 */
export enum CabinType {
  COC = 'CABIN_COC', // COC
  SOC = 'CABIN_SOC',
} // SOC
export const CabinTypeLabel = {
  [CabinType.COC]: 'COC',
  [CabinType.SOC]: 'SOC',
};

/** 海运保舱类型 */
export enum HoldCabinType {
  HOLDCABIN_YES = 'HOLDCABIN_YES', // 保舱
  HOLDCABIN_NO = 'HOLDCABIN_NO',
} // 非保舱
export const HoldCabinTypeLabel = {
  [HoldCabinType.HOLDCABIN_YES]: i18n?.t('保舱'),
  [HoldCabinType.HOLDCABIN_NO]: i18n?.t('非保舱'),
};

/** 价格展示类型 */
export enum PriceShowType {
  PRICE_FIXED = 'PRICE_FIXED', // 一口价
  PRICE_NEGOTIABLE = 'PRICE_NEGOTIABLE',
} // 可议价
export const PriceShowTypeLabel = {
  [PriceShowType.PRICE_FIXED]: i18n?.t('一口价'),
  [PriceShowType.PRICE_NEGOTIABLE]: i18n?.t('可议价'),
};

// 持箱人
export enum BoxHolder {
  // 船公司
  SHIP_COMPANY = 'BOX_HOLDER_SHIP_COMPANY',
  // 卖家
  SELLER = 'BOX_HOLDER_SELLER',
}

/** 海运下单-提单类型 */
export enum BillType {
  Bill_MBL = 'BILL_MBL',
  Bill_HBL = 'BILL_HBL',
}
export const BillTypeLabel = {
  [BillType.Bill_MBL]: 'MBL',
  [BillType.Bill_HBL]: 'HBL',
};

/** 海运下单-运输条款 */
export enum TransportMode {
  CYCY = 'TRANSPORT_CYCY',
  CYSD = 'TRANSPORT_CYSD',
}
export const TransportModeLabel = {
  [TransportMode.CYCY]: 'CY-CY',
  [TransportMode.CYSD]: 'CY-SD',
};

/** 海运下单-付款方式 */
export enum PayMode {
  PAY_ADVANCE = 'PAY_ADVANCE',
} // 预付款
export const PayModeLabel = {
  [PayMode.PAY_ADVANCE]: i18n?.t('预付款'),
};

/** 海运下单-放单方式 */
export enum ReleaseType {
  ORIGINAL = 'RELEASE_ORIGINAL', // 正本提单
  PHONE = 'RELEASE_PHONE', // 电放提单
  OTHER_PLACE = 'RELEASE_OTHER_PLACE',
} // 异地提单
export const ReleaseTypeLabel = {
  [ReleaseType.ORIGINAL]: i18n?.t('正本提单'),
  [ReleaseType.PHONE]: i18n?.t('电放提单'),
  [ReleaseType.OTHER_PLACE]: i18n?.t('异地提单'),
};

/** 匿名/非匿名展示价格 */
export enum AliasShowType {
  ALIAS_SHOW_YES = 'ALIAS_SHOW_YES', // 匿名展示
  ALIAS_SHOW_NO = 'ALIAS_SHOW_NO',
} // 非匿名展示

/** 库存状态 */
export enum InventoryStatus {
  ADEQUATE = 'INVENTORY_STATUS_ADEQUATE', // 库存充足
  TIGHT = 'INVENTORY_STATUS_TIGHT', // 库存紧张
  OUT = 'INVENTORY_STATUS_OUT',
} // 库存告罄
export const InventoryStatusLabel = {
  [InventoryStatus.ADEQUATE]: i18n?.t('库存充足'),
  [InventoryStatus.TIGHT]: i18n?.t('库存紧张'),
  [InventoryStatus.OUT]: i18n?.t('库存告罄'),
};

/** 附件类型 */
export enum AttachmentType {
  VOTING_TRUST = 'ATTACHMENT_TYPE_VOTING_TRUST', // 托书信息
  SO = 'ATTACHMENT_TYPE_SO', // SO
  // SUPPLEMENT = 'ATTACHMENT_TYPE_SUPPLEMENT', //补料
  VGM = 'ATTACHMENT_TYPE_VGM', //VGM
  SI = 'ATTACHMENT_TYPE_SI', //SI
  ISF = 'ATTACHMENT_TYPE_ISF', //ISF
  PICKUP_DRAFT = 'ATTACHMENT_TYPE_PICKUP_DRAFT', //提单确认件
  PICKUP = 'ATTACHMENT_TYPE_PICKUP', //提单
  OTHERS = 'ATTACHMENT_TYPE_OTHERS',
} //其他
export const AttachmentTypeLabel = {
  [AttachmentType.VOTING_TRUST]: i18n?.t('托书'),
  [AttachmentType.SO]: i18n?.t('舱位确认书'),
  [AttachmentType.VGM]: 'VGM', //VGM
  [AttachmentType.SI]: 'SI', //SI
  [AttachmentType.ISF]: 'ISF', //ISF
  [AttachmentType.PICKUP_DRAFT]: '提单确认件', //提单确认件
  [AttachmentType.PICKUP]: i18n?.t('提单'), //正式提单
  [AttachmentType.OTHERS]: i18n?.t('其他'), //其他
};

export const AttachmentTypesForBuyer: AttachmentType[] = [
  AttachmentType.VOTING_TRUST,
  AttachmentType.VGM,
  AttachmentType.SI,
  AttachmentType.ISF,
  AttachmentType.OTHERS,
];

export const AttachmentTypesForSeller: AttachmentType[] = [
  AttachmentType.VOTING_TRUST,
  AttachmentType.VGM,
  AttachmentType.SI,
  AttachmentType.ISF,
  AttachmentType.OTHERS,
];

/** 附加费类型（每票/每柜） */
export enum SurchargeType {
  EACH_BOX = 'SURCHARGE_TYPE_EACH_BOX', // 每柜
  EACH_TICKET = 'SURCHARGE_TYPE_EACH_TICKET',
} // 每票
/** 附加费类型（每票/每柜） */
export const SurchargeTypeTxt = {
  [SurchargeType.EACH_BOX]: '每柜', // 每柜
  [SurchargeType.EACH_TICKET]: '每票',
};

/** 启用/禁用 */
export enum EnableStatus {
  ENABLE = 'ENABLE_STATUS_ENABLE', // 启用
  FORBIDDEN = 'ENABLE_STATUS_FORBIDDEN',
} // 禁用
/** 启用/禁用 */
export const EnableStatusTxt = {
  [EnableStatus.ENABLE]: '正常', // 启用
  [EnableStatus.FORBIDDEN]: '已冻结',
};
/** 冻结/解冻 */
export const FreezeStatusTxt = {
  [EnableStatus.ENABLE]: '解冻', // 启用
  [EnableStatus.FORBIDDEN]: '冻结',
};

/** 商品状态 */
export enum ActiveStatus {
  ON = 'ACTIVE_STATUS_ON', //上架
  OFF = 'ACTIVE_STATUS_OFF', // 下架
  OVERDUE = 'ACTIVE_STATUS_OVERDUE',
} // 超期
export const ActiveStatusLable = {
  [ActiveStatus.ON]: i18n?.t('上架'),
  [ActiveStatus.OFF]: i18n?.t('下架'),
  [ActiveStatus.OVERDUE]: i18n?.t('超期'),
};

/** 推荐状态 */
export enum RecommendStatus {
  ON = 'RECOMMEND_STATUS_ON',
  OFF = 'RECOMMEND_STATUS_OFF',
}
/** 推荐状态 */
export const RecommendStatusTxt = {
  [RecommendStatus.ON]: '推荐',
  [RecommendStatus.OFF]: '取消推荐',
};

/**货币简称 */
export enum CurrencyAbbr {
  /** 人民币 */
  CURRENCY_TYPE_RMB = 'CURRENCY_TYPE_RMB',
  /** 美元 */
  CURRENCY_TYPE_DOLLAR = 'CURRENCY_TYPE_DOLLAR',
  /** 日元 */
  CURRENCY_TYPE_YEN = 'CURRENCY_TYPE_YEN',
  /** 欧元 */
  CURRENCY_TYPE_EURO = 'CURRENCY_TYPE_EURO',
}

export const CurrencyIcon = {
  [CurrencyAbbr.CURRENCY_TYPE_RMB]: '¥',
  [CurrencyAbbr.CURRENCY_TYPE_DOLLAR]: '$',
  [CurrencyAbbr.CURRENCY_TYPE_YEN]: 'Ұ',
  [CurrencyAbbr.CURRENCY_TYPE_EURO]: '€',
};

/** 海运履约单动作 */
export enum PerformanceAction {
  ACTION_ACCEPT_ORDER = 'ACTION_ACCEPT_ORDER', // 接单
  ACTION_REJECT_ORDER = 'ACTION_REJECT_ORDER', // 拒单
  ACTION_COMPLETE_ORDER = 'ACTION_COMPLETE_ORDER', // 完成订单
  CHANGE_PRICE = 'CHANGE_PRICE', // 改价
  EDIT_ORDER = 'EDIT_BILLING', // 修改订单
  RELEASE_ORDER = 'ACTION_CANCEL_ORDER', // 取消订单
  ACTION_CONFIRM_STOWAGE = 'ACTION_CONFIRM_STOWAGE', // 确认舱位
  ACTION_CONFIRM_BOARDING = 'ACTION_CONFIRM_BOARDING',
} // 确认上船

/** 售后状态 */
export enum AFTER_SALE_STATUS {
  AFTER_SALE_ORDER_SUBMIT_SUCCESS = 'AFTER_SALE_ORDER_SUBMIT_SUCCESS', // 售后服务单已提交成功，等待服务商审核
  AFTER_SALE_ORDER_UNAPPROVE = 'AFTER_SALE_ORDER_UNAPPROVE', // 审核未通过
  AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS = 'AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS', // 服务商修改退款金额，待客户确认
  AFTER_SALE_ORDER_APPROVE_PASS = 'AFTER_SALE_ORDER_APPROVE_PASS', // 审核通过，退款中
  AFTER_SALE_ORDER_REFUND_COUPON_SUCCESS = 'AFTER_SALE_ORDER_REFUND_COUPON_SUCCESS', // 售后单退劵成功
  AFTER_SALE_ORDER_REFUND_COUPON_FAIL = 'AFTER_SALE_ORDER_REFUND_COUPON_FAIL', // 售后单退劵失败
  AFTER_SALE_ORDER_REDUCE_SKU_NUM_SUCCESS = 'AFTER_SALE_ORDER_REDUCE_SKU_NUM_SUCCESS', // 售后单扣减商品数量成功
  AFTER_SALE_ORDER_REDUCE_SKU_NUM_FAIL = 'AFTER_SALE_ORDER_REDUCE_SKU_NUM_FAIL', // 售后单扣减商品数量失败
  AFTER_SALE_ORDER_TRADE_SUCCESS = 'AFTER_SALE_ORDER_TRADE_SUCCESS', // 售后单交易层退款请求成功
  AFTER_SALE_ORDER_REFUND_SUCCESS = 'AFTER_SALE_ORDER_REFUND_SUCCESS', // 已退款，到账待确认
  AFTER_SALE_ORDER_SUCCESS = 'AFTER_SALE_ORDER_SUCCESS', // 售后完成
  AFTER_SALE_ORDER_CANCELED = 'AFTER_SALE_ORDER_CANCELED', // 售后单已取消
  AFTER_SALE_ORDER_FAIL = 'AFTER_SALE_ORDER_FAIL', // 售后单处理失败
  AFTER_SALE_ORDER_APPROVING_BY_BUYER = 'AFTER_SALE_ORDER_APPROVING_BY_BUYER', // 待买家审核
  AFTER_SALE_ORDER_APPROVING_BY_PLATFORM = 'AFTER_SALE_ORDER_APPROVING_BY_PLATFORM', // 待平台审核
  AFTER_SALE_ORDER_UNPASS_BY_BUYER = 'AFTER_SALE_ORDER_UNPASS_BY_BUYER', // 买家审核拒绝
  AFTER_SALE_ORDER_UNPASS_BY_SELLER = 'AFTER_SALE_ORDER_UNPASS_BY_SELLER',
} // 卖家审核拒绝
// 对应海运订单详情页中的状态，2- 未通过,11- 已完成, 12-已取消（已过滤） 是完结态，其他都是处理中
/** 售后状态前端自定义使用的简单枚举 */
export enum AFTER_SALE_STATUS_SIMPLE {
  PASS = 'PASS',
  NOTPASS = 'NOTPASS',
  CANCELED = 'CANCELED',
  GOING = 'GOING',
}
/** 售后状态转换前端使用的简单枚举 */
export enum AFTER_SALE_STATUS_TRANS {
  AFTER_SALE_ORDER_SUBMIT_SUCCESS = AFTER_SALE_STATUS_SIMPLE['GOING'], // 售后服务单已提交成功，等待服务商审核
  AFTER_SALE_ORDER_UNAPPROVE = AFTER_SALE_STATUS_SIMPLE['NOTPASS'], // 审核未通过
  AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS = AFTER_SALE_STATUS_SIMPLE['GOING'], // 服务商修改退款金额，待客户确认
  AFTER_SALE_ORDER_APPROVE_PASS = AFTER_SALE_STATUS_SIMPLE['GOING'], // 审核通过，退款中
  AFTER_SALE_ORDER_REFUND_COUPON_SUCCESS = AFTER_SALE_STATUS_SIMPLE['GOING'], // 售后单退劵成功
  AFTER_SALE_ORDER_REFUND_COUPON_FAIL = AFTER_SALE_STATUS_SIMPLE['GOING'], // 售后单退劵失败
  AFTER_SALE_ORDER_REDUCE_SKU_NUM_SUCCESS = AFTER_SALE_STATUS_SIMPLE['GOING'], // 售后单扣减商品数量成功
  AFTER_SALE_ORDER_REDUCE_SKU_NUM_FAIL = AFTER_SALE_STATUS_SIMPLE['GOING'], // 售后单扣减商品数量失败
  AFTER_SALE_ORDER_TRADE_SUCCESS = AFTER_SALE_STATUS_SIMPLE['GOING'], // 售后单交易层退款请求成功
  AFTER_SALE_ORDER_REFUND_SUCCESS = AFTER_SALE_STATUS_SIMPLE['GOING'], // 已退款，到账待确认
  AFTER_SALE_ORDER_SUCCESS = AFTER_SALE_STATUS_SIMPLE['PASS'], // 售后完成
  AFTER_SALE_ORDER_CANCELED = AFTER_SALE_STATUS_SIMPLE['CANCELED'], // 售后单已取消
  AFTER_SALE_ORDER_FAIL = AFTER_SALE_STATUS_SIMPLE['GOING'], // 售后单处理失败
  AFTER_SALE_ORDER_APPROVING_BY_BUYER = AFTER_SALE_STATUS_SIMPLE['GOING'], // 待买家审核
  AFTER_SALE_ORDER_APPROVING_BY_PLATFORM = AFTER_SALE_STATUS_SIMPLE['GOING'], // 待平台审核
  AFTER_SALE_ORDER_UNPASS_BY_BUYER = AFTER_SALE_STATUS_SIMPLE['GOING'], // 买家审核拒绝
  AFTER_SALE_ORDER_UNPASS_BY_SELLER = AFTER_SALE_STATUS_SIMPLE['GOING'],
} // 卖家审核拒绝

/** 存储商品时的后端任务状态 */
export enum ProductTaskStatus {
  WAITING = 'TASK_STATUS_WAITING',
  RUNNING = 'TASK_STATUS_RUNNING',
  SUCCESS = 'TASK_STATUS_SUCCESS',
  FAIL = 'TASK_STATUS_FAIL',
}
/** 售后类型 */
export enum AfterSaleType {
  PEACE_CANCEL_CONTRACT = 'PEACE_CANCEL_CONTRACT', // 和平解约
  SELLER_CANCEL_CONTRACT = 'SELLER_CANCEL_CONTRACT', // 卖家违约
  BUYER_CANCEL_CONTRACT = 'BUYER_CANCEL_CONTRACT', // 买家违约
  PEACE_CANCEL_CONTRACT_BUYER = 'PEACE_CANCEL_CONTRACT_BUYER',
  PEACE_CANCEL_CONTRACT_SELLER = 'PEACE_CANCEL_CONTRACT_SELLER',
}
export const AfterSaleTypeLabel = {
  [AfterSaleType.PEACE_CANCEL_CONTRACT]: i18n?.t('和平解约'),
  [AfterSaleType.PEACE_CANCEL_CONTRACT_BUYER]: i18n?.t('和平解约'),
  [AfterSaleType.PEACE_CANCEL_CONTRACT_SELLER]: i18n?.t('和平解约'),
  [AfterSaleType.SELLER_CANCEL_CONTRACT]: i18n?.t('卖家违约赔付'),
  [AfterSaleType.BUYER_CANCEL_CONTRACT]: i18n?.t('买家违约赔付'),
};

/** 售后订单状态 */
export enum AfterSaleOrderStatus {
  AFTER_SALE_ORDER_SUBMIT_SUCCESS = 'AFTER_SALE_ORDER_SUBMIT_SUCCESS', // 售后服务单已提交成功，等待服务商审核
  AFTER_SALE_ORDER_UNAPPROVE = 'AFTER_SALE_ORDER_UNAPPROVE', // 审核未通过
  AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS = 'AFTER_SALE_ORDER_MODIFIED_PRICE_SUCCESS', // 服务商修改退款金额，待客户确认
  AFTER_SALE_ORDER_APPROVE_PASS = 'AFTER_SALE_ORDER_APPROVE_PASS', // 审核通过，退款中
  AFTER_SALE_ORDER_REFUND_COUPON_SUCCESS = 'AFTER_SALE_ORDER_REFUND_COUPON_SUCCESS', // 售后单退劵成功
  AFTER_SALE_ORDER_REFUND_COUPON_FAIL = 'AFTER_SALE_ORDER_REFUND_COUPON_FAIL', // 售后单退劵失败
  AFTER_SALE_ORDER_REDUCE_SKU_NUM_SUCCESS = 'AFTER_SALE_ORDER_REDUCE_SKU_NUM_SUCCESS', // 售后单扣减商品数量成功
  AFTER_SALE_ORDER_REDUCE_SKU_NUM_FAIL = 'AFTER_SALE_ORDER_REDUCE_SKU_NUM_FAIL', // 售后单扣减商品数量失败
  AFTER_SALE_ORDER_TRADE_SUCCESS = 'AFTER_SALE_ORDER_TRADE_SUCCESS', // 售后单交易层退款请求成功
  AFTER_SALE_ORDER_REFUND_SUCCESS = 'AFTER_SALE_ORDER_REFUND_SUCCESS', // 已退款，到账待确认
  AFTER_SALE_ORDER_SUCCESS = 'AFTER_SALE_ORDER_SUCCESS', // 售后完成
  AFTER_SALE_ORDER_CANCELED = 'AFTER_SALE_ORDER_CANCELED', // 售后单已取消
  AFTER_SALE_ORDER_FAIL = 'AFTER_SALE_ORDER_FAIL', // 售后单处理失败
  AFTER_SALE_ORDER_APPROVING_BY_BUYER = 'AFTER_SALE_ORDER_APPROVING_BY_BUYER', // 待买家审核
  AFTER_SALE_ORDER_APPROVING_BY_PLATFORM = 'AFTER_SALE_ORDER_APPROVING_BY_PLATFORM', // 待平台审核
  AFTER_SALE_ORDER_UNPASS_BY_BUYER = 'AFTER_SALE_ORDER_UNPASS_BY_BUYER', // 买家审核拒绝
  AFTER_SALE_ORDER_UNPASS_BY_SELLER = 'AFTER_SALE_ORDER_UNPASS_BY_SELLER',
} // 卖家审核拒绝

/** 售后类型 */
export enum AFTER_SALE_TYPE {
  AFTER_SALE_TYPE_REFUND_PRODUCT_AND_AMOUNT = 'AFTER_SALE_TYPE_REFUND_PRODUCT_AND_AMOUNT', // 退商品&退款
  AFTER_SALE_TYPE_REFUND_AMOUNT = 'AFTER_SALE_TYPE_REFUND_AMOUNT', // 仅退款
  PEACE_CANCEL_CONTRACT = 'PEACE_CANCEL_CONTRACT', // 和平解约
  PEACE_CANCEL_CONTRACT_BUYER = 'PEACE_CANCEL_CONTRACT_BUYER', // 买家和平解约
  PEACE_CANCEL_CONTRACT_SELLER = 'PEACE_CANCEL_CONTRACT_SELLER', // 卖家和平解约
  SELLER_CANCEL_CONTRACT = 'SELLER_CANCEL_CONTRACT', // 卖家违约
  BUYER_CANCEL_CONTRACT = 'BUYER_CANCEL_CONTRACT',
} // 买家违约

/** 申请赔付售后类型 */
export const AfterSaleTypeApply = [AFTER_SALE_TYPE.SELLER_CANCEL_CONTRACT, AFTER_SALE_TYPE.BUYER_CANCEL_CONTRACT];
/** 和平解约售后类型 */
export const AfterSaleTypePeace = [
  AFTER_SALE_TYPE.PEACE_CANCEL_CONTRACT,
  AFTER_SALE_TYPE.PEACE_CANCEL_CONTRACT_BUYER,
  AFTER_SALE_TYPE.PEACE_CANCEL_CONTRACT_SELLER,
];

/* 卖家买家修改数量的审核状态 */
export enum UPDATE_COUNT_STATUS {
  UPDATE_COUNT_STATUS_ING = 'UPDATE_COUNT_STATUS_ING',
  UPDATE_COUNT_STATUS_YES = 'UPDATE_COUNT_STATUS_YES',
  UPDATE_COUNT_STATUS_NO = 'UPDATE_COUNT_STATUS_NO',
}
/* 卖家买家修改数量的审核状态 */
export enum UPDATE_COUNT_LABEL {
  UPDATE_COUNT_STATUS_ING = i18n?.t('审核中'),
  UPDATE_COUNT_STATUS_YES = i18n?.t('修改成功'),
  UPDATE_COUNT_STATUS_NO = i18n?.t('修改失败'),
}
/* 卖家买家修改数量时候人员类型 */
export enum OPERATOR_TYPE {
  OPERATOR_TYPE_SELLER = 'OPERATOR_TYPE_SELLER',
  OPERATOR_TYPE_BUYER = 'OPERATOR_TYPE_BUYER',
  OPERATOR_TYPE_ADMIN = 'OPERATOR_TYPE_ADMIN',
}
/* 卖家买家修改数量时候人员类型 */
export const OPERATOR_LABEL = {
  [OPERATOR_TYPE.OPERATOR_TYPE_BUYER]: i18n?.t('买家'),
  [OPERATOR_TYPE.OPERATOR_TYPE_SELLER]: i18n?.t('卖家'),
};
export const AfterSaleOrderStatusLabel = {
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_SUBMIT_SUCCESS]: i18n?.t('待卖家确认'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_APPROVING_BY_BUYER]: i18n?.t('待买家确认'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_UNPASS_BY_BUYER]: i18n?.t('买家拒绝'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_UNPASS_BY_SELLER]: i18n?.t('卖家拒绝'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_APPROVING_BY_PLATFORM]: i18n?.t('待平台审核'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_APPROVE_PASS]: i18n?.t('退款中'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_SUCCESS]: i18n?.t('退款完成'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_UNAPPROVE]: i18n?.t('审核未通过'),
  [AfterSaleOrderStatus.AFTER_SALE_ORDER_CANCELED]: i18n?.t('已取消'),
};

export enum AfterSaleUserType {
  USER_TYPE_BUYER = 'USER_TYPE_BUYER',
  USER_TYPE_SELLER = 'USER_TYPE_SELLER',
}

/** 佣金收取类型 */
export enum CommissionReceiveType {
  /** 未知 */
  COMMISSION_RECEIVE_TYPE_UNKNOWN = 'COMMISSION_RECEIVE_TYPE_UNKNOWN', // 未知
  /** 线上收取 */
  COMMISSION_RECEIVE_TYPE_ONLINE = 'COMMISSION_RECEIVE_TYPE_ONLINE', // 线上收取
  /** 线下收取 */
  COMMISSION_RECEIVE_TYPE_OFFLINE = 'COMMISSION_RECEIVE_TYPE_OFFLINE',
} // 线下收取
/**货币简称 */
export enum AfterSaleCurrencyAbbr {
  /** 人民币 */
  CURRENCY_TYPE_RMB = 'SKU_COIN_TYPE_RMB',
  /** 美元 */
  CURRENCY_TYPE_DOLLAR = 'SKU_COIN_TYPE_DOLLAR',
  /** 日元 */
  CURRENCY_TYPE_YEN = 'SKU_COIN_TYPE_YEN',
  /** 欧元 */
  CURRENCY_TYPE_EURO = 'SKU_COIN_TYPE_EURO',
}
export const AfterSaleCurrencyIcon = {
  [AfterSaleCurrencyAbbr.CURRENCY_TYPE_RMB]: '¥',
  [AfterSaleCurrencyAbbr.CURRENCY_TYPE_DOLLAR]: '$',
  [AfterSaleCurrencyAbbr.CURRENCY_TYPE_YEN]: 'Ұ',
  [AfterSaleCurrencyAbbr.CURRENCY_TYPE_EURO]: '€',
};

/**海运履约主单状态 */
export enum OceanPerfOrderStatusEnum {
  /** 待确认 */
  ORDER_STATUS_WAITING_CONFIRM = 'ORDER_STATUS_WAITING_CONFIRM',
  /** 待接单 */
  ORDER_STATUS_WAITING_ACCEPT = 'ORDER_STATUS_WAITING_ACCEPT',
  /** 已接单 */
  ORDER_STATUS_ALREADY_ACCEPT = 'ORDER_STATUS_ALREADY_ACCEPT',
  /** 已订舱 */
  ORDER_STATUS_ALREADY_BOOKING = 'ORDER_STATUS_ALREADY_BOOKING',
  /** 已进港 */
  ORDER_STATUS_ENTER_PORT = 'ORDER_STATUS_ENTER_PORT',
  /** 码头放行 */
  ORDER_STATUS_TERMINAL_RELEASE = 'ORDER_STATUS_TERMINAL_RELEASE',
  /** 提单确认件核对 */
  ORDER_STATUS_UPLOAD_PICKUP_DRAFT = 'ORDER_STATUS_UPLOAD_PICKUP_DRAFT',
  /** 已开航 */
  ORDER_STATUS_ALREADY_SAIL = 'ORDER_STATUS_ALREADY_SAIL',
  /** 签发提单 */
  ORDER_STATUS_UPLOAD_PICKUP = 'ORDER_STATUS_UPLOAD_PICKUP',
  /** 已抵港 */
  ORDER_STATUS_ARRIVAL_PORT = 'ORDER_STATUS_ARRIVAL_PORT',
  /** 已清关 */
  ORDER_STATUS_ALREADY_CLEARANCE = 'ORDER_STATUS_ALREADY_CLEARANCE',
  /** 已送达 */
  ORDER_STATUS_ALREADY_SERVE = 'ORDER_STATUS_ALREADY_SERVE',
  /** 待支付 */
  ORDER_STATUS_WAIT_PAY = 'ORDER_STATUS_WAIT_PAY',
  /** 已关闭 */
  ORDER_STATUS_ORDER_CLOSE = 'ORDER_STATUS_ORDER_CLOSE',
  /** 已取消 */
  ORDER_STATUS_ORDER_CANCEL = 'ORDER_STATUS_ORDER_CANCEL',
  /** 已完成 */
  ORDER_STATUS_ORDER_COMPLETED = 'ORDER_STATUS_ORDER_COMPLETED',
}
export const OceanPerfOrderStatus = {
  [OceanPerfOrderStatusEnum.ORDER_STATUS_WAITING_CONFIRM]: '待确认',
  [OceanPerfOrderStatusEnum.ORDER_STATUS_WAITING_ACCEPT]: i18n?.t('待接单'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ALREADY_ACCEPT]: i18n?.t('已接单'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ALREADY_BOOKING]: i18n?.t('已放舱'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ENTER_PORT]: i18n?.t('已进港'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_TERMINAL_RELEASE]: i18n?.t('码头放行'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_UPLOAD_PICKUP_DRAFT]: i18n?.t('提单确认件核对'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ALREADY_SAIL]: i18n?.t('已开航'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_UPLOAD_PICKUP]: i18n?.t('签发提单'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ARRIVAL_PORT]: i18n?.t('已抵港'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ALREADY_CLEARANCE]: i18n?.t('已清关'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ALREADY_SERVE]: i18n?.t('已送达'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_WAIT_PAY]: i18n?.t('待支付'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ORDER_CLOSE]: i18n?.t('已关闭'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ORDER_CANCEL]: i18n?.t('已取消'),
  [OceanPerfOrderStatusEnum.ORDER_STATUS_ORDER_COMPLETED]: i18n?.t('已完成'),
};

export enum OceanShipStatusEnum {
  ALREADY_STOWAGE = 'ALREADY_STOWAGE',
  ALREADY_EMPTY = 'ALREADY_EMPTY',
  ALREADY_PACKING = 'ALREADY_PACKING',
  ALREADY_ENTERING_PORT = 'ALREADY_ENTERING_PORT',
  ALREADY_SAILING = 'ALREADY_SAILING',
  ALREADY_ARRIVAL = 'ALREADY_ARRIVAL',
  HEAVY_LEFT = 'DELIVERY_STATUS_HEAVY_LEFT',
  ALREADY_SERVE = 'DELIVERY_STATUS_ALREADY_SERVE',
}

export const OceanShipStatusOnline = {
  [OceanShipStatusEnum.ALREADY_STOWAGE]: i18n?.t('已发放订舱确认书'),
  [OceanShipStatusEnum.ALREADY_EMPTY]: i18n?.t('拖车正在前往提货地'),
  [OceanShipStatusEnum.ALREADY_PACKING]: i18n?.t('正在前往起运港运输中'),
  [OceanShipStatusEnum.ALREADY_ENTERING_PORT]: i18n?.t('已到达起运港'),
  [OceanShipStatusEnum.ALREADY_SAILING]: i18n?.t('正在前往目的港运输中'),
  [OceanShipStatusEnum.ALREADY_ARRIVAL]: i18n?.t('已到达目的港'),
  [OceanShipStatusEnum.HEAVY_LEFT]: i18n?.t('正在前往目的地运输中'),
  [OceanShipStatusEnum.ALREADY_SERVE]: i18n?.t('货物已送达'),
};

export enum OrderType {
  ORDER_TYPE_SEA_FCL = 'ORDER_TYPE_SEA_FCL',
  ORDER_TYPE_SEA_HEAD = 'ORDER_TYPE_SEA_HEAD',
}
export const OrderTypeLabel = {
  [OrderType.ORDER_TYPE_SEA_FCL]: i18n?.t('海运整箱'),
  [OrderType.ORDER_TYPE_SEA_HEAD]: i18n?.t('跨境电商头程'),
};

//海运履约状态
export enum PerformanceOrderSeaStatus {
  SEA_STATUS_UNKNOWN = 'SEA_STATUS_UNKNOWN', //未知
  SEA_STATUS_WAITING_ACCEPT = 'SEA_STATUS_WAITING_ACCEPT', //待接单
  SEA_STATUS_ALREADY_ACCEPT = 'SEA_STATUS_ALREADY_ACCEPT', //已接单
  SEA_STATUS_ALREADY_BOOKING = 'SEA_STATUS_ALREADY_BOOKING', //已订舱
  SEA_STATUS_ALREADY_SUPPLEMENT = 'SEA_STATUS_ALREADY_SUPPLEMENT', //已补料
  SEA_STATUS_ALREADY_SAIL = 'SEA_STATUS_ALREADY_SAIL', //已开航
  SEA_STATUS_UPLOAD_PICKUP_DRAFT = 'SEA_STATUS_UPLOAD_PICKUP_DRAFT', //提单确认件核对
  SEA_STATUS_ARRIVAL_PORT = 'SEA_STATUS_ARRIVAL_PORT', //已抵港
  SEA_STATUS_UPLOAD_PICKUP = 'SEA_STATUS_UPLOAD_PICKUP', //签发提单
  SEA_STATUS_BILL_WAIT_CONFIRM = 'SEA_STATUS_BILL_WAIT_CONFIRM', //账单待确认
  SEA_STATUS_PAID = 'SEA_STATUS_PAID', //已支付
  SEA_STATUS_ORDER_CLOSE = 'SEA_STATUS_ORDER_CLOSE', //已关闭
  SEA_STATUS_ORDER_CANCEL = 'SEA_STATUS_ORDER_CANCEL',
} //已取消

//拖车履约状态
export enum PerformanceOrderTrailerStatus {
  TRAILER_STATUS_UNKNOWN = 'TRAILER_STATUS_UNKNOWN', //未知
  TRAILER_STATUS_WAITING_ACCEPT = 'TRAILER_STATUS_WAITING_ACCEPT', //待接单
  TRAILER_STATUS_ALREADY_ACCEPT = 'TRAILER_STATUS_ALREADY_ACCEPT', //已接单
  TRAILER_STATUS_ALREADY_ALLOCATION = 'TRAILER_STATUS_ALREADY_ALLOCATION', //已分配
  TRAILER_STATUS_ALREADY_PICKUP_EMPTY = 'TRAILER_STATUS_ALREADY_PICKUP_EMPTY', //已提空箱
  TRAILER_STATUS_ALREADY_PACKING = 'TRAILER_STATUS_ALREADY_PACKING', //已装箱
  TRAILER_STATUS_ENTER_PORT = 'TRAILER_STATUS_ENTER_PORT', //已进港
  TRAILER_STATUS_ORDER_CLOSE = 'TRAILER_STATUS_ORDER_CLOSE', //已关闭
  TRAILER_STATUS_ORDER_CANCEL = 'TRAILER_STATUS_ORDER_CANCEL',
} //已取消

//拖车行为枚举
export enum PerformanceTrailerActionType {
  TRAILER_ACTION_TYPE_UNKNOWN = 'TRAILER_ACTION_TYPE_UNKNOWN', //未知
  TRAILER_ACTION_TYPE_ALLOCATION = 'TRAILER_ACTION_TYPE_ALLOCATION', //分配
  TRAILER_ACTION_TYPE_PICKUP_EMPTY = 'TRAILER_ACTION_TYPE_PICKUP_EMPTY', //提空箱
  TRAILER_ACTION_TYPE_PACKING = 'TRAILER_ACTION_TYPE_PACKING', //装箱
  TRAILER_ACTION_TYPE_ENTER_PORT = 'TRAILER_ACTION_TYPE_ENTER_PORT',
} //进港

//行为更新类型
export enum PerformanceActionUpdateType {
  ACTION_UPDATE_TYPE_UNKNOWN = 'ACTION_UPDATE_TYPE_UNKNOWN', //未知
  ACTION_UPDATE_TYPE_MANUAL = 'ACTION_UPDATE_TYPE_MANUAL', //手动
  ACTION_UPDATE_TYPE_AUTO = 'ACTION_UPDATE_TYPE_AUTO',
} //自动
//海运履约行为枚举
export enum PerformanceSeaActionType {
  ACTION_TYPE_UNKNOWN = 'ACTION_TYPE_UNKNOWN', //未知
  ACTION_TYPE_CREATE = 'ACTION_TYPE_CREATE', //下单
  ACTION_TYPE_PLACE_CREATE = 'ACTION_TYPE_PLACE_CREATE', //代客下单
  ACTION_TYPE_CANCEL = 'ACTION_TYPE_CANCEL', //取消订单
  ACTION_TYPE_REJECT = 'ACTION_TYPE_REJECT', //拒单
  ACTION_TYPE_FORCE_CLOSE = 'ACTION_TYPE_FORCE_CLOSE', //强制关闭订单
  ACTION_TYPE_ACCEPT = 'ACTION_TYPE_ACCEPT', //接单
  ACTION_TYPE_CONFIRM_BOOKING = 'ACTION_TYPE_CONFIRM_BOOKING', //确认订舱
  ACTION_TYPE_SUPPLEMENT = 'ACTION_TYPE_SUPPLEMENT', //补料
  ACTION_TYPE_SAIL = 'ACTION_TYPE_SAIL', //开航
  ACTION_TYPE_UPLOAD_PICKUP_DRAFT = 'ACTION_TYPE_UPLOAD_PICKUP_DRAFT', //上传提单确认件
  ACTION_TYPE_ARRIVAL_PORT = 'ACTION_TYPE_ARRIVAL_PORT', //抵港
  ACTION_TYPE_UPLOAD_PICKUP = 'ACTION_TYPE_UPLOAD_PICKUP', //上传提单
  ACTION_TYPE_CONFIRM_BILL = 'ACTION_TYPE_CONFIRM_BILL', //确认账单
  ACTION_TYPE_PAY = 'ACTION_TYPE_PAY', //支付
  ACTION_TYPE_COMPLETE = 'ACTION_TYPE_COMPLETE',
} //完成履约

export enum OceanExceptionType {
  TRADING = 'TRADING',
  CONVENTIONS = 'CONVENTIONS',
  ACTIVITY_SALE = 'ACTIVITY_SALE',
  AFTER_SALE_NOTICE = 'AFTER_SALE_NOTICE',
  FINANCE_CHANGE = 'FINANCE_CHANGE',
  OFFICIAL_NOTICE = 'OFFICIAL_NOTICE',
  YICHANG_NOTICE = 'YICHANG_NOTICE',
}

export const OceanExceptionTypeLabel = {
  [OceanExceptionType.TRADING]: i18n?.t('交易提醒'),
  [OceanExceptionType.CONVENTIONS]: i18n?.t('订单履约'),
  [OceanExceptionType.ACTIVITY_SALE]: i18n?.t('活动优惠'),
  [OceanExceptionType.AFTER_SALE_NOTICE]: i18n?.t('售后通知'),
  [OceanExceptionType.FINANCE_CHANGE]: i18n?.t('资金变动'),
  [OceanExceptionType.OFFICIAL_NOTICE]: i18n?.t('官方通知'),
  [OceanExceptionType.YICHANG_NOTICE]: i18n?.t('易畅公告'),
};
// 报关单类型
export enum PerformanceDeclarationOrderType {
  DECLARATION_ORDER_TYPE_DECLARATION = 'DECLARATION_ORDER_TYPE_DECLARATION', //报关
  DECLARATION_ORDER_TYPE_CLEARANCE = 'DECLARATION_ORDER_TYPE_CLEARANCE',
} //清关

//报关履约状态
export enum PerformanceOrderDeclarationStatus {
  DECLARATION_STATUS_WAITING_ACCEPT = 'DECLARATION_STATUS_WAITING_ACCEPT', //待接单
  DECLARATION_STATUS_ALREADY_ACCEPT = 'DECLARATION_STATUS_ALREADY_ACCEPT', //已接单
  DECLARATION_STATUS_ALREADY_APPLY = 'DECLARATION_STATUS_ALREADY_APPLY', //已申请报关
  DECLARATION_STATUS_CUSTOMS_RELEASE = 'DECLARATION_STATUS_CUSTOMS_RELEASE', //海关放行
  DECLARATION_STATUS_TERMINAL_RELEASE = 'DECLARATION_STATUS_TERMINAL_RELEASE', //码头放行
  DECLARATION_STATUS_ORDER_CLOSE = 'DECLARATION_STATUS_ORDER_CLOSE', //已关闭
  DECLARATION_STATUS_ORDER_CANCEL = 'DECLARATION_STATUS_ORDER_CANCEL',
} //已取消

//清关履约状态
export enum PerformanceOrderClearanceStatus {
  CLEARANCE_STATUS_WAITING_ACCEPT = 'CLEARANCE_STATUS_WAITING_ACCEPT', //待接单
  CLEARANCE_STATUS_ALREADY_ACCEPT = 'CLEARANCE_STATUS_ALREADY_ACCEPT', //已接单
  CLEARANCE_STATUS_ALREADY_APPLY = 'CLEARANCE_STATUS_ALREADY_APPLY', //已申请清关
  CLEARANCE_STATUS_ALREADY_CLEARANCE = 'CLEARANCE_STATUS_ALREADY_CLEARANCE', //已清关
  CLEARANCE_STATUS_ORDER_CLOSE = 'CLEARANCE_STATUS_ORDER_CLOSE', //已关闭
  CLEARANCE_STATUS_ORDER_CANCEL = 'CLEARANCE_STATUS_ORDER_CANCEL',
} //已取消

//拖车单类型
export enum PerformanceTrailerOrderType {
  TRAILER_ORDER_TYPE_TRAILER = 'TRAILER_ORDER_TYPE_TRAILER', //拖车
  TRAILER_ORDER_TYPE_DELIVERY = 'TRAILER_ORDER_TYPE_DELIVERY',
} //送仓

//送仓履约状态
export enum PerformanceOrderDeliveryStatus {
  DELIVERY_STATUS_WAITING_ACCEPT = 'DELIVERY_STATUS_WAITING_ACCEPT', //待接单
  DELIVERY_STATUS_ALREADY_ACCEPT = 'DELIVERY_STATUS_ALREADY_ACCEPT', //已接单
  DELIVERY_STATUS_ALREADY_ALLOCATION = 'DELIVERY_STATUS_ALREADY_ALLOCATION', //已分配
  DELIVERY_STATUS_HEAVY_LEFT = 'DELIVERY_STATUS_HEAVY_LEFT', //重箱离港
  DELIVERY_STATUS_ALREADY_SERVE = 'DELIVERY_STATUS_ALREADY_SERVE', //已送达
  DELIVERY_STATUS_RETURN_EMPTY = 'DELIVERY_STATUS_RETURN_EMPTY', //已还空箱
  DELIVERY_STATUS_ORDER_CLOSE = 'DELIVERY_STATUS_ORDER_CLOSE', //已关闭
  DELIVERY_STATUS_ORDER_CANCEL = 'DELIVERY_STATUS_ORDER_CANCEL',
} //已取消

export enum SearchOrderStatus {
  ALL = 'ALL', //全部
  WAIT_ACCEPT = 'WAIT_ACCEPT', //待接单
  WAIT_CABIN = 'WAIT_CABIN', //待放舱
  WAIT_DECLARATION = 'WAIT_DECLARATION', //待报关
  WAIT_ENTERPORT = 'WAIT_ENTERPORT', //待进港
  WAIT_SAILING = 'WAIT_SAILING', //待开航
  WAIT_ARRIVEPORT = 'WAIT_ARRIVEPORT', //待抵港
  WAIT_CLEARANCE = 'WAIT_CLEARANCE', //待清关
  WAIT_DELIVER = 'WAIT_DELIVER', //待送达
  WAIT_MAKEBILL = 'WAIT_MAKEBILL', //待制作账单
  EXCEPTION = 'EXCEPTION', //异常
  ALREADY_BOOK = 'ALREADY_BOOK', // 已订舱待提货
  ALREADY_ENTER_PORT = 'ALREADY_ENTER_PORT', // 已到达港口
  ALREADY_SAIL = 'ALREADY_SAIL', // 已开航
  ALREADY_ARRIVE_PORT = 'ALREADY_ARRIVE_PORT', // 已抵港
  ALREADY_HEAVY_LEFT = 'ALREADY_HEAVY_LEFT', // 重箱已离港
  ALREADY_DELIVERED = 'ALREADY_DELIVERED',
} // 货物已送达

export enum CargoStatus {
  /** 待接单 */
  WAIT_ACCEPT = 'WAIT_ACCEPT',
  /** 已接单 */
  ACCEPT = 'ACCEPT',
  /** 已确认舱位 */
  COMFIRM_SPACE = 'COMFIRM_SPACE',
  /** 已提空箱 */
  ALREADY_PICK_EMPTY = 'ALREADY_PICK_EMPTY',
  /** 已装货 */
  ALREADY_PACKING = 'ALREADY_PACKING',
  /** 重柜进港 */
  ALREADY_ENTER_PORT = 'ALREADY_ENTER_PORT',
  /** 已开航 */
  ALREADY_SAIL = 'ALREADY_SAIL',
  /** 已抵港 */
  ALREADY_ARRIVE_PORT = 'ALREADY_ARRIVE_PORT',
  /** 重箱已离港 */
  ALREADY_HEAVY_LEFT = 'ALREADY_HEAVY_LEFT',
  /** 货物已送达 */
  ALREADY_DELIVERED = 'ALREADY_DELIVERED',
}

export const CargoStatusLabel: Record<CargoStatus, string> = {
  [CargoStatus.WAIT_ACCEPT]: i18n?.t('等待卖家接单'),
  [CargoStatus.ACCEPT]: i18n?.t('等待放舱'),
  [CargoStatus.COMFIRM_SPACE]: i18n?.t('已放舱等待装货'),
  [CargoStatus.ALREADY_PICK_EMPTY]: i18n?.t('车辆正在赶往装货地'),
  [CargoStatus.ALREADY_PACKING]: i18n?.t('正在运往起运港'),
  [CargoStatus.ALREADY_ENTER_PORT]: i18n?.t('已到达起运港'),
  [CargoStatus.ALREADY_SAIL]: i18n?.t('正在运往目的港'),
  [CargoStatus.ALREADY_ARRIVE_PORT]: i18n?.t('已到达目的港'),
  [CargoStatus.ALREADY_HEAVY_LEFT]: i18n?.t('目的地配送中'),
  [CargoStatus.ALREADY_DELIVERED]: i18n?.t('已送达'),
};

export const CargoStatusPercent: Record<CargoStatus, number> = {
  [CargoStatus.WAIT_ACCEPT]: 0,
  [CargoStatus.ACCEPT]: 10,
  [CargoStatus.COMFIRM_SPACE]: 10,
  [CargoStatus.ALREADY_PICK_EMPTY]: 10,
  [CargoStatus.ALREADY_PACKING]: 20,
  [CargoStatus.ALREADY_ENTER_PORT]: 30,
  [CargoStatus.ALREADY_SAIL]: 50,
  [CargoStatus.ALREADY_ARRIVE_PORT]: 60,
  [CargoStatus.ALREADY_HEAVY_LEFT]: 80,
  [CargoStatus.ALREADY_DELIVERED]: 100,
};

export const CargoStatusLabelForMap: Record<CargoStatus, string> = {
  [CargoStatus.WAIT_ACCEPT]: i18n?.t('待接单'),
  [CargoStatus.ACCEPT]: i18n?.t('已接单'),
  [CargoStatus.COMFIRM_SPACE]: i18n?.t('已确认舱位'),
  [CargoStatus.ALREADY_PICK_EMPTY]: i18n?.t('已提空箱'),
  [CargoStatus.ALREADY_PACKING]: i18n?.t('装货完成'),
  [CargoStatus.ALREADY_ENTER_PORT]: i18n?.t('重柜已进港'),
  [CargoStatus.ALREADY_SAIL]: i18n?.t('船舶已开航'),
  [CargoStatus.ALREADY_ARRIVE_PORT]: i18n?.t('已到达目的港'),
  [CargoStatus.ALREADY_HEAVY_LEFT]: i18n?.t('已提重柜'),
  [CargoStatus.ALREADY_DELIVERED]: i18n?.t('送达仓库'),
};

export const enum ShowRealShipCompany {
  SHOW = 'ENABLE_STATUS_ENABLE',
  NO_SHOW = 'ENABLE_STATUS_FORBIDDEN',
}

export const ShowRealShipCompanyLabel: Record<ShowRealShipCompany, string> = {
  [ShowRealShipCompany.SHOW]: '展示',
  [ShowRealShipCompany.NO_SHOW]: '隐藏',
};

export const enum LadingBillType {
  MBL = 'BILL_MBL',
  HBL = 'BILL_HBL',
}

export const LadingBillTypeLabel: Record<LadingBillType, string> = {
  [LadingBillType.MBL]: 'MBL',
  [LadingBillType.HBL]: 'HBL',
};

/** 海运经营范围 */
export enum WhiteType {
  /** 售卖所有船公司+所有线路 */
  WHITE_TYPE_ALL = 'WHITE_TYPE_ALL',
  /** 售卖部分船公司线路 */
  WHITE_TYPE_PART = 'WHITE_TYPE_PART',
}
export const WhiteTypeOptions = {
  [WhiteType.WHITE_TYPE_ALL]: i18n?.t('售卖所有船公司+所有线路'),
  [WhiteType.WHITE_TYPE_PART]: i18n?.t('售卖部分船公司线路'),
};
/** 付款状态 */
export enum Settlement_Pay_Status {
  STATEMENT_PAY_STATUS_WAITING = 'STATEMENT_PAY_STATUS_WAITING',
  STATEMENT_PAY_STATUS_SUCCESS = 'STATEMENT_PAY_STATUS_SUCCESS',
  STATEMENT_PAY_STATUS_PARTIAL = 'STATEMENT_PAY_STATUS_PARTIAL',
}
export const Settlement_Pay_Status_Label = {
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_WAITING]: '待支付',
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_SUCCESS]: '支付完成',
  [Settlement_Pay_Status.STATEMENT_PAY_STATUS_PARTIAL]: '部分支付',
};

/** 海运账单支付单状态 */
export enum Bill_Payment_Status {
  PAYMENT_DETAIL_STATUS_CREATE = 'PAYMENT_DETAIL_STATUS_CREATE',
  PAYMENT_DETAIL_STATUS_CANCEL = 'PAYMENT_DETAIL_STATUS_CANCEL',
  PAYMENT_DETAIL_STATUS_WAITING = 'PAYMENT_DETAIL_STATUS_WAITING',
  PAYMENT_DETAIL_STATUS_FINISH = 'PAYMENT_DETAIL_STATUS_FINISH',
  PAYMENT_DETAIL_STATUS_CLOSE = 'PAYMENT_DETAIL_STATUS_CLOSE',
  PAYMENT_DETAIL_STATUS_PROCESSING = 'PAYMENT_DETAIL_STATUS_PROCESSING',
  PAYMENT_DETAIL_STATUS_FAIL = 'PAYMENT_DETAIL_STATUS_FAIL',
}
export const Bill_Payment_Status_Label = {
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CREATE]: '已创建',
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CANCEL]: '已取消',
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_WAITING]: '待卖家确认',
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_FINISH]: '已完成',
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CLOSE]: '已关闭',
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_PROCESSING]: '处理中',
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_FAIL]: '支付失败',
};
export const Bill_Payment_Status_Config = {
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CREATE]: {
    label: Bill_Payment_Status_Label[Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CREATE],
    color: '#28A051',
  },
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CANCEL]: {
    label: Bill_Payment_Status_Label[Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CANCEL],
    color: '#FF8413',
  },
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_WAITING]: {
    label: Bill_Payment_Status_Label[Bill_Payment_Status.PAYMENT_DETAIL_STATUS_WAITING],
    color: '#FF8413',
  },
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_FINISH]: {
    label: Bill_Payment_Status_Label[Bill_Payment_Status.PAYMENT_DETAIL_STATUS_FINISH],
    color: '#28A051',
  },
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CLOSE]: {
    label: Bill_Payment_Status_Label[Bill_Payment_Status.PAYMENT_DETAIL_STATUS_CLOSE],
    color: '#FF8413',
  },
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_PROCESSING]: {
    label: Bill_Payment_Status_Label[Bill_Payment_Status.PAYMENT_DETAIL_STATUS_PROCESSING],
    color: '#FF8413',
  },
  [Bill_Payment_Status.PAYMENT_DETAIL_STATUS_FAIL]: {
    label: Bill_Payment_Status_Label[Bill_Payment_Status.PAYMENT_DETAIL_STATUS_FAIL],
    color: '#FF8413',
  },
};

// 海运上传提单提单类型枚举
export enum PICKUP_TYPE {
  ATTACHMENT_PICKUP_TYPE_ORIGINAL_BILL_OF_LADING = 'ATTACHMENT_PICKUP_TYPE_ORIGINAL_BILL_OF_LADING',
  ATTACHMENT_PICKUP_TYPE_TELEX_RELEASE = 'ATTACHMENT_PICKUP_TYPE_TELEX_RELEASE',
}
// 海运上传提单提单类型
export const pickupTypeOptions = {
  [PICKUP_TYPE.ATTACHMENT_PICKUP_TYPE_ORIGINAL_BILL_OF_LADING]: '正本',
  [PICKUP_TYPE.ATTACHMENT_PICKUP_TYPE_TELEX_RELEASE]: '电放',
};
