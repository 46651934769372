// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 根据员工姓名查询员工信息 GET /salesgateway/enterprise/wxEmployees */
export async function OperationId_salesgateway_EnterpriseWxEmployeeController_getEnterpriseWxEmployeeList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayEnterpriseWxEmployeeControllerGetEnterpriseWxEmployeeListParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetEnterpriseWxEmployeeResponse>('/salesgateway/enterprise/wxEmployees', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_EnterpriseWxEmployeeController_getEnterpriseWxEmployeeList',
    },
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(rest || {}),
  });
}
