// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 开发票 POST /invoice/v1/seller/invoice/create-blue-invoice */
export async function OperationId_businessinvoice_SellerInvoiceController_createBlueInvoice(
  body: API.CreateBlueInvoiceReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number[]>('/invoice/v1/seller/invoice/create-blue-invoice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_SellerInvoiceController_createBlueInvoice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 红冲 POST /invoice/v1/seller/invoice/create-red-invoice */
export async function OperationId_businessinvoice_SellerInvoiceController_createRedInvoice(
  body: API.CreateRedInvoiceReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number[]>('/invoice/v1/seller/invoice/create-red-invoice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_SellerInvoiceController_createRedInvoice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 根据蓝票红冲 POST /invoice/v1/seller/invoice/create-red-invoice-by-blue-invoice */
export async function OperationId_businessinvoice_SellerInvoiceController_createRedInvoiceBlueInvoice(
  body: API.CreateRedInvoiceBlueInvoiceReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number[]>('/invoice/v1/seller/invoice/create-red-invoice-by-blue-invoice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_businessinvoice_SellerInvoiceController_createRedInvoiceBlueInvoice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 详情 GET /invoice/v1/seller/invoice/getDetails/${param0} */
export async function OperationId_businessinvoice_SellerInvoiceController_getDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinessinvoiceSellerInvoiceControllerGetDetailsParams,
  options?: { [key: string]: any },
) {
  const { invoiceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetDetailsResponse>(`/invoice/v1/seller/invoice/getDetails/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_SellerInvoiceController_getDetails',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 列表 POST /invoice/v1/seller/invoice/page-invoice */
export async function OperationId_businessinvoice_SellerInvoiceController_pageInvoice(
  body: API.SellerPageInvoiceRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SellerPageInvoiceResponse>('/invoice/v1/seller/invoice/page-invoice', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businessinvoice_SellerInvoiceController_pageInvoice',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询待红冲列表 POST /invoice/v1/seller/invoice/pending-red-invoice-list */
export async function OperationId_businessinvoice_SellerInvoiceController_getPendingRedInvoiceList(
  body: API.GetPendingRedInvoiceListReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.GetPendingRedInvoiceListResp>(
    '/invoice/v1/seller/invoice/pending-red-invoice-list',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_businessinvoice_SellerInvoiceController_getPendingRedInvoiceList',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}
