/**
 * 默认搜索 page 和 size
 */
export const defaultSearchParams = {
  page: 1,
  size: 10,
};

/**
 * 默认城市列表，select下来默认显示
 */
export const defaultCityIds = [
  '12008838257496064', // 北京
  '12008842661515264', // 上海
  '12008850186096640', // 广州
  '12008849364013056', // 深圳
  '12008846423805952', // 青岛
  '12008840614694912', // 天津
  '12008841835237376', // 大连
  '12008844351819776', // 宁波
  '22230018583220224', // 苏州
];

export const trackerNames = {
  booking: 'CLICK_OCEAN_TAB_BOOKING_FROM_SITE', // 订舱
  ask: 'CLICK_OCEAN_TAB_ASK_FROM_SITE', // 询价
  buynow: 'CLICK_OCEAN_TAB_RECOMMEND_BOOKING_FROM_SITE', // 立即抢购
};

/**
 * 公有oss基础地址
 */
export const OSS_PUBLIC_BASE_BURL = 'https://yichang-fronted-public.oss-cn-hangzhou.aliyuncs.com';

// 权限文件上传至oss的文件名
export const ACCESS_UPLOAD_NAME = (version: string) => {
  return {
    TRADE: `trade.${process.env.APP_ENV}${['pre', 'prod'].includes(process.env.APP_ENV || 'dev') ? '' : `.${version}`}.json`, // 交易中心
    OPERATION_PLATFORM: `operationPlatform.${process.env.APP_ENV}${['pre', 'prod'].includes(process.env.APP_ENV || 'dev') ? '' : `.${version}`}.json`, // 运营平台
    BOX: `box.${process.env.APP_ENV}${['pre', 'prod'].includes(process.env.APP_ENV || 'dev') ? '' : `.${version}`}.json`, // 箱管
    DMS: `dms.${process.env.APP_ENV}${['pre', 'prod'].includes(process.env.APP_ENV || 'dev') ? '' : `.${version}`}.json`, // DMS
    DEPOT_APP: `depot_app.${process.env.APP_ENV}${['pre', 'prod'].includes(process.env.APP_ENV || 'dev') ? '' : `.${version}`}.json`, // 预约平台
  };
};

export interface PermissionJsonUrlOption {
  /**
   * 权限json文件的地址
   */
  base: string;
  /**
   * 权限json文件的备选地址
   */
  fallback: string;
}

export type PermissionPlatform = 'trade' | 'admin' | 'box' | 'dms' | 'depot_app' | 'depot';

/**
 * 权限json地址配置
 */
export const PERMISSION_JSON_URL_MAP = (version: string): Record<PermissionPlatform, PermissionJsonUrlOption> => {
  return {
    trade: {
      base: `${OSS_PUBLIC_BASE_BURL}/files/${ACCESS_UPLOAD_NAME(version).TRADE}`,
      fallback: `${OSS_PUBLIC_BASE_BURL}/files/trade.prod.json`,
    },
    admin: {
      base: `${OSS_PUBLIC_BASE_BURL}/files/${ACCESS_UPLOAD_NAME(version).OPERATION_PLATFORM}`,
      fallback: `${OSS_PUBLIC_BASE_BURL}/files/operationPlatform.prod.json`,
    },
    box: {
      base: `${OSS_PUBLIC_BASE_BURL}/files/${ACCESS_UPLOAD_NAME(version).BOX}`,
      fallback: `${OSS_PUBLIC_BASE_BURL}/files/box.prod.json`,
    },
    dms: {
      base: `${OSS_PUBLIC_BASE_BURL}/files/${ACCESS_UPLOAD_NAME(version).DMS}`,
      fallback: `${OSS_PUBLIC_BASE_BURL}/files/dms.prod.json`,
    },
    depot_app: {
      base: `${OSS_PUBLIC_BASE_BURL}/files/${ACCESS_UPLOAD_NAME(version).DEPOT_APP}`,
      fallback: `${OSS_PUBLIC_BASE_BURL}/files/depot_app.prod.json`,
    },
  };
};
