import { useControllableValue, useMemoizedFn, useRequest, useSetState } from 'ahooks';
import { useEffect, useMemo, useRef } from 'react';
import type { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';
import { Image, message, Upload } from 'antd';
import { isEqual } from 'lodash';
import { utils } from '@easygo/utils';
import { i18n } from '@easygo/i18n';
import { UploadComponent } from './Upload';
import type { ProUploadProps } from './interface';
import { getFileAddress } from './utils';

export function ProUpload(props: ProUploadProps) {
  const deaultAccept = '.pdf,.jpg,.jpeg,.png,.docx,.doc,.xls,.xlsx';
  const { value, onChange, render, fileSize = 5, accept = deaultAccept, ...restProps } = props;
  const ref = useRef<{ name: string; url: string }[]>([]);
  const [previewFile, setPreviewFile] = useSetState<{ url: string; visible: boolean }>({ url: '', visible: false });
  const [fileList, setFileList] = useControllableValue<UploadFile[]>(props, {
    defaultValue: [],
  });
  const beforeUpload: UploadProps['beforeUpload'] = useMemoizedFn((file: UploadFile) => {
    const isLtSize = file?.size! / 1024 / 1024 < fileSize;
    const fileType = file?.name.split('.')?.slice(-1);
    const isLtType = accept.split(',').includes(`.${fileType[0]}`);
    if (!isLtSize) {
      message.error(`${i18n?.t('文件不允许超过{{value}}M', { value: fileSize })}`);
    } else if (!isLtType) {
      message.error(`${i18n?.t('仅支持文件格式：{{accept}}格式附件', { accept })}`);
    }
    return isLtSize && isLtType ? true : Upload.LIST_IGNORE;
  });
  const onUploadChange = useMemoizedFn((param: UploadChangeParam) => {
    let newFileList = [...param.fileList];
    if (fileList?.length === (restProps.maxCount ?? 0) && param.file.status !== 'removed' && !props.multiple) {
      newFileList.splice(fileList.length - 1, 1, param.file);
    }
    newFileList = newFileList.map((file) => {
      if (file.response && file.status === 'done') {
        if (!restProps.isPublic) {
          file.name = file.response;
        } else {
          file.url = `${file.response}${restProps.watermarkStyleName ? `?x-oss-process=style/${restProps.watermarkStyleName}` : ''}`;
        }
      }
      return file;
    });
    setFileList(newFileList);
  });
  const onRemove = useMemoizedFn((file: UploadFile) => {
    ref.current = ref.current.filter((item) => {
      return item.name !== file.name;
    });
  });
  const onPreview = useMemoizedFn((file: UploadFile) => {
    const { name } = file;
    const fileSuffix = name.substring(name.lastIndexOf('.'));
    if (name && /.(png|jpg|gif|jpeg|webp)$/.test(fileSuffix)) {
      setPreviewFile({ url: file.url!, visible: true });
    } else {
      window.open(file.url);
    }
  });
  const onDownload = useMemoizedFn((file: UploadFile) => {
    utils.download(file.url!, file.name);
  });
  const initUrl = useRequest(
    async () => {
      if (!restProps.isPublic) {
        const newList = [...fileList];
        for (let i = 0; i < newList?.length; i++) {
          const target: any = newList[i] ?? {};
          if (!target.url && (target.status === 'done' || !target.status)) {
            const url = await getFileAddress(target.name, restProps.watermarkStyleName);
            target.url = url!;
          }
        }
        ref.current = newList.flatMap((item) => {
          return item.status === 'done' || !item.status ? [{ name: item.name, url: item.url! }] : [];
        });
        setFileList(newList);
      }
    },
    { manual: true, debounceWait: 300 }
  );
  const defaultDom = useMemo(() => {
    return (
      <UploadComponent
        onDownload={onDownload}
        onPreview={onPreview}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
        accept={accept}
        {...restProps}
        fileList={fileList ?? []}
        onChange={onUploadChange}
      ></UploadComponent>
    );
  }, [fileList, onDownload, onPreview, onRemove, onUploadChange, restProps, beforeUpload, accept]);
  useEffect(() => {
    if (!restProps.isPublic && fileList?.length && !fileList?.some((it) => it.status === 'uploading')) {
      const cache: any = (fileList ?? []).flatMap((item) => {
        if (item.status === 'done' || !item.status) {
          return [{ name: item.name, url: item.url || '' }];
        }
        return [];
      });
      if (!ref.current || (ref.current && !isEqual(ref.current, cache))) {
        ref.current = cache;
        initUrl.run();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, restProps.isPublic]);
  return (
    <>
      {render ? render(defaultDom, fileList) : defaultDom}

      <Image
        alt=""
        {...restProps.previewImg}
        preview={
          // false表示禁用预览，直接覆盖
          restProps.previewImg?.preview === false
            ? false
            : {
                visible: previewFile.visible,
                src: previewFile.url,
                onVisibleChange(value, prevValue) {
                  setPreviewFile({ visible: value });
                },
                // 需要预览得情况，需要排除掉true值
                ...(typeof restProps.previewImg?.preview !== 'boolean' && restProps.previewImg?.preview),
              }
        }
      />
    </>
  );
}
