export const formatEnums = (target: any) => {
  let enums: any = {};
  if (Array.isArray(target)) {
    target.map((it: any) => {
      enums[it.value] = it.label;
    });
  } else {
    enums = target;
  }
  return enums;
};

export const getLabelByEnumValue = (value: any, enums: any) => {
  if (Array.isArray(enums)) {
    return enums.find((it) => it.value === value)?.label || '-';
  }
  const enumsMap = formatEnums(enums);
  const target = enumsMap[value];
  if (Object.prototype.toString.call(target) === '[object Object]') {
    return target.text;
  }
  return target;
};
