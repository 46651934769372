// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 分页查询导出任务列表 GET /exportcenter/v1/tasks */
export async function OperationId_exportcenter_ExportController_pageTasks(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdExportcenterExportControllerPageTasksParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPagePageTasksResponse>('/exportcenter/v1/tasks', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_exportcenter_ExportController_pageTasks',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 获取任务下载地址 GET /exportcenter/v1/tasks/${param0}/download */
export async function OperationId_exportcenter_ExportController_getDownloadUri(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdExportcenterExportControllerGetDownloadUriParams,
  options?: { [key: string]: any },
) {
  const { taskId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetDownloadUriResponse>(`/exportcenter/v1/tasks/${param0}/download`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_exportcenter_ExportController_getDownloadUri',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}
