// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 通过安全码创建或直接登录 POST /salesgateway/auth/authorize */
export async function OperationId_salesgateway_AuthController_createOrLoginAccountBySecureCode(
  body: API.CreateOrLoginAccountBySecureCodeVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AuthResponseVO>('/salesgateway/auth/authorize', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AuthController_createOrLoginAccountBySecureCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取JWT验证公钥 GET /salesgateway/auth/jwt-key */
export async function OperationId_salesgateway_AuthController_jwtRsaKey(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.JWTRsaKeyResponseVO>('/salesgateway/auth/jwt-key', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AuthController_jwtRsaKey',
    },
    ...(rest || {}),
  });
}

/** 通过密码进行登录 POST /salesgateway/auth/password */
export async function OperationId_salesgateway_AuthController_authByPasswordWithCipher(
  body: API.CipherRequestVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AuthResponseVO>('/salesgateway/auth/password', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AuthController_authByPasswordWithCipher',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取随机公钥 GET /salesgateway/auth/random-key */
export async function OperationId_salesgateway_AuthController_randomRsaKey(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.RandomRsaKeyResponseVO>('/salesgateway/auth/random-key', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AuthController_randomRsaKey',
    },
    ...(rest || {}),
  });
}

/** 通过验证码交换安全码 GET /salesgateway/auth/secure-code */
export async function OperationId_salesgateway_AuthController_exchangeSecureCodeByVerifyCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayAuthControllerExchangeSecureCodeByVerifyCodeParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ExchangeSecureCodeByVerifyCodeResponseVO>('/salesgateway/auth/secure-code', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AuthController_exchangeSecureCodeByVerifyCode',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 刷新令牌 PUT /salesgateway/auth/token */
export async function OperationId_salesgateway_AuthController_refreshToken(
  body: API.RefreshTokenVo,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AuthResponseVO>('/salesgateway/auth/token', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AuthController_refreshToken',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 发送验证码，有效期120s POST /salesgateway/auth/verify-code */
export async function OperationId_salesgateway_AuthController_sendVerifyCode(
  body: API.SendVerifyCodeVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SendVerifyCodeResponseVO>('/salesgateway/auth/verify-code', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_AuthController_sendVerifyCode',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
