import { Form, Space } from 'antd';
import classNames from 'classnames/bind';
import styles from './index.module.less';
import type { EasyFormProps } from './interface';
import formTypes from '../field-types/formTypes';
import { ruleRequired } from '@easygo/utils/src/utils';
import { useComponentTrans } from '../config';

const selectType = ['cascader', 'remoteSelect', 'select', 'checkboxGroup', 'radioGroup', 'upload'];
const cx = classNames.bind(styles);
// 文件夹不加pro前缀以便查找时可以根据首字母直接找到文件夹
export function EasyForm<ValuesType extends Record<string, any> = Record<string, any>>(props: EasyFormProps<ValuesType>) {
  const { options, formDisabled = false, showLabel = false, ...restProps } = props;
  const trans = useComponentTrans();
  return (
    <Form {...restProps}>
      <div className={cx('wrap')}>
        {(options ?? []).map(
          ({
            key,
            label,
            type,
            placeholderPrefix = selectType.includes(type ?? '') ? trans('select_default_placeholder') : trans('input_default_placeholder'),
            placeholder,
            totalLine = true,
            halfLine,
            thirdLine,
            quarterLine,
            tips,
            renderFormItem,
            require = false,
            rules = [],
            fieldProps,
            ...optionRestProps
          }) => {
            const defaultPlaceholder = `${placeholderPrefix}${label}`;
            const itemProps = {
              placeholder: placeholder || defaultPlaceholder,
              rules: require ? [...ruleRequired(defaultPlaceholder), ...rules] : rules,
              disabled: formDisabled,
              ...optionRestProps,
            };
            return (
              <div key={key} className={cx({ totalLine: totalLine && !halfLine && !thirdLine && !quarterLine, halfLine, thirdLine, quarterLine })}>
                <Form.Item name={key} label={showLabel ? label : ''} {...itemProps}>
                  {renderFormItem ? renderFormItem(itemProps, restProps.form) : formTypes[type ?? 'text']({ ...itemProps, ...fieldProps })}
                </Form.Item>
                {tips && <div className={styles.tips}>{tips}</div>}
              </div>
            );
          }
        )}
      </div>
    </Form>
  );
}
