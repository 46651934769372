import { createContext, useContext } from 'react';
import type { ComponentProps, FC } from 'react';
import zh from './locales/zh/index.json';
import en from './locales/en/index.json';
import { uploadConfig } from '../pro-upload/utils';
import { dictConfig } from '../hooks/useDict';
import type { DictType } from '../hooks/useDict';
import type {
  RequestPrivateFileAddress,
  RequestPrivateStsToken,
  RequestPublicFileAddress,
  RequestPrivateFileUploadAddress,
  RequestPublicStsToken,
} from '../pro-upload/interface';

const Context = createContext<{
  locale?: string;
  /**
   * 文件上传配置
   */
  uploadConfig?: {
    /**
     * 获取私有文件真实地址接口
     */
    getPrivateFileAddress?: RequestPrivateFileAddress;
    /**
     * 获取公有文件真实地址接口
     */
    getPublicFileAddress?: RequestPublicFileAddress;
    /**
     * 获取私有文件上传地址
     */
    getPrivateUploadAddress?: RequestPrivateFileUploadAddress;
    /**
     * 获取公有STS上传临时Token
     */
    getPublicStsToken?: RequestPublicStsToken;
    /**
     * 获取私有STS上传临时Token
     */
    getPrivateStsToken?: RequestPrivateStsToken;
  };
  dictConfig?: {
    [key in DictType]: () => Promise<{ label: any; value: any; [key: string]: any }[]>;
  };
}>({ locale: 'zh' });
export const ComponentConfigProvider: FC<ComponentProps<typeof Context.Provider>> = ({ children, ...config }) => {
  // 初始化oss上传等相关接口
  uploadConfig.config(config.value?.uploadConfig ?? {});
  dictConfig.config(config.value?.dictConfig ?? {});
  componentI18nInstance.locale === config?.value?.locale ?? 'zh';
  return <Context.Provider {...config}>{children}</Context.Provider>;
};
class ComponentI18n {
  locale: string = 'zh';
  trans(key: string, options?: any[]) {
    const target: any = this.locale === 'en' ? en : zh;
    const str = key.split('.').reduce((a, b) => {
      return a[b];
    }, target);
    if (!options) {
      return str;
    }
    return str.replace(/\{\?\}/gim, (a: any, b: any) => {
      const el = options.splice(0, 1);
      return el;
    });
  }
}
export const componentI18nInstance = new ComponentI18n();

export function useComponentTrans() {
  const { locale = 'zh' } = useContext(Context);
  return (key: string, options?: any[]) => {
    const target: any = locale === 'en' ? en : zh;
    const str = key.split('.').reduce((a, b) => {
      return a[b];
    }, target);
    if (!options) {
      return str;
    }
    return str.replace(/\{\?\}/gim, (a: any, b: any) => {
      const el = options.splice(0, 1);
      return el;
    });
  };
}
