// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 新增或编辑 POST /commodity/originCoverCityTemplate */
export async function OperationId_businesscommodity_OriginCoverCityTemplateController_createOrEdit(
  body: API.OriginCoverCityTemplateCreateReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/commodity/originCoverCityTemplate', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businesscommodity_OriginCoverCityTemplateController_createOrEdit',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取详情 GET /commodity/originCoverCityTemplate/${param0} */
export async function OperationId_businesscommodity_OriginCoverCityTemplateController_details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinesscommodityOriginCoverCityTemplateControllerDetailsParams,
  options?: { [key: string]: any },
) {
  const { originCityId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.OriginCoverCityTemplateDetailsRespBo>(
    `/commodity/originCoverCityTemplate/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_businesscommodity_OriginCoverCityTemplateController_details',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 查询按条件-城市/地区列表 POST /commodity/originCoverCityTemplate/address/condition-address */
export async function OperationId_businesscommodity_OriginCoverCityTemplateController_listConditionAddress(
  body: API.AddressConditionQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.AddressConditionRespBo>(
    '/commodity/originCoverCityTemplate/address/condition-address',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_businesscommodity_OriginCoverCityTemplateController_listConditionAddress',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 分页查询 GET /commodity/originCoverCityTemplate/page */
export async function OperationId_businesscommodity_OriginCoverCityTemplateController_page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinesscommodityOriginCoverCityTemplateControllerPageParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.OriginCoverCityTemplatePageRespBo>('/commodity/originCoverCityTemplate/page', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businesscommodity_OriginCoverCityTemplateController_page',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 模版启用禁用 POST /commodity/originCoverCityTemplate/status/${param0} */
export async function OperationId_businesscommodity_OriginCoverCityTemplateController_updateStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdBusinesscommodityOriginCoverCityTemplateControllerUpdateStatusParams,
  body: API.OriginCoverCityTemplateUpdateReq,
  options?: { [key: string]: any },
) {
  const { originCityId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/commodity/originCoverCityTemplate/status/${param0}`, {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_businesscommodity_OriginCoverCityTemplateController_updateStatus',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
