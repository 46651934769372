// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询商品 GET /sinotransbooking/v1/open/products */
export async function OperationId_sinotransbooking_OpenProductController_pageProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenProductControllerPageProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/open/products', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenProductController_pageProduct',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 查询热门商品 GET /sinotransbooking/v1/open/products/recommend-products */
export async function OperationId_sinotransbooking_OpenProductController_pageRecommendProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingOpenProductControllerPageRecommendProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/open/products/recommend-products', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_OpenProductController_pageRecommendProduct',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}
