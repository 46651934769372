// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询境外回箱线路详情 GET /salesgateway/container-back/line/api/${param0}/detail */
export async function OperationId_salesgateway_ContainerBackRouteController_getContainerBackLineInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerBackRouteControllerGetContainerBackLineInfoParams,
  options?: { [key: string]: any },
) {
  const { backhaulRouteId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ContainerBackRouteInfoResVO>(
    `/salesgateway/container-back/line/api/${param0}/detail`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_ContainerBackRouteController_getContainerBackLineInfo',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
