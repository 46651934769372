import { useMemoizedFn } from 'ahooks';
import { Modal } from 'antd';
import { useLayoutEffect } from 'react';
import type { ReactNode } from 'react';
import style from './index.module.less';

/** 签署状态枚举 */
export const enum TsignCode {
  /** 签署成功 */
  'SUCCESS',
  /** 无权访问 */
  'NOAUTH',
  /** 流程被拒签 */
  'REJECT',
  /** 异常 */
  'ERROR',
}
/** 签署回调所有数据 */
export interface CbData {
  tsignCode?: TsignCode;
}

interface Props {
  /** 协议地址 */
  signUrl: string;
  /** 弹窗标题 */
  title?: ReactNode;
  /** 弹窗开关 */
  open: boolean;
  /** 控制弹窗开关的方法 */
  onClose: () => void;
  /** 用于接收签署结果等参数 */
  onConfirm?: (cbData: CbData) => void;
}

export default ({ title, signUrl, open, onClose, onConfirm }: Props) => {
  const handleMessage = useMemoizedFn((e: MessageEvent) => {
    try {
      if (typeof e.data === 'string' && e.data.includes('tsignCode')) {
        const data = JSON.parse(e.data);
        // 签署完成，自动提交
        if ('tsignCode' in data) {
          onConfirm?.({ tsignCode: data.tsignCode ? +data.tsignCode : TsignCode.ERROR });
        }
      }
    } catch (error) {
      console.error(error);
    }
  });
  useLayoutEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);
  return (
    <Modal title={title ?? ' '} open={open} onCancel={onClose} footer={null} destroyOnClose width="100vw" className={style.easyESignModal}>
      <iframe src={signUrl} frameBorder="0" width="100%" height="100%">
        <p>Your browser does not support iframes.</p>
      </iframe>
    </Modal>
  );
};
