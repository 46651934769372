// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 卖家开通-查询所有买家名称下拉列表 GET /salesgateway/payment-on-account/api/allBuyerEnterpriseList */
export async function OperationId_salesgateway_PaymentOnAccountController_allBuyerEnterpriseList(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseIdNameVO[]>(
    '/salesgateway/payment-on-account/api/allBuyerEnterpriseList',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_allBuyerEnterpriseList',
      },
      ...(rest || {}),
    },
  );
}

/** 买/卖 家-开通查询 POST /salesgateway/payment-on-account/api/buyer/credits */
export async function OperationId_salesgateway_PaymentOnAccountController_getCreditAccounts(
  body: API.SearchCreditAccountReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CreditAccountResWithPageVO>(
    '/salesgateway/payment-on-account/api/buyer/credits',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_getCreditAccounts',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家-已出账单-已出账单列表 POST /salesgateway/payment-on-account/api/buyer/searchBuyerCreditOrder */
export async function OperationId_salesgateway_PaymentOnAccountController_searchBuyerCreditOrder(
  body: API.SearchCreditOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchMerchantCreditOrderResPB>(
    '/salesgateway/payment-on-account/api/buyer/searchBuyerCreditOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_searchBuyerCreditOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家-查询买家名称下拉列表 GET /salesgateway/payment-on-account/api/buyerEnterpriseList */
export async function OperationId_salesgateway_PaymentOnAccountController_getBuyerEnterpriseList(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseIdNameVO[]>(
    '/salesgateway/payment-on-account/api/buyerEnterpriseList',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_getBuyerEnterpriseList',
      },
      ...(rest || {}),
    },
  );
}

/** 买家-我已还款按钮-还款确认填写 POST /salesgateway/payment-on-account/api/creditRePay */
export async function OperationId_salesgateway_PaymentOnAccountController_creditRePay(
  body: API.CreditPayReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/payment-on-account/api/creditRePay', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PaymentOnAccountController_creditRePay',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 卖家-新增账期付款账号 POST /salesgateway/payment-on-account/api/credits */
export async function OperationId_salesgateway_PaymentOnAccountController_createCreditAccount(
  body: API.CreditVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/payment-on-account/api/credits', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PaymentOnAccountController_createCreditAccount',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买/卖家-历史额度查询 GET /salesgateway/payment-on-account/api/credits/history/${param0} */
export async function OperationId_salesgateway_PaymentOnAccountController_buyerCreditsHistory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPaymentOnAccountControllerBuyerCreditsHistoryParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.CreditOrderHistoryResVO>(
    `/salesgateway/payment-on-account/api/credits/history/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_buyerCreditsHistory',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 卖家-查询账期付修改记录 GET /salesgateway/payment-on-account/api/credits/logs/${param0} */
export async function OperationId_salesgateway_PaymentOnAccountController_searchCreditChangeLog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayPaymentOnAccountControllerSearchCreditChangeLogParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetCreditChangeLogResponseVO>(
    `/salesgateway/payment-on-account/api/credits/logs/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_searchCreditChangeLog',
      },
      params: {
        ...queryParams,
        pagination: undefined,
        ...queryParams['pagination'],
      },
      ...(rest || {}),
    },
  );
}

/** 买家-未出账单&账单详情 POST /salesgateway/payment-on-account/api/searchCreditRecord */
export async function OperationId_salesgateway_PaymentOnAccountController_searchCreditRecord(
  body: API.SearchCreditRecordReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchCreditRecordResPB>(
    '/salesgateway/payment-on-account/api/searchCreditRecord',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_searchCreditRecord',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家-未出账单&账单详情 POST /salesgateway/payment-on-account/api/searchSellerCreditRecord */
export async function OperationId_salesgateway_PaymentOnAccountController_searchSellerCreditRecord(
  body: API.SearchCreditRecordReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchCreditRecordResPB>(
    '/salesgateway/payment-on-account/api/searchSellerCreditRecord',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_searchSellerCreditRecord',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 卖家-已出账单-已出账单列表 POST /salesgateway/payment-on-account/api/seller/searchSellerCreditOrder */
export async function OperationId_salesgateway_PaymentOnAccountController_searchSellerCreditOrder(
  body: API.SearchCreditOrderReqVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SearchMerchantCreditOrderResPB>(
    '/salesgateway/payment-on-account/api/seller/searchSellerCreditOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_searchSellerCreditOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 买家-查询卖家名称下拉列表 GET /salesgateway/payment-on-account/api/sellerEnterpriseList */
export async function OperationId_salesgateway_PaymentOnAccountController_getSellerEnterpriseList(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseIdNameVO[]>(
    '/salesgateway/payment-on-account/api/sellerEnterpriseList',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_PaymentOnAccountController_getSellerEnterpriseList',
      },
      ...(rest || {}),
    },
  );
}

/** 卖家-更新账期付款 PUT /salesgateway/payment-on-account/api/updateCredits */
export async function OperationId_salesgateway_PaymentOnAccountController_updateCredit(
  body: API.UpdateCreditVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/payment-on-account/api/updateCredits', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_PaymentOnAccountController_updateCredit',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
