import { Alert } from 'antd';
import type { AlertProps } from 'antd';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { InfoOutlined, CheckOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';
import styles from './index.module.less';

type type = 'success' | 'info' | 'warning' | 'error';

const TYPE_ICON_MAP: {
  [key in type]: ReactNode;
} = {
  success: <CheckOutlined />,
  info: <InfoOutlined />,
  warning: <WarningOutlined />,
  error: <CloseOutlined />,
};

const DEFUALT_ICON = TYPE_ICON_MAP['info'];

export type ProAlertProps = Omit<AlertProps, 'icon'>;

export function ProAlert(props: ProAlertProps) {
  const { type, ...restProps } = props;

  const memoIcon = useMemo(() => {
    return <div className={styles.icon}>{TYPE_ICON_MAP[type as keyof typeof TYPE_ICON_MAP] || DEFUALT_ICON}</div>;
  }, [type]);

  return (
    <div className={styles.alert}>
      <Alert {...restProps} icon={memoIcon} />
    </div>
  );
}
