import { i18n } from '@easygo/i18n';
import { pick } from 'lodash';

/** 报关订单类型 */
export enum DECLARE_ORDER_TYPE {
  /** 全部 */
  ALL = 'ALL',
  /** 进行中 */
  PROGRESS = 'PROGRESS',
  /** 待处理 */
  PENDING = 'PENDING',
  /** 异常 */
  ABNOMAL = 'ABNOMAL',
  /** 待支付 */
  UNPAID = 'UNPAID',
}

/** 报关履约状态 */
export enum DeclarePerformanceStatus {
  /** 待接单 */
  ORDER_WAITING = 'CUSTOMS_DECLARE_PERFORMANCE_STATUS_ORDER_WAITING',
  /** 已接单待报关 */
  WAITING_CUSTOMS_DECLARE = 'CUSTOMS_DECLARE_PERFORMANCE_STATUS_WAITING_CUSTOMS_DECLARE',
  /** 已报关 */
  CUSTOMS_DECLARE_SUCCESS = 'CUSTOMS_DECLARE_PERFORMANCE_STATUS_CUSTOMS_DECLARE_SUCCESS',
  /** 已完成 */
  CUSTOMS_ORDER_COMPLETED = 'CUSTOMS_DECLARE_PERFORMANCE_STATUS_CUSTOMS_ORDER_COMPLETED',
  /** 已取消 */
  CUSTOMS_ORDER_CANCEL = 'CUSTOMS_DECLARE_PERFORMANCE_STATUS_CUSTOMS_ORDER_CANCEL',
}
export const DeclarePerformanceStatusLabels = {
  [DeclarePerformanceStatus.ORDER_WAITING]: 'ORDER_WAITING',
  [DeclarePerformanceStatus.WAITING_CUSTOMS_DECLARE]: 'WAITING_CUSTOMS_DECLARE',
  [DeclarePerformanceStatus.CUSTOMS_DECLARE_SUCCESS]: 'CUSTOMS_DECLARE_SUCCESS',
  [DeclarePerformanceStatus.CUSTOMS_ORDER_CANCEL]: 'CUSTOMS_ORDER_CANCEL',
  [DeclarePerformanceStatus.CUSTOMS_ORDER_COMPLETED]: 'CUSTOMS_ORDER_COMPLETED',
};
export const DeclarePerformanceStatusLabel = {
  [DeclarePerformanceStatus.ORDER_WAITING]: i18n?.t('待接单'),
  [DeclarePerformanceStatus.WAITING_CUSTOMS_DECLARE]: i18n?.t('已接单待报关'),
  [DeclarePerformanceStatus.CUSTOMS_DECLARE_SUCCESS]: i18n?.t('已报关'),
  [DeclarePerformanceStatus.CUSTOMS_ORDER_COMPLETED]: i18n?.t('已完成'),
  [DeclarePerformanceStatus.CUSTOMS_ORDER_CANCEL]: i18n?.t('已取消'),
};

//报关履约支付状态
export enum DeclarePerformancePayStatus {
  UNPAID = 'CUSTOMS_DECLARE_PERFORMANCE_PAY_STATUS_UNPAID',
  IN_PROCESSING = 'CUSTOMS_DECLARE_PERFORMANCE_PAY_STATUS_IN_PROCESSING',
  SUCCESS = 'CUSTOMS_DECLARE_PERFORMANCE_PAY_STATUS_SUCCESS',
  FAIL = 'CUSTOMS_DECLARE_PERFORMANCE_PAY_STATUS_FAIL',
}
export const DeclarePerformancePayStatusLabel = {
  [DeclarePerformancePayStatus.UNPAID]: i18n?.t('待支付'),
  [DeclarePerformancePayStatus.IN_PROCESSING]: i18n?.t('支付中'),
  [DeclarePerformancePayStatus.SUCCESS]: i18n?.t('支付成功'),
  [DeclarePerformancePayStatus.FAIL]: i18n?.t('支付失败'),
};

//报关履约节点
export enum DeclarePerformanceNode {
  CUSTOMS_DECLARE_NODE_ORDER_SUBMIT = 'CUSTOMS_DECLARE_NODE_ORDER_SUBMIT',
  CUSTOMS_DECLARE_NODE_ORDER_ACCEPT = 'CUSTOMS_DECLARE_NODE_ORDER_ACCEPT',
  CUSTOMS_DECLARE_NODE_ORDER_REJECT = 'CUSTOMS_DECLARE_NODE_ORDER_REJECT',
  CUSTOMS_DECLARE_NODE_ORDER_CANCEL = 'CUSTOMS_DECLARE_NODE_ORDER_CANCEL',
  CUSTOMS_DECLARE_NODE_UPLOAD_CUSTOMS_DECLARE = 'CUSTOMS_DECLARE_NODE_UPLOAD_CUSTOMS_DECLARE',
  CUSTOMS_DECLARE_NODE_UPLOAD_CUSTOMS_CREDENTIALS = 'CUSTOMS_DECLARE_NODE_UPLOAD_CUSTOMS_CREDENTIALS',
  CUSTOMS_DECLARE_NODE_MAKE_BILLS = 'CUSTOMS_DECLARE_NODE_MAKE_BILLS',
  CUSTOMS_DECLARE_NODE_PAY_BILLS = 'CUSTOMS_DECLARE_NODE_PAY_BILLS',
}
export const DeclarePerformanceNodeLabels = {
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_ORDER_SUBMIT]: 'SUBMMIT_ORER', // 下单
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_ORDER_ACCEPT]: 'ORDER_ACCEPT', // 接单
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_ORDER_REJECT]: 'ORDER_REJECT', // 拒单
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_ORDER_CANCEL]: 'ORDER_CANCEL_DECLARE', // 取消订单
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_UPLOAD_CUSTOMS_DECLARE]: 'UPLOAD_CUSTOMS_DECLARE', // 买家上传报关资料
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_UPLOAD_CUSTOMS_CREDENTIALS]: 'UPLOAD_CUSTOMS_CREDENTIALS', // 卖家上传通关凭证
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_MAKE_BILLS]: 'MAKE_BILLS', // 制作账单
  [DeclarePerformanceNode.CUSTOMS_DECLARE_NODE_PAY_BILLS]: 'PAY_BILLS', // 支付
};

//报关附件类型
export enum DeclareAttachmentType {
  CUSTOMS_DECLARE = 'CUSTOMS_DECLARE_ATTACHMENT_TYPE_CUSTOMS_DECLARE',
  CUSTOMS_CREDENTIALS = 'CUSTOMS_DECLARE_ATTACHMENT_TYPE_CUSTOMS_CREDENTIALS',
  OTHERS = 'CUSTOMS_DECLARE_ATTACHMENT_TYPE_OTHERS',
}
export const DeclareAttachmentTypeLabels = {
  [DeclareAttachmentType.CUSTOMS_DECLARE]: 'DECLARE', // 报关资料
  [DeclareAttachmentType.CUSTOMS_CREDENTIALS]: 'CREDENTIALS', // 通关凭证
  [DeclareAttachmentType.OTHERS]: 'OTHERS_FILE',
};

/**
 * 报关模式
 */
export enum DeclareMode {
  GENERAL_TRADE = 'CUSTOMS_DECLARE_MODE_TYPE_GENERAL_TRADE',
  MANUAL = 'CUSTOMS_DECLARE_MODE_TYPE_MANUAL',
  MARKET_PROCUREMENT = 'CUSTOMS_DECLARE_MODE_TYPE_MARKET_PROCUREMENT',
  CROSS_BORDER_ONLINE_RETAILERS = 'CUSTOMS_DECLARE_MODE_TYPE_CROSS_BORDER_ONLINE_RETAILERS',
}

export const DeclareModeLabel = {
  [DeclareMode.GENERAL_TRADE]: i18n?.t('一般贸易'),
  [DeclareMode.MANUAL]: i18n?.t('手册报关'),
  [DeclareMode.MARKET_PROCUREMENT]: i18n?.t('市场采购'),
  [DeclareMode.CROSS_BORDER_ONLINE_RETAILERS]: i18n?.t('跨境电商'),
};

//报关运输方式
export enum DeclareTransportType {
  RAILWAY = 'CUSTOMS_DECLARE_TRANSPORT_TYPE_RAILWAY',
  SHIPPING = 'CUSTOMS_DECLARE_TRANSPORT_TYPE_SHIPPING',
  OTHERS = 'CUSTOMS_DECLARE_TRANSPORT_TYPE_OTHERS',
}
export const DeclareTransportTypeLabels = {
  [DeclareTransportType.RAILWAY]: i18n?.t('班列'),
  [DeclareTransportType.SHIPPING]: i18n?.t('海运'),
  [DeclareTransportType.OTHERS]: i18n?.t('其他-公路/空运'),
};

/**
 * 报关类型
 * @property IMPORT 进口报关
 * @property EXPORT 出口报关
 */
export enum DeclareType {
  IMPORT = 'CUSTOMS_DECLARE_TYPE_IMPORT',
  EXPORT = 'CUSTOMS_DECLARE_TYPE_EXPORT',
}

export const DeclareTypeLabel = {
  [DeclareType.IMPORT]: i18n?.t('进口清关'),
  [DeclareType.EXPORT]: i18n?.t('出口报关'),
};

export const DeclareTypeIcon = {
  [DeclareType.IMPORT]: 'easygo-icon-Import',
  [DeclareType.EXPORT]: 'easygo-icon-Export',
};

//报关费用类型
export enum DeclareFeeType {
  /** 报关服务 */
  CUSTOMS_DECLARE = 'CUSTOMS_DECLARE_FEE_TYPE_CUSTOMS_DECLARE',
  /** 报检 */
  APPLICATION_INSPECTION = 'CUSTOMS_DECLARE_FEE_TYPE_APPLICATION_INSPECTION',
  /** 办保 */
  INSURANCE = 'CUSTOMS_DECLARE_FEE_TYPE_INSURANCE',
  /** 延保 */
  EXTENDED_WARRANTY = 'CUSTOMS_DECLARE_FEE_TYPE_EXTENDED_WARRANTY',
  /** 熏蒸 */
  FUMIGATION = 'CUSTOMS_DECLARE_FEE_TYPE_FUMIGATION',
  /** 核注清单录入 */
  DISINFECTION_STERILIZATION = 'CUSTOMS_DECLARE_FEE_TYPE_DISINFECTION_STERILIZATION',
  /** 消杀 */
  CHECK_LIST_ENTRY = 'CUSTOMS_DECLARE_FEE_TYPE_CHECK_LIST_ENTRY',
  /** 代垫税金 */
  PREPAID_TAXES = 'CUSTOMS_DECLARE_FEE_TYPE_PREPAID_TAXES',
}
export const DeclareFeeTypeLabel = {
  [DeclareFeeType.CUSTOMS_DECLARE]: i18n?.t('报关服务'),
  [DeclareFeeType.APPLICATION_INSPECTION]: i18n?.t('报检'),
  [DeclareFeeType.INSURANCE]: i18n?.t('办保'),
  [DeclareFeeType.EXTENDED_WARRANTY]: i18n?.t('延保'),
  [DeclareFeeType.FUMIGATION]: i18n?.t('熏蒸'),
  [DeclareFeeType.CHECK_LIST_ENTRY]: i18n?.t('核注清单录入'),
  [DeclareFeeType.DISINFECTION_STERILIZATION]: i18n?.t('消杀'),
  [DeclareFeeType.PREPAID_TAXES]: i18n?.t('代垫税金'),
};

export const DeclareImportFeeTypeLabel = pick(DeclareFeeTypeLabel, [
  DeclareFeeType.CUSTOMS_DECLARE,
  DeclareFeeType.APPLICATION_INSPECTION,
  DeclareFeeType.INSURANCE,
  DeclareFeeType.EXTENDED_WARRANTY,
  DeclareFeeType.DISINFECTION_STERILIZATION,
  DeclareFeeType.PREPAID_TAXES,
]);

export const DeclareExportFeeTypeLabel = pick(DeclareFeeTypeLabel, [
  DeclareFeeType.CUSTOMS_DECLARE,
  DeclareFeeType.APPLICATION_INSPECTION,
  DeclareFeeType.INSURANCE,
  DeclareFeeType.EXTENDED_WARRANTY,
  DeclareFeeType.FUMIGATION,
  DeclareFeeType.CHECK_LIST_ENTRY,
]);

//报关异常类型
export enum DeclareExceptionType {
  EXCEPTION_UPLOAD_CREDENTIALS_OVERTIME = 'EXCEPTION_UPLOAD_CREDENTIALS_OVERTIME',
}

export const DeclareExceptionLabel = {
  [DeclareExceptionType.EXCEPTION_UPLOAD_CREDENTIALS_OVERTIME]: i18n?.t('超时未上传通关凭证'),
};

//报关金额明细支付状态
export enum DeclarePerformanceOrderPayStatus {
  UNKNOWN = 'PAYMENT_DETAIL_STATUS_UNKNOWN',
  CREATE = 'PAYMENT_DETAIL_STATUS_CREATE',
  CANCEL = 'PAYMENT_DETAIL_STATUS_CANCEL',
  WAITING = 'PAYMENT_DETAIL_STATUS_WAITING',
  FINISHED = 'PAYMENT_DETAIL_STATUS_FINISH',
  CLOSE = 'PAYMENT_DETAIL_STATUS_CLOSE',
  PROCESSING = 'PAYMENT_DETAIL_STATUS_PROCESSING',
  FAIL = 'PAYMENT_DETAIL_STATUS_FAIL',
}
export const DeclarePerformanceOrderPayStatusLabel = {
  [DeclarePerformanceOrderPayStatus.UNKNOWN]: i18n?.t('未知'),
  [DeclarePerformanceOrderPayStatus.CREATE]: i18n?.t('已创建'),
  [DeclarePerformanceOrderPayStatus.CANCEL]: i18n?.t('已取消'),
  [DeclarePerformanceOrderPayStatus.WAITING]: i18n?.t('支付待确认'),
  [DeclarePerformanceOrderPayStatus.FINISHED]: i18n?.t('已完成'),
  [DeclarePerformanceOrderPayStatus.CLOSE]: i18n?.t('已关闭'),
  [DeclarePerformanceOrderPayStatus.PROCESSING]: i18n?.t('处理中'),
  [DeclarePerformanceOrderPayStatus.FAIL]: i18n?.t('支付失败'),
};
