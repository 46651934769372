// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 模糊获取代下单买家公司列表 GET /logisticssolution/v1/open/common/agent-enterprise */
export async function OperationId_logisticssolution_OpenCommonController_listBuyerEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCommonControllerListBuyerEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseInfoResponse>('/logisticssolution/v1/open/common/agent-enterprise', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCommonController_listBuyerEnterprise',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 模糊获取代下单买家公司员工列表 GET /logisticssolution/v1/open/common/agent-enterprise/emps */
export async function OperationId_logisticssolution_OpenCommonController_listBuyerEnterpriseEmps(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCommonControllerListBuyerEnterpriseEmpsParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseEmployeeResponse>(
    '/logisticssolution/v1/open/common/agent-enterprise/emps',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCommonController_listBuyerEnterpriseEmps',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 创建需求单 POST /logisticssolution/v1/open/common/demand-orders */
export async function OperationId_logisticssolution_OpenCommonController_createDemandOrder(
  body: API.DemandOrderCreateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/logisticssolution/v1/open/common/demand-orders', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_OpenCommonController_createDemandOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 模糊获取买家公司列表_铁路 GET /logisticssolution/v1/open/common/train-buyer-enterprises */
export async function OperationId_logisticssolution_OpenCommonController_listTrainBuyerEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCommonControllerListTrainBuyerEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcEnterpriseInfoDTO[]>(
    '/logisticssolution/v1/open/common/train-buyer-enterprises',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCommonController_listTrainBuyerEnterprise',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 模糊获取卖家公司列表_铁路 GET /logisticssolution/v1/open/common/train-seller-enterprises */
export async function OperationId_logisticssolution_OpenCommonController_listTrainSellerEnterprise(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionOpenCommonControllerListTrainSellerEnterpriseParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.RpcEnterpriseInfoDTO[]>(
    '/logisticssolution/v1/open/common/train-seller-enterprises',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_logisticssolution_OpenCommonController_listTrainSellerEnterprise',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}
