import { Col, Form, Row } from 'antd';
import type { ColProps } from 'antd';

import formTypes from '../field-types/formTypes';

import type { ProFormFieldProps } from '../pro-form';

interface FieldConfig extends Pick<ColProps, 'span'>, ProFormFieldProps<any> {
  type: string;
  visible?: boolean;
  required?: boolean;
  props?: any;
}

interface RowConfig {
  visible?: boolean;
  gutter?: number;
  column?: number;
  fields?: FieldConfig[];
}

interface LayoutFormProps {
  rows: RowConfig[];
  gutter?: RowConfig['gutter'];
  column?: number;
}

const LayoutFormCol: React.FC<{ col: FieldConfig; defaultSpan?: FieldConfig['span'] }> = ({ col, defaultSpan, fieldTypes }) => {
  const { visible = true, type, rules = [], span = defaultSpan, itemRender, required = false, props: fieldProps, ...itemProps } = col;
  const FieldComponent = formTypes[type] || fieldTypes?.[type];

  const finalRules = rules.slice();

  required && !finalRules.find((item) => typeof item?.required === 'boolean') && finalRules.push({ required: true });

  const finalItemProps = { ...itemProps, rules: finalRules };
  const finalFieldProps = { ...fieldProps, placeholder: fieldProps?.placeholder ?? col.label };

  if (itemRender) {
    return <Col span={span}>{itemRender(finalItemProps, finalFieldProps)}</Col>;
  }

  if (!visible || !FieldComponent) {
    return null;
  }

  return (
    <Col span={span}>
      <Form.Item {...finalItemProps}>
        <FieldComponent {...finalFieldProps} />
      </Form.Item>
    </Col>
  );
};

const LayoutFormRow: React.FC<{ row: RowConfig; defaultGutter: number; defaultColumn: number }> = ({
  row,
  fieldTypes,
  defaultGutter,
  defaultColumn,
}) => {
  const { visible = true, gutter = defaultGutter, column = defaultColumn } = row;
  const defaultColSpan = Math.ceil(24 / column);
  return visible ? (
    <Row gutter={gutter}>
      {row.fields?.map((col, cIndex) => (
        <LayoutFormCol key={`col_${cIndex}`} col={col} defaultSpan={defaultColSpan} fieldTypes={fieldTypes} />
      ))}
    </Row>
  ) : null;
};

const LayoutForm: React.FC<LayoutFormProps & { fieldTypes?: Record<string, React.FC> }> & { Row: typeof LayoutFormRow } = ({
  rows,
  gutter = 48,
  column = 4,
  fieldTypes,
}) => (
  <>
    {rows.map((row, rIndex) => {
      return <LayoutFormRow key={`row_${rIndex}`} row={row} defaultGutter={gutter} defaultColumn={column} fieldTypes={fieldTypes} />;
    })}
  </>
);

LayoutForm.Row = LayoutFormRow;

export default LayoutForm;
