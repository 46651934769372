// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 增加用箱明细 POST /logisticssolution/v1/buyer/container-orders/add-container-detail/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_addUsingBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerAddUsingBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/buyer/container-orders/add-container-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_addUsingBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 批量操作用箱明细 POST /logisticssolution/v1/buyer/container-orders/batch-handle-detail/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_batchHandleBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerBatchHandleBoxDetailParams,
  body: API.BatchHandleUsingBoxDetailRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BatchHandleUsingBoxDetailResponse>(
    `/logisticssolution/v1/buyer/container-orders/batch-handle-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_batchHandleBoxDetail',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 箱子轨迹 GET /logisticssolution/v1/buyer/container-orders/box-position-list/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_getBoxPositionList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerGetBoxPositionListParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.BoxPositionDTO[]>(
    `/logisticssolution/v1/buyer/container-orders/box-position-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_getBoxPositionList',
      },
      params: {
        ...queryParams,
        request: undefined,
        ...queryParams['request'],
      },
      ...(rest || {}),
    },
  );
}

/** 作废提/还箱单 PUT /logisticssolution/v1/buyer/container-orders/cancel-pick-dropoff-order/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_cancelPickOrDropoffOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerCancelPickOrDropoffOrderParams,
  body: API.CanclePickOrDropoffRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/buyer/container-orders/cancel-pick-dropoff-order/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_cancelPickOrDropoffOrder',
        'Content-Type': 'application/json',
      },
      params: { ...queryParams },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 删除用箱明细 POST /logisticssolution/v1/buyer/container-orders/delete-container-detail/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_deleteUsingBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerDeleteUsingBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/buyer/container-orders/delete-container-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_deleteUsingBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 修改用箱明细 POST /logisticssolution/v1/buyer/container-orders/edit-container-detail/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_editUsingBoxDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerEditUsingBoxDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/logisticssolution/v1/buyer/container-orders/edit-container-detail/${param0}`,
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_editUsingBoxDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 提箱记录详情 GET /logisticssolution/v1/buyer/container-orders/extract-boxrecord-detail/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_extractBoxRecordDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerExtractBoxRecordDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.PickupDetailDTO[]>(
    `/logisticssolution/v1/buyer/container-orders/extract-boxrecord-detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_extractBoxRecordDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 根据明细id获取已提的箱子 GET /logisticssolution/v1/buyer/container-orders/get-after-box-list/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_getBoxListByPerformanceContainerDetailId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerGetBoxListByPerformanceContainerDetailIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(
    `/logisticssolution/v1/buyer/container-orders/get-after-box-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_getBoxListByPerformanceContainerDetailId',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 根据履约单id获取所有已提还的箱子 GET /logisticssolution/v1/buyer/container-orders/get-all-box-list/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_getAllBoxListByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerGetAllBoxListByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(
    `/logisticssolution/v1/buyer/container-orders/get-all-box-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_getAllBoxListByPerformanceContainerId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 根据履约单获取所有已经还的箱子 GET /logisticssolution/v1/buyer/container-orders/get-already-box-list/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_getAlreadyBoxListByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerGetAlreadyBoxListByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(
    `/logisticssolution/v1/buyer/container-orders/get-already-box-list/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_getAlreadyBoxListByPerformanceContainerId',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 根据用箱单获取用箱明细 GET /logisticssolution/v1/buyer/container-orders/get-box-detail/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_getBoxDetailByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerGetBoxDetailByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.GetBoxDetailResponse[]>(
    `/logisticssolution/v1/buyer/container-orders/get-box-detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_getBoxDetailByPerformanceContainerId',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 根据履约单id获取所有已经提箱子 GET /logisticssolution/v1/buyer/container-orders/get-box-list/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_getBoxListByPerformanceContainerId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerGetBoxListByPerformanceContainerIdParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string[]>(`/logisticssolution/v1/buyer/container-orders/get-box-list/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_logisticssolution_BuyerContainerOrderController_getBoxListByPerformanceContainerId',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 根据箱型获取堆场箱子库存 GET /logisticssolution/v1/buyer/container-orders/location-stock-by-box-type/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_getLocationStockByBoxType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerGetLocationStockByBoxTypeParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<number>(
    `/logisticssolution/v1/buyer/container-orders/location-stock-by-box-type/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_getLocationStockByBoxType',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 预览提箱单 GET /logisticssolution/v1/buyer/container-orders/preview-pickup-order/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_previewPickupOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerPreviewPickupOrderParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<string>(
    `/logisticssolution/v1/buyer/container-orders/preview-pickup-order/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_previewPickupOrder',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 查看用箱信息 GET /logisticssolution/v1/buyer/container-orders/query-using-boxrecord/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_queryUsingBoxRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerQueryUsingBoxRecordParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.UsingBoxRecordVo>(
    `/logisticssolution/v1/buyer/container-orders/query-using-boxrecord/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_queryUsingBoxRecord',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}

/** 还箱记录详情 GET /logisticssolution/v1/buyer/container-orders/repay-boxrecord-detail/${param0} */
export async function OperationId_logisticssolution_BuyerContainerOrderController_repayBoxRecordDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionBuyerContainerOrderControllerRepayBoxRecordDetailParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DropoffDetailDTO[]>(
    `/logisticssolution/v1/buyer/container-orders/repay-boxrecord-detail/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_logisticssolution_BuyerContainerOrderController_repayBoxRecordDetail',
      },
      params: {
        ...queryParams,
      },
      ...(rest || {}),
    },
  );
}
