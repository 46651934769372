import type { AxiosResponse, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import { CodeMap } from './codeMap';
import { i18n } from '../../i18n/src/index';
import { utils } from '@easygo/utils';
export interface CustomRequestConfig {
  baseUrl: string;
  sessionKey: string;
  refreshTokenApi?: (body: any, options?: { [key: string]: any } | undefined) => Promise<any>;
  onRefreshError?: (error: any) => void;
  onError?: (code: string, message?: string) => void;
}
export interface UserInfoProps {
  accountId: string;
  name: string;
  email: string;
  mobile: string;
}
/** 卖家/买家标识 只限于权限使用 */
const userType = {
  buier: 1,
  saler: 2,
};
class AxiosConfig {
  public config: CustomRequestConfig;
  constructor() {
    this.config = { baseUrl: '', sessionKey: '' };
  }
  public setConfig(config: CustomRequestConfig) {
    this.config = config;
  }
}
const requestConfig = new AxiosConfig();

// 实例
const instance = axios.create({
  baseURL: requestConfig.config.baseUrl,
  timeout: 60000,
  headers: { Authorization: '', 'Content-Type': 'application/json' },
  validateStatus: (status) => {
    return [404, 200].includes(status);
  },
});

const getSession = () => {
  let session: any = Cookies.get(requestConfig.config.sessionKey);
  try {
    session = JSON.parse(atob(session));
    return session;
  } catch (e) {
    return null;
  }
};

const getRefreshToken = () => {
  let session: any = Cookies.get(requestConfig.config.sessionKey);
  try {
    session = JSON.parse(atob(session));
    return session.refresh_tokena;
  } catch (e) {
    return '';
  }
};

const accessHeaders = () => {
  const userInfo = sessionStorage.getItem('_easygo_user');
  const userInfoFormat = userInfo ? JSON.parse(userInfo as string) : '';
  const { accountId } = userInfoFormat as UserInfoProps;
  const userTypeInfo2 = utils.storage.getEnterpriseType(`${accountId}E-TYPE`) ?? {};
  return userType[userTypeInfo2 as keyof typeof userTypeInfo2];
};

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const session = getSession();
    const eToken = Cookies.get(process.env.NEXT_PUBLIC_ENTERPRISE_TOKEN_KEY!);
    config.headers!['Authorization'] = session ? session.accessToken : config.headers?.Authorization || '';
    config.headers!['X-Enterprise-Authorization'] = eToken ? eToken : config.headers?.['X-Enterprise-Authorization'] || '';
    config.headers!['X-Language'] = (Cookies.get(process.env.NEXT_PUBLIC_LANGUAGE!) ?? 'zh').toUpperCase();
    if (accessHeaders() && process.env.NEXT_PUBLIC_APP_NAME === 'trade') config.headers!['Identity'] = accessHeaders();
    config.baseURL = requestConfig.config.baseUrl;
    if (config.data instanceof FormData) {
      (config.headers as any)['Content-Type'] = 'multipart/form-data';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let requests: any = [];

function getMessageFromCodeMap(code: string): string | boolean {
  return Object.keys(CodeMap).includes(code) ? CodeMap[code as keyof typeof CodeMap] : false;
}

instance.interceptors.response.use(
  (response) => {
    const config = response.config;
    const { code, message } = response.data;
    // /** 船讯接口调用特殊处理 */
    // if (code === 'und' || !code) return response?.data;
    if (!`${code}`.startsWith('200') && !config.noErrorTip) {
      // 接口不成功，且 noErrorTip = false 时
      const msgEnums = getMessageFromCodeMap(`${code}`);
      const finalMessage = msgEnums ? i18n?.t(msgEnums as string, { ns: 'message' }) : message;
      requestConfig.config.onError?.(code, finalMessage);
      throw response;
    } else if (`${code}` == '4001000005') {
      if (!isRefreshing) {
        isRefreshing = true;
        let refresh_token = getRefreshToken();
        requestConfig.config
          .refreshTokenApi?.(refresh_token)
          .then((res) => {
            Cookies.set(requestConfig.config.sessionKey, btoa(JSON.stringify(res)));
            const session = getSession();
            config.headers!['Authorization'] = session ? session.accessToken : '';
            requests.forEach((cb: any) => cb());
            requests = [];
            return instance(config);
          })
          .catch((err) => {
            requestConfig.config.onRefreshError?.(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        return new Promise((resolve) => {
          requests.push(() => {
            resolve(instance(config));
          });
        });
      }
    } else {
      return response;
    }
  },
  (error: any) => {
    if (!error?.config?.noErrorTip) {
      const { code, message } = error.response?.data ?? {};
      requestConfig.config.onError?.(code, message ?? error.message);
    }
    return Promise.reject(error);
  }
);

export interface IResponse<P> {
  code?: number;
  data?: P;
  message?: string;
}

export interface PaginationData<T> {
  pageNumber: number;
  totalCount: number;
  totalPage: number;
  pageSize: number;
  item: T[];
}

export interface PageRequestParams {
  page: number;
  pageSize: number;
}

type Parameters<T extends (...args: any[]) => any> = T extends (...args: infer P) => any ? P : never;

export function get<ParamsType, ResponseType>(...args: Parameters<typeof instance.get<ParamsType, ResponseType>>) {
  return instance.get<ParamsType, AxiosResponse<IResponse<ResponseType>>>(...args);
}
export function post<ParamsType, ResponseType>(...args: Parameters<typeof instance.post<ParamsType, ResponseType>>) {
  return instance.post<ParamsType, AxiosResponse<IResponse<ResponseType>>>(...args);
}
export function del<ParamsType, ResponseType>(...args: Parameters<typeof instance.delete<ParamsType, ResponseType>>) {
  return instance.delete<ParamsType, AxiosResponse<IResponse<ResponseType>>>(...args);
}
export function put<ParamsType, ResponseType>(...args: Parameters<typeof instance.put<ParamsType, ResponseType>>) {
  return instance.put<ParamsType, AxiosResponse<IResponse<ResponseType>>>(...args);
}
export function patch<ParamsType, ResponseType>(...args: Parameters<typeof instance.patch<ParamsType, ResponseType>>) {
  return instance.patch<ParamsType, AxiosResponse<IResponse<ResponseType>>>(...args);
}

/**
 * Data传接口的data字段的格式
 * @parmas ReqConfig
 * @return Promise<data>
 */
const headersMap = {
  normForm: 'application/x-www-form-urlencoded',
  mutiForm: 'multipart/form-data',
};
type ReqConfig = AxiosRequestConfig & {
  noErrorTip?: boolean; // 是否不在这里处理错误提示 默认 false
  contentType?: keyof typeof headersMap; // contentType默认json
};
export const request = <Data>(config: ReqConfig) => {
  const { noErrorTip = false, contentType, ...config_ } = config;
  if (contentType && headersMap[contentType]) {
    config_.headers = config_.headers || {};
    Object.assign(config_.headers as AxiosRequestHeaders, {
      'Content-Type': headersMap[contentType],
    });
  }
  return instance
    .request<Required<IResponse<Data>>>(config_)
    .then(({ data: result }) => {
      const { code, data, message } = result;
      // if (['xxx'].includes(String(code))) {
      //   return Promise.reject(result);
      // }
      return data;
    })
    .catch((error) => {
      // 错误集中处理情况
      // if (!noErrorTip && error && error.status !== 404) requestConfig.config.onError?.(error.response?.data.code || error.message);
      return Promise.reject(error);
    });
};
const alias =
  (method: string) =>
  <Data>(url: string, config: ReqConfig = {}) =>
    request<Data>({ url, method, ...config });
request.get = alias('get');
request.post = alias('post');
request.put = alias('put');
request.delete = alias('delete');
request.patch = alias('patch');

export async function gernatorRequest<ResponseType>(url: string, options: any): Promise<ResponseType> {
  const res = await instance.request({
    url,
    ...options,
  });
  return (res?.data?.code ? res?.data?.data : res) as ResponseType;
}

export const config = requestConfig;
export default instance;
