// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 查询需求单 GET /logisticssolution/v1/seller/demand-orders */
export async function OperationId_logisticssolution_SellerDemandOrderController_pageDemandOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerDemandOrderControllerPageDemandOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageDemandOrderDTO>('/logisticssolution/v1/seller/demand-orders', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerDemandOrderController_pageDemandOrder',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 更新需求单 PUT /logisticssolution/v1/seller/demand-orders */
export async function OperationId_logisticssolution_SellerDemandOrderController_updateDemandOrder(
  body: API.DemandOrderUpdateRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/logisticssolution/v1/seller/demand-orders', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerDemandOrderController_updateDemandOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 下载需求单文件 GET /logisticssolution/v1/seller/demand-orders/documents */
export async function OperationId_logisticssolution_SellerDemandOrderController_downloadDemandOrder(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdLogisticssolutionSellerDemandOrderControllerDownloadDemandOrderParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<string>('/logisticssolution/v1/seller/demand-orders/documents', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_logisticssolution_SellerDemandOrderController_downloadDemandOrder',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}
