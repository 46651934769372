/** 商家与客户业务规则 */
export const TRADE_RULE_DOC =
  'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/patform-agreement/%E5%95%86%E5%AE%B6%E4%B8%8E%E5%AE%A2%E6%88%B7%E4%B8%9A%E5%8A%A1%E8%A7%84%E5%88%99.pdf';
/** 授权委托书 */
export const AUTHORIZATION_DOC =
  'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/patform-agreement/%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6.pdf';
/** 隐私协议 */
export const PRIVACY_DOC =
  'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/patform-agreement/%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.pdf';
/** 用户服务协议和管理规则 */
export const MEMBER_RULE_DOC =
  'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/patform-agreement/%E7%94%A8%E6%88%B7%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE%E5%92%8C%E7%AE%A1%E7%90%86%E8%A7%84%E5%88%99.pdf';
/** 注册协议 */
export const REGIST_DOC =
  'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/patform-agreement/%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf';
/** 班列托书模板 */
export const RAILWAY_SO_TPL = 'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/selling/8923f9e3e01249d58de6302893a9d3c7.xlsx';
/** 海运托书模版 */
export const OCEAN_SO_TPL = 'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/selling/d776e13aef0345ff99bb52dedf1b5eba.xlsx';
/**《舱位服务协议》 */
export const OCEAN_SERVICE_PROTOCOL =
  'https://yichang-microservice-public.oss-cn-hangzhou.aliyuncs.com/patform-agreement/%E6%B5%B7%E8%BF%90%E8%88%B1%E4%BD%8D%E4%BA%A4%E6%98%93%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf';
/** E签宝协议签订教程 */
export const ESIGN_HELP_URL = 'https://help.esign.cn/detail?id=bih8ag&nameSpace=cs3-dept%2Fexboae';
