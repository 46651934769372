import styles from './index.module.less';
import { useBoolean, useRequest } from 'ahooks';
import { forwardRef } from 'react';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import type { SelectProps } from 'antd';
import { List, Select, Space } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../icon-font';
import { useTranslation } from 'next-i18next';

export enum AddressType {
  city = 'CITY',
  station = 'STATION',
  port = 'PORT',
}

const AddressTypeLabelMap = {
  [AddressType.city]: '城市',
  [AddressType.station]: '车站',
  [AddressType.port]: '港口',
};

const AddressTypeIconMap = {
  [AddressType.city]: {
    default: 'easygo-icon-chengshi-weixuanzhong',
    active: 'easygo-icon-chengshixuanzhong',
  },
  [AddressType.station]: {
    default: 'easygo-icon-a-chezhanweixuanzhong',
    active: 'easygo-icon-a-chezhanxuanzhong',
  },
  [AddressType.port]: {
    default: 'easygo-icon-a-gangkouweixuanzhong',
    active: 'easygo-icon-a-gangkouxuanzhong',
  },
};

export interface AddressOptionItem {
  id: string;
  nameZh: string;
  nameEn: string;
  areaType: AddressType;
}

interface SelectAddressItemProps<T> {
  item: T;
  opts: { [key: string]: any };
  onSelected: (item: T) => void;
}

function getTopBotTxt({ nameZh, nameEn }: { nameZh: string | undefined; nameEn: string | undefined }, opts: { [key: string]: any }) {
  const { textPosition = 'zhTop' } = opts;
  let topTxt = nameZh;
  let botTxt = nameEn;
  switch (textPosition) {
    case 'enTop':
      topTxt = nameEn;
      botTxt = nameZh;
      break;
    default:
      break;
  }
  return { topTxt, botTxt };
}

function SelectAddressItem<T extends AddressOptionItem>({ item, opts, onSelected }: SelectAddressItemProps<T>) {
  const [isActive, { setTrue: setActive, setFalse: setNoActive }] = useBoolean(false);
  const _txt = getTopBotTxt({ nameZh: item.nameZh, nameEn: item.nameEn }, opts);
  return (
    <div className={styles['selector-item']} onClick={() => onSelected(item)} onMouseDown={setActive} onMouseUp={setNoActive}>
      <div className={styles.left}>
        <IconFont type={isActive ? AddressTypeIconMap[item.areaType]?.active : AddressTypeIconMap[item.areaType]?.default} style={{ fontSize: 24 }} />
        <div className={styles['type-label']}>{AddressTypeLabelMap[item.areaType]}</div>
      </div>
      <div className={styles.right}>
        <div className={styles['label-top']}>{_txt?.topTxt}</div>
        <div className={styles['label-bot']}>{_txt?.botTxt}</div>
      </div>
    </div>
  );
}

export interface SelectAddressProps<T> extends SelectProps {
  onChange?: (value: T | undefined) => void;
  onSearch: (keyword: string) => Promise<T[]>;
  opts?: { [key: string]: any };
  labelClassName?: string;
}

function SelectAddressInner<T extends AddressOptionItem>(props: SelectAddressProps<T>, ref: any) {
  const { t } = useTranslation('common');
  const { onSearch, onChange, style, opts = {}, dropdownStyle, value, labelClassName, ...restProps } = props;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T>();
  const keywordRef = useRef<string>();
  const [keyword, setKeyword] = useState<string>();
  const portServer = useRequest(onSearch, {
    manual: true,
    debounceWait: 500,
  });

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  function onSelected(item: T) {
    setSelectedItem(item);
    onChange?.(item);
    setOpen(false);
  }

  const dropdownRender = () => (
    <List
      style={{ maxHeight: 300, overflow: 'auto', padding: '8px' }}
      loading={portServer.loading}
      dataSource={keyword ? portServer.data || [] : []}
      renderItem={(item) => <SelectAddressItem item={item} opts={opts} onSelected={onSelected} />}
      locale={{
        emptyText: <div className={styles.empty}>{t('no_results')}</div>,
      }}
    />
  );

  const _txt = getTopBotTxt({ nameZh: selectedItem?.nameZh, nameEn: selectedItem?.nameEn }, opts);

  useImperativeHandle(ref, () => ({
    setSelect: (item: T) => {
      setSelectedItem(item);
    },
  }));

  const handleClear = () => {
    setSelectedItem(undefined);
    onChange?.(undefined);
  };

  return (
    <div className={classNames(styles['select-city'])} style={style}>
      <Select
        className={classNames(styles.selector, { [styles.hide]: !open })}
        popupClassName={styles['area-select-dropdown']}
        open={open}
        dropdownMatchSelectWidth={363}
        showSearch
        allowClear
        value={selectedItem?.id}
        onClear={handleClear}
        style={{
          width: '100%',
        }}
        onSearch={(keyword) => {
          keywordRef.current = keyword;
          setKeyword(keyword);
          if (!keyword) {
            return;
          }

          portServer.runAsync(keyword);
        }}
        placement="bottomLeft"
        {...restProps}
        placeholder=""
        showArrow={false}
        onDropdownVisibleChange={(value) => {
          setKeyword('');
          setOpen(value);
        }}
        dropdownRender={dropdownRender}
      />
      <div
        className={classNames(styles['display-label'], labelClassName, open ? (!!keyword ? styles.hide : styles.placehoder) : '')}
        onClick={() => setOpen(true)}
        style={dropdownStyle}
      >
        {selectedItem ? (
          <div className={styles.select_content}>
            <span className={styles['label-top']}>{_txt?.topTxt}</span>
            <span className={styles['label-bot']}>{_txt?.botTxt}</span>
          </div>
        ) : (
          <div className={styles.placeholder} style={dropdownStyle}>
            {restProps.placeholder}
          </div>
        )}
      </div>
    </div>
  );
}

export const SelectAddress = forwardRef(SelectAddressInner);
