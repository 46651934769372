// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 通过授权码交换用户accessToken 通过授权码交换用户accessToken，没有用户需要自动创建，使用第三方扫码登录或注册统一使用该接口 GET /salesgateway/social/${param0}/token */
export async function OperationId_salesgateway_SocialController_exchangeTokenByAuthCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewaySocialControllerExchangeTokenByAuthCodeParams,
  options?: { [key: string]: any },
) {
  const { provider: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.AuthResponseVO>(`/salesgateway/social/${param0}/token`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_SocialController_exchangeTokenByAuthCode',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}

/** 使用微信扫码绑定微信账号回调 此操作适用于已登录，并需要手动绑定社交账号的场景 GET /salesgateway/social/${param1}/accounts/${param0}/token */
export async function OperationId_salesgateway_SocialController_exchangeTokenForBindSocial(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewaySocialControllerExchangeTokenForBindSocialParams,
  options?: { [key: string]: any },
) {
  const { accountId: param0, provider: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<any>(`/salesgateway/social/${param1}/accounts/${param0}/token`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_SocialController_exchangeTokenForBindSocial',
    },
    params: {
      ...queryParams,
    },
    ...(rest || {}),
  });
}
