// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 创建或修改商品 POST /sinotransbooking/v1/seller/products */
export async function OperationId_sinotransbooking_SellerProductController_createOrUpdateProduct(
  body: API.BatchProductRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/sinotransbooking/v1/seller/products', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_createOrUpdateProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品详情 GET /sinotransbooking/v1/seller/products/${param0} */
export async function OperationId_sinotransbooking_SellerProductController_getProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerGetProductParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductVO>(`/sinotransbooking/v1/seller/products/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_getProduct',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 上架/下架(批量) PUT /sinotransbooking/v1/seller/products/active-status */
export async function OperationId_sinotransbooking_SellerProductController_BatchOnOrOffSaleItem(
  body: API.ActiveStatusRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/sinotransbooking/v1/seller/products/active-status', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_BatchOnOrOffSaleItem',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 上架/下架 PUT /sinotransbooking/v1/seller/products/active-status/${param0}/${param1} */
export async function OperationId_sinotransbooking_SellerProductController_onOrOffSaleItem(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerOnOrOffSaleItemParams,
  options?: { [key: string]: any },
) {
  const { itemId: param0, activeStatus: param1, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/sinotransbooking/v1/seller/products/active-status/${param0}/${param1}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerProductController_onOrOffSaleItem',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 异步导入商品2 POST /sinotransbooking/v1/seller/products/async-import-2-products */
export async function OperationId_sinotransbooking_SellerProductController_importProductAsync2(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerImportProductAsync2Params,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ProductImportAsyncVO>(
    '/sinotransbooking/v1/seller/products/async-import-2-products',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerProductController_importProductAsync2',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 异步导入商品 POST /sinotransbooking/v1/seller/products/async-import-products */
export async function OperationId_sinotransbooking_SellerProductController_importProductAsync(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerImportProductAsyncParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ProductImportAsyncVO>(
    '/sinotransbooking/v1/seller/products/async-import-products',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerProductController_importProductAsync',
      },
      params: {
        ...params,
      },
      ...(rest || {}),
    },
  );
}

/** 异步创建或修改商品 POST /sinotransbooking/v1/seller/products/async-products */
export async function OperationId_sinotransbooking_SellerProductController_createOrUpdateProductAsync(
  body: API.BatchProductRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/seller/products/async-products', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_SellerProductController_createOrUpdateProductAsync',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 创建或修改(单个)商品 POST /sinotransbooking/v1/seller/products/create-or-update */
export async function OperationId_sinotransbooking_SellerProductController_createOrUpdateSingleProduct(
  body: API.ProductRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<number>('/sinotransbooking/v1/seller/products/create-or-update', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_SellerProductController_createOrUpdateSingleProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看字典 GET /sinotransbooking/v1/seller/products/dict/${param0} */
export async function OperationId_sinotransbooking_SellerProductController_getDict(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerGetDictParams,
  options?: { [key: string]: any },
) {
  const { type: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.DictVO[]>(`/sinotransbooking/v1/seller/products/dict/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_getDict',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}

/** 导出商品 GET /sinotransbooking/v1/seller/products/export */
export async function OperationId_sinotransbooking_SellerProductController_exportProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerExportProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/seller/products/export', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_exportProduct',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 导出商品2 POST /sinotransbooking/v1/seller/products/export2 */
export async function OperationId_sinotransbooking_SellerProductController_exportProduct2(
  body: API.ProductExport2Query,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/sinotransbooking/v1/seller/products/export2', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_exportProduct2',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 获取导入商品2模板下载文件名 GET /sinotransbooking/v1/seller/products/get-import-2-products-template-file-name */
export async function OperationId_sinotransbooking_SellerProductController_getImportProductTemplateFileName2(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<string>(
    '/sinotransbooking/v1/seller/products/get-import-2-products-template-file-name',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerProductController_getImportProductTemplateFileName2',
      },
      ...(rest || {}),
    },
  );
}

/** 获取导入商品模板下载文件名 GET /sinotransbooking/v1/seller/products/get-import-products-template-file-name */
export async function OperationId_sinotransbooking_SellerProductController_getImportProductTemplateFileName(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<string>(
    '/sinotransbooking/v1/seller/products/get-import-products-template-file-name',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_sinotransbooking_SellerProductController_getImportProductTemplateFileName',
      },
      ...(rest || {}),
    },
  );
}

/** 导入商品 POST /sinotransbooking/v1/seller/products/import */
export async function OperationId_sinotransbooking_SellerProductController_importProduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerImportProductParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ProductImportVO>('/sinotransbooking/v1/seller/products/import', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_importProduct',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看导入任务状态 GET /sinotransbooking/v1/seller/products/import/tasks/${param0} */
export async function OperationId_sinotransbooking_SellerProductController_getImportTask(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerGetImportTaskParams,
  options?: { [key: string]: any },
) {
  const { taskId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductImportTaskDTO>(
    `/sinotransbooking/v1/seller/products/import/tasks/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerProductController_getImportTask',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** 导入商品2 POST /sinotransbooking/v1/seller/products/import2 */
export async function OperationId_sinotransbooking_SellerProductController_importProduct2(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerImportProduct2Params,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ProductImportVO>('/sinotransbooking/v1/seller/products/import2', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_importProduct2',
    },
    params: {
      ...params,
    },
    ...(rest || {}),
  });
}

/** 查看商品日志 GET /sinotransbooking/v1/seller/products/item-logs */
export async function OperationId_sinotransbooking_SellerProductController_pageItemLog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerPageItemLogParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductOperationLogDTO>(
    '/sinotransbooking/v1/seller/products/item-logs',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerProductController_pageItemLog',
      },
      params: {
        ...params,
        query: undefined,
        ...params['query'],
      },
      ...(rest || {}),
    },
  );
}

/** 查看商品版本 GET /sinotransbooking/v1/seller/products/item-versions */
export async function OperationId_sinotransbooking_SellerProductController_pageItemVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerPageItemVersionParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVersionVO>('/sinotransbooking/v1/seller/products/item-versions', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_pageItemVersion',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}

/** 查看商品版本详情 GET /sinotransbooking/v1/seller/products/item-versions/${param0} */
export async function OperationId_sinotransbooking_SellerProductController_getItemVersion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerGetItemVersionParams,
  options?: { [key: string]: any },
) {
  const { versionId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductVersionVO>(
    `/sinotransbooking/v1/seller/products/item-versions/${param0}`,
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_sinotransbooking_SellerProductController_getItemVersion',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}

/** /商品列表 POST /sinotransbooking/v1/seller/products/page */
export async function OperationId_sinotransbooking_SellerProductController_searchPageProduct(
  body: API.ProductQueryV2,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/seller/products/page', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_searchPageProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 商品预览 POST /sinotransbooking/v1/seller/products/preview */
export async function OperationId_sinotransbooking_SellerProductController_previewPageProduct(
  body: API.ProductQueryV2,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/seller/products/preview', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_previewPageProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品 POST /sinotransbooking/v1/seller/products/search */
export async function OperationId_sinotransbooking_SellerProductController_pageProduct(
  body: API.ProductQuery,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/seller/products/search', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_pageProduct',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查询商品代下单 POST /sinotransbooking/v1/seller/products/search/agent */
export async function OperationId_sinotransbooking_SellerProductController_pageProductAgent(
  body: API.ProductQueryV2,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.IPageProductVO>('/sinotransbooking/v1/seller/products/search/agent', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_pageProductAgent',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 查看任务状态 GET /sinotransbooking/v1/seller/products/tasks/${param0} */
export async function OperationId_sinotransbooking_SellerProductController_getTask(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingSellerProductControllerGetTaskParams,
  options?: { [key: string]: any },
) {
  const { taskId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<API.ProductCreateTaskDTO>(`/sinotransbooking/v1/seller/products/tasks/${param0}`, {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_SellerProductController_getTask',
    },
    params: { ...queryParams },
    ...(rest || {}),
  });
}
