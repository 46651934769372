import { i18n } from '@easygo/i18n';
// 统一社会信用代码
export const certReg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;

// 新uscc 支持15和18位
export const usccReg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;

// 身份证
export const idCardReg =
  /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/;

// 手机
export const mobileReg = /^1([3-9])\d{9}$/;

// 座机
export const phoneReg = /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/;

// 座机+手机
export const telReg = /^((0\d{2,3}-\d{7,8})|(1\d{10}))$/;

// 邮箱
export const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;

// 只包含数字、字母
export const letterOrNumReg = /^[0-9a-zA-Z]*$/g;

// 只包含数字、字母、汉字
export const nickNameReg = /^[0-9a-zA-Z\u4e00-\u9fa5]*$/g;

// 只包含数字、字母、汉字 + - @ %
export const nickNameSpeReg = /^[\+\-@%0-9a-zA-Z\u4e00-\u9fa5]*$/g;

// 只包含中英文和空格
export const CnOrEnReg = /^[a-zA-Z\u4e00-\u9fa5 ]*$/g;

// 只包含数字、字母，用于订单号，不能加g
export const orderNumReg = /^[0-9a-zA-Z_]*$/;

//数字
export const numberReg = /^\d+$/g;

// 整数/浮点数
export const posPattern = /^\d*\.?\d+$/;

//大于零的整数
export const integerReg = /^[1-9][0-9]*$/g;

// 0和非0开头的正整数
export const zeroAndPositiveIntegerReg = /^(0|[1-9][0-9]*)$/;

// [1, 100] 正整数
export const zeroAndPositiveIntegerRegTwo = /^(100|[1-9][0-9]|[1-9])$/;

// 银行卡号正则（建行16、19，农行19，工行19、交通17、民生16、兴业18、招行12、16、19）
export const bankCardReg = /^([1-9]{1})(\d{11}|\d{15}|\d{16}|\d{17}|\d{18})$/;

// 货币金额
export const monetaryReg = /^(\+)?\d+(\.\d+)?$/;

//限制两位小数
export const numberLimitTwoDecimalReg = /^\d+(\.\d{1,2})?$/g;

// 限制2位小数并支持负数
export const twoDeciAndNegative = /^(\-|\+)?\d+(\.\d{1,2})?$/g;

// 密码：大小写字母加数字 6~16位
export const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z]{6,16}$/;

// 非中文 非英文
export const notLetterAndChinese = /^[^a-zA-Z\u4e00-\u9fa5]*$/g;

// http/https请求地址
export const urlReg = /^http(s)?:\/\/(\S)+/;

// 根据文件名后缀判断是否是图片
export const imageUrlReg = /\.(png|jpg|gif|jpeg|webp)$/;

export const trimValidator = (message?: string) => [
  {
    validator: (_: any, value: string) => {
      if (value && !value.trim()) {
        return Promise.reject(new Error(message ?? '请输入正确信息'));
      } else {
        return Promise.resolve();
      }
    },
  },
];

// 正整数或两位小数
export const numberTwoDecimalReg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/;

// 正整数、0或两位小数
export const zeroNumberTwoDecimalReg = /^0$|(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/;

// 只包含数字 +
export const numberOrPlus = /^[\d\+ ]*$/g;

// 经度校验，整数部分为0-180，小数部分为0到6位
export const valiLongitude = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;

// 纬度校验，整数部分为0-90，小数部分为0到6位
export const valiLatitude = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;

// 正整数或三位小数
export const numberLimitthreeDecimalReg = /^(([1-9]\d+)|[1-9])(\.\d{1,3})?$/;

// 0-9的正整数
export const nunberLessNine = /^([1-9]?|9)$/;

// 是否中文
export const isChinese = (str: string) => {
  const reg = /[u00-uff]/;
  return !reg.test(str);
};

// 限制最大数字
export const fn_numberMaximumDigit = (_: any, value: string | number, digit: number = 8) => {
  if (value && Number(value) > Math.pow(10, digit)) {
    return Promise.reject(new Error(i18n?.t('最大限制{{digit}}位数', { digit })));
  } else {
    return Promise.resolve();
  }
};

// 大于0的整数
export const integerGreaterZero = /^[1-9]\d*$/;
