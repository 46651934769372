import { i18n } from '@easygo/i18n';
import { useMemo, useState } from 'react';
import { useControllableValue } from 'ahooks';
import { Radio, Select } from 'antd';
import type { SelectProps } from 'antd';

import styles from './index.module.less';
import { WEEK } from '@easygo/utils/src/enums';
import { CalendarOutlined } from '@ant-design/icons';

const WeekOptions = [
  { value: WEEK.WEEK_MONDAY, label: '周一' },
  { value: WEEK.WEEK_TUESDAY, label: '周二' },
  { value: WEEK.WEEK_WEDNESDAY, label: '周三' },
  { value: WEEK.WEEK_THURSDAY, label: '周四' },
  { value: WEEK.WEEK_FRIDAY, label: '周五' },
  { value: WEEK.WEEK_SATURDAY, label: '周六' },
  { value: WEEK.WEEK_SUNDAY, label: '周日' },
];
export const getText = (data: string) => WeekOptions.find((item) => item.value === data)?.label;

const WeekRadioDropdown: React.FC<SelectProps> = ({ bordered = true, ...rest }) => {
  const [data, onDataChange] = useControllableValue(rest);
  const [innerData, setInnerData] = useState();

  const str = useMemo(() => getText(data), [data]);

  const handleVisibleChange = (open: boolean) => {
    if (open) {
      setInnerData(data);
    } else {
      onDataChange(innerData);
    }
  };

  return (
    <div className={styles.wrap}>
      <Select
        {...rest}
        value={str}
        placeholder={i18n?.t('请选择')}
        bordered={bordered}
        suffixIcon={<CalendarOutlined />}
        dropdownStyle={{ width: 352 }}
        dropdownMatchSelectWidth={false}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        popupClassName={styles.popup}
        onDropdownVisibleChange={handleVisibleChange}
        dropdownRender={() => (
          <Radio.Group
            className={styles.radio}
            value={innerData}
            onChange={(newInnerValue) => setInnerData(newInnerValue.target.value)}
            options={WeekOptions}
          />
        )}
      />
    </div>
  );
};

export default WeekRadioDropdown;
