import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

export function getEnumsFromLabelValue(data?: { [key: string]: any }[], options?: { labelKey?: string; valueKey?: string }) {
  const target: Record<any, any> = {};
  const { labelKey = 'label', valueKey = 'value' } = options || {};
  data?.forEach((it) => {
    target[it[valueKey]] = it[labelKey];
  });
  return target;
}

/**
 * 枚举转换为select待选项options，可指定key，默认排除包含 UNRECOGNIZED 和 UNKNOWN 的枚举项目
 * @param enums 枚举值
 * @param options 配置项
 * @param options.labelKey 指定label的key名，默认：label
 * @param options.valueKey 指定value的key名，默认：value
 * @param options.excludes 需要排除的枚举项（字符串匹配，字符子串即可匹配）| false，默认：['UNRECOGNIZED', 'UNKNOWN']
 *
 */
export const transformEnumsToOptions = (enums: { [key: string]: string }, options?: any) => {
  const { labelKey = 'label', valueKey = 'value', excludes = ['UNRECOGNIZED', 'UNKNOWN'] } = options || {};
  let res: { [key: string]: string }[] = [];
  for (let key in enums) {
    res.push({
      [valueKey]: key,
      [labelKey]: enums[key],
    });
  }
  if (excludes) {
    excludes.forEach((exi: string) => {
      res = res.filter((item) => item[valueKey].indexOf(exi) < 0);
    });
  }
  return res;
};

/**
 * 将原枚举去掉一些特定项（例如：UNRECOGNIZED 和 UNKNOWN），然后返回新的枚举，默认去掉包含 UNRECOGNIZED 和 UNKNOWN 的key
 * @param enums 枚举值
 * @param options 配置项
 * @param options.excludes 需要排除的枚举项（字符串匹配，字符子串即可匹配）| false，默认：['UNRECOGNIZED', 'UNKNOWN']
 *
 */
export const transformEnumsFromSourceEnums = (enums: { [key: string]: string }, options?: any) => {
  const { excludes = ['UNRECOGNIZED', 'UNKNOWN'] } = options || {};
  let keys = Object.keys(enums);
  if (excludes) {
    excludes.forEach((exi: string) => {
      keys = keys.filter((item) => item.indexOf(exi) < 0);
    });
  }
  const res: { [key: string]: string } = {};
  for (let item of keys) {
    res[item] = enums[item];
  }
  return res;
};

/**
 * 根据select的id获取label
 * @param items options data 数据
 * @param value 需要匹配的value
 * @returns
 */
export function getSelectOptionsLabel(items: any[], value: number | string): string | undefined {
  for (let i = 0; i < items.length; i++) {
    const e = items[i];
    if ((typeof e.value === 'string' && e.value.split(',').some((i: string | number) => Number(i) === value)) || e.value === value) {
      return e.label;
    }
  }
}

/**
 * 根据数组创建枚举
 * @param values
 * @returns
 */
export function createEnums<T extends string | number>(values: T[]) {
  return values.reduce((enums, value) => {
    enums[value] = value;
    return enums;
  }, {} as { [K in T]: K });
}

/**
 * 枚举转 label - value 数组
 * @param enums 枚举值
 * @param trans 翻译函数
 * @param ns 翻译配置
 * @returns
 */
export const getEnumsLabelValue = (enums: Record<string | number, string>, trans?: any, ns?: { ns: string }) => {
  return Object.keys(enums).map((value) => ({
    value,
    label: trans ? trans(enums[value], ns) : enums[value],
  }));
};

export const useEnumOptions = (items: any[] = []) => {
  const { t } = useTranslation('enums');
  return useMemo(() => items.map((item) => getEnumsLabelValue(item, t)), [t, items]);
};

/** 字典 根据code换label */
export const useDictCodeToLabel =
  (dict: Record<string, any>, t: any) =>
  (code: string, ...args: any) => {
    return t(dict[code], ...args);
  };
