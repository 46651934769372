/** 堆场账单状态 */
export enum YardBillStatus {
  /** 待出账 */
  NOT_BILLED = 'YARD_BILL_STATUS_NOT_BILLED',
  /** 待审核，已出账 */
  WAIT_AUDIT = 'YARD_BILL_STATUS_WAIT_AUDIT',
  /** 待确认，已审核 */
  WAIT_CONFIRM = 'YARD_BILL_STATUS_WAIT_CONFIRM',
  /** 已确认 */
  CONFIRMED = 'YARD_BILL_STATUS_CONFIRMED',
  /** 已驳回 */
  REJECT = 'YARD_BILL_STATUS_REJECT',
}

/** 堆场账单审核状态 */
export enum AuditYardBillStatus {
  /** 通过 */
  PASS = 'AUDIT_YARD_BILL_STATUS_PASS',
  /** 驳回 */
  REJECT = 'AUDIT_YARD_BILL_STATUS_REJECT',
}

/** 堆场账单确认状态 */
export enum ConfirmYardBillStatus {
  /** 通过 */
  PASS = 'CONFIRM_YARD_BILL_STATUS_PASS',
  /** 驳回 */
  REJECT = 'CONFIRM_YARD_BILL_STATUS_REJECT',
}

/** 堆场账单语言 */
export enum YardBillLanguage {
  /** 英文 */
  EN = 'EN',
  /** 中文 */
  CN = 'CN',
}

/** 汇率转换方式 */
export enum RateChangeType {
  /** 按明细转换 */
  DETAIL = 'RATE_CHANGE_TYPE_DETAIL',
  /** 按总额转换 */
  TOTAL = 'RATE_CHANGE_TYPE_TOTAL',
}
