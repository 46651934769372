import { Button, DatePicker, Space } from 'antd';
import type { RangePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';
import { useComponentTrans } from '../config';
import { ProDatePicker } from '../pro-date-picker';
type ButtonRangePickerProps = Omit<RangePickerProps, 'onChange'> & {
  onChange?: (values: dayjs.Dayjs[]) => void;
};
const { ProRangePicker } = ProDatePicker;
const ButtonRangePicker = ({ format = 'YYYY-MM-DD', onChange, ...restProps }: ButtonRangePickerProps) => {
  const trans = useComponentTrans();
  const monthRange = [dayjs().startOf('month'), dayjs().endOf('month')];
  const todayRange = [dayjs().startOf('day'), dayjs().endOf('day')];
  const handleChange = (values: dayjs.Dayjs[]) => {
    onChange && onChange(values);
  };
  const rangePickerProps = {
    format,
    onChange: handleChange,
    style: { width: '100%' },
    placeholder: [trans('range_picker_start_placeholder'), trans('range_picker_end_placeholder')],
    ...restProps,
  } as RangePickerProps;
  const setMonth = () => {
    handleChange(monthRange);
  };

  const setToday = () => {
    handleChange(todayRange);
  };

  return (
    <>
      <Space>
        <ProRangePicker {...rangePickerProps} />
        <Button onClick={setMonth}>{trans('btn_curr_month')}</Button>
        <Button onClick={setToday}>{trans('btn_curr_day')}</Button>
      </Space>
    </>
  );
};

export default ButtonRangePicker;
