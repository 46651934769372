// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 获取类型映射的配置 GET /sinotransbooking/v1/business/configs/type-content */
export async function OperationId_sinotransbooking_BusinessConfigurationController_getTypeContent(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingBusinessConfigurationControllerGetTypeContentParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.ContentMapVO>('/sinotransbooking/v1/business/configs/type-content', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_sinotransbooking_BusinessConfigurationController_getTypeContent',
    },
    params: {
      ...params,
      query: undefined,
      ...params['query'],
    },
    ...(rest || {}),
  });
}
