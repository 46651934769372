// 租箱订单状态
export const OrderStatusMap = {
  ORDER_STATUS_TAKE_CONFIRM: 'ORDER_STATUS_TAKE_CONFIRM', // 待接单
  ORDER_STATUS_PAY_WAIT: 'ORDER_STATUS_PAY_WAIT', // 待支付
  ORDER_STATUS_PAY_PART: 'ORDER_STATUS_PAY_PART', // 部分支付
  ORDER_STATUS_PAY_FINISH: 'ORDER_STATUS_PAY_FINISH', // 支付完成
  ORDER_STATUS_FINISH: 'ORDER_STATUS_FINISH', // 订单完成，用于结算
  ORDER_STATUS_CANCEL: 'ORDER_STATUS_CANCEL', // 取消
  ORDER_STATUS_TIMEOUT_CANCEL: 'ORDER_STATUS_TIMEOUT_CANCEL', // 超时取消
  ORDER_STATUS_PAY_CONFIRM: 'ORDER_STATUS_PAY_CONFIRM', // 支付待确认
};

/** 租箱履约状态 */
export const PerformanceStatus = {
  /** 未提箱 */
  PERFORMANCE_STATUS_INIT: 'PERFORMANCE_STATUS_INIT',
  /** 待提箱 */
  PERFORMANCE_STATUS_UN_PICKUP: 'PERFORMANCE_STATUS_UN_PICKUP',
  /** 未还箱 */
  PERFORMANCE_STATUS_UN_RETURN: 'PERFORMANCE_STATUS_UN_RETURN',
  /** 已完成 */
  PERFORMANCE_STATUS_COMPLETE: 'PERFORMANCE_STATUS_COMPLETE',
  /** 已取消 */
  PERFORMANCE_STATUS_CANCEL: 'PERFORMANCE_STATUS_CANCEL',
};

/** 租箱订单状态+履约状态——下拉搜索需求 */
export const OrderPerfStatus = {
  ORDER_STATUS_PAY_WAIT: 'ORDER_STATUS_PAY_WAIT', // 待支付
  ORDER_STATUS_PAY_PART: 'ORDER_STATUS_PAY_PART', // 部分支付
  /** 未提箱 */
  PERFORMANCE_STATUS_UN_PICKUP: 'PERFORMANCE_STATUS_UN_PICKUP',
  /** 未还箱 */
  PERFORMANCE_STATUS_UN_RETURN: 'PERFORMANCE_STATUS_UN_RETURN',
  ORDER_STATUS_FINISH: 'ORDER_STATUS_FINISH', // 订单完成，用于结算
  ORDER_STATUS_CANCEL: 'ORDER_STATUS_CANCEL', // 取消
};

// 卖家(商户)类型
export const SellerTypeMap = {
  ENTERPRISE_TYPE_OWN_BUSINESS: 'ENTERPRISE_TYPE_OWN_BUSINESS', // 自营
  ENTERPRISE_TYPE_OTHERS: 'ENTERPRISE_TYPE_OTHERS', // 非自营
};

// 附加费
export const Surcharge = {
  EXTRA_CHARGE_TYPE_OVERTIME: 'EXTRA_CHARGE_TYPE_OVERTIME', // 超期费
  EXTRA_CHARGE_TYPE_DELAY: 'EXTRA_CHARGE_TYPE_DELAY', // 延期费
  EXTRA_CHARGE_TYPE_CRANE: 'EXTRA_CHARGE_TYPE_CRANE', // 吊车费
};

// 支付方式
export const PayMethod = {
  PAY_METHOD_ALL: 'PAY_METHOD_ALL', // 一次性付清
  PAY_METHOD_PREPAID: 'PAY_METHOD_PREPAID', // 比例预付
};

// 城市类型（境内or境外）
export const CityArea = {
  CITY_TYPE_INSIDE: 'CITY_TYPE_INSIDE', // 境内
  CITY_TYPE_OUTSIDE: 'CITY_TYPE_OUTSIDE', // 境外
};

/** 租箱订单预警 */
export const RentOrderWarning = {
  /** 无预警 */
  WARNING_TYPE_NONE: 'WARNING_TYPE_NONE',
  /** 超期未提 */
  WARNING_TYPE_OVERDUE_UNPICKUP: 'WARNING_TYPE_OVERDUE_UNPICKUP',
  /** 超期未还 */
  WARNING_TYPE_OVERDUE_UNDROPOFF: 'WARNING_TYPE_OVERDUE_UNDROPOFF',
};

/** 提箱状态 */
export const PICKUP_STATUS = {
  /** 待发放提箱单 */
  PICKUP_STATUS_UNPROVIDED: 'PICKUP_STATUS_UNPROVIDED',
  /** 已发放提箱单 */
  PICKUP_STATUS_PROVIDED: 'PICKUP_STATUS_PROVIDED',
  /** 已作废 */
  PICKUP_STATUS_CANCELED: 'PICKUP_STATUS_CANCELED',
};

/** 还箱状态 */
export const RETURN_STATUS = {
  /** 待发放还箱单 */
  RETURN_STATUS_UNPROVIDED: 'RETURN_STATUS_UNPROVIDED',
  /** 已发放还箱单 */
  RETURN_STATUS_PROVIDED: 'RETURN_STATUS_PROVIDED',
  /** 已作废 */
  RETURN_STATUS_CANCELED: 'RETURN_STATUS_CANCELED',
};

export const BOX_STATE = {
  /** 新箱 */
  BOX_CONTAINER: 'BOX_CONTAINER',
  /** 适货箱 */
  CARGOWORTHY_CONTAINER: 'CARGOWORTHY_CONTAINER',
  /** 次新箱 */
  OLD_CONTAINER: 'OLD_CONTAINER',
};
/** 箱况 */
export const BOXSTATE = {
  [BOX_STATE.BOX_CONTAINER]: 'new_box',
  [BOX_STATE.CARGOWORTHY_CONTAINER]: 'only_goods_box',
  [BOX_STATE.OLD_CONTAINER]: 'sec_new_box',
};

export const BOXSTATEZh = {
  新箱: 'new_box',
  次新箱: 'sec_new_box',
  适货箱: 'only_goods_box',
};

export const PERFORMANCE_MODE_OPTIONS = [
  { value: 1, label: '自动（对接箱管系统自动发放提还箱单）' },
  { value: 2, label: '手动（手动上传提还箱单）' },
];

export const resolveBoxStateOptions = (tran?: (...arg: any) => any) =>
  Object.entries(BOXSTATEZh).map(([value, label]) => ({ value, label: tran?.(label) || label }));

export enum ChangeType {
  /** 提箱城市 */
  CHANGE_TYPE_PICKUP_CITY = 'CHANGE_TYPE_PICKUP_CITY',
  /** 还箱城市 */
  CHANGE_TYPE_DROP_OFF_CITY = 'CHANGE_TYPE_DROP_OFF_CITY',
}

export enum ChangeLineStatus {
  /** 正常 */
  LINE_CHANGE_STATUS_NORMAL = 'LINE_CHANGE_STATUS_NORMAL',
  /** 变更中 */
  LINE_CHANGE_STATUS_CHANGING = 'LINE_CHANGE_STATUS_CHANGING',
}

export enum ChangeApplyStatus {
  /** 待运营审核 */
  CHANGE_APPLY_STATUS_OPERATE_CHECK = 'CHANGE_APPLY_STATUS_OPERATE_CHECK',
  /** 待平台审核 */
  CHANGE_APPLY_STATUS_PLATFORM_CHECK = 'CHANGE_APPLY_STATUS_PLATFORM_CHECK',
  /** 待支付 */
  CHANGE_APPLY_STATUS_WAIT_PAY = 'CHANGE_APPLY_STATUS_WAIT_PAY',
  /** 待退款 */
  CHANGE_APPLY_STATUS_WAIT_REFUND = 'CHANGE_APPLY_STATUS_WAIT_REFUND',
  /** 审核驳回 */
  CHANGE_APPLY_STATUS_REJECT = 'CHANGE_APPLY_STATUS_REJECT',
  /** 已完成 */
  CHANGE_APPLY_STATUS_FINISH = 'CHANGE_APPLY_STATUS_FINISH',
  /** 已关闭 */
  CHANGE_APPLY_STATUS_CLOSE = 'CHANGE_APPLY_STATUS_CLOSE',
}

export enum SubPerformanceStatus {
  /** 待申请提箱 */
  SUB_PERFORMANCE_STATUS_WAIT_APPLY_PICKUP = 'SUB_PERFORMANCE_STATUS_WAIT_APPLY_PICKUP',
  /** 待提箱 */
  SUB_PERFORMANCE_STATUS_WAIT_REAL_PICKUP = 'SUB_PERFORMANCE_STATUS_WAIT_REAL_PICKUP',
  /** 待申请还箱 */
  SUB_PERFORMANCE_STATUS_WAIT_APPLY_DROP_OFF = 'SUB_PERFORMANCE_STATUS_WAIT_APPLY_DROP_OFF',
  /** 待还箱 */
  SUB_PERFORMANCE_STATUS_WAIT_REAL_DROP_OFF = 'SUB_PERFORMANCE_STATUS_WAIT_REAL_DROP_OFF',
  /** 已还箱 */
  SUB_PERFORMANCE_STATUS_FINISH_DROP_OFF = 'SUB_PERFORMANCE_STATUS_FINISH_DROP_OFF',
  /** 已取消 */
  SUB_PERFORMANCE_STATUS_CANCEL = 'SUB_PERFORMANCE_STATUS_CANCEL',
}

export enum CityType {
  /** 境内 */
  CITY_TYPE_INSIDE = 'CITY_TYPE_INSIDE',
  /** 境外 */
  CITY_TYPE_OUTSIDE = 'CITY_TYPE_OUTSIDE',
}

export enum ChangeApplyReason {
  /** 买家原因 */
  CHANGE_APPLY_REASON_BUYER = 'CHANGE_APPLY_REASON_BUYER',
  /** 卖家原因 */
  CHANGE_APPLY_REASON_SELLER = 'CHANGE_APPLY_REASON_SELLER',
}
