// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 库存列表导出 GET /salesgateway/container-owner/stock/export */
export async function OperationId_salesgateway_ContainerOwnerStockController_exportContainerOwnerStock(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerStockControllerExportContainerOwnerStockParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/container-owner/stock/export', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerOwnerStockController_exportContainerOwnerStock',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 库存查询列表 GET /salesgateway/container-owner/stock/page */
export async function OperationId_salesgateway_ContainerOwnerStockController_pageContainerOwnerStock(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerStockControllerPageContainerOwnerStockParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.LocationBoxTypePageVO>('/salesgateway/container-owner/stock/page', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerOwnerStockController_pageContainerOwnerStock',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}
