// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 超期提醒邮箱列表 GET /salesgateway/container/leasing/overdue/email/list */
export async function OperationId_salesgateway_OverdueEmailController_getEmails(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.OverdueEmailVO>('/salesgateway/container/leasing/overdue/email/list', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OverdueEmailController_getEmails',
    },
    ...(rest || {}),
  });
}

/** 保存超期提醒邮箱 POST /salesgateway/container/leasing/overdue/email/save */
export async function OperationId_salesgateway_OverdueEmailController_saveEmails(
  body: API.OverdueEmailVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/container/leasing/overdue/email/save', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_OverdueEmailController_saveEmails',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
