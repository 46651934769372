// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 履约动作 PUT /sinotransbooking/v1/seller/performances-delivery/${param0}/actions */
export async function OperationId_sinotransbooking_PerformanceDeliverySellerController_performanceDeliveryAction(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceDeliverySellerControllerPerformanceDeliveryActionParams,
  body: API.SellerPerformanceDeliveryActionRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performances-delivery/${param0}/actions`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_PerformanceDeliverySellerController_performanceDeliveryAction',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}

/** 送仓分配 PUT /sinotransbooking/v1/seller/performances-delivery/${param0}/items */
export async function OperationId_sinotransbooking_PerformanceDeliverySellerController_deliveryAllocation(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSinotransbookingPerformanceDeliverySellerControllerDeliveryAllocationParams,
  body: API.PerformanceTrailerAllocationRequest,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(`/sinotransbooking/v1/seller/performances-delivery/${param0}/items`, {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_sinotransbooking_PerformanceDeliverySellerController_deliveryAllocation',
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(rest || {}),
  });
}
