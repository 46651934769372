// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 下单接口 POST /salesgateway/v1/declaration/performance/buyer/createOrder */
export async function OperationId_salesgateway_DeclarationPerformanceBuyerController_createOrder(
  body: API.DeclarationCreateOrderReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.DeclarationCreateOrderVo>(
    '/salesgateway/v1/declaration/performance/buyer/createOrder',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationPerformanceBuyerController_createOrder',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 获取订单关联卖家信息 GET /salesgateway/v1/declaration/performance/buyer/getSellers */
export async function OperationId_salesgateway_DeclarationPerformanceBuyerController_getBuyers(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.EnterpriseInfo[]>(
    '/salesgateway/v1/declaration/performance/buyer/getSellers',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationPerformanceBuyerController_getBuyers',
      },
      ...(rest || {}),
    },
  );
}

/** 报关买家订单列表 POST /salesgateway/v1/declaration/performance/buyer/orderList */
export async function OperationId_salesgateway_DeclarationPerformanceBuyerController_orderList(
  body: API.DeclarationPageOrderBaseRequest,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.CustomerOrderListVO>(
    '/salesgateway/v1/declaration/performance/buyer/orderList',
    {
      method: 'POST',
      headers: {
        ...(headers || {}),
        operationId: 'OperationId_salesgateway_DeclarationPerformanceBuyerController_orderList',
        'Content-Type': 'application/json',
      },
      data: body,
      ...(rest || {}),
    },
  );
}

/** 海关订单买家列表banner GET /salesgateway/v1/declaration/performance/buyer/orderListBanner */
export async function OperationId_salesgateway_DeclarationPerformanceBuyerController_orderListBanner(options?: {
  [key: string]: any;
}) {
  const { headers, ...rest } = options || {};
  return request<API.CustomerOrderBannerVO[]>(
    '/salesgateway/v1/declaration/performance/buyer/orderListBanner',
    {
      method: 'GET',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceBuyerController_orderListBanner',
      },
      ...(rest || {}),
    },
  );
}

/** 取消订单 PUT /salesgateway/v1/declaration/performance/buyer/orders/cancel */
export async function OperationId_salesgateway_DeclarationPerformanceBuyerController_cancelOrder(
  body: API.DeclarationOrderBaseReq,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<boolean>('/salesgateway/v1/declaration/performance/buyer/orders/cancel', {
    method: 'PUT',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_DeclarationPerformanceBuyerController_cancelOrder',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 买家报关 PUT /salesgateway/v1/declaration/performance/buyer/performance/customs-declare/${param0} */
export async function OperationId_salesgateway_DeclarationPerformanceBuyerController_customsDeclare(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayDeclarationPerformanceBuyerControllerCustomsDeclareParams,
  options?: { [key: string]: any },
) {
  const { performanceId: param0, ...queryParams } = params;
  const { headers, ...rest } = options || {};
  return request<boolean>(
    `/salesgateway/v1/declaration/performance/buyer/performance/customs-declare/${param0}`,
    {
      method: 'PUT',
      headers: {
        ...(headers || {}),
        operationId:
          'OperationId_salesgateway_DeclarationPerformanceBuyerController_customsDeclare',
      },
      params: { ...queryParams },
      ...(rest || {}),
    },
  );
}
