// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 箱源明细-历史记录 GET /salesgateway/container-owner/box-log/box/log */
export async function OperationId_salesgateway_ContainerOwnerBoxLogController_pageContainerOwnerBoxLog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OperationIdSalesgatewayContainerOwnerBoxLogControllerPageContainerOwnerBoxLogParams,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoxLogPageVO>('/salesgateway/container-owner/box-log/box/log', {
    method: 'GET',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerOwnerBoxLogController_pageContainerOwnerBoxLog',
    },
    params: {
      ...params,
      vo: undefined,
      ...params['vo'],
    },
    ...(rest || {}),
  });
}

/** 业务流水列表导出 POST /salesgateway/container-owner/box-log/export */
export async function OperationId_salesgateway_ContainerOwnerBoxLogController_exportContainerOwnerLog(
  body: API.QueryBoxLogVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<any>('/salesgateway/container-owner/box-log/export', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId:
        'OperationId_salesgateway_ContainerOwnerBoxLogController_exportContainerOwnerLog',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}

/** 业务流水列表分页 POST /salesgateway/container-owner/box-log/page */
export async function OperationId_salesgateway_ContainerOwnerBoxLogController_pageContainerOwnerLog(
  body: API.QueryBoxLogVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.BoxLogPageVO>('/salesgateway/container-owner/box-log/page', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContainerOwnerBoxLogController_pageContainerOwnerLog',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
