/**
 * 扁平数组转化为树结构
 * @param arr {array} 数组
 * @param key {string} item 标识
 * @param parentKey {string} item 父级标识
 * @returns 树结构
 */
export function transferArrToTree(arr: any[], key: string = 'id', parentKey: string = 'pid', sortChildrenCallback?: (params: []) => []) {
  const result = [];
  const itemMap: any = {};
  for (const item of arr) {
    const id = item[key];
    const pid = item[parentKey];
    if (!itemMap[id]) {
      itemMap[id] = { children: [] };
    }
    itemMap[id] = { ...item, children: itemMap[id]['children'] };
    const treeItem = itemMap[id];
    if (pid == 0) {
      result.push(treeItem);
    } else {
      if (!itemMap[pid]) {
        itemMap[pid] = { children: [] };
      }
      itemMap[pid].children.push(treeItem);
      if (sortChildrenCallback) {
        itemMap[pid].children = sortChildrenCallback?.(itemMap[pid].children);
      }
    }
  }
  return result;
}

/**
 * 获取扁平树节点得所有父节点
 * @param arr 树
 * @param id 节点id
 * @param key id 键名称
 * @returns 所有父节点组成的数组
 */
export const getArrParentNodes = (arr: any[], id: string | number, key: string = 'id', parentKey: string = 'pid', result: any[] = []): any => {
  if (!arr?.length) return result;
  const _item = arr.find((i) => i[key] === id);
  if (_item) {
    if (_item[parentKey] == 0) {
      return result;
    } else {
      const _itemParent = arr.find((i) => i[key] === _item[parentKey]);
      result.unshift(_itemParent);
      return getArrParentNodes(arr, _itemParent[key], key, parentKey, result);
    }
  }
};

/** 获取cascader value string | number to (string | number)[] */
export function getCascaderValue<DataType = Record<string, any>>(
  value: string,
  data: DataType[],
  keys: {
    valueKey: string;
    childrenKey: string;
  } = {
    valueKey: 'id',
    childrenKey: 'children',
  }
) {
  const arr: (string | number)[] = [];
  let returnArr: (string | number)[] = [];
  let depth = 0;
  function childrenEach(childrenData: DataType[], depthN: number) {
    for (let j = 0; j < childrenData.length; j++) {
      depth = depthN;
      const obj: any = childrenData[j];
      arr[depthN] = obj[keys.valueKey];
      if (obj[keys.valueKey] === value) {
        returnArr = arr.slice(0, depthN + 1);
        break;
      } else {
        if (obj[keys.childrenKey]) {
          depth++;
          childrenEach(obj[keys.childrenKey], depth);
        }
      }
    }
    return returnArr;
  }
  return childrenEach(data, depth);
}
