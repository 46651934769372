// @ts-ignore
/* eslint-disable */

import { request } from '@easygo-athena/request';
import type { API } from './typings.d';

/** 签署协议 POST /salesgateway/contracts/sign */
export async function OperationId_salesgateway_ContractController_signContract(
  body: API.SignContractRequestVO,
  options?: { [key: string]: any },
) {
  const { headers, ...rest } = options || {};
  return request<API.SignContractResponseVO>('/salesgateway/contracts/sign', {
    method: 'POST',
    headers: {
      ...(headers || {}),
      operationId: 'OperationId_salesgateway_ContractController_signContract',
      'Content-Type': 'application/json',
    },
    data: body,
    ...(rest || {}),
  });
}
